import { useState, Fragment, useEffect } from "react";

import {
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiCallOut,
} from "@inscopix/ideas-eui";
import { uuid } from "../../utils/uuid";

function getBase64(
  file: File,
  setFileUploadIsInvalid: React.Dispatch<React.SetStateAction<boolean>>,
  setFileSize: React.Dispatch<React.SetStateAction<number>>,
  onSuccessful: (imageBase64: string) => void,
) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    if (reader.result === null) {
      throw new Error("File reader result undefined");
    }
    const fileSize = new Blob([reader.result]).size / 1000;
    if (fileSize > 800) {
      setFileUploadIsInvalid(true);
      setFileSize(Math.round(fileSize));
    } else {
      setFileUploadIsInvalid(false);
      onSuccessful(reader.result as string);
    }
  };
  reader.onerror = function (error) {
    // eslint-disable-next-line no-console
    console.log("Error: ", error);
  };
}

const filePickerId = uuid();
interface FileImageUploadProps {
  onValidImageBase64Selection: (imageBase64: string) => void;
}

function FileImageUpload({
  onValidImageBase64Selection,
}: FileImageUploadProps) {
  const [file, setFile] = useState<File | null>(null);
  const [fileUploadIsInvalid, setFileUploadIsInvalid] = useState(false);
  const [fileSize, setFileSize] = useState(0);

  const onChange = (inputFiles: FileList | null) => {
    if (inputFiles !== null) {
      setFile(inputFiles[0]);
    }
  };

  useEffect(() => {
    if (file) {
      getBase64(
        file,
        setFileUploadIsInvalid,
        setFileSize,
        onValidImageBase64Selection,
      );
    }
  }, [file, onValidImageBase64Selection]);

  return (
    <Fragment>
      <EuiFlexGroup>
        <EuiFlexItem grow={2}>
          <EuiFilePicker
            id={filePickerId}
            multiple
            initialPromptText="Select an image file"
            onChange={onChange}
            display={"default"}
            aria-label="Use aria labels when no actual label is in use"
            isInvalid={fileUploadIsInvalid}
          />
          {fileUploadIsInvalid ? (
            <EuiCallOut
              title="File size too big! "
              color="danger"
              iconType="warning"
            >
              <p>Image size must be less than 800kB</p>
              <p>Current image: {fileSize}kB</p>
            </EuiCallOut>
          ) : null}
          <EuiSpacer />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Fragment>
  );
}

export default FileImageUpload;
