import axios from "axios";
import { cloneDeep } from "lodash";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";

import { useUpdatePageProjectCache } from "pages/project/hooks/useUpdatePageProjectCache";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";

import {
  EuiGlobalToastListToast as Toast,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { AnalysisTableGroup } from "../../graphql/_Types";
import { addToast } from "../GlobalToastList/GlobalToastList";
import { isDefined } from "utils/isDefined";
import { ModalConfirmDestructiveAction } from "components/ModalConfirmDestructiveAction/ModalConfirmDestructiveAction";
import { useState } from "react";

type ModalDeleteAnalysisTableGroupProps = {
  analysisTableGroupId: AnalysisTableGroup["id"];
  onClose?: () => void;
};

function ModalDeleteAnalysisTableGroup({
  analysisTableGroupId,
  onClose,
}: ModalDeleteAnalysisTableGroupProps) {
  const closeModal = () => {
    if (isDefined(onClose)) {
      onClose();
    }
  };

  const [isDeletionInProgress, setIsDeletionInProgress] = useState(false);

  // Send a REST query to delete the Analysis table and manually update the cache
  const { project } = useProjectDataContext();
  const { updateCache: updateProjectCache } = useUpdatePageProjectCache(
    project.key,
  );

  const deleteAnalysisTableGroup = async () => {
    const headers = await getRequestHeaders();
    const url = getEnvVar("URL_LIBRARY_ANALYSIS_TABLE_GROUP");
    const response = await axios.delete(`${url}${analysisTableGroupId}/`, {
      headers,
    });
    if (response?.status === 204) {
      // Update the cache
      updateProjectCache((prevData) => {
        const newData = cloneDeep(prevData);
        newData.project.analysisTableGroups.nodes =
          newData.project.analysisTableGroups.nodes.filter(
            (group) => group.id !== analysisTableGroupId,
          );
        return newData;
      });

      return true; // Returning success
    }

    return false; // Returning failure
  };

  // Toast messages to display after the mutation is called
  const toastSuccess: Toast = {
    id: htmlIdGenerator()(),
    title: "Analysis Table Deleted",
    color: "success",
  };

  const toastFailure: Toast = {
    id: htmlIdGenerator()(),
    title: "Error",
    color: "danger",
  };

  const handleConfirm = async () => {
    setIsDeletionInProgress(true);
    const isSuccessful = await deleteAnalysisTableGroup();
    setIsDeletionInProgress(false);
    isSuccessful ? addToast(toastSuccess) : addToast(toastFailure);
    closeModal();
  };

  return (
    <ModalConfirmDestructiveAction
      title="Delete Analysis Table"
      onCancel={closeModal}
      onConfirm={(e) => {
        e.preventDefault();
        void handleConfirm();
      }}
      confirmButtonText="Delete Analysis Table"
      isLoading={isDeletionInProgress}
      confirmCheckboxPrompt="I wish to delete this Analysis Table"
      body={
        <>
          <p>
            You are about to delete an Analysis Table permanently along with all
            its data - this operation cannot be undone.
          </p>
        </>
      }
    />
  );
}

export default ModalDeleteAnalysisTableGroup;
