import assert from "assert";

/**
 * Creates an invisible file input element, triggers a file selection dialog,
 * and resolves a promise with an array of selected files once chosen.
 * @param allowMultiselect Whether multiple file selection is allowed.
 * @returns A `Promise` that resolves with an array of selected `File` objects.
 */
export const selectSystemFiles = (allowMultiselect: boolean) => {
  return new Promise<File[] | undefined>((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = allowMultiselect;
    input.title = "";
    input.style.display = "none";
    document.body.appendChild(input);
    input.click();

    /* Fired when a file (or files if `allowMultiselect` is true) from the
       file picker are selected */
    input.onchange = (e) => {
      e.preventDefault();
      input.remove();
      assert(e.target instanceof HTMLInputElement);
      const files = Array.from(e.target.files ?? []);
      resolve(files);
    };

    /* Fired when the file picker dialog gets closed, or canceled, via the
       "cancel" button or the escape key */
    input.oncancel = () => {
      input.remove();
      resolve(undefined);
    };
  });
};
