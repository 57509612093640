import {
  EuiButton,
  EuiComboBox,
  EuiFlexGroup,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiSpacer,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { FileType, FILE_TYPES, FILE_TYPES_BY_KEY } from "types/FileTypes";
import { File as DrsFile } from "graphql/_Types";

const fileTypeOptions = Object.values(FILE_TYPES).map((FILE_TYPE) => {
  return {
    label: FILE_TYPE.name,
    prepend: <EuiIcon size="s" type={FILE_TYPE.icon} />,
    id: String(FILE_TYPE.id),
    key: FILE_TYPE.key,
  };
});

export interface ModalIdentifyFileBodyProps {
  file: Pick<DrsFile, "fileType">;
  onCancel: () => void;
  onSubmit: (fileType: FileType["id"]) => Promise<void>;
}

export const ModalIdentifyFileBody = ({
  file,
  onCancel,
  onSubmit,
}: ModalIdentifyFileBodyProps) => {
  const [selectedOption, setSelectedOption] = useState(
    fileTypeOptions.find(
      (option) =>
        String(file.fileType ?? FILE_TYPES_BY_KEY["unknown"].id) === option.id,
    ),
  );

  return (
    <EuiForm
      component="form"
      role="form"
      onSubmit={(e) => {
        e.preventDefault();

        if (selectedOption !== undefined) {
          void onSubmit(Number(selectedOption.id) as FileType["id"]);
        }
      }}
    >
      <EuiFormRow
        label="Data Type"
        helpText="Manually set the data type of this file. For supported data types, metadata will be extracted and preview(s) generated."
        display="rowCompressed"
      >
        <EuiComboBox<string>
          aria-label="Select data column type"
          options={fileTypeOptions}
          onChange={(newSelectedOptions) => {
            setSelectedOption(
              fileTypeOptions.find(
                (option) => option.id === newSelectedOptions[0]?.id,
              ),
            );
          }}
          singleSelection={true}
          selectedOptions={selectedOption ? [selectedOption] : []}
          placeholder={`Select one option or start typing to search`}
          compressed
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiButton type="submit" isDisabled={selectedOption === undefined}>
          Set Data Type
        </EuiButton>
        <EuiButton color="text" onClick={onCancel}>
          Close
        </EuiButton>
      </EuiFlexGroup>
    </EuiForm>
  );
};
