import assert from "assert";
import {
  isLinkedMetadataColumn,
  isMetadataColumn,
} from "components/RecordingsGrid/RecordingsGrid.helpers";
import { RecordingsGridColDef } from "components/RecordingsGrid/RecordingsGrid.types";
import {
  FileRecordingGroup,
  RecordingIdentifierBadgeMetadataDocument,
  RecordingIdentifierBadgeMetadataQuery,
  RecordingIdentifierBadgeMetadataQueryVariables,
  RecordingIdentifierBadgeRecordingsDocument,
  RecordingIdentifierBadgeRecordingsQuery,
  RecordingIdentifierBadgeRecordingsQueryVariables,
} from "graphql/_Types";
import { chain, set, uniq } from "lodash";
import pDebounce from "p-debounce";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { JsonValue } from "type-fest";
import { isDefined } from "utils/isDefined";
import { isNonNull } from "utils/isNonNull";
import { stringifyMetadataValue } from "utils/stringifyMetadataValue";

/**
 * Gets recording data
 * @param recordingIds
 * @returns The formatted recording data
 * @throws An error if any data fails to be fetched
 */
const getRecordings = async (recordingIds: string[]) => {
  const { data } = await client.query<
    RecordingIdentifierBadgeRecordingsQuery,
    RecordingIdentifierBadgeRecordingsQueryVariables
  >({
    query: RecordingIdentifierBadgeRecordingsDocument,
    fetchPolicy: "no-cache",
    variables: { recordingIds },
  });

  assert(isNonNull(data.recordings));

  return data.recordings.nodes.map((recording) => {
    const { number, recordingsTable } = recording;
    assert(isNonNull(number));
    assert(isNonNull(recordingsTable));
    const { dataset } = recordingsTable;
    assert(isNonNull(dataset));
    return { ...recording, number, recordingsTable, dataset };
  });
};

/**
 * Gets the identifier columns associated with a recording row
 * @param recording
 * @returns The identifier columns in the order their values should appear
 * in recording identifiers
 */
const getIdentifierColumns = (
  recording: Awaited<ReturnType<typeof getRecordings>>[number],
) => {
  const { recordingsTable } = recording;
  assert(isNonNull(recordingsTable));
  return recordingsTable.columns.nodes
    .filter((column) => isNonNull(column.identifierPosition))
    .sort((a, b) => {
      assert(isNonNull(a.identifierPosition));
      assert(isNonNull(b.identifierPosition));
      return a.identifierPosition - b.identifierPosition;
    })
    .map((column) => {
      const colDef = column.colDef as RecordingsGridColDef;
      return { ...column, colDef };
    });
};

/**
 * Gets the files currently assigned to a recordings table cell
 * @param recordingId
 * @param columnId
 * @returns The files in the cell
 */
const getFilesInCell = (recordingId: string, columnId: string) => {
  const { files } = useProjectFilesStore.getState();
  return files.filter((file) => {
    // Is the file assigned to the specified recording?
    const isRecordingMember = file.recordings.some(
      (recording) => recording.id === recordingId,
    );
    // Is the file assigned to the specified column?
    const isColumnMember = file.columns.some(
      (column) => column.id === columnId,
    );
    return isRecordingMember && isColumnMember;
  });
};

/**
 * Gets maps of recording-level metadata and file-level metadata
 * @param recordings
 * @returns
 * `recordingMetadataMap`: A map of metadata values keyed by recording ID and
 * metadatum key
 *
 * `fileMetadataMap`: A map of metadata values keyed by file ID and metadatum
 * key
 * @throws An error if any data fails to be fetched
 */
const getMetadataMaps = async (
  recordings: Awaited<ReturnType<typeof getRecordings>>,
) => {
  /* An array of recording ID, metadatum key pairs used to efficiently fetch
     metadata values */
  const recordingMetadataPairs = recordings.flatMap((recording) => {
    return getIdentifierColumns(recording)
      .filter(isMetadataColumn)
      .map((column) => ({
        recordingId: recording.id,
        metadatumKey: column.colDef.metadataKey,
      }));
  });

  /* An array of file ID, metadatum key pairs used to efficiently fetch
     metadata values */
  const fileMetadataPairs = recordings.flatMap((recording) => {
    return getIdentifierColumns(recording)
      .filter(isLinkedMetadataColumn)
      .flatMap((column) => {
        const { drsFileColumnId } = column.colDef;
        const files = getFilesInCell(recording.id, drsFileColumnId);
        return files.map((file) => ({
          fileId: file.id,
          metadatumKey: column.colDef.metadataKey,
        }));
      });
  });

  const { data } = await client.query<
    RecordingIdentifierBadgeMetadataQuery,
    RecordingIdentifierBadgeMetadataQueryVariables
  >({
    query: RecordingIdentifierBadgeMetadataDocument,
    fetchPolicy: "no-cache",
    variables: {
      /* If no pairs are specified, skip fetching recording metadata values.
         GQL will fetch ALL metadata records with an empty array of filters
         (very, very expensive)! */
      withRecordingMetadata: recordingMetadataPairs.length > 0,
      recordingMetadataFilter: {
        or: recordingMetadataPairs.map(({ recordingId, metadatumKey }) => ({
          fileRecordingGroupId: {
            equalTo: recordingId,
          },
          metadatumByMetadataId: {
            key: {
              equalTo: metadatumKey,
            },
          },
        })),
      },
      /* If no pairs are specified, skip fetching file metadata values.
         GQL will fetch ALL metadata records with an empty array of filters
         (very, very expensive)! */
      withFileMetadata: fileMetadataPairs.length > 0,
      fileMetadataFilter: {
        or: fileMetadataPairs.map(({ fileId, metadatumKey }) => ({
          fileId: {
            equalTo: fileId,
          },
          metadatumByMetadataId: {
            key: {
              equalTo: metadatumKey,
            },
          },
        })),
      },
    },
  });

  // Recording ID -> metadatum key -> metadatum value
  const recordingMetadataMap: Record<string, Record<string, JsonValue>> = {};
  data.recordingMetadata?.nodes.forEach((node) => {
    const { recordingId, metadatum } = node;
    assert(isNonNull(recordingId));
    assert(isNonNull(metadatum));
    const { key, value } = metadatum;
    set(recordingMetadataMap, [recordingId, key], value);
  });

  // File ID -> metadatum key -> metadatum value
  const fileMetadataMap: Record<string, Record<string, JsonValue>> = {};
  data.fileMetadata?.nodes.forEach((node) => {
    const { fileId, metadatum } = node;
    assert(isNonNull(fileId));
    assert(isNonNull(metadatum));
    const { key, value } = metadatum;
    set(fileMetadataMap, [fileId, key], value);
  });

  return { recordingMetadataMap, fileMetadataMap };
};

// Accumulator holding the recording IDs to use as variables in the next fetch
const recordingIds: FileRecordingGroup["id"][] = [];

/**
 * Fetches the identifiers for the recording IDs stored in the accumulator
 * @returns A lookup map from recording IDs to recording identifiers
 */
const fetchIdentifiers = pDebounce(async () => {
  // Copy and reset the accumulator
  const _recordingIds = recordingIds.splice(0);

  // Fetch recording level information
  const recordings = await getRecordings(_recordingIds);

  // Fetch recording and file metadata
  const { recordingMetadataMap, fileMetadataMap } = await getMetadataMaps(
    recordings,
  );

  // Format data into a lookup map
  const identifiersByRecordingId = chain(recordings)
    .keyBy(({ id }) => id)
    .mapValues((recording) => {
      const cellValues = getIdentifierColumns(recording)
        // Parse cell values from column definitions
        .map((column) => {
          if (isMetadataColumn(column)) {
            const { metadataKey } = column.colDef;
            const columnName = column.colDef.headerName;
            const cellValue = recordingMetadataMap[recording.id]?.[metadataKey];
            return { columnName, cellValue };
          } else if (isLinkedMetadataColumn(column)) {
            const { metadataKey, drsFileColumnId } = column.colDef;
            const columnName = column.colDef.headerName;
            const files = getFilesInCell(recording.id, drsFileColumnId);
            let values = files.map((f) => fileMetadataMap[f.id]?.[metadataKey]);
            values = uniq(values);
            if (values.length === 0) {
              return { columnName, cellValue: undefined };
            } else if (values.length === 1) {
              return { columnName, cellValue: values[0] };
            } else {
              return { columnName, cellValue: "*" };
            }
          } else {
            return { columnName: undefined, cellValue: undefined };
          }
        })
        // Normalize cell values to strings or `undefined`
        .map(({ columnName, cellValue }) => ({
          columnName,
          cellValue: stringifyMetadataValue(cellValue),
        }))
        // Remove empty cell values
        .filter(({ cellValue }) => cellValue !== "");

      const { dataset } = recording;
      const shortId = `${dataset.prefix}-${recording.number}`;
      return {
        shortId,
        cellValues: cellValues as {
          columnName: string;
          cellValue: string;
        }[],
      };
    })
    .value();

  return identifiersByRecordingId;
}, 100);

/**
 * Gets the identifier for a specified recording
 * @param recordingId
 * @returns The recording identifier
 * @throws An error if the identifier could not be retrieved
 */
export const getRecordingIdentifier = async (
  recordingId: FileRecordingGroup["id"],
) => {
  recordingIds.push(recordingId);
  const identifiersByRecordingId = await fetchIdentifiers();
  assert(isDefined(identifiersByRecordingId));
  const identifier = identifiersByRecordingId[recordingId];
  assert(isDefined(identifier));
  return identifier;
};
