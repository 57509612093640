import { EuiBadge } from "@inscopix/ideas-eui";
import { TUserRole } from "components/ModalProjectSharing/ModalProjectSharing";
import { USER_ACCESS_LEVELS_BY_KEY } from "types/UserAccessLevels";

type RoleBadgeProps = {
  role: TUserRole;
};

export const RoleBadge = ({ role }: RoleBadgeProps) => {
  const roleName =
    role === "custom" ? "Custom" : USER_ACCESS_LEVELS_BY_KEY[role].name;

  return <EuiBadge color="hollow">{roleName}</EuiBadge>;
};
