import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { FILE_TYPES_BY_KEY } from "types/FileTypes";
import { shallow } from "zustand/shallow";

/**
 * Hook that retrieves all NWB files in the current dataset from the project
 * files store.
 * @returns The retrieved files.
 */
export const useDatasetNwbFiles = () => {
  const { dataset } = useDatasetDataContext();

  const files = useProjectFilesStore((s) => {
    return s.files.filter((file) => {
      const isInDataset = file.datasets.some(({ id }) => id === dataset.id);
      const isNwbFile = file.fileType === FILE_TYPES_BY_KEY["nwb_data"].id;
      const isInColumn = file.columns.length > 0;
      return isInDataset && isNwbFile && isInColumn;
    });
  }, shallow);

  return files;
};
