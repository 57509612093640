import { css } from "@emotion/react";
import { euiBackgroundColor, useEuiTheme } from "@inscopix/ideas-eui";

export const useMenuBarStyles = () => {
  const theme = useEuiTheme();
  const menuOpenBackgroundColor = euiBackgroundColor(theme, "primary");
  const buttonBase = css`
    display: inline-block;
    height: ${theme.euiTheme.size.xl};
    border-radius: 4px;
    padding: ${theme.euiTheme.size.xs} ${theme.euiTheme.size.s};
  `;
  const menuBarStyles = {
    menuBarMenuButtonStyle: (isOpen: boolean) => [
      buttonBase,
      isOpen
        ? css`
            background-color: ${menuOpenBackgroundColor};
          `
        : undefined,
    ],
    toolbarButtonStyle: [
      buttonBase,
      css`
        &:hover {
          background-color: ${menuOpenBackgroundColor};
        }
      `,
    ],
    rightToolbarStyle: [
      css`
        position: relative;
        float: right;
        padding-right: ${theme.euiTheme.size.s};
      `,
    ],
    iconStyle: css`
      margin-right: 5px;
    `,
  };

  return menuBarStyles;
};
