import { captureException } from "@sentry/react";
import { getRecordingIdentifier } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge.helpers";
import { DataSelectorQuery, useDataSelectorQuery } from "graphql/_Types";
import { zip } from "lodash";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useEffect, useState } from "react";

/**
 * Attaches an `identifier` property to every recording object
 * @param data
 * @returns The query data with recording identifier properties.
 */
const attachRecordingIdentifiers = async (data: DataSelectorQuery) => {
  const datasets = data?.project?.datasets.nodes ?? [];
  const recordingIds = datasets.flatMap((dataset) => {
    const recordings = dataset.recordingsTable?.recordingGroups.nodes ?? [];
    return recordings.map((recording) => recording.id);
  });

  const promises = recordingIds.map((id) => getRecordingIdentifier(id));
  const results = await Promise.all(promises);

  const recordingIdentifiers = results.map((result) => {
    const { shortId } = result;
    const cellValues = result.cellValues.map(({ cellValue }) => cellValue);
    return [shortId, ...cellValues].join(" | ");
  });

  const identifierMap = new Map<string, string>(
    zip(recordingIds, recordingIdentifiers) as [string, string][],
  );

  // Forgive me, this is the most type-safe implementation I could think of
  // with the time given
  return {
    ...data,
    project: data.project && {
      ...data.project,
      datasets: {
        ...data.project.datasets,
        nodes: data.project.datasets.nodes.map((dataset) => ({
          ...dataset,
          recordingsTable: dataset.recordingsTable && {
            ...dataset.recordingsTable,
            recordingGroups: {
              ...dataset.recordingsTable.recordingGroups,
              nodes: dataset.recordingsTable.recordingGroups.nodes.map(
                (recording) => ({
                  ...recording,
                  identifier: identifierMap.get(recording.id),
                }),
              ),
            },
          },
        })),
      },
    },
  };
};

export type DataSelectorData = Awaited<
  ReturnType<typeof attachRecordingIdentifiers>
>;

/** Hook that fetches the data needed to render the DataSelector component */
export const useDataSelectorData = () => {
  const { project } = useProjectDataContext();
  const [data, setData] = useState<DataSelectorData>();
  const [error, setError] = useState<Error>();

  const { data: queryData, error: queryError } = useDataSelectorQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId: project.id,
    },
  });

  // After the initial data has been fetched, attach recording identifiers
  useEffect(() => {
    if (queryData !== undefined) {
      attachRecordingIdentifiers(queryData)
        .then((data) => setData(data))
        .catch((error) => {
          captureException(error);
          setError(error as Error);
        });
    }
  }, [queryData]);

  return { data, error: queryError ?? error };
};
