import assert from "assert";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { isDefined } from "utils/isDefined";

type ModalContextValue = {
  openModal: (modal: ReactNode) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

interface ModalProviderProps {
  children: ReactNode;
}

/** Context provider for mounting modals */
export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modal, setModal] = useState<ReactNode>(null);

  const openModal: ModalContextValue["openModal"] = useCallback(
    (modal) => setModal(modal),
    [],
  );

  const closeModal = useCallback(() => setModal(null), []);

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      <>
        {children}
        {modal}
      </>
    </ModalContext.Provider>
  );
};

/** Hook for consuming the {@link ModalContext} */
export const useModalContext = () => {
  const context = useContext(ModalContext);
  assert(isDefined(context), new ContextOutOfBoundsError("ModalContext"));
  return context;
};
