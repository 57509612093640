import { EuiCard, EuiLoadingChart } from "@inscopix/ideas-eui";

/** Component rendered when a file preview is being generated */
export const FilePreviewGenerating = () => {
  return (
    <EuiCard
      title={
        <>
          <EuiLoadingChart size="l" mono />
          &nbsp; Generating preview...
        </>
      }
      description="Preview is being generated. Please wait."
      display="plain"
      hasBorder
    />
  );
};
