/** @jsxImportSource @emotion/react */
import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPanel,
  EuiText,
} from "@inscopix/ideas-eui";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { EmptyTablePrompt } from "components/EmptyTablePrompt/EmptyTablePrompt";
import { useRoutes } from "hooks/useRoutes";
import { ButtonCreateDataset } from "components/ButtonCreateDataset/ButtonCreateDataset";
import { ButtonEditDataset } from "components/ButtonEditDataset/ButtonEditDataset";
import { ReactNode, useState } from "react";
import { DatasetVersionTable } from "../../FlyoutDatasetVersions/DatasetVersionTable";
import { Tooltip } from "../../Tooltip/Tooltip";
import { css } from "@emotion/react";
import { ProjectDataset } from "../../../pages/project/usePageProjectData";
import { isNonNullish } from "utils/isNonNullish";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

export const ProjectPanelDatasetTable = () => {
  const { project, datasets } = useProjectDataContext();

  return (
    <EuiPanel hasBorder={true} paddingSize={"l"}>
      <EuiFlexGroup direction={"column"} gutterSize={"s"}>
        <EuiFlexGroup alignItems={"center"}>
          <EuiFlexItem>
            <EuiText>
              <h3>Organize</h3>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <ButtonCreateDataset project={project} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>
            <p>
              Organize your uploaded files and analysis results into a version
              controlled dataset, where you can visually preview data and browse
              and edit associated metadata.
            </p>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          {datasets.length > 0 ? (
            <DatasetTable />
          ) : (
            <EmptyTablePrompt message="No datasets found" />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

const DatasetTable = () => {
  const { routeMap } = useRoutes();
  const { project, datasets } = useProjectDataContext();
  const currentTenant = useTenantContext((s) => s.currentTenant);

  /**
   * Expanding rows
   * Based on https://elastic.github.io/eui/#/tabular-content/tables#expanding-rows
   */
  const [datasetIdToExpandedRowMap, setDatasetIdToExpandedRowMap] = useState<
    Record<string, ReactNode>
  >({});

  const toggleDetails = (dataset: ProjectDataset) => {
    const datasetIdToExpandedRowMapValues = { ...datasetIdToExpandedRowMap };

    if (isNonNullish(datasetIdToExpandedRowMapValues[dataset.id])) {
      delete datasetIdToExpandedRowMapValues[dataset.id];
    } else {
      datasetIdToExpandedRowMapValues[dataset.id] =
        dataset.datasetVersions.length > 0 ? (
          <DatasetVersionTable
            dataset={dataset}
            datasetVersions={dataset.datasetVersions}
            project={project}
          />
        ) : (
          <EuiText>
            <p>No dataset versions found</p>
          </EuiText>
        );
    }
    setDatasetIdToExpandedRowMap(datasetIdToExpandedRowMapValues);
  };

  const columnName: EuiBasicTableColumn<ProjectDataset> = {
    name: "Datasets",
    width: "100%",
    render: (dataset: ProjectDataset) => {
      return (
        <EuiLink
          css={css`
            display: block;
            width: 100%;
            height: 100%;
          `}
          to={
            routeMap["DATASET"].dynamicPath({
              tenantKey: currentTenant.key,
              datasetId: dataset.id,
              projectKey: project.key,
            }).path
          }
          data-test-subj="analysisTableLink"
        >
          {dataset.name}
        </EuiLink>
      );
    },
  };

  const columnActions: EuiBasicTableColumn<ProjectDataset> = {
    width: "40px",
    render: (dataset: ProjectDataset) => {
      return <ButtonEditDataset iconType="gear" size="s" dataset={dataset} />;
    },
  };

  const columnExpand: EuiBasicTableColumn<ProjectDataset> = {
    align: "left",
    width: "40px",
    isExpander: true,
    render: (dataset: ProjectDataset) => {
      const itemIdToExpandedRowMapValues = { ...datasetIdToExpandedRowMap };
      if (dataset.datasetVersions.length === 0) {
        return null;
      }
      return (
        <Tooltip content={"View saved versions"} delayShow={500}>
          <EuiButtonIcon
            onClick={() => toggleDetails(dataset)}
            aria-label={
              isNonNullish(itemIdToExpandedRowMapValues[dataset.id])
                ? "Collapse"
                : "Expand"
            }
            iconType={
              isNonNullish(itemIdToExpandedRowMapValues[dataset.id])
                ? "arrowDown"
                : "arrowRight"
            }
          />
        </Tooltip>
      );
    },
  };

  const doAnyDatasetsHaveVersions = datasets.some(
    (dataset) => dataset.datasetVersions.length > 0,
  );
  const columns = [columnName, columnActions];
  if (doAnyDatasetsHaveVersions) {
    columns.unshift(columnExpand);
  }

  return (
    <EuiBasicTable<ProjectDataset>
      columns={columns}
      itemId="id"
      items={datasets}
      itemIdToExpandedRowMap={datasetIdToExpandedRowMap}
      isExpandable={true}
    />
  );
};
