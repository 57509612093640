import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { CroppingFrameSelectorProps } from "components/CroppingFrameSelector/CroppingFrameSelector";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";
import { ModalToolCroppingFrameParamProps } from "../ModalToolCroppingFrameParam/ModalToolCroppingFrameParam";
import { ToolParamsGridRowDatum } from "../ToolParamsGrid.types";

export type ButtonToolCroppingFrameParamProps = Pick<
  CroppingFrameSelectorProps,
  "initialCroppingCoordinates" | "isInvalidInitialCoordinates"
> &
  Pick<ModalToolCroppingFrameParamProps, "toolParam"> & {
    rowId: ToolParamsGridRowDatum["id"];
  };
export const ButtonToolCroppingFrameParam = ({
  initialCroppingCoordinates,
  isInvalidInitialCoordinates,
  rowId,
  toolParam,
}: ButtonToolCroppingFrameParamProps) => {
  const { openModal } = useAnalysisTableLayoutContext();

  return (
    <EuiButtonIcon
      aria-label="Open cropping frame editor"
      iconType="namespace"
      style={{ marginRight: 10 }}
      color="primary"
      onClick={() =>
        openModal({
          type: "toolCroppingFrameParam",
          props: {
            initialCroppingCoordinates,
            isInvalidInitialCoordinates,
            rowId,
            toolParam,
          },
        })
      }
    />
  );
};
