/** @jsxImportSource @emotion/react */

import { EuiCallOut, EuiLink, EuiSpacer, EuiText } from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import assert from "assert";

import { isNonNullish } from "utils/isNonNullish";
import { sleep } from "utils/sleep";
import {
  ToolParamsGridRowDatum,
  ToolPathParam,
  ToolRoiFrameParam,
} from "../ToolParamsGrid.types";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { ModalToolRoiFrameParamWrapper } from "./ModalToolRoiFrameParamWrapper";

interface ModalToolRoiFrameParamNoSourceFileProps {
  toolParam: ToolRoiFrameParam;
  sourceFileParam: ToolPathParam;
  onClose: () => void;
  rowDatum: ToolParamsGridRowDatum;
  readOnly?: boolean;
}

export const ModalToolRoiFrameParamNoSourceFile = ({
  toolParam,
  sourceFileParam,
  onClose,
  rowDatum,
  readOnly,
}: ModalToolRoiFrameParamNoSourceFileProps) => {
  const { gridApi } = useToolParamsGridContext();

  return (
    <ModalToolRoiFrameParamWrapper
      toolParam={toolParam}
      onClose={onClose}
      maxWidth="600px"
      readOnly={readOnly}
    >
      {!readOnly && (
        <>
          <EuiCallOut>
            <EuiText>
              <p>
                To view/edit ROIs you must first select an input file of the
                appropriate type in the{" "}
                <EuiLink
                  onClick={() =>
                    void (async () => {
                      try {
                        onClose();
                        gridApi?.ensureColumnVisible(
                          sourceFileParam.key,
                          "start",
                        );
                        // Wait a tick for the scroll to complete
                        await sleep(0);

                        const rowNode = gridApi?.getRowNode(rowDatum.id);

                        assert(isNonNullish(rowNode));
                        gridApi?.flashCells({
                          rowNodes: [rowNode],
                          columns: [sourceFileParam.key],
                        });
                      } catch (err) {
                        captureException(err);
                        // TODO: investigate the cause of this error
                        // https://github.com/ag-grid/ag-grid/issues/5085
                      }
                    })()
                  }
                >
                  {sourceFileParam.name}
                </EuiLink>{" "}
                column.
              </p>
            </EuiText>
          </EuiCallOut>
          <EuiSpacer />
        </>
      )}
    </ModalToolRoiFrameParamWrapper>
  );
};
