import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";
import { ModalToolCellStatusParamProps } from "../ModalToolCellStatusParam/ModalToolCellStatusParam";
import { CellStatusEditorProps } from "components/CellStatusEditor/CellStatusEditor";
import { CellStatuses } from "components/CellStatusEditor/CellStatusEditor.types";
import { ToolParamsGridRowDatum } from "../ToolParamsGrid.types";

export type ButtonToolCellStatusParamProps = {
  initialCellStatuses?: CellStatuses;
  rowId: ToolParamsGridRowDatum["id"];
} & Pick<CellStatusEditorProps, "isInvalidInitialCellStatuses"> &
  Pick<ModalToolCellStatusParamProps, "toolParam">;
export const ButtonToolCellStatusParam = ({
  initialCellStatuses,
  isInvalidInitialCellStatuses,
  toolParam,
  rowId,
}: ButtonToolCellStatusParamProps) => {
  const { openModal } = useAnalysisTableLayoutContext();

  return (
    <EuiButtonIcon
      aria-label="Open cell status editor"
      iconType="namespace"
      style={{ marginRight: 10 }}
      color="primary"
      onClick={() =>
        openModal({
          type: "toolCellStatusParam",
          props: {
            rowId,
            initialCellStatuses,
            isInvalidInitialCellStatuses,
            toolParam,
          },
        })
      }
    />
  );
};
