import { css } from "@emotion/react";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";

export const useDatasetStyles = () => {
  const { bottomFlyout, rightFlyout } = useDatasetLayoutContext();

  const minContainerHeight = (() => {
    let height = 300;
    height += bottomFlyout?.height.value ?? 0;
    return height;
  })();

  const styles = {
    root: css`
      height: 100%;
      min-width: 650px;
    `,
    gridAndFlyoutsContainer: css`
      overflow: auto;
    `,
    gridAndBottomFlyoutContainer: css`
      min-width: 0;
      flex: 10 0 600px;
      min-height: ${minContainerHeight}px;
    `,
    bottomFlyoutContainer: css`
      padding-top: 2px;
      margin-bottom: 2px;
      height: ${bottomFlyout?.height.value}px;
    `,
    rightFlyoutContainer: css`
      margin-left: 2px;
      margin-right: 2px;
      padding-bottom: 2px;
      height: 100%;
      min-height: ${minContainerHeight}px;
      width: ${rightFlyout?.width.value}px;
      flex-shrink: 0;
    `,
  };

  return styles;
};
