import {
  EuiFormRow,
  EuiSwitch,
  EuiSwitchEvent,
  useGeneratedHtmlId,
} from "@inscopix/ideas-eui";
import { useState } from "react";

export const useShowHiddenAnalysisTables = (initialValue = false) => {
  const showHiddenTablesHTMLId = useGeneratedHtmlId({
    prefix: "showHiddenTablesHTMLID",
  });
  const [showHiddenTables, setShowHiddenTables] = useState(initialValue);

  const handleShowHiddenTables = (e: EuiSwitchEvent) => {
    setShowHiddenTables(e.target.checked);
  };

  const showHiddenTableSwitch = (
    <EuiFormRow
      display="columnCompressedSwitch"
      label={<span id={showHiddenTablesHTMLId}>Show hidden</span>}
    >
      <EuiSwitch
        data-test-subj="show-hidden-tables"
        showLabel={false}
        checked={showHiddenTables}
        onChange={handleShowHiddenTables}
        aria-describedby={showHiddenTablesHTMLId}
        compressed
        label="Show hidden"
      />
    </EuiFormRow>
  );

  return {
    showHiddenTables,
    showHiddenTableSwitch,
  };
};
