/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIconTip,
  EuiPopover,
  EuiSwitch,
} from "@inscopix/ideas-eui";
import { css } from "@emotion/react";
import { useToolParamsGridContext } from "./ToolParamsGridProvider";
import { isToolPathParam } from "./ToolParamsGrid.helpers";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { captureException } from "@sentry/react";
import { AnalysisTableIdentifiers } from "./ToolParamsGrid.types";
import { isEqual, sortBy } from "lodash";
import { useUpdateAnalysisTableByIdMutation } from "graphql/_Types";
import { parseAnalysisTableIdentifiers } from "components/AnalysisTableRowIdentifier/AnalysisTableRowIdentifier";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";

const styles = {
  contextMenu: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 4px;
  `,
  switch: css`
    min-height: 10px;
    padding: 4px;
  `,
  helpTooltipContainer: css`
    align-items: flex-end;
  `,
};

export const ToolParamsGridIdentifierHeader = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { toolSpec, analysisTable } = useToolParamsGridContext();
  const identifiers = parseAnalysisTableIdentifiers(analysisTable.identifiers);

  const [selectedKeys, setSelectedKeys] =
    useState<AnalysisTableIdentifiers>(identifiers);
  const [updateTable] = useUpdateAnalysisTableByIdMutation();

  // other allow non-path params to be used as identifiers
  const params = toolSpec.params.filter((param) => !isToolPathParam(param));
  // store the order of param keys from the tool spec
  const orderedParamKeys = params.map(({ key }) => key);

  // task information like the start date should be listed first
  // currently, this is limited to the start date only but task information may be added later
  const taskOptions = [
    {
      name: "Task Start Date",
      key: "task_date_created",
      isSelected: selectedKeys.includes("task_date_created"),
    },
  ];

  const paramOptions = params
    .filter((param) => !isToolPathParam(param))
    .map((param) => ({
      name: param.name,
      key: param.key,
      isSelected: selectedKeys.includes(param.key),
    }));

  const options = [...taskOptions, ...paramOptions];

  const handleToggle = (key: string) => {
    // remove the key if it already is in the selected keys list
    if (selectedKeys.includes(key)) {
      setSelectedKeys(selectedKeys.filter((identifier) => key !== identifier));
    } else {
      // otherwise, add it and sort the keys based on the tool spec
      setSelectedKeys(
        [...selectedKeys, key].sort(
          (a, b) => orderedParamKeys.indexOf(a) - orderedParamKeys.indexOf(b),
        ),
      );
    }
  };

  const updateTableIdentifiers = useCallback(
    async (identifiers: string[]) => {
      try {
        await updateTable({
          variables: {
            id: analysisTable.id,
            patch: {
              identifiers: identifiers,
            },
          },
        });
      } catch (err) {
        captureException(err);
        addUtilityToastFailure("Failed to save task identifier change");
      }
    },
    [analysisTable.id, updateTable],
  );

  // when selected keys are changed, update the analysis table
  useEffect(() => {
    if (!isEqual(sortBy(selectedKeys), sortBy(identifiers))) {
      void updateTableIdentifiers(selectedKeys);
    }
  }, [identifiers, selectedKeys, updateTableIdentifiers]);

  const toggles = options.map((option) => {
    return (
      <EuiSwitch
        key={option.key}
        css={styles.switch}
        label={option.name}
        checked={option.isSelected}
        onChange={(e) => handleToggle(option.key)}
        aria-describedby={option.key}
        compressed
      />
    );
  });

  return (
    <EuiFlexGroup gutterSize={"none"} alignItems={"center"} responsive={false}>
      <EuiFlexItem grow={false}>
        <span>Task ID</span>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiPopover
          button={
            <ButtonIconPermissioned
              iconType="pencil"
              aria-label="Edit Task ID"
              onClick={() => setIsPopoverOpen((isOpen) => !isOpen)}
              requiredPermission="edit"
            />
          }
          isOpen={isPopoverOpen}
          closePopover={() => setIsPopoverOpen(false)}
          anchorPosition="downLeft"
          panelPaddingSize="none"
          ownFocus={false}
        >
          <div css={styles.contextMenu}>{toggles}</div>
        </EuiPopover>
      </EuiFlexItem>
      <EuiFlexItem css={styles.helpTooltipContainer}>
        <EuiIconTip
          content="Select parameters to use as identifiers for this task. Defaults to show the time the task was started."
          position="right"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
