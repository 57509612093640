/** @jsxImportSource @emotion/react */
import { memo } from "react";
import { ToolParamsGridRowDatum } from "./ToolParamsGrid.types";
import { isDefined } from "utils/isDefined";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { css } from "@emotion/react";
import { AutofillFromRecording } from "./AutofillFromRecording/AutofillFromRecording";
import { uniq } from "lodash";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  `,
  badgeContainer: css`
    align-items: center;
    display: flex;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
  `,
};

type GridRendererRecordingProps = { data: ToolParamsGridRowDatum };

export const GridRendererRecording = memo(function GridRendererRecording({
  data,
}: GridRendererRecordingProps) {
  const isRowExecuted = data?.task_id !== undefined;
  const recordingIds = uniq(data?.recordings ?? []);

  return (
    <div css={styles.root}>
      <div css={styles.badgeContainer}>
        {recordingIds.map((recordingId) => (
          <RecordingIdentifierBadge
            key={recordingId}
            recordingId={recordingId}
            showShortIdOnly={recordingIds.length > 1}
          />
        ))}
      </div>
      {!isRowExecuted && isDefined(data) && (
        <AutofillFromRecording rowDatum={data} />
      )}
    </div>
  );
});
