import { EuiComboBox, EuiComboBoxProps } from "@inscopix/ideas-eui";
import { withUserPermission } from "hocs/withUserPermission";

/**
 * {@link EuiComboBox} represented as a function component. This is necessary
 * because TypeScript only supports propagating generic type arguments with
 * functions, not classes.
 */
const EuiComboBoxFC = <T,>(props: EuiComboBoxProps<T>) => (
  <EuiComboBox {...props} />
);

/**
 * Enhanced {@link EuiComboBox} that is disabled when the user lacks a
 * required permission
 */
export const ComboBoxPermissioned = withUserPermission(EuiComboBoxFC);
