/** @jsxImportSource @emotion/react */

import { EuiBadge } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { groupBy } from "lodash";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { ToolRoiFrameParam } from "../ToolParamsGrid.types";

export interface GridRendererToolRoiFrameParamShapesProps {
  shapes: ShapeJson[];
  toolParam: ToolRoiFrameParam;
}
export const GridRendererToolRoiFrameParamShapes = ({
  shapes,
  toolParam,
}: GridRendererToolRoiFrameParamShapesProps) => {
  const boundingBox = shapes.find(
    (shape): shape is Extract<ShapeJson, { type: "boundingBox" }> =>
      shape.type === "boundingBox",
  );

  const rois = shapes.filter((shape) => shape.type !== "boundingBox");
  const roiCount = rois.length;

  const roiGroups = groupBy(rois, (roi) => roi.groupKey);

  return (
    <>
      {boundingBox && (
        <Tooltip
          content={
            <div>
              <div>{boundingBox.name}</div>
              <div>left: {Math.round(boundingBox.left)}</div>
              <div>top: {Math.round(boundingBox.top)}</div>
              <div>width: {Math.round(boundingBox.width)}</div>
              <div>height: {Math.round(boundingBox.height)}</div>
            </div>
          }
        >
          <EuiBadge
            // suppress default tooltip
            title=""
          >
            {Math.round(boundingBox.left)},{Math.round(boundingBox.top)},
            {Math.round(boundingBox.width)},{Math.round(boundingBox.height)}
          </EuiBadge>
        </Tooltip>
      )}
      {roiCount > 0 && (
        <Tooltip
          content={
            <div>
              {Object.keys(roiGroups).map((groupKey) => {
                const groupName = toolParam.type.roi_settings.groups?.find(
                  (group) => group.key === groupKey,
                )?.name;
                const groupCount = roiGroups[groupKey].length;

                if (groupName === undefined) {
                  return null;
                }

                return (
                  <div key={groupKey}>
                    {groupName}: {groupCount} ROIs
                  </div>
                );
              })}
            </div>
          }
        >
          <EuiBadge
            // suppress default tooltip
            title=""
          >
            {roiCount} ROIs
          </EuiBadge>
        </Tooltip>
      )}
    </>
  );
};
