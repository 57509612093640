import {
  Dataset,
  GetAllDatasetsDocument,
  GetAllDatasetsQuery,
  GetAllDatasetsQueryVariables,
} from "graphql/_Types";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { DatasetStatus } from "types/constants";

/**
 * Gets a dataset with a specified prefix inside a project
 * @param attrs The dataset attributes
 * @return The ID of the matching dataset or `undefined` if no dataset exists
 * with the specified attributes
 * @throws An error if the query fails to execute
 */
export const getDatasetWithPrefix = async (
  attrs: Pick<Dataset, "prefix" | "projectId">,
) => {
  const { data } = await client.query<
    GetAllDatasetsQuery,
    GetAllDatasetsQueryVariables
  >({
    fetchPolicy: "network-only",
    query: GetAllDatasetsDocument,
    variables: {
      filter: {
        and: [
          {
            projectId: {
              equalTo: attrs.projectId,
            },
          },
          {
            prefix: {
              equalTo: attrs.prefix,
            },
          },
          {
            status: {
              notIn: [
                DatasetStatus["SCHEDULE_DELETE"],
                DatasetStatus["SCHEDULE_DATA_DELETE"],
                DatasetStatus["DATA_DELETED"],
              ],
            },
          },
        ],
      },
    },
  });

  if (data.datasets === null) {
    throw new Error("Failed to perform existence check");
  }

  const datasets = data.datasets.nodes;
  return datasets.length > 0 ? datasets[0].id : undefined;
};
