import { EuiSelect, EuiSelectOption } from "@inscopix/ideas-eui";
import { DandiServer, ModalDandiState } from "./ModalDandi.types";

interface ModalDandiFieldServerProps {
  fields: ModalDandiState;
  setField: <T extends keyof ModalDandiState>(
    field: T,
    value: ModalDandiState[T],
  ) => void;
}

/** Component that renders the server select field. */
export const ModalDandiFieldServer = ({
  fields,
  setField,
}: ModalDandiFieldServerProps) => {
  const options: EuiSelectOption[] = [
    {
      text: "Production",
      value: "dandi" satisfies DandiServer,
    },
    {
      text: "Staging",
      value: "dandi-staging" satisfies DandiServer,
    },
  ];

  return (
    <EuiSelect
      options={options}
      value={fields.server}
      onChange={(e) => {
        const server = e.target.value as ModalDandiState["server"];
        setField("server", server);
      }}
    />
  );
};
