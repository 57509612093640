import { Dataset } from "components/Dataset/Dataset";
import { useRoutes } from "hooks/useRoutes";
import { Page404 } from "pages/404/Page404";
import { DatasetLayoutProvider } from "pages/project/dataset/DatasetLayoutProvider";
import { Route, Switch } from "react-router-dom";
import { PageProjectDatasetHistory } from "./PageProjectDatasetHistory";

export const PageProjectDataset = () => {
  const { routeMap } = useRoutes();

  return (
    <DatasetLayoutProvider>
      <Switch>
        <Route path={routeMap["DATASET"].parameterizedPath} exact>
          <Dataset />
        </Route>
        <Route path={routeMap["DATASET_VERSION"].parameterizedPath} exact>
          <Dataset />
        </Route>
        <Route path={routeMap["DATASET_HISTORY"].parameterizedPath} exact>
          <PageProjectDatasetHistory />
        </Route>
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </DatasetLayoutProvider>
  );
};
