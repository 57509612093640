type PolarCoordinate = {
  r: number;
  theta: number;
};

/**
 * Converts a polar coordinate to a cartesian coordinate
 * @param coordinate A polar coordinate
 * @returns The cartesian coordinate
 */
export const polarToCartesian = (coordinate: PolarCoordinate) => {
  const { r, theta } = coordinate;
  const x = r * Math.cos(theta);
  const y = r * Math.sin(theta);
  return { x, y };
};
