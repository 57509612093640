/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalProps,
  EuiText,
  EuiTitle,
} from "@inscopix/ideas-eui";

export interface ModalToolCroppingFrameParamWrapperProps extends EuiModalProps {
  children: React.ReactNode;
  readOnly?: boolean;
}
export const ModalToolCroppingFrameParamWrapper = ({
  children,
  readOnly,
  ...props
}: ModalToolCroppingFrameParamWrapperProps) => {
  return (
    <EuiModal
      {...props}
      title={"Cropping Frame Selection" + (readOnly ? " (Read Only)" : "")}
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100vw;
        min-height: 100vh;
      `}
    >
      <EuiModalHeader>
        <EuiTitle>
          <EuiText>
            Cropping Frame Selection {readOnly && " (Read Only)"}
          </EuiText>
        </EuiTitle>
      </EuiModalHeader>
      <EuiModalBody>{children}</EuiModalBody>
    </EuiModal>
  );
};
