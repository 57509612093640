import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  InMemoryCacheConfig,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getSessionId } from "../../utils/getSessionId";
import { sessionIdExists } from "../../utils/sessionIdExists";
import { getEnvVar } from "../../ideas.env";
import { captureException } from "@sentry/react";
import { isDefined } from "utils/isDefined";
import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicy,
} from "@apollo/client/cache/inmemory/policies";
import { Except } from "type-fest";
import { ResolversParentTypes } from "graphql/_Types";
import { onlyNonNullishArgs } from "./ApolloProvider.helpers";
import { isEmpty } from "lodash";
import { ideasFeatures } from "ideas.features";
import { isNonNullish } from "utils/isNonNullish";
import { getRequestHeaders } from "utils/getRequestHeaders";

const httpLink = createHttpLink({
  uri: getEnvVar("URL_GRAPHQL"),
});

const authLink = setContext(async (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  let newHeaders: Record<string, unknown> = {
    ...(headers as Record<string, unknown>),
    ...(await getRequestHeaders()),
  };

  if (sessionIdExists()) {
    newHeaders = { ...newHeaders, "X-Session-Id": getSessionId() };
  }

  return {
    headers: {
      ...newHeaders,
    },
  };
});

/* Retry failed queries a fixed number of times with an exponential delay
   between attempts */

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error) => isDefined(error),
  },
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
});

/* Report errors to Sentry */

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors !== undefined) {
    graphQLErrors.forEach((error) => {
      captureException(`Apollo GraphQL Error: ${error.message}`, {
        extra: { ...error, operation },
      });
    });
  }

  if (isNonNullish(networkError)) {
    captureException(`Apollo Network Error: ${networkError.message}`, {
      extra: { ...networkError, operation },
    });
  }
});

/**
 * Link that prevents queries from executing if they were automatically
 * triggered as part of a partial refetch
 */
const partialRefetchLink = new ApolloLink((operation, forward) => {
  let completeCacheRecord: unknown;

  try {
    completeCacheRecord = client.cache.readQuery({
      query: operation.query,
      variables: operation.variables,
      returnPartialData: false,
    });
  } catch (error) {
    // Call the next link in the chain if we can't read the cache
    return forward(operation);
  }

  let partialCacheRecord: unknown;

  try {
    partialCacheRecord = client.cache.readQuery({
      query: operation.query,
      variables: operation.variables,
      returnPartialData: true,
    });
  } catch (error) {
    // Call the next link in the chain if we can't read the cache
    return forward(operation);
  }

  /* We know we have a partial refetch when we have some, but not all, data in
     the cache */
  const isPartialRefetch =
    /* When returnPartialData is false, readQuery returns null when there is a
       cache miss */
    completeCacheRecord === null &&
    /* When returnPartialData is true, readQuery returns an empty object when
       there is a cache miss */
    !isEmpty(partialCacheRecord);

  /* Prevent the next link in the chain from being called if the operation was
     automatically triggered as part of a partial refetch */
  return isPartialRefetch ? null : forward(operation);
});

const linkChain = from([
  // Enable feature flagging for the partial refetch link
  ...(ideasFeatures.partialRefetchLink ? [partialRefetchLink] : []),
  errorLink,
  retryLink,
  authLink,
  httpLink,
]);

/**
 * Represents a narrower version of {@link InMemoryCacheConfig}
 */
type IdeasCacheConfig = Except<InMemoryCacheConfig, "typePolicies"> & {
  typePolicies?: {
    [T in keyof ResolversParentTypes]?: Except<TypePolicy, "fields"> & {
      fields?: {
        [U in keyof ResolversParentTypes[T]]?: FieldPolicy | FieldReadFunction;
      };
    };
  };
};

export const client = new ApolloClient({
  link: linkChain,
  cache: new InMemoryCache({
    typePolicies: {
      AnalysisTableRow: {
        fields: {
          activeAttachResults: {
            keyArgs: onlyNonNullishArgs,
          },
        },
      },
      DatasetRecordingsTable: {
        fields: {
          activeColumns: {
            keyArgs: onlyNonNullishArgs,
          },
        },
      },
      DatasetRecordingsTableColumn: {
        fields: {
          activeColDef: {
            keyArgs: onlyNonNullishArgs,
          },
          activeOrder: {
            keyArgs: onlyNonNullishArgs,
          },
        },
      },
      File: {
        fields: {
          activeAssignment: {
            keyArgs: onlyNonNullishArgs,
          },
          /* ATTENTION: This forces all cache updates to this field, regardless
             of any variables used, to resolve to the same field in the cache.
             This is intended purely as a temporary fix to prevent the 
             ProjectFilesManagerQuery from refetching and overwriting optimistically
             cached data. Please see associated PR for further details. */
          fileMetadataByFileId: {
            keyArgs: false,
          },
        },
      },
      Project: {
        fields: {
          activeFiles: {
            keyArgs: onlyNonNullishArgs,
          },
        },
      },
    },
  } satisfies IdeasCacheConfig),
});

interface GraphQlProviderProps {
  children: React.ReactNode;
}

function GraphQlProvider(props: GraphQlProviderProps) {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default GraphQlProvider;
