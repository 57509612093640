import { useCallback, useMemo, useRef } from "react";
import { MetadataColumn } from "../RecordingsGrid.helpers";
import { ColDef, IHeaderParams } from "ag-grid-community";
import { RecordingsGridRowDatum } from "../RecordingsGrid.types";
import { GridRendererMetadata } from "../GridRendererMetadata";
import { RecordingsGridColumnHeader } from "../headers/RecordingsGridColumnHeader";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { debounce } from "lodash";
import { JsonValue } from "type-fest";
import { CreateRecordingMetadataParams } from "hooks/useDatasetAction/factories/useActionCreateRecordingMetadataValues";
import { uuid } from "utils/uuid";

/**
 * Hook for creating metadata column definitions
 */
export const useParseMetadataColumn = () => {
  const createMetadataValuesAction = useDatasetAction(
    "createRecordingMetadataValues",
  );
  // Buffer of metadata values waiting to be persisted
  const bufferRef = useRef<CreateRecordingMetadataParams>([]);

  /**
   * Persists the metadata values stored in the buffer.
   * This function is debounced to batch multiple successive value changes into
   * a single network request.
   */
  const persistBufferedValues = useMemo(() => {
    return debounce(() => {
      const buffer = bufferRef.current;
      bufferRef.current = []; // Reset buffer
      void createMetadataValuesAction.enqueue(buffer);
    }, 1000);
  }, [createMetadataValuesAction]);

  /**
   * Creates a column definition object for a custom recording metadata column
   * @param column
   * @returns The column definition
   */
  const parseMetadataColumn = useCallback(
    (
      column: MetadataColumn<{
        pinned: boolean;
        width: number;
      }>,
    ) => {
      /**
       * Gets a metadatum with column's key from row data
       * @param data
       * @returns The metadatum if it exists or `undefined` otherwise
       */
      const getMetadatum = (data: RecordingsGridRowDatum | undefined) => {
        const { metadataKey } = column.colDef;
        const metadata = data?.recording.metadata.nodes;
        const metadatum = metadata?.find(({ key }) => key === metadataKey);
        return metadatum;
      };

      /**
       * Adds a metadatum with column's key to the row data
       * @param data
       * @returns The new metadatum
       */
      const createMetadatum = (data: RecordingsGridRowDatum) => {
        const metadatum = {
          __typename: "Metadatum" as const,
          id: uuid(),
          key: column.colDef.metadataKey,
          displayName: column.colDef.headerName,
          tenantId: 0,
          value: undefined,
        };
        const metadata = data.recording.metadata.nodes;
        metadata.push(metadatum);
        return metadatum;
      };

      return {
        cellRenderer: GridRendererMetadata,
        colId: column.id,
        editable: !createMetadataValuesAction.isDisabled,
        headerComponent: (params: IHeaderParams) => (
          <RecordingsGridColumnHeader {...params} column={column} />
        ),
        headerName: column.colDef.headerName,
        pinned: column.pinned,
        valueGetter: ({ data }) => {
          const metadatum = getMetadatum(data);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return metadatum?.value;
        },
        valueSetter: ({ data, newValue }) => {
          /* Update the row data the new value. This is necessary because
             updating only the cache will cause the cell renderer to briefly
             display the old value until the col def is rerendered with the new
             row data. This allows the new value to appear instantly. */
          const metadatum = getMetadatum(data) ?? createMetadatum(data);
          metadatum.value = newValue as JsonValue;

          // Add new metadata value details to buffer
          bufferRef.current.push({
            dateCreated: new Date().toISOString(),
            recordingId: data.recording.id,
            metadataKey: column.colDef.metadataKey,
            value: newValue as JsonValue,
          });

          // Request value to be persisted
          persistBufferedValues();

          return true;
        },
        width: column.width,
      } satisfies ColDef<RecordingsGridRowDatum>;
    },
    [createMetadataValuesAction.isDisabled, persistBufferedValues],
  );

  return { parseMetadataColumn };
};
