import { captureException } from "@sentry/react";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { File } from "graphql/_Types";
import useGetFileDownloadUrl from "hooks/useGetFileDownloadUrl";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useState } from "react";
import { DRS_FILE_ACTION_ICONS } from "types/Icons";
import { QUOTA_TYPES_BY_KEY } from "types/QuotaType";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { downloadFile } from "utils/downloadFile";

interface ButtonDownloadFileProps {
  file: Pick<File, "id" | "name" | "status">;
}

export const ButtonDownloadFile = ({ file }: ButtonDownloadFileProps) => {
  const { getFileDownloadUrl } = useGetFileDownloadUrl();
  const [loading, setLoading] = useState(false);
  const checkTenantQuota = useUserContext((s) => s.checkTenantQuota);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const initiateDownload = async () => {
    setLoading(true);
    try {
      const url = await getFileDownloadUrl({ id: file.id });
      downloadFile(url, file.name);
      addUtilityToastSuccess("Successfully queued download");
    } catch (err) {
      addUtilityToastFailure("Failed to download file");
      const quotaType = QUOTA_TYPES_BY_KEY["network"];
      void checkTenantQuota(currentTenant.id, quotaType);
      captureException(err);
    }
    setLoading(false);
  };

  return (
    <ButtonIconPermissioned
      aria-label="Download file"
      iconType={DRS_FILE_ACTION_ICONS["download"]}
      onClick={() => void initiateDownload()}
      isLoading={loading}
      requiredPermission="download"
      defaultTooltip="Download File"
    />
  );
};
