import { EuiButton } from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { downloadFile } from "utils/downloadFile";

export interface ButtonDownloadTaskLogProps {
  downloadUrl: string;
  downloadFileName: string;
}
export const ButtonDownloadTaskLog = ({
  downloadUrl,
  downloadFileName,
}: ButtonDownloadTaskLogProps) => {
  const [loading, setLoading] = useState(false);

  const downloadTaskLogs = () => {
    setLoading(true);
    try {
      downloadFile(downloadUrl, downloadFileName);
    } catch (err) {
      captureException(err);
      addUtilityToastFailure(`Failed to download log for ${downloadFileName}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EuiButton
      iconType="download"
      isLoading={loading}
      onClick={() => void downloadTaskLogs()}
    >
      Download {downloadFileName}
    </EuiButton>
  );
};
