import axios from "axios";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";

export type DatasetRecordingsTableColumnPinInput = {
  date_pinned: string;
};

type DatasetRecordingsTableColumnPinDjangoResponse = {
  data: {
    id: string;
  };
};

export const useDatasetRecordingsTableColumnPinDjango = () => {
  const pinColumn = useCallback(
    async (columnId: string, input: DatasetRecordingsTableColumnPinInput) => {
      const url = `${getEnvVar(
        "URL_DRS_DATASET_RECORDINGS_TABLE_COLUMN_PIN",
      )}${columnId}/`;
      const headers = await getRequestHeaders();
      const res = await axios.patch<
        DatasetRecordingsTableColumnPinInput,
        DatasetRecordingsTableColumnPinDjangoResponse
      >(url, input, { headers });

      return res.data;
    },
    [],
  );

  return { pinColumn };
};
