import assert from "assert";
import axios from "axios";
import { File as DrsFile } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { FileStatus } from "types/constants";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type UnarchiveDrsFileDjangoResponse = {
  data: {
    id: DrsFile["id"];
    status: DrsFile["status"];
  };
};

export const useDrsFileUnarchiveDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);

  const drsFileUnarchive = async (drsFileId: DrsFile["id"]) => {
    const url = `${getEnvVar("URL_DRS_FILE_UNARCHIVE")}${drsFileId}/`;
    const body = { user: currentUser.id };
    const headers = await getRequestHeaders();
    const res = await axios.patch<never, UnarchiveDrsFileDjangoResponse>(
      url,
      body,
      { headers },
    );
    const { status } = res.data;

    assert(
      status === FileStatus["UNARCHIVED"],
      "Received incorrect object status from delete request",
    );
    return res.data;
  };

  return { drsFileUnarchive };
};
