import assert from "assert";
import axios from "axios";
import { DatasetRecordingsTableColumn } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type CreateRecordingGroupBulkInput = {
  id: string;
  date_created: string;
  dataset_recordings_table: string;
  name: {
    key: "fileRecordingGroup.name";
    display_name: "Name";
    id: string;
    values: { id: string; value: string }[];
  };
}[];

type createRecordingGroupBulkDjangoResponse = {
  data: {
    id: string;
    dataset_recordings_table: string;
    date_created: string;
    name: {
      id: string;
      key: "fileRecordingGroup.name";
      display_name: "Name";
    };
    tenant: number;
  }[];
};

export type CreateRecordingGroupBulkResult = Pick<
  DatasetRecordingsTableColumn,
  "id"
>[];

export const useCreateRecordingGroupBulkDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { project } = useProjectDataContext();

  const createRecordingGroupBulkDjango = async (
    input: CreateRecordingGroupBulkInput,
  ): Promise<CreateRecordingGroupBulkResult> => {
    input.forEach(({ name }) =>
      assert(
        name.values.length === 1,
        "Expected 1 name metadatum when creating recording group",
      ),
    );
    const body = input.map((recording) => ({
      ...recording,
      tenant: currentTenant.id,
      user: currentUser.id,
      project: project.id,
    }));
    const url = getEnvVar("URL_DRS_FILE_RECORDING_GROUP_CREATE_BULK");
    const headers = await getRequestHeaders();
    const response = await axios.post<
      CreateRecordingGroupBulkInput,
      createRecordingGroupBulkDjangoResponse
    >(url, body, {
      headers,
    });

    const ids = response.data.map(({ id }) => ({ id }));

    return ids;
  };
  return { createRecordingGroupBulkDjango };
};
