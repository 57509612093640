import { keyBy } from "lodash";

/***********
  BASE TYPE
 ***********/

export type FileStructure =
  (typeof FILE_STRUCTURES)[keyof typeof FILE_STRUCTURES];

/************************
  FILE STRUCTURE DEFINITIONS
 ************************/

const TIME_SERIES = {
  id: 1,
  key: "time_series",
  name: "Time series",
} as const;

const SPARSE_TIME_SERIES = {
  id: 2,
  key: "sparse_time_series",
  name: "Sparse time series",
} as const;

const TABLE = {
  id: 3,
  key: "table",
  name: "Table",
} as const;

const SCALAR = {
  id: 4,
  key: "scalar",
  name: "Scalar",
} as const;

const IMAGE = {
  id: 5,
  key: "image",
  name: "Image",
} as const;

const IMAGE_STACK = {
  id: 6,
  key: "image_stack",
  name: "Image stack",
} as const;

const MOVIE = {
  id: 7,
  key: "movie",
  name: "Movie",
} as const;

const KEY_VALUE_PAIRS = {
  id: 8,
  key: "key_value_pairs",
  name: "Key-value pairs",
} as const;

const ARCHIVE = {
  id: 9,
  key: "archive",
  name: "Archive",
} as const;

const BINARY = {
  id: 10,
  key: "binary",
  name: "Binary",
} as const;

const UNKNOWN = {
  id: 11,
  key: "unknown",
  name: "Unknown",
} as const;

const FILE_STRUCTURES = {
  TIME_SERIES,
  SPARSE_TIME_SERIES,
  TABLE,
  SCALAR,
  IMAGE,
  IMAGE_STACK,
  MOVIE,
  KEY_VALUE_PAIRS,
  ARCHIVE,
  BINARY,
  UNKNOWN,
} as const;

/*******************
  AGGREGATED EXPORTS
 *******************/

export const FILE_STRUCTURES_BY_ID = keyBy(
  FILE_STRUCTURES,
  (fileStructure) => fileStructure.id,
) as {
  [id in FileStructure["id"]]: FileStructure;
} & {
  [unknown: number]: FileStructure | undefined;
};

export const FILE_STRUCTURES_BY_KEY = keyBy(
  FILE_STRUCTURES,
  (fileStructure) => fileStructure.key,
) as {
  [key in FileStructure["key"]]: FileStructure;
} & {
  [unknown: string]: FileStructure | undefined;
} & {
  [unknown: number | symbol]: undefined;
};
