import pDebounce from "p-debounce";
import { DandiServer, ModalDandiState } from "./ModalDandi.types";
import axios from "axios";
import { SetNonNullable } from "type-fest";

/**
 * Represents the state of all the DANDI form fields with values valid for
 * submission
 */
type ModalDandiValidFieldState = SetNonNullable<
  ModalDandiState,
  "dandisetId" | "dataColumnId"
>;

/**
 * Type guard asserts whether the specified fields are valid for submission.
 * @param fields
 * @returns `true` if the fields are valid, `false` otherwise.
 */
export const isFieldStateValid = (
  fields: ModalDandiState,
): fields is ModalDandiValidFieldState => {
  return (
    fields.apiKey !== "" &&
    fields.dandisetId !== "" &&
    fields.dataColumnId !== "" &&
    fields.files.length > 0
  );
};

/**
 * Determines which base URL to use to access a specified DANDI server.
 * @param server The DANDI server.
 * @returns The DANDI server's API base URL.
 */
const getBaseUrl = (server: DandiServer) => {
  return server === "dandi-staging"
    ? "https://api-staging.dandiarchive.org/api"
    : "https://api.dandiarchive.org/api";
};

/**
 * Gets the request headers needed by the DANDI API.
 * @param apiKey The API key of the user.
 * @returns The request headers.
 */
const getHeaders = (apiKey: string) => {
  return {
    authorization: `token ${apiKey}`,
  };
};

/**
 * Determines whether an API key is valid for a specified DANDI server.
 * @param apiKey The API key of the user.
 * @param server The DANDI server.
 * @returns `true` if the API key is valid, `false` otherwise.
 */
export const isApiKeyValid = async (apiKey: string, server: DandiServer) => {
  const baseUrl = getBaseUrl(server);
  const url = `${baseUrl}/auth/token/`;
  const headers = getHeaders(apiKey);

  try {
    await axios.get<string>(url, { headers });
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Gets the dandisets owned by a specified user.
 * @param apiKey The API key of the user.
 * @param server The DANDI server.
 * @returns A list of dandisets the user owns.
 * @throws An error if the API key is invalid.
 */
export const getDandisets = pDebounce(
  async (apiKey: string, server: DandiServer) => {
    const baseUrl = getBaseUrl(server);
    const url = `${baseUrl}/dandisets/`;
    const headers = getHeaders(apiKey);
    const params = { ordering: "id", user: "me" };

    try {
      const { data } = await axios.get<{
        count: number;
        next: string | null;
        previous: string | null;
        results: {
          identifier: string;
          created: string;
          modified: string;
          contact_person: string;
          embargo_status: string;
          draft_version?: {
            asset_count: number;
            created: string;
            modified: string;
            name: string;
            size: number;
            status: string;
            version: string;
          };
          most_recent_published_version?: {
            asset_count: number;
            created: string;
            modified: string;
            name: string;
            size: number;
            status: string;
            version: string;
          };
        }[];
      }>(url, { headers, params });
      return data.results;
    } catch (error) {
      return [];
    }
  },
  500,
);

/**
 * Gets the URL of a specified dandiset
 * @param dandisetId The ID of the dandiset.
 * @param server The server where the dandiset is located.
 * @returns The URL of the dandiset.
 */
export const getDandisetUrl = (dandisetId: string, server: DandiServer) => {
  return server === "dandi-staging"
    ? `https://gui-staging.dandiarchive.org/dandiset/${dandisetId}`
    : `https://dandiarchive.org/dandiset/${dandisetId}`;
};
