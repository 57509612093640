import { DragOverlay, useDndContext } from "@dnd-kit/core";
import { captureException } from "@sentry/react";
import { FileBadge } from "components/FileBadge/FileBadge";
import { useMemo } from "react";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { parseDatasetDraggableId } from "./DatasetDndProvider";

export const DatasetDragOverlay = () => {
  const { active } = useDndContext();
  const files = useProjectFilesStore((s) => s.files);
  const dragOverlay = useMemo(() => {
    if (active !== null) {
      const fileId = parseDatasetDraggableId(active.id as string).drsFile.id;
      const file = files.find((file) => file.id === fileId);
      if (file !== undefined) {
        return (
          <FileBadge
            drsFile={file}
            isMisplaced={false}
            suppressTooltip={true}
          />
        );
      } else {
        captureException(
          "File from draggable data not found in project files manager",
          {
            extra: {
              activeId: active.id,
            },
          },
        );
      }
    }
  }, [active, files]);

  return (
    <DragOverlay
      dropAnimation={null}
      style={{ width: undefined, height: undefined, cursor: "grabbing" }}
    >
      {dragOverlay}
    </DragOverlay>
  );
};
