import { getEnvVar } from "ideas.env";
import { File } from "../graphql/_Types";
import axios from "axios";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { useCallback } from "react";

type DrsFileDownloadDjangoResponse = {
  url: string;
};

/**
 * A hook for fetching the download URL for a data object
 * @returns A function to trigger the fetch
 */
export default function useGetFileDownloadUrl() {
  /**
   * Gets the pre-signed download URL for the DRS object's source file
   * @returns The pre-signed download URL or `undefined` if an error occurs
   */
  const getFileDownloadUrl = useCallback(async ({ id }: Pick<File, "id">) => {
    const endpoint = `${getEnvVar("URL_DRS_FILE_DOWNLOAD")}${id}/`;
    const headers = await getRequestHeaders();
    const { data } = await axios.get<DrsFileDownloadDjangoResponse>(endpoint, {
      headers,
    });
    return data.url;
  }, []);

  return { getFileDownloadUrl };
}
