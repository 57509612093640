import { gql } from "@apollo/client";

/**
 * The subset of user fields returned by queries and mutations
 */

export const USER_FIELDS = gql`
  fragment UserFields on ApplicationUser {
    id
    username
    firstName
    lastName
    email
    internal
    isActive
  }
`;
