import ErrorBoundaryFallback from "./ErrorBoundaryFallback/ErrorBoundaryFallback";
import { FC } from "react";
import * as Sentry from "@sentry/react";

export const SentryErrorBoundary: FC<{ tag: string }> = ({ children, tag }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <ErrorBoundaryFallback
          error={error}
          resetErrorBoundary={() => {
            window.location.reload();
          }}
        />
      )}
      beforeCapture={(scope) => {
        scope.setTag("location", tag);
      }}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
