/** @jsxImportSource @emotion/react */
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
} from "@inscopix/ideas-eui";
import { CellStatusTable } from "./CellStatusTable";
import { css } from "@emotion/react";
import { CellStatusEditorPlots } from "./CellStatusEditorPlots";
import { useCellStatusContext } from "./CellStatusEditorProvider";
import { CellStatuses } from "./CellStatusEditor.types";
import { isDefined } from "utils/isDefined";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";

export interface CellStatusEditorProps {
  onAcceptStatuses: (statuses: CellStatuses) => void;
  onCancel: () => void;
  isInvalidInitialCellStatuses: boolean;
  isQcReportDataMatchingCellInfo?: boolean;
  qcReportError?: Error;
  readOnly?: boolean;
}

export const CellStatusEditor = ({
  onAcceptStatuses,
  isInvalidInitialCellStatuses,
  isQcReportDataMatchingCellInfo,
  qcReportError,
  onCancel,
  readOnly,
}: CellStatusEditorProps) => {
  const cellStatuses = useCellStatusContext((s) => s.cellStatuses);
  const resetCellStatuses = useCellStatusContext((s) => s.resetCellStatuses);
  const files = useCellStatusContext((s) => s.files);
  const hasQcReportFile = files.qcReportFile !== undefined;

  // we don't necesarily need a qc report to work, but should prominently display to the user
  // to encourage them to select one or warn them if they picked a mismatching one
  const mainSectionContent = (() => {
    if (hasQcReportFile) {
      if (isDefined(qcReportError)) {
        return (
          <EuiEmptyPrompt
            title={<h5>Failed to read QC report data</h5>}
            body={
              <p>
                Unable to extract valid images, cell metrics, and traces from
                the provided QC report.
              </p>
            }
          />
        );
      } else if (isQcReportDataMatchingCellInfo === false) {
        return (
          <EuiEmptyPrompt
            title={<h5>QC Report Mismatch</h5>}
            body={
              <p>
                The number of cells in the selected QC report input does not
                match the number of cells in the cell set. Please check to
                ensure the correct QC report corresponding to the cell set has
                been selected.
              </p>
            }
          />
        );
      } else {
        return <CellStatusEditorPlots />;
      }
    } else {
      return (
        <EuiEmptyPrompt
          title={<h5>No QC Report File</h5>}
          body={
            <p>
              Please select a valid QC report file input to view cell
              footprints, metrics, and traces.
            </p>
          }
        />
      );
    }
  })();

  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="xs"
      responsive={false}
      css={css`
        height: 100%;
        min-width: 760px;
      `}
    >
      {
        // if the saved cell statuses in the cell are invalid, we should warn the user
        // we'll use the ones from the cell set metadata instead
        isInvalidInitialCellStatuses && (
          <EuiFlexItem grow={false}>
            <EuiCallOut
              title="Saved cell statuses could not be parsed or do not match the selected cell set - displayed cell statuses have been re-initialized from cell set metadata"
              color="warning"
              iconType="alert"
              size="s"
            />
          </EuiFlexItem>
        )
      }
      <EuiFlexItem
        css={css`
          height: 85vh;
        `}
      >
        <EuiFlexGroup
          direction="row"
          gutterSize="s"
          css={css`
            height: 100%;
          `}
          responsive={false}
        >
          <EuiFlexItem
            grow={false}
            css={css`
              overflow-y: scroll;
            `}
          >
            <CellStatusTable readOnly={readOnly} />
          </EuiFlexItem>
          <EuiFlexItem
            css={css`
              overflow-y: scroll;
              overflow-x: scroll;
              min-width: 600px;
            `}
          >
            {mainSectionContent}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup
          direction="row"
          gutterSize="s"
          justifyContent="spaceBetween"
        >
          <EuiFlexItem grow={false}>
            <div>
              <EuiButton onClick={resetCellStatuses}>Reset</EuiButton>
            </div>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="row" gutterSize="s" responsive={false}>
              <EuiFlexItem>
                <div>
                  <EuiButtonEmpty onClick={onCancel}>Cancel</EuiButtonEmpty>
                </div>
              </EuiFlexItem>
              <EuiFlexItem>
                <div>
                  <ButtonPermissioned
                    requiredPermission="edit"
                    fill
                    onClick={() => {
                      onAcceptStatuses(cellStatuses);
                    }}
                    isDisabled={readOnly}
                  >
                    Save & Close
                  </ButtonPermissioned>
                </div>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
