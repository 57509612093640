import {
  EuiBasicTable,
  EuiLoadingChart,
  EuiTableComputedColumnType,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { CallOutError } from "components/CallOutError/CallOutError";
import {
  Metadatum,
  MetadataValue,
  File as DrsFile,
  useGetFileMetadataByFileIdQuery,
} from "graphql/_Types";
import { merge } from "lodash";
import { JsonValue } from "type-fest";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { metadataMap } from "types/MetadataMap";
import { isDefined } from "utils/isDefined";
import { isNonNull } from "utils/isNonNull";
import { isNonNullish } from "utils/isNonNullish";
import { stringifyMetadataValue } from "utils/stringifyMetadataValue";

type TMetadataDisplayKeyAndValues = {
  key: Metadatum["key"];
  displayName: string;
  value: MetadataValue["value"];
};

type FlyoutFileInfoBodyMetadataTableProps = {
  drsFile: Pick<DrsFile, "id" | "status" | "fileType">;
};

export const FlyoutFileInfoBodyMetadataTable = ({
  drsFile,
}: FlyoutFileInfoBodyMetadataTableProps) => {
  const fileTypeKey = isNonNullish(drsFile.fileType)
    ? FILE_TYPES_BY_ID[drsFile.fileType]?.key
    : undefined;

  const { data, loading } = useGetFileMetadataByFileIdQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      drsFileId: drsFile.id,
      metadataFilter: {
        metadatumByMetadataId: {
          key: {
            in: isDefined(fileTypeKey)
              ? Object.keys(metadataMap[fileTypeKey] ?? {})
              : [],
          },
        },
      },
    },
    onError: (err) => captureException(err),
  });
  const columns: Array<
    EuiTableComputedColumnType<TMetadataDisplayKeyAndValues>
  > = [
    {
      render: (data) => data.displayName,
      name: "",
      width: "50%",
    },
    {
      render: (data) => data.value,
      name: "",
      align: "right",
    },
  ];

  const metadata = data?.drsFile?.metadata.nodes
    .map(({ metadatum }) => metadatum)
    .filter(isNonNull);

  // get file metadata
  const metadataForFileType = isNonNullish(fileTypeKey)
    ? metadataMap[fileTypeKey]
    : undefined;

  if (
    // check for query error
    metadata === undefined ||
    // invalid data states where we don't have a file type or a metadata map entry
    // these are checked upstream so this should never occur
    fileTypeKey === undefined ||
    metadataForFileType === undefined
  ) {
    if (loading) {
      return <EuiLoadingChart />;
    }
    return <CallOutError />;
  }

  // series parent metadata if exists
  const seriesParentMetadataByKey =
    data?.drsFile?.seriesParentFile?.metadata.nodes
      .map(({ metadatum }) => metadatum)
      .filter(isNonNull)
      .reduce<Record<Metadatum["key"], JsonValue>>(
        (metadataByKey, { key, activeValue }) => {
          metadataByKey[key] = activeValue;
          return metadataByKey;
        },
        {},
      );

  // active file metadata
  const metadataByKey = metadata.reduce<Record<Metadatum["key"], JsonValue>>(
    (metadataByKey, { key, activeValue }) => {
      metadataByKey[key] = activeValue;
      return metadataByKey;
    },
    {},
  );

  // combined metadata
  // inherit series parent metadata and overwrite with child values if exist
  const combinedMetadataByKey = merge(seriesParentMetadataByKey, metadataByKey);

  const metadataDisplayKeyAndValues: TMetadataDisplayKeyAndValues[] = [];

  for (const [metadataMapKey, metadataMapDisplayName] of Object.entries(
    metadataForFileType,
  )) {
    const metadataValue = combinedMetadataByKey[metadataMapKey];

    if (
      isNonNullish(metadataValue) &&
      !(Array.isArray(metadataValue) && metadataValue.length === 0)
    ) {
      metadataDisplayKeyAndValues.push({
        key: metadataMapKey,
        displayName: metadataMapDisplayName,
        value: stringifyMetadataValue(metadataValue),
      });
    }
  }

  return (
    <EuiBasicTable
      responsive={false}
      items={metadataDisplayKeyAndValues}
      rowHeader="displayName"
      columns={columns}
      compressed
      tableLayout="auto"
    />
  );
};
