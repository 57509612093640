/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiBadge,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiLoadingSpinner,
  EuiStat,
  EuiText,
  EuiToolTip,
  useEuiTheme,
} from "@inscopix/ideas-eui";
import ProjectIcon from "components/ProjectIcon/ProjectIcon";
import { ProjectsCardActions } from "components/ProjectsCards/ProjectsCardActions";
import { Project, Tenant } from "graphql/_Types";
import { ProjectStatus } from "types/constants";
import { filesize } from "utils/filesize";
import { RoleBadge } from "components/RoleBadge/RoleBadge";
import { isUndefined } from "lodash";
import { TRegion } from "providers/RegionsProvider";
import { useRoutes } from "hooks/useRoutes";
import { RegionBadge } from "components/RegionBadge/RegionBadge";
import { isDefined } from "utils/isDefined";
import { libraryPinnedProjectDjango } from "hooks/useLibraryPinnedProjectDjango";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { roundToSignificant } from "utils/roundToSignificant";
import { IconComputeCredits } from "components/IconComputeCredits/IconComputeCredits";
import { TUserRole } from "components/ModalProjectSharing/ModalProjectSharing";
import { formatDate } from "utils/formatDate";
import { useState } from "react";
import { Tooltip } from "components/Tooltip/Tooltip";

const getStyles = (euiTheme: ReturnType<typeof useEuiTheme>["euiTheme"]) => ({
  card: css`
    border: 1px solid ${euiTheme.colors.lightShade};
    border-radius: 5px;
    padding: ${euiTheme.size.m};
  `,
  name: css`
    font-size: ${euiTheme.size.l};
  `,
  linkText: css`
    word-break: break-word;
  `,
});

export interface ProjectCardProps {
  project: Pick<
    Project,
    | "id"
    | "name"
    | "key"
    | "icon"
    | "iconColor"
    | "iconText"
    | "shortDescription"
    | "userId"
    | "activeStorageSize"
    | "archivedStorageSize"
    | "defaultUserAccessLevel"
    | "usedCredits"
    | "status"
    | "pinned"
    | "tenantId"
    | "dateCreated"
  > & {
    tenantKey: Tenant["key"];
    tenantName?: Tenant["name"];
    region?: TRegion;
    role: TUserRole;
  };
}

export const ProjectCard = ({ project }: ProjectCardProps) => {
  const { euiTheme } = useEuiTheme();
  const { routeMap } = useRoutes();
  const styles = getStyles(euiTheme);

  const [loadingPin, setLoadingPin] = useState(false);

  const onPin = async () => {
    setLoadingPin(true);
    try {
      await libraryPinnedProjectDjango.pin({ project: project.id });
    } catch (err) {
      addUtilityToastFailure("Failed to pin project");
    }
    setLoadingPin(false);
  };

  const onUnpin = async () => {
    setLoadingPin(true);
    try {
      await libraryPinnedProjectDjango.unpin({ project: project.id });
    } catch (err) {
      addUtilityToastFailure("Failed to unpin project");
    }
    setLoadingPin(false);
  };

  const {
    id,
    name,
    shortDescription,
    activeStorageSize,
    archivedStorageSize,
    tenantKey,
    tenantName,
    pinned,
    role,
    usedCredits,
  } = project;

  /* End get the user role*/

  const hasAdminControls = role === "admin" || role === "owner";

  const projectPath = routeMap["PROJECT"].dynamicPath({
    tenantKey: tenantKey,
    projectKey: project.key,
  }).path;

  const projectLink = () => {
    if (project.status === ProjectStatus.CLONING) {
      return (
        <EuiLink css={styles.name} disabled={true}>
          {name}
        </EuiLink>
      );
    }
    return isUndefined(project.region) ? (
      <EuiLink css={styles.name} to={projectPath}>
        {name}
      </EuiLink>
    ) : (
      <EuiLink
        css={styles.name}
        href={`${project.region.url}${projectPath}`}
        target="_blank"
      >
        {name}
      </EuiLink>
    );
  };

  return (
    <div css={styles.card}>
      <EuiFlexGroup direction="column">
        <EuiFlexGroup
          alignItems="center"
          justifyContent="flexStart"
          responsive={false}
          gutterSize="s"
        >
          <EuiFlexItem grow={false}>
            <ProjectIcon size="l" project={project} tenantKey={tenantKey} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText css={styles.linkText}>{projectLink()}</EuiText>
          </EuiFlexItem>
          {project.status === ProjectStatus.CLONING && (
            <EuiToolTip
              content="This project is being cloned and will be available shortly"
              position="bottom"
            >
              <EuiBadge color="hollow" title="">
                <EuiFlexGroup gutterSize="xs" alignItems="center">
                  <EuiFlexItem grow={false}>
                    <EuiLoadingSpinner size="s" />
                  </EuiFlexItem>
                  <EuiFlexItem>CLONING</EuiFlexItem>
                </EuiFlexGroup>
              </EuiBadge>
            </EuiToolTip>
          )}

          {/* spacer on header */}
          <EuiFlexItem />

          <EuiFlexItem grow={false}>
            <EuiFlexGroup
              alignItems="center"
              responsive={false}
              gutterSize="xs"
            >
              <EuiFlexItem grow={false}>
                <RoleBadge role={role} />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <Tooltip
                  content={pinned ? "Unpin project" : "Pin project to top"}
                  delayShow={500}
                >
                  <EuiButtonIcon
                    onClick={pinned ? () => void onUnpin() : () => void onPin()}
                    isLoading={loadingPin}
                    iconType={pinned ? "starFilled" : "starEmpty"}
                    iconSize="l"
                    color={"text"}
                    size="s"
                  />
                </Tooltip>
              </EuiFlexItem>
              {isUndefined(project.region) && (
                <EuiFlexItem grow={false}>
                  <ProjectsCardActions
                    projectId={id}
                    readOnly={!hasAdminControls}
                    disabled={project.status === ProjectStatus.CLONING}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>{shortDescription}</EuiText>
        </EuiFlexItem>
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem>
            <EuiFlexGroup alignItems="flexStart" responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiStat
                  titleSize="xs"
                  title={filesize(activeStorageSize)}
                  description="Active storage"
                  reverse
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiStat
                  titleSize="xs"
                  title={filesize(archivedStorageSize)}
                  description="Archived storage"
                  reverse
                  titleColor="subdued"
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiStat
                  titleSize="xs"
                  title={
                    <EuiFlexGroup alignItems="center" gutterSize="s">
                      {roundToSignificant(usedCredits ?? 0)}{" "}
                      <IconComputeCredits />
                    </EuiFlexGroup>
                  }
                  description="Compute credits"
                  reverse
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ paddingRight: 10 }}>
            <EuiFlexGroup
              direction="column"
              gutterSize="none"
              alignItems="flexEnd"
            >
              <EuiText>
                {isUndefined(project.region) ? (
                  <EuiLink
                    to={
                      routeMap["ORGANIZATION"].dynamicPath({
                        tenantKey: tenantKey,
                      }).path
                    }
                  >
                    {tenantName}
                  </EuiLink>
                ) : (
                  <EuiLink
                    href={`${project.region.url}${
                      routeMap["ORGANIZATION"].dynamicPath({
                        tenantKey: tenantKey,
                      }).path
                    }`}
                    target="_blank"
                  >
                    {tenantName}{" "}
                    {isDefined(project.region) && (
                      <RegionBadge regionKey={project.region.key} />
                    )}
                  </EuiLink>
                )}
              </EuiText>
              <EuiFlexItem grow={false}>
                <Tooltip
                  delayShow={500}
                  content={`Project created: ${formatDate(
                    project.dateCreated,
                    "YYYY-MM-DD HH:mm:ss",
                  )}`}
                >
                  <EuiText size="s" color="subdued">
                    {formatDate(project.dateCreated, "YYYY-MM-DD")}
                  </EuiText>
                </Tooltip>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGroup>
    </div>
  );
};
