import {
  EuiConfirmModal,
  EuiConfirmModalProps,
  EuiText,
} from "@inscopix/ideas-eui";
import { Task } from "graphql/_Types";
import { useSelectedDrsFileId } from "stores/useSelectedDrsFileId";
import { TaskStatus } from "types/constants";
import { ToolParamsGridRowDatum } from "./ToolParamsGrid.types";
import { useToolParamsGridRowDataContext } from "./ToolParamsGridRowDataProvider";

export const getDeleteAnalysisTableRowModeFromRowTaskStatus = (
  status: ToolParamsGridRowDatum["task_status"],
) => {
  switch (status) {
    case TaskStatus["INITIALIZING"]:
    case TaskStatus["NEW"]:
    case TaskStatus["PAUSED"]:
    case TaskStatus["QUEUED"]:
    case TaskStatus["RUNNING"]:
      return "DISABLED";
    case TaskStatus["COMPLETE"]:
      return "REMOVE_AND_DELETE";
    case TaskStatus["CANCELED"]:
    case TaskStatus["ERROR"]:
    case TaskStatus["FAILED"]:
    case undefined:
      return "REMOVE";
  }
};

export interface ModalDeleteRowProps {
  onClose: () => void;
  row: Pick<ToolParamsGridRowDatum, "id" | "output_group_files">;
  task: Pick<Task, "status">;
}

export const ModalDeleteRow = ({ row, task, onClose }: ModalDeleteRowProps) => {
  const { deselectDrsFile } = useSelectedDrsFileId();
  const removeRow = useToolParamsGridRowDataContext((s) => s.removeRow);

  const mode = getDeleteAnalysisTableRowModeFromRowTaskStatus(task.status);

  const modal: {
    props: Pick<
      EuiConfirmModalProps,
      "title" | "confirmButtonText" | "onConfirm" | "buttonColor"
    >;
    body: React.ReactNode;
  } = (() => {
    switch (mode) {
      case "DISABLED":
        return {
          props: {
            title: "Remove Task",
            confirmButtonText: "Confirm",
            onConfirm: () => onClose(),
            buttonColor: "primary",
          },
          body: (
            <EuiText>
              This row cannot be removed. Once submitted, only completed or
              failed tasks may be deleted.
            </EuiText>
          ),
        };
      case "REMOVE":
        return {
          props: {
            title: "Remove Task",
            confirmButtonText: "Confirm",
            onConfirm: () => onClose(),
            buttonColor: "warning",
          },
          body: (
            <EuiText>
              <p>Remove this task from the analysis table. </p>
              <p>
                The task information and log will still be visible in this
                project&apos;s All Tasks view.
              </p>
            </EuiText>
          ),
        };
      case "REMOVE_AND_DELETE":
        return {
          props: {
            title: "Remove Task and Delete Results",
            confirmButtonText: "Confirm",
            onConfirm: () => onClose(),
            buttonColor: "danger",
          },
          body: (
            <EuiText>
              <p>
                Remove this task from the analysis table and permanently delete
                any result files from the project (input files will not be
                affected).
              </p>
              <p>
                The task information and log will still be visible in this
                project&apos;s All Tasks view.
              </p>
            </EuiText>
          ),
        };
    }
  })();

  const onSubmit = () => {
    if (mode === "DISABLED") {
      return onClose();
    }

    row.output_group_files?.forEach((file) => deselectDrsFile(file.id));
    removeRow(row.id);

    onClose();
  };

  return (
    <EuiConfirmModal
      title="Remove Task"
      {...modal.props}
      cancelButtonText="Cancel"
      onCancel={() => onClose()}
      defaultFocusedButton="cancel"
      confirmButtonDisabled={false}
      isLoading={false}
      onConfirm={onSubmit}
    >
      {modal.body}
    </EuiConfirmModal>
  );
};
