/** @jsxImportSource @emotion/react */

import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPagination,
  EuiSearchBar,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import "./ProjectCards.scss";
import { isDefined } from "utils/isDefined";
import { useEuiSearchBar } from "hooks/useEuiSearchBar";
import {
  ProjectCard,
  ProjectCardProps,
} from "components/ProjectCard/ProjectCard";
import { ButtonCreateProjectFilled } from "../ButtonCreateProject/ButtonCreateProjectFilled";
import usePagination from "hooks/usePagination";
import { Project, Tenant } from "graphql/_Types";
import { useEffect, useMemo, useState } from "react";
import { clamp, isUndefined, orderBy } from "lodash";
import { useIdeasSearchParams } from "hooks/useIdeasSearchParams";
import { css } from "@emotion/react";
import { ToggleFilter, ToggleFilterProps } from "./ToggleFilter";
import { htmlIdGenerator } from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";

interface ProjectCardsProps {
  projects: (ProjectCardProps["project"] & Pick<Project, "dateCreated">)[];
  tenantId?: Tenant["id"];
}

const sortStates = [
  {
    icon: "sortUp",
    value: "asc" as const,
  },
  {
    icon: "sortDown",
    value: "desc" as const,
  },
];

const items = [
  {
    value: "dateCreated" as const,
    name: "Date Created",
    states: sortStates,
  },
  {
    value: "activeStorage" as const,
    name: "Active Storage",
    states: sortStates,
  },
  {
    value: "archivedStorage" as const,
    name: "Archived Storage",
    states: sortStates,
  },
  {
    value: "computeCredits" as const,
    name: "Compute Credits",
    states: sortStates,
  },
];

const onSort = <
  T extends Pick<
    Project,
    | "name"
    | "dateCreated"
    | "usedCredits"
    | "activeStorageSize"
    | "archivedStorageSize"
  >,
  U extends (typeof items)[number],
>(
  projects: T[],
  selectedItemKey: U["value"],
  selectedDir: U["states"][number]["value"],
) => {
  switch (selectedItemKey) {
    case "dateCreated":
      switch (selectedDir) {
        case "asc":
          return orderBy(projects, [(project) => project.dateCreated], ["asc"]);
        case "desc":
          return orderBy(
            projects,
            [(project) => project.dateCreated],
            ["desc"],
          );
      }
      break;

    case "activeStorage":
      switch (selectedDir) {
        case "asc":
          return orderBy(
            projects,
            [(project) => Number(project.activeStorageSize ?? 0)],
            ["asc"],
          );
        case "desc":
          return orderBy(
            projects,
            [(project) => Number(project.activeStorageSize ?? 0)],
            ["desc"],
          );
      }
      break;

    case "archivedStorage":
      switch (selectedDir) {
        case "asc":
          return orderBy(
            projects,
            [(project) => Number(project.archivedStorageSize ?? 0)],
            ["asc"],
          );

        case "desc":
          return orderBy(
            projects,
            [(project) => Number(project.archivedStorageSize ?? 0)],
            ["desc"],
          );
      }
      break;

    case "computeCredits":
      switch (selectedDir) {
        case "asc":
          return orderBy(
            projects,
            [(project) => Number(project.usedCredits ?? 0)],
            ["asc"],
          );

        case "desc":
          return orderBy(
            projects,
            [(project) => Number(project.usedCredits ?? 0)],
            ["desc"],
          );
      }
      break;
  }
  return projects;
};

/**
 * Displays a table of projects, allows selection and provides context menu for edit/deletion
 */
export const ProjectCards = ({ projects, tenantId }: ProjectCardsProps) => {
  const [searchBarContainerId] = useState(
    htmlIdGenerator("search-bar-container")(),
  );
  const { setParam, getParam } = useIdeasSearchParams();

  const { query, executeQuery, onChange, searchBarRef, roleFilter } =
    useEuiSearchBar();
  const [selectedOption, setSelectedOption] = useState<
    ToggleFilterProps<(typeof items)[number]>["selected"]
  >({ item: "dateCreated", state: 1 });

  const filteredProjects = useMemo(() => {
    const selectedItem = items.find(
      (item) => item.value === selectedOption.item,
    );

    const selectedItemKey = selectedItem?.value;
    const selectedDir = selectedItem?.states[selectedOption.state]?.value;
    const queriedProjects = isDefined(query)
      ? executeQuery(query, projects)
      : projects;

    if (isUndefined(selectedItemKey) || isUndefined(selectedDir)) {
      captureException("Unexpected state in ProjectCards");
      return queriedProjects;
    }

    return onSort(queriedProjects, selectedItemKey, selectedDir);
  }, [executeQuery, projects, query, selectedOption]);

  const { activePage, activePageItems, onPageChange, pageCount } =
    usePagination({
      allItems: filteredProjects,
      itemsPerPage: 5,
    });

  useEffect(() => {
    let page = Number(getParam("PAGE"));
    if (isNaN(page)) {
      page = 1;
    }
    onPageChange(clamp(page - 1, 0, pageCount - 1));
  }, [getParam, onPageChange, pageCount]);

  /**
   * Load sort route params into state on mount
   */
  useEffect(() => {
    const sortField = getParam("SORT_FIELD");
    const sortOrder = getParam("SORT_ORDER");

    if (sortField !== undefined && sortOrder !== undefined) {
      const selectedItem = items.find((item) => item.value === sortField);
      const stateIdx =
        selectedItem?.states.findIndex((state) => state.value === sortOrder) ??
        -1;
      if (stateIdx > -1 && selectedItem !== undefined) {
        setSelectedOption({ item: selectedItem.value, state: stateIdx });
      } else {
        captureException("Failed to restore route params in Project cards");
      }
    }
  }, [getParam]);

  if (projects.length === 0) {
    return (
      <EuiEmptyPrompt
        title={<h2>Start your first project!</h2>}
        style={{ maxWidth: "100%" }}
        titleSize="xs"
        iconType="layers"
        body={
          <p>
            <EuiText>
              All data in IDEAS is contained within projects. Start your first
              project to begin uploading and analyzing data.
            </EuiText>
          </p>
        }
        actions={[
          <ButtonCreateProjectFilled
            key="create-project-button"
            tenantId={tenantId}
          />,
        ]}
      />
    );
  }

  return (
    <>
      <EuiFlexGroup
        gutterSize="s"
        css={css`
          #${searchBarContainerId} {
            .euiButtonEmpty {
              min-width: 70px;
              .euiFilterButton__notification {
                margin-right: 5px;
              }
              .euiButtonEmpty__content {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        `}
      >
        <EuiFlexItem id={searchBarContainerId}>
          <EuiSearchBar
            ref={searchBarRef}
            query={query}
            onChange={onChange}
            filters={[roleFilter]}
            box={{ placeholder: "Find a project...", incremental: true }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <ToggleFilter
            items={items}
            selected={selectedOption}
            onChange={(newSelectedOption) => {
              const selectedItem = items.find(
                (item) => item.value === newSelectedOption.item,
              );
              const selectedItemOrder =
                selectedItem?.states?.[newSelectedOption.state]?.value;

              if (selectedItemOrder === undefined) {
                captureException("Route params out of sync in Project Cards");
              } else {
                setParam("SORT_FIELD", newSelectedOption.item);
                setParam("SORT_ORDER", selectedItemOrder);
              }

              setSelectedOption(newSelectedOption);
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup direction="column" gutterSize="s">
        {activePageItems.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </EuiFlexGroup>
      <EuiSpacer />
      {pageCount > 1 && (
        <EuiPagination
          activePage={activePage}
          onPageClick={(num) => {
            setParam("PAGE", String(num + 1));
          }}
          pageCount={pageCount}
        />
      )}
    </>
  );
};
