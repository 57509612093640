import { CallOutError } from "components/CallOutError/CallOutError";
import { useProjectPermission } from "hooks/useProjectPermission";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { ReactNode } from "react";

type ProjectAccessCalloutProps = {
  children: ReactNode;
};

export const ProjectAccessCallout = ({
  children,
}: ProjectAccessCalloutProps) => {
  const { hasPermission } = useProjectPermission();
  const { project } = useProjectDataContext();

  const userTenants = useUserContext((s) => s.tenants);

  const isOrgAdmin =
    userTenants.find(({ id }) => id === project.tenantId)?.role.key === "admin";

  if (!hasPermission("view") && !isOrgAdmin) {
    return (
      <CallOutError>
        The project you are trying to access does not exist or you do not have
        access to it.
      </CallOutError>
    );
  }

  return <>{children}</>;
};
