/** @jsxImportSource @emotion/react */
import { useState } from "react";
import {
  EuiBadge,
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  useEuiTheme,
} from "@inscopix/ideas-eui";
import {
  Dataset,
  Project,
  useUpdateDatasetByIdMutation,
} from "../../graphql/_Types";
import { isDefined } from "utils/isDefined";
import { RequireExactlyOne } from "type-fest";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { css } from "@emotion/react";
import { captureException } from "@sentry/react";
import { useCreateDatasetDjango } from "hooks/useCreateDatasetDjango";
import assert from "assert";
import { useValidateModalDatasetFields } from "./useValidateModalDatasetFields";
import { cloneDeep } from "lodash";
import { uuid } from "../../utils/uuid";
import { updateCacheFragment, writeCacheFragment } from "utils/cache-fragments";

export type ModalDatasetFields = Pick<Dataset, "name" | "prefix">;

export type ModalDatasetProps = RequireExactlyOne<
  {
    edit?: { dataset: Pick<Dataset, "id" | "name" | "prefix"> };
    project?: Pick<Project, "id" | "key">;
    onClose: () => void;
  },
  "edit" | "project"
>;

const MAX_KEY_LENGTH = 10;

/**
 * Displays a modal to create or edit a dataset
 */
export const ModalDataset = ({ onClose, edit, project }: ModalDatasetProps) => {
  const isEditMode = isDefined(edit);
  const [fields, setFields] = useState({
    name: isEditMode ? edit.dataset.name : "",
    prefix: isEditMode ? edit.dataset.prefix : "",
  });
  const [loading, setLoading] = useState(false);

  const { createDatasetDjango } = useCreateDatasetDjango();
  const [updateDataset] = useUpdateDatasetByIdMutation();
  const { euiTheme } = useEuiTheme();

  const { fieldValidationErrors, validateFields } =
    useValidateModalDatasetFields(edit);

  const createDataset = async () => {
    const { name, prefix } = fields;

    // project is required in props if edit is not specified
    assert(
      isDefined(project),
      "Expected project id to be passed to ModalDataset",
    );
    const datasetCreated = await createDatasetDjango({
      prefix,
      key: uuid(), // TODO ID-2498 remove deprecated field
      name,
      project: project.id,
    });
    writeCacheFragment({
      __typename: "Dataset",
      id: datasetCreated.id,
      data: {
        ...datasetCreated,
        activeDescription: "",
        datasetRecordingsTable: null,
        datasetMetadata: {
          __typename: "DatasetMetadataConnection",
          nodes: [],
        },
        datasetVersions: {
          __typename: "DatasetVersionsConnection",
          nodes: [],
        },
        project: { __typename: "Project", ...project },
        description: {
          __typename: "Metadatum",
          id: datasetCreated.descriptionId,
        },
        user: {
          __typename: "ApplicationUser",
          id: datasetCreated.userId,
        },
        tasks: {
          __typename: "TasksConnection",
          nodes: [],
        },
        tenant: {
          __typename: "Tenant",
          id: datasetCreated.tenantId,
        },
        files: {
          __typename: "FilesConnection",
          nodes: [],
        },
      },
    });
    updateCacheFragment({
      __typename: "Project",
      id: project.id,
      update: (data) => {
        const newData = cloneDeep(data);

        if (newData.datasets === undefined) {
          newData.datasets = {
            __typename: "DatasetsConnection",
            nodes: [],
          };
        }

        newData.datasets.nodes.push({
          __typename: "Dataset",
          id: datasetCreated.id,
        });

        return newData;
      },
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const isInputValid = await validateFields(fields);
      if (!isInputValid) {
        return setLoading(false);
      }
      if (isEditMode) {
        await updateDataset({
          variables: {
            id: edit.dataset.id,
            patch: {
              ...fields,
            },
          },
        });
        addUtilityToastSuccess("Successfully updated dataset");
      } else {
        await createDataset();
        addUtilityToastSuccess("Successfully created dataset");
      }
    } catch (err) {
      captureException(err);
      addUtilityToastFailure(
        isEditMode ? "Failed to update dataset" : "Failed to create dataset",
      );
    }

    setLoading(false);
    onClose();
  };

  const handleFieldChange = (field: RequireExactlyOne<ModalDatasetFields>) => {
    setFields((prevFields) => ({ ...prevFields, ...field }));
  };

  return (
    <EuiModal onClose={onClose} initialFocus="[name=name]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {isDefined(edit) ? "Edit Dataset" : "Create Dataset"}
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm>
          <EuiFormRow
            label="Name"
            isInvalid={isDefined(fieldValidationErrors.name)}
            error={fieldValidationErrors.name}
          >
            <EuiFieldText
              placeholder="Dataset Name"
              name="name"
              value={fields.name}
              onChange={(e) => handleFieldChange({ name: e.target.value })}
              icon="copyClipboard"
              aria-label="Dataset Name"
              maxLength={255}
              isInvalid={isDefined(fieldValidationErrors.name)}
              required
            />
          </EuiFormRow>
          <EuiFormRow
            label="Recording ID Prefix"
            helpText={
              <EuiText size={"s"}>
                <p>
                  This prefix combines with an recording&apos;s sequential
                  number to a unique ID number for each recording in the
                  dataset.
                </p>
                <ul>
                  <li>Maximum 10 characters.</li>
                  <li>Uppercase letters, numbers, and underscores.</li>
                  <li>Keys must be unique within this project.</li>
                  <li>Can be edited after initial save.</li>
                </ul>
              </EuiText>
            }
            isInvalid={isDefined(fieldValidationErrors.prefix)}
            error={fieldValidationErrors.prefix}
          >
            <EuiFieldText
              placeholder="REC"
              name="prefix"
              append={
                <EuiText size="s">
                  Example:{" "}
                  <EuiBadge
                    css={css`
                      font-family: ${euiTheme.font.familyCode};
                    `}
                  >
                    {fields.prefix !== "" ? fields.prefix : "REC"}-1
                  </EuiBadge>
                </EuiText>
              }
              value={fields.prefix}
              onChange={(e) => {
                const upperValue = e.target.value.toUpperCase();
                if (upperValue.match(/^[A-Z0-9_]*$/)) {
                  handleFieldChange({
                    prefix: upperValue.slice(0, MAX_KEY_LENGTH),
                  });
                }
              }}
              maxLength={MAX_KEY_LENGTH}
              isInvalid={isDefined(fieldValidationErrors.prefix)}
              required
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton fill isLoading={loading} onClick={() => void handleSubmit()}>
          Submit
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
