import { EuiPortal, htmlIdGenerator, useEuiTheme } from "@inscopix/ideas-eui";
import { Children, cloneElement, isValidElement } from "react";
import { ReactNode, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { Tooltip as ReactTooltip, ITooltip } from "react-tooltip";

export interface TooltipProps
  extends Omit<
    ITooltip,
    | "anchorId"
    | "anchorSelect"
    | "children"
    | "content"
    | "id"
    | "positionStrategy"
  > {
  children: ReactNode;
  content: ReactNode;
}

export const Tooltip = ({
  children,
  content,
  delayShow = 250,
  ...rest
}: TooltipProps) => {
  const { euiTheme } = useEuiTheme();
  const [tooltipId] = useState(htmlIdGenerator()());

  /* Attach a data-tooltip-id prop to every child element. This allows the
     tooltip component to select anchor elements from the DOM.
     https://react-tooltip.com/docs/examples/anchor-select */
  const anchoredChildren = Children.map(children, (child) => {
    return isValidElement<{ "data-tooltip-id": string }>(child)
      ? cloneElement(child, { "data-tooltip-id": tooltipId })
      : child;
  });

  return (
    <>
      {anchoredChildren}
      <EuiPortal>
        <ReactTooltip
          delayShow={delayShow}
          id={tooltipId}
          // Render at the same level as EUI tooltips
          style={{ zIndex: euiTheme.levels.toast }}
          {...rest}
        >
          {content}
        </ReactTooltip>
      </EuiPortal>
    </>
  );
};
