import { useEffect, useMemo, useState } from "react";

/**
 * Tells you whether or not files are being dragged over document
 */
export const useDragState = () => {
  const [isDraggingFiles, setIsDraggingFiles] = useState(false);

  useEffect(() => {
    const onDragLeave = (e: DragEvent) => {
      // if relatedTarget is the secondary target of an event
      // for a leave event this is the target that is being entered when the leave event is fired
      // if this is null, it means the drag event is leaving the window
      // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/relatedTarget
      if (e.relatedTarget === null) {
        setIsDraggingFiles(false);
      }
    };

    const onDragEnter = () => {
      if (!isDraggingFiles) {
        setIsDraggingFiles(true);
      }
    };

    const onDrop = () => {
      setIsDraggingFiles(false);
    };

    document.addEventListener("dragleave", onDragLeave);
    document.addEventListener("dragenter", onDragEnter);
    document.addEventListener("drop", onDrop);

    return () => {
      document.removeEventListener("dragleave", onDragLeave);
      document.removeEventListener("dragenter", onDragEnter);
      document.removeEventListener("drop", onDrop);
    };
  }, [isDraggingFiles]);

  const dragState = useMemo(() => ({ isDraggingFiles }), [isDraggingFiles]);

  return dragState;
};
