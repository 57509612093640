import {
  EuiGlobalToastList,
  EuiGlobalToastListToast as Toast,
} from "@inscopix/ideas-eui";
import { useState } from "react";

/**
 * Component used to display toast messages
 */

let addToastHandler: (toast: Toast) => void;
let removeAllToastsHandler: () => void;

// Helper function which is imported by other components to enable adding toasts
export function addToast(toast: Toast) {
  if (typeof addToastHandler === "function") addToastHandler(toast);
}

// Helper function which is imported by other components to enable removing toasts
export function removeAllToasts() {
  removeAllToastsHandler();
}

function GlobalToastHandler() {
  const [toasts, setToasts] = useState<Toast[]>([]);

  addToastHandler = (toast: Toast) => {
    setToasts(toasts.concat(toast));
  };

  const removeToast = (removedToast: { id: Toast["id"] }) => {
    setToasts(toasts.filter((toast: Toast) => toast.id !== removedToast.id));
  };

  removeAllToastsHandler = () => {
    setToasts([]);
  };

  return (
    <EuiGlobalToastList
      toasts={toasts}
      dismissToast={removeToast}
      toastLifeTimeMs={6000}
    />
  );
}

export default GlobalToastHandler;
