/** @jsxImportSource @emotion/react */

import {
  EuiButton,
  EuiCallOut,
  EuiFieldNumberProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import assert from "assert";
import {
  CroppingCoordinates,
  CroppingFrameSelectorProps,
} from "components/CroppingFrameSelector/CroppingFrameSelector";
import {
  errorMessageInvalidInitialCoordinates,
  isCompleteSetOfCroppingCoordinates,
} from "components/CroppingFrameSelector/CroppingFrameSelector.helpers";
import { useCroppingFrameSelectorStyles } from "components/CroppingFrameSelector/useCroppingFrameSelectorStyles";
import { useState } from "react";
import { isNonNullish } from "utils/isNonNullish";
import { sleep } from "utils/sleep";
import { ToolParamsGridRowDatum, ToolPathParam } from "../ToolParamsGrid.types";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { ModalToolCroppingFrameParamWrapper } from "./ModalToolCroppingFrameParamWrapper";
import { FieldNumberPermissioned } from "components/FieldNumberPermissioned/FieldNumberPermissioned";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";

interface ModalToolCroppingFrameParamNoSourceFileProps
  extends Pick<
    CroppingFrameSelectorProps,
    | "initialCroppingCoordinates"
    | "onAcceptCoordinates"
    | "isInvalidInitialCoordinates"
  > {
  sourceFileParam: ToolPathParam;
  onClose: () => void;
  rowDatum: ToolParamsGridRowDatum;
  readOnly?: boolean;
}

export const ModalToolCroppingFrameParamNoSourceFile = ({
  initialCroppingCoordinates,
  isInvalidInitialCoordinates,
  sourceFileParam,
  onClose,
  onAcceptCoordinates,
  rowDatum,
  readOnly,
}: ModalToolCroppingFrameParamNoSourceFileProps) => {
  const [selectedCoordinates, setSelectedCoordinates] = useState(
    initialCroppingCoordinates
      ? { ...initialCroppingCoordinates }
      : ({} as Partial<CroppingCoordinates>),
  );

  const { gridApi } = useToolParamsGridContext();

  const styles = useCroppingFrameSelectorStyles();

  const onChangeValue: EuiFieldNumberProps["onChange"] = (e) => {
    const targetValue = e.target.value;
    let value: number | undefined;
    if (targetValue !== "") {
      value = Math.round(Number(targetValue));
    }
    setSelectedCoordinates((prev) => {
      const newCoords = { ...prev };
      newCoords[e.target.name as keyof typeof newCoords] = value;
      return newCoords;
    });
  };

  return (
    <ModalToolCroppingFrameParamWrapper
      onClose={onClose}
      maxWidth="600px"
      readOnly={readOnly}
    >
      {!readOnly && (
        <>
          <EuiCallOut>
            <EuiText>
              <p>
                To see your cropping frame on a preview, you must first select a
                source file of the appropriate type in the{" "}
                <EuiLink
                  onClick={() =>
                    void (async () => {
                      try {
                        onClose();
                        gridApi?.ensureColumnVisible(
                          sourceFileParam.key,
                          "start",
                        );
                        // Wait a tick for the scroll to complete
                        await sleep(0);

                        const rowNode = gridApi?.getRowNode(rowDatum.id);

                        assert(isNonNullish(rowNode));
                        gridApi?.flashCells({
                          rowNodes: [rowNode],
                          columns: [sourceFileParam.key],
                        });
                      } catch (err) {
                        captureException(err);
                        // TODO: investigate the cause of this error
                        // https://github.com/ag-grid/ag-grid/issues/5085
                      }
                    })()
                  }
                >
                  {sourceFileParam.name}
                </EuiLink>{" "}
                column.
              </p>
              <p>
                If you already know your coordinates, you may enter them below.
              </p>
            </EuiText>
          </EuiCallOut>
          <EuiSpacer />
        </>
      )}

      <div css={styles.controlsAndCanvasContainer}>
        <div css={styles.numericInputsContainer}>
          <EuiFlexItem grow={false}>
            <FieldNumberPermissioned
              requiredPermission="edit"
              prepend="left"
              readOnly={readOnly}
              step={1}
              compressed
              fullWidth={false}
              type="number"
              min={0}
              value={
                selectedCoordinates.left === undefined
                  ? ""
                  : Math.round(selectedCoordinates.left)
              }
              title="left"
              // name used in onChange for identifying input
              name={"left" satisfies keyof typeof selectedCoordinates}
              onChange={onChangeValue}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <FieldNumberPermissioned
              requiredPermission="edit"
              prepend="top"
              readOnly={readOnly}
              step={1}
              compressed
              fullWidth={false}
              type="number"
              min={0}
              value={
                selectedCoordinates.top === undefined
                  ? ""
                  : Math.round(selectedCoordinates.top)
              }
              title="top"
              // name used in onChange for identifying input
              name={"top" satisfies keyof typeof selectedCoordinates}
              onChange={onChangeValue}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <FieldNumberPermissioned
              requiredPermission="edit"
              prepend="width"
              readOnly={readOnly}
              step={1}
              compressed
              fullWidth={false}
              type="number"
              min={1}
              value={
                selectedCoordinates.width === undefined
                  ? ""
                  : Math.round(selectedCoordinates.width)
              }
              title="width"
              // name used in onChange for identifying input
              name={"width" satisfies keyof typeof selectedCoordinates}
              onChange={onChangeValue}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <FieldNumberPermissioned
              requiredPermission="edit"
              prepend="height"
              readOnly={readOnly}
              step={1}
              compressed
              fullWidth={false}
              type="number"
              min={1}
              value={
                selectedCoordinates.height === undefined
                  ? ""
                  : Math.round(selectedCoordinates.height)
              }
              title="height"
              // name used in onChange for identifying input
              name={"height" satisfies keyof typeof selectedCoordinates}
              onChange={onChangeValue}
            />
          </EuiFlexItem>
        </div>

        {isInvalidInitialCoordinates &&
          !isCompleteSetOfCroppingCoordinates(selectedCoordinates) && (
            <EuiFlexGroup
              alignItems="center"
              gutterSize="xs"
              css={styles.errorMessage}
            >
              <EuiIcon type="warning" />
              <EuiText>{errorMessageInvalidInitialCoordinates}</EuiText>
            </EuiFlexGroup>
          )}
        <EuiFlexGroup responsive={false} gutterSize="xs">
          <EuiFlexItem grow={false}>
            <ButtonPermissioned
              requiredPermission="edit"
              disabled={
                readOnly ||
                !isCompleteSetOfCroppingCoordinates(selectedCoordinates)
              }
              onClick={() => {
                if (isCompleteSetOfCroppingCoordinates(selectedCoordinates)) {
                  onAcceptCoordinates(selectedCoordinates);
                }
              }}
            >
              Save
            </ButtonPermissioned>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton color="text" onClick={onClose}>
              Cancel
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </ModalToolCroppingFrameParamWrapper>
  );
};
