import { EuiEmptyPrompt, EuiSpacer } from "@inscopix/ideas-eui";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { FlyoutSubsection } from "components/Flyout/FlyoutSubsection";

interface FlyoutAnalysisSubsectionEmptyPromptProps {
  onClickCreateTable: () => void;
  selectedRecordingsCount: number;
}
export const FlyoutAnalysisSubsectionEmptyPrompt = ({
  onClickCreateTable,
  selectedRecordingsCount,
}: FlyoutAnalysisSubsectionEmptyPromptProps) => {
  return (
    <FlyoutSubsection title="View or Add to Existing Table">
      <EuiSpacer />
      <EuiEmptyPrompt
        color="subdued"
        title={<h3>Add an Analysis Table</h3>}
        body={
          <div>
            <div>
              Select One or More Recordings or Create an Empty Analysis Table
            </div>
            <div>({selectedRecordingsCount} recordings selected)</div>
          </div>
        }
        actions={
          <ButtonPermissioned
            onClick={onClickCreateTable}
            requiredPermission="edit"
          >
            Create a Table
          </ButtonPermissioned>
        }
      />
    </FlyoutSubsection>
  );
};
