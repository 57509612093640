import { QueryResult } from "@apollo/client";
import {
  useNotificationsFeedUnreadCountLazyQuery,
  NotificationsFeedUnreadCountQuery,
  Exact,
} from "graphql/_Types";
import { useRegionsContext } from "providers/RegionsProvider";
import { useState, useEffect, useCallback } from "react";

export const useGetExternalNotificationsFeedUnreadCount = () => {
  const { externalRegions } = useRegionsContext();
  const [getNotificationsFeedUnreadCount] =
    useNotificationsFeedUnreadCountLazyQuery({
      fetchPolicy: "no-cache",
      pollInterval: 300 * 1000, // 5 minutes
    });
  const [results, setResults] = useState<
    QueryResult<
      NotificationsFeedUnreadCountQuery,
      Exact<{ [key: string]: never }>
    >[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const getExternalNotificationsFeedUnreadCount = useCallback(async () => {
    try {
      const promises = externalRegions.map((region) =>
        getNotificationsFeedUnreadCount({
          context: {
            uri: region.urlGraphQL,
            queryDeduplication: false,
          },
        }),
      );
      const results = await Promise.all(promises);
      setResults(results);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }, [externalRegions, getNotificationsFeedUnreadCount]);

  useEffect(() => {
    void getExternalNotificationsFeedUnreadCount(); // initial fetch

    const interval = setInterval(() => {
      void getExternalNotificationsFeedUnreadCount();
    }, 300 * 1000); // poll every 5 minutes

    return () => clearInterval(interval);
  }, [externalRegions, getExternalNotificationsFeedUnreadCount]);

  // manually triggerable refetch
  const refetchExternalNotificationsFeedUnreadCount = () => {
    void getExternalNotificationsFeedUnreadCount();
  };

  const initialValue = 0;
  const externalNotificationsCount = results?.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.data?.notifications?.totalCount ?? 0),
    initialValue,
  );

  return {
    externalNotificationsCount,
    loading,
    error,
    refetchExternalNotificationsFeedUnreadCount,
  };
};
