import { ModalError } from "components/ModalError/ModalError";
import { ModalLoading } from "components/ModalLoading/ModalLoading";
import { isNonNullish } from "utils/isNonNullish";
import { ModalProject, ModalProjectMode } from "./ModalProject";
import { Project, useGetProjectByIdQuery } from "../../graphql/_Types";

type ModalCloneProjectProps = {
  projectId: Project["id"];
  onClose: () => void;
};
/**
 * Displays a Project Modal to clone an existing project
 */
function ModalCloneProject({ projectId, onClose }: ModalCloneProjectProps) {
  const { data: ProjectData, loading: loadingProjectData } =
    useGetProjectByIdQuery({
      variables: { id: projectId },
    });

  if (loadingProjectData) {
    return <ModalLoading onClose={onClose} />;
  }

  const project = ProjectData?.projectById;
  if (!isNonNullish(project)) {
    return <ModalError onClose={onClose} />;
  }

  return (
    <ModalProject
      mode={ModalProjectMode.CLONE}
      project={project}
      onClose={onClose}
    />
  );
}

export default ModalCloneProject;
