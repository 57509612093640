import { EuiSuperSelect, EuiSuperSelectProps } from "@inscopix/ideas-eui";
import { withUserPermission } from "hocs/withUserPermission";

/**
 * {@link EuiSuperSelect} represented as a function component. This is necessary
 * because TypeScript only supports propagating generic type arguments with
 * functions, not classes.
 */
const EuiSuperSelectFC = <T extends string>(props: EuiSuperSelectProps<T>) => (
  <EuiSuperSelect {...props} />
);

/**
 * Enhanced {@link EuiSuperSelect} that is disabled when the user lacks a
 * required permission
 */
export const SuperSelectPermissioned = withUserPermission(EuiSuperSelectFC);
