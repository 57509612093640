import { ApolloQueryResult } from "@apollo/client";
import {
  EuiFlexGroup,
  EuiPanel,
  EuiText,
  EuiSpacer,
  EuiButton,
  EuiSkeletonText,
  EuiCallOut,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import {
  UserProviderQuery,
  useCreateEulaVersionUserAcceptanceMutation,
  useGetAllEulaVersionsQuery,
} from "graphql/_Types";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { uuid } from "utils/uuid";
import { EulaBody } from "./EulaBody";

interface EulaProps {
  refetch: () => Promise<ApolloQueryResult<UserProviderQuery>>;
  userID: string;
}

export const Eula = ({ refetch, userID: userIDKey }: EulaProps) => {
  const {
    data: dataEulaContent,
    loading: loadingEulaContent,
    error: errorEulaContent,
  } = useGetAllEulaVersionsQuery({
    onError: (err) => captureException(err),
  });

  const [createEulaVersionUserAcceptance, { loading: loadingEulaAcceptance }] =
    useCreateEulaVersionUserAcceptanceMutation({
      onError: (err) => captureException(err),
    });

  const handleAcceptEula = async () => {
    try {
      const result = await createEulaVersionUserAcceptance({
        variables: {
          input: {
            eulaVersionUserAcceptance: {
              id: uuid(),
              eulaVersionId: dataEulaContent?.allEulaVersions?.nodes?.[0]?.id,
              userId: userIDKey,
              accepted: true,
            },
          },
        },
      });

      if (result.data) {
        void refetch();
      }
    } catch (err) {
      addUtilityToastFailure("Error accepting EULA");
      captureException(err);
    }
  };

  return (
    <EuiFlexGroup justifyContent="center">
      <EuiPanel
        color="plain"
        hasShadow={true}
        paddingSize="xl"
        style={{ maxWidth: "60rem", maxHeight: "100vh", overflowY: "auto" }}
      >
        <EuiText>
          <h2>End User License Agreement</h2>
        </EuiText>
        <EuiSpacer size="l" />

        {loadingEulaContent ? (
          <EuiSkeletonText lines={5} />
        ) : errorEulaContent ||
          dataEulaContent?.allEulaVersions?.nodes[0]?.content === undefined ? (
          <EuiCallOut
            title="Sorry, there was an error"
            color="danger"
            iconType="warning"
          >
            <p>
              There was an error loading the content of the EULA. Please reload
              the page to try again.
            </p>
          </EuiCallOut>
        ) : (
          <>
            <EulaBody
              eulaContent={dataEulaContent?.allEulaVersions?.nodes[0]?.content}
            />
            <EuiSpacer size="l" />
            <div style={{ textAlign: "center" }}>
              <EuiButton
                isLoading={loadingEulaAcceptance}
                color="primary"
                fill
                onClick={() => {
                  void handleAcceptEula();
                }}
              >
                Accept
              </EuiButton>
            </div>
          </>
        )}
      </EuiPanel>
    </EuiFlexGroup>
  );
};
