/** @jsxImportSource @emotion/react */
import { useDroppable } from "@dnd-kit/core";
import { css } from "@emotion/react";
import { DragEventHandler, memo, useState } from "react";
import { uuid } from "utils/uuid";
import { Dataset, File as DrsFile, FileRecordingGroup } from "graphql/_Types";
import { GridRendererDrsFileWithFiles } from "./GridRendererDrsFileWithFiles";
import {
  DroppableData,
  parseDatasetDraggableId,
} from "components/Dataset/DatasetDndProvider";
import { GridRendererDrsFileWithoutFiles } from "./GridRendererDrsFileWithoutFiles";
import { useUploadAndAssignFile } from "./useUploadAndAssignFile";
import { tint, useEuiTheme } from "@inscopix/ideas-eui";
import { isDrsFileAllowedInColumn } from "utils/isDrsFileAllowedInColumn";
import { DrsFileColumn } from "../RecordingsGrid.helpers";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { selectSystemFiles } from "utils/selectSystemFiles";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
  `,
  filePicker: css`
    display: none;
  `,
  dropZone: (backgroundColor: string) => css`
    background-color: ${backgroundColor};
  `,
};

interface GridRendererDrsFileProps {
  column: DrsFileColumn;
  datasetId: Dataset["id"];
  drsFiles: Pick<
    DrsFile,
    | "id"
    | "name"
    | "originalName"
    | "status"
    | "fileType"
    | "fileFormat"
    | "source"
    | "dateCreated"
    | "datasetId"
    | "isSeries"
    | "seriesParentId"
    | "projectId"
    | "processingStatus"
  >[];
  recordingId: FileRecordingGroup["id"];
}

export const GridRendererDrsFile = memo(function GridRendererDrsFile({
  column,
  datasetId,
  drsFiles,
  recordingId,
}: GridRendererDrsFileProps) {
  const { euiTheme } = useEuiTheme();
  const { datasetMode } = useDatasetDataContext();
  const { openModal } = useDatasetLayoutContext();
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { uploadAndAssignFile } = useUploadAndAssignFile({
    columnId: column.id,
    datasetId,
    recordingId,
    tenantId: currentTenant.id,
  });
  const uploadFileAction = useDatasetAction("uploadFile");
  const assignFileAction = useDatasetAction("assignFile");
  const [cellId] = useState(uuid);

  const droppableData: DroppableData = {
    recordingId,
    column,
  };

  const {
    active,
    setNodeRef: droppableRef,
    isOver,
  } = useDroppable({
    id: cellId,
    disabled: datasetMode.type !== "current",
    data: droppableData,
  });

  const handleCellDrop: DragEventHandler<HTMLDivElement> = (e) => {
    const files = Array.from(e.dataTransfer.files);
    files.forEach(uploadAndAssignFile);
  };

  const rootCss = (() => {
    const css = [styles.root];

    if (active === null) {
      return css;
    }

    const activeData = parseDatasetDraggableId(active.id as string);

    // FIXME: For some reason activeData can sometimes be `{}`
    // https://inscopix.atlassian.net/browse/ID-1383
    if (Object.keys(activeData).length === 0) {
      return css;
    }

    const isValidDropZone = isDrsFileAllowedInColumn(
      activeData.drsFile,
      column,
    );

    if (isOver && isValidDropZone) {
      const bgColor = tint(euiTheme.colors.success, 0.8);
      css.push(styles.dropZone(bgColor));
    }

    if (!isValidDropZone) {
      const bgColor = euiTheme.colors.lightestShade;
      css.push(styles.dropZone(bgColor));
    }

    return css;
  })();

  return (
    <div css={rootCss} ref={droppableRef} onDrop={handleCellDrop}>
      {drsFiles.length === 0 ? (
        datasetMode.type === "current" ? (
          <GridRendererDrsFileWithoutFiles
            onUploadClick={() => {
              if (!uploadFileAction.isDisabled) {
                void selectSystemFiles(true).then((files) => {
                  files?.forEach(uploadAndAssignFile);
                });
              }
            }}
            onAssignClick={() => {
              if (!assignFileAction.isDisabled) {
                openModal({
                  type: "assignFile",
                  props: {
                    column,
                    recordingId,
                  },
                });
              }
            }}
          />
        ) : null
      ) : (
        <GridRendererDrsFileWithFiles drsFiles={drsFiles} />
      )}
    </div>
  );
});
