import { EuiLink } from "@inscopix/ideas-eui";
import assert from "assert";
import { NotificationsFeedBodyQuery } from "graphql/_Types";
import { useRoutes } from "hooks/useRoutes";
import { USER_ACCESS_LEVELS_BY_ID } from "types/UserAccessLevels";
import { isNonNull } from "utils/isNonNull";
import { NotificationAlert } from "./NotificationAlert";
import { useFetchAndSetProjectAccess } from "hooks/useFetchAndSetProjectAccess";
import { useCallback, useEffect } from "react";
import { TRegion } from "providers/RegionsProvider";
import { isUndefined } from "lodash";
import { RegionBadge } from "components/RegionBadge/RegionBadge";

interface NotificationAlertProjectSharedProps {
  data: NonNullable<
    NotificationsFeedBodyQuery["notifications"]
  >["nodes"][number] & { region?: TRegion };
  onNotificationRead: (
    notificationId: string,
    region?: TRegion,
    refetchExternalNotifications?: () => void,
  ) => void;
  refetchExternalNotifications: () => void;
}

/** Component that renders a notification that a project was shared with the user */
export const NotificationAlertProjectShared = ({
  data,
  onNotificationRead,
  refetchExternalNotifications,
}: NotificationAlertProjectSharedProps) => {
  const { refetchCurrentUserProjectAccesses } = useFetchAndSetProjectAccess();
  const { routeMap } = useRoutes();

  // FIXME: Is there a way to guarantee we have project permissions before
  // rerouting?
  useEffect(() => {
    void refetchCurrentUserProjectAccesses();
  }, [refetchCurrentUserProjectAccesses]);

  const { dateCreated, hasSeen, id: notificationId } = data;
  const attrs = data.projectSharedNotification;
  assert(isNonNull(attrs));

  const onNotificationReadMemoized = useCallback(() => {
    if (!hasSeen) {
      onNotificationRead(
        notificationId,
        data.region,
        refetchExternalNotifications,
      );
    }
  }, [
    hasSeen,
    notificationId,
    onNotificationRead,
    refetchExternalNotifications,
    data.region,
  ]);

  const { project, sharer } = attrs;
  if (project === null) {
    // bail out if we don't have access to this project
    return null;
  }
  assert(isNonNull(sharer));
  const accessLevel = USER_ACCESS_LEVELS_BY_ID[attrs.accessLevel];
  const projectKey = project.key;

  const tenantKey = project.tenant?.key;
  assert(tenantKey !== undefined);

  const projectPath = routeMap["PROJECT"].dynamicPath({
    projectKey,
    tenantKey: tenantKey,
  }).path;

  const action = isUndefined(data.region) ? (
    <EuiLink to={projectPath} onClick={onNotificationReadMemoized}>
      Go to project
    </EuiLink>
  ) : (
    <EuiLink
      href={`${data.region.url}${projectPath}`}
      target="_blank"
      onClick={onNotificationReadMemoized}
    >
      Go to project <RegionBadge regionKey={data.region.key} />
    </EuiLink>
  );

  return (
    <NotificationAlert
      dateCreated={dateCreated}
      hasSeen={hasSeen}
      title="Project shared"
      onNotificationRead={onNotificationReadMemoized}
      text={
        <span>
          You have been granted{" "}
          <strong>{accessLevel?.name.toLowerCase() ?? "unknown"}</strong> access
          to <strong>{project.name}</strong> by{" "}
          <strong>
            {sharer.firstName} {sharer.lastName}
          </strong>
          .
        </span>
      }
      action={action}
    />
  );
};
