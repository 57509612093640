import { css } from "@emotion/react";

export const fileRendererCellPadding = 4;
export const fileBadgeWidth = 36;

export const useFileGridRendererStyles = () => {
  const styles = {
    root: css`
      &,
      & > * {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
      }
    `,
    dropZone: (backgroundColor: string) => css`
      background-color: ${backgroundColor};
    `,
    button: css`
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
      padding: ${fileRendererCellPadding}px;
    `,
    invisible: css`
      visibility: hidden;
    `,
    badgeWrapper: (compensateForPadding: boolean) => css`
      display: flex;
      margin-left: ${compensateForPadding ? -4 : 0}px;
      max-width: 100%;
    `,
    overflowBadge: (compensateForPadding: boolean) => css`
      margin-top: 2px;
      margin-left: ${compensateForPadding ? 0 : 4}px;
      margin-right: 4px;
    `,
  };

  return styles;
};
