/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";

interface MenuBarProps {
  children: ReactNode;
}

export const MenuBar = ({ children }: MenuBarProps) => {
  return <div>{children}</div>;
};
