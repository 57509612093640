import axios, { AxiosResponse } from "axios";
import { File as DrsFile, File } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";

export type CreateSeriesInput = {
  id: string;
  name: string;
  series_files: File["id"][];
};

type CreateSeriesResponse = AxiosResponse<{
  id: DrsFile["id"];
  key: DrsFile["key"];
  name: DrsFile["name"];
  size: DrsFile["size"];
  series_files: DrsFile["id"][];
}>;

export const useCreateSeriesFromDrsFilesDjango = () => {
  const createSeriesDjango = async (
    input: CreateSeriesInput,
  ): Promise<CreateSeriesResponse["data"]> => {
    const url = getEnvVar("URL_DRS_FILE_SERIES_CREATE");
    const headers = await getRequestHeaders();
    const response = await axios.post<CreateSeriesInput, CreateSeriesResponse>(
      url,
      input,
      {
        headers,
      },
    );

    return response.data;
  };
  return { createSeriesDjango };
};
