import { DatasetNotes } from "components/Dataset/DatasetNotes";
import { Flyout, FlyoutProps } from "components/Flyout/Flyout";

interface FlyoutDatasetInfoProps extends Pick<FlyoutProps, "headerControls"> {
  onClose: () => void;
}

export const FlyoutDatasetNotes = ({
  onClose,
  ...props
}: FlyoutDatasetInfoProps) => {
  return (
    <Flyout title="Dataset Notes" onClose={onClose} {...props}>
      <DatasetNotes />
    </Flyout>
  );
};
