import { File, ToolVersion } from "graphql/_Types";
import { cloneDeep, fromPairs, isUndefined, set, uniq } from "lodash";
import { FILE_FORMATS_BY_ID } from "types/FileFormats";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { isNonNull } from "utils/isNonNull";
import { uuid } from "utils/uuid";
import {
  ToolParamsGridRowDatum as RowDatum,
  ToolParamValue,
  ToolParamsGridRowDatum,
  ToolSpec,
} from "./ToolParamsGrid.types";
import { isDefined } from "utils/isDefined";
import {
  isFileMetadatumReference,
  isRecordingMetadatumReference,
} from "./ToolParamsGrid.helpers";
import { getFileMetadata } from "./GridRendererToolParam/MetadataTableFile.helpers";
import { getRecordingMetadata } from "./GridRendererToolParam/MetadataTableRecording.helpers";
import { isNonNullish } from "utils/isNonNullish";

/**
 * Creates a row datum with the default values
 * @param toolSpec
 * @returns The row datum
 */
export const getDefaultRowDatum = (
  toolSpec: ToolSpec,
  toolVersion: Pick<ToolVersion, "id" | "version">,
) => {
  const rowDatum: RowDatum = {
    id: uuid(),
    selected: false,
    params: fromPairs(
      toolSpec.params.map((param) => [param.key, param.default]),
    ),
    attachResults: true,
    metadatumReferences: {},
    toolVersion,
  };

  return rowDatum;
};

/**
 * Determines whether a file matches a tool path param file filter
 * @param file
 * @param filter
 * @returns A `boolean` representing whether the file matches the file filter
 */
export const isFileFilterMatch = (
  file: Pick<File, "fileType" | "fileFormat">,
  filter: {
    file_type: string;
    file_format: string;
  },
) => {
  const isSameFileType =
    isUndefined(filter.file_type) ||
    (isNonNull(file.fileType) &&
      FILE_TYPES_BY_ID[file.fileType]?.key === filter.file_type);

  const isSameFileFormat =
    isUndefined(filter.file_format) ||
    (isNonNull(file.fileFormat) &&
      FILE_FORMATS_BY_ID[file.fileFormat]?.key === filter.file_format);

  return isSameFileType && isSameFileFormat;
};

/**
 * Synchronizes the values of tool parameter's populated using file metadatum
 * references. Executed analysis rows will not be updated.
 * @param rowData
 * @returns The updated row data.
 */
const syncFileMetadatumReferences = async (
  rowData: ToolParamsGridRowDatum[],
) => {
  // Get the file IDs from every unexecuted analysis row with a file
  // metadatum reference
  const fileIds = rowData.flatMap((row) => {
    if (row.metadatumReferences === undefined || row.task_id !== undefined) {
      return [];
    }

    const fileMetadatumReferences = Object.values(row.metadatumReferences)
      .filter(isDefined)
      .filter(isFileMetadatumReference);

    return fileMetadatumReferences.flatMap((reference) => {
      const sourceFileIds = (row.params[reference.source] ?? []) as string[];
      return sourceFileIds;
    });
  });

  // Create a map from file ID -> metadatum key -> values
  const metadata = await getFileMetadata(fileIds);

  /**
   * Build a map of metadata by file id and key
   * Because metadata can be a list, each [fileId][key] entry is turned into a list to make
   * downstream matching straightforward
   */
  const metadataMap = metadata.reduce<
    Record<string, Record<string, ToolParamValue[]>>
  >((metadataMap, current) => {
    if (metadataMap[current.fileId] === undefined) {
      metadataMap[current.fileId] = {};
    }
    if (metadataMap[current.fileId]?.[current.key] === undefined) {
      metadataMap[current.fileId][current.key] = [];
    }

    if (isNonNullish(current.value)) {
      metadataMap[current.fileId][current.key].push(
        current.value as ToolParamValue,
      );
    }

    return metadataMap;
  }, {});
  // Update values of tool params with metadatum references
  return rowData.map((row) => {
    // Do nothing for executed rows and rows without metadatum references
    if (row.metadatumReferences === undefined || row.task_id !== undefined) {
      return row;
    }

    Object.entries(row.metadatumReferences).forEach(([paramKey, reference]) => {
      if (reference !== undefined && isFileMetadatumReference(reference)) {
        const currentValue = row.params[paramKey];
        const sourceFileIds = uniq(
          (row.params[reference.source] ?? []) as string[],
        );

        const validFileValues = sourceFileIds
          .map((fileId) => metadataMap[fileId]?.[reference.metadatum_key])
          .filter(isDefined);

        // Only 1 matching set of values from a single file
        if (validFileValues.length === 1) {
          // only one matching metadatum in the file
          if (validFileValues[0].length === 1) {
            // Default the tool param's value if there is only 1 option
            const onlyValidValue = validFileValues[0][0];
            row.params[paramKey] = onlyValidValue;
          }
        }

        // Reset the tool param's value if the current value isn't valid
        else if (
          !validFileValues.some((valueArray) =>
            valueArray.includes(currentValue),
          )
        ) {
          row.params[paramKey] = undefined;
        }
      }
    });

    return row;
  });
};

/**
 * Synchronizes the values of tool parameter's populated using recording
 * metadatum references. Executed analysis rows will not be updated.
 * @param rowData
 * @returns The updated row data.
 */
const syncRecordingMetadatumReferences = async (
  rowData: ToolParamsGridRowDatum[],
) => {
  // Get the recording IDs from every unexecuted analysis row with a recording
  // metadatum reference
  const recordingIds = rowData.flatMap((row) => {
    if (row.metadatumReferences === undefined || row.task_id !== undefined) {
      return [];
    }

    const recordingMetadatumReferences = Object.values(row.metadatumReferences)
      .filter(isDefined)
      .filter(isRecordingMetadatumReference);

    return recordingMetadatumReferences.flatMap(() => row.recordings ?? []);
  });

  // Create a map from recording ID -> metadatum key -> metadatum values
  const metadata = await getRecordingMetadata(recordingIds);
  const metadataMap: Record<string, Record<string, ToolParamValue>> = {};
  metadata.forEach((metadatum) => {
    set(metadataMap, [metadatum.recordingId, metadatum.key], metadatum.value);
  });

  // Update values of tool params with metadatum references
  return rowData.map((row) => {
    // Do nothing for executed rows and rows without metadatum references
    if (row.metadatumReferences === undefined || row.task_id !== undefined) {
      return row;
    }

    Object.entries(row.metadatumReferences).forEach(([paramKey, reference]) => {
      if (isDefined(reference) && isRecordingMetadatumReference(reference)) {
        const currentValue = row.params[paramKey];
        const recordingIds = uniq(row.recordings ?? []);
        const validValues = recordingIds.map(
          (id) => metadataMap[id][reference.metadatum_key],
        );

        // Default the tool param's value if there is only option
        if (validValues.length === 1) {
          row.params[paramKey] = validValues[0];
        }
        // Reset the tool param's value if the current value isn't valid
        else if (!validValues.includes(currentValue)) {
          row.params[paramKey] = undefined;
        }
      }
    });

    return row;
  });
};

/**
 * Synchronizes the values of tool parameters populated using metadatum
 * references. This is necessary if the metadatum reference source (recording
 * or file) has changed. If a parameter's previous value is no longer valid,
 * reset it to `undefined` or auto-populate it if the new source only has one
 * possible value. Data for executed analysis rows will not be updated.
 * @param rowData
 * @returns The updated row data.
 */
export const syncMetadatumReferences = async (
  rowData: ToolParamsGridRowDatum[],
) => {
  let newRowData = cloneDeep(rowData);
  newRowData = await syncRecordingMetadatumReferences(newRowData);
  newRowData = await syncFileMetadatumReferences(newRowData);
  return newRowData;
};
