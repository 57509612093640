import axios from "axios";
import {
  ApplicationUser,
  Metadatum,
  FileRecordingGroup,
  Tenant,
} from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { Except, JsonValue } from "type-fest";

type CreateRecordingGroupMetadataValueBulkInput = {
  file_recording_group: FileRecordingGroup["id"];
  metadata_key: Metadatum["key"];
  value: JsonValue;
  date_created: string;
  tenant: Tenant["id"];
  user: ApplicationUser["id"];
}[];

type CreateRecordingGroupMetadataValueBulkResponse = {
  data: {
    tenant: Tenant["id"];
    user: ApplicationUser["id"];
  }[];
};

export const useRecordingMetadataValueCreateBulkDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const createRecordingMetadata = async (
    inputs: Except<
      CreateRecordingGroupMetadataValueBulkInput[number],
      "tenant" | "user"
    >[],
  ) => {
    const body = inputs.map((input) => ({
      ...input,
      tenant: currentTenant.id,
      user: currentUser.id,
    }));

    const url = getEnvVar(
      "URL_METADATA_FILE_RECORDING_GROUP_METADATA_VALUE_CREATE_BULK",
    );

    const headers = await getRequestHeaders();

    const res = await axios.post<
      unknown,
      CreateRecordingGroupMetadataValueBulkResponse,
      CreateRecordingGroupMetadataValueBulkInput
    >(url, body, { headers });

    return res.data;
  };

  return { createRecordingMetadata };
};
