/** @jsxImportSource @emotion/react */

import { captureException } from "@sentry/react";
import { useTasksTableQuery } from "graphql/_Types";
import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import AppLoading from "components/AppLoading/AppLoading";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ToolSpec } from "components/ToolParamsGrid/ToolParamsGrid.types";
import {
  FlyoutDrsFileInfo,
  FlyoutDrsFileInfoProps,
} from "components/FlyoutDrsFileInfo/FlyoutDrsFileInfo";
import { ToolParamValue } from "components/ToolParamsGrid/ToolParamsGrid.types";
import { orderBy } from "lodash";
import { Helmet } from "react-helmet-async";
import { useCallback, useMemo, useState } from "react";
import { formatInterval } from "utils/formatInterval";
import { isNonNull } from "utils/isNonNull";
import { roundToSignificant } from "utils/roundToSignificant";
import { useProjectDataContext } from "../ProjectDataProvider";
import { PageProjectTasksHeader } from "./PageProjectTasksHeader";
import { TasksTable, TasksTableProps } from "./TasksTable";
import { useSelectedDrsFileId } from "stores/useSelectedDrsFileId";
import { usePageProjectTasksStyles } from "./usePageProjectTasksStyles";
import { isNullish } from "@apollo/client/cache/inmemory/helpers";

export const PageProjectTasks = () => {
  const styles = usePageProjectTasksStyles();
  const [selectedFile, setSelectedFile] = useState<
    FlyoutDrsFileInfoProps["drsFile"] | undefined
  >();

  const { selectDrsFile, deselectDrsFile } = useSelectedDrsFileId();
  const { project } = useProjectDataContext();

  const onClickFile: NonNullable<TasksTableProps["onClickFile"]> = useCallback(
    (file) => {
      selectDrsFile(file.id);
      setSelectedFile(file);
    },
    [selectDrsFile],
  );

  const { loading, data } = useTasksTableQuery({
    variables: {
      filter: {
        projectId: { equalTo: project.id },
        taskId: { isNull: false },
      },
    },
    fetchPolicy: "cache-and-network",
    onError: (err) => captureException(err),
  });

  const tasks = useMemo(() => {
    const tasks = data?.analysisTableRows?.nodes
      .map((row) => {
        const { task, table } = row;
        const group = table?.group;

        if (
          task === null ||
          table === null ||
          table.name === null ||
          isNullish(group)
        ) {
          return null;
        }

        const { id, credits, status, user, created, cloned } = task;

        const toolSpec = (table.toolVersion?.toolSpec ??
          null) as ToolSpec | null;

        if (toolSpec === null) {
          return null;
        }

        const interval = task.taskActivityByTaskId?.duration;

        const durationString = interval ? formatInterval(interval) : "";

        const formattedTask: TasksTableProps["tasks"][number] = {
          id,
          credits: roundToSignificant(credits ?? 0),
          cloned,
          status,
          user,
          created,
          table: {
            toolSpec,
            id: table.id,
            identifiers: table.identifiers,
            name: `${group.name} (${table.name})`,
            group,
          },
          row: { ...row, data: row.data as Record<string, ToolParamValue> },
          duration:
            interval !== undefined
              ? {
                  readableString: durationString,
                  interval,
                }
              : undefined,
          resultsByKey: row.task?.outputGroup?.outputGroupFiles.nodes
            .flatMap(({ drsFile }) => drsFile)
            .filter(isNonNull)
            .reduce<Record<string, string>>((resultsByKey, file) => {
              if (file.key !== null) {
                resultsByKey[file.key] = file.id;
              }
              return resultsByKey;
            }, {}),
        };
        return formattedTask;
      })
      .filter(isNonNull);
    if (tasks !== undefined) {
      return orderBy([...tasks], [(task) => task.created], ["desc"]);
    }
  }, [data?.analysisTableRows?.nodes]);

  const onCloseFlyout = useCallback(() => {
    setSelectedFile(undefined);
    if (selectedFile) {
      deselectDrsFile(selectedFile.id);
    }
  }, [deselectDrsFile, selectedFile]);

  if (tasks) {
    return (
      <>
        <Helmet title={`${project.name}: All Tasks`} />
        <div css={styles.page}>
          <EuiFlexGroup direction="column">
            <PageProjectTasksHeader />
            <EuiFlexGroup
              responsive={false}
              gutterSize={"none"}
              css={styles.gridAndFlyoutContainer}
            >
              <EuiFlexItem>
                <TasksTable
                  tasks={tasks}
                  project={project}
                  onClickFile={onClickFile}
                />
              </EuiFlexItem>
              {selectedFile && (
                <EuiFlexItem grow={false} css={styles.flyoutContainer}>
                  <FlyoutDrsFileInfo
                    onClose={onCloseFlyout}
                    drsFile={selectedFile}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexGroup>
        </div>
      </>
    );
  }

  if (loading) {
    return <AppLoading />;
  }

  return <CallOutError />;
};
