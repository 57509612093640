import { useState } from "react";
import { EuiFlexGroup, EuiFlexItem, EuiPagination } from "@inscopix/ideas-eui";
import { VegaLite, VisualizationSpec } from "react-vega";
import { Handler } from "vega-tooltip";
import { css } from "@emotion/react";

type TFormattedTraceData = {
  cell: string;
  time: number;
  zscore: number | null;
};

export interface FilePreviewCellSetTracesProps {
  content: {
    [cellName: string]: number;
    Time: number;
  }[];
}

/**
 * Finds the average time between periods for a given number of samples
 * @param traces an array of cellset traces
 * @param samples the number of frames used to calculate the average
 * @returns a float representing the average period
 */
function getAveragePeriodFromTraces(
  traces: Array<{ [key: string]: number }>,
  samples = 10,
) {
  let sum = 0;
  const max_samples = Math.min(traces.length, samples);
  for (let i = 0; i < max_samples; i++) {
    sum += traces[i]["Time"];
  }
  return sum / samples;
}

/**
 * Formats cell traces data into the array structure required by Vega
 * @param tracesPreviewData an array of cellset traces
 * @param pageSize the number of traces returned for pagination
 * @param activePage current page being viewed
 * @returns a formatted cell traces array
 */
function formatTracesPreviewForVega(
  content: {
    [cellName: string]: number;
    Time: number;
  }[],
  pageSize: number,
  activePage: number,
) {
  const formattedData: TFormattedTraceData[] = [];
  const cellNumRegex = /C0*([1-9]\d*)/;
  const numCells = Object.keys(content).length - 1;
  const maxPages = numCells < pageSize ? 1 : Math.ceil(numCells / pageSize);
  const startCell = activePage * pageSize;
  const endCell = Math.min(activePage * pageSize + pageSize - 1, numCells - 1);
  let prevTime = 0.0;
  const period = getAveragePeriodFromTraces(content);

  const getCellNumFromKey = (key: string) => {
    const match = cellNumRegex.exec(key);
    return match !== null && match.length > 0 ? parseInt(match[1]) : 0;
  };

  const getFormattedTraces = (
    period: number,
    frame: number,
    key: string,
    time: number,
    prevTime: number,
    content: {
      [cellName: string]: number;
      Time: number;
    }[],
  ) => {
    const formattedTraceData: TFormattedTraceData[] = [];
    // test to see if there's a significant gap between this timestamp and the last (10+ periods)
    // if there is, add a null value in between the last value and this one
    // this breaks the line Vega renders in the chart, showing the gap
    const isPeriodGapSignificant =
      period !== 0.0 && period * 10 < time - prevTime;
    if (isPeriodGapSignificant) {
      formattedTraceData.push({
        cell: key,
        time: prevTime + period * 10,
        zscore: null,
      });
    }

    // ensure there is a value for the frame and cell key
    const isCellData = content[frame][key] !== null;
    // push formatted data to output array
    if (isCellData) {
      formattedTraceData.push({
        cell: key,
        time: time,
        zscore: content[frame][key],
      });
    }
    return formattedTraceData;
  };

  content.forEach((_, frame) => {
    const time = content[frame]["Time"];
    for (const key in content[frame]) {
      // ignore the Time key, we only want cell data
      if (key === "Time") continue;
      // extract cell number (expecting format C###)
      const cellNum = getCellNumFromKey(key);
      // ignore cells outside our page range
      if (cellNum > endCell || cellNum < startCell) {
        continue;
      }
      // get formatted data (with null row if needed)
      const formattedTraces = getFormattedTraces(
        period,
        frame,
        key,
        time,
        prevTime,
        content,
      );
      // append new formatted rows to formattedData
      if (formattedTraces.length > 0) formattedData.push(...formattedTraces);
    }
    prevTime = time;
  });

  return {
    formattedData,
    maxPages: maxPages,
    numCells: numCells,
    startCell: startCell,
    endCell: endCell,
  };
}

// style for container
const tracePreviewContainerStyle = css`
  width: 520px;
  max-height: 420px;
  overflow-y: scroll;
`;

/**
 * Renders a cell traces Vega visualization for a given cellset-group data object
 */
export function FilePreviewCellSetTraces({
  content,
}: FilePreviewCellSetTracesProps) {
  // pagination state for traces
  const [activePage, setActivePage] = useState(0);
  const pageSize = 50;

  // get Vega formatted data array
  const { formattedData, maxPages } = formatTracesPreviewForVega(
    content,
    pageSize,
    activePage,
  );

  const spec = {
    width: 500,
    height: 30,
    data: { name: "traces" },
    view: { stroke: null },
    mark: {
      type: "line",
    },
    encoding: {
      x: {
        field: "time",
        type: "quantitative",
        range: "width",
        domain: { data: "traces", field: "time" },
      },
      y: {
        field: "zscore",
        type: "quantitative",
        title: null,
        labels: false,
        scale: { domain: [0, 40] },
        axis: { title: null, labels: false, ticks: false },
      },
      defined: false,
      row: { field: "cell", spacing: 0, title: null },
      color: { field: "cell", type: "nominal" },
    },
  } as VisualizationSpec;

  return (
    <div css={tracePreviewContainerStyle}>
      <EuiFlexGroup justifyContent="spaceBetween">
        <EuiFlexItem grow={true}>
          {
            //@TODO limit cells in pagination to visible/accepted cells only
            // disabled for now to prevent confusiion
            /*
                <span>
                  Viewing cells {formatCellNumber(startCell)} to{" "}
                  {formatCellNumber(endCell)} ({numCells} total)
                </span>
                */
          }
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiPagination
            pageCount={maxPages}
            activePage={activePage}
            onPageClick={(activePage) => setActivePage(activePage)}
            compressed
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <div>
        <VegaLite
          spec={spec}
          data={{
            traces: [...formattedData],
          }}
          config={{
            axis: { grid: false, domain: false },
            legend: { disable: true },
          }}
          tooltip={new Handler().call}
        />
      </div>
    </div>
  );
}
