import { ReactNode } from "react";
// eslint-disable-next-line no-restricted-imports
import { useFileUploadManager } from "./useFileUploadManager";

interface FileUploadManagerProps {
  children: ReactNode;
}

/**
 * Singleton component that manages the queue of uploading files
 */
export const FileUploadManager = ({ children }: FileUploadManagerProps) => {
  useFileUploadManager();
  return <>{children}</>;
};
