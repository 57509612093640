import { EuiIcon, EuiIconProps } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";

export const IconComputeCredits = (props: Omit<EuiIconProps, "type">) => {
  return (
    <Tooltip content="Compute credits">
      <EuiIcon type="compute" {...props} />
    </Tooltip>
  );
};
