import { useCallback, useState } from "react";
import { ModalDatasetFields, ModalDatasetProps } from "./ModalDataset";
import { useProjectDataContext } from "../../pages/project/ProjectDataProvider";
import { useGetAllDatasetsLazyQuery } from "../../graphql/_Types";
import { DatasetStatus } from "types/constants";
import assert from "assert";
import { isNonNullish } from "utils/isNonNullish";

type FieldValidationErrors = Partial<Record<keyof ModalDatasetFields, string>>;

export const useValidateModalDatasetFields = (
  edit: ModalDatasetProps["edit"],
) => {
  const [fieldValidationErrors, setFieldValidationErrors] =
    useState<FieldValidationErrors>({});
  const { project } = useProjectDataContext();

  const [getDatasets] = useGetAllDatasetsLazyQuery({
    fetchPolicy: "network-only",
  });

  const handleFieldValidationErrors = useCallback(
    (validationErrors: typeof fieldValidationErrors) => {
      setFieldValidationErrors(validationErrors);
      const isValid = Object.keys(validationErrors).length === 0;
      return isValid;
    },
    [],
  );

  const validateFields = useCallback(
    async (fields: ModalDatasetFields) => {
      const validationErrors: FieldValidationErrors | undefined = {};
      if (fields.name === "") {
        validationErrors.name = "You must specify a name";
      }
      if (fields.prefix === "") {
        validationErrors.prefix = "You must specify a recording ID prefix";
      }

      const isPrefixInvalid = "prefix" in validationErrors;
      const isUserEditingAndKeyIsUnchanged =
        edit?.dataset.prefix === fields.prefix;
      // avoid the network request to validate the prefix if we are editing and haven't changed it
      // or if it's already invalid for some other reason
      if (isPrefixInvalid || isUserEditingAndKeyIsUnchanged) {
        return handleFieldValidationErrors(validationErrors);
      }

      const { data: datasetsQuery } = await getDatasets({
        variables: {
          filter: {
            and: [
              {
                projectId: {
                  equalTo: project.id,
                },
              },
              {
                prefix: {
                  equalTo: fields.prefix,
                },
              },
              {
                status: {
                  in: [DatasetStatus["AVAILABLE"], DatasetStatus["ARCHIVED"]],
                },
              },
            ],
          },
        },
      });
      const matchingDatasets = datasetsQuery?.datasets?.nodes;
      assert(isNonNullish(matchingDatasets), "Failed to fetch datasets");
      const isPrefixUnique = matchingDatasets.length === 0;
      if (!isPrefixUnique) {
        validationErrors.prefix = `Your recording ID prefix must be unique to this project - ${fields.prefix} is already in use.`;
      }

      return handleFieldValidationErrors(validationErrors);
    },
    [
      edit?.dataset.prefix,
      getDatasets,
      handleFieldValidationErrors,
      project.id,
    ],
  );

  return {
    validateFields,
    fieldValidationErrors,
  };
};
