import axios from "axios";
import { Tenant } from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";

/**
 * Represents the options passed to {@link renameOrganization}
 */
type RenameOrganizationOptions = {
  tenantId: Tenant["id"];
  newName: Tenant["name"];
};

/**
 * Gets all pending tenant-user initiations.
 * @param options
 * @returns The pending invitations.
 */
export const renameOrganization = async ({
  tenantId,
  newName,
}: RenameOrganizationOptions) => {
  const baseUrl = getEnvVar("URL_TENANT");
  const url = `${baseUrl}${tenantId}/`;
  const body = { name: newName };
  const headers = await getRequestHeaders();
  await axios.patch(url, body, { headers });
};
