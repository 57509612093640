import { EuiSplitPanel, EuiText } from "@inscopix/ideas-eui";
import { TenantMembershipRole } from "types/TenantMembershipRole";

interface UserRoleBadgeProps {
  role: TenantMembershipRole;
}

/** Component that renders a badge displaying a user's role in the tenant */
export const UserRoleBadge = ({ role }: UserRoleBadgeProps) => {
  return (
    <EuiSplitPanel.Outer direction="row" hasShadow={false} hasBorder>
      <EuiSplitPanel.Inner color="subdued" grow paddingSize="s">
        <EuiText style={{ fontSize: 12 }}>
          <strong>Role</strong>
        </EuiText>
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner grow paddingSize="s">
        <EuiText style={{ fontSize: 14 }}>
          <p>{role.name}</p>
        </EuiText>
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  );
};
