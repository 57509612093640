/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import {
  CellClassParams,
  ColDef,
  ICellRendererParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { GridRendererToolPathParam } from "components/ToolParamsGrid/GridRendererToolPathParam";
import {
  ToolParam,
  ToolParamValue,
} from "components/ToolParamsGrid/ToolParamsGrid.types";
import { useMemo } from "react";
import { TaskStatus } from "types/constants";
import { isDefined } from "utils/isDefined";
import { TasksTableDetailRendererProps } from "./TasksTableDetailRenderer";
import { isNullish } from "utils/isNullish";

type RowData = {
  name: string;
  paramValue: ToolParamValue;
  paramType: ToolParam["type"]["param_type"];
  group?: { name: string; size: number };
};

export const TasksTableDetailRendererParamsGrid = ({
  data,
  onClickFile,
}: TasksTableDetailRendererProps) => {
  const toolSpec = data.table.toolSpec;
  const taskParams = data.row.data;

  const { groupedParams, ungroupedParams } = toolSpec.params.reduce<{
    groupedParams: { groupKey: string; groupParams: ToolParam[] }[];
    ungroupedParams: ToolParam[];
  }>(
    ({ groupedParams, ungroupedParams }, param) => {
      const paramGroup = param.type.display;

      if (paramGroup !== undefined) {
        const groupIndex = groupedParams.findIndex(
          (param) => param.groupKey === paramGroup.group,
        );
        if (groupIndex > -1) {
          groupedParams[groupIndex].groupParams.push(param);
        } else {
          groupedParams.push({
            groupKey: paramGroup.group,
            groupParams: [param],
          });
        }
      } else {
        ungroupedParams.push(param);
      }

      return { groupedParams, ungroupedParams };
    },
    { groupedParams: [], ungroupedParams: [] },
  );

  const toolParamRowData = [...groupedParams, ...ungroupedParams].reduce<
    RowData[]
  >((toolParamRowData, paramOrGroup) => {
    const parseParam = (param: ToolParam): RowData => {
      return {
        name: param.name,
        paramType: param.type.param_type,
        paramValue: taskParams[param.key],
      };
    };

    if ("groupKey" in paramOrGroup) {
      // unpack groups
      paramOrGroup.groupParams.forEach((param, i) => {
        const rowDatum = parseParam(param);
        if (i === 0) {
          // add group info to first param for cell grouping
          rowDatum.group = {
            name: paramOrGroup.groupKey,
            size: paramOrGroup.groupParams.length,
          };
        }
        toolParamRowData.push(rowDatum);
      });
    } else {
      toolParamRowData.push(parseParam(paramOrGroup));
    }
    return toolParamRowData;
  }, []);

  const resultsRowData: RowData[] = toolSpec.results[0].files.map(
    (resultFile, i, results) => {
      const rowDatum: RowData = {
        name: resultFile.result_name,
        paramValue: [data.resultsByKey?.[resultFile.result_key]].filter(
          isDefined,
        ),
        paramType: "ToolPathParam",
      };
      if (i === 0) {
        rowDatum.group = { name: "Analysis Results", size: results.length };
      }

      return rowDatum;
    },
  );

  const rowData = [...toolParamRowData];

  if (data.status === TaskStatus["COMPLETE"]) {
    rowData.push(...resultsRowData);
  }

  const colDefs = useMemo(() => {
    const colDefs: ColDef<RowData>[] = [
      {
        headerName: "",
        cellDataType: false,
        valueGetter: ({ data }) => data?.group,
        rowSpan: (params) => {
          const groupSize = params.data?.group?.size ?? 0;
          if (groupSize > 0) {
            return groupSize;
          }
          return 1;
        },

        cellStyle: { padding: 0 },
        cellRenderer: (params: ICellRendererParams<RowData>) => {
          if (isNullish(params.value) || params.data === undefined) {
            return null;
          }
          return (
            <div
              css={css`
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                padding: 0px;
                margin: 0px;
              `}
            >
              {params.data.group?.name}
            </div>
          );
        },
        flex: 1,
      },
      {
        headerName: "Parameter",
        valueGetter: ({ data }) => data?.name,
        flex: 1.5,
      },
      {
        headerName: "Value",
        valueGetter: ({ data }) => data?.paramValue,

        cellStyle: ({ data }: CellClassParams<RowData>) => {
          // remove padding for cells with file badges, which have their own padding
          return data?.paramType === "ToolPathParam"
            ? { padding: 0 }
            : undefined;
        },
        cellRenderer: ({ data }: ICellRendererParams<RowData>) => {
          if (data === undefined) {
            return null;
          }
          if (data.paramType === "ToolPathParam") {
            return (
              <div
                css={css`
                  & {
                    justify-content: left;
                  }
                `}
              >
                <GridRendererToolPathParam
                  fileIds={data.paramValue as string[]}
                  onClickFileBadge={(file) => {
                    if (onClickFile !== undefined) {
                      onClickFile(file);
                    }
                  }}
                />
              </div>
            );
          }
          return <span>{data.paramValue}</span>;
        },
        flex: 3,
      },
    ];
    return colDefs;
  }, [onClickFile]);

  return (
    <AgGridReact<RowData>
      css={css`
        padding: 1px;
        display: block;
      `}
      rowData={rowData}
      rowHeight={35}
      columnDefs={colDefs}
      defaultColDef={{
        valueGetter: ({ data }) => data?.paramValue,
        resizable: true,
        suppressMenu: true,
      }}
      suppressRowTransform
    />
  );
};
