import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import { captureException } from "@sentry/react";
import assert from "assert";
import { ToolSpec } from "components/ToolParamsGrid/ToolParamsGrid.types";
import {
  AnalysisTableGroup,
  DatasetFieldsFragment,
  DatasetVersionFieldsFragment,
  Metadatum,
  Project,
  ProjectFieldsFragment,
  usePageProjectQuery,
} from "graphql/_Types";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { chain, cloneDeep, isUndefined, keyBy, sortBy } from "lodash";
import { useMemo } from "react";
import { IdeasQueryState, NullishDataError } from "types/IdeasQueryState";
import { ToolboxInformationSchema } from "types/ToolboxInformationSchema";
import { isNonNull } from "utils/isNonNull";
import { isNonNullish } from "utils/isNonNullish";

type TProjectQueryData = NonNullable<
  NonNullable<ReturnType<typeof usePageProjectQuery>["data"]>["project"]
>;

type TAnalysisTable =
  TProjectQueryData["analysisTableGroups"]["nodes"][number]["analysisTables"]["nodes"][number];

type AnalysisResult =
  ToolboxInformationSchema["tools"][number]["results"][number]["files"][number];

export type ProjectDataset = DatasetFieldsFragment & {
  description: Pick<Metadatum, "id"> & {
    value: string;
  };
  datasetVersions: DatasetVersionFieldsFragment[];
};

export type PageProjectData = {
  project: ProjectFieldsFragment;
  analysisTableGroups: (Omit<
    TProjectQueryData["analysisTableGroups"]["nodes"][number],
    "analysisTables"
  > & {
    id: AnalysisTableGroup["id"];
    tool: NonNullable<
      NonNullable<
        TProjectQueryData["analysisTableGroups"]["nodes"][number]
      >["tool"]
    >;
    analysisTables: (TAnalysisTable & {
      toolSpec: ToolSpec;
      toolVersion: NonNullable<TAnalysisTable["toolVersion"]> & {
        tool: NonNullable<NonNullable<TAnalysisTable["toolVersion"]>["tool"]>;
      };
      name: string;
    })[];
  })[];
  datasets: ProjectDataset[];
  analysisResultsByTableId: {
    [analysisTableId: string]: {
      [resultKey: string]: AnalysisResult;
    };
  };
};

export const usePageProjectData = ({
  projectKey,
}: {
  projectKey: Project["key"];
}): IdeasQueryState<PageProjectData> => {
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { loading, error, data } = usePageProjectQuery({
    variables: {
      projectKey,
      tenantId: currentTenant.id,
    },
    fetchPolicy: "cache-and-network",
    onError: (err) => captureException(err),
  });

  const datasets = useMemo(() => {
    const datasets = cloneDeep(data?.project?.datasets.nodes);

    if (isNullish(datasets)) {
      return;
    }

    // TODO https://inscopix.atlassian.net/browse/ID-2447
    // This is sorted on the frontend as a temporary solution due to the fact that cache fragments
    // are cached separately for each set of query variables
    return sortBy(datasets, ({ dateCreated }) => dateCreated).map((dataset) => {
      const description = dataset.description;
      const descriptionValue = description?.metadataValuesByMetadataId.nodes[0]
        .value as string | undefined;
      assert(
        isNonNullish(description) && descriptionValue !== undefined,
        "Expected dataset description metadatum and value to be non nullish",
      );
      const formattedDescription = {
        id: description.id,
        value: descriptionValue,
      };
      return {
        ...dataset,
        description: formattedDescription,
        datasetVersions: dataset.datasetVersions.nodes,
      };
    });
  }, [data?.project?.datasets.nodes]);

  if (loading) {
    return { loading: true, error: undefined, data: undefined };
  }

  if (error) {
    return { loading: false, error, data: undefined };
  }

  const project = data?.project;
  const analysisTableGroups = project?.analysisTableGroups.nodes
    .map((group) => {
      const tool = group.tool;
      if (tool === null) {
        return null;
      }
      return {
        ...group,
        tool,
        analysisTables: group.analysisTables.nodes
          .map((table) => {
            const toolSpec = (table.toolVersion?.toolSpec ?? undefined) as
              | ToolSpec
              | undefined;

            if (toolSpec === undefined) {
              captureException("toolSpec undefined", {
                extra: { analysisTableId: table.id },
              });
              return null;
            }

            const toolVersion = table.toolVersion;
            if (toolVersion === null) {
              captureException("toolVersion undefined", {
                extra: { analysisTableId: table.id },
              });
              return null;
            }

            const tool = toolVersion.tool;

            if (tool === null) {
              captureException("tool undefined", {
                extra: { analysisTableId: table.id },
              });
              return null;
            }

            if (table.name === null) {
              captureException("analysis table name undefined", {
                extra: { analysisTableId: table.id },
              });
              return null;
            }

            return {
              ...table,
              name: table.name,
              toolSpec,
              toolVersion: { ...toolVersion, tool: { ...tool } },
            };
          })
          .filter(isNonNull),
      };
    })
    .filter(isNonNull);

  if (
    isNullish(project) ||
    isUndefined(analysisTableGroups) ||
    isNullish(datasets)
  ) {
    return { loading: false, error: new NullishDataError(), data: undefined };
  }

  const analysisResultsByTableId = chain(analysisTableGroups)
    .flatMap((group) => group.analysisTables)
    .keyBy(({ id }) => id)
    .mapValues((analysisTable) => {
      const analysisResults = analysisTable.toolSpec.results[0].files;
      return keyBy(analysisResults, ({ result_key }) => result_key);
    })
    .value();

  return {
    loading: false,
    error: undefined,
    data: {
      project,
      analysisTableGroups,
      datasets,
      analysisResultsByTableId,
    },
  };
};
