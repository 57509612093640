import {
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  ToolTipPositions,
} from "@inscopix/ideas-eui";
import { PanelInsertDataColumn } from "./PanelInsertDataColumn";
import PanelInsertAnalysisResultColumns from "./PanelInsertAnalysisResultColumn";
import { PanelInsertCustomMetadataColumn } from "./PanelInsertCustomMetadataColumn";
import { PanelInsertLinkedMetadataColumn } from "./PanelInsertLinkedMetadataColumn";
import { COLUMN_TYPE_ICONS } from "types/Icons";

type MenuInsertColumnProps = {
  onClose: () => void;
};

/**
 * Context menu component for adding a column to a recordings table
 */
export const MenuInsertColumn = ({ onClose }: MenuInsertColumnProps) => {
  enum PANELS {
    CHOOSE_COLUMN,
    DATA_COLUMN,
    RESULT_COLUMN,
    CUSTOM_METADATA,
    LINKED_METADATA,
  }

  const width: EuiContextMenuPanelDescriptor["width"] = 400;
  const toolTipPosition: ToolTipPositions = "right";

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: PANELS.CHOOSE_COLUMN,
      title: "Select column type",
      width,
      initialFocusedItemIndex: 0,
      items: [
        {
          name: "Uploaded data",
          icon: COLUMN_TYPE_ICONS.dataColumn,
          toolTipContent: "Create a column to store uploaded files",
          toolTipProps: {
            position: toolTipPosition,
          },
          panel: PANELS.DATA_COLUMN,
        },
        {
          name: "Analysis Result",
          icon: COLUMN_TYPE_ICONS.analysisResult,
          toolTipContent:
            "Add columns to display result files from Analysis Tables",
          toolTipProps: {
            position: toolTipPosition,
          },
          panel: PANELS.RESULT_COLUMN,
        },
        {
          name: "Custom Metadata",
          icon: COLUMN_TYPE_ICONS.customMetadata,
          toolTipContent:
            "Create a custom column that allows free text entry - use this to add new information and annotation to your recordings.",
          toolTipProps: {
            position: toolTipPosition,
          },
          panel: PANELS.CUSTOM_METADATA,
        },
        {
          name: "Linked Metadata",
          icon: COLUMN_TYPE_ICONS.linkedMetadata,
          toolTipContent:
            "Add a column to display metadata values from uploaded or result files",
          toolTipProps: {
            position: toolTipPosition,
          },
          panel: PANELS.LINKED_METADATA,
        },
      ],
    },
    {
      id: PANELS.DATA_COLUMN,
      width,
      title: "Insert data column",
      content: <PanelInsertDataColumn onClose={onClose} />,
    },
    {
      id: PANELS.RESULT_COLUMN,
      width,
      title: "Insert Analysis Result Columns",
      content: <PanelInsertAnalysisResultColumns onClose={onClose} />,
    },
    {
      id: PANELS.CUSTOM_METADATA,
      width,
      title: "Insert Custom Metadata Column",
      content: <PanelInsertCustomMetadataColumn onClose={onClose} />,
    },
    {
      id: PANELS.LINKED_METADATA,
      width,
      title: "Insert Linked Metadata Column",
      content: <PanelInsertLinkedMetadataColumn onClose={onClose} />,
    },
  ];

  return <EuiContextMenu initialPanelId={0} panels={panels} />;
};
