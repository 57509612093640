import { useEuiTheme } from "@inscopix/ideas-eui";
import {
  FileBadgeBase,
  FileBadgeBaseProps,
} from "components/FileBadge/FileBadgeBase";

export type FileNotFoundProps = Pick<FileBadgeBaseProps, "isCompact">;
export const FileNotFoundBadge = ({ isCompact }: FileNotFoundProps) => {
  const { euiTheme } = useEuiTheme();
  return (
    <FileBadgeBase
      isCompact={isCompact}
      backgroundColor={euiTheme.colors.warning}
      icon="alert"
      name="File Not Found"
    />
  );
};
