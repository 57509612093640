import {
  EuiBadge,
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { ICellEditorParams } from "ag-grid-community";
import { cloneDeep } from "lodash";
import { forwardRef, useImperativeHandle, useState } from "react";
import { compare } from "semver";
import "./GridEditorToolChoiceParam.scss";
import { ToolParamsGridRowDatum } from "./ToolParamsGrid.types";
import { useToolParamsGridContext } from "./ToolParamsGridProvider";

type GridEditorToolVersionProps = ICellEditorParams<ToolParamsGridRowDatum>;

/**
 * A component for changing the tool version of a row
 */
export const GridEditorToolVersion = forwardRef(function GridEditorToolVersion(
  { stopEditing, data, column, node }: GridEditorToolVersionProps,
  ref,
) {
  const { toolVersions } = useToolParamsGridContext();
  const [selection, setSelection] = useState(data.toolVersion);

  useImperativeHandle(ref, () => ({
    getValue: () => selection.version,
  }));

  const items = cloneDeep(toolVersions)
    // Sort tool versions by semantic version. If the tool version is not a
    // valid semver, sort it last.
    .sort((a, b) => {
      try {
        return compare(a.version, b.version);
      } catch (error) {
        return -1;
      }
    })
    .map((toolVersion) => (
      <EuiListGroupItem
        id={toolVersion.id.toString()}
        key={toolVersion.id}
        iconType={selection?.id === toolVersion.id ? "check" : "empty"}
        label={
          <EuiBadge color="hollow" style={{ fontFamily: "monospace" }}>
            {toolVersion.version}
          </EuiBadge>
        }
        onClick={() => {
          setSelection(toolVersion);
        }}
      />
    ));

  return (
    <EuiPopover
      anchorPosition="downLeft"
      button={
        <div
          style={{
            width: column.getActualWidth(),
            height: node.rowHeight ?? 0,
            display: "flex",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          <span style={{ paddingLeft: 17 }}>
            {
              <EuiBadge color="hollow" style={{ fontFamily: "monospace" }}>
                {selection.version}
              </EuiBadge>
            }
          </span>
        </div>
      }
      className="Popover PopoverAnchor"
      closePopover={stopEditing}
      display="block"
      isOpen
      panelPaddingSize="none"
      repositionOnScroll={false}
    >
      <EuiListGroup className="ContextMenu" maxWidth="100%">
        {items}
      </EuiListGroup>
    </EuiPopover>
  );
});
