import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  EuiTextArea,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import {
  CreateDatasetVersionInput,
  useCreateDatasetVersionDjango,
} from "hooks/useCreateDatasetVersionDjango";
import { cloneDeep } from "lodash";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useUpdatePageProjectCache } from "pages/project/hooks/useUpdatePageProjectCache";
import { useEffect, useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { useUpdatePageDatasetCache } from "./hooks/useUpdatePageDatasetCache";
import assert from "assert";
import { useActionQueueStore } from "stores/ActionsQueue/ActionsQueueManager";
import { formatDate } from "utils/formatDate";

interface ModalCreateDatasetVersionProps {
  onClose: () => void;
}
export const ModalCreateDatasetVersion = ({
  onClose,
}: ModalCreateDatasetVersionProps) => {
  const { dataset, project } = useDatasetDataContext();
  const status = useActionQueueStore((s) => s.status);
  const { updateCache: updateDatasetCache } = useUpdatePageDatasetCache(
    dataset.id,
  );
  const { updateCache: updateProjectCache } = useUpdatePageProjectCache(
    project.key,
  );

  const { createDatasetVersionDjango } = useCreateDatasetVersionDjango();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  // get the current datetime timestamp and keep it live
  const [timestamp, setTimestamp] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setTimestamp(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  const saveVersion = async () => {
    setIsLoadingSave(true);
    try {
      const datasetVersionInput: CreateDatasetVersionInput = {
        dataset: dataset.id,
        validation_state: {},
        name: name.trim() !== "" ? name : null,
        description: description.trim() !== "" ? description : null,
      };

      const versionCreated = await createDatasetVersionDjango(
        datasetVersionInput,
      );
      updateDatasetCache((prevData) => {
        const newData = cloneDeep(prevData);
        newData.dataset.datasetVersions.nodes.unshift(versionCreated);
        return newData;
      });
      updateProjectCache((prevData) => {
        const newData = cloneDeep(prevData);
        const _dataset = newData.project.datasets.nodes.find(
          ({ id }) => id === dataset.id,
        );
        assert(
          _dataset !== undefined,
          "Expect dataset to be defined in page project cache",
        );
        _dataset.datasetVersions.nodes.unshift(versionCreated);
        return newData;
      });
      addUtilityToastSuccess("Successfully created dataset version.");
    } catch (err) {
      captureException(err);
      addUtilityToastFailure("Failed to create dataset version.");
    }
    setIsLoadingSave(false);
    onClose();
  };

  const formId = htmlIdGenerator()();
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Save Dataset Version</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm
          component="form"
          role="form"
          id={formId}
          onSubmit={(e) => {
            e.preventDefault();
            void saveVersion();
          }}
        >
          {status === "synced" ? (
            <>
              <EuiFormRow>
                <EuiText size={"s"}>
                  <p>
                    Save a reference to the current state of the dataset. It
                    will automatically be saved with the current timestamp, you
                    can optionally add a name and/or description.
                  </p>
                </EuiText>
              </EuiFormRow>
              <EuiFormRow label="Date Created">
                <EuiFieldText
                  name="timestamp"
                  value={formatDate(timestamp)}
                  disabled
                />
              </EuiFormRow>
              <EuiFormRow
                label="Name (optional)"
                helpText={
                  name.length === 255
                    ? "Max character limit (255) reached"
                    : null
                }
              >
                <EuiFieldText
                  name="name"
                  value={name}
                  placeholder="Enter an optional name for this version"
                  aria-label="Version Name"
                  onChange={(e) => setName(e.target.value)}
                  maxLength={255}
                />
              </EuiFormRow>
              <EuiFormRow
                label="Description (optional)"
                helpText={
                  description.length === 1000
                    ? "Max character limit (1000) reached"
                    : null
                }
              >
                <EuiTextArea
                  name="description"
                  value={description}
                  placeholder="Enter an optional description for this version"
                  aria-label="Version Description"
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={1000}
                />
              </EuiFormRow>
            </>
          ) : (
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={false}>
                <EuiLoadingSpinner />
              </EuiFlexItem>{" "}
              <EuiFlexItem>
                <EuiText>Syncing dataset.</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton type="submit" form={formId} isLoading={isLoadingSave}>
          Save Version
        </EuiButton>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
      </EuiModalFooter>
    </EuiModal>
  );
};
