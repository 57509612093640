/** @jsxImportSource @emotion/react */
import { useDndContext } from "@dnd-kit/core";
import { memo, useState } from "react";
import { File as DrsFile } from "graphql/_Types";
import {
  EuiBadge,
  EuiPopover,
  EuiSpacer,
  useEuiTheme,
} from "@inscopix/ideas-eui";
import { useResizeDetector } from "react-resize-detector";
import { useDatasetLayoutContext } from "../../pages/project/dataset/DatasetLayoutProvider";
import { FileBadge } from "components/FileBadge/FileBadge";
import { useFileGridRendererStyles } from "../../styles/useFileGridRendererStyles/useFileGridRendererStyles";
import { getNumBadgesFit } from "styles/useFileGridRendererStyles/getNumBadgesFit";

interface GridRendererAnalysisResultProps {
  drsFiles: Pick<
    DrsFile,
    | "id"
    | "name"
    | "status"
    | "fileType"
    | "source"
    | "dateCreated"
    | "datasetId"
    | "isSeries"
    | "projectId"
    | "processingStatus"
    | "seriesParentId"
  >[];
}

export const GridRendererAnalysisResult = memo(
  function GridRendererAnalysisResult({
    drsFiles,
  }: GridRendererAnalysisResultProps) {
    const fileGridRendererStyles = useFileGridRendererStyles();
    const { euiTheme } = useEuiTheme();
    const { active } = useDndContext();
    const { width, ref } = useResizeDetector();
    const { openFlyout, openModal } = useDatasetLayoutContext();
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const togglePopover = () => setIsPopoverVisible((isOpen) => !isOpen);
    const closePopover = () => setIsPopoverVisible(false);

    const rootCss = (() => {
      const css = [fileGridRendererStyles.root];

      if (active === null) {
        return css;
      } else {
        const bgColor = euiTheme.colors.lightestShade;
        css.push(fileGridRendererStyles.dropZone(bgColor));
      }

      return css;
    })();
    if (drsFiles.length === 0) return <div css={rootCss} />;
    if (drsFiles.length === 1) {
      const handleClick = () => {
        openFlyout({
          type: "fileInfo",
          props: {
            drsFile: drsFiles[0],
          },
        });
      };

      return (
        <div css={rootCss}>
          <FileBadge
            onClick={handleClick}
            onDelete={() =>
              openModal({
                type: "deleteDrsFile",
                props: {
                  drsFiles: [drsFiles[0]],
                },
              })
            }
            onArchive={() =>
              openModal({
                type: "archiveDrsFile",
                props: {
                  drsFiles: [drsFiles[0]],
                },
              })
            }
            onUnarchive={() =>
              openModal({
                type: "unarchiveDrsFile",
                props: {
                  drsFiles: [drsFiles[0]],
                },
              })
            }
            drsFile={drsFiles[0]}
          />
        </div>
      );
    }
    // Calculate the number of badges that fit in the cell
    const numBadgesFit = getNumBadgesFit(width);

    const isOverflow = numBadgesFit < drsFiles.length;
    const visibleFiles = isOverflow
      ? drsFiles.slice(0, numBadgesFit - 1)
      : drsFiles;

    const button = (
      <div
        css={fileGridRendererStyles.button}
        ref={ref}
        onClick={togglePopover}
      >
        {visibleFiles.map((drsFile, idx, arr) => {
          // if multiple compact badges are displaying side by side, their padding doubles up
          // so for all badges after the first one, compensate with a negative margin
          const compensateForPadding = idx !== 0;
          return (
            <div
              key={drsFile.id}
              css={fileGridRendererStyles.badgeWrapper(compensateForPadding)}
            >
              <FileBadge drsFile={drsFile} compact />
            </div>
          );
        })}
        {isOverflow && (
          <EuiBadge
            css={fileGridRendererStyles.overflowBadge(
              visibleFiles.length !== 0,
            )}
          >
            +{drsFiles.length - visibleFiles.length}
          </EuiBadge>
        )}
      </div>
    );

    return (
      <div css={rootCss}>
        <EuiPopover
          anchorPosition="downCenter"
          button={button}
          closePopover={closePopover}
          isOpen={isPopoverVisible}
          // Hide popover when dragging
          css={fileGridRendererStyles.root}
          panelProps={{
            css: active === null ? undefined : fileGridRendererStyles.invisible,
          }}
        >
          {drsFiles.map((drsFile, idx, arr) => {
            const isLast = idx === arr.length - 1;

            const handleClickBadge = () => {
              openFlyout({
                type: "fileInfo",
                props: {
                  drsFile,
                },
              });
            };

            return (
              <div key={drsFile.id} style={{ display: "flex" }}>
                <FileBadge
                  onClick={handleClickBadge}
                  onDelete={() =>
                    openModal({
                      type: "deleteDrsFile",
                      props: {
                        drsFiles: [drsFile],
                      },
                    })
                  }
                  onArchive={() =>
                    openModal({
                      type: "archiveDrsFile",
                      props: {
                        drsFiles: [drsFile],
                      },
                    })
                  }
                  onUnarchive={() =>
                    openModal({
                      type: "unarchiveDrsFile",
                      props: {
                        drsFiles: [drsFile],
                      },
                    })
                  }
                  drsFile={drsFile}
                />
                {!isLast && <EuiSpacer size="s" />}
              </div>
            );
          })}
        </EuiPopover>
      </div>
    );
  },
);
