import axios from "axios";
import { Project } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { camelCase, mapKeys, snakeCase } from "lodash";
import { SnakeCasedProperties } from "type-fest";

export type LibraryProjectPatchInput = Partial<
  Pick<
    Project,
    | "key"
    | "name"
    | "shortDescription"
    | "iconColor"
    | "userId"
    | "description"
    | "defaultUserAccessLevel"
  >
>;

export type LibraryProjectPatchDjangoResponse = {
  data: SnakeCasedProperties<LibraryProjectPatchInput>;
};

export const useLibraryProjectPatchDjango = () => {
  const libraryProjectPatch = async (
    projectId: Project["id"],
    input: LibraryProjectPatchInput,
  ) => {
    const url = `${getEnvVar("URL_LIBRARY_PROJECT")}${projectId}/`;
    const snakeCasedInput = mapKeys(input, (_, key) => snakeCase(key));
    const headers = await getRequestHeaders();
    const res = await axios.patch<
      LibraryProjectPatchInput,
      LibraryProjectPatchDjangoResponse
    >(url, { ...snakeCasedInput }, { headers });

    return mapKeys(res.data, (_, key) => camelCase(key));
  };

  return { libraryProjectPatch };
};
