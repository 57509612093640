import { EuiText } from "@inscopix/ideas-eui";
import { ICellRendererParams } from "ag-grid-community";
import { RecordingsGridRowDatum } from "./RecordingsGrid.types";

type GridRendererRowNumberProps = ICellRendererParams<
  RecordingsGridRowDatum,
  undefined
>;

export const GridRendererRowNumber = ({ node }: GridRendererRowNumberProps) => {
  return (
    <EuiText size="xs" color={"subdued"}>
      {node.childIndex + 1}
    </EuiText>
  );
};
