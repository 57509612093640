import { useUpdatePageDatasetCache } from "components/Dataset/hooks/useUpdatePageDatasetCache";
import {
  ActionDeleteRecordingFragment,
  ActionDeleteRecordingFragmentDoc,
  FileRecordingGroup,
} from "graphql/_Types";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import { useDeleteRecordingGroupDjango } from "hooks/useDeleteRecordingGroupDjango";
import { getCacheIdsByTypename } from "utils/getCacheIdsByTypename";
import { client } from "providers/ApolloProvider/ApolloProvider";
import gql from "graphql-tag";
import { useProjectPermission } from "hooks/useProjectPermission";

gql`
  fragment ActionDeleteRecording on File {
    id
    assignment: activeAssignment {
      id
      recordingId: fileRecordingGroupId
      recording: fileRecordingGroupByFileRecordingGroupId {
        id
      }
    }
  }
`;

type DeleteRecordingParams = {
  recordingId: FileRecordingGroup["id"];
  dateDeleted: string;
};

export const useActionDeleteRecording = () => {
  const { dataset, datasetMode } = useDatasetDataContext();
  const { updateCache } = useUpdatePageDatasetCache(dataset.id);
  const { deleteRecordingGroupDjango } = useDeleteRecordingGroupDjango();
  const { hasPermission } = useProjectPermission();

  const getActionDef = useCallback(
    (params: DeleteRecordingParams) => {
      return {
        onEnqueue: () => {
          updateCache((prevData) => {
            const newData = cloneDeep(prevData);
            const table = newData.dataset.datasetRecordingsTable;
            table.fileRecordingGroups.nodes =
              table.fileRecordingGroups.nodes.filter(
                ({ id }) => id !== params.recordingId,
              );

            return newData;
          });

          // Unassign any files that belonged to the recording
          getCacheIdsByTypename("File").forEach((cacheId) => {
            client.cache.updateFragment<ActionDeleteRecordingFragment>(
              {
                id: cacheId,
                fragment: ActionDeleteRecordingFragmentDoc,
                returnPartialData: true,
                variables: { at: null },
              },
              (data) => {
                const newData = cloneDeep(data);
                if (
                  newData?.assignment?.recordingId === params.recordingId ||
                  newData?.assignment?.recording?.id === params.recordingId
                ) {
                  newData.assignment = null;
                }
                return newData;
              },
            );
          });
        },

        onDequeue: async () =>
          await deleteRecordingGroupDjango(params.recordingId, {
            date_assignment_ended: params.dateDeleted,
          }),
      };
    },
    [deleteRecordingGroupDjango, updateCache],
  );

  const isDisabled = datasetMode.type !== "current" || !hasPermission("edit");

  return {
    getActionDef,
    isDisabled,
  };
};
