/** @jsxImportSource @emotion/react */
import { EuiBadge } from "@inscopix/ideas-eui";
import { Tooltip } from "../Tooltip/Tooltip";
import { isDefined } from "../../utils/isDefined";
import {
  AnalysisTableIdentifiers,
  ToolParamValue,
  ToolSpec,
} from "../ToolParamsGrid/ToolParamsGrid.types";
import { ShortTaskId } from "../ShortTaskId/ShortTaskId";
import { AnalysisTable, Task } from "graphql/_Types";
import { captureException } from "@sentry/react";
import { formatDate } from "utils/formatDate";

type IdentifierMap = {
  name: string;
  value: string;
};

const defaultIdentifiers = ["task_id", "task_date_created"];

export const parseAnalysisTableIdentifiers = (
  identifiers: AnalysisTableIdentifiers | null,
) => {
  return identifiers !== null ? identifiers : defaultIdentifiers;
};

export interface AnalysisTableRowIdentifierProps {
  params: Record<string, ToolParamValue>;
  task: Pick<Task, "id" | "created">;
  table: Pick<AnalysisTable, "identifiers">;
  toolSpec: ToolSpec;
}

/**
 * A component for rendering an analysis table row's identifier
 * Not context-dependent, takes the tool spec and row data as props
 */
export const AnalysisTableRowIdentifier = ({
  table,
  task,
  params,
  toolSpec,
}: AnalysisTableRowIdentifierProps) => {
  // fallback in case a task ID is not present - should never be reached

  // the one non-param value we currently allow is the date the task was started
  // this is also the default value shown if no others are selected
  const dateStarted = {
    name: "Task Start Date",
    value: formatDate(task.created),
  };

  // if we have identifiers, create a mapping of names and values from the toolspec and row data
  // if not, default to date started
  const identifierNamesAndValues: IdentifierMap[] =
    parseAnalysisTableIdentifiers(table.identifiers as AnalysisTableIdentifiers)
      .map((key) => {
        if (key === "task_id") {
          return undefined;
        }
        if (key === "task_date_created") {
          return dateStarted;
        }
        const toolParam = toolSpec.params.find((param) => param.key === key);
        if (toolParam) {
          const paramValue = params[key];
          const displayValue = Array.isArray(paramValue)
            ? paramValue.join()
            : String(paramValue);
          return {
            name: toolParam.name,
            value: displayValue,
          };
        } else {
          captureException("Unhandled analysis table identifier");
          return undefined;
        }
      })
      .filter(isDefined);

  // tooltip shows the task ID and a list of parameter names and values
  const tooltipParams = identifierNamesAndValues.map(({ name, value }) => {
    return (
      <div key={name}>
        <strong>{name}</strong>: {value}
      </div>
    );
  });

  const tooltipContent = [
    <div key={"id"}>
      <strong>Task ID</strong>: <ShortTaskId taskId={task.id} />
    </div>,
    ...tooltipParams,
  ];

  return (
    <Tooltip content={<>{tooltipContent}</>}>
      <div style={{ display: "inline-block" }}>
        <ShortTaskId taskId={task.id} />
        {identifierNamesAndValues.length > 0 && (
          <EuiBadge color={"hollow"} style={{ fontFamily: "monospace" }}>
            {identifierNamesAndValues.map(({ value }) => value).join(" | ")}
          </EuiBadge>
        )}
      </div>
    </Tooltip>
  );
};
