import { ApplicationUser, Maybe, Project, Scalars } from "graphql/_Types";
import { flatMap, keyBy, uniq } from "lodash";

const RESTRICTED = {
  id: 1,
  key: "restricted",
  name: "Restricted",
  permissions: [],
  icon: "lock",
} as const;

const VIEWER = {
  id: 2,
  key: "viewer",
  name: "Viewer",
  permissions: ["download", "view"],
  icon: "eye",
} as const;

const EDITOR = {
  id: 3,
  key: "editor",
  name: "Editor",
  permissions: [...VIEWER.permissions, "edit", "execute", "upload", "copy"],
  icon: "pencil",
} as const;

const ADMIN = {
  id: 4,
  key: "admin",
  name: "Admin",
  permissions: [...EDITOR.permissions, "grantAccess"],
  icon: "console",
} as const;

// Backend doesn't have a concept of owner as a user role
// but we need it for the frontend in the UI
const OWNER = {
  id: 0,
  key: "owner",
  name: "Owner",
  permissions: [...ADMIN.permissions],
  icon: "userAvatar",
} as const;

export const USER_ACCESS_LEVELS = {
  RESTRICTED,
  VIEWER,
  EDITOR,
  ADMIN,
  OWNER,
} as const;

/***********
  BASE TYPE
 ***********/

export type UserAccessLevel =
  (typeof USER_ACCESS_LEVELS)[keyof typeof USER_ACCESS_LEVELS];

/*******************
  AGGREGATED EXPORTS
 *******************/
export const USER_ACCESS_LEVELS_BY_ID = keyBy(
  USER_ACCESS_LEVELS,
  (UserAccessLevel) => UserAccessLevel.id,
) as {
  [id in UserAccessLevel["id"]]: UserAccessLevel;
} & {
  [unknown: number]: UserAccessLevel | undefined;
};

export const USER_ACCESS_LEVELS_BY_KEY = keyBy(
  USER_ACCESS_LEVELS,
  (UserAccessLevel) => UserAccessLevel.key,
) as {
  [key in UserAccessLevel["key"]]: UserAccessLevel;
} & {
  [unknown: string]: UserAccessLevel | undefined;
} & {
  [unknown: number | symbol]: undefined;
};

export type UserPermission = UserAccessLevel["permissions"][number];
export type UserAccessLevelKey = UserAccessLevel["key"];

export const USER_PERMISSIONS: UserPermission[] = uniq(
  flatMap(USER_ACCESS_LEVELS, (accessLevel) => accessLevel.permissions),
);

// Autogenerated types copied from the GraphQL schema since the table is banned from graphql use
export type UserProjectAccess = {
  __typename: "UserProjectAccess";
  /** Reads a single `ApplicationUser` that is related to this `UserProjectAccess`. */
  applicationUserByGrantedById: Maybe<ApplicationUser>;
  /** Reads a single `ApplicationUser` that is related to this `UserProjectAccess`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  copy: Scalars["Boolean"]["output"];
  dateGranted: Scalars["Datetime"]["output"];
  download: Scalars["Boolean"]["output"];
  edit: Scalars["Boolean"]["output"];
  execute: Scalars["Boolean"]["output"];
  grantAccess: Scalars["Boolean"]["output"];
  grantedById: Scalars["UUID"]["output"];
  id: Scalars["UUID"]["output"];
  managed: Scalars["Boolean"]["output"];
  /** Reads a single `Project` that is related to this `UserProjectAccess`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  upload: Scalars["Boolean"]["output"];
  userId: Scalars["UUID"]["output"];
  view: Scalars["Boolean"]["output"];
};
