import { client } from "../providers/ApolloProvider/ApolloProvider";
import { Auth } from "aws-amplify";
import { setUser } from "@sentry/browser";
import { captureException } from "@sentry/react";

/**
 * Logs out the user by removing their active token, resetting the client store, and redirecting the user to the login page.
 */
export const logOut = async () => {
  try {
    await client.clearStore();
  } catch (error) {
    captureException(error);
  }
  localStorage.clear();
  sessionStorage.clear();
  setUser(null);
  try {
    await Auth.signOut();
  } catch (error) {
    captureException(error);
  }
};
