import assert from "assert";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { createContext, ReactNode, useContext, useState } from "react";
import { createStore, StoreApi, useStore } from "zustand";
import { CellStatus } from "./CellStatusEditor.types";
import { CellStatusEditorImageData } from "./CellStatusEditor.types";
import {
  CellStatusCellEvents,
  CellStatusCellTraces,
  CellStatusEditorCellMetrics,
} from "./CellStatusEditor.types";
import { QcReportData } from "components/ToolParamsGrid/ModalToolCellStatusParam/useQcReport";
import { File as DrsFile } from "graphql/_Types";
import {
  CellStatusEditorCellInfo,
  CellStatusEditorSpacingInfo,
} from "./CellStatusEditor.types";

export type CellStatusContextValue = {
  cells: CellStatusEditorCellInfo[];
  cellStatuses: CellStatus[];
  setCellStatus: (status: CellStatus, cellIndex: number) => void;
  setCellStatuses: (statuses: CellStatus, cells: number[]) => void;
  resetCellStatuses: () => void;
  selectedCells: Set<number>;
  getSelectedCells: () => Set<number>;
  setSelectedCells: (selectedCells: number[]) => void;
  cellMetrics: CellStatusEditorCellMetrics | undefined;
  cellContours:
    | {
        contoursX: number[][] | undefined;
        contoursY: number[][] | undefined;
      }
    | undefined;
  imageData: CellStatusEditorImageData | undefined;
  tracesAndEvents:
    | {
        traces: CellStatusCellTraces;
        events: CellStatusCellEvents;
      }
    | undefined;
  files: {
    cellSetFile: Pick<DrsFile, "id">;
    qcReportFile?: Pick<DrsFile, "id">;
    eventSetFile?: Pick<DrsFile, "id">;
  };
  spacingInfo: CellStatusEditorSpacingInfo;
};

export const CellStatusContext = createContext<
  StoreApi<CellStatusContextValue> | undefined
>(undefined);

interface CreateCellStatusStoreProps {
  cellInfo: CellStatusEditorCellInfo[];
  spacingInfo: CellStatusEditorSpacingInfo;
  qcReportData: QcReportData | undefined;
  files: {
    cellSetFile: Pick<DrsFile, "id">;
    qcReportFile?: Pick<DrsFile, "id">;
    eventSetFile?: Pick<DrsFile, "id">;
  };
}

const createCellStatusStore = ({
  cellInfo,
  spacingInfo,
  qcReportData,
  files,
}: CreateCellStatusStoreProps) =>
  createStore<CellStatusContextValue>((set, get) => ({
    cells: cellInfo,
    cellStatuses: cellInfo.map(({ initialStatus }) => initialStatus),
    setCellStatus: (status: CellStatus, cellIndex: number) => {
      const newCellStatuses = [...get().cellStatuses];
      newCellStatuses[cellIndex] = status;
      set({ cellStatuses: newCellStatuses });
    },
    setCellStatuses: (status: CellStatus, cells: number[]) => {
      const newCellStatuses = [...get().cellStatuses];
      cells.forEach((cellIndex) => {
        newCellStatuses[cellIndex] = status;
      });
      set({ cellStatuses: newCellStatuses });
    },
    resetCellStatuses: () => {
      set({ cellStatuses: get().cells.map((cell) => cell.initialStatus) });
    },
    selectedCells: new Set(),
    setSelectedCells: (selectedCells: number[]) => {
      set({ selectedCells: new Set(selectedCells) });
    },
    getSelectedCells: () => get().selectedCells,
    cellMetrics: qcReportData?.cellSet?.cellMetrics,
    cellContours: qcReportData?.cellSet?.cellContours,
    imageData: qcReportData?.imageData,
    tracesAndEvents: qcReportData?.tracesAndEvents,
    files,
    spacingInfo,
  }));

interface CellStatusProviderProps extends CreateCellStatusStoreProps {
  children: ReactNode;
}

export const CellStatusEditorProvider = ({
  cellInfo,
  spacingInfo,
  qcReportData,
  files,
  children,
}: CellStatusProviderProps) => {
  const [store] = useState(
    createCellStatusStore({ cellInfo, spacingInfo, qcReportData, files }),
  );
  return (
    <CellStatusContext.Provider value={store}>
      {children}
    </CellStatusContext.Provider>
  );
};

export const useCellStatusContext = <T,>(
  selector: (state: CellStatusContextValue) => T,
) => {
  const store = useContext(CellStatusContext);
  assert(store !== undefined, new ContextOutOfBoundsError("CellStatusContext"));
  return useStore(store, selector);
};
