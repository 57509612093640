import { ApolloError } from "@apollo/client";
import {
  EuiAvatar,
  EuiButton,
  EuiButtonEmpty,
  EuiColorPicker,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiRadioGroup,
  EuiRadioGroupOption,
  EuiSpacer,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import assert from "assert";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useCallback, useEffect, useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { isNonNullish } from "utils/isNonNullish";
import { isNullish } from "utils/isNullish";
import {
  ApplicationUserPatch,
  useUpdateApplicationUserByIdMutation,
  useUpdateAvatarByIdMutation,
} from "../../graphql/_Types";
import { generateRandomEuiColor } from "../../utils/generateRandomEuiColor";
import FileImageUpload from "./FileImageUpload";
import "./UserPreference.scss";
import { useUserPreferenceData } from "./useUserPreferenceData";

export default function UserPreference() {
  const { userPreferenceData } = useUserPreferenceData();

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(userPreferenceData.firstName);
  const [lastName, setLastName] = useState(userPreferenceData.lastName);
  const [initials, setInitials] = useState(
    userPreferenceData?.avatar?.initials ?? "",
  );

  const [avatarColor, setAvatarColor] = useState(
    userPreferenceData.avatar?.color ?? generateRandomEuiColor(),
  );

  const [useAvatarImage, setUseAvatarImage] = useState(
    userPreferenceData.avatar?.useImage ?? false,
  );
  const [avatarImageUrl, setAvatarImageUrl] = useState<string>();
  const [avatarImageBase64, setAvatarImageBase64] = useState(
    userPreferenceData.avatar?.imageBase64 ?? null,
  );
  const [radioIdSelected, setRadioIdSelected] = useState<"Image" | "Initials">(
    userPreferenceData.avatar?.useImage ? "Image" : "Initials",
  );
  //const [selectedTimezone, setSelectedTimezone] = useState(
  //  userPreferenceData.timezone?.value,
  //);

  const radios: (EuiRadioGroupOption & { id: "Image" | "Initials" })[] = [
    {
      id: "Initials",
      label: "Initials",
    },
    {
      id: "Image",
      label: "Image",
      disabled: avatarImageUrl !== undefined ? false : true,
    },
  ];

  const [updateUser] = useUpdateApplicationUserByIdMutation({
    update: (_, { data }) => {
      const userUpdated = data?.updateApplicationUserById?.applicationUser;
      if (isNullish(userUpdated)) {
        throw new ApolloError({});
      }
    },
  });
  const [updateAvatar] = useUpdateAvatarByIdMutation();

  // convert image base64 to URL
  useEffect(() => {
    if (avatarImageBase64 !== null) {
      fetch(avatarImageBase64)
        .then((res) => res.blob())
        .then((imageBlob) => {
          setAvatarImageUrl(URL.createObjectURL(imageBlob));
        })
        .catch((err) => {
          captureException(err);
          addUtilityToastFailure("Failed to load avatar image");
        });
    }
  }, [avatarImageBase64]);

  const onClickResetAvatar = () => {
    setAvatarColor(generateRandomEuiColor());
    setInitials("");
    setAvatarImageBase64(null);
    setAvatarImageUrl(undefined);
    setUseAvatarImage(false);
    setRadioIdSelected("Initials");
  };

  const onRadioSelect = (optionId: (typeof radios)[number]["id"]) => {
    setRadioIdSelected(optionId);
    if (optionId === "Image") {
      return setUseAvatarImage(true);
    }
    setUseAvatarImage(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const applicationUserPatch: ApplicationUserPatch & {
      applicationUserMetadataUsingId: {
        create: NonNullable<
          NonNullable<
            ApplicationUserPatch["applicationUserMetadataUsingId"]
          >["create"]
        >;
        updateById: NonNullable<
          NonNullable<
            ApplicationUserPatch["applicationUserMetadataUsingId"]
          >["updateById"]
        >;
      };
    } = {
      firstName: undefined,
      lastName: undefined,
      applicationUserMetadataUsingId: {
        create: [],
        updateById: [],
      },
    };

    // create or update timezone
    /*
    const userTimezoneId = userPreferenceData.timezone?.id;
    if (selectedTimezone !== undefined) {
      if (userTimezoneId === undefined) {
        applicationUserPatch.applicationUserMetadataUsingId.create.push({
          key: "timezone",
          value: selectedTimezone,
        });
      } else {
        applicationUserPatch.applicationUserMetadataUsingId.updateById.push({
          id: userTimezoneId,
          applicationUserMetadatumPatch: {
            key: "timezone",
            value: selectedTimezone,
          },
        });
      }
    }
    */

    // update name
    if (
      firstName !== userPreferenceData.firstName ||
      lastName !== userPreferenceData.lastName
    ) {
      applicationUserPatch["firstName"] = firstName;
      applicationUserPatch["lastName"] = lastName;
    }

    try {
      // avatars are guaranteed for users
      // only null if failure to fetch
      // in which case we don't want to overwrite the avatar
      const avatar = userPreferenceData.avatar;
      if (avatar !== null) {
        const { data: avatarUpdatedData } = await updateAvatar({
          variables: {
            id: avatar.id,
            patch: {
              color: avatarColor,
              imageBase64: avatarImageBase64,
              initials,
              useImage: useAvatarImage,
            },
          },
          onError: (err) => captureException(err),
        });
        assert(
          isNonNullish(avatarUpdatedData?.updateAvatarById?.avatar),
          "Failed to update avatar",
        );
      }

      const { data: userUpdatedData } = await updateUser({
        variables: {
          id: userPreferenceData.userId,
          patch: applicationUserPatch,
        },
        onError: (err) => captureException(err),
      });
      assert(
        isNonNullish(
          userUpdatedData?.updateApplicationUserById?.applicationUser,
        ),
        "Failed to update avatar",
      );
      addUtilityToastSuccess("Successfully updated preferences");
    } catch (err) {
      // exceptions reported by mutations
      addUtilityToastFailure("Failed to update preferences");
    }
    setLoading(false);
  };

  const fullName = [firstName, lastName].filter(Boolean).join(" ");

  const onValidImageBase64Selection = useCallback((imageBase64: string) => {
    setAvatarImageBase64(imageBase64);
    setUseAvatarImage(true);
    setRadioIdSelected("Image");
  }, []);

  return (
    <EuiPanel>
      <EuiForm
        component="form"
        className={"UserPreferenceForm"}
        onSubmit={(e) => void onSubmit(e)}
        role="form"
      >
        <EuiFlexGrid columns={2}>
          <EuiFlexItem>
            <EuiTitle size="s">
              <h3>Personal Info</h3>
            </EuiTitle>
            <EuiSpacer />
            <EuiFormRow label="First Name">
              <EuiFieldText
                name="first_name"
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
                value={firstName}
              />
            </EuiFormRow>

            <EuiFormRow label="Last Name">
              <EuiFieldText
                name="last_name"
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
                value={lastName}
              />
            </EuiFormRow>

            <EuiFormRow label="Email">
              <EuiFieldText
                name="Email"
                disabled
                value={userPreferenceData.email}
              />
            </EuiFormRow>
          </EuiFlexItem>

          {userPreferenceData.avatar === null ? (
            <CallOutError>Failed to retrieve avatar data</CallOutError>
          ) : (
            <EuiFlexItem>
              <EuiTitle size="s">
                <h3>Avatar</h3>
              </EuiTitle>
              <EuiSpacer />

              <EuiFlexGrid columns={2} direction="column" responsive={false}>
                <EuiFlexItem>
                  <EuiFormRow label="Color">
                    <EuiColorPicker
                      onChange={(color: string) => {
                        setAvatarColor(color);
                      }}
                      color={avatarColor}
                      display="inline"
                    />
                  </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem>
                  <EuiFlexGrid columns={1}>
                    <EuiFlexItem>
                      <EuiFormRow label="Preview">
                        {avatarImageUrl !== undefined && useAvatarImage ? (
                          <EuiAvatar
                            aria-label="User avatar"
                            size="xl"
                            name={fullName}
                            color={avatarColor}
                            imageUrl={avatarImageUrl}
                          />
                        ) : (
                          <EuiAvatar
                            aria-label="User avatar"
                            size="xl"
                            name={fullName}
                            color={avatarColor}
                            initials={initials}
                            initialsLength={2}
                          />
                        )}
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiRadioGroup
                        options={radios}
                        idSelected={radioIdSelected}
                        onChange={(id) =>
                          onRadioSelect(id as (typeof radios)[number]["id"])
                        }
                        name="radio group"
                        legend={{
                          children: <span>Select</span>,
                        }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGrid>
                </EuiFlexItem>
              </EuiFlexGrid>

              <EuiSpacer />

              <EuiFormRow label="Preferred initials (max two characters)">
                <EuiFieldText
                  name="initials"
                  maxLength={2}
                  value={initials}
                  onChange={(e) => {
                    setInitials(e.target.value);
                  }}
                />
              </EuiFormRow>
              <EuiSpacer />
              <EuiFormRow label="Upload Avatar Image">
                <FileImageUpload
                  onValidImageBase64Selection={onValidImageBase64Selection}
                />
              </EuiFormRow>
              <EuiButtonEmpty
                onClick={onClickResetAvatar}
                disabled={userPreferenceData.avatar === null}
              >
                Reset Avatar
              </EuiButtonEmpty>
            </EuiFlexItem>
          )}
        </EuiFlexGrid>

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiButton type="submit" isLoading={loading}>
              Save
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </EuiPanel>
  );
}
