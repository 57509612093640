/** @jsxImportSource @emotion/react */
import { useState, FormEventHandler, useRef, useCallback } from "react";
import { isUndefined } from "lodash";
import assert from "assert";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFieldText,
  EuiFlexGroup,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiSpacer,
  EuiToolTip,
} from "@inscopix/ideas-eui";
import { isDefined } from "utils/isDefined";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { FILE_TYPES_BY_KEY } from "types/FileTypes";
import { useMenuInsertColumnStyles } from "./useMenuInsertColumnStyles";

// Specify object type order and rename "Unknown" to "Any"
const fileTypeOptions = [
  // TODO FILE TYPE MIGRATION
  { ...FILE_TYPES_BY_KEY["unknown"], name: "Any" },
  // common types ordered by most expected to be used
  FILE_TYPES_BY_KEY["miniscope_movie"],
  FILE_TYPES_BY_KEY["nvision_movie"],
  FILE_TYPES_BY_KEY["bruker2p_movie"],
  FILE_TYPES_BY_KEY["movie"],
  FILE_TYPES_BY_KEY["cell_set"],
  FILE_TYPES_BY_KEY["vessel_set"],
  FILE_TYPES_BY_KEY["neural_events"],
  FILE_TYPES_BY_KEY["timestamp_events"],
  FILE_TYPES_BY_KEY["gpio_data"],
  FILE_TYPES_BY_KEY["imu_data"],
  FILE_TYPES_BY_KEY["miniscope_image"],
  FILE_TYPES_BY_KEY["image"],
  FILE_TYPES_BY_KEY["event_aligned_neural_data"],
  FILE_TYPES_BY_KEY["experiment_annotations"],
  FILE_TYPES_BY_KEY["nwb_data"],
  FILE_TYPES_BY_KEY["caiman_data"],
  FILE_TYPES_BY_KEY["dlc_model"],
  FILE_TYPES_BY_KEY["dlc_model_predictions_data"],
  FILE_TYPES_BY_KEY["dlc_labeled_data"],
  FILE_TYPES_BY_KEY["dlc_config"],
  FILE_TYPES_BY_KEY["dlc_evaluation_results"],
  FILE_TYPES_BY_KEY["suite2p_data"],
  FILE_TYPES_BY_KEY["suite2p_classifier"],
  // new file types added for manual identification - less expected to be used
  // ordered alphabetically
  FILE_TYPES_BY_KEY["behavior_annotations"],
  FILE_TYPES_BY_KEY["bundle"],
  FILE_TYPES_BY_KEY["calcium_image_qc_data"],
  FILE_TYPES_BY_KEY["config"],
  FILE_TYPES_BY_KEY["combined_correlation_data"],
  FILE_TYPES_BY_KEY["combined_peri_event_statistics"],
  FILE_TYPES_BY_KEY["combined_peri_event_traces"],
  FILE_TYPES_BY_KEY["combined_population_activity_data"],
  FILE_TYPES_BY_KEY["correlation_data"],
  FILE_TYPES_BY_KEY["correlation_comparison_data"],
  FILE_TYPES_BY_KEY["locomotion_comparison_data"],
  FILE_TYPES_BY_KEY["movie_zones"],
  FILE_TYPES_BY_KEY["modulation_data"],
  FILE_TYPES_BY_KEY["peri_event_comparison_data"],
  FILE_TYPES_BY_KEY["population_activity_comparison_data"],
  FILE_TYPES_BY_KEY["statistics"],
  FILE_TYPES_BY_KEY["zone_occupancy_analysis"],
].map((option) => ({
  label: option.name,
  prepend: <EuiIcon size="s" type={option.icon} />,
  id: String(option.id),
  key: option.key,
}));

type PanelInsertDataColumnProps = {
  onClose: () => void;
};

export const PanelInsertDataColumn = ({
  onClose,
}: PanelInsertDataColumnProps) => {
  const createColumnAction = useDatasetAction("createColumn");
  const { panelStyles } = useMenuInsertColumnStyles();

  const [columnName, setColumnName] = useState("");
  const [selectedOption, setSelectedOption] =
    useState<EuiComboBoxOptionOption<string>>();

  // auto focus the column type input when loaded
  const setTypeRef: React.RefCallback<HTMLInputElement> = useCallback(
    (node) => {
      // timeout needed to get around EUI focusing the context menu title
      setTimeout(() => {
        node?.focus();
      }, 500);
    },
    [],
  );
  const nameRef = useRef<HTMLInputElement | null>(null);
  const setNameRef: React.RefCallback<HTMLInputElement> = useCallback(
    (node) => {
      nameRef.current = node;
    },
    [],
  );

  const selectedFileType =
    isDefined(selectedOption) && isDefined(selectedOption.key)
      ? FILE_TYPES_BY_KEY[selectedOption.key]?.id
      : undefined;

  const submitButtonTooltip = (() => {
    if (columnName === "") {
      return "Enter a column name";
    } else if (isUndefined(selectedOption)) {
      return "Select a data type";
    }
  })();

  const handleColumnTypeChange = (
    newSelectedOption: EuiComboBoxOptionOption<string> | undefined,
  ) => {
    if (columnName === "" || columnName === selectedOption?.label) {
      setColumnName(newSelectedOption?.label ?? "");
    }
    setSelectedOption(newSelectedOption);
    if (isDefined(newSelectedOption)) {
      // focus the metadata input after a data column is selected
      setTimeout(() => {
        nameRef?.current?.focus();
      }, 100);
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    assert(
      isDefined(selectedFileType),
      "Expected file type to be defined when creating data column",
    );
    void createColumnAction.enqueue({
      colDef: {
        type: "drsFile",
        headerName: columnName,
        fileType: selectedFileType,
      },
    });
    onClose();
  };

  return (
    <EuiForm
      component="form"
      role="form"
      onSubmit={handleSubmit}
      css={panelStyles}
    >
      <EuiFormRow
        label="Data Type"
        helpText="Choosing a data type restricts the column to storing a specific kind of data. This keeps your dataset organized and allows data to be used in analyses."
        display="rowCompressed"
      >
        <EuiComboBox<string>
          aria-label="Select data column type"
          options={fileTypeOptions}
          onChange={(newSelectedOptions) =>
            handleColumnTypeChange(newSelectedOptions[0])
          }
          singleSelection={true}
          selectedOptions={selectedOption ? [selectedOption] : []}
          placeholder={`Select one option or start typing to search`}
          compressed
          inputRef={setTypeRef}
        />
      </EuiFormRow>
      <EuiFormRow label="Column Name" display="rowCompressed">
        <EuiFieldText
          name="columnName"
          value={columnName}
          onChange={(e) => setColumnName(e.target.value)}
          placeholder="e.g. Miniscope Movie"
          compressed
          inputRef={setNameRef}
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiFlexGroup justifyContent="flexEnd">
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiToolTip content={submitButtonTooltip}>
          <EuiButton
            type="submit"
            fill
            disabled={columnName === "" || isUndefined(selectedFileType)}
          >
            Add Column
          </EuiButton>
        </EuiToolTip>
      </EuiFlexGroup>
    </EuiForm>
  );
};
