import { EuiModal } from "@inscopix/ideas-eui";
import { MenuInsertColumn } from "components/MenuInsertColumn/MenuInsertColumn";

type ModalInsertColumnProps = {
  onClose: () => void;
};

export const ModalInsertColumn = ({ onClose }: ModalInsertColumnProps) => {
  return (
    <EuiModal onClose={onClose}>
      <MenuInsertColumn onClose={onClose} />
    </EuiModal>
  );
};
