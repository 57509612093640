/** @jsxImportSource @emotion/react */
import { EuiPopover, EuiPopoverTitle } from "@inscopix/ideas-eui";
import { IHeaderParams } from "ag-grid-community";
import { css } from "@emotion/react";
import { useState } from "react";
import {
  isLinkedMetadataColumn,
  isMetadataColumn,
  RecordingsGridColumn,
} from "../../RecordingsGrid.helpers";
import { RecordingIdentifierFormatForm } from "./RecordingIdentifierFormatForm";
import { DatasetRecordingsTableColumn, Dataset, Project } from "graphql/_Types";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  `,
  name: css`
    background: none;
    border: none;
    cursor: default;
    flex: 1;
    margin: 0px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
};

interface RecordingSessionColumnHeaderProps extends IHeaderParams {
  columns: RecordingsGridColumn<{
    identifierPosition: number | null;
    order: number;
  }>[];
  dataset: Pick<Dataset, "id" | "prefix">;
  projectId: Project["id"];
  recordingsTableId: DatasetRecordingsTableColumn["id"];
}

export const RecordingSessionColumnHeader = ({
  api,
  columnApi,
  columns,
  dataset,
  displayName,
  projectId,
  recordingsTableId,
}: RecordingSessionColumnHeaderProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen(false);

  /**
   * Gets the name of a column from AG Grid
   * @param columnId
   * @returns The column name
   */
  const getColumnName = (columnId: string) => {
    const column = columnApi.getColumn(columnId);
    return column?.getColDef().headerName;
  };

  // Columns that can be used to identify a recording
  const possibleIdentifierColumns = columns
    .filter((column) => {
      return isMetadataColumn(column) || isLinkedMetadataColumn(column);
    })
    .map((column) => {
      const { id, identifierPosition, order } = column;
      const name = getColumnName(id) ?? "";
      return { id, identifierPosition, name, order };
    });

  return (
    <div css={styles.root}>
      <span css={styles.name}>{displayName}</span>
      <EuiPopover
        button={
          <span
            aria-hidden="true"
            className="ag-header-icon ag-header-cell-menu-button"
            data-test-subj="headerCellMenuButton"
            onClick={togglePopover}
          >
            <span className="ag-icon ag-icon-menu" unselectable="on" />
          </span>
        }
        hasDragDrop
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        anchorPosition="downLeft"
        ownFocus={false}
      >
        <EuiPopoverTitle>Recording identifier format</EuiPopoverTitle>
        <RecordingIdentifierFormatForm
          api={api}
          columns={possibleIdentifierColumns}
          dataset={dataset}
          projectId={projectId}
          recordingsTableId={recordingsTableId}
          onClose={closePopover}
        />
      </EuiPopover>
    </div>
  );
};
