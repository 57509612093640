import axios from "axios";
import { DatasetRecordingsTableColumn } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";

export type DatasetRecordingsTableColumnUnpinInput = {
  date_unpinned: string;
};

type DatasetRecordingsTableColumnUnpinDjangoResponse = {
  data: {
    id: string;
  };
};

export const useDatasetRecordingsTableColumnUnpinDjango = () => {
  const unpinColumn = useCallback(
    async (
      columnId: DatasetRecordingsTableColumn["id"],
      input: DatasetRecordingsTableColumnUnpinInput,
    ) => {
      const url = `${getEnvVar(
        "URL_DRS_DATASET_RECORDINGS_TABLE_COLUMN_UNPIN",
      )}${columnId}/`;
      const headers = await getRequestHeaders();
      const res = await axios.patch<
        DatasetRecordingsTableColumnUnpinInput,
        DatasetRecordingsTableColumnUnpinDjangoResponse
      >(url, input, { headers });

      return res.data;
    },
    [],
  );

  return { unpinColumn };
};
