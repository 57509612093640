import {
  EuiButtonEmpty,
  EuiPopover,
  EuiTreeView,
  EuiTreeViewProps,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { useToolParamsGridContext } from "components/ToolParamsGrid/ToolParamsGridProvider";
import { useCallback, useMemo, useState } from "react";
import { groupBy, sortBy } from "lodash";
import { isDefined } from "utils/isDefined";
import { ToolParam } from "components/ToolParamsGrid/ToolParamsGrid.types";
import { sleep } from "utils/sleep";
import { captureException } from "@sentry/react";
import { ToolboxInformationSchema } from "types/ToolboxInformationSchema";

type EuiTreeViewNode = EuiTreeViewProps["items"][number];

export const AnalysisTableJumpToColumn = ({
  onClick,
  closePopover,
}: {
  onClick?: () => void;
  closePopover?: () => void;
}) => {
  const { gridApi, toolSpec } = useToolParamsGridContext();

  /**
   * The items displayed in the parameter tree view
   */
  const items = useMemo(() => {
    /**
     * Get the tree node for a tool param
     * @param param
     * @returns The tree node
     */
    const getParamNode = (param: ToolParam) => {
      const jumpToParam = async () => {
        try {
          gridApi?.ensureColumnVisible(param.key, "start");
          // Wait a tick for the scroll to complete
          await sleep(0);
          gridApi?.flashCells({ columns: [param.key] });
        } catch (err) {
          captureException(err);
          // TODO: investigate the cause of this error
          // https://github.com/ag-grid/ag-grid/issues/5085
        } finally {
          closePopover && closePopover();
        }
      };

      const node: EuiTreeViewNode = {
        callback: () => {
          void jumpToParam();
          onClick && onClick();
          return param.key;
        },
        id: param.key,
        label: param.name,
      };

      return node;
    };

    /**
     * Gets the tree node for a tool param group
     * @param groupName
     * @param params
     * @returns The tree node
     */
    const getParamGroupNode = (groupName: string, params: ToolParam[]) => {
      const childNodes = sortBy(
        params,
        ({ type }) => type.display?.group_order,
      ).map(getParamNode);

      const node: EuiTreeViewNode = {
        id: groupName,
        label: groupName,
        children: childNodes,
      };

      return node;
    };

    const items: (EuiTreeViewNode | undefined)[] = [];

    // Attach a display order to each param
    const params = toolSpec.params.map((param, order) => {
      return { ...param, order };
    });

    // Parse ungrouped params
    const ungroupedParams = params.filter((param) => {
      const groupName = param.type.display?.group;
      return groupName === undefined;
    });

    ungroupedParams.forEach((param) => {
      const node = getParamNode(param);
      const { order } = param;
      items[order] = node;
    });

    // Parse grouped params
    const groupedParams = params.filter((param) => {
      const groupName = param.type.display?.group;
      return groupName !== undefined;
    });

    const paramsByGroupName = groupBy(groupedParams, (param) => {
      const groupName = param.type.display?.group;
      return groupName;
    });

    Object.entries(paramsByGroupName).forEach(([groupName, params]) => {
      const node = getParamGroupNode(groupName, params);
      const order = Math.min(...params.map(({ order }) => order));
      items[order] = node;
    });

    const resultGroups = toolSpec.results.map((result, order) => {
      return { ...result, order };
    });

    /**
     * Get the tree node for a tool result
     * @param param
     * @returns The tree node
     */
    const getResultNode = (
      result: ToolboxInformationSchema["tools"][number]["results"][number]["files"][number],
    ) => {
      const jumpToResult = async () => {
        try {
          gridApi?.ensureColumnVisible(result.result_key, "start");
          // Wait a tick for the scroll to complete
          await sleep(0);
          gridApi?.flashCells({ columns: [result.result_key] });
        } catch (err) {
          captureException(err);
          // TODO: investigate the cause of this error
          // https://github.com/ag-grid/ag-grid/issues/5085
        } finally {
          closePopover && closePopover();
        }
      };

      const node: EuiTreeViewNode = {
        callback: () => {
          void jumpToResult();
          onClick && onClick();
          return result.result_key;
        },
        id: result.result_key,
        label: result.result_name,
      };

      return node;
    };

    /**
     * Gets the tree node for a tool param group
     * @param groupName
     * @param params
     * @returns The tree node
     */
    const getResultGroupNode = (
      resultGroup: ToolboxInformationSchema["tools"][number]["results"][number],
    ): EuiTreeViewNode => {
      const results = resultGroup.files;
      const childNodes = results.map(getResultNode);

      const node: EuiTreeViewNode = {
        id: resultGroup.group_key,
        label: resultGroup.group_name,
        children: childNodes,
      };

      return node;
    };

    const resultGroupNodes: EuiTreeViewNode[] =
      resultGroups.map(getResultGroupNode);

    // Remove any empty slots caused by grouping
    return [...items, ...resultGroupNodes].filter(isDefined);
  }, [gridApi, onClick, toolSpec.params, toolSpec.results, closePopover]);

  return (
    <EuiTreeView
      aria-label="Jump to parameter"
      display="compressed"
      expandByDefault
      items={items}
      showExpansionArrows
      style={{ maxHeight: "80vh", overflow: "auto" }}
    />
  );
};

/*
// menu bar compatible version
export const AnalysisTableJumpToColumnPanel = ({
  onClick,
}: {
  onClick?: () => void;
}) => {
  return (
    <EuiPanel color="transparent" paddingSize="s">
      <AnalysisTableJumpToColumn onClick={onClick} />
    </EuiPanel>
  );
};
*/

export const AnalysisTableJumpToColumnPopover = ({
  onClick,
}: {
  onClick?: () => void;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  /**
   * If the popover is open, closes the popover
   * If the popover is closed, opens the popover
   */
  const togglePopover = useCallback(() => {
    setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
  }, []);

  /**
   * Closes the popover
   */
  const closePopover = useCallback(() => {
    setIsPopoverOpen(false);
  }, []);

  /**
   * The button rendered by the popover
   */
  const button = (
    <EuiButtonEmpty
      size={"xs"}
      iconType={"arrowDown"}
      iconSide="right"
      onClick={togglePopover}
    >
      Jump to column
    </EuiButtonEmpty>
  );
  return (
    <EuiPopover
      id={htmlIdGenerator()()}
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      anchorPosition="upCenter"
    >
      <AnalysisTableJumpToColumn
        onClick={onClick}
        closePopover={closePopover}
      />
    </EuiPopover>
  );
};
