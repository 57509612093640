import { EuiHeaderAlert } from "@inscopix/ideas-eui";
import moment from "moment";
import { ReactNode } from "react";

export interface NotificationAlertProps {
  title: ReactNode;
  text: ReactNode;
  action: ReactNode;
  dateCreated: string;
  hasSeen: boolean;
  onNotificationRead: () => void;
}

/** Base component for rendering a notification */
export const NotificationAlert = ({
  title,
  text,
  action,
  dateCreated,
  hasSeen,
  onNotificationRead,
}: NotificationAlertProps) => {
  const unreadBadge = (
    <svg
      viewBox="0 0 100 100"
      style={{ height: 10, width: 10, marginTop: "auto" }}
    >
      <circle cx="50" cy="50" r="50" fill="rgb(186, 61, 118)" />
    </svg>
  );

  return (
    <EuiHeaderAlert
      date={moment(dateCreated).fromNow()}
      title={title}
      text={text}
      action={action}
      badge={!hasSeen && unreadBadge}
      onClick={() => {
        if (!hasSeen) {
          onNotificationRead();
        }
      }}
    />
  );
};
