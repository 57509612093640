import { useUpdatePageDatasetCache } from "components/Dataset/hooks/useUpdatePageDatasetCache";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import { useDatasetRecordingsTableSetColumnOrder } from "hooks/useDatasetRecordingsTableSetColumnOrder";
import {
  DatasetRecordingsTable,
  DatasetRecordingsTableColumn,
} from "graphql/_Types";
import { useProjectPermission } from "hooks/useProjectPermission";

type ReorderColumnsParams = {
  recordingsTableId: DatasetRecordingsTable["id"];
  orderedColumnIds: DatasetRecordingsTableColumn["id"][];
};

export const useActionReorderColumns = () => {
  const { dataset, datasetMode } = useDatasetDataContext();
  const { updateCache } = useUpdatePageDatasetCache(dataset.id);
  const { reorderColumns } = useDatasetRecordingsTableSetColumnOrder();
  const { hasPermission } = useProjectPermission();

  const getActionDef = useCallback(
    (params: ReorderColumnsParams) => {
      const dateReordered = new Date().toISOString();

      return {
        onEnqueue: () => {
          updateCache((prevData) => {
            const newData = cloneDeep(prevData);
            const table = newData.dataset.datasetRecordingsTable;
            const allColumns = table.datasetRecordingsTableColumns.nodes;

            allColumns.forEach((column) => {
              const newOrder = params.orderedColumnIds.indexOf(column.id);
              column.activeOrder = newOrder;
            });

            return newData;
          });
        },

        onDequeue: async () =>
          await reorderColumns(params.recordingsTableId, {
            date_reordered: dateReordered,
            columns: params.orderedColumnIds,
          }),
      };
    },
    [updateCache, reorderColumns],
  );

  const isDisabled = datasetMode.type !== "current" || !hasPermission("edit");

  return {
    getActionDef,
    isDisabled,
  };
};
