import { EuiSkeletonText } from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { Dataset, DatasetVersion } from "graphql/_Types";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { createContext, ReactNode, useContext } from "react";
import { usePageDatasetData } from "./usePageDatasetData";

type DatasetData = NonNullable<ReturnType<typeof usePageDatasetData>["data"]>;
const DatasetDataContext = createContext<DatasetData | undefined>(undefined);

interface DatasetDataProviderProps {
  children: ReactNode;
  cutoffTime: string | undefined;
  datasetId: Dataset["id"];
  datasetVersionId: DatasetVersion["id"] | undefined;
}

export const DatasetDataProvider = ({
  children,
  cutoffTime,
  datasetId,
  datasetVersionId,
}: DatasetDataProviderProps) => {
  const { loading, error, data } = usePageDatasetData({
    cutoffTime,
    datasetId,
    datasetVersionId,
  });

  if (loading) {
    return <EuiSkeletonText />;
  }

  if (error) {
    return <CallOutError />;
  }

  return (
    <DatasetDataContext.Provider value={data}>
      {children}
    </DatasetDataContext.Provider>
  );
};

export const useDatasetDataContext = () => {
  const value = useContext(DatasetDataContext);

  if (value === undefined) {
    throw new ContextOutOfBoundsError("DatasetDataContext");
  }

  return value;
};
