import { useActionQueueStore } from "stores/ActionsQueue/ActionsQueueManager";
import { captureException } from "@sentry/react";
import { actionDefFactoryMap, DatasetActionName } from "./actionDefFactoryMap";

export const useDatasetAction = <T extends DatasetActionName>(
  actionName: T,
) => {
  const useActionFactory = actionDefFactoryMap[actionName];
  const { getActionDef, isDisabled } = useActionFactory();
  const enqueueAction = useActionQueueStore((s) => s.enqueueAction);

  const enqueue = async (
    params: Parameters<typeof getActionDef>[0],
    options?: Parameters<typeof enqueueAction<T>>[1],
  ) => {
    if (isDisabled) {
      const error = new Error("Attempted to enqueue disabled action");
      captureException(error);
      return { error, data: undefined };
    }

    const actionDef = getActionDef(params);
    const result = await enqueueAction(actionDef, options);
    return result;
  };

  return {
    enqueue,
    isDisabled,
  };
};
