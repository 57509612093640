import moment from "moment";

/**
 * Formats a date into a compact, sortable, readable string
 * @param date A valid date object or string
 * @returns string formatted date
 */
export const formatDate = (
  date: moment.MomentInput,
  format = "YYYY-MM-DD HH:mm:ss",
) => {
  return moment(date).format(format);
};
