import { css } from "@emotion/react";
import { useEuiTheme } from "@inscopix/ideas-eui";

export const useCroppingFrameSelectorStyles = () => {
  const { euiTheme } = useEuiTheme();
  const widthBreakpoint = 650;

  return {
    errorMessage: css`
      color: ${euiTheme.colors.danger};
    `,
    canvasContainer: (isErrorMessage: boolean) => css`
      max-width: calc(100vw - 100px);
      overflow: auto;
      max-height: calc(100vh - 200px - ${isErrorMessage ? "25px" : "0px"});

      @media screen and (max-width: ${widthBreakpoint}px) {
        max-height: calc(100vh - 325px - ${isErrorMessage ? "25px" : "0px"});
      }
    `,
    numericInputsContainer: css`
      display: flex;
      flex-direction: row;
      @media screen and (max-width: ${widthBreakpoint}px) {
        flex-direction: column;
      }
      gap: ${euiTheme.size.xs};
    `,
    scaleSlider: css`
      transform: rotate(270deg);
      margin-top: 50px;
      margin-left: -45px !important;
      width: 100px;
    `,
    scaleSliderContainer: css`
      width: 30px !important;
    `,
    controlsAndCanvasContainer: css`
      display: flex;
      flex-direction: column;
      gap: ${euiTheme.size.s};
    `,
  };
};
