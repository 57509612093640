import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiRange,
} from "@inscopix/ideas-eui";
import { useEffect, useState } from "react";
import * as fabric from "fabric";
import { Canvas } from "./RoiEditorCanvas";
import { Tooltip } from "components/Tooltip/Tooltip";

/**
 * Formats a timestamp for human readability.
 * @param timestamp A timestamp in seconds.
 * @returns The formatted timestamp.
 */
const formatTimestamp = (timestamp: number) => {
  const hours = Math.floor(timestamp / 3600);
  const minutes = Math.floor((timestamp % 3600) / 60);
  const seconds = timestamp % 60;

  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = seconds.toString().padStart(2, "0");

  return hours > 0
    ? `${hours}:${paddedMinutes}:${paddedSeconds}`
    : `${minutes}:${paddedSeconds}`;
};

interface RoiEditorVideoPlaybackControlsProps {
  canvas: Canvas;
  image: fabric.Image;
}

/** Component that renders playback controls for reference videos */
export const RoiEditorVideoPlaybackControls = ({
  canvas,
  image,
}: RoiEditorVideoPlaybackControlsProps) => {
  const videoElement = image.getElement() as HTMLVideoElement;
  const duration = Math.floor(videoElement.duration);
  const [timestamp, setTimestamp] = useState(videoElement.currentTime);
  const [isPlaying, setIsPlaying] = useState(!videoElement.paused);
  const [isLooping, setIsLooping] = useState(videoElement.loop);

  useEffect(() => {
    const handlePlay = () => setIsPlaying(true);
    videoElement.addEventListener("play", handlePlay);

    const handlePause = () => setIsPlaying(false);
    videoElement.addEventListener("pause", handlePause);

    const handleSeeked = () => canvas.requestRenderAll();
    videoElement.addEventListener("seeked", handleSeeked);

    const interval = setInterval(() => {
      const timestamp = Math.floor(videoElement.currentTime);
      setTimestamp(timestamp);
    }, 10);

    return () => {
      videoElement.removeEventListener("play", handlePlay);
      videoElement.removeEventListener("pause", handlePause);
      videoElement.removeEventListener("seeked", handleSeeked);
      clearInterval(interval);
    };
  }, [canvas, videoElement]);

  // Rerender the canvas while the video is playing
  useEffect(() => {
    if (isPlaying) {
      let requestId = fabric.util.requestAnimFrame(function render() {
        canvas.requestRenderAll();
        requestId = fabric.util.requestAnimFrame(render);
      });
      return () => cancelAnimationFrame(requestId);
    }
  }, [canvas, isPlaying]);

  return (
    <div>
      <EuiFlexGroup alignItems="center" responsive={false}>
        <EuiFlexItem grow={false}>
          <strong style={{ fontSize: 12 }}>{formatTimestamp(timestamp)}</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiRange
            min={0}
            max={duration}
            value={timestamp}
            onChange={(e) => {
              videoElement.currentTime = parseInt(e.currentTarget.value);
            }}
            fullWidth
            showRange
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <strong style={{ fontSize: 12 }}>{formatTimestamp(duration)}</strong>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup justifyContent="center" responsive={false}>
        {/* Invisible button to make sure the pause/play button remains centered */}
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            aria-label="Spacer"
            iconType="none"
            style={{ visibility: "hidden" }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            aria-label="Jump to beginning of video"
            color="text"
            iconType="framePrevious"
            onClick={() => {
              videoElement.currentTime = 0;
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            aria-label={isPlaying ? "Pause video" : "Play video"}
            color="text"
            iconType={isPlaying ? "pause" : "playFilled"}
            onClick={() => {
              if (videoElement.paused) {
                void videoElement.play();
              } else {
                videoElement.pause();
              }
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            aria-label="Jump to end of video"
            color="text"
            iconType="frameNext"
            onClick={() => {
              videoElement.currentTime = duration;
              videoElement.loop = false;
              setIsLooping(false);
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Tooltip content={`Loop video: ${isLooping ? "on" : "off"}`}>
            <EuiButtonIcon
              aria-label="Loop video"
              color={isLooping ? "primary" : "text"}
              display={isLooping ? "base" : "empty"}
              iconType="refresh"
              onClick={() => {
                setIsLooping(!videoElement.loop);
                videoElement.loop = !videoElement.loop;
              }}
            />
          </Tooltip>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
