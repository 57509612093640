import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { File as DrsFile } from "graphql/_Types";
import { useDrsFileUpdateDjango } from "hooks/useDrsFileUpdateDjango";
import { cloneDeep } from "lodash";
import { FormEvent, useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { updateCacheFragment } from "utils/cache-fragments";
import { isDefined } from "utils/isDefined";

const styles = {
  root: {
    maxWidth: 500,
  },
};
const MAX_NAME_LENGTH = 265;

export interface ModalRenameDrsFileProps {
  drsFile: Pick<DrsFile, "id" | "name">;
  onClose: () => void;
  onComplete?: (drsFileUpdated: Pick<DrsFile, "id" | "name">) => void;
  onError?: (error: Error) => void;
}

export const ModalRenameDrsFile = ({
  drsFile,
  onClose,
  onComplete,
  onError,
}: ModalRenameDrsFileProps) => {
  const formId = htmlIdGenerator()();
  const [formName, setFormName] = useState(drsFile.name);
  const [loading, setLoading] = useState(false);

  const { drsFileUpdate } = useDrsFileUpdateDjango();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formName === drsFile.name) {
      return onClose();
    }
    setLoading(true);

    try {
      const { id, name } = await drsFileUpdate(drsFile.id, {
        name: formName.trim(),
      });

      updateCacheFragment({
        __typename: "File",
        id,
        update: (data) => {
          const newData = cloneDeep(data);
          newData.name = name;
          return newData;
        },
      });

      if (isDefined(onComplete)) {
        onComplete({ id, name });
      }
      addUtilityToastSuccess("Successfully renamed file");
    } catch (err) {
      if (isDefined(onError)) {
        onError(err as Error);
      }
      addUtilityToastFailure("Failed to rename file");
    }

    setLoading(false);

    onClose();
  };

  const nameError = (() => {
    if (formName.trim().length === 0) {
      return "Please specify a name";
    }
    if (formName.trim().length > MAX_NAME_LENGTH) {
      return "Character limit exceeded";
    }
  })();
  const isInvalid = nameError !== undefined;
  return (
    <EuiModal onClose={onClose} style={styles.root}>
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h3">Rename File</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm
          id={formId}
          component="form"
          role="form"
          onSubmit={(e) => void handleSubmit(e)}
        >
          <EuiFormRow label="Name" isInvalid={isInvalid} error={nameError}>
            <EuiFieldText
              title="Name"
              isInvalid={isInvalid}
              aria-label="Name"
              placeholder="Name"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              maxLength={MAX_NAME_LENGTH}
              disabled={loading}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiButton
              type="submit"
              form={formId}
              isLoading={loading}
              isDisabled={isInvalid}
            >
              Update
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  );
};
