/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ICellRendererParams } from "ag-grid-community";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { RecordingsGridRowDatum } from "./RecordingsGrid.types";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
  `,
};

type GridRendererRecordingProps = ICellRendererParams<
  RecordingsGridRowDatum,
  undefined
>;

export const GridRendererRecording = ({ data }: GridRendererRecordingProps) => {
  assert(
    isDefined(data),
    "Expected row datum to be defined in recording renderer",
  );

  return (
    <div css={styles.root}>
      <RecordingIdentifierBadge recordingId={data.recording.id} />
    </div>
  );
};
