import {
  EuiButtonEmpty,
  EuiButtonEmptyProps,
  EuiButtonIcon,
  EuiToolTip,
} from "@inscopix/ideas-eui";
import axios from "axios";
import { getEnvVar } from "ideas.env";
import { useState, useEffect } from "react";
import ls from "localstorage-slim";
import { captureException } from "@sentry/react";
import { Tool, ToolVersion } from "graphql/_Types";

interface DocsUrlsByToolKey {
  [key: Tool["key"]]:
    | {
        default: string;
        [version: ToolVersion["version"]]: string | undefined;
      }
    | undefined;
}

interface ButtonToolDocsProps {
  tool: {
    key: Tool["key"];
    version?: ToolVersion["version"];
  };
  iconSize?: EuiButtonEmptyProps["iconSize"];
  size?: EuiButtonEmptyProps["size"];
  iconType?: EuiButtonEmptyProps["iconType"];
  EuiButtonComponent?: typeof EuiButtonEmpty | typeof EuiButtonIcon;
}

// Shared promise for the axios request because more than one instance of the component can be rendered on the page
let fetchDocsMapperPromise: Promise<DocsUrlsByToolKey> | null = null;

export const ButtonToolDocs = ({
  iconType = "documentation",
  tool,
  iconSize = "m",
  size = "s",
  EuiButtonComponent = EuiButtonIcon,
}: ButtonToolDocsProps) => {
  const [docsUrlsByToolKey, setDocsUrlsByToolKey] = useState<DocsUrlsByToolKey>(
    {},
  );

  const docsUrlsByToolKeyCashKey = "IDEASdocsUrlsByToolKeyMapper";

  const fetchDocsMapper = async () => {
    const response = await axios.get<DocsUrlsByToolKey>(
      getEnvVar("URL_DOCS_MAPPER"),
    );
    return response.data;
  };

  useEffect(() => {
    const cachedData = ls.get<DocsUrlsByToolKey>(docsUrlsByToolKeyCashKey);
    if (cachedData) {
      setDocsUrlsByToolKey(cachedData);
      fetchDocsMapperPromise = null;
    } else {
      if (!fetchDocsMapperPromise) {
        // If the promise doesn't exist, create it
        fetchDocsMapperPromise = fetchDocsMapper();
      }
      fetchDocsMapperPromise
        .then((docsMapper) => {
          setDocsUrlsByToolKey(docsMapper);
          ls.set<DocsUrlsByToolKey>(
            docsUrlsByToolKeyCashKey,
            docsMapper,
            { ttl: 3600 }, // 1 hour TTL for cache on localstorage
          );
        })
        .catch((error) => {
          captureException(error);
        });
    }
  }, [setDocsUrlsByToolKey]);

  // It first tries to find a URL specific to the version of the tool being used.
  // If that doesn't exist, it tries to find the default doc URL for the tool.
  const docsUrl = (() => {
    const defaultUrl = docsUrlsByToolKey[tool.key]?.default;
    const toolVersionUrl =
      tool.version !== undefined
        ? docsUrlsByToolKey[tool.key]?.[tool.version]
        : undefined;
    return toolVersionUrl ?? defaultUrl;
  })();

  if (docsUrl === undefined) {
    return null;
  }

  return (
    <EuiToolTip position="top" content="Open documentation in new tab">
      <EuiButtonComponent
        onClick={(e: { stopPropagation: () => void }) => {
          e.stopPropagation();
        }}
        iconSize={iconSize}
        size={size}
        key="tool-docs-button"
        data-test-subj="tool-docs-button"
        aria-label="View documentation"
        iconType={iconType}
        href={docsUrl}
        target="_blank"
      >
        Documentation
      </EuiButtonComponent>
    </EuiToolTip>
  );
};
