import { css } from "@emotion/react";
import { useProjectLayoutContext } from "./ProjectLayoutProvider";

export const useProjectStyles = () => {
  const { rightFlyout } = useProjectLayoutContext();

  const minContainerHeight = 300;

  const styles = {
    root: css`
      height: 100%;
      min-width: 650px;
    `,
    contentAndFlyoutsContainer: css`
      overflow: auto;
    `,
    contentAndBottomFlyoutContainer: css`
      min-width: 0;
      flex: 10 0 600px;
      min-height: ${minContainerHeight}px;
    `,
    bottomFlyoutContainer: css`
      padding-top: 2px;
      margin-bottom: 2px;
      height: ${1000}px;
    `,
    rightFlyoutContainer: css`
      margin-left: 2px;
      margin-right: 2px;
      padding-bottom: 2px;
      height: 100%;
      min-height: ${minContainerHeight}px;
      width: ${rightFlyout?.width.value}px;
      flex-shrink: 0;
    `,
  };

  return styles;
};
