import { useUpdatePageDatasetCache } from "components/Dataset/hooks/useUpdatePageDatasetCache";
import { RecordingsGridColDef } from "components/RecordingsGrid/RecordingsGrid.types";
import {
  DatasetRecordingsTableColumn,
  useCreateMetadataValueMutation,
} from "graphql/_Types";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { uuid } from "utils/uuid";
import { isNonNullish } from "utils/isNonNullish";
import { captureException } from "@sentry/react";
import { useProjectPermission } from "hooks/useProjectPermission";
import { useUserContext } from "providers/UserProvider/UserProvider";

type UpdateColumnDefParams = {
  columnId: DatasetRecordingsTableColumn["id"];
  colDef: RecordingsGridColDef;
};

export const useActionUpdateColumnDef = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { dataset, datasetMode, recordingsTable, project } =
    useDatasetDataContext();
  const { updateCache } = useUpdatePageDatasetCache(dataset.id);
  const [createMetadatumValue] = useCreateMetadataValueMutation();
  const { hasPermission } = useProjectPermission();

  const getActionDef = useCallback(
    (params: UpdateColumnDefParams) => {
      const columns = recordingsTable.datasetRecordingsTableColumns.nodes;
      const column = columns.find((column) => column.id === params.columnId);
      assert(
        isDefined(column),
        "Column not found in dataset data context when attempting to update column definition",
      );

      const metadataValue = {
        id: uuid(),
        metadataId: column.colDefMetadatumId,
        value: params.colDef,
        userId: currentUser.id,
        tenantId: currentTenant.id,
        dateCreated: new Date().toISOString(),
        taskId: null,
        projectId: project.id,
      };

      return {
        onEnqueue: () => {
          updateCache((prevData) => {
            const newData = cloneDeep(prevData);
            const table = newData.dataset.datasetRecordingsTable;
            const column = table.datasetRecordingsTableColumns.nodes.find(
              (column) => column.id === params.columnId,
            );
            assert(isDefined(column), "Expected column to exist in cache");
            column.activeColDef = params.colDef;
            return newData;
          });
        },

        onDequeue: async () => {
          const { data } = await createMetadatumValue({
            variables: { metadataValue },
            onError: (err) => captureException(err),
          });
          const newMetadataValue = data?.createMetadataValue?.metadataValue;
          assert(
            isNonNullish(newMetadataValue),
            "Expected data to be non nullish when creating metadatum value",
          );
          return newMetadataValue;
        },
      };
    },
    [
      createMetadatumValue,
      currentTenant.id,
      currentUser.id,
      project.id,
      recordingsTable.datasetRecordingsTableColumns.nodes,
      updateCache,
    ],
  );

  const isDisabled = datasetMode.type !== "current" || !hasPermission("edit");

  return {
    getActionDef,
    isDisabled,
  };
};
