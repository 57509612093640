import { EuiBadge } from "@inscopix/ideas-eui";
import { FileRecordingGroup } from "graphql/_Types";
import { useEffect } from "react";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useRecordingIdentifierContext } from "providers/RecordingIdentifierProvider/RecordingIdentifierProvider";

interface RecordingIdentifierBadgeProps {
  showShortIdOnly?: boolean;
  recordingId: FileRecordingGroup["id"];
}

/**
 * Component that displays a badge with the identifier of a given recording.
 * It handles loading states, errors and content display.
 */
export const RecordingIdentifierBadge = ({
  showShortIdOnly = false,
  recordingId,
}: RecordingIdentifierBadgeProps) => {
  const identifier = useRecordingIdentifierContext(
    (s) => s.identifierMap[recordingId],
  );
  const getIdentifier = useRecordingIdentifierContext((s) => s.getIdentifier);

  // Fetch data on mount if no data in cache
  useEffect(() => {
    if (identifier === undefined) {
      void getIdentifier(recordingId);
    }
  }, [getIdentifier, identifier, recordingId]);

  if (identifier === undefined) {
    return (
      <EuiBadge>
        <span style={{ color: "grey" }}>Loading</span>
      </EuiBadge>
    );
  }

  if (identifier instanceof Error) {
    return <EuiBadge color="danger">ERROR</EuiBadge>;
  }

  const tooltipParams = identifier.cellValues.map(
    ({ columnName, cellValue }) => {
      return (
        <div key={columnName}>
          <strong>{columnName}</strong>: {cellValue}
        </div>
      );
    },
  );

  const tooltipContent = [
    <div key={"id"}>
      <strong>Recording Session ID</strong>:{" "}
      <EuiBadge style={{ fontFamily: "monospace" }}>
        {identifier.shortId}
      </EuiBadge>
    </div>,
    ...tooltipParams,
  ];

  return (
    <div>
      <EuiBadge
        style={{ fontFamily: "monospace", marginRight: 5 }}
        title={undefined} // Remove default tooltip from the badge
      >
        {identifier.shortId}
      </EuiBadge>
      {!showShortIdOnly && identifier.cellValues.length > 0 && (
        <Tooltip content={tooltipContent}>
          <EuiBadge
            color="hollow"
            style={{ fontFamily: "monospace" }}
            title={undefined} // Remove default tooltip from the badge
          >
            {identifier.cellValues
              .map(({ cellValue }) => cellValue)
              .join(" | ")}
          </EuiBadge>
        </Tooltip>
      )}
    </div>
  );
};
