/**
 * Error for nullish data on completed query
 */
export class NullishDataError extends Error {
  constructor() {
    super("Nullish data returned from query");
  }
}

/**
 * Type to narrow custom queries
 */

export type IdeasQueryState<TData> =
  | { loading: true; data: undefined; error: undefined }
  | { loading: false; data: undefined; error: Error }
  | {
      loading: false;
      data: TData;
      error: undefined;
    };
