import {
  fileBadgeWidth,
  fileRendererCellPadding,
} from "styles/useFileGridRendererStyles/useFileGridRendererStyles";

/**
 * Get the number of compact file badges that can fit within a given container width
 * @param width The width of the container the file badges are displayed in - allowed to be undefined in case a ref is not initialized
 * @return the number of badges that can fit in the given width (at least one), or if the provided width is undefined returns 0
 */
export const getNumBadgesFit = (width: number | undefined) => {
  // Only occurs when `ref` is undefined
  if (width === undefined) {
    return 0;
  }

  // TODO: Compute size from EUI theme
  const numFit = Math.floor(
    (width + fileRendererCellPadding * 2) / fileBadgeWidth,
  );
  return Math.max(numFit, 1);
};
