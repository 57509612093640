import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiRange,
} from "@inscopix/ideas-eui";

interface RoiEditorScaleControlsProps {
  scale: number;
  onChangeScale: (scale: number | "fit") => void;
}

/** Component that renders scaling controls  */
export const RoiEditorScaleControls = ({
  scale,
  onChangeScale,
}: RoiEditorScaleControlsProps) => {
  return (
    <EuiFlexGroup
      alignItems="center"
      justifyContent="flexEnd"
      responsive={false}
    >
      <EuiFlexItem grow={false}>
        <EuiButton
          color="text"
          size="s"
          minWidth="auto"
          onClick={() => onChangeScale("fit")}
        >
          Fit to width
        </EuiButton>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton
          color="text"
          size="s"
          minWidth="auto"
          onClick={() => onChangeScale(1)}
        >
          Actual size
        </EuiButton>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiRange
          min={25}
          max={400}
          showInput
          showRange
          append="%"
          compressed
          value={Math.floor(scale * 100)}
          onChange={(e) => {
            const newScale = parseInt(e.currentTarget.value) / 100;
            onChangeScale(newScale);
          }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
