import { captureException } from "@sentry/react";
import axios from "axios";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";
import { getRequestHeaders } from "utils/getRequestHeaders";

export type SystemHealthDjangoResponse = {
  health: {
    status: string;
    version?: string;
  };
};

export type BackendStatus = {
  status: "ok" | "error";
};

export const useGetBackendStatus = () => {
  const getBackendStatus: () => Promise<BackendStatus> =
    useCallback(async () => {
      try {
        const headers = await getRequestHeaders();
        const response = await axios.get<SystemHealthDjangoResponse>(
          getEnvVar("URL_SYSTEM_HEALTH"),
          { headers },
        );

        if (response.data.health.status === "ok") {
          return {
            status: "ok" as const,
          };
        } else {
          captureException("Unexpected response from backend status check", {
            extra: response.data,
          });
        }
      } catch (error) {
        captureException(error);
      }

      return { status: "error" as const };
    }, []);

  return { getBackendStatus };
};
