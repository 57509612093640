import { EuiCard, EuiIcon } from "@inscopix/ideas-eui";

/** Component rendered when no previews exist for a file */
export const FilePreviewEmpty = () => {
  return (
    <EuiCard
      icon={<EuiIcon size="xl" type="logoObservability" />}
      title="No preview available"
      display="plain"
      hasBorder
    />
  );
};
