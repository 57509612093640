import { EuiButtonIconProps } from "@inscopix/ideas-eui";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import {
  ModalDataset,
  ModalDatasetProps,
} from "components/ModalDataset/ModalDataset";
import { useState } from "react";

export interface ButtonEditDatasetProps
  extends Omit<EuiButtonIconProps, "onClick"> {
  dataset: NonNullable<ModalDatasetProps["edit"]>["dataset"];
}

export const ButtonEditDataset = ({
  dataset,
  ...props
}: ButtonEditDatasetProps) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ButtonIconPermissioned
        aria-label="edit dataset"
        type="gear"
        {...props}
        onClick={() => setShowModal(true)}
        requiredPermission="edit"
      />
      {showModal && (
        <ModalDataset edit={{ dataset }} onClose={() => setShowModal(false)} />
      )}
    </>
  );
};
