import { DatasetRecordingsTableColumn } from "graphql/_Types";
import {
  AnalysisResultColDef,
  DrsFileColDef,
  LinkedMetadataColDef,
  MetadataColDef,
  RecordingsGridColDef,
} from "./RecordingsGrid.types";

/**
 * Represents a column in a dataset recordings table
 *
 * Do not add any additional static properties to this type unless you want to
 * force all usages of this type to specify values for them.
 */
export type RecordingsGridColumn<T = unknown> = T & {
  id: DatasetRecordingsTableColumn["id"];
  colDef: RecordingsGridColDef;
};

export type DrsFileColumn<T = unknown> = RecordingsGridColumn<T> & {
  colDef: DrsFileColDef;
};

export const isDrsFileColumn = <T extends RecordingsGridColumn>(
  column: T,
): column is DrsFileColumn<T> => {
  return column.colDef.type === "drsFile";
};

export type MetadataColumn<T = unknown> = RecordingsGridColumn<T> & {
  colDef: MetadataColDef;
};

export const isMetadataColumn = <T extends RecordingsGridColumn>(
  column: T,
): column is MetadataColumn<T> => {
  return column.colDef.type === "metadata";
};

export type LinkedMetadataColumn<T = unknown> = RecordingsGridColumn<T> & {
  colDef: LinkedMetadataColDef;
};

export const isLinkedMetadataColumn = <T extends RecordingsGridColumn>(
  column: T,
): column is LinkedMetadataColumn<T> => {
  return column.colDef.type === "linkedMetadata";
};

export type AnalysisResultColumn<T = unknown> = RecordingsGridColumn<T> & {
  colDef: AnalysisResultColDef;
};

export const isAnalysisResultColumn = <T extends RecordingsGridColumn>(
  column: T,
): column is AnalysisResultColumn<T> => {
  return column.colDef.type === "analysisResult";
};
