/** @jsxImportSource @emotion/react */
import { RecordingsGrid } from "components/RecordingsGrid/RecordingsGrid";
import { Dataset as TDataset, DatasetVersion } from "graphql/_Types";
import { DatasetDataProvider } from "pages/project/dataset/DatasetDataProvider";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { DatasetDndProvider } from "./DatasetDndProvider";
import { DatasetHeader } from "./DatasetHeader";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { useProjectDataContext } from "../../pages/project/ProjectDataProvider";
import { isDefined } from "../../utils/isDefined";
import { isUndefined } from "lodash";
import { Page404 } from "../../pages/404/Page404";
import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import { DatasetBottomBar } from "./DatasetBottomBar/DatasetBottomBar";
import { useDatasetStyles } from "./useDatasetStyles";
import { ProjectFilesManager } from "stores/project-files/ProjectFilesManager";
import { ActionsQueueManager } from "stores/ActionsQueue/ActionsQueueManager";
import { DatasetSelectionProvider } from "pages/project/dataset/SelectionProvider";

export const Dataset = () => {
  const { project, datasets } = useProjectDataContext();
  const {
    cutoffTime: cutoffTimeParam,
    versionId,
    datasetId,
  } = useParams<{
    cutoffTime?: string;
    versionId?: DatasetVersion["id"];
    datasetId: TDataset["id"];
  }>();

  const { bottomFlyout, rightFlyout, modal } = useDatasetLayoutContext();
  const styles = useDatasetStyles();

  const dataset = datasets.find(({ id }) => id === datasetId);
  const version = dataset?.datasetVersions.find(
    (version) => version.id === versionId,
  );

  // if the version ID does not match this project's dataset id
  // or if the version ID param is defined but does not match any version of this dataset
  // return a 404
  if (
    isUndefined(dataset) ||
    (isDefined(versionId) && isUndefined(version)) ||
    datasetId !== dataset.id
  ) {
    return <Page404 />;
  }

  const cutoffTime =
    cutoffTimeParam !== undefined
      ? moment.unix(parseInt(cutoffTimeParam)).toISOString()
      : version?.dateCreated;

  return (
    <ProjectFilesManager projectId={project.id} cutoffTime={cutoffTime}>
      <DatasetDataProvider
        cutoffTime={cutoffTime}
        datasetId={datasetId}
        datasetVersionId={versionId}
      >
        <ActionsQueueManager>
          <DatasetDndProvider>
            {/* PAGE CONTAINER */}
            <EuiFlexGroup
              direction="column"
              gutterSize="none"
              css={styles.root}
            >
              <DatasetSelectionProvider>
                {/* HEADER */}
                <EuiFlexItem grow={false}>
                  <DatasetHeader />
                </EuiFlexItem>
                {/* SIDE FLYOUT, GRID, AND BOTTOM FLYOUT CONTAINER */}
                <EuiFlexGroup
                  gutterSize="none"
                  responsive={false}
                  css={styles.gridAndFlyoutsContainer}
                >
                  {/* GRID AND BOTTOM FLYOUT CONTAINER*/}
                  <EuiFlexGroup
                    direction="column"
                    gutterSize="none"
                    css={styles.gridAndBottomFlyoutContainer}
                  >
                    {/* GRID */}
                    <EuiFlexItem>
                      <RecordingsGrid />
                    </EuiFlexItem>
                    {/* BOTTOM FLYOUT */}
                    {bottomFlyout && (
                      <EuiFlexItem
                        grow={false}
                        css={styles.bottomFlyoutContainer}
                      >
                        {bottomFlyout?.node}
                      </EuiFlexItem>
                    )}
                  </EuiFlexGroup>
                  {/* SIDE FLYOUT */}
                  {rightFlyout && (
                    <EuiFlexItem grow={false} css={styles.rightFlyoutContainer}>
                      {rightFlyout?.node}
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
              </DatasetSelectionProvider>
              <EuiFlexItem grow={false}>
                <DatasetBottomBar />
              </EuiFlexItem>
            </EuiFlexGroup>
            {modal}
          </DatasetDndProvider>
        </ActionsQueueManager>
      </DatasetDataProvider>
    </ProjectFilesManager>
  );
};
