import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { File as DrsFile } from "graphql/_Types";
import { useProjectPermission } from "hooks/useProjectPermission";
import { evictCacheFragment } from "utils/cache-fragments";
import { useDrsFileScheduleDataDeleteDjango } from "hooks/useDrsFileScheduleDataDeleteDjango";

type DeleteColumnParams = {
  fileId: DrsFile["id"];
};

export const useActionDataDeleteFile = () => {
  const { datasetMode } = useDatasetDataContext();
  const { drsFileScheduleDataDelete } = useDrsFileScheduleDataDeleteDjango();
  const { hasPermission } = useProjectPermission();

  const getActionDef = useCallback(
    (params: DeleteColumnParams) => {
      const dateDeleted = new Date().toISOString();

      return {
        onEnqueue: () => {
          evictCacheFragment({
            __typename: "File",
            id: params.fileId,
          });
        },

        onDequeue: () => {
          return drsFileScheduleDataDelete(params.fileId, {
            date_data_deleted: dateDeleted,
          });
        },
      };
    },
    [drsFileScheduleDataDelete],
  );

  const isDisabled = datasetMode.type !== "current" || !hasPermission("edit");

  return {
    getActionDef,
    isDisabled,
  };
};
