/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { FlyoutSubsection } from "components/Flyout/FlyoutSubsection";
import {
  AnalysisTable,
  AnalysisTableGroup,
  FileRecordingGroup,
  Project,
  Tool,
} from "graphql/_Types";
import { useRoutes } from "hooks/useRoutes";
import { useShowHiddenAnalysisTables } from "hooks/useShowHiddenAnalysisTables";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { AnalysisTableGroupTaskCounts } from "components/AnalysisTableTaskCounts/AnalysisTableGroupTaskCounts";
import { ButtonAddRecordingsToTable } from "./ButtonAddRowsToTable";
import { ButtonToolDocs } from "components/ButtonToolDocs/ButtonToolDocs";

const styles = {
  panel: css`
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 15px;
    margin-bottom: 10px;
    &:hover {
      border: 1px solid lightgray;
      cursor: pointer;
      box-shadow: none;
      transform: none;
    }
  `,
};
interface FlyoutAnalysisSubsectionAnalysesProps {
  items: {
    groupId: AnalysisTableGroup["id"];
    name: AnalysisTableGroup["name"];
    tool: Pick<Tool, "key">;
    toolName: string;
    projectId: AnalysisTableGroup["projectId"];
    hidden: AnalysisTableGroup["hidden"];
    tables: Pick<AnalysisTable, "id" | "name">[];
  }[];
  project: Pick<Project, "id" | "key">;
  selectedRecordingIds: FileRecordingGroup["id"][];
}
export const FlyoutAnalysisSubsectionAnalyses = ({
  items,
  project,
  selectedRecordingIds,
}: FlyoutAnalysisSubsectionAnalysesProps) => {
  const { routeMap } = useRoutes();
  const { showHiddenTables, showHiddenTableSwitch } =
    useShowHiddenAnalysisTables(false);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  return (
    <FlyoutSubsection title="View or Add to Existing Table">
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>{showHiddenTableSwitch}</EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      {items
        .filter((analysisTable) => showHiddenTables || !analysisTable.hidden)
        .map(({ name, tool, toolName, groupId, projectId, hidden, tables }) => {
          return (
            <EuiPanel
              key={groupId}
              css={styles.panel}
              style={{ opacity: hidden ? 0.45 : 1 }}
              color={hidden ? "plain" : "subdued"}
              onClick={() => {
                routeMap["PROJECT_ANALYSIS_TABLE_GROUP"]
                  .dynamicPath({
                    tenantKey: currentTenant.key,
                    projectKey: project.key,
                    analysisTableGroupId: groupId,
                  })
                  .navigateTo();
              }}
            >
              <EuiFlexGroup gutterSize="xs">
                <EuiFlexItem grow={true}>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={true}>
                      <EuiText>{name}</EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <AnalysisTableGroupTaskCounts
                        analysisTableGroup={{ id: groupId, projectId }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup gutterSize="none">
                    <EuiFlexItem grow={true}>
                      <EuiText size={"s"}>
                        <strong>Workflow:</strong> {toolName}
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <ButtonToolDocs tool={tool} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <ButtonAddRecordingsToTable
                    selectedRecordingIds={selectedRecordingIds}
                    tables={tables.map((table) => ({ ...table, groupId }))}
                    project={project}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          );
        })}
    </FlyoutSubsection>
  );
};
