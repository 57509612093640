import {
  GetAllTasksDocument,
  GetAllTasksQuery,
  GetAllTasksQueryVariables,
  Project,
} from "graphql/_Types";
import { updateCacheFragment } from "utils/cache-fragments";
import { cloneDeep } from "lodash";
import { TaskStatus } from "types/constants";
import { client } from "providers/ApolloProvider/ApolloProvider";

/**
 * Gets the minimum 'date created' of an analysis result when polling.
 * This is calculated as the current time, minus one minute to create a buffer
 * for analysis results created in the time between the last fetch and the
 * next fetch.
 * @returns The cutoff time in ISO format.
 */
const getCutoffTime = () => {
  const now = new Date();
  now.setMinutes(now.getMinutes() - 1);
  return now.toISOString();
};

/** A type-safe wrapper for calling the `GetAllTasks` query outside of a hook */
const getAllTasks = (variables: GetAllTasksQueryVariables) => {
  return client.query<GetAllTasksQuery, GetAllTasksQueryVariables>({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    query: GetAllTasksDocument,
    variables,
  });
};

/**
 * Polls for newly created analysis results. In the case where new results are
 * fetched, the cache is updated.
 * @param projectKey
 * @returns A function to stop polling.
 */
export const pollAnalysisResults = (projectKey: Project["key"]) => {
  // Only tasks completed after this time will be fetched
  let cutoffTime = getCutoffTime();

  const interval = setInterval(() => {
    void getAllTasks({
      filter: {
        projectByProjectId: {
          key: {
            equalTo: projectKey,
          },
        },
        status: {
          equalTo: TaskStatus.COMPLETE,
        },
        fileOutputGroupByTaskId: {
          dateCreated: {
            greaterThanOrEqualTo: cutoffTime,
          },
        },
      },
    })
      .then(({ data }) => {
        /* Update the cutoff time to the current timestamp. This prevents the
             query result from growing increasingly large over time as tasks
             complete, potentially overwhelming the backend. */
        cutoffTime = getCutoffTime();

        data.tasks?.nodes.forEach((task) => {
          task.outputGroup?.outputGroupFiles.nodes.forEach((file) => {
            // Skip nodes without file information
            if (file.drsFile === null) {
              return;
            }

            const { drsFile } = file;

            // Add the analysis result files to the project cache
            updateCacheFragment({
              __typename: "Project",
              id: drsFile.projectId,
              update: (data) => {
                const newData = cloneDeep(data);

                if (newData.activeFiles === undefined) {
                  newData.activeFiles = {
                    __typename: "FilesConnection",
                    nodes: [],
                  };
                }

                // Prevent attaching a file multiple times to the project cache
                const isFileAlreadyCached = newData.activeFiles.nodes.some(
                  ({ id }) => id === drsFile.id,
                );

                if (!isFileAlreadyCached) {
                  newData.activeFiles.nodes.push({
                    __typename: "File",
                    id: drsFile.id,
                  });
                }

                return newData;
              },
            });
          });
        });
      })
      // Silently ignore errors. All query errors are reported in the Apollo Client error link.
      .catch(() => undefined);
  }, 60 * 1000); // 1 minute

  // Clear the interval when the component unmounts
  return () => clearInterval(interval);
};
