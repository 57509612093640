import {
  euiPaletteColorBlindBehindText,
  useEuiTheme,
} from "@inscopix/ideas-eui";
import { css } from "@emotion/react";

export const useDataSelectorStyles = () => {
  const { euiTheme } = useEuiTheme();
  const maxColumnWidth = 250;

  const behindTextGreen = euiPaletteColorBlindBehindText({
    sortBy: "natural",
  })[0];
  const behindTextYellow = euiPaletteColorBlindBehindText({
    sortBy: "natural",
  })[9];

  return {
    columnSelector: css`
      height: 320px;
      display: flex;
      flex-direction: row;
      width: 1000px;
      max-inline-size: calc(100vw - 36px);
      overflow-x: scroll;
      .selectCol {
        padding: 8px;
        align-items: center;
        background-color: white;
        display: flex;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .euiSelectableList__list {
        mask-image: none;
      }
      .euiMark {
        color: #ffffff !important;
      }
      .selected {
        background-color: #304960 !important;
        color: #ffffff !important;
      }
      .selectedObj {
        background-color: rgb(109, 204, 177);
      }
      .arrowIcon {
        padding: 0px 0px 1px 6px;
      }
    `,
    column: css`
      min-width: 250px;
      max-width: ${maxColumnWidth}px;
      flex-shrink: 0;
      border-right: 1px solid #aaaaaa;
      overflow-y: scroll;
      .fileColumn > span {
        padding: 0px;
        color: initial;
      }
      .fileColumn .euiSelectableListItem__append {
        position: absolute;
        right: 0;
      }
    `,
    sectionHeader: css`
      padding: 4px;
    `,
    selectionItem: (isSelected: boolean, hasMatch: boolean) => css`
      padding: 8px;
      align-items: center;
      background-color: white;
      display: flex;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      ${!hasMatch &&
      `
      color: ${euiTheme.colors.subduedText};
    `}
      ${isSelected &&
      `
      background-color: #304960;
      color: #ffffff;
    `}
    ${isSelected &&
      !hasMatch &&
      `
      color: rgb(145, 154, 175);
    `}
    `,
    selectionFile: (isSelected: boolean, hasMatch: boolean) => css`
      padding: 8px;
      width: 100%;
      gap: 2px;
      align-items: center;
      background-color: white;
      display: flex;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      ${!hasMatch &&
      `
      color: ${euiTheme.colors.subduedText};
    `}
      ${isSelected &&
      `
      background-color: ${behindTextGreen};
    `}
    ${isSelected &&
      !hasMatch &&
      `
      background-color: ${behindTextYellow};
    `}
    `,
    selectedFileTitle: css`
      flex-grow: 0;
      display: flex;
      padding: 15px 8px 8px 8px;
    `,
    clearSelections: css`
      padding: 8px;
    `,
    selectedFiles: css`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 8px 0px;
      max-height: 120px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    `,
    selectedFile: css`
      display: flex;
      align-items: center;
    `,
    label: css`
      width: 90%;
      max-width: ${maxColumnWidth - 64}px;
    `,
    iconWrapper: css`
      padding: 2px;
    `,
    lastButtonContainer: css`
      align-items: end;
    `,
  };
};
