import { useCallback, useEffect, useState } from "react";

/**
 * A hook for determining whether or not the tab running ideas is currently active within its window.
 * This will be false if the user switches to a different browser tab in the same window, and true otherwise
 * @returns A boolean representing whether or not the the tab with the app is active
 */
export const useIsTabActive = () => {
  const [isTabActive, setIsTabActive] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsTabActive(document.visibilityState === "visible");
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return { isTabActive };
};
