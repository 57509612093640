import axios from "axios";
import { getEnvVar } from "ideas.env";
import { useCallback, useEffect, useState } from "react";
import { useProjectPermissionStore } from "stores/project-permission/useProjectPermissionStore";
import { getRequestHeaders } from "utils/getRequestHeaders";
import {
  UserProjectAccessDjango,
  UserProjectAccessDjangoResponse,
} from "./useProjectPermission";
import { Project } from "graphql/_Types";
import { camelCase, mapKeys } from "lodash";

// Shared promise for the axios request because more than one instance of the component can be rendered on the page
let fetchCurrentUserProjectAccessesPromise: Promise<
  UserProjectAccessDjango[]
> | null = null;

/**
 * Fetches the user's project accesses and updates the permissions store
 * This hook acts as an exclusive interface to the project permissions store
 * Use this hook to access the project permissions store
 */
export const useFetchAndSetProjectAccess = () => {
  /**
   * TODO: Fixme so I don't refetch the same data multiple times simultaneously (for example when each project card wants to get access to permissions)
   * https://inscopix.atlassian.net/browse/ID-3915
   *
   */
  const { permissions, setPermissions, updatePermission, removePermission } =
    useProjectPermissionStore();

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Fetch user access level for all project that a user has access to.
   * Can be filtered by project id.
   * @returns
   * It returns all non-system-managed user_project_access rules that are for either:
   *   • the current user (aka rules created for projects they have access to)
   *   • rules for projects where the current user has grant_access on (admins)
   * It also fetches project across tenants
   */
  const fetchCurrentUserProjectAccesses = useCallback(
    async (projectId?: Project["id"]) => {
      const headers = await getRequestHeaders();
      const result = await axios.get<UserProjectAccessDjangoResponse>(
        `${getEnvVar("URL_LIBRARY_PROJECT_ACCESS")}${
          projectId !== undefined ? `?project=${projectId}` : ""
        }`,
        { headers },
      );

      const camelCasedResults = result.data.map(
        (result) =>
          mapKeys(result, (_, key) =>
            camelCase(key),
          ) as UserProjectAccessDjango,
      );

      return camelCasedResults;
    },
    [],
  );

  /**
   * Refetches the user's project accesses for all projects and updates the permissions state
   * Use this when you want to sync the permissions store with the server
   */
  const refetchCurrentUserProjectAccesses = useCallback(async () => {
    fetchCurrentUserProjectAccessesPromise = fetchCurrentUserProjectAccesses();
    const result = await fetchCurrentUserProjectAccessesPromise;
    setPermissions(result);
  }, [fetchCurrentUserProjectAccesses, setPermissions]);

  useEffect(() => {
    if (permissions === undefined) {
      if (!fetchCurrentUserProjectAccessesPromise) {
        fetchCurrentUserProjectAccessesPromise =
          fetchCurrentUserProjectAccesses();
      }
      setIsLoading(true);
      void fetchCurrentUserProjectAccessesPromise.then((result) => {
        setPermissions(result);
        setIsLoading(false);
      });
    }
  }, [fetchCurrentUserProjectAccesses, permissions, setPermissions]);

  return {
    permissions,
    setPermissions,
    updatePermission,
    removePermission,
    fetchCurrentUserProjectAccesses,
    refetchCurrentUserProjectAccesses,
    isLoading,
  };
};
