import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import {
  ModalDeleteDrsFile,
  ModalDeleteDrsFileProps,
} from "components/ModalDeleteDrsFile/ModalDeleteDrsFile";
import { useRoutes } from "hooks/useRoutes";
import { useState } from "react";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";

export interface ButtonDeleteDrsFileProps {
  drsFile: Parameters<typeof getDrsFileModifyPermissionByDrsFileAndAction>[0] &
    ModalDeleteDrsFileProps["drsFiles"][number];
}

export const ButtonDeleteDrsFile = ({ drsFile }: ButtonDeleteDrsFileProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { routeMap } = useRoutes();
  const isViewingHistory =
    routeMap.DATASET_HISTORY.isRouteMatch() ||
    routeMap.DATASET_VERSION.isRouteMatch();

  return (
    <>
      <ButtonIconPermissioned
        aria-label="Delete file"
        defaultTooltip={
          isViewingHistory
            ? "Delete disabled while viewing history"
            : "Delete file"
        }
        color="danger"
        iconType="trash"
        disabled={
          isViewingHistory ||
          !getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "DELETE")
            .isPermitted ||
          !getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "DATA_DELETE")
            .isPermitted
        }
        onClick={() => {
          setShowConfirmModal(true);
        }}
        requiredPermission="edit"
      />
      {showConfirmModal && (
        <ModalDeleteDrsFile
          drsFiles={[drsFile]}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};
