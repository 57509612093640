import { Metadatum } from "graphql/_Types";

/**
 * Formats a metadatum key, removing prefixes we want to hide from users.
 * @param key
 * @returns The formatted metadatum key.
 */
export const formatMetadatumKey = (key: Metadatum["key"]) => {
  const hiddenPrefixes = ["ideas", "user"];

  for (const prefix of hiddenPrefixes) {
    if (key.startsWith(`${prefix}.`)) {
      return key.replace(`${prefix}.`, "");
    }
  }

  return key;
};
