import { EuiConfirmModal } from "@inscopix/ideas-eui";
import { useSelectedDrsFileId } from "stores/useSelectedDrsFileId";
import { ToolParamsGridRowDatum } from "./ToolParamsGrid.types";
import { useToolParamsGridRowDataContext } from "./ToolParamsGridRowDataProvider";

export interface ModalDeleteRowBulkProps {
  onClose: () => void;
  rows: Pick<ToolParamsGridRowDatum, "id" | "output_group_files">[];
}

export const ModalDeleteRowBulk = ({
  rows,
  onClose,
}: ModalDeleteRowBulkProps) => {
  const { deselectDrsFile } = useSelectedDrsFileId();
  const removeRow = useToolParamsGridRowDataContext((s) => s.removeRow);

  const onSubmit = () => {
    rows.forEach((row) => {
      row.output_group_files?.forEach((file) => deselectDrsFile(file.id));
      removeRow(row.id);
    });
    onClose();
  };

  return (
    <EuiConfirmModal
      title="Remove Tasks and Delete Results"
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
      onCancel={() => onClose()}
      confirmButtonDisabled={false}
      isLoading={false}
      onConfirm={onSubmit}
      buttonColor="danger"
    >
      <p>
        Remove selected tasks from the analysis table and permanently delete any
        result files from the project (input files will not be affected).
      </p>
      <p>
        Executed task information and logs will still be visible in this
        project&apos;s All Tasks view.
      </p>
    </EuiConfirmModal>
  );
};
