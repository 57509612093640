/** @jsxImportSource @emotion/react */
import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalProps,
  EuiText,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { ToolCellStatusParam } from "../ToolParamsGrid.types";
import { css } from "@emotion/react";

export interface ModalToolCellStatusParamWrapperProps extends EuiModalProps {
  toolParam: ToolCellStatusParam;
  children: React.ReactNode;
  readOnly?: boolean;
  fullScreen?: boolean;
}

export const ModalToolCellStatusParamWrapper = ({
  toolParam,
  children,
  readOnly,
  fullScreen = false,
  ...props
}: ModalToolCellStatusParamWrapperProps) => {
  return (
    <EuiModal
      {...props}
      style={
        fullScreen
          ? {
              position: "absolute",
              top: 0,
              left: 0,
              width: "calc(100vw - 20px)",
              height: "calc(100vh - 20px)",
              maxWidth: "calc(100vw - 20px)",
              maxHeight: "calc(100vh - 20px)",
              margin: 10,
            }
          : undefined
      }
    >
      <EuiModalHeader>
        <EuiTitle size="s">
          <EuiText>Accept/Reject Cells {readOnly && " (Read Only)"}</EuiText>
        </EuiTitle>
      </EuiModalHeader>
      <EuiModalBody
        css={css`
          .euiModalBody__overflow {
            align-content: center;
          }
        `}
      >
        {children}
      </EuiModalBody>
    </EuiModal>
  );
};
