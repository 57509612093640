import { CellStatuses } from "./CellStatusEditor.types";
import { CellStatus } from "./CellStatusEditor.types";
import { JsonValue } from "type-fest";
import { euiPaletteColorBlindBehindText } from "@inscopix/ideas-eui";

export const selectionColor = "#00C8FF";

export const selectionLabelColor = euiPaletteColorBlindBehindText({
  rotations: 2,
})[11];

export const getCellStatusColor = (status: CellStatus, opacity = 1): string => {
  switch (status) {
    case CellStatus.Accepted:
      return `rgba(77,210,202,${opacity})`;
    case CellStatus.Undecided:
      return `rgba(254,197,20,${opacity})`;
    case CellStatus.Rejected:
      return `rgba(189,39,30,${opacity})`;
    default:
      return `rgba(0,0,0,${opacity})`;
  }
};

/**
 * Parses Cell Status param data and converts to cell statuses array.
 * @throws if coordinates improperly formatted
 * @param value unknown param data value
 * @returns parsed cropping coordinates if valid
 */
export const parseCellStatusParamData = (value: unknown): CellStatuses => {
  const InvalidCellStatusesError = new Error("Invalid cell status list.");
  if (typeof value !== "string") {
    throw InvalidCellStatusesError;
  }

  try {
    const paramJSON = JSON.parse(value) as JsonValue;
    if (Array.isArray(paramJSON)) {
      for (const val of paramJSON) {
        if (typeof val !== "number" || !(val in CellStatus)) {
          throw InvalidCellStatusesError;
        }
      }

      return paramJSON as CellStatuses;
    }

    throw InvalidCellStatusesError;
  } catch (err) {
    throw InvalidCellStatusesError;
  }
};
