import assert from "assert";
import axios from "axios";
import { File as DrsFile } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { FileStatus } from "types/constants";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type DataDeleteDrsFileInput = {
  date_data_deleted: DrsFile["dateDeleted"];
};

export type DataDeleteDrsFileDjangoResponse = {
  data: {
    id: DrsFile["id"];
    date_data_deleted: DrsFile["dateDeleted"];
    status: DrsFile["status"];
    project: DrsFile["projectId"];
  };
};

export const useDrsFileScheduleDataDeleteDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);

  const drsFileScheduleDataDelete = async (
    drsFileId: DrsFile["id"],
    input: DataDeleteDrsFileInput,
  ) => {
    const url = `${getEnvVar(
      "URL_DRS_FILE_SCHEDULE_DATA_DELETE",
    )}${drsFileId}/`;
    const body = { ...input, user: currentUser.id };
    const headers = await getRequestHeaders();
    const res = await axios.patch<
      DataDeleteDrsFileInput,
      DataDeleteDrsFileDjangoResponse
    >(url, body, { headers });
    const { status, date_data_deleted } = res.data;
    assert(
      status === FileStatus["SCHEDULE_DATA_DELETE"] &&
        date_data_deleted !== null,
      "Received incorrect object status from data delete request",
    );
    return { ...res.data, status, date_data_deleted };
  };

  return { drsFileScheduleDataDelete };
};
