import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import { JsonValue } from "type-fest";

/**
 * Creates a string representation of a metadata value.
 * @param value The metadata value to stringify.
 * @returns The metadata value as a string.
 */
export const stringifyMetadataValue = (
  value: JsonValue | undefined,
): string => {
  /* Strings have to be handled explicitly because `JSON.stringify` will add
     double quotes to them */
  if (typeof value === "string") {
    return value;
  }

  /* Arrays have to be handled explicitly because `JSON.stringify` will add
     brackets to them and will not include space characters after commas */
  if (Array.isArray(value)) {
    return value.map(stringifyMetadataValue).join(", ");
  }

  /* `null` and `undefined` values have to be handled explicitly because
     `JSON.stringify` will convert them to "null" (as a string) and `undefined`
     (as the `undefined` primitive) respectively */
  if (isNullish(value)) {
    return "";
  }

  return JSON.stringify(value);
};
