import {
  EuiBasicTable,
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { ButtonDownloadFile } from "components/ButtonDownloadFile/ButtonDownloadFile";
import { File } from "graphql/_Types";
import { filesize } from "utils/filesize";

export interface ModalDownloadFilesProps {
  files: Pick<File, "id" | "name" | "size" | "status">[];
  title?: string;
  onClose: () => void;
}

export const ModalDownloadFiles = ({
  files,
  title,
  onClose,
}: ModalDownloadFilesProps) => {
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{title ?? "Download Files"}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiBasicTable<(typeof files)[number]>
          tableLayout="auto"
          columns={[
            { field: "name", name: "Name" },
            {
              field: "size",
              name: "Size",
              render: (_, { size }) => filesize(Number(size ?? "0")),
              textOnly: true,
            },
            {
              actions: [
                {
                  render: (file) => <ButtonDownloadFile file={file} />,
                },
              ],
            },
          ]}
          hasActions
          items={files}
        />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton onClick={onClose}>Cancel</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
