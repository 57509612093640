/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { memo, useState } from "react";
import { DRS_FILE_ACTION_ICONS } from "types/Icons";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    cursor: pointer;
  `,
};

interface GridRendererDrsFileWithoutFilesProps {
  onUploadClick: () => void;
  onAssignClick: () => void;
}

export const GridRendererDrsFileWithoutFiles = memo(
  function GridRendererDrsFileWithoutFiles({
    onUploadClick,
    onAssignClick,
  }: GridRendererDrsFileWithoutFilesProps) {
    const [isUploadIconVisible, setIsUploadIconVisible] = useState(false);
    const [isAssignIconVisible, setIsAssignIconVisible] = useState(false);
    const uploadFileAction = useDatasetAction("uploadFile");
    const assignFileAction = useDatasetAction("assignFile");

    const handleMouseEnter = () => {
      if (!uploadFileAction.isDisabled) {
        setIsUploadIconVisible(true);
      }
      if (!assignFileAction.isDisabled) {
        setIsAssignIconVisible(true);
      }
    };

    const handleMouseLeave = () => {
      if (!uploadFileAction.isDisabled) {
        setIsUploadIconVisible(false);
      }
      if (!assignFileAction.isDisabled) {
        setIsAssignIconVisible(false);
      }
    };

    return (
      <div
        css={styles.root}
        onDrop={handleMouseLeave}
        onDragLeave={handleMouseLeave}
        onDragOver={handleMouseEnter}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isUploadIconVisible && (
          <Tooltip content={"Upload file"} delayShow={500}>
            <EuiButtonIcon
              onClick={onUploadClick}
              iconType={DRS_FILE_ACTION_ICONS["upload"]}
            />
          </Tooltip>
        )}
        {isAssignIconVisible && (
          <Tooltip content={"Assign file"} delayShow={500}>
            <EuiButtonIcon
              css={css`
                margin-left: 8px;
              `}
              onClick={onAssignClick}
              iconType={DRS_FILE_ACTION_ICONS["assign"]}
            />
          </Tooltip>
        )}
      </div>
    );
  },
);
