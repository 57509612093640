import { useEffect, useState } from "react";

/**
 * Hook for determining whether the mouse cursor is over an HTML element
 * @param element
 * @returns A `boolean` representing whether the mouse cursor is over the HTML
 * element
 */
export const useMouseOver = (element: HTMLElement) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    const handleMouseEnter = () => setIsMouseOver(true);
    element.addEventListener("mouseenter", handleMouseEnter);

    const handleMouseLeave = () => setIsMouseOver(false);
    element.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      element.removeEventListener("mouseenter", handleMouseEnter);
      element.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [element]);

  return { isMouseOver };
};
