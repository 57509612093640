import axios from "axios";
import {
  DatasetRecordingsTableColumn,
  File as DrsFile,
  FileRecordingGroup as RecordingGroup,
  RecordingGroupFile,
} from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type CreateRecordingGroupDrsFileInput = {
  id: RecordingGroupFile["id"];
  dataset_recordings_table_column: DatasetRecordingsTableColumn["id"];
  file: DrsFile["id"];
  file_recording_group: RecordingGroup["id"];
};

type CreateRecordingGroupDrsFileDjangoResponse = {
  data: {
    id: RecordingGroupFile["id"];
    date_created: RecordingGroupFile["dateCreated"];
  };
};

export const useRecordingGroupDrsFileCreateDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const recordingGroupDrsFileCreateDjango = async (
    input: CreateRecordingGroupDrsFileInput,
  ): Promise<CreateRecordingGroupDrsFileDjangoResponse["data"]> => {
    const url = getEnvVar("URL_DRS_RECORDING_GROUP_FILES_CREATE");
    const headers = await getRequestHeaders();
    const response = await axios.post<
      CreateRecordingGroupDrsFileInput,
      CreateRecordingGroupDrsFileDjangoResponse
    >(
      url,
      {
        ...input,
        user: currentUser.id,
        tenant: currentTenant.id,
      },
      {
        headers,
      },
    );

    return response.data;
  };
  return { recordingGroupDrsFileCreateDjango };
};
