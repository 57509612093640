import axios from "axios";
import {
  Dataset,
  DatasetFieldsFragment,
  Metadatum,
  MetadataValue,
  Project,
} from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { SetNonNullable } from "type-fest";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
export type CreateDatasetInput = {
  name: string;
  prefix: string;
  key: string;
  project: Project["id"];
};

type CreateDatasetDjangoResponse = {
  data: {
    id: Dataset["id"];
    date_archived: null;
    date_created: Dataset["dateCreated"];
    date_data_deleted: null;
    date_deleted: null;
    description: {
      display_name: Metadatum["displayName"];
      id: Metadatum["id"];
      key: Metadatum["key"];
      tenant: Metadatum["tenantId"];
      values: {
        id: MetadataValue["id"];
        date_created: MetadataValue["dateCreated"];
        metadata: Metadatum["id"];
        task: null;
        tenant: MetadataValue["tenantId"];
        user: MetadataValue["userId"];
        value: MetadataValue["value"];
      }[];
    };
    key: Dataset["key"];
    prefix: Dataset["prefix"];
    name: Dataset["name"];
    project: Dataset["projectId"];
    recordings_table: Dataset["recordingsTableId"];
    status: Dataset["status"];
    tenant: Dataset["tenantId"];
    user: Dataset["userId"];
  };
};

export const useCreateDatasetDjango = () => {
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const createDatasetDjango = async (
    input: CreateDatasetInput,
  ): Promise<SetNonNullable<DatasetFieldsFragment, "descriptionId">> => {
    const url = getEnvVar("URL_DRS_DATASET_CREATE");
    const headers = await getRequestHeaders();
    const response = await axios.post<
      CreateDatasetInput,
      CreateDatasetDjangoResponse
    >(
      url,
      {
        ...input,
        // TODO remove me after https://inscopix.atlassian.net/browse/ID-2226
        tenant: currentTenant.id,
      },
      {
        headers,
      },
    );

    const {
      id,
      key,
      prefix,
      name,
      date_archived,
      date_created,
      date_data_deleted,
      date_deleted,
      project,
      recordings_table,
      status,
      tenant,
      user,
      description,
    } = response.data;

    return {
      __typename: "Dataset",
      id,
      key,
      prefix,
      name,
      dateArchived: date_archived,
      dateCreated: date_created,
      dateDataDeleted: date_data_deleted,
      dateDeleted: date_deleted,
      projectId: project,
      recordingsTableId: recordings_table,
      status,
      tenantId: tenant,
      userId: user,
      descriptionId: description.id,
    };
  };
  return { createDatasetDjango };
};
