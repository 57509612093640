import { useState } from "react";
import { EuiConfirmModal, EuiSpacer, EuiText } from "@inscopix/ideas-eui";
import { File as DrsFile } from "graphql/_Types";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { useDrsFileArchiveDjango } from "hooks/useDrsFileArchiveDjango";
import { updateCacheFragment } from "utils/cache-fragments";
import { cloneDeep } from "lodash";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { useActionQueueStore } from "stores/ActionsQueue/ActionsQueueManager";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { QUOTA_TYPES_BY_KEY } from "types/QuotaType";

type TFile = Pick<
  DrsFile,
  | "id"
  | "status"
  | "name"
  | "source"
  | "isSeries"
  | "seriesParentId"
  | "processingStatus"
>;
export type ModalArchiveDrsFileProps = {
  drsFiles: TFile[];
  onClose: () => void;
  onComplete?: ({
    successfulFiles,
    errors,
  }: {
    successfulFiles: Pick<DrsFile, "id">[];
    errors: Error[];
  }) => void;
};

export const ModalArchiveDrsFile = ({
  drsFiles,
  onClose,
  onComplete,
}: ModalArchiveDrsFileProps) => {
  drsFiles.forEach((drsFile) => {
    assert(
      getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "ARCHIVE")
        .isPermitted,
      `Drs object without archive permissions reached archive modal`,
    );
  });

  const { drsFileArchive } = useDrsFileArchiveDjango();
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const checkTenantQuota = useUserContext((s) => s.checkTenantQuota);

  const status = useActionQueueStore((s) => s.status);
  const [isLoading, setIsLoading] = useState(false);

  const archiveDrsFile = async () => {
    const requests = drsFiles.map(({ id }) =>
      (async () => {
        try {
          const res = await drsFileArchive(id, {
            date_archived: new Date().toISOString(),
          });
          return res;
        } catch (err) {
          return new Error(`Failed to archive ${id}`);
        }
      })(),
    );

    const drsFileArchiveResults = await Promise.all(requests);
    const successfullyArchivedObjects = drsFileArchiveResults.filter(
      function (result): result is Awaited<ReturnType<typeof drsFileArchive>> {
        return !(result instanceof Error);
      },
    );

    successfullyArchivedObjects.forEach((drsFileArchived) => {
      updateCacheFragment({
        __typename: "File",
        id: drsFileArchived.id,
        update: (data) => {
          const newData = cloneDeep(data);
          newData.status = drsFileArchived.status;
          newData.dateArchived = drsFileArchived.date_archived;
          return newData;
        },
      });
    });

    const errors = drsFileArchiveResults.filter(
      function (result): result is Error {
        return result instanceof Error;
      },
    );

    if (errors.length > 0) {
      addUtilityToastFailure("One or more files failed to archive.");
      const quotaType = QUOTA_TYPES_BY_KEY["archived_storage"];
      void checkTenantQuota(currentTenant.id, quotaType);
    } else {
      addUtilityToastSuccess("Successfully archived files");
    }

    if (isDefined(onComplete)) {
      onComplete({
        successfulFiles: successfullyArchivedObjects,
        errors: errors,
      });
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await archiveDrsFile();
    setIsLoading(false);
    onClose();
  };

  return (
    <EuiConfirmModal
      title="Archive File"
      cancelButtonText="Cancel"
      confirmButtonText="Confirm"
      onCancel={onClose}
      onConfirm={() => void onSubmit()}
      buttonColor="warning"
      defaultFocusedButton="cancel"
      confirmButtonDisabled={status !== "synced"}
      isLoading={isLoading}
    >
      <div>
        <EuiText>
          Are you sure you wish to archive the following {drsFiles.length}{" "}
          file(s):
          <p>
            {drsFiles.map(({ name }) => (
              <div key={name}>{name}</div>
            ))}
          </p>
        </EuiText>
      </div>
      <EuiSpacer size="l" />
      <EuiText>
        <p>
          Move this data to archived storage. It will not count towards active
          storage limits, but will be unavailable (cannot be downloaded or used
          in analyses) until it is unarchived.
        </p>
        <p>
          Unarchiving can take up to 12 hours for the data to become available.
        </p>
      </EuiText>
    </EuiConfirmModal>
  );
};
