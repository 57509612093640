import axios from "axios";
import { getRequestHeaders } from "utils/getRequestHeaders";

import { Project } from "graphql/_Types";
import { updateCacheFragment } from "utils/cache-fragments";
import { getEnvVar } from "ideas.env";

export type LibraryPinnedProjectInput = {
  project: Project["id"];
};

export type LibraryPinnedProjectPinDjangoResponse = {
  data: { project: Project["id"] };
};

const pin = async (input: LibraryPinnedProjectInput) => {
  const url = `${getEnvVar("URL_LIBRARY_PROJECT_PIN")}${input.project}/`;
  const headers = await getRequestHeaders();
  const res = await axios.patch<
    LibraryPinnedProjectInput,
    LibraryPinnedProjectPinDjangoResponse
  >(url, input, { headers });

  updateCacheFragment({
    __typename: "Project",
    id: input.project,
    update: (data) => {
      const newData = { ...data };
      newData.pinned = true;
      return newData;
    },
  });
  return res.data;
};

const unpin = async (input: LibraryPinnedProjectInput) => {
  const url = `${getEnvVar("URL_LIBRARY_PROJECT_UNPIN")}${input.project}/`;
  const headers = await getRequestHeaders();
  const res = await axios.patch<LibraryPinnedProjectInput>(url, input, {
    headers,
  });

  updateCacheFragment({
    __typename: "Project",
    id: input.project,
    update: (data) => {
      const newData = { ...data };
      newData.pinned = false;
      return newData;
    },
  });

  return res.data;
};

export const libraryPinnedProjectDjango = {
  pin,
  unpin,
};
