import { FlyoutDrsFileInfoQuery } from "graphql/_Types";
import { ProjectFile } from "stores/project-files/ProjectFilesManager";
import { FlyoutFileInfoBodyPreviews } from "./FlyoutFileInfoBodyPreviews";
import {
  FlyoutFileInfoBodyBasicInfo,
  FlyoutFileInfoBodyBasicInfoProps,
} from "./FlyoutFileInfoBodyBasicInfo";
import { FlyoutFileInfoBodyMetadata } from "./FlyoutFileInfoBodyMetadata";

export interface FlyoutFileInfoBodyProps
  extends Pick<FlyoutFileInfoBodyBasicInfoProps, "drsFileAnalysisTableInfo"> {
  activeFile: Pick<
    NonNullable<FlyoutDrsFileInfoQuery["drsFile"]>,
    | "id"
    | "fileType"
    | "status"
    | "size"
    | "source"
    | "dateCreated"
    | "userId"
    | "preview"
    | "name"
    | "processingStatus"
  >;
  recordings: ProjectFile["recordings"];
}
export const FlyoutFileInfoBody = ({
  activeFile,
  recordings,
  drsFileAnalysisTableInfo,
}: FlyoutFileInfoBodyProps) => {
  return (
    <>
      <FlyoutFileInfoBodyBasicInfo
        activeFile={activeFile}
        recordings={recordings}
        drsFileAnalysisTableInfo={drsFileAnalysisTableInfo}
      />
      <FlyoutFileInfoBodyMetadata activeFile={activeFile} />
      <FlyoutFileInfoBodyPreviews activeFile={activeFile} />
    </>
  );
};
