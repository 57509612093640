import {
  EuiButton,
  EuiCollapsibleNavGroup,
  EuiPageTemplate,
  EuiTextAlign,
  EuiCodeBlock,
  EuiToast,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
} from "@inscopix/ideas-eui";
import { logOut } from "../../../utils/logOut";
import { FallbackProps } from "react-error-boundary";
import { IdeasError } from "./ErrorBoundaryFallback.types";

// Expose exports from within the module
export * from "./ErrorBoundaryFallback.types";

interface ErrorBoundaryFallbackProps extends FallbackProps {
  small?: true;
  toast?: true;
  children?: React.ReactNode;
}

/**
 * Component rendered by an error boundary in the event of an error.
 */
function ErrorBoundaryFallback({
  error,
  resetErrorBoundary,
  small,
  toast,
  children,
}: ErrorBoundaryFallbackProps) {
  const errorTitle =
    error instanceof IdeasError ? error.title : "Application Error";

  if (toast) {
    return (
      <>
        {children}
        <EuiToast title={errorTitle} color="danger" iconType="warning">
          <p>{error?.message}</p>
        </EuiToast>
      </>
    );
  }

  if (small) {
    return (
      <>
        <span style={{ color: "white" }}>There was an error </span>
        <EuiButton fill size="s" color="danger" onClick={resetErrorBoundary}>
          Reload
        </EuiButton>
      </>
    );
  }

  return (
    <div>
      <EuiPageTemplate>
        <EuiPageTemplate.EmptyPrompt
          title={<span>{errorTitle}</span>}
          iconType="warning"
          iconColor="danger"
        >
          <div>
            <p role="textbox">{error?.message}</p>
            <EuiTextAlign textAlign="left">
              <p>
                The application encountered a problem and had to close. Please
                reload the page and try again, or contact support at{" "}
                <EuiLink href="mailto:support.inscopix@bruker.com">
                  support.inscopix@bruker.com
                </EuiLink>{" "}
                if the issue persists.
              </p>
            </EuiTextAlign>
            <EuiCollapsibleNavGroup
              titleElement="span"
              background="none"
              title="View Log"
              isCollapsible={true}
              initialIsOpen={false}
            >
              <EuiTextAlign textAlign="left">
                <EuiCodeBlock language="js" isCopyable>
                  {error?.stack}
                </EuiCodeBlock>
              </EuiTextAlign>
            </EuiCollapsibleNavGroup>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiButton onClick={resetErrorBoundary}>Reload</EuiButton>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton
                  onClick={() => {
                    resetErrorBoundary();
                    void logOut();
                  }}
                >
                  Logout
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
        </EuiPageTemplate.EmptyPrompt>
      </EuiPageTemplate>
    </div>
  );
}

export default ErrorBoundaryFallback;
