/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { euiPaletteGray } from "@inscopix/ideas-eui";
import { TRegion } from "providers/RegionsProvider";

type RegionBadgeProps = {
  regionKey: TRegion["key"];
};

const styles = {
  root: css`
    display: inline-block;
    background-color: ${euiPaletteGray(10)[0]};
    border-radius: 8px;
    padding: 0px 4px;
    font-size: 0.6em;
    font-weight: 400;
    color: ${euiPaletteGray(10)[7]};
    line-height: 1.4em;
    vertical-align: middle;
  `,
};

export const RegionBadge = ({ regionKey }: RegionBadgeProps) => {
  return <div css={styles.root}>{regionKey}</div>;
};
