/** @jsxImportSource @emotion/react */
import * as fabric from "fabric";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  EuiButton,
  EuiFieldNumberProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import {
  calculateDefaultScale,
  errorMessageInvalidInitialCoordinates,
  getInBoundsCoordinatesForObj,
  isCompleteSetOfCroppingCoordinates,
} from "./CroppingFrameSelector.helpers";
import { useCroppingFrameSelectorStyles } from "./useCroppingFrameSelectorStyles";
import { FieldNumberPermissioned } from "components/FieldNumberPermissioned/FieldNumberPermissioned";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { CroppingFrameSelectorCanvas } from "./CroppingFrameSelectorCanvas";

export type CroppingCoordinates = {
  top: number;
  left: number;
  height: number;
  width: number;
};

export interface CroppingFrameSelectorProps {
  image: fabric.Image;
  onAcceptCoordinates: (coordinates: CroppingCoordinates) => void;
  onCancel: () => void;
  initialCroppingCoordinates?: CroppingCoordinates;
  isInvalidInitialCoordinates?: boolean;
  readOnly?: boolean;
}

export const CroppingFrameSelector = ({
  image,
  onAcceptCoordinates,
  initialCroppingCoordinates,
  isInvalidInitialCoordinates,
  onCancel,
  readOnly,
}: CroppingFrameSelectorProps) => {
  const styles = useCroppingFrameSelectorStyles();

  const initialCoordinates = {
    top: 0,
    left: 0,
    height: image.height,
    width: image.width,
  };

  const [selectedCoordinates, setSelectedCoordinates] = useState<
    Partial<CroppingCoordinates>
  >(
    isInvalidInitialCoordinates
      ? {}
      : initialCroppingCoordinates
      ? { ...initialCroppingCoordinates }
      : { ...initialCoordinates },
  );
  const [rect, setRect] = useState<fabric.Rect>();
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const [scale, setScale] = useState(calculateDefaultScale(image, 150, 300));

  const strokeWidth = useMemo(() => {
    return Math.round(5 / scale);
  }, [scale]);

  const onCanvasReady = useCallback(
    (canvas: fabric.Canvas) => {
      canvas.set({
        height: initialCoordinates.height + 2 * strokeWidth,
        width: initialCoordinates.width + 2 * strokeWidth,
        uniformScaling: false,
        selection: false,
      });

      setCanvas(canvas);

      const videoElement = image.getElement();
      videoElement.oncanplay = function () {
        canvas.renderAll();
      };
    },
    [image, initialCoordinates.height, initialCoordinates.width, strokeWidth],
  );

  /** initialize rectangle if does not exist */
  useEffect(() => {
    if (
      canvas &&
      !rect &&
      isCompleteSetOfCroppingCoordinates(selectedCoordinates)
    ) {
      const rect = new fabric.Rect({
        strokeWidth,
        strokeUniform: true,
        stroke: "#EB823B",
        fill: "transparent",
        ...selectedCoordinates,
        height: selectedCoordinates.height + strokeWidth,
        width: selectedCoordinates.width + strokeWidth,
        lockRotation: true,
        lockMovementX: readOnly,
        lockMovementY: readOnly,
        lockScalingX: readOnly,
        lockScalingY: readOnly,
        scaleX: 1,
        scaleY: 1,
      });
      // hide rotation controls
      rect.controls.mtr.visible = false;

      canvas.add(rect);
      canvas.setActiveObject(rect);
      setRect(rect);
    }
  }, [canvas, image, readOnly, rect, selectedCoordinates, strokeWidth]);

  /** remove rectangle if coordinates incomplete */
  useEffect(() => {
    if (
      canvas &&
      rect &&
      !isCompleteSetOfCroppingCoordinates(selectedCoordinates)
    ) {
      canvas.remove(rect);
      setRect(undefined);
    }
  }, [
    canvas,
    rect,
    selectedCoordinates,
    selectedCoordinates.height,
    selectedCoordinates.left,
    selectedCoordinates.top,
    selectedCoordinates.width,
  ]);

  /** handle coordinate and scale change via inputs */
  useEffect(() => {
    if (canvas) {
      canvas.setDimensions({
        width: (initialCoordinates.width + 2 * strokeWidth) * scale,
        height: (initialCoordinates.height + 2 * strokeWidth) * scale,
      });
      canvas.setZoom(scale);

      if (rect && isCompleteSetOfCroppingCoordinates(selectedCoordinates)) {
        rect.set({
          ...selectedCoordinates,
          height: selectedCoordinates.height + strokeWidth,
          width: selectedCoordinates.width + strokeWidth,
          scaleX: 1,
          scaleY: 1,
          strokeWidth: strokeWidth,
        });
        rect.setCoords();

        // clear old listeners
        rect.off();
        // add new listener
        rect.on("modified", (e) => {
          const obj = e.target;

          try {
            const newCoords = getInBoundsCoordinatesForObj(obj);
            obj.set({ ...newCoords });

            setSelectedCoordinates({
              ...newCoords,
              // warning: strokeWidth is inside a closure here so this will become stale
              // if this listener is not re-initialized every time strokeWidth changes
              width: newCoords.width - 2 * strokeWidth,
              height: newCoords.height - 2 * strokeWidth,
            });
          } catch (err) {
            // trigger state update that causes re-render to redraw previous rectangle
            setSelectedCoordinates((prev) => ({ ...prev }));
          }
        });

        // image offset to account for rect stroke
        image.top = strokeWidth;
        image.left = strokeWidth;

        // corner offset for drag handles
        const cornerSize = 3 * strokeWidth * scale;
        rect.transparentCorners = false;
        rect.cornerSize = cornerSize;
      }

      canvas.renderAll();
    }
  }, [
    canvas,
    image,
    initialCoordinates.height,
    initialCoordinates.width,
    rect,
    scale,
    selectedCoordinates,
    strokeWidth,
  ]);

  const onChangeValue: EuiFieldNumberProps["onChange"] = (e) => {
    const targetValue = e.target.value;
    let value: number | undefined;
    if (targetValue !== "") {
      value = Math.round(Number(targetValue));
    }
    setSelectedCoordinates((prev) => {
      const newCoords = { ...prev };
      newCoords[e.target.name as keyof typeof newCoords] = value;
      return newCoords;
    });
  };

  const bounds = {
    top: {
      max: initialCoordinates.height - 10,
      min: 0,
    },
    left: {
      max: initialCoordinates.width - 10,
      min: 0,
    },
    width: {
      max: initialCoordinates.width - (selectedCoordinates?.left ?? 0),
      min: 10,
    },
    height: {
      max: initialCoordinates.height - (selectedCoordinates?.top ?? 0),
      min: 10,
    },
  };

  const errorState = (() => {
    const widthError =
      isCompleteSetOfCroppingCoordinates(selectedCoordinates) &&
      (selectedCoordinates.width > bounds.width.max ||
        selectedCoordinates.width < bounds.width.min);

    const heightError =
      isCompleteSetOfCroppingCoordinates(selectedCoordinates) &&
      (selectedCoordinates.height > bounds.height.max ||
        selectedCoordinates.height < bounds.height.min);

    const leftError =
      isCompleteSetOfCroppingCoordinates(selectedCoordinates) &&
      (selectedCoordinates.left > bounds.left.max ||
        selectedCoordinates.left < bounds.left.min);

    const topError =
      isCompleteSetOfCroppingCoordinates(selectedCoordinates) &&
      (selectedCoordinates.top > bounds.top.max ||
        selectedCoordinates.top < bounds.top.min);

    const errorMessage = (() => {
      if (isCompleteSetOfCroppingCoordinates(selectedCoordinates)) {
        if (topError || leftError || widthError || heightError) {
          return "One or more coordinates is out of bounds.";
        }
      }
      if (
        isInvalidInitialCoordinates &&
        !isCompleteSetOfCroppingCoordinates(selectedCoordinates)
      ) {
        return errorMessageInvalidInitialCoordinates;
      }
    })();

    return {
      widthError,
      heightError,
      topError,
      leftError,
      errorMessage,
    };
  })();

  return (
    <EuiFlexGroup direction="row" gutterSize="none" responsive={false}>
      <EuiFlexItem grow={false} css={styles.scaleSliderContainer}>
        <input
          type="range"
          css={styles.scaleSlider}
          id={htmlIdGenerator("image scale")()}
          min={0.5}
          max={3}
          step={0.1}
          value={scale}
          onChange={(e) => setScale(Number(e.target.value))}
          aria-label="Image scale"
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <div css={styles.controlsAndCanvasContainer}>
          <EuiFlexItem grow={false}>
            <div css={styles.numericInputsContainer}>
              <EuiFlexItem grow={false}>
                <FieldNumberPermissioned
                  requiredPermission="edit"
                  suppressPermissionTooltip
                  prepend="left"
                  readOnly={readOnly}
                  step={1}
                  compressed
                  fullWidth={false}
                  isInvalid={errorState.leftError}
                  type="number"
                  min={bounds.left.min}
                  max={bounds.left.max}
                  value={
                    selectedCoordinates.left === undefined
                      ? ""
                      : Math.round(selectedCoordinates.left)
                  }
                  title="left"
                  // name used in onChange for identifying input
                  name={"left" satisfies keyof typeof selectedCoordinates}
                  onChange={onChangeValue}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <FieldNumberPermissioned
                  requiredPermission="edit"
                  suppressPermissionTooltip
                  prepend="top"
                  readOnly={readOnly}
                  step={1}
                  compressed
                  fullWidth={false}
                  type="number"
                  min={bounds.top.min}
                  max={bounds.top.max}
                  isInvalid={errorState.topError}
                  value={
                    selectedCoordinates.top === undefined
                      ? ""
                      : Math.round(selectedCoordinates.top)
                  }
                  title="top"
                  // name used in onChange for identifying input
                  name={"top" satisfies keyof typeof selectedCoordinates}
                  onChange={onChangeValue}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <FieldNumberPermissioned
                  requiredPermission="edit"
                  suppressPermissionTooltip
                  prepend="width"
                  readOnly={readOnly}
                  step={1}
                  compressed
                  fullWidth={false}
                  isInvalid={errorState.widthError}
                  type="number"
                  min={bounds.width.min}
                  max={bounds.width.max}
                  value={
                    selectedCoordinates.width === undefined
                      ? ""
                      : Math.round(selectedCoordinates.width)
                  }
                  title="width"
                  // name used in onChange for identifying input
                  name={"width" satisfies keyof typeof selectedCoordinates}
                  onChange={onChangeValue}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <FieldNumberPermissioned
                  requiredPermission="edit"
                  suppressPermissionTooltip
                  prepend="height"
                  readOnly={readOnly}
                  step={1}
                  compressed
                  fullWidth={false}
                  isInvalid={errorState.heightError}
                  type="number"
                  min={bounds.height.min}
                  max={bounds.height.max}
                  value={
                    selectedCoordinates.height === undefined
                      ? ""
                      : Math.round(selectedCoordinates.height)
                  }
                  title="height"
                  // name used in onChange for identifying input
                  name={"height" satisfies keyof typeof selectedCoordinates}
                  onChange={onChangeValue}
                />
              </EuiFlexItem>
            </div>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {errorState.errorMessage !== undefined && (
              <EuiFlexGroup
                alignItems="center"
                gutterSize="xs"
                css={styles.errorMessage}
              >
                <EuiIcon type="warning" />
                <EuiText>{errorState.errorMessage}</EuiText>
              </EuiFlexGroup>
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup responsive={false} gutterSize="xs">
              <EuiFlexItem grow={false}>
                <ButtonPermissioned
                  requiredPermission="edit"
                  suppressPermissionTooltip={readOnly}
                  disabled={
                    readOnly ||
                    !isCompleteSetOfCroppingCoordinates(selectedCoordinates) ||
                    errorState.errorMessage !== undefined
                  }
                  onClick={() => {
                    if (
                      isCompleteSetOfCroppingCoordinates(selectedCoordinates)
                    ) {
                      onAcceptCoordinates(selectedCoordinates);
                    }
                  }}
                >
                  Save
                </ButtonPermissioned>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton color="text" onClick={onCancel}>
                  Cancel
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <ButtonPermissioned
                  requiredPermission="edit"
                  suppressPermissionTooltip={readOnly}
                  color="text"
                  onClick={() => setSelectedCoordinates(initialCoordinates)}
                  isDisabled={readOnly}
                >
                  Reset
                </ButtonPermissioned>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem
            css={styles.canvasContainer(errorState.errorMessage !== undefined)}
          >
            <CroppingFrameSelectorCanvas
              onCanvasReady={onCanvasReady}
              backgroundImage={image}
            />
          </EuiFlexItem>
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
