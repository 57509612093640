import { useUpdatePageDatasetCache } from "components/Dataset/hooks/useUpdatePageDatasetCache";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import {
  DatasetRecordingsTable,
  DatasetRecordingsTableColumn,
} from "graphql/_Types";
import { useDatasetRecordingsTableSetIdentifierColumns } from "hooks/useDatasetRecordingsTableSetIdentifierColumns";
import { useProjectPermission } from "hooks/useProjectPermission";
import { useRecordingIdentifierContext } from "providers/RecordingIdentifierProvider/RecordingIdentifierProvider";

type SetIdentifierColumnsParams = {
  recordingsTableId: DatasetRecordingsTable["id"];
  identifierColumnIds: DatasetRecordingsTableColumn["id"][];
};

export const useActionSetIdentifierColumns = () => {
  const { dataset, datasetMode } = useDatasetDataContext();
  const { updateCache } = useUpdatePageDatasetCache(dataset.id);
  const { setIdentifierColumns } =
    useDatasetRecordingsTableSetIdentifierColumns();
  const { hasPermission } = useProjectPermission();
  const refetchAllIdentifiers = useRecordingIdentifierContext(
    (s) => s.refetchAllIdentifiers,
  );

  const getActionDef = useCallback(
    (params: SetIdentifierColumnsParams) => {
      return {
        onEnqueue: () => {
          updateCache((prevData) => {
            const newData = cloneDeep(prevData);
            const table = newData.dataset.datasetRecordingsTable;
            const allColumns = table.datasetRecordingsTableColumns.nodes;

            allColumns.forEach((column) => {
              const identifierPosition = params.identifierColumnIds.indexOf(
                column.id,
              );
              column.identifierPosition =
                identifierPosition !== -1 ? identifierPosition : null;
            });

            return newData;
          });
        },

        onDequeue: async () => {
          await setIdentifierColumns(
            params.recordingsTableId,
            params.identifierColumnIds,
          );

          // Refetch recording identifiers
          // TODO: This will be removed when identifiers are calculated on the backend
          refetchAllIdentifiers();
        },
      };
    },
    [updateCache, setIdentifierColumns, refetchAllIdentifiers],
  );

  const isDisabled = datasetMode.type !== "current" || !hasPermission("edit");

  return {
    getActionDef,
    isDisabled,
  };
};
