/**
 * Resolves a promise after a given number of milliseconds
 * @param ms The time, in milliseconds, after which the promise should resolve
 * @returns A promise that will only ever resolve
 */
export const sleep = (ms: number) => {
  return new Promise<void>((res) => {
    setTimeout(res, ms);
  });
};
