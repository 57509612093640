import axios from "axios";
import { DatasetRecordingsTableColumn } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";

export type DatasetRecordingsTableColumnResizeInput = {
  width: DatasetRecordingsTableColumn["width"];
};

type DatasetRecordingsTableColumnResizeDjangoResponse = {
  data: Pick<DatasetRecordingsTableColumn, "id" | "width">;
};

export const useDatasetRecordingsTableColumnResizeDjango = () => {
  const resizeColumn = useCallback(
    async (
      columnId: DatasetRecordingsTableColumn["id"],
      input: DatasetRecordingsTableColumnResizeInput,
    ) => {
      const url = `${getEnvVar(
        "URL_DRS_DATASET_RECORDINGS_TABLE_COLUMN_RESIZE",
      )}${columnId}/`;
      const headers = await getRequestHeaders();
      const res = await axios.patch<
        DatasetRecordingsTableColumnResizeInput,
        DatasetRecordingsTableColumnResizeDjangoResponse
      >(url, input, { headers });

      return res.data;
    },
    [],
  );

  return { resizeColumn };
};
