import { EuiCallOut, EuiLink } from "@inscopix/ideas-eui";
import { useRoutes } from "hooks/useRoutes";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

export const CallOutQuotas = () => {
  const { routeMap } = useRoutes();
  const currentTenant = useTenantContext((s) => s.currentTenant);

  return currentTenant.isQuotaExceeded ? (
    <EuiCallOut
      style={{ textAlign: "center" }}
      size="s"
      title={
        <>
          Quota Exceeded! For more details go to your{" "}
          <EuiLink
            role="button"
            to={
              routeMap["ORGANIZATION_USAGE"].dynamicPath({
                tenantKey: currentTenant.key,
              }).path
            }
          >
            organization usage page
          </EuiLink>
          .
        </>
      }
      iconType="error"
      color="danger"
    ></EuiCallOut>
  ) : null;
};
