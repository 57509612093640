/** @jsxImportSource @emotion/react */
import Plot, { PlotParams } from "react-plotly.js";
import { useCellStatusContext } from "../CellStatusEditorProvider";
import { css } from "@emotion/react";
import { EuiCallOut, EuiEmptyPrompt } from "@inscopix/ideas-eui";
import { isDefined } from "utils/isDefined";

export const TraceAndEventPlot = () => {
  const tracesAndEvents = useCellStatusContext((s) => s.tracesAndEvents);
  const selectedCells = useCellStatusContext((s) => s.selectedCells);

  // we can plot if we have events or traces
  if (
    tracesAndEvents === undefined ||
    (tracesAndEvents.events === undefined &&
      tracesAndEvents.traces === undefined)
  ) {
    return (
      <EuiCallOut
        title="No traces and events data available"
        color="primary"
        iconType="alert"
        style={{ margin: "20px" }}
      />
    );
  }

  // can only display for single cells
  if (selectedCells.size !== 1) {
    return (
      <EuiEmptyPrompt
        body={<p>Select a single cell to render trace and event data.</p>}
        hasBorder={true}
      />
    );
  }
  // if only one cell selected, extract it from array
  const [cellIndex] = selectedCells;

  // try to retrieve event time and amplitudes
  const eventTimes = isDefined(tracesAndEvents.events.times)
    ? tracesAndEvents.events.times[cellIndex]
    : undefined;
  const eventAmplitudes = isDefined(tracesAndEvents.events.amplitudes)
    ? tracesAndEvents.events.amplitudes[cellIndex]
    : undefined;

  // try to retrieve trace time and amplitudes
  const traceTimes = isDefined(tracesAndEvents.traces.times)
    ? tracesAndEvents.traces.times[cellIndex]
    : undefined;
  const traceAmplitudes = isDefined(tracesAndEvents.traces.amplitudes)
    ? tracesAndEvents.traces.amplitudes[cellIndex]
    : undefined;

  const data: PlotParams["data"] = [];
  if (isDefined(traceTimes) && isDefined(traceAmplitudes)) {
    data.push({
      type: "scattergl",
      x: traceTimes,
      y: traceAmplitudes,
      mode: "lines",
      line: { width: 1, color: "#000000" },
      hoverinfo: "x+y",
    });
  }
  if (isDefined(eventTimes) && isDefined(eventAmplitudes)) {
    data.push({
      type: "scattergl",
      x: eventTimes,
      y: eventAmplitudes,
      mode: "markers",
      marker: { color: "#FF0000", symbol: "triangle-down" },
      hoverinfo: "x+y",
    });
  }
  if (data.length === 0) {
    return (
      <EuiCallOut
        title="Could not find traces or event data for selected cell"
        color="primary"
        style={{ margin: "20px" }}
      />
    );
  }

  return (
    <Plot
      data={data}
      layout={{
        yaxis: {
          title: "Amplitude",
          ticks: "outside",
          tickformat: ".2e", // scientific notation to 2 decimals
        },
        xaxis: {
          title: "Time (s)",
          ticks: "outside",
        },
        margin: { t: 0, r: 0 },
        font: {
          family: `InterVariable, "Inter var", Inter, -apple-system, "system-ui", "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif`,
        },
        showlegend: false,
      }}
      config={{ responsive: true }}
      css={css`
        width: 100%;
        height: 400px;
      `}
    />
  );
};
