import { EuiImage } from "@inscopix/ideas-eui";
import { MenuVegaMenuClone } from "components/MenuVegaMenuClone/MenuVegaMenuClone";
import { downloadFile } from "utils/downloadFile";
import { FilePreviewComponentProps } from "./FilePreview.types";

/** Component that renders a file preview formatted as an PNG file */
export const FilePreviewPng = ({ preview }: FilePreviewComponentProps) => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: 10, right: 10 }}>
        <MenuVegaMenuClone
          menuItems={[
            {
              text: "Save as PNG",
              action: () => {
                const filename = `${preview.name}.png`;
                void downloadFile(preview.url, filename);
              },
            },
          ]}
        />
      </div>
      <EuiImage
        src={preview.url}
        alt="Preview"
        allowFullScreen
        size="fullWidth"
        style={{
          objectFit: "contain",
          cursor: "zoom-in",
        }}
      />
    </div>
  );
};
