import { ToolBooleanParam } from "./ToolParamsGrid.types";
import {
  GridRendererToolParam,
  GridRendererToolParamProps,
} from "./GridRendererToolParam/GridRendererToolParam";

interface GridRendererToolBooleanParamProps
  extends Omit<GridRendererToolParamProps, "defaultValue"> {
  toolParam: ToolBooleanParam;
}

/**
 * A component for rendering `ToolBooleanParam` grid cells
 */
export const GridRendererToolBooleanParam = ({
  toolParam,
  ...props
}: GridRendererToolBooleanParamProps) => {
  return (
    <GridRendererToolParam
      {...props}
      toolParam={toolParam}
      defaultValue={toolParam.default}
      suppressMetadatumSelector
    />
  );
};
