import { EuiLoadingChart } from "@inscopix/ideas-eui";
import {
  CroppingFrameSelector,
  CroppingFrameSelectorProps,
} from "components/CroppingFrameSelector/CroppingFrameSelector";
import { File as DrsFile } from "graphql/_Types";
import { CallOutError } from "components/CallOutError/CallOutError";
import { FileFormat } from "types/FileFormats";
import { useFabricImage } from "./useFabricImage";

export interface ModalToolCroppingFrameParamWithFileProps
  extends Pick<
    CroppingFrameSelectorProps,
    | "initialCroppingCoordinates"
    | "onAcceptCoordinates"
    | "onCancel"
    | "isInvalidInitialCoordinates"
  > {
  file: Pick<DrsFile, "id">;
  previewType: {
    fileFormat: FileFormat["id"];
  };
  readOnly?: boolean;
}

export const ModalToolCroppingFrameParamWithFile = ({
  file,
  onAcceptCoordinates,
  initialCroppingCoordinates,
  previewType,
  onCancel,
  isInvalidInitialCoordinates,
  readOnly,
}: ModalToolCroppingFrameParamWithFileProps) => {
  const { loading, image, error } = useFabricImage(
    file.id,
    previewType.fileFormat,
  );

  if (error !== undefined) {
    return (
      <CallOutError>
        <p>Failed to load image.</p>
        <p>{error.message}</p>
      </CallOutError>
    );
  }

  if (loading) {
    return <EuiLoadingChart />;
  }

  return (
    <CroppingFrameSelector
      isInvalidInitialCoordinates={isInvalidInitialCoordinates}
      onCancel={onCancel}
      initialCroppingCoordinates={initialCroppingCoordinates}
      image={image}
      onAcceptCoordinates={onAcceptCoordinates}
      readOnly={readOnly}
    />
  );
};
