import assert from "assert";
import axios from "axios";
import {
  ApplicationUser,
  ApplicationUserMembership,
  Tenant,
} from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { cloneDeep } from "lodash";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useCallback } from "react";
import { TenantMembershipRole } from "types/TenantMembershipRole";
import { updateCacheFragment } from "utils/cache-fragments";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { isDefined } from "utils/isDefined";

export type UpdateTenantMembershipRolePayload = {
  role: TenantMembershipRole["id"];
};

export type UpdateTenantMembershipRoleResponse = {
  data: {
    id: ApplicationUserMembership["id"];
    role: TenantMembershipRole["id"];
    role_label: TenantMembershipRole["name"];
    tenant: Tenant["id"];
    user: ApplicationUser["id"];
  };
};

export const useUpdateTenantMembershipRoleDjango = () => {
  const allUsers = useTenantContext((s) => s.tenantUsers);

  const updateRole = useCallback(
    async (userId: ApplicationUser["id"], role: TenantMembershipRole) => {
      const user = allUsers.find((user) => user.id === userId);
      assert(isDefined(user));
      const { membershipId } = user;

      const url = `${getEnvVar("URL_TENANT_MEMBERSHIP")}${membershipId}/`;
      const body = { role: role.id };
      const headers = await getRequestHeaders();

      const { data } = await axios.patch<
        UpdateTenantMembershipRolePayload,
        UpdateTenantMembershipRoleResponse
      >(url, body, { headers });

      updateCacheFragment({
        __typename: "ApplicationUserMembership",
        id: membershipId,
        update: (prevData) => {
          const newData = cloneDeep(prevData);
          newData.role = role.id;
          return newData;
        },
      });

      return data;
    },
    [allUsers],
  );

  return { updateRole };
};
