import { euiPaletteColorBlind } from "@inscopix/ideas-eui";

/**
 * Generates a random hex color that is color-blind enabled
 * @returns a random hex color
 */
export const generateRandomEuiColor = () => {
  const palette = euiPaletteColorBlind({
    rotations: 3,
    order: "group",
    direction: "both",
  });
  const randomIndex = Math.floor(Math.random() * palette.length);
  return palette[randomIndex];
};
