import {
  AnalysisTable,
  DatasetRecordingsTableColumn,
  Dataset,
  Metadatum,
  MetadatumFieldsFragment,
  MetadataValueFieldsFragment,
  FileRecordingGroup,
} from "graphql/_Types";
import { FileType } from "types/FileTypes";
import { ToolboxInformationSchema } from "types/ToolboxInformationSchema";

export type DrsFileColDef = {
  type: "drsFile";
  headerName: string;
  fileType: FileType["id"];
};

export type MetadataColDef = {
  type: "metadata";
  headerName: string;
  metadataKey: Metadatum["key"];
};

export type LinkedMetadataColDef = {
  type: "linkedMetadata";
  headerName: string;
  metadataKey: Metadatum["key"];
  drsFileColumnId: DatasetRecordingsTableColumn["id"];
};

export type AnalysisResultColDef = {
  type: "analysisResult";
  analysisTableId: AnalysisTable["id"];
  resultKey: NonNullable<
    ToolboxInformationSchema["tools"][number]["results"][number]["files"][number]["result_key"]
  >;
};

export type RecordingsGridColDef =
  | DrsFileColDef
  | MetadataColDef
  | LinkedMetadataColDef
  | AnalysisResultColDef;

export type RecordingsGridRowDatum = {
  id: FileRecordingGroup["id"];
  dataset: Pick<Dataset, "id" | "prefix">;
  recording: Pick<FileRecordingGroup, "id"> & {
    number: string;
    metadata: {
      nodes: (MetadatumFieldsFragment & {
        value: MetadataValueFieldsFragment["value"] | undefined;
      })[];
    };
  };
};

export const RECORDINGS_GRID_DEFAULT_COLUMN_WIDTH = 200;
