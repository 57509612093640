/** @jsxImportSource @emotion/react */
import { CellStatus } from "./CellStatusEditor.types";
import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import { css } from "@emotion/react";
import { useCellStatusContext } from "./CellStatusEditorProvider";
import { CellStatusEditorCellInfo } from "./CellStatusEditor.types";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { selectionLabelColor } from "./CellStatusEditor.helpers";
import { isElementInViewport } from "utils/isElementInViewport";

const styles = {
  root: css`
    .hover.accepted {
      background-color: rgba(0, 191, 179, 0.5);
    }
    .hover.undecided {
      background-color: rgba(254, 197, 20, 0.5);
    }
    .hover.rejected {
      background-color: rgba(189, 39, 30, 0.5);
    }
  `,
  cellStatusRow: (isSelected: boolean) => css`
    cursor: pointer;
    background-color: ${isSelected ? selectionLabelColor : "transparent"};
    padding: 2px;
    &:hover {
      background-color: ${isSelected ? selectionLabelColor : "e6f1fa"};
    }
  `,
  cellName: css`
    font-weight: bold;
    padding-left: 2px;
  `,
};

export interface CellStatusTableProps {
  readOnly?: boolean;
}

export const CellStatusTable = ({ readOnly }: CellStatusTableProps) => {
  const cellInfo = useCellStatusContext((s) => s.cells);
  const selectedCells = useCellStatusContext((s) => s.selectedCells);
  const getSelectedCells = useCellStatusContext((s) => s.getSelectedCells);
  const setCellStatus = useCellStatusContext((s) => s.setCellStatus);
  const setCellStatuses = useCellStatusContext((s) => s.setCellStatuses);

  // we do a manual tracking of hover state so that we can visually indicate to the user
  // that changing one selected cell's status will change all selected cell statuses
  const [selectedHoverStatus, setSelectedHoverStatus] = useState<
    CellStatus | undefined
  >();

  const handleSetStatus = useCallback(
    (status: CellStatus, cell: number) => {
      // clicking on a selected cell sets teh status for all selected cells
      if (getSelectedCells().has(cell)) {
        setCellStatuses(status, Array.from(getSelectedCells()));
      }
      // clicking on an unselected cell sets the status for that cell only
      else {
        setCellStatus(status, cell);
      }
    },
    [getSelectedCells, setCellStatus, setCellStatuses],
  );
  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="xs"
      style={{ width: 140 }}
      css={styles.root}
      responsive={false}
    >
      {cellInfo.map((cell) => (
        <EuiFlexItem key={cell.name}>
          <CellStatusRow
            cellIndex={cell.index}
            cellName={cell.name}
            selected={selectedCells.has(cell.index)}
            setStatus={handleSetStatus}
            hoverStatus={
              selectedCells.has(cell.index) && !readOnly
                ? selectedHoverStatus
                : undefined
            }
            setSelectedHoverStatus={setSelectedHoverStatus}
            readOnly={readOnly}
          />
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
};

const CellStatusRow = memo(function CellStatusRow({
  cellIndex,
  selected,
  setStatus,
  hoverStatus,
  setSelectedHoverStatus,
  readOnly,
  cellName,
}: {
  cellIndex: CellStatusEditorCellInfo["index"];
  cellName: string;
  selected: boolean;
  setStatus: (status: CellStatus, cell: number) => void;
  hoverStatus: CellStatus | undefined;
  setSelectedHoverStatus: (status?: CellStatus) => void;
  readOnly?: boolean;
}) {
  const cellStatus = useCellStatusContext((s) => s.cellStatuses[cellIndex]);
  const setSelectedCells = useCellStatusContext((s) => s.setSelectedCells);
  const ref = useRef<HTMLDivElement | HTMLSpanElement>(null);
  // Whether the cell is the first (by index) cell selected
  const isFirstSelected = useCellStatusContext((s) => {
    return (
      s.selectedCells.has(cellIndex) &&
      cellIndex === Math.min(...Array.from(s.selectedCells))
    );
  });

  // Scroll the row to the top of the table if it is selected but not in view
  useEffect(() => {
    if (isFirstSelected && ref.current && !isElementInViewport(ref.current)) {
      ref.current?.scrollIntoView(true);
    }
  }, [isFirstSelected]);

  return (
    <EuiFlexGroup
      justifyContent="spaceBetween"
      css={styles.cellStatusRow(selected)}
      onClick={() => {
        !selected && setSelectedCells([cellIndex]);
      }}
      responsive={false}
      ref={ref}
    >
      <EuiFlexItem grow={true} css={styles.cellName}>
        {cellName}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <CellStatusSelect
          status={cellStatus}
          selected={selected}
          setStatus={(status) => setStatus(status, cellIndex)}
          hoverStatus={hoverStatus}
          setSelectedHoverStatus={setSelectedHoverStatus}
          readOnly={readOnly}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
});

const CellStatusSelect = ({
  status,
  selected,
  hoverStatus,
  setStatus,
  setSelectedHoverStatus,
  readOnly,
}: {
  status: CellStatus;
  selected: boolean;
  hoverStatus: CellStatus | undefined;
  setStatus: (status: CellStatus) => void;
  setSelectedHoverStatus: (status?: CellStatus) => void;
  readOnly?: boolean;
}) => {
  return (
    <EuiFlexGroup direction="row" gutterSize="none" responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          aria-label="Accepted"
          iconType="check"
          color="success"
          onClick={() => setStatus(CellStatus.Accepted)}
          size="xs"
          display={status === CellStatus.Accepted ? "fill" : "empty"}
          isDisabled={readOnly}
          // if selected and hovering over a different status, show hover color
          className={
            selected &&
            hoverStatus === CellStatus.Accepted &&
            status !== CellStatus.Accepted
              ? "accepted hover"
              : ""
          }
          // track hover state for selected cells only
          onMouseEnter={
            selected
              ? () => setSelectedHoverStatus(CellStatus.Accepted)
              : undefined
          }
          onMouseLeave={
            selected ? () => setSelectedHoverStatus(undefined) : undefined
          }
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          aria-label="Undecided"
          iconType="dot"
          color="warning"
          onClick={() => setStatus(CellStatus.Undecided)}
          size="xs"
          display={status === CellStatus.Undecided ? "fill" : "empty"}
          isDisabled={readOnly}
          // if selected and hovering over a different status, show hover color
          className={
            selected &&
            hoverStatus === CellStatus.Undecided &&
            status !== CellStatus.Undecided
              ? "undecided hover"
              : ""
          }
          // track hover state for selected cells only
          onMouseEnter={
            selected
              ? () => setSelectedHoverStatus(CellStatus.Undecided)
              : undefined
          }
          onMouseLeave={
            selected ? () => setSelectedHoverStatus(undefined) : undefined
          }
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          aria-label="Rejected"
          iconType="cross"
          color="danger"
          onClick={() => setStatus(CellStatus.Rejected)}
          size="xs"
          display={status === CellStatus.Rejected ? "fill" : "empty"}
          isDisabled={readOnly}
          // if selected and hovering over a different status, show hover color
          className={
            selected &&
            hoverStatus === CellStatus.Rejected &&
            status !== CellStatus.Rejected
              ? "rejected hover"
              : ""
          }
          // track hover state for selected cells only
          onMouseEnter={
            selected
              ? () => setSelectedHoverStatus(CellStatus.Rejected)
              : undefined
          }
          onMouseLeave={
            selected ? () => setSelectedHoverStatus(undefined) : undefined
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
