/** @jsxImportSource @emotion/react */
import {
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuperSelect,
  EuiSuperSelectProps,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import {
  FileBrowserFile,
  fileBrowserSearchableColumnIds,
  fileBrowserValueGettersByColId,
} from "components/FileBrowser/FileBrowser";
import { useEffect, useState } from "react";

import assert from "assert";

type FileBrowserFileSources = "UPLOADED" | "ANALYSIS_RESULTS" | "ALL";

type Filter<T extends string> = EuiSuperSelectProps<T>["options"][number] & {
  onFilter: (file: FileBrowserFile) => boolean;
};

type FileBrowserFilterOptions = {
  source: FileBrowserFileSources;
  datasetFilter: DatasetFilter[number] | undefined;
};

type DatasetFilter = (Filter<string> & { default?: boolean })[];
export const applyFileBrowserSearchAndFilter: (
  files: FileBrowserFile[],
  selectedOptions: FileBrowserFilterOptions,
  query: string,
) => FileBrowserFile[] = (files, selectedOption, query) => {
  const { source } = selectedOption;
  return files
    .filter((file) => {
      switch (source) {
        case "ALL":
          return true;
        case "ANALYSIS_RESULTS":
          return file.source.type === "ANALYSIS_RESULT";
        case "UPLOADED":
          return file.source.type === "UPLOADED";
        default:
          throw new Error("Unhandled filter option in FileBrowser");
      }
    })
    .filter((file) => {
      return selectedOption.datasetFilter?.onFilter(file) ?? true;
    })
    .filter((file) => {
      if (query === "") {
        return true;
      }
      return fileBrowserSearchableColumnIds.some((colId) => {
        const valueGetter = fileBrowserValueGettersByColId[colId];
        const value = valueGetter(file);
        const isFieldMatch = value
          .toString()
          .toLocaleLowerCase()
          .includes(query.toLocaleLowerCase());
        return isFieldMatch;
      });
    });
};

export interface FileBrowserSearchAndFilterProps {
  selectedOptions: FileBrowserFilterOptions;
  onChangeSelectedOptions: React.Dispatch<
    React.SetStateAction<FileBrowserFilterOptions>
  >;
  query: string;
  onChangeQuery: (query: string) => void;
  datasetFilter: DatasetFilter;
}
export const FileBrowserSearchAndFilter = ({
  selectedOptions,
  onChangeSelectedOptions,
  query,
  onChangeQuery,
  datasetFilter,
}: FileBrowserSearchAndFilterProps) => {
  const selectFileSourceOptions: EuiSuperSelectProps<FileBrowserFileSources>["options"] =
    [
      { value: "ALL", inputDisplay: "Any Source" },
      { value: "UPLOADED", inputDisplay: "Uploaded Files" },
      { value: "ANALYSIS_RESULTS", inputDisplay: "Analysis Results" },
    ];

  const [value, setValue] = useState(query);
  const fileSourceId = htmlIdGenerator("file_source_select")();
  const datasetFilterId = htmlIdGenerator("file_dataset_status")();

  // if the user clears the search bar by erasing all characters, reset search
  useEffect(() => {
    if (value === "" && query !== "") {
      onChangeQuery("");
    }
  }, [onChangeQuery, query, value]);

  return (
    <>
      <EuiFlexGroup justifyContent="flexStart" responsive={false}>
        <EuiFlexItem grow={true} css={{ maxWidth: 500 }}>
          <EuiFieldSearch
            compressed
            fullWidth
            placeholder="Search..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onSearch={(query) => onChangeQuery(query)}
            incremental={false}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false} css={{ width: 200 }}>
          <EuiSuperSelect
            id={datasetFilterId}
            compressed
            options={datasetFilter}
            aria-label="Select dataset"
            onChange={(newOption) =>
              onChangeSelectedOptions((prev) => ({
                ...prev,
                datasetFilter: (() => {
                  const option = datasetFilter.find(
                    (option) => option.value === newOption,
                  );
                  assert(option !== undefined);
                  return option;
                })(),
              }))
            }
            valueOfSelected={selectedOptions.datasetFilter?.value}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false} css={{ width: 200 }}>
          <EuiSuperSelect
            id={fileSourceId}
            compressed
            aria-label="Select file sources"
            valueOfSelected={selectedOptions.source}
            onChange={(newOption) =>
              onChangeSelectedOptions((prev) => ({
                ...prev,
                source: newOption,
              }))
            }
            options={selectFileSourceOptions}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
