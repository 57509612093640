import { EuiIcon } from "@inscopix/ideas-eui";
import { Tooltip } from "../../Tooltip/Tooltip";

export const NoMatchWarning = () => {
  return (
    <Tooltip
      content={
        "This file does not match the requirements specified by this tool for this input. This may cause an error on task execution."
      }
    >
      <EuiIcon color={"warning"} type={"alert"} />
    </Tooltip>
  );
};
