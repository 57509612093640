import { ReactNode } from "react";
import { SelectorPath } from "../ToolParamsGrid.types";
import { ProjectFile } from "stores/project-files/ProjectFilesManager";
import { FileType } from "types/FileTypes";

export type TSelectionItem = {
  id: string;
  searchText: string;
  hasMatch: boolean;
  label: ReactNode | string;
};

export type TSelectionDrsFile = TSelectionItem & {
  colType: "DrsFile";
  id: string;
  hasMatch: boolean;
  projectFile: ProjectFile;
};

export interface TSelectionDataColumn extends TSelectionItem {
  colType: "DataColumn";
  order: number;
  objectType?: FileType;
  children: TSelectionDrsFile[];
}

export interface TSelectionAllFiles extends TSelectionItem {
  colType: "AllFiles";
  children: TSelectionDrsFile[];
}

export interface TSelectionRecording extends TSelectionItem {
  colType: "RecordingGroup";
  children: TSelectionDataColumn[];
}

export interface TSelectionDataset extends TSelectionItem {
  colType: "Dataset";
  children: TSelectionRecording[];
}

export interface TSelectionAnalysisTableTaskResultColumn
  extends TSelectionItem {
  colType: "TaskResultColumn";
  children: TSelectionDrsFile[];
}
export interface TSelectionAnalysisTableTaskRow extends TSelectionItem {
  colType: "Task";
  children: TSelectionAnalysisTableTaskResultColumn[];
}

export interface TSelectionAnalysisTable extends TSelectionItem {
  colType: "AnalysisTable";
  children: TSelectionAnalysisTableTaskRow[];
}

export interface SelectorPathWithLabel extends SelectorPath {
  label: ReactNode | string;
}

export type TColumnSelectionItems = (
  | TSelectionAllFiles
  | TSelectionDataset
  | TSelectionAnalysisTable
)[];
export type TAnySelectionItem =
  | TSelectionAllFiles
  | TSelectionDataset
  | TSelectionAnalysisTable
  | TSelectionRecording
  | TSelectionDataColumn
  | TSelectionDrsFile
  | TSelectionAnalysisTableTaskRow
  | TSelectionAnalysisTableTaskResultColumn;
export type TAnySelectionChildren = TAnySelectionItem[];

/**
 * Type guard to check if a selection item is a file
 */
export const isSelectionDrsFile = (
  item: TAnySelectionItem,
): item is TSelectionDrsFile => {
  return item.colType === "DrsFile";
};
