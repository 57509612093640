import { keyBy } from "lodash";

/***********
  BASE TYPE
 ***********/
export type TenantMembershipRole =
  (typeof TENANT_MEMBERSHIP_ROLES)[keyof typeof TENANT_MEMBERSHIP_ROLES];

/************************************
  TENANT MEMBERSHIP ROLE DEFINITIONS
 ************************************/

const MEMBER = {
  id: 1,
  key: "member",
  name: "Member",
} as const;

const ADMIN = {
  id: 2,
  key: "admin",
  name: "Admin",
} as const;

/*******************
  AGGREGATED EXPORTS
 *******************/

export const TENANT_MEMBERSHIP_ROLES = {
  MEMBER,
  ADMIN,
} as const;

export const TENANT_MEMBERSHIP_ROLES_BY_ID = keyBy(
  TENANT_MEMBERSHIP_ROLES,
  (role) => role.id,
) as {
  [id in TenantMembershipRole["id"]]: TenantMembershipRole;
} & {
  [unknown: number]: TenantMembershipRole | undefined;
};

export const TENANT_MEMBERSHIP_ROLES_BY_KEY = keyBy(
  TENANT_MEMBERSHIP_ROLES,
  (role) => role.key,
) as {
  [key in TenantMembershipRole["key"]]: TenantMembershipRole;
} & {
  [unknown: string]: TenantMembershipRole | undefined;
} & {
  [unknown: number | symbol]: undefined;
};
