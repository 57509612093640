import assert from "assert";
import {
  Dataset,
  PageDatasetDocument,
  PageDatasetQuery,
  PageDatasetQueryVariables,
} from "graphql/_Types";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { useCallback } from "react";
import { isNonNullish } from "utils/isNonNullish";

type TDataset = NonNullable<PageDatasetQuery["dataset"]>;

type CachedData = Pick<PageDatasetQuery, "__typename"> & {
  dataset: TDataset & {
    datasetRecordingsTable: NonNullable<TDataset["datasetRecordingsTable"]>;
    description: NonNullable<TDataset["description"]>;
    project: NonNullable<TDataset["project"]>;
  };
};

type UpdateCacheOptions = {
  // if optional, only update if cache record exists
  // otherwise cache expected to exist and error will be thrown if not found
  optional?: boolean;
};

export const useUpdatePageDatasetCache = (datasetId: Dataset["id"]) => {
  /**
   * Applies an update function on the cached data of the base dataset query
   * @param update A function to update the cached data
   * @param options Update options
   */
  const updateCache = useCallback(
    (
      update: (data: CachedData) => CachedData,
      options?: UpdateCacheOptions,
    ) => {
      client.cache.updateQuery<PageDatasetQuery, PageDatasetQueryVariables>(
        {
          query: PageDatasetDocument,
          variables: {
            datasetId: datasetId,
            // Must be explicitly passed since `undefined` was explicitly passed to the query
            cutoffTime: undefined,
          },
        },
        (data) => {
          if (data === null) {
            if (options?.optional) {
              return;
            } else {
              throw new Error("Dataset cache record not found");
            }
          }

          assert(
            isNonNullish(data.dataset) &&
              isNonNullish(data.dataset.datasetRecordingsTable) &&
              isNonNullish(data.dataset.description) &&
              isNonNullish(data.dataset.project),

            "Dataset cache record missing partial data",
          );

          return update({
            __typename: "Query",
            dataset: {
              ...data.dataset,
              datasetRecordingsTable: {
                ...data.dataset.datasetRecordingsTable,
              },
              description: {
                ...data.dataset.description,
              },
              project: data.dataset.project,
            },
          });
        },
      );
    },
    [datasetId],
  );
  return { updateCache };
};
