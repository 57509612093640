import { DatasetRecordingsTable, Dataset } from "graphql/_Types";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";

type FormFields = {
  identifierColumnIds: string[];
  datasetPrefix: string;
};

export const useUpdateRecordingIdentifierFormat = (
  datasetId: Dataset["id"],
  recordingsTableId: DatasetRecordingsTable["id"],
) => {
  const updateDatasetPrefixAction = useDatasetAction("updateDatasetPrefix");
  const setIdentifierColumnsAction = useDatasetAction("setIdentifierColumns");

  const updateRecordingIdentifierFormat = (fields: FormFields) => {
    void updateDatasetPrefixAction.enqueue({
      id: datasetId,
      newPrefix: fields.datasetPrefix,
    });

    void setIdentifierColumnsAction.enqueue({
      recordingsTableId,
      identifierColumnIds: fields.identifierColumnIds,
    });
  };

  return { updateRecordingIdentifierFormat };
};
