/** @jsxImportSource @emotion/react */
import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@inscopix/ideas-eui";
import { ProjectHeader } from "./ProjectHeader";
import { ProjectDescription } from "./ProjectDescription";
import { ProjectPanels } from "./ProjectPanels";
import { useState } from "react";
import { ProjectBottomBar } from "./ProjectBottomBar";
import { FlyoutProjectFiles } from "./FlyoutProjectFiles";
import { useProjectLayoutContext } from "./ProjectLayoutProvider";
import { useProjectStyles } from "./useProjectStyles";

export const Project = () => {
  const [isFileBrowserActive, setIsFileBrowserActive] = useState(false);
  const { modal, rightFlyout } = useProjectLayoutContext();
  const styles = useProjectStyles();

  return (
    <>
      {/* PAGE CONTAINER */}
      <EuiFlexGroup direction="column" gutterSize="none" css={styles.root}>
        {/* HEADER */}
        <EuiFlexItem grow={false}>
          <ProjectHeader />
        </EuiFlexItem>
        {/* SIDE FLYOUT, CONTENT, AND BOTTOM FLYOUT CONTAINER */}
        <EuiFlexGroup
          gutterSize="none"
          responsive={false}
          css={styles.contentAndFlyoutsContainer}
        >
          {/* GRID AND BOTTOM FLYOUT CONTAINER*/}
          <EuiFlexGroup
            direction="column"
            gutterSize="none"
            css={styles.contentAndBottomFlyoutContainer}
          >
            {/* CONTENT */}
            {isFileBrowserActive ? (
              <EuiFlexItem style={{ minHeight: 0 }}>
                <FlyoutProjectFiles
                  onClose={() => setIsFileBrowserActive(false)}
                />
              </EuiFlexItem>
            ) : (
              <EuiFlexItem>
                <ProjectDescription />
                <EuiSpacer size="s" />
                <ProjectPanels />
              </EuiFlexItem>
            )}
            {/* BOTTOM FLYOUT */}
          </EuiFlexGroup>
          {/* SIDE FLYOUT */}
          {rightFlyout && (
            <EuiFlexItem grow={false} css={styles.rightFlyoutContainer}>
              {rightFlyout.node}
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <ProjectBottomBar
            isFileBrowserActive={isFileBrowserActive}
            onClickFiles={() => setIsFileBrowserActive((isActive) => !isActive)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      {modal}
    </>
  );
};
