import assert from "assert";
import axios from "axios";
import { RecordingsGridColDef } from "components/RecordingsGrid/RecordingsGrid.types";
import { DatasetRecordingsTableColumn } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type CreateDatasetRecordingsTableColumnInput = {
  id: string;
  date_created: string;
  dataset_recordings_table: string;
  col_def: {
    key: "datasetRecordingsTableColumn.colDef";
    display_name: "Column Definition";
    id: string;
    values: { id: string; value: RecordingsGridColDef }[];
  };
};

type CreateDatasetRecordingsTableColumnDjangoResponse = {
  data: {
    id: string;
    dataset_recordings_table: string;
    date_created: string;
    col_def: {
      id: string;
      key: "datasetRecordingsTableColumn.colDef";
      display_name: "Column Definition";
    };
    tenant: number;
  };
};

export type CreateDatasetRecordingsTableColumnResult = Pick<
  DatasetRecordingsTableColumn,
  "id"
>;

export const useCreateDatasetRecordingsTableColumnDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const createDatasetRecordingsTableColumnDjango = async (
    input: CreateDatasetRecordingsTableColumnInput,
  ): Promise<CreateDatasetRecordingsTableColumnResult> => {
    assert(
      input.col_def.values.length === 1,
      "Expected 1 metadatum value when creating column",
    );
    const url = getEnvVar("URL_DRS_DATASET_RECORDINGS_TABLE_COLUMN_CREATE");
    const headers = await getRequestHeaders();
    const response = await axios.post<
      CreateDatasetRecordingsTableColumnInput,
      CreateDatasetRecordingsTableColumnDjangoResponse
    >(
      url,
      { ...input, tenant: currentTenant.id, user: currentUser.id },
      {
        headers,
      },
    );

    const { id } = response.data;

    return {
      id,
    };
  };
  return { createDatasetRecordingsTableColumnDjango };
};
