import { FC } from "react";
import { EuiProvider } from "@inscopix/ideas-eui";
import createCache from "@emotion/cache";

const cache = createCache({
  key: "ideas",
  container: document.querySelector('meta[name="emotion-style-insert"]') as
    | Node
    | undefined,
});
cache.compat = true;

// TODO ID-2461 remove me and let components determine whether or not they should be responsive
// We do not have a plan to support mobile devices at this time.
// Setting all the EUI breakpoint min-width to 800px to avoid content from being too narrow
// The following code is just a fancy dry way to making an object with all the keys set to 1200
const breakpoint = {
  ...Object.fromEntries(
    ["xxs", "xs", "s", "m", "l", "xl"].map((key) => [key, 800]),
  ),
  xxl: 1366,
};

export const ThemeProvider: FC = ({ children }) => {
  return (
    <EuiProvider cache={cache} colorMode="light" modify={{ breakpoint }}>
      {children}
    </EuiProvider>
  );
};
