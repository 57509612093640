import { Tenant } from "graphql/_Types";
import { ModalProject, ModalProjectMode } from "./ModalProject";

interface ModalCreateProjectProps {
  tenantId?: Tenant["id"];
  onClose: () => void;
}
/**
 * Displays a Project Modal to create a new project
 */
function ModalCreateProject({ tenantId, onClose }: ModalCreateProjectProps) {
  return (
    <ModalProject
      mode={ModalProjectMode.CREATE}
      tenantId={tenantId}
      onClose={onClose}
    />
  );
}

export default ModalCreateProject;
