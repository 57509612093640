import { memo } from "react";
import { TaskStatus } from "types/constants";
import { isDefined } from "utils/isDefined";
import { TaskStatusBadge } from "components/TaskStatusBadge/TaskStatusBadge";

export interface GridRendererTaskStatusProps {
  value: TaskStatus | undefined;
}

/**
 * A component for rendering task status grid cells
 */
export const GridRendererTaskStatus = memo(function GridRendererTaskStatus({
  value,
}: GridRendererTaskStatusProps) {
  return isDefined(value) ? <TaskStatusBadge taskStatus={value} /> : null;
});
