import { EuiAccordion, EuiTitle } from "@inscopix/ideas-eui";
import { FilePreview } from "components/FilePreview/FilePreview";
import { FilePreviewError } from "components/FilePreview/FilePreviewError";
import { FilePreviewGenerating } from "components/FilePreview/FilePreviewGenerating";
import { File as DrsFile } from "graphql/_Types";
import { useGetDrsFileProcessingStatus } from "hooks/useGetDrsFileProcessingStatus";

interface FlyoutFileInfoBodyPreviewsProps {
  activeFile: Pick<
    DrsFile,
    "id" | "fileType" | "status" | "preview" | "processingStatus"
  >;
}

export const FlyoutFileInfoBodyPreviews = ({
  activeFile,
}: FlyoutFileInfoBodyPreviewsProps) => {
  const { processingStatus } = useGetDrsFileProcessingStatus(activeFile);

  return (
    <EuiAccordion
      id={"drsFlyoutPreview"}
      buttonContent={
        <div>
          <EuiTitle size="xs">
            <h3>Preview</h3>
          </EuiTitle>
        </div>
      }
      paddingSize="l"
      initialIsOpen={true}
    >
      <div style={{ marginTop: "-16px" }}>
        {(() => {
          switch (processingStatus) {
            case "complete":
              return <FilePreview file={activeFile} />;
            case "pending":
              return <FilePreviewGenerating />;
            case "error":
              return <FilePreviewError />;
          }
        })()}
      </div>
    </EuiAccordion>
  );
};
