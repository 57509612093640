import { GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useColumnDefs } from "components/ToolParamsGrid/hooks/useColumnDefs";
import { useValueFormatter } from "components/ToolParamsGrid/hooks/useValueFormatter";
import { useToolParamsGridContext } from "components/ToolParamsGrid/ToolParamsGridProvider";
import { useCallback } from "react";
import { ToolParamsGridRowDatum } from "./ToolParamsGrid.types";
import { useToolParamsGridsGetContextMenuItems } from "./hooks/useToolParamsGridsGetContextMenuItems";
import { useRangeSelection } from "./hooks/useRangeSelection";
import { useToolParamsGridRowDataContext } from "./ToolParamsGridRowDataProvider";

export const ToolParamsGrid = () => {
  const { setGridApi, gridRef, analysisTableGroup, analysisTable } =
    useToolParamsGridContext();

  const rowData = useToolParamsGridRowDataContext((s) => s.rowData);

  const { columnDefs, defaultColDef } = useColumnDefs();
  const { fillOperation, onCellEditRequest, processCellFromClipboard } =
    useValueFormatter();

  const { onRangeSelectionChanged } = useRangeSelection();

  const handleGridReady = useCallback(
    (e: GridReadyEvent) => setGridApi(e.api),
    [setGridApi],
  );

  const getContextMenuItems = useToolParamsGridsGetContextMenuItems(
    gridRef,
    `${analysisTableGroup.name} (${analysisTable.name})`,
  );

  const getRowId = useCallback(
    ({ data }: { data: ToolParamsGridRowDatum }) => data.id,
    [],
  );

  return (
    <AgGridReact<ToolParamsGridRowDatum>
      alwaysShowHorizontalScroll
      className="ag-theme-balham-cell-borders"
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      enableFillHandle
      enableRangeSelection
      fillOperation={fillOperation}
      getRowId={getRowId}
      onCellEditRequest={onCellEditRequest}
      onGridReady={handleGridReady}
      processCellFromClipboard={processCellFromClipboard}
      readOnlyEdit
      rowData={rowData}
      // The column definitions used in this grid use custom attributes (see
      // types file). By default AG grid throws warnings when you do so.
      suppressPropertyNamesCheck
      suppressRowHoverHighlight
      tooltipShowDelay={0}
      ref={gridRef}
      rowHeight={35}
      getContextMenuItems={getContextMenuItems}
      onRangeSelectionChanged={onRangeSelectionChanged}
    />
  );
};
