import {
  EuiBadge,
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTableComputedColumnType,
  EuiTableRowProps,
} from "@inscopix/ideas-eui";
import { MetadatumReference, ToolParamValue } from "../ToolParamsGrid.types";
import { formatMetadatumKey } from "./MetadataTable.helpers";
import { Tooltip } from "components/Tooltip/Tooltip";
import { memo, ReactNode } from "react";

type Metadatum = {
  key: string;
  name: string;
  values: ToolParamValue[];
};

interface MetadataTableProps<T extends Metadatum> {
  isLoading: boolean;
  isError: boolean;
  items: T[];
  onSelect: (metadatumKey: Metadatum["key"], value: ToolParamValue) => void;
  currentReference: MetadatumReference | undefined;
  currentValue: ToolParamValue | undefined;
  renderValueTooltip: (
    metadatumKey: Metadatum["key"],
    value: ToolParamValue,
  ) => ReactNode;
}

/** Component that renders a table of metadatum key-value pairs */
export const MetadataTable = memo(function MetadataTable<T extends Metadatum>({
  isLoading,
  isError,
  items,
  onSelect,
  currentReference,
  currentValue,
  renderValueTooltip,
}: MetadataTableProps<T>) {
  const columns: EuiTableComputedColumnType<T>[] = [
    {
      name: "Name",
      render: ({ name, key }) => (
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem>
            <b>{name}</b>
          </EuiFlexItem>
          <EuiFlexItem>
            <code>{formatMetadatumKey(key)}</code>
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
    {
      name: "Value",
      render: (metadatum) => {
        return (
          <EuiFlexGroup gutterSize="s" wrap style={{ overflowX: "scroll" }}>
            {metadatum.values.map((value) => (
              <Tooltip
                key={value?.toString()}
                content={renderValueTooltip(metadatum.key, value)}
              >
                <EuiFlexItem grow={false}>
                  <EuiBadge
                    color={
                      currentReference?.metadatum_key === metadatum.key &&
                      value === currentValue
                        ? "primary"
                        : "hollow"
                    }
                    onClick={() => onSelect(metadatum.key, value)}
                    onClickAriaLabel="Select value"
                    style={{ cursor: "pointer", fontWeight: "normal" }}
                    title=""
                  >
                    {value}
                  </EuiBadge>
                </EuiFlexItem>
              </Tooltip>
            ))}
          </EuiFlexGroup>
        );
      },
    },
  ];

  return (
    <EuiBasicTable
      items={items}
      columns={columns}
      compressed
      loading={isLoading}
      error={isError ? "Failed to load metadata" : undefined}
      noItemsMessage={isLoading ? "Loading..." : "No metadata found"}
      rowProps={(metadatum): Partial<EuiTableRowProps> => ({
        isSelected: metadatum.key === currentReference?.metadatum_key,
      })}
    />
  );
});
