/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { DrsFileColumn } from "components/RecordingsGrid/RecordingsGrid.helpers";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiDescriptionList,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { captureException } from "@sentry/react";
import { ButtonEmptyPermissioned } from "components/ButtonEmptyPermissioned/ButtonEmptyPermissioned";

export type ModalViewDrsFileColumnProps = {
  column: DrsFileColumn;
  onClose: () => void;
};

export const ModalViewDrsFileColumn = ({
  column,
  onClose,
}: ModalViewDrsFileColumnProps) => {
  const { colDef } = column;
  const [isEditMode, setIsEditMode] = useState(false);
  const [columnName, setColumnName] = useState(colDef.headerName);
  const updateColumnDefAction = useDatasetAction("updateColumnDef");

  /* Report a warning to Sentry if we find ourselves in an illegal state */

  useEffect(() => {
    if (isEditMode && updateColumnDefAction.isDisabled) {
      captureException("Illegal attempt to edit a drsFile column");
    }
  }, [isEditMode, updateColumnDefAction.isDisabled]);

  const saveButtonTooltipMessage = (() => {
    if (columnName === "") {
      return "Enter a column name";
    }

    if (columnName === colDef.headerName) {
      return "Edit at least one value";
    }
  })();

  /**
   * Reverts the fields back to their original state
   */
  const handleCancel = () => {
    setColumnName(colDef.headerName);
    setIsEditMode(false);
  };

  /**
   * Persists changes when the save button is pressed
   */
  const handleSave = () => {
    void updateColumnDefAction.enqueue({
      columnId: column.id,
      colDef: {
        type: "drsFile",
        headerName: columnName,
        fileType: colDef.fileType,
      },
    });
    setIsEditMode(false);
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {isEditMode ? "Edit Column" : columnName}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {isEditMode ? (
          <>
            <EuiFormRow
              label="Column name"
              helpText="Custom name for this column"
            >
              <EuiFieldText
                value={columnName}
                autoFocus
                onChange={(e) => setColumnName(e.target.value)}
              />
            </EuiFormRow>
            <EuiFormRow
              label="Data type"
              helpText="Data type for this column (Not editable)"
            >
              <EuiFieldText
                value={FILE_TYPES_BY_ID[colDef.fileType].name}
                icon={FILE_TYPES_BY_ID[colDef.fileType].icon}
                disabled
              />
            </EuiFormRow>
          </>
        ) : (
          <EuiDescriptionList
            listItems={[
              {
                title: "Data type",
                description: FILE_TYPES_BY_ID[colDef.fileType].name,
              },
            ]}
          />
        )}
      </EuiModalBody>
      <EuiModalFooter>
        {isEditMode ? (
          <>
            <EuiButtonEmpty onClick={handleCancel}>
              Discard Changes
            </EuiButtonEmpty>
            <Tooltip content={saveButtonTooltipMessage}>
              <EuiButton
                iconType="save"
                onClick={() => void handleSave()}
                fill
                disabled={
                  saveButtonTooltipMessage !== undefined ||
                  updateColumnDefAction.isDisabled
                }
              >
                Save
              </EuiButton>
            </Tooltip>
          </>
        ) : (
          <>
            <ButtonEmptyPermissioned
              iconType="pencil"
              onClick={() => setIsEditMode(true)}
              disabled={updateColumnDefAction.isDisabled}
              requiredPermission="edit"
            >
              Edit
            </ButtonEmptyPermissioned>
            <EuiButton fill onClick={onClose}>
              Close
            </EuiButton>
          </>
        )}
      </EuiModalFooter>
    </EuiModal>
  );
};
