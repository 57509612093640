import { createStore } from "zustand";
import { TenantStoreState } from "./TenantProvider.types";
import { TenantProviderQuery } from "graphql/_Types";
import assert from "assert";
import { isNonNull } from "utils/isNonNull";
import { TENANT_MEMBERSHIP_ROLES_BY_ID } from "types/TenantMembershipRole";
import { isDefined } from "utils/isDefined";

/** Global store */
export const TenantStore = createStore<TenantStoreState | undefined>(
  () => undefined,
);

/** Resets the {@link TenantStore} data */
export const resetTenantStore = () => {
  TenantStore.setState(undefined);
};

/**
 * Initializes the {@link TenantStore} data
 * @param data Data fetched by a TenantProvider.
 */
export const initTenantStore = (
  data: TenantProviderQuery,
  isQuotaExceeded: boolean,
) => {
  const { tenant } = data;
  assert(isNonNull(tenant));

  const users = tenant.memberships.nodes.map((membership) => {
    const { user } = membership;
    assert(isNonNull(user));
    const role = TENANT_MEMBERSHIP_ROLES_BY_ID[membership.role];
    assert(isDefined(role));
    return { ...user, role, membershipId: membership.id };
  });

  const usersById = new Map(users.map((user) => [user.id, user]));

  const newState: TenantStoreState = {
    currentTenant: { ...tenant, isQuotaExceeded },
    tenantUsers: users,
    usersById,
  };

  TenantStore.setState(newState);
  return newState;
};
