import axios from "axios";
import { getEnvVar } from "ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useCallback } from "react";
import { QuotaType } from "types/QuotaType";

export type TenantResourceValue = {
  consumed: number;
  allowed: number;
  percentage?: number | null;
};

export type TenantResourceSummary = Record<
  Extract<
    QuotaType["key"],
    "storage" | "archived_storage" | "network" | "credits"
  >,
  TenantResourceValue
>;

export const useFetchResourceSummary = () => {
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const fetchResourceSummary = useCallback(async () => {
    const headers = await getRequestHeaders();
    const response = await axios.get<TenantResourceSummary>(
      `${getEnvVar("URL_TENANT_RESOURCE_SUMMARY")}${currentTenant.id}/`,
      { headers },
    );
    return response.data;
  }, [currentTenant.id]);

  return { fetchResourceSummary };
};
