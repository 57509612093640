import { Page404 } from "pages/404/Page404";
import { PageHome } from "pages/home/PageHome";
import { PageSettings } from "pages/settings/PageSettings";
import { Route, Switch } from "react-router-dom";
import { OrganizationRoutes } from "pages/routes/OrganizationRoutes";
import { useRoutes } from "hooks/useRoutes";

export const PageRoutes = () => {
  const { routeMap } = useRoutes();
  return (
    <Switch>
      <Route path={routeMap["ROOT"].path} exact>
        <PageHome />
      </Route>
      <Route path={routeMap["DASHBOARD"].path} exact>
        <PageHome />
      </Route>
      <Route path={routeMap["SETTINGS"].path} exact>
        <PageSettings />
      </Route>
      <Route path={routeMap["ORGANIZATION"].parameterizedPath}>
        <OrganizationRoutes />
      </Route>
      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  );
};
