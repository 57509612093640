export const COMMON = {
  upload: "exportAction",
  analysis: "indexMapping",
};

export const DRS_FILE_ACTION_ICONS = {
  archive: "snowflake",
  delete: "trash",
  unarchive: "tear",
  assign: "link",
  unassign: "unlink",
  createSeries: "listAdd",
  revertSeries: "indexFlush",
  rename: "pencil",
  download: "download",
  upload: COMMON.upload,
} as const;

export const COLUMN_TYPE_ICONS = {
  dataColumn: COMMON.upload,
  analysisResult: COMMON.analysis,
  customMetadata: "indexEdit",
  linkedMetadata: "link",
};
