import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiIcon,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  EuiTextColor,
} from "@inscopix/ideas-eui";
import { useEffect, useState } from "react";
import { useFetchAndSetHasActiveContract } from "hooks/useFetchAndSetHasActiveContract";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

export const CallOutContract = () => {
  const {
    hasActiveContract,
    expiresAt,
    fetchAndSetHasActiveContract,
    resetActiveContract,
  } = useFetchAndSetHasActiveContract();
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const daysRemaining = Math.ceil(
    (new Date(expiresAt ?? "").getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24), // 1000 * 60 * 60 * 24 = number of milliseconds in a day
  );

  const sessionStorageKey = `${currentTenant.key}CallOutContractModalClosed`;
  const showModalOnLoad =
    window.sessionStorage.getItem(sessionStorageKey) !== "true";
  const [isModalVisible, setIsModalVisible] = useState(showModalOnLoad); // The modal is visible in every reload of the app when the contract is expired

  const closeModal = () => {
    window.sessionStorage.setItem(sessionStorageKey, "true");
    setIsModalVisible(false);
  };

  useEffect(() => {
    void fetchAndSetHasActiveContract();
    return () => {
      resetActiveContract();
    };
  }, [fetchAndSetHasActiveContract, resetActiveContract]);

  return !hasActiveContract ? (
    <>
      {isModalVisible && (
        <EuiModal onClose={closeModal} maxWidth={580}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <EuiTextColor color="danger">
                <EuiIcon type="warning" color="danger" size="xl" />
                Your IDEAS subscription has expired
              </EuiTextColor>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiText>
              Please contact{" "}
              <EuiLink role="button" href="mailto:support.inscopix@bruker.com">
                support.inscopix@bruker.com
              </EuiLink>{" "}
              to renew. You may close this window and continue viewing your
              projects. Uploads, downloads, and running analysis tasks require
              an active subscription.
            </EuiText>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal}>Close</EuiButtonEmpty>
          </EuiModalFooter>
        </EuiModal>
      )}

      <EuiCallOut
        style={{ textAlign: "center" }}
        size="s"
        title={
          <>
            Your IDEAS subscription has expired. Please contact{" "}
            <EuiLink role="button" href="mailto:support.inscopix@bruker.com">
              support.inscopix@bruker.com
            </EuiLink>{" "}
            to renew.
          </>
        }
        iconType="warning"
        color="danger"
      ></EuiCallOut>
    </>
  ) : daysRemaining <= 30 ? ( // If the contract is active and it expires in less than 30 days, show a warning
    <EuiCallOut
      style={{ textAlign: "center" }}
      size="s"
      title={
        <>
          Your IDEAS subscription will expire in {daysRemaining} day
          {daysRemaining > 1 && "s"}. Please contact{" "}
          <EuiLink role="button" href="mailto:support.inscopix@bruker.com">
            support.inscopix@bruker.com
          </EuiLink>{" "}
          to renew.
        </>
      }
      iconType="warning"
      color="warning"
    ></EuiCallOut>
  ) : null;
};
