import axios, { AxiosResponse } from "axios";
import {
  File as DrsFile,
  FileFieldsFragment,
  FileSource,
} from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { FileStatus, ProcessingStatus } from "types/constants";
import { getRequestHeaders } from "utils/getRequestHeaders";

type CreateFileInput = Pick<
  DrsFile,
  "id" | "datasetId" | "projectId" | "name" | "tenantId"
> & {
  size: number;
  processingStatus: ProcessingStatus;
  fileType: NonNullable<DrsFile["fileType"]>;
  fileFormat: NonNullable<DrsFile["fileFormat"]>;
};

type CreateFileBody = {
  id: DrsFile["id"];
  dataset: DrsFile["datasetId"];
  name: DrsFile["name"];
  user: DrsFile["userId"];
  tenant: DrsFile["tenantId"];
  part_size: DrsFile["partSize"];
  size: DrsFile["size"];
  date_created: DrsFile["dateCreated"];
  project: DrsFile["projectId"];
  uploaded: true;
  processing_status: ProcessingStatus;
  file_type: DrsFile["fileType"];
  file_format: DrsFile["fileFormat"];
};

type CreateFileResponse = AxiosResponse<{
  date_created: DrsFile["dateCreated"];
  id: DrsFile["id"];
  name: DrsFile["name"];
  project: DrsFile["projectId"];
  part_size: NonNullable<DrsFile["partSize"]>;
  size: DrsFile["size"];
  status: FileStatus;
  user: DrsFile["userId"];
  tenant: DrsFile["tenantId"];
  processing_status: ProcessingStatus;
  file_type: DrsFile["fileType"];
  file_format: DrsFile["fileFormat"];
}>;

/**
 * Calculates a multipart upload part size for a minimum part size of 100 MB
 * (AWS guideline) and maximum 10,000 parts (AWS limit). Part sizes will only
 * increase from the minimum for extremely large (1TB+) files.
 * @param fileSize file size in bytes
 * @returns A multipart upload part size in bytes
 */
const getPartSize = (fileSize: number) => {
  const minPartSize = 52_428_800; // 50 MB
  const maxNumParts = 10_000;
  const factor = Math.ceil(fileSize / (minPartSize * maxNumParts));
  return factor * minPartSize;
};

/**
 * Formats a filename to be compatible with the backend
 * @param filename
 * @returns The formatted filename
 */
const formatFilename = (filename: string) => {
  /*
      A valid filename may contain the following characters:
      - Letters (A-Za-z)
      - Numbers (\d)
      - Periods (.)
      - Hyphens (-)
      - Underscores (_)
    */
  const invalidCharRegExp = /[^A-Za-z\d.-_]/g;
  const replaceValue = "-";
  return filename.replace(invalidCharRegExp, replaceValue);
};

export const useCreateFileDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);

  const createFile = useCallback(
    async (input: CreateFileInput) => {
      const url = getEnvVar("URL_DRS_FILE_CREATE");

      const body: CreateFileBody = {
        id: input.id,
        dataset: input.datasetId,
        name: formatFilename(input.name),
        user: currentUser.id,
        tenant: input.tenantId,
        size: input.size.toString(),
        part_size: getPartSize(input.size),
        date_created: new Date().toISOString(),
        project: input.projectId,
        uploaded: true,
        processing_status: input.processingStatus,
        file_format: input.fileFormat,
        file_type: input.fileType,
      };

      const headers = await getRequestHeaders({ tenantId: input.tenantId });

      const { data } = await axios.post<CreateFileBody, CreateFileResponse>(
        url,
        body,
        { headers },
      );

      const file = {
        __typename: "File",
        id: data.id,
        key: "",
        name: data.name,
        dateCreated: data.date_created,
        dateDeleted: null,
        tenantId: data.tenant,
        userId: data.user,
        status: FileStatus.CREATED,
        location: null,
        size: data.size,
        partSize: data.part_size,
        fileFormat: data.file_format,
        fileStructure: null,
        fileType: data.file_type,
        multipartUploadId: null,
        uploadStatus: null,
        signal: null,
        parentIds: null,
        projectId: data.project,
        originalName: data.name,
        datasetId: input.datasetId,
        dateArchived: null,
        dateDataDeleted: null,
        seriesParentId: null,
        source: FileSource.Uploaded,
        isSeries: false,
        seriesOrder: null,
        processingStatus: data.processing_status,
      } satisfies FileFieldsFragment;

      return { file };
    },
    [currentUser.id],
  );

  return { createFile };
};
