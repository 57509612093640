import { EuiBasicTable, EuiTableComputedColumnType } from "@inscopix/ideas-eui";
import { FileBadge } from "components/FileBadge/FileBadge";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { useMemo, useRef } from "react";
import { ProjectFile } from "stores/project-files/ProjectFilesManager";
import { filesize } from "utils/filesize";
import { useDatasetNwbFiles } from "./ModalDandi.hooks";
import { ModalDandiState } from "./ModalDandi.types";

const columns: Array<EuiTableComputedColumnType<ProjectFile>> = [
  {
    name: "Recording Session ID",
    render: (file: ProjectFile) => (
      <>
        {file.recordings.map((recording) => (
          <RecordingIdentifierBadge
            key={recording.id}
            recordingId={recording.id}
          />
        ))}
      </>
    ),
  },
  {
    name: "File",
    render: (file: ProjectFile) => <FileBadge drsFile={file} />,
  },
  {
    name: "Size",
    render: (file: ProjectFile) => filesize(file.size),
  },
];

interface ModalDandiFieldFilesProps {
  fields: ModalDandiState;
  setFiles: (files: ProjectFile[]) => void;
}

/** Component that file selection table field. */
export const ModalDandiFieldFiles = ({
  fields,
  setFiles,
}: ModalDandiFieldFilesProps) => {
  const tableRef = useRef<EuiBasicTable<ProjectFile>>(null);
  const files = useDatasetNwbFiles();
  const filesInDataColumn = useMemo(
    () =>
      files.filter((file) =>
        file.columns.some((column) => column.id === fields.dataColumnId),
      ),
    [fields.dataColumnId, files],
  );

  return (
    <EuiBasicTable
      ref={tableRef}
      items={filesInDataColumn}
      columns={columns}
      isSelectable
      selection={{
        onSelectionChange: setFiles,
        selected: fields.files,
      }}
      itemId="id"
    />
  );
};
