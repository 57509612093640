import { EuiBadge, EuiToolTip } from "@inscopix/ideas-eui";
import { ColorsByTaskStatus } from "types/ColorsByTaskStatus";
import { TaskStatus } from "types/constants";

interface TaskCountBadgeProps {
  isLoading: boolean;
  marginLeft?: number;
  numTasksByStatus: {
    [TaskStatus.COMPLETE]: number;
    [TaskStatus.RUNNING]: number;
    [TaskStatus.QUEUED]: number;
    [TaskStatus.FAILED]: number;
  };
  taskStatus:
    | TaskStatus.COMPLETE
    | TaskStatus.RUNNING
    | TaskStatus.QUEUED
    | TaskStatus.FAILED;
}

export const TaskCountBadge = ({
  isLoading,
  marginLeft,
  numTasksByStatus,
  taskStatus,
}: TaskCountBadgeProps) => {
  const numTasks = numTasksByStatus[taskStatus];

  const taskStatusAsString = (() => {
    switch (taskStatus) {
      case TaskStatus.COMPLETE:
        return "completed";
      case TaskStatus.RUNNING:
        return "running";
      case TaskStatus.QUEUED:
        return "queued";
      case TaskStatus.FAILED:
        return "failed";
    }
  })();

  const tooltipContent = (() => {
    if (isLoading) {
      return ""; // Hide tooltip
    } else if (numTasks === 1) {
      return `${numTasks} analysis ${taskStatusAsString}`; // e.g. 1 analysis running
    } else {
      return `${numTasks} analyses ${taskStatusAsString}`; // e.g. 4 analyses completed
    }
  })();

  return (
    <EuiToolTip content={tooltipContent} position="top">
      <EuiBadge
        color={ColorsByTaskStatus[taskStatus]}
        style={{ marginLeft }}
        title=""
      >
        {isLoading ? "-" : numTasks}
      </EuiBadge>
    </EuiToolTip>
  );
};
