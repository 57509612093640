import { EuiEmptyPrompt, EuiPageTemplate, EuiText } from "@inscopix/ideas-eui";
import { Helmet } from "react-helmet-async";

export const Page404 = () => {
  return (
    <>
      <Helmet>
        <title>404 Page Not found</title>
      </Helmet>
      <EuiPageTemplate>
        <EuiEmptyPrompt
          iconType="warning"
          iconColor="danger"
          title={
            <EuiText>
              <h1>404</h1>
            </EuiText>
          }
          body={
            <EuiText>
              <p>
                This page does not exist. If you believe this is an error,
                please contact your administrator.
              </p>
            </EuiText>
          }
        />
      </EuiPageTemplate>
    </>
  );
};
