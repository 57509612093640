import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { ButtonCreateProject } from "../ButtonCreateProject/ButtonCreateProject";
import { Tenant } from "graphql/_Types";

import { useMemo } from "react";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useGetProjects } from "hooks/useGetProjects";
import { ProjectCards } from "components/ProjectsCards/ProjectCards";
import { PinnedProjects } from "./PinnedProjects";

interface PanelProjectsProps {
  panelTitle?: string;
  tenantId?: Tenant["id"];
  showCreateProject?: boolean;
}

export const PanelProjects = ({
  panelTitle = "Projects",
  tenantId,
  showCreateProject = true,
}: PanelProjectsProps) => {
  const { projects, loading } = useGetProjects({ tenantId });
  const projectsBody = useMemo(() => {
    if (loading) {
      return <EuiSkeletonText lines={5} />;
    }

    if (projects === undefined) {
      return <CallOutError />;
    }
    return <ProjectCards projects={projects} tenantId={tenantId} />;
  }, [loading, projects, tenantId]);

  const pinnedProjects = useMemo(() => {
    return projects?.filter(({ pinned }) => pinned);
  }, [projects]);

  return (
    <div>
      <EuiFlexGroup responsive={false} direction="column">
        {pinnedProjects && (pinnedProjects?.length ?? 0) > 0 && (
          <PinnedProjects projects={pinnedProjects} />
        )}
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle>
              <h4>{panelTitle}</h4>
            </EuiTitle>
          </EuiFlexItem>
          {showCreateProject && (
            <EuiFlexItem grow={false}>
              <ButtonCreateProject tenantId={tenantId} />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexGroup>
      <EuiSpacer />
      {projectsBody}
    </div>
  );
};
