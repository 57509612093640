import { Interval } from "graphql/_Types";

/**
 * Formats an Interval object into a human readable string
 */
export const formatInterval = (interval: Interval) => {
  const intervalValues = [
    { value: interval.years ?? 0, label: "y" },
    { value: interval.months ?? 0, label: "m" },
    { value: interval.hours ?? 0, label: "h" },
    { value: interval.minutes ?? 0, label: "m" },
    { value: Number((interval.seconds ?? 0).toFixed(1)), label: "s" },
  ];
  const durationString = intervalValues
    .filter(({ value }) => Number(value) > 0)
    .map(({ value, label }) => `${value}${label}`)
    .join(" ");
  return durationString;
};
