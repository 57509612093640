import {
  useCreateUserSessionMutation,
  useGetCurrentUserQuery,
  useGetUserSessionByIdLazyQuery,
} from "../graphql/_Types";
import { getSessionId } from "../utils/getSessionId";
import { removeSessionId } from "../utils/removeSessionId";
import { sessionIdExists } from "../utils/sessionIdExists";
import { setSessionId } from "../utils/setSessionId";
import { ApolloError } from "@apollo/client";
import { uuid } from "../utils/uuid";
import { captureException } from "@sentry/react";

export default function useSessionState() {
  const isSessionActive = sessionIdExists();
  const [getSession] = useGetUserSessionByIdLazyQuery();

  const updateSession = () => {
    const userSessionId = getSessionId();
    if (userSessionId !== null) {
      void getSession({
        variables: {
          id: userSessionId,
        },
      });
    }
  };

  const [createSession] = useCreateUserSessionMutation({
    onCompleted: (data) => {
      if (data.createUserSession?.userSession?.id !== undefined) {
        setSessionId(data.createUserSession.userSession.id);
      }
    },
    onError: (err: ApolloError) => {
      // TODO: When IF-384/401 (Error Boundary) is merged, throw error through error boundary handlers.
      captureException(err);
    },
  });

  const setSession = (currentUser: { id: string }) => {
    isSessionActive
      ? void updateSession()
      : void createSession({
          variables: {
            id: uuid(),
            userid: currentUser.id,
          },
        });
  };

  useGetCurrentUserQuery({
    onCompleted: ({ currentUser }) => {
      if (currentUser) {
        setSession(currentUser);
      } else {
        removeSessionId();
      }
    },
    onError: (err: ApolloError) => {
      captureException(err);
      removeSessionId();
    },
  });
}
