import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalProps,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { File as DrsFile } from "graphql/_Types";
import { useDrsFileUpdateDjango } from "hooks/useDrsFileUpdateDjango";
import { cloneDeep } from "lodash";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { updateCacheFragment } from "utils/cache-fragments";
import { getFileFormatFromFilename } from "utils/identifyFile";
import {
  ModalIdentifyFileBody,
  ModalIdentifyFileBodyProps,
} from "./ModalIdentifyFileBody";

export interface ModalIdentifyFileProps extends Pick<EuiModalProps, "onClose"> {
  file: Pick<
    DrsFile,
    | "id"
    | "fileType"
    | "fileFormat"
    | "source"
    | "processingStatus"
    | "originalName"
    | "isSeries"
    | "seriesParentId"
    | "status"
  >;
}

export const ModalIdentifyFile = ({
  onClose,
  file,
}: ModalIdentifyFileProps) => {
  const { drsFileUpdate } = useDrsFileUpdateDjango();

  const handleSubmit: ModalIdentifyFileBodyProps["onSubmit"] = async (
    fileType,
  ) => {
    if (
      !getDrsFileModifyPermissionByDrsFileAndAction(file, "IDENTIFY")
        .isPermitted
    ) {
      captureException("Invalid state reached in ModalIdentifyFile", {
        extra: { file },
      });
      return addUtilityToastFailure("Failed to set file type");
    }

    try {
      // prior to 24.3, files that did not successfully process received a null file format
      // If a user identifies a file manually, we want them to be able to use it in an analysis
      // so we make sure to set the format here based on the original file name
      // which is how we derive the format now when identifying files
      const fileFormat = getFileFormatFromFilename(file.originalName).id;
      const fileUpdated = await drsFileUpdate(file.id, {
        file_type: fileType,
        file_format: fileFormat,
      });
      updateCacheFragment({
        id: file.id,
        __typename: "File",
        update: (data) => {
          const newData = cloneDeep(data);
          newData.fileType = fileUpdated.file_type;
          newData.fileFormat = fileUpdated.file_format;
          newData.processingStatus = fileUpdated.processing_status;
          return newData;
        },
      });
      addUtilityToastSuccess("Successfully updated file type");
      onClose();
    } catch (err) {
      addUtilityToastFailure("Failed to set file type");
    }
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Identify File</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <ModalIdentifyFileBody
          file={file}
          onSubmit={handleSubmit}
          onCancel={onClose}
        />
      </EuiModalBody>
    </EuiModal>
  );
};
