import { EuiConfirmModal } from "@inscopix/ideas-eui";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { useJobExecutor } from "components/ToolParamsGrid/hooks/useJobExecutor";
import { useValueValidator } from "components/ToolParamsGrid/ToolParamsGridValueValidatorContext";
import { AnalysisTable, Project } from "graphql/_Types";
import { useCallback, useMemo, useState } from "react";
import { useToolParamsGridRowDataContext } from "components/ToolParamsGrid/ToolParamsGridRowDataProvider";
import { usePollTasks } from "components/ToolParamsGrid/hooks/usePollTasks";
import { isUndefined } from "lodash";

export const AnalysisTableStartJobs = ({
  projectId,
  analysisTableId,
  projectKey,
}: {
  analysisTableId: AnalysisTable["id"];
  projectKey: Project["key"];
  projectId: Project["id"];
}) => {
  usePollTasks();

  const rowData = useToolParamsGridRowDataContext((s) => s.rowData);
  const saveStatus = useToolParamsGridRowDataContext((s) => s.saveStatus);

  const { loading, startJobs } = useJobExecutor({
    projectId: projectId,
    analysisTableId,
    projectKey,
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const errorsByRowId = useValueValidator((s) => s.errorsByRowId);

  const isRowValid = useCallback(
    (rowId: string) => {
      const errorsByParamKey = errorsByRowId?.[rowId] ?? {};
      return Object.values(errorsByParamKey).every(isUndefined);
    },
    [errorsByRowId],
  );

  const unexecutedRows = useMemo(() => {
    return rowData.filter(({ task_status }) => task_status === undefined);
  }, [rowData]);

  const unexecutedValidRows = useMemo(() => {
    return unexecutedRows.filter(({ id }) => isRowValid(id));
  }, [isRowValid, unexecutedRows]);

  const unexecutedSelectedRows = useMemo(() => {
    return unexecutedRows.filter(({ selected }) => selected);
  }, [unexecutedRows]);

  const unexecutedValidAndSelectedRows = useMemo(() => {
    return unexecutedValidRows.filter(({ selected }) => selected);
  }, [unexecutedValidRows]);

  const jobsToExecute = useMemo(() => {
    return unexecutedSelectedRows.length === 0
      ? unexecutedValidRows
      : unexecutedValidAndSelectedRows;
  }, [
    unexecutedSelectedRows.length,
    unexecutedValidAndSelectedRows,
    unexecutedValidRows,
  ]);

  const tooltipMessage = (() => {
    if (loading) {
      return "Wait for analyses to be queued";
    }

    if (jobsToExecute.length === 0) {
      return "You have no unexecuted analyses";
    }

    if (saveStatus !== "saved") {
      return "Wait for table to save";
    }
  })();

  return (
    <>
      <ButtonPermissioned
        style={{ minWidth: "170px" }} // to prevent the button from resizing when the text changes
        fill
        requiredPermission="edit"
        defaultTooltip={tooltipMessage}
        isDisabled={
          loading || jobsToExecute.length === 0 || saveStatus !== "saved"
        }
        iconType={"playFilled"}
        isLoading={loading}
        onClick={() => setShowConfirmModal(true)}
        size="s"
      >
        {unexecutedSelectedRows.length === 0
          ? `Start All Tasks: ${jobsToExecute.length}`
          : `Start Tasks: ${jobsToExecute.length}/${unexecutedSelectedRows.length}`}
      </ButtonPermissioned>
      {showConfirmModal && (
        <EuiConfirmModal
          cancelButtonText="Cancel"
          confirmButtonText="Yes"
          defaultFocusedButton="confirm"
          isLoading={loading}
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={() => {
            void startJobs(jobsToExecute);
            setShowConfirmModal(false);
          }}
          title={`Do you want to start ${jobsToExecute.length} task(s) now?`}
        />
      )}
    </>
  );
};
