import { EuiHorizontalRule, EuiText } from "@inscopix/ideas-eui";

interface EmptyTablePromptProps {
  message: string;
}

export const EmptyTablePrompt = ({ message }: EmptyTablePromptProps) => {
  return (
    <>
      <EuiHorizontalRule margin="xs" />
      <EuiText textAlign="center" color="subdued">
        {message}
      </EuiText>
      <EuiHorizontalRule margin="xs" />
    </>
  );
};
