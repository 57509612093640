import { EuiPopover } from "@inscopix/ideas-eui";
import { useState } from "react";
import "./MenuVegaMenuClone.scss";

interface MenuVegaMenuCloneProps {
  menuItems: {
    text: string;
    action: () => void;
  }[];
}

/**
 * Renders a menu that mimics the vega menu
 */

export const MenuVegaMenuClone = ({ menuItems }: MenuVegaMenuCloneProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <EuiPopover
      offset={14}
      anchorPosition="downRight"
      isOpen={isPopoverOpen}
      closePopover={() => setIsPopoverOpen(false)}
      panelPaddingSize="none"
      button={
        <div
          className="vega-menu-clone"
          onClick={() => setIsPopoverOpen((prevState) => !prevState)}
        >
          <svg
            style={{ height: 14, width: 14 }}
            viewBox="0 0 16 16"
            fill="currentColor"
            stroke="none"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle r="2" cy="8" cx="2"></circle>
            <circle r="2" cy="8" cx="8"></circle>
            <circle r="2" cy="8" cx="14"></circle>
          </svg>
        </div>
      }
    >
      <div className="vega-menu-clone-actions">
        {menuItems.map(({ text, action }) => (
          <div
            key={"vega-menu-clone-menu-item-" + text}
            onClick={action}
            className="vega-menu-clone-menu-item"
          >
            {text}
          </div>
        ))}
      </div>
    </EuiPopover>
  );
};
