/** @jsxImportSource @emotion/react */
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useGetProjects } from "hooks/useGetProjects";
import { useMemo } from "react";
import { ProjectsGridSearchable } from "./ProjectsGridSearchable";
import { Tooltip } from "components/Tooltip/Tooltip";
import { css } from "@emotion/react";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { TENANT_MEMBERSHIP_ROLES_BY_KEY } from "types/TenantMembershipRole";
import { useUserContext } from "providers/UserProvider/UserProvider";

export const PanelTenantUsageProjects = () => {
  const tenantId = useTenantContext((s) => s.currentTenant.id);

  const { loading, projects } = useGetProjects({
    showRestrictedProjects: true,
    tenantId,
  });

  const currentUser = useUserContext((s) => s.currentUser);
  const currentUserRole = useTenantContext((s) => {
    const defaultRole = TENANT_MEMBERSHIP_ROLES_BY_KEY["member"];
    const user = s.usersById.get(currentUser.id);
    return user?.role ?? defaultRole;
  });

  const body = useMemo(() => {
    if (loading) {
      return <EuiSkeletonText lines={5} />;
    }

    if (projects === undefined) {
      return <CallOutError />;
    }
    return <ProjectsGridSearchable projects={projects} />;
  }, [loading, projects]);

  return (
    <EuiPanel grow={true}>
      <EuiFlexGroup
        direction="column"
        style={{ height: "100%" }}
        gutterSize="none"
      >
        <EuiFlexGroup responsive={false} alignItems="center" gutterSize={"s"}>
          <EuiFlexItem grow={false}>
            <EuiTitle>
              <EuiText>
                <h3>Manage Projects</h3>
              </EuiText>
            </EuiTitle>
          </EuiFlexItem>
          {currentUserRole.key === "admin" && (
            <EuiFlexItem grow={false}>
              <Tooltip
                content={
                  "You can see and manage all projects in this organization (including ones not shared with you) because you are an organization admin"
                }
                css={css`
                  max-width: 350px;
                  text-wrap: wrap;
                `}
              >
                <EuiBadge color="hollow">Admin</EuiBadge>
              </Tooltip>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
        <EuiSpacer size="l" />
        {body}
      </EuiFlexGroup>
    </EuiPanel>
  );
};
