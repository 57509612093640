/**
 * Rounds a number to a significant figure.
 *
 * If the number is between 0 and 1, it rounds to the first non-zero decimal place.
 * For everything else, it rounds to one decimal place.
 *
 * @param num - The number to round.
 * @returns The rounded number as a Number.
 */

export const roundToSignificant = (num: number) => {
  if (Math.abs(num) < 1) {
    const decimalPlaces = (() => {
      const numStr = num.toString();
      const decimalIndex = 1;
      for (let i = decimalIndex + 1; i < numStr.length; i++) {
        if (numStr[i] !== "0") {
          return i - decimalIndex;
        }
      }
    })();
    return Number(num.toFixed(decimalPlaces));
  } else {
    return Number(num.toFixed(1));
  }
};
