import { create } from "zustand";
import assert from "assert";
import { FileStatus, FileUploadStatus } from "types/constants";
import { cloneDeep, throttle } from "lodash";
import { updateCacheFragment } from "utils/cache-fragments";
import { File as DrsFile, Project, Tenant } from "graphql/_Types";

type QueuedFile = {
  drsFile: {
    id: DrsFile["id"];
    partSize: NonNullable<DrsFile["partSize"]>;
  };
  project: Pick<Project, "id" | "key" | "name" | "tenantId">;
  blob: Blob;
  tenantId: Tenant["id"];
};

type FileUploadStoreState = {
  filesById: Record<
    DrsFile["id"],
    | {
        fileSize: number;
        progress: number | undefined;
        isUploaded: boolean;
        error: Error | undefined;
        project: Pick<Project, "id" | "key" | "name" | "tenantId">;
      }
    | undefined
  >;
  queue: QueuedFile[];
  initTime: string;
  enqueueFile: (file: QueuedFile) => void;
  dequeueFile: () => QueuedFile;
  onUploadProgress: (drsFileId: DrsFile["id"], progress: number) => void;
  onUploadError: (drsFileId: DrsFile["id"], error: Error) => void;
  onUploadComplete: (drsFileId: DrsFile["id"]) => void;
};

/**
 * A private hook for accessing the file upload store
 *
 * THIS SHOULD ONLY BE USED BY THE STORE MANAGER
 */
export const _useFileUploadStore = create<FileUploadStoreState>((set, get) => ({
  filesById: {},
  queue: [],
  /* Represents the time the store was created. This is necessary for filtering
     out failed uploads from other sessions. */
  initTime: new Date().toISOString(),
  enqueueFile: (file) => {
    set((state) => ({
      ...state,
      filesById: {
        ...state.filesById,
        [file.drsFile.id]: {
          fileSize: file.blob.size,
          progress: undefined,
          isUploaded: false,
          error: undefined,
          project: file.project,
        },
      },
      queue: [...state.queue, file],
    }));
  },
  dequeueFile: () => {
    const file = get().queue[0];
    set((state) => ({
      ...state,
      queue: state.queue.slice(1),
    }));
    return file;
  },
  onUploadProgress: throttle((drsFileId: DrsFile["id"], progress: number) => {
    const file = get().filesById[drsFileId];
    assert(file !== undefined, "Untracked file reference");
    set((state) => ({
      ...state,
      filesById: {
        ...state.filesById,
        [drsFileId]: {
          ...file,
          progress,
          isUploaded: progress === 100,
        },
      },
    }));
  }, 500),
  onUploadError: (drsFileId, error) => {
    const file = get().filesById[drsFileId];
    assert(file !== undefined, "Untracked file reference");
    set((state) => ({
      ...state,
      filesById: {
        ...state.filesById,
        [drsFileId]: {
          ...file,
          error,
        },
      },
    }));
  },
  onUploadComplete: (drsFileId) => {
    const file = get().filesById[drsFileId];
    assert(file !== undefined, "Untracked file reference");
    set((state) => ({
      ...state,
      filesById: {
        ...state.filesById,
        [drsFileId]: {
          ...file,
          isUploaded: true,
        },
      },
    }));

    updateCacheFragment({
      __typename: "File",
      id: drsFileId,
      update: (data) => {
        const newData = cloneDeep(data);
        newData.uploadStatus = FileUploadStatus.COMPLETE;
        newData.status = FileStatus["AVAILABLE"];
        return newData;
      },
    });
    updateCacheFragment({
      __typename: "Project",
      id: file.project.id,
      update: (data) => {
        const newData = cloneDeep(data);
        const oldSize = Number(newData.activeStorageSize ?? "0");
        const newSize = oldSize + file.fileSize;
        newData.activeStorageSize = newSize.toString();
        return newData;
      },
    });
  },
}));

/**
 * A hook for accessing the file upload store
 * @param selector A zustand selector
 * @returns The selected store state
 */
export const useFileUploadStore = <U>(
  selector: (
    state: Pick<FileUploadStoreState, "enqueueFile" | "filesById" | "initTime">,
  ) => U,
  equals?: (a: U, b: U) => boolean,
) =>
  _useFileUploadStore((state) => {
    // Restrict consumers from accessing private store state
    const { enqueueFile, filesById, initTime } = state;
    return selector({ enqueueFile, filesById, initTime });
  }, equals);
