import {
  EuiSuperSelect,
  EuiSuperSelectOption,
  EuiText,
} from "@inscopix/ideas-eui";
import { AgGridReact } from "ag-grid-react";
import { RecordingsGridRowDatum } from "components/RecordingsGrid/RecordingsGrid.types";
import { chain } from "lodash";
import { RefObject, useEffect } from "react";
import { isDefined } from "utils/isDefined";
import { isNonNullish } from "utils/isNonNullish";
import { useDatasetNwbFiles } from "./ModalDandi.hooks";
import { ModalDandiState } from "./ModalDandi.types";

interface ModalDandiFieldDataColumnProps {
  fields: ModalDandiState;
  gridRef: RefObject<AgGridReact<RecordingsGridRowDatum>>;
  setField: <T extends keyof ModalDandiState>(
    field: T,
    value: ModalDandiState[T],
  ) => void;
}

/** Component that data column select field. */
export const ModalDandiFieldDataColumn = ({
  fields,
  gridRef,
  setField,
}: ModalDandiFieldDataColumnProps) => {
  const files = useDatasetNwbFiles();

  const columnIds = chain(files)
    .flatMap((file) => file.columns.map((column) => column.id))
    .uniq()
    .value();

  // Auto-select the data column if there is only one
  useEffect(() => {
    if (columnIds.length === 1 && fields.dataColumnId === "") {
      setField("dataColumnId", columnIds[0]);
    }
  }, [columnIds, fields.dataColumnId, setField]);

  const options: EuiSuperSelectOption<string>[] = columnIds
    .map((columnId) => {
      const column = gridRef.current?.columnApi.getColumn(columnId);
      return column;
    })
    .filter(isNonNullish)
    .map((column) => {
      const colDef = column.getColDef();
      const colGroupDef = column.getParent()?.getColGroupDef();
      const colId = column.getColId();

      /* If the column belongs to a column group, display the group header name
         as the option's title and the column header name as the subtitle. If the
         column does not belong to a column group, display the column header
         name as the option's title and do not display a subtitle. */
      const title = colGroupDef?.headerName ?? colDef.headerName;
      const subtitle = isDefined(colGroupDef?.headerName)
        ? colDef.headerName
        : undefined;

      return {
        inputDisplay: title,
        dropdownDisplay: (
          <>
            <strong>{title}</strong>
            {subtitle !== undefined && (
              <EuiText size="s" color="subdued">
                <p>{subtitle}</p>
              </EuiText>
            )}
          </>
        ),
        value: colId,
      };
    });

  const hasOptions = options.length !== 0;

  return (
    <EuiSuperSelect<string>
      options={options}
      placeholder={
        hasOptions ? "Select a data column" : "No NWB data columns found"
      }
      disabled={!hasOptions}
      valueOfSelected={fields.dataColumnId}
      onChange={(value) => setField("dataColumnId", value)}
    />
  );
};
