import {
  EuiHeaderAlert,
  EuiSkeletonRectangle,
  EuiSkeletonText,
} from "@inscopix/ideas-eui";

/** Component that renders placeholder content for notifications that are still loading */
export const NotificationAlertSkeleton = () => {
  return (
    <EuiHeaderAlert
      title={
        <EuiSkeletonRectangle
          height={20}
          width="50%"
          style={{ marginBottom: 12 }}
        />
      }
      text={<EuiSkeletonText lines={2} style={{ marginBottom: 10 }} />}
      date={
        <EuiSkeletonRectangle
          height={16}
          width={48}
          style={{ marginBottom: 5 }}
        />
      }
    />
  );
};
