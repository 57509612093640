import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { StoreApi, useStore } from "zustand";
import AppLoading from "components/AppLoading/AppLoading";
import { useTenantProviderQuery } from "graphql/_Types";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { TenantStoreState } from "./TenantProvider.types";
import { useParams } from "react-router-dom";
import {
  TenantStore,
  initTenantStore,
  resetTenantStore,
} from "./TenantProvider.helpers";
import * as sentry from "@sentry/react";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { Page404 } from "pages/404/Page404";

type TenantContextValue = StoreApi<TenantStoreState>;

const TenantContext = createContext<TenantContextValue | undefined>(undefined);

interface TenantProviderProps {
  children: ReactNode;
}

/** Context provider for managing data for the current tenant */
export const TenantProvider = ({ children }: TenantProviderProps) => {
  const { tenantKey } = useParams<{ tenantKey: string }>();
  const { data } = useTenantProviderQuery({ variables: { tenantKey } });
  const isQuotaExceeded = useUserContext((s) => {
    const tenant = s.tenants.find((tenant) => tenant.key === tenantKey);
    return tenant?.isQuotaExceeded ?? false;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  // Reset store when provider mounts and unmounts
  useEffect(() => {
    resetTenantStore();
    return () => {
      resetTenantStore();
      sentry.setContext("tenant", null);
      sentry.setTag("tenant", null);
    };
  }, []);

  useEffect(() => {
    if (data !== undefined) {
      try {
        // Initialize store
        const state = initTenantStore(data, isQuotaExceeded);
        // Add tenant information to Sentry reports
        const { currentTenant } = state;
        sentry.setContext("tenant", currentTenant);
        sentry.setTag("tenant", currentTenant.key);
      } catch (error) {
        setError(error as Error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [data, isQuotaExceeded]);

  if (error !== undefined) {
    return <Page404 />;
  }

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <TenantContext.Provider value={TenantStore as TenantContextValue}>
      {children}
    </TenantContext.Provider>
  );
};

/** Hook for consuming the {@link TenantContext} */
export const useTenantContext = <T,>(
  selector: (state: TenantStoreState) => T,
) => {
  const store = useContext(TenantContext);
  assert(isDefined(store), new ContextOutOfBoundsError("TenantContext"));
  return useStore(store, selector);
};
