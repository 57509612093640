import { EuiButtonProps } from "@inscopix/ideas-eui";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { ModalDataset } from "components/ModalDataset/ModalDataset";
import { Project } from "graphql/_Types";
import { useState } from "react";

export interface ButtonCreateDatasetProps
  extends Omit<EuiButtonProps, "onClick"> {
  project: Pick<Project, "id" | "key">;
}
export const ButtonCreateDataset = ({
  project,
  ...props
}: ButtonCreateDatasetProps) => {
  const [showModalCreateDataset, setShowModalCreateDataset] = useState(false);

  return (
    <>
      <ButtonPermissioned
        title="Create Dataset"
        color="primary"
        iconType={"plus"}
        {...props}
        onClick={() => setShowModalCreateDataset(true)}
        requiredPermission="edit"
      >
        Create Dataset
      </ButtonPermissioned>
      {showModalCreateDataset && (
        <ModalDataset
          onClose={() => setShowModalCreateDataset(false)}
          project={project}
        />
      )}
    </>
  );
};
