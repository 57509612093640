import assert from "assert";
import axios from "axios";
import { File as DrsFile } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { FileStatus } from "types/constants";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type DeleteDrsFileInput = {
  date_deleted: DrsFile["dateDeleted"];
};

export type DeleteDrsFileDjangoResponse = {
  data: {
    id: DrsFile["id"];
    date_deleted: DrsFile["dateDeleted"];
    status: DrsFile["status"];
  };
};

export const useDrsFileScheduleDeleteDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);

  const drsFileScheduleDelete = async (
    drsFileId: DrsFile["id"],
    input: DeleteDrsFileInput,
  ) => {
    const url = `${getEnvVar("URL_DRS_FILE_SCHEDULE_DELETE")}${drsFileId}/`;
    const body = { ...input, user: currentUser.id };
    const headers = await getRequestHeaders();
    const res = await axios.patch<
      DeleteDrsFileInput,
      DeleteDrsFileDjangoResponse
    >(url, body, { headers });
    const { status, date_deleted } = res.data;
    assert(
      status === FileStatus["SCHEDULE_DELETE"] && date_deleted !== null,
      "Received incorrect object status from delete request",
    );
    return { ...res.data, status, date_deleted };
  };

  return { drsFileScheduleDelete };
};
