import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { ModalInfo } from "components/ModalInfo/ModalInfo";
import {
  AnalysisTableGroup,
  useUpdateAnalysisTableGroupByIdMutation,
} from "graphql/_Types";
import { useCallback, useMemo, useState } from "react";
import { isDefined } from "utils/isDefined";

interface ModalEditAnalysisTableGroupProps {
  onClose: () => void;
  onError: () => void;
  onSuccess: () => void;
  analysisTableGroup: Pick<AnalysisTableGroup, "id" | "name">;
}

export const ModalEditAnalysisTableGroup = ({
  onClose,
  onError,
  onSuccess,
  analysisTableGroup,
}: ModalEditAnalysisTableGroupProps) => {
  const [updateGroup] = useUpdateAnalysisTableGroupByIdMutation();
  const formId = htmlIdGenerator()();
  const [fields, setFields] = useState({
    name: analysisTableGroup.name,
  });
  const [loading, setLoading] = useState(false);

  const errors = useMemo(
    () => ({
      name: fields.name === "" ? "Please specify a table name" : undefined,
    }),
    [fields.name],
  );

  const isDisabled = useMemo(
    () => Object.values(errors).some(isDefined),
    [errors],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await updateGroup({
        variables: {
          id: analysisTableGroup.id,
          patch: {
            name: fields.name,
          },
        },
      });
      onSuccess();
    } catch (err) {
      captureException(err);
      onError();
    } finally {
      setLoading(false);
      onClose();
    }
  }, [fields, onClose, onError, onSuccess, analysisTableGroup.id, updateGroup]);

  const setField = useCallback(
    <Key extends keyof typeof fields>(
      key: Key,
      value: (typeof fields)[Key],
    ) => {
      setFields((prevFields) => ({
        ...prevFields,
        [key]: value,
      }));
    },
    [],
  );

  return (
    <ModalInfo
      data-test-subj="analysis-table-edit-modal"
      body={
        <EuiForm
          component="form"
          role="form"
          onSubmit={(e) => {
            e.preventDefault();
            void handleSubmit();
          }}
          id={formId}
        >
          <EuiFormRow
            label="Name"
            isInvalid={isDefined(errors.name)}
            error={errors.name}
          >
            <EuiFieldText
              data-test-subj="analysis-tablel-edit-name-input"
              autoFocus
              placeholder="Name"
              name="name"
              value={fields.name}
              icon="layers"
              aria-label="Name"
              maxLength={64}
              required
              isInvalid={isDefined(errors.name)}
              onChange={(e) => {
                const { value } = e.target;
                setField("name", value);
              }}
              disabled={loading}
            />
          </EuiFormRow>
        </EuiForm>
      }
      footer={
        <>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          <EuiButton
            data-test-subj="analysis-table-edit-name-submit-button"
            type="submit"
            fill
            form={formId}
            disabled={isDisabled}
            isLoading={loading}
          >
            Update
          </EuiButton>
        </>
      }
      onClose={onClose}
      title="Edit Analysis Table"
    />
  );
};
