// ATTENTION: I intentionally disabled this ESLint rule to directly adhere to
// the Stack Overflow answer. I don't want to risk breaking a known working
// solution.

/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/**
 * Determines whether an element is within the bounds of the current viewport.
 *
 * Credit: https://stackoverflow.com/a/7557433
 * @param element
 * @returns `true` if the element is in the viewport, `false` otherwise.
 */
export const isElementInViewport = (element: Element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
