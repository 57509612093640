import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { ModalArchiveDrsFileProps } from "components/ModalArchiveDrsFile/ModalArchiveDrsFile";
import { ModalUnarchiveDrsFiles } from "components/ModalUnarchiveDrsFile/ModalUnarchiveDrsFile";
import { useRoutes } from "hooks/useRoutes";
import { useState } from "react";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";

export interface ButtonUnarchiveDrsFileProps {
  drsFile: Parameters<typeof getDrsFileModifyPermissionByDrsFileAndAction>[0] &
    ModalArchiveDrsFileProps["drsFiles"][number];
}

export const ButtonUnarchiveDrsFile = ({
  drsFile,
}: ButtonUnarchiveDrsFileProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { routeMap } = useRoutes();
  const isViewingHistory =
    routeMap.DATASET_HISTORY.isRouteMatch() ||
    routeMap.DATASET_VERSION.isRouteMatch();

  return (
    <>
      <ButtonIconPermissioned
        aria-label="Unarchive file"
        defaultTooltip={
          isViewingHistory
            ? "Unarchive disabled while viewing history"
            : "Unarchive file"
        }
        color="primary"
        iconType="tear"
        disabled={
          isViewingHistory ||
          !getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "UNARCHIVE")
            .isPermitted
        }
        onClick={() => {
          setShowConfirmModal(true);
        }}
        requiredPermission="edit"
      />
      {showConfirmModal && (
        <ModalUnarchiveDrsFiles
          drsFiles={[drsFile]}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};
