import axios, { AxiosResponse } from "axios";
import {
  ApplicationUser,
  ApplicationUserMembership,
  Tenant,
} from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { InviteStatus } from "types/constants";
import { evictCacheFragment } from "utils/cache-fragments";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { TenantUserInvite } from "./PanelTenantAccess.types";

/**
 * Represents the options passed to {@link sendInvitations}
 */
type GetPendingInvitationsOptions = {
  tenantId: Tenant["id"];
};

/**
 * Gets all pending tenant-user initiations.
 * @param options
 * @returns The pending invitations.
 */
export const getPendingInvitations = async ({
  tenantId,
}: GetPendingInvitationsOptions) => {
  const url = getEnvVar("URL_TENANT_USER_INVITE");
  const headers = await getRequestHeaders();
  const { data } = await axios.get<TenantUserInvite[]>(url, { headers });
  return data.filter(
    (invitation) =>
      invitation.status === InviteStatus.OPEN && invitation.tenant === tenantId,
  );
};

/**
 * Represents the options passed to {@link sendInvitations}
 */
type SendInvitationsOptions = {
  inviterId: ApplicationUser["id"];
  tenantId: Tenant["id"];
  emails: string[];
};

/**
 * Sends a tenant-user invitation.
 * @param options
 * @returns The new invitation.
 */
export const sendInvitations = ({
  inviterId,
  tenantId,
  emails,
}: SendInvitationsOptions) => {
  const promises = emails.map(async (email) => {
    const url = getEnvVar("URL_TENANT_USER_INVITE");
    const headers = await getRequestHeaders();
    const body = {
      inviter: inviterId,
      tenant: tenantId,
      email,
    };

    const { data } = await axios.post<
      TenantUserInvite,
      AxiosResponse<TenantUserInvite>
    >(url, body, { headers });

    return data;
  });
  return Promise.all(promises);
};

/**
 * Represents the options passed to {@link resendInvitation}
 */
type ResendInvitationOptions = {
  invitationId: TenantUserInvite["id"];
};

/**
 * Resends a tenant-user invitation.
 * @param options
 * @returns The updated invitation.
 */
export const resendInvitation = async ({
  invitationId,
}: ResendInvitationOptions) => {
  const baseUrl = getEnvVar("URL_TENANT_USER_INVITE");
  const url = `${baseUrl}resend/${invitationId}/`;
  const headers = await getRequestHeaders();

  const { data } = await axios.patch<
    TenantUserInvite,
    AxiosResponse<TenantUserInvite>
  >(url, undefined, { headers });

  return data;
};

/**
 * Represents the options passed to {@link revokeInvitation}
 */
type RevokeInvitationOptions = {
  invitationId: TenantUserInvite["id"];
};

/**
 * Revokes a tenant-user invitation.
 * @param options
 * @returns The updated invitation.
 */
export const revokeInvitation = async ({
  invitationId,
}: RevokeInvitationOptions) => {
  const baseUrl = getEnvVar("URL_TENANT_USER_INVITE");
  const url = `${baseUrl}revoke/${invitationId}/`;
  const headers = await getRequestHeaders();

  const { data } = await axios.patch<
    TenantUserInvite,
    AxiosResponse<TenantUserInvite>
  >(url, undefined, { headers });

  return data;
};

/**
 * Represents the options passed to {@link removeMembership}
 */
type RemoveMembershipOptions = {
  membershipId: ApplicationUserMembership["id"];
};

/**
 * Removes a user from a tenant.
 * @param options
 */
export const removeMembership = async ({
  membershipId,
}: RemoveMembershipOptions) => {
  const baseUrl = getEnvVar("URL_TENANT_MEMBERSHIP");
  const url = `${baseUrl}${membershipId}/`;
  const headers = await getRequestHeaders();
  await axios.delete(url, { headers });

  evictCacheFragment({
    __typename: "ApplicationUserMembership",
    id: membershipId,
  });
};
