/** @jsxImportSource @emotion/react */
import { EuiBadge } from "@inscopix/ideas-eui";
import { css } from "@emotion/react";

interface ShortTaskIdProps {
  taskId: string;
}
/**
 * A component for rendering a shortened task ID in a badge
 */
export const ShortTaskId = ({ taskId }: ShortTaskIdProps) => {
  const styles = {
    root: css`
      font-family: monospace;
    `,
  };
  return <EuiBadge css={styles.root}>{taskId.slice(0, 5)}</EuiBadge>;
};
