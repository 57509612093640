import {
  CommonProps,
  EuiContextMenuItem,
  EuiContextMenuItemProps,
} from "@inscopix/ideas-eui";
import { withUserPermission } from "hocs/withUserPermission";
import { ButtonHTMLAttributes } from "react";

/**
 * {@link EuiContextMenuItem} represented as a function component. This is necessary
 * because TypeScript only supports propagating generic type arguments with
 * functions, not classes.
 */
const EuiContextMenuItemFC = (
  props: CommonProps &
    Omit<
      ButtonHTMLAttributes<HTMLButtonElement>,
      "type" | "onClick" | "disabled"
    > &
    EuiContextMenuItemProps,
) => <EuiContextMenuItem {...props} />;

/**
 * Enhanced {@link EuiContextMenuItem} that is disabled when the user lacks a
 * required permission
 */
export const ContextMenuItemPermissioned =
  withUserPermission(EuiContextMenuItemFC);
