import { useCallback, useState } from "react";
import { Jsonifiable } from "type-fest";

type LocalStorage = {
  "isx.fileBrowser.paginationSize": number;
  "isx.flyoutFiles.isExpanded": boolean;
};

const parseItem = <T extends keyof LocalStorage>(
  storageKey: T,
  defaultValue: LocalStorage[T],
) => {
  const item = localStorage.getItem(storageKey);
  if (item === null) {
    const stringifiedDefault = JSON.stringify(defaultValue);
    localStorage.setItem(storageKey, stringifiedDefault);
    return defaultValue;
  }
  const parsedValue = JSON.parse(
    item,
    // ensures a only non-nullable jsonifiable types can be put in the LocalStorage type
  ) as LocalStorage[T] satisfies NonNullable<Jsonifiable>;
  return parsedValue;
};

export const useLocalStorage = <T extends keyof LocalStorage>(
  key: T,
  defaultValue: LocalStorage[T],
) => {
  const [value, setValue] = useState(parseItem<T>(key, defaultValue));

  const updateValue = useCallback(
    (value: LocalStorage[T]) => {
      const stringifiedValue = JSON.stringify(value);
      setValue(value);
      localStorage.setItem(key, stringifiedValue);
    },
    [key],
  );

  return [value, updateValue] as const;
};
