import { RangeSelectionChangedEvent } from "ag-grid-community";
import { ToolParamsGridRowDatum } from "../ToolParamsGrid.types";
import { COL_ID_CHECKBOX } from "../AnalysisTableConstants";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";

/**
 * Custom hook for handling range selection in the ToolParamsGrid component.
 *
 * This hook provides a callback function, `onRangeSelectionChanged`, which is called when the range selection changes in the ToolParamsGrid component.
 * It determines if a range of cells is selected and if the selected column is a checkbox column. If so, it updates the corresponding row data with the selected state.
 *
 * @returns An object containing the `onRangeSelectionChanged` callback function.
 */
export const useRangeSelection = () => {
  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );

  const onRangeSelectionChanged = (
    data: RangeSelectionChangedEvent<ToolParamsGridRowDatum>,
  ) => {
    // If the range selection is not finished, return
    if (!data.finished) return;

    const cellRanges = data.api.getCellRanges();
    cellRanges?.forEach((cellRange) => {
      // Determine if a valid range of cells is selected
      const startRow = cellRange.startRow?.rowIndex;
      const endRow = cellRange.endRow?.rowIndex;
      if (startRow === undefined || endRow === undefined) {
        return;
      }

      // See if the selected column is a checkbox column
      // If not a checkbox column, return
      const isCheckBoxColumn = cellRange.columns?.some(
        (column) => column.getColId() === COL_ID_CHECKBOX,
      );
      if (!isCheckBoxColumn) return;

      //   Check to see if a single row is selected
      //   If so, toggle the selected state of the row
      if (startRow === endRow) {
        const rowNode = data.api.getDisplayedRowAtIndex(startRow);
        const rowNodeId = rowNode?.data?.id;
        const selected = !(rowNode?.data?.selected ?? false);
        if (rowNodeId !== undefined) {
          updateRowDatum(
            rowNodeId,
            { selected },
            { skipSave: true, forceUpdateLockedRow: true },
          );
        }
        return;
      }

      // If a range of rows is selected, update the selected state of the rows to true
      const rowIds = Array.from(
        { length: endRow - startRow + 1 },
        (_, index) => {
          const rowNode = data.api.getDisplayedRowAtIndex(startRow + index);
          const rowNodeId = rowNode?.data?.id;
          return rowNodeId;
        },
      ).filter((rowId): rowId is string => rowId !== undefined);

      updateRowDatum(
        rowIds,
        { selected: true },
        { skipSave: true, forceUpdateLockedRow: true },
      );
    });
  };

  return {
    onRangeSelectionChanged,
  };
};
