import assert from "assert";
import axios from "axios";
import { File as DrsFile } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { FileStatus } from "types/constants";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type ArchiveDrsFileInput = {
  date_archived: DrsFile["dateArchived"];
};

export type ArchiveDrsFileDjangoResponse = {
  data: {
    id: DrsFile["id"];
    date_archived: DrsFile["dateArchived"];
    status: DrsFile["status"];
  };
};

export const useDrsFileArchiveDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);

  const drsFileArchive = async (
    drsFileId: DrsFile["id"],
    input: ArchiveDrsFileInput,
  ) => {
    const url = `${getEnvVar("URL_DRS_FILE_ARCHIVE")}${drsFileId}/`;
    const body = { ...input, user: currentUser.id };
    const headers = await getRequestHeaders();
    const res = await axios.patch<
      ArchiveDrsFileInput,
      ArchiveDrsFileDjangoResponse
    >(url, body, { headers });
    const { status, date_archived } = res.data;
    assert(
      status === FileStatus["ARCHIVED"] && date_archived !== null,
      "Received incorrect object status from delete request",
    );
    return { ...res.data, status, date_archived };
  };

  return { drsFileArchive };
};
