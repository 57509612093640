import { EuiLink } from "@inscopix/ideas-eui";
import assert from "assert";
import { NotificationsFeedBodyQuery } from "graphql/_Types";
import { useRoutes } from "hooks/useRoutes";
import { isNonNull } from "utils/isNonNull";
import { NotificationAlert } from "./NotificationAlert";
import { useCallback } from "react";
import { TRegion } from "providers/RegionsProvider";
import { isUndefined } from "lodash";
import { RegionBadge } from "components/RegionBadge/RegionBadge";

interface NotificationAlertTenantUserMembershipProps {
  data: NonNullable<
    NotificationsFeedBodyQuery["notifications"]
  >["nodes"][number] & { region?: TRegion };
  onNotificationRead: (
    notificationId: string,
    region?: TRegion,
    refetchExternalNotifications?: () => void,
  ) => void;
  refetchExternalNotifications: () => void;
}

/** Component that renders a notification that a user has been invited to a tenant */
export const NotificationAlertTenantUserMembership = ({
  data,
  onNotificationRead,
  refetchExternalNotifications,
}: NotificationAlertTenantUserMembershipProps) => {
  const { routeMap } = useRoutes();
  const { dateCreated, hasSeen, id: notificationId } = data;
  const attrs = data.tenantUserMembershipNotification;
  assert(isNonNull(attrs));
  const { inviter, tenant } = attrs;
  assert(isNonNull(inviter) && isNonNull(tenant));
  const inviterFullName = `${inviter.firstName} ${inviter.lastName}`.trim();

  const tenantPath = routeMap["ORGANIZATION"].dynamicPath({
    tenantKey: tenant.key,
  }).path;

  const onNotificationReadMemoized = useCallback(() => {
    if (!hasSeen) {
      onNotificationRead(
        notificationId,
        data.region,
        refetchExternalNotifications,
      );
    }
  }, [
    hasSeen,
    notificationId,
    onNotificationRead,
    refetchExternalNotifications,
    data.region,
  ]);

  const action = isUndefined(data.region) ? (
    <EuiLink href={tenantPath} onClick={onNotificationReadMemoized}>
      Go to organization
    </EuiLink>
  ) : (
    <EuiLink
      href={`${data.region.url}${tenantPath}`}
      target="_blank"
      onClick={onNotificationReadMemoized}
    >
      Go to organization <RegionBadge regionKey={data.region.key} />
    </EuiLink>
  );

  return (
    <NotificationAlert
      dateCreated={dateCreated}
      hasSeen={hasSeen}
      title="Invited to organization"
      onNotificationRead={onNotificationReadMemoized}
      text={
        <span>
          You have been invited to the <strong>{tenant.name}</strong>{" "}
          organization by <strong>{inviterFullName}</strong>.
        </span>
      }
      action={action}
    />
  );
};
