import { EuiText } from "@inscopix/ideas-eui";
import { CSSProperties, memo, useEffect, useMemo, useState } from "react";
import { ToolParamsGridRowDatum } from "./ToolParamsGrid.types";
import { useToolParamsGridContext } from "./ToolParamsGridProvider";
import { TaskStatus } from "../../types/constants";
import { useProjectDataContext } from "../../pages/project/ProjectDataProvider";
import { SwitchPermissioned } from "components/SwitchPermissioned/SwitchPermissioned";
import { useToolParamsGridRowDataContext } from "./ToolParamsGridRowDataProvider";

interface GridRendererAttachResultsProps {
  data: ToolParamsGridRowDatum;
}

export const GridRendererAttachResults = memo(
  function GridRendererAttachResults({ data }: GridRendererAttachResultsProps) {
    const { datasets: projectDatasets } = useProjectDataContext();
    const { recordingsById } = useToolParamsGridContext();
    const attachResultsFn = useToolParamsGridRowDataContext(
      (s) => s.attachResults,
    );

    const [attachResults, setAttachResults] = useState(data.attachResults);

    const style: CSSProperties = {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      width: "100%",
    };

    // Update attachResults state when data changes (e.g. when the attachment is changed via the menubar)
    useEffect(() => {
      setAttachResults(data.attachResults);
    }, [data.attachResults]);

    const { recordings, datasets, task_status } = data;

    const handleChange = useMemo(() => {
      return (newAttachResults: boolean) => {
        setAttachResults(newAttachResults);
        void attachResultsFn([data.id], newAttachResults);
      };
    }, [attachResultsFn, data.id]);

    const tooltipContent = (() => {
      const recordingTerm =
        recordings?.length === 1 ? "recording" : "recordings";
      const datasetTerm = datasets?.length === 1 ? "dataset" : "datasets";
      const verb = task_status === TaskStatus.COMPLETE ? "are" : "will";
      const adjective =
        task_status === TaskStatus.COMPLETE ? "attached" : "attach";
      const condition = attachResults ? "" : <strong>not</strong>;

      // @TODO nest recordings under datasets
      return (
        <EuiText grow={false}>
          <h5>
            Results {verb} {condition} {adjective} to {datasetTerm} and{" "}
            {recordingTerm} from inputs
          </h5>
          <ul>
            <li>
              Datasets
              <ul>
                {datasets && datasets.length !== 0 ? (
                  datasets?.map((id) => {
                    const foundDataset = projectDatasets.find(
                      (projectDataset) => id === projectDataset.id,
                    );
                    if (foundDataset) {
                      return <li key={id}>{foundDataset.name}</li>;
                    } else return null;
                  })
                ) : (
                  <li>None</li>
                )}
              </ul>
            </li>
            <li>
              Recordings
              <ul>
                {recordings && recordings?.length !== 0 ? (
                  recordings?.map((id) => {
                    const foundRecording = recordingsById[id];
                    if (foundRecording !== undefined) {
                      return (
                        <li key={id}>
                          {foundRecording.datasetPrefix}-{foundRecording.number}
                        </li>
                      );
                    } else return null;
                  })
                ) : (
                  <li>None</li>
                )}
              </ul>
            </li>
          </ul>
        </EuiText>
      );
    })();

    return (
      <div style={style}>
        <SwitchPermissioned
          aria-label="Attach Results"
          showLabel={false}
          label={"Attach Results"}
          checked={attachResults}
          compressed
          onChange={(e) => void handleChange(e.target.checked)}
          requiredPermission="edit"
          defaultTooltip={tooltipContent}
        />
      </div>
    );
  },
);
