import { EuiButtonEmpty, EuiButtonProps } from "@inscopix/ideas-eui";
import ModalCreateProject from "components/ModalProject/ModalCreateProject";
import { Tenant } from "graphql/_Types";
import { useState } from "react";

export const ButtonCreateProject = (
  props: Omit<EuiButtonProps, "onClick"> & { tenantId?: Tenant["id"] },
) => {
  const [showModalCreateProject, setShowModalCreateProject] = useState(false);

  return (
    <>
      <EuiButtonEmpty
        color="primary"
        iconType={"plus"}
        {...props}
        onClick={() => setShowModalCreateProject(true)}
      >
        Create Project
      </EuiButtonEmpty>
      {showModalCreateProject && (
        <ModalCreateProject
          tenantId={props.tenantId}
          onClose={() => setShowModalCreateProject(false)}
        />
      )}
    </>
  );
};
