import { useActionAssignFile } from "./factories/useActionAssignFile";
import { useActionCreateColumn } from "./factories/useActionCreateColumn";
import { useActionCreateRecordingMetadataValues } from "./factories/useActionCreateRecordingMetadataValues";
import { useActionDataDeleteFile } from "./factories/useActionDataDeleteFile";
import { useActionDeleteColumn } from "./factories/useActionDeleteColumn";
import { useActionDeleteRecording } from "./factories/useActionDeleteRecording";
import { useActionPinColumn } from "./factories/useActionPinColumn";
import { useActionRenameColumn } from "./factories/useActionRenameColumn";
import { useActionReorderColumns } from "./factories/useActionReorderColumns";
import { useActionResizeColumn } from "./factories/useActionResizeColumns";
import { useActionScheduleDeleteFile } from "./factories/useActionScheduleDeleteFile";
import { useActionSetIdentifierColumns } from "./factories/useActionSetIdentifierColumns";
import { useActionUnassignFile } from "./factories/useActionUnassignFile";
import { useActionUnpinColumn } from "./factories/useActionUnpinColumn";
import { useActionUpdateColumnDef } from "./factories/useActionUpdateColumnDef";
import { useActionUpdateDatasetPrefix } from "./factories/useActionUpdateDatasetPrefix";
import { useActionUploadFile } from "./factories/useActionUploadFile";

// Register new factories here
const _actionDefFactoryMap = {
  assignFile: useActionAssignFile,
  createColumn: useActionCreateColumn,
  createRecordingMetadataValues: useActionCreateRecordingMetadataValues,
  dataDeleteFile: useActionDataDeleteFile,
  deleteColumn: useActionDeleteColumn,
  deleteRecording: useActionDeleteRecording,
  pinColumn: useActionPinColumn,
  renameColumn: useActionRenameColumn,
  reorderColumns: useActionReorderColumns,
  resizeColumn: useActionResizeColumn,
  scheduleDeleteFile: useActionScheduleDeleteFile,
  setIdentifierColumns: useActionSetIdentifierColumns,
  unassignFile: useActionUnassignFile,
  unpinColumn: useActionUnpinColumn,
  updateColumnDef: useActionUpdateColumnDef,
  updateDatasetPrefix: useActionUpdateDatasetPrefix,
  uploadFile: useActionUploadFile,
};

/*
https://stackoverflow.com/q/75114429/18977368

Black magic necessary for TypeScript to infer the param types of the
`getActionDef` callbacks returned by the factories
*/

/** Represents a definition of an action's execution logic */
export type DatasetActionDef<Result = unknown> = {
  onEnqueue: () => void;
  onDequeue: () => Result;
};

type _ActionDefFactoryMap = typeof _actionDefFactoryMap;

/** Represents the name of a dataset action */
export type DatasetActionName = keyof _ActionDefFactoryMap;

/** Represents the parameters of an action definition */
export type DatasetActionDefParams<K extends DatasetActionName> = Parameters<
  ReturnType<_ActionDefFactoryMap[K]>["getActionDef"]
>[0];

/** Represents the return value of a dataset action */
export type DatasetActionResult<K extends DatasetActionName> = ReturnType<
  ReturnType<ReturnType<_ActionDefFactoryMap[K]>["getActionDef"]>["onDequeue"]
>;

/** Factory for creating an `ActionDef` */
type DatasetActionDefFactory<Params, Result> = {
  getActionDef: (params: Params) => DatasetActionDef<Result>;
  isDisabled: boolean;
};

type ActionDefFactoryMap = {
  [K in DatasetActionName]: () => DatasetActionDefFactory<
    DatasetActionDefParams<K>,
    DatasetActionResult<K>
  >;
};

// Enforce the coerced type on the exported map
export const actionDefFactoryMap: ActionDefFactoryMap = _actionDefFactoryMap;
