import {
  EuiCallOut,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";

const styles = {
  root: {
    maxWidth: 500,
  },
};

type ModalRenameMetadataColumnProps = {
  onClose: () => void;
};

export const ModalRenameMetadataColumn = ({
  onClose,
}: ModalRenameMetadataColumnProps) => {
  return (
    <EuiModal onClose={onClose} style={styles.root}>
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h3">Rename Column</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiCallOut title="Attention" color="warning" iconType="help">
          <p>
            Renaming metadata columns is not currently supported. Consider
            creating a new column and copying values from this column.
          </p>
        </EuiCallOut>
      </EuiModalBody>
    </EuiModal>
  );
};
