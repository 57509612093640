import { create } from "zustand";

interface HasActiveContract {
  hasActiveContract: boolean;
  expiresAt?: string;
  setHasActiveContract: (hasActiveContract: boolean) => void;
  setExpiresAt: (expiresAt?: string) => void;
}

/**
 * A hook for for managing the hasActiveContract state
 * @returns The hasActiveContract state and setter
 */

export const useHasActiveContract = create<HasActiveContract>()((set) => {
  return {
    hasActiveContract: true,
    setHasActiveContract: (hasActiveContract) =>
      set({ hasActiveContract: hasActiveContract }),
    setExpiresAt(expiresAt) {
      set({ expiresAt: expiresAt });
    },
  };
});
