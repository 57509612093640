import assert from "assert";
import axios, { AxiosResponse } from "axios";
import {
  ApplicationUser,
  Dataset,
  File as DrsFile,
  ExportToDandiToolInfoQuery,
  Project,
  Tenant,
  ToolParameter,
  ToolSecret,
  ToolSource,
  ToolVersion,
  useExportToDandiToolInfoLazyQuery,
} from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { DandiServer } from "components/ModalDandi/ModalDandi.types";
import { gql } from "@apollo/client";
import { isDefined } from "utils/isDefined";
import { isNonNull } from "utils/isNonNull";
import { useUserContext } from "providers/UserProvider/UserProvider";

/* Query that fetches the IDs and keys of the tool version, tool sources, tool
   parameters and tool secrets associated with the export to DANDI tool */
gql`
  query ExportToDandiToolInfo {
    tool: toolByKey(key: "export_to_dandi") {
      id
      toolVersions: toolVersionsByToolId(orderBy: VERSION_ASC, last: 1) {
        nodes {
          id
          toolSources: toolSourcesByToolVersionId {
            nodes {
              id
              key
            }
          }
          toolParameters: toolParametersByToolVersionId {
            nodes {
              id
              key
            }
          }
          toolSecrets: toolSecretsByToolVersionId {
            nodes {
              id
              key
            }
          }
        }
      }
    }
  }
`;

/** Represents the information the caller needs to specify when executing the tool */
type ExportToDandiDjangoOptions = {
  datasetId: Dataset["id"];
  projectId: Project["id"];
  apiKey: string;
  server: DandiServer;
  dandisetId: string;
  fileIds: string[];
};

/** Represents the structure of the request body expected by the backend */
type ExportToDandiDjangoRequest = {
  user: ApplicationUser["id"];
  tenant: Tenant["id"];
  dataset: Dataset["id"];
  project: Project["id"];
  tool_version: ToolVersion["id"];
  task_sources: {
    tool_source: ToolSource["id"];
    file: DrsFile["id"];
  }[];
  task_parameters: {
    tool_parameter: ToolParameter["id"];
    value: string;
  }[];
  task_secrets: {
    key: ToolSecret["key"];
    value: string;
  }[];
};

/** Represents the structure of the response data returned by the backend */
export type ExportToDandiDjangoResponse = {
  id: DrsFile["id"];
  status: DrsFile["status"];
};

/**
 * Validates and formats the data returned by the `ExportToDandiToolInfo` query.
 * @param data
 * @returns The formatted query data.
 * @throws An error if any of the data is missing or malformed.
 */
const validateToolInfo = (data: ExportToDandiToolInfoQuery | undefined) => {
  // Validate tool
  assert(isDefined(data));
  const { tool } = data;
  assert(isNonNull(tool));

  // Validate tool version
  const toolVersion = tool.toolVersions.nodes[0];
  assert(isDefined(toolVersion));

  // Validate tool sources
  const sources = toolVersion.toolSources.nodes;
  assert(sources.length === 1);

  // Validate tool parameters
  const params = toolVersion.toolParameters.nodes;
  const dandisetIdParam = params.find(({ key }) => key === "dandiset_id");
  assert(isDefined(dandisetIdParam));
  const serverParam = params.find(({ key }) => key === "instance");
  assert(isDefined(serverParam));

  // Validate tool secrets
  const secrets = toolVersion.toolSecrets.nodes;
  assert(secrets.length === 1);

  return {
    toolVersionId: toolVersion.id,
    toolSources: {
      fileIds: {
        id: sources[0].id,
      },
    },
    toolParameters: {
      dandisetId: {
        id: dandisetIdParam.id,
      },
      server: {
        id: serverParam.id,
      },
    },
    toolSecrets: {
      apiKey: {
        key: secrets[0].key,
      },
    },
  };
};

/**
 * Hook that executes the export to DANDI tool
 * @returns `exportToDandi`: A function to trigger the export
 */
export const useExportToDandiDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const [getToolInfo] = useExportToDandiToolInfoLazyQuery({
    fetchPolicy: "network-only",
  });

  const exportToDandi = async (options: ExportToDandiDjangoOptions) => {
    // Fetch and validate information related to the tool
    const { data } = await getToolInfo();
    const toolInfo = validateToolInfo(data);

    // Map the tool inputs onto the request body
    const url = getEnvVar("URL_TES_TASK_CREATE");
    const body: ExportToDandiDjangoRequest = {
      user: currentUser.id,
      tenant: currentTenant.id,
      dataset: options.datasetId,
      project: options.projectId,
      tool_version: toolInfo.toolVersionId,
      task_sources: options.fileIds.map((fileId) => ({
        tool_source: toolInfo.toolSources.fileIds.id,
        file: fileId,
      })),
      task_parameters: [
        {
          tool_parameter: toolInfo.toolParameters.dandisetId.id,
          value: options.dandisetId,
        },
        {
          tool_parameter: toolInfo.toolParameters.server.id,
          value: options.server,
        },
      ],
      task_secrets: [
        {
          key: toolInfo.toolSecrets.apiKey.key,
          value: options.apiKey,
        },
      ],
    };

    // Create the tool execution task
    const headers = await getRequestHeaders();
    return axios.post<
      ExportToDandiDjangoResponse,
      AxiosResponse<ExportToDandiDjangoResponse>,
      ExportToDandiDjangoRequest
    >(url, body, { headers });
  };

  return { exportToDandi };
};
