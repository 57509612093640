import {
  AnyVal,
  NDArrayRep,
} from "@bokeh/bokehjs/build/js/lib/core/serialization";
import { DocJson } from "@bokeh/bokehjs/build/js/lib/document";

export type CellStatuses = CellStatus[];
export enum CellStatus {
  Accepted,
  Undecided,
  Rejected,
}

// all cell metrics generated by the QC report
export const cellStatusEditorCellMetricTypes = [
  "snr",
  "medianDecay",
  "meanEventRate",
  "sdDecay",
  "circularity",
  "perimeters",
  "gof",
  "maxCorr",
  "rho",
  "skew",
  "areas",
] as const;

export const cellStatusEditorCellMetricTypeLabels: {
  [key in (typeof cellStatusEditorCellMetricTypes)[number]]: string;
} = {
  snr: "Signal to noise ratio",
  medianDecay: "Median decay timescale (s)",
  meanEventRate: "Mean event rate (Hz)",
  sdDecay: "Std. dev. decay timescale (s)",
  circularity: "Circularity",
  perimeters: "Perimeter",
  gof: "Goodness of fit to decaying exponential",
  maxCorr: "Max correlation to other traces",
  rho: "Spearman correlation with time",
  skew: "Trace skew",
  areas: "Area",
};

export type CellStatusEditorCellMetrics = {
  [K in (typeof cellStatusEditorCellMetricTypes)[number]]: number[] | undefined;
};
export type CellStatusCellContours = number[][];
export type CellStatusCellTraces = {
  times: number[][] | undefined;
  amplitudes: number[][] | undefined;
};
export type CellStatusCellEvents = {
  times: number[][] | undefined;
  amplitudes: number[][] | undefined;
};
export type CellStatusEditorImageData = { label: string; image: number[][] }[];
export type CellStatusEditorCellInfo = {
  index: number;
  name: string;
  initialStatus: CellStatus;
};

export type CellStatusEditorSpacingInfo = {
  numPixelsX: number | undefined;
  numPixelsY: number | undefined;
};

/* Bokeh types - mostly stolen from BokehJS, some interpreted */
export type BokehDocJson = DocJson;
export type BokehAnyVal = AnyVal;
export type BokehNDArrayRep = NDArrayRep;
export type BokehEntryAttributes = {
  labels: string[];
  js_event_callbacks: {
    entries: [
      (
        | string
        | [
            {
              attributes: {
                args: {
                  entries: [string, { id: string } | BokehNDArrayRep[]][];
                };
              };
            },
          ]
      )[],
    ];
  };
};
export type BokehContours = [[[BokehNDArrayRep]]];
export type BokehColours = [[number, number, number, number]];
export type BokehCellNames = string[];
export type BokehTraces = BokehNDArrayRep[];
export type BokehEventEntries = ["amplitudes" | "times", BokehNDArrayRep[]][];
export type BokehEvents = {
  type: "map";
  entries?: BokehEventEntries;
};
export type BokehStatuses = {
  type: "ndarray";
  order: "little";
  dtype: "object";
  shape: [number];
  array: ("undecided" | "accepted" | "rejected")[];
};
export type BokehEntries = [
  string,
  (
    | BokehNDArrayRep
    | BokehContours
    | BokehColours
    | BokehCellNames
    | BokehStatuses
  ),
][];
