/** @jsxImportSource @emotion/react */
import {
  EuiText,
  EuiCallOut,
  EuiTreeView,
  EuiTreeViewProps,
} from "@inscopix/ideas-eui";
import {
  ToolRoiFrameParam,
  ToolParamsGridRowDatum,
} from "../ToolParamsGrid.types";
import { ProjectFile } from "stores/project-files/ProjectFilesManager";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { isToolPathParam } from "../ToolParamsGrid.helpers";
import { captureException } from "@sentry/react";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ModalToolRoiFrameParamWrapper } from "./ModalToolRoiFrameParamWrapper";
import { FILE_FORMATS_BY_KEY } from "types/FileFormats";
import { useProjectPermission } from "hooks/useProjectPermission";
import { ModalToolRoiFrameParamNoSourceFile } from "./ModalToolRoiFrameParamNoSourceFile";
import { RoiEditorProps } from "../../RoiEditor/RoiEditor";
import { ModalToolRoiFrameParamWithFile } from "./ModalToolRoiFrameParamWithFile";
import { useState } from "react";
import { useGetProjectFilesByFileIds } from "../hooks/useGetProjectFilesByFileIds";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { css } from "@emotion/react";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";
import assert from "assert";

export type ModalToolRoiFrameParamProps = {
  toolParam: ToolRoiFrameParam;
  rowId: ToolParamsGridRowDatum["id"];
  onClose: () => void;
} & Pick<RoiEditorProps, "initialShapes">;

/**
 * A component for editing any `ToolRoiFrameParam` grid cells
 */
export const ModalToolRoiFrameParam = ({
  toolParam,
  initialShapes,
  onClose,
  rowId,
}: ModalToolRoiFrameParamProps) => {
  const data = useToolParamsGridRowDataContext((s) =>
    s.rowData.find((row) => row.id === rowId),
  );
  assert(
    data !== undefined,
    "Expected row data to be defined in ModalToolRoiFrameParam",
  );

  const { getProjectFilesByFileIds } = useGetProjectFilesByFileIds();
  const [selectedSourceFile, setSelectedSourceFile] =
    useState<Pick<ProjectFile, "id" | "metadata">>();

  const { hasPermission } = useProjectPermission();
  const readOnly = data.task_id !== undefined || !hasPermission("edit");

  const { toolSpec } = useToolParamsGridContext();
  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );

  const sourceFileParamKey = toolParam.type.source_file.key;

  const sourceFileParam = toolSpec.params.find(
    (param) => param.key === sourceFileParamKey,
  );

  // handle invalid linked param
  if (sourceFileParam === undefined) {
    captureException(
      "Source file param key points to param that does not exist",
      {
        extra: {
          sourceFileParamKey,
        },
      },
    );
    return (
      <ModalToolRoiFrameParamWrapper toolParam={toolParam} onClose={onClose}>
        <CallOutError />
      </ModalToolRoiFrameParamWrapper>
    );
  }

  if (!isToolPathParam(sourceFileParam)) {
    captureException(
      "Unexpected param type for ToolRoiFrameParam source file",
      {
        extra: {
          sourceFileParam,
        },
      },
    );
    return (
      <ModalToolRoiFrameParamWrapper toolParam={toolParam} onClose={onClose}>
        <CallOutError />
      </ModalToolRoiFrameParamWrapper>
    );
  }

  const projectFilesFromFileIds = getProjectFilesByFileIds(
    (data.params[sourceFileParamKey] ?? []) as string[],
  );

  const sourceFiles = projectFilesFromFileIds.drsFilesFound;

  /**
   * None of the selected files could be found (i.e. all have been deleted after they were selected)
   */
  const isFileNotFound =
    sourceFiles.length === 0 &&
    projectFilesFromFileIds.fileIdsNotFound.length > 0;

  if (isFileNotFound) {
    return (
      <ModalToolRoiFrameParamWrapper toolParam={toolParam} onClose={onClose}>
        <EuiCallOut>
          <EuiText>
            <p>File not found.</p>
            <p>
              The selected file could not be located. It may have been deleted.{" "}
            </p>
          </EuiText>
        </EuiCallOut>
      </ModalToolRoiFrameParamWrapper>
    );
  }

  /**
   * No file selected
   */
  if (sourceFiles.length === 0) {
    return (
      <ModalToolRoiFrameParamNoSourceFile
        toolParam={toolParam}
        rowDatum={data}
        sourceFileParam={sourceFileParam}
        onClose={onClose}
        readOnly={readOnly}
      />
    );
  }

  const onAccept = (shapes: ShapeJson[]) => {
    const newParamValue = JSON.stringify(shapes);

    updateRowDatum(data.id, {
      params: {
        [toolParam.key]: newParamValue,
      },
    });
    onClose();
  };

  /**
   * Auto pick if only one file
   */
  if (
    sourceFiles.length === 1 &&
    !sourceFiles[0].isSeries &&
    selectedSourceFile === undefined
  ) {
    setSelectedSourceFile(sourceFiles[0]);
  }

  /**
   * Multiple valid files, allow user to select one
   */

  if (selectedSourceFile === undefined) {
    return (
      <ModalToolRoiFrameParamWrapper toolParam={toolParam} onClose={onClose}>
        <>
          <EuiText>
            Select which input file you would like to use in the ROI editor. For
            series input, a single child of the series must be selected.
          </EuiText>
          <br />
          <EuiTreeView
            style={{
              border: "1px solid lightgrey",
              borderRadius: 5,
              padding: 10,
            }}
            css={css`
              .euiTreeView__nodeLabel {
                width: 100%;
              }
            `}
            aria-label="File tree"
            showExpansionArrows
            expandByDefault
            items={sourceFiles.map((file) => {
              const treeItem: EuiTreeViewProps["items"][number] = {
                id: file.id,
                label: "",
              };
              if (file.isSeries) {
                treeItem.label = (
                  <EuiText color="GrayText" size="s">
                    {file.name + " (series)"}
                  </EuiText>
                );
                treeItem.children = file.seriesFiles.map((seriesFile) => {
                  const drsFile = {
                    ...seriesFile,
                    isSeries: false,
                    source: file.source,
                  };
                  return {
                    id: seriesFile.id,
                    label: (
                      <EuiText
                        onClick={() => setSelectedSourceFile(drsFile)}
                        size="s"
                      >
                        {drsFile.name}
                      </EuiText>
                    ),
                  };
                });
              } else {
                treeItem.label = (
                  <EuiText onClick={() => setSelectedSourceFile(file)} size="s">
                    {file.name}
                  </EuiText>
                );
              }
              return treeItem;
            })}
          />
        </>
      </ModalToolRoiFrameParamWrapper>
    );
  }

  const previewFormat =
    FILE_FORMATS_BY_KEY[toolParam.type.source_file.data.file_format]?.id;

  if (previewFormat === undefined) {
    return (
      <ModalToolRoiFrameParamWrapper toolParam={toolParam} onClose={onClose}>
        <CallOutError>Improper tool configuration.</CallOutError>
      </ModalToolRoiFrameParamWrapper>
    );
  }

  return (
    <ModalToolRoiFrameParamWrapper
      toolParam={toolParam}
      onClose={onClose}
      readOnly={readOnly}
      fullScreen
    >
      <ModalToolRoiFrameParamWithFile
        toolSpec={toolParam}
        onCancel={onClose}
        file={selectedSourceFile}
        previewType={{
          fileFormat: previewFormat,
          key: toolParam.type.source_file.data.key,
        }}
        onAccept={onAccept}
        readOnly={readOnly}
        initialShapes={initialShapes}
      />
    </ModalToolRoiFrameParamWrapper>
  );
};
