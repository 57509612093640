import { EuiSpacer, EuiTitle } from "@inscopix/ideas-eui";

interface FlyoutSubsectionProps {
  children: React.ReactNode;
  title?: string;
}

export const FlyoutSubsection = ({
  children,
  title,
}: FlyoutSubsectionProps) => {
  return (
    <>
      {title !== undefined && (
        <EuiTitle size="s">
          <h3>{title}</h3>
        </EuiTitle>
      )}
      <EuiSpacer size="s" />
      {children}
      <EuiSpacer size="m" />
    </>
  );
};
