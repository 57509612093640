import axios from "axios";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type DeleteDatasetRecordingsTableColumnInput = {
  date_assignment_ended: string;
};

type DeleteDatasetRecordingsTableColumnDjangoResponse = {
  data: {
    id: string;
    date_assignment_ended: string;
  };
};

export const useDeleteDatasetRecordingsTableColumnDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);

  const deleteDatasetRecordingsTableColumnDjango = async (
    columnId: string,
    input: DeleteDatasetRecordingsTableColumnInput,
  ): Promise<DeleteDatasetRecordingsTableColumnDjangoResponse["data"]> => {
    const url = `${getEnvVar(
      "URL_DRS_DATASET_RECORDINGS_TABLE_COLUMN_END_ASSIGNMENT",
    )}${columnId}/`;
    const headers = await getRequestHeaders();
    const response = await axios.patch<
      DeleteDatasetRecordingsTableColumnInput,
      DeleteDatasetRecordingsTableColumnDjangoResponse
    >(
      url,
      { ...input, user: currentUser.id },
      {
        headers,
      },
    );

    return response.data;
  };
  return { deleteDatasetRecordingsTableColumnDjango };
};
