import {
  NotificationsFeedBodyQuery,
  NotificationsFeedBodyQueryVariables,
  NotificationsFeedBodyDocument,
} from "graphql/_Types";
import { compact, isUndefined } from "lodash";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { useRegionsContext, TRegion } from "providers/RegionsProvider";
import { useState, useEffect, useCallback } from "react";

export type TExternalNotificationsAndRegion = {
  notifications: Awaited<ReturnType<typeof getNotifications>>;
  region: TRegion;
};

const getNotifications = (onlyShowUnread: boolean, region: TRegion) => {
  return client.query<
    NotificationsFeedBodyQuery,
    NotificationsFeedBodyQueryVariables
  >({
    query: NotificationsFeedBodyDocument,
    fetchPolicy: "no-cache",
    context: {
      uri: region.urlGraphQL,
      queryDeduplication: false,
    },
    variables: onlyShowUnread ? { condition: { hasSeen: false } } : undefined,
  });
};

export const useGetExternalNotifications = (onlyShowUnread: boolean) => {
  const { externalRegions } = useRegionsContext();
  const [results, setResults] = useState<TExternalNotificationsAndRegion[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const getExternalNotifications = useCallback(async () => {
    try {
      const promises = externalRegions.map(async (region) => ({
        region,
        notifications: await getNotifications(onlyShowUnread, region),
      }));
      const results = await Promise.all(promises);
      setResults(results);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }, [externalRegions, onlyShowUnread]);

  useEffect(() => {
    void getExternalNotifications(); // initial fetch

    const interval = setInterval(() => {
      void getExternalNotifications();
    }, 300 * 1000); // poll every 5 minutes

    return () => clearInterval(interval);
  }, [getExternalNotifications]);

  // manually triggerable refetch
  const refetchExternalNotifications = () => {
    void getExternalNotifications();
  };

  const externalNotifications =
    compact(
      results.flatMap(
        ({ notifications, region }) =>
          notifications.data?.notifications?.nodes.map((notification) => {
            if (isUndefined(region)) return undefined;
            return {
              ...notification,
              region,
            };
          }),
      ),
    ) ?? [];

  return {
    externalNotifications,
    loading,
    error,
    refetchExternalNotifications,
  };
};
