import axios from "axios";
import { RecordingGroupFile } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type RecordingGroupDrsFilesEndAssignmentDjangoInput = {
  date_assignment_ended: RecordingGroupFile["dateAssignmentEnded"];
};

type RecordingGroupDrsFilesEndAssignmentDjangoResponse = {
  data: {
    id: RecordingGroupFile["id"];
    date_assignment_ended: RecordingGroupFile["dateAssignmentEnded"];
  };
};

export const useRecordingGroupDrsFilesEndAssignmentDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);

  const recordingGroupDrsFileEndAssignment = async (
    recordingGroupFileId: RecordingGroupFile["id"],
    input: RecordingGroupDrsFilesEndAssignmentDjangoInput,
  ) => {
    const url = `${getEnvVar(
      "URL_DRS_RECORDING_GROUP_FILES_END_ASSIGNMENT",
    )}${recordingGroupFileId}/`;
    const body = { ...input, user: currentUser.id };
    const headers = await getRequestHeaders();
    const res = await axios.patch<
      RecordingGroupDrsFilesEndAssignmentDjangoInput,
      RecordingGroupDrsFilesEndAssignmentDjangoResponse
    >(url, body, { headers });
    const { date_assignment_ended } = res.data;

    return { date_assignment_ended };
  };

  return { recordingGroupDrsFileEndAssignment };
};
