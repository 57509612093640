import { File, Tenant } from "graphql/_Types";
import {
  MultiPartUploadUrl,
  useGetMultiPartUploadUrlsDjango,
} from "hooks/useGetMultiPartUploadUrlsDjango";
import { useCallback, useRef } from "react";
import moment from "moment";
import { min } from "lodash";
import { isDefined } from "utils/isDefined";
import assert from "assert";

type UploadUrlCacheRecord = {
  uploadUrls: MultiPartUploadUrl[];
  isStale: boolean;
};

type UploadUrlCache = Record<File["id"], UploadUrlCacheRecord | undefined>;

/**
 * A hook to fetch, cache and refresh presigned upload URLs
 * @returns `getFilePartUploadUrls`: Get the presigned upload urls for a file
 */
export const useUploadUrlCache = () => {
  const { getMultiPartUploadUrls } = useGetMultiPartUploadUrlsDjango();
  const uploadUrlCacheRef = useRef<UploadUrlCache>({});

  /**
   * Marks the upload URLs for a file as stale
   * @param fileId
   */
  const invalidateCache = useCallback((fileId: File["id"]) => {
    const cacheRecord = uploadUrlCacheRef.current[fileId];
    // Cache record does exist for a deleted file that is queued
    if (isDefined(cacheRecord)) {
      cacheRecord.isStale = true;
    }
  }, []);

  /**
   * Schedules a file's upload URLs to be refreshed 10 minutes before their
   * expiration
   *
   * We want a buffer period to avoid file parts from being uploaded after a
   * URL has expired and before the URLs have finished refreshing
   * @param fileId
   */
  const scheduleCacheInvalidation = useCallback(
    (fileId: File["id"]) => {
      const cacheRecord = uploadUrlCacheRef.current[fileId];

      // Cache record does exist for a deleted file that is queued
      if (!isDefined(cacheRecord)) {
        return;
      }

      // Get the current epoch time
      const now = moment().unix();
      // Get an array of expiration times for all the URLs
      const { uploadUrls } = cacheRecord;
      const urlExpirationTimes = uploadUrls.map(({ expiresAt }) => expiresAt);
      // Find the soonest expiration time
      const soonestExpirationTime = min(urlExpirationTimes);

      // If there's no soonest expiration time, do nothing
      if (soonestExpirationTime === undefined) {
        return;
      }

      // Calculate the time until the soonest expiration time
      const timeToExpiration = soonestExpirationTime - now;
      // Calculate the time until 10 minutes before the soonest expiration time
      const refetchDelay = timeToExpiration - 10 * 60;
      // Schedule URLs to be refreshed 10 minutes before the soonest expiration time
      setTimeout(
        () => invalidateCache(fileId),
        refetchDelay * 1000, // Convert to milliseconds
      );
    },
    [invalidateCache],
  );

  /**
   * Gets the presigned upload urls for a file
   * @param fileId
   * @returns The presigned upload urls
   */
  const getFilePartUploadUrls = useCallback(
    async (fileId: File["id"], tenantId: Tenant["id"]) => {
      const cacheRecord = uploadUrlCacheRef.current[fileId];

      // Fetch URLs when the cache is uninitialized or stale (cache miss)
      if (cacheRecord === undefined || cacheRecord.isStale) {
        const newUploadUrls = await getMultiPartUploadUrls(fileId, tenantId);
        assert(isDefined(newUploadUrls), "Debounced function never invoked");
        uploadUrlCacheRef.current[fileId] = {
          uploadUrls: newUploadUrls,
          isStale: false,
        };
        scheduleCacheInvalidation(fileId);
        return newUploadUrls.map(({ url }) => url);
      } else {
        // Return the cached URLs if they are still fresh (cache hit)
        const { uploadUrls } = cacheRecord;
        return uploadUrls.map(({ url }) => url);
      }
    },
    [getMultiPartUploadUrls, scheduleCacheInvalidation],
  );

  return {
    getFilePartUploadUrls,
    invalidateUploadUrlCache: invalidateCache,
  };
};
