import {
  EuiAccordion,
  EuiBadge,
  EuiText,
  EuiTitle,
  euiPaletteColorBlind,
} from "@inscopix/ideas-eui";
import { flyoutDrsFileInfoStyles } from "./FlyoutDrsFileInfo";
import { capitalize, invert } from "lodash";
import { FileStatus } from "types/constants";
import { FlyoutFileInfoBodyProps } from "./FlyoutFileInfoBody";
import { useGetDrsFileProcessingStatus } from "hooks/useGetDrsFileProcessingStatus";
import { FileSource } from "graphql/_Types";
import moment from "moment";
import { UserAvatarRenderer } from "components/UserAvatarRenderer/UserAvatarRenderer";
import { filesize } from "utils/filesize";
import {
  DrsFileInfoAnalysisTable,
  DrsFileInfoAnalysisTableProps,
} from "./DrsFileInfoAnalysisTable";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { FILE_TYPES_BY_ID } from "types/FileTypes";

export type FlyoutFileInfoBodyBasicInfoProps = Pick<
  FlyoutFileInfoBodyProps,
  "activeFile" | "recordings"
> & {
  // Explicitly required | undefined because this is calculated in the parent and undefined is a valid value
  drsFileAnalysisTableInfo: DrsFileInfoAnalysisTableProps | undefined;
};

export const FlyoutFileInfoBodyBasicInfo = ({
  activeFile,
  recordings,
  drsFileAnalysisTableInfo,
}: FlyoutFileInfoBodyBasicInfoProps) => {
  const { processingStatus } = useGetDrsFileProcessingStatus(activeFile);
  return (
    <EuiAccordion
      id="drsFlyoutInfo"
      paddingSize="l"
      initialIsOpen={true}
      buttonContent={
        <EuiTitle size="xs">
          <h3>Info</h3>
        </EuiTitle>
      }
    >
      <div style={{ marginTop: "-16px", paddingLeft: "3px" }}>
        <EuiText size="s" css={flyoutDrsFileInfoStyles.descriptionListStyles}>
          <dl>
            <dt>Status:</dt>
            <dd>
              {processingStatus === "error" ? (
                <EuiBadge
                  color={
                    euiPaletteColorBlind({
                      rotations: 3,
                    })[17]
                  }
                >
                  Failed to read
                </EuiBadge>
              ) : (
                <>
                  {/* Converting file.status code to corresponding status string and finally capitalizing it for a nice display  */}
                  {capitalize(invert(FileStatus)[activeFile.status])}
                </>
              )}
            </dd>
            {activeFile.fileType !== null && (
              <>
                <dt>Type:</dt>
                <dd>{FILE_TYPES_BY_ID[activeFile.fileType]?.name}</dd>
              </>
            )}

            <dt>Assigned to:</dt>
            <dd>
              {recordings.length > 0
                ? recordings.map(({ id }) => (
                    <RecordingIdentifierBadge
                      key={id}
                      showShortIdOnly
                      recordingId={id}
                    />
                  ))
                : "Unassigned"}
            </dd>

            <dt>Size:</dt>
            <dd>{filesize(activeFile.size ?? "")}</dd>

            {activeFile.source === FileSource["Uploaded"] && (
              <>
                <dt>Uploaded at:</dt>
                <dd>
                  {moment(activeFile.dateCreated).format(
                    "MMMM Do YYYY, h:mm:ss a",
                  )}
                </dd>

                <dt>Uploaded by:</dt>
                <dd>
                  <UserAvatarRenderer
                    userId={activeFile.userId}
                    displayName={true}
                    size="s"
                  />
                </dd>
              </>
            )}
          </dl>
        </EuiText>
        {drsFileAnalysisTableInfo !== undefined && (
          <DrsFileInfoAnalysisTable {...drsFileAnalysisTableInfo} />
        )}
      </div>
    </EuiAccordion>
  );
};
