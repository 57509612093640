import { EuiButton } from "@inscopix/ideas-eui";
import { AnalysisTableTaskCounts } from "components/AnalysisTableTaskCounts/AnalysisTableTaskCounts";
import { useEffect, useState } from "react";
import { AnalysisTableProps } from "./AnalysisTable";
import { useToolParamsGridRowDataContext } from "components/ToolParamsGrid/ToolParamsGridRowDataProvider";

interface AnalysisTableButtonTabProps {
  isSelected: boolean;
  table: AnalysisTableProps["tables"][number];
  setSelectedTableId: AnalysisTableProps["setSelectedTableId"];
}

export const AnalysisTableButtonTab = ({
  isSelected,
  table,
  setSelectedTableId,
}: AnalysisTableButtonTabProps) => {
  const saveStatus = useToolParamsGridRowDataContext((s) => s.saveStatus);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (["saved", "error"].includes(saveStatus) && isClicked) {
      void setSelectedTableId(table.id).finally(() => setIsClicked(false));
    }
  }, [isClicked, saveStatus, setSelectedTableId, table.id]);

  return (
    <EuiButton
      color="text"
      size="s"
      minWidth="auto"
      onClick={() => setIsClicked(true)}
      style={{ border: isSelected ? "1px solid grey" : undefined }}
      isLoading={isClicked}
    >
      {table.name}
      <AnalysisTableTaskCounts analysisTable={table} />
    </EuiButton>
  );
};
