import { Flyout, FlyoutProps } from "components/Flyout/Flyout";
import { FlyoutSubsection } from "components/Flyout/FlyoutSubsection";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { DatasetVersionTable } from "./DatasetVersionTable";

interface FlyoutDatasetVersionsProps
  extends Pick<FlyoutProps, "headerControls"> {
  onClose: () => void;
}

export const FlyoutDatasetVersions = ({
  onClose,
  ...props
}: FlyoutDatasetVersionsProps) => {
  const { project } = useProjectDataContext();
  const { dataset, datasetVersions } = useDatasetDataContext();
  return (
    <Flyout title="Dataset Versions" onClose={onClose} {...props}>
      <FlyoutSubsection>
        <DatasetVersionTable
          dataset={dataset}
          datasetVersions={datasetVersions}
          project={project}
        />
      </FlyoutSubsection>
    </Flyout>
  );
};
