import { EuiText } from "@inscopix/ideas-eui";
import { NotificationAlert, NotificationAlertProps } from "./NotificationAlert";

type NotificationAlertErrorProps = Pick<
  NotificationAlertProps,
  "dateCreated" | "hasSeen" | "onNotificationRead"
>;

/**
 * Component that renders placeholder content for notifications that cannot be
 * displayed.
 */
export const NotificationAlertError = (props: NotificationAlertErrorProps) => {
  return (
    <NotificationAlert
      {...props}
      title={
        <EuiText color="danger">
          <h4>Sorry, there was an error</h4>
        </EuiText>
      }
      text="We are unable to load this notification at this time."
      action={undefined}
    />
  );
};
