import { Column, ColumnGroup } from "ag-grid-community";
import { isLinkedMetadataColumn } from "components/RecordingsGrid/RecordingsGrid.helpers";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { flattenColumnGroup } from "./ModalRemoveColumns.helpers";
import { useMemo } from "react";

/** A hook for accessing information about a column and its dependents */
export const useColumnDependents = (targetColumn: Column | ColumnGroup) => {
  const { recordingsTable, columnsById } = useDatasetDataContext();

  /**
   * Gets the columns whose cell values are dependent on the values of cells
   * in a specified column. At the time of writing, the only columns with
   * "derived" values are linked metadata columns.
   * @param columnId
   * @returns The dependent columns.
   */
  const getDependentColumns = (columnId: string) => {
    return recordingsTable.datasetRecordingsTableColumns.nodes
      .filter(isLinkedMetadataColumn)
      .filter(({ colDef }) => colDef.drsFileColumnId === columnId)
      .map((column) => ({
        id: column.id,
        name: column.colDef.headerName,
        colDef: column.colDef,
      }));
  };

  // All columns nested inside the target column
  const allColumns = useMemo(() => {
    return targetColumn instanceof Column
      ? [targetColumn]
      : flattenColumnGroup(targetColumn);
  }, [targetColumn]);

  return allColumns.map((column) => {
    const id = column.getColId();
    const name = column.getColDef().headerName ?? "Unknown";
    const colDef = columnsById[id].colDef;
    const dependents = getDependentColumns(id);
    return { id, name, colDef, dependents };
  });
};
