import { useMemo } from "react";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type UserAvatarObject = {
  color: string;
  initials: string;
  useAvatarImage: boolean;
  imageBase64?: string;
};
export const useUserPreferenceData = () => {
  const currentUser = useUserContext((s) => s.currentUser);

  const currentUserTimezoneMetadatum = useMemo(
    () =>
      currentUser.metadata.nodes.find(
        (metadatumNode) => metadatumNode?.key === "timezone",
      ),
    [currentUser.metadata.nodes],
  );

  const userPreferenceData = useMemo(() => {
    const avatar = currentUser.avatar;
    return {
      userId: currentUser.id,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      avatar,
      timezone:
        currentUserTimezoneMetadatum !== undefined
          ? {
              id: currentUserTimezoneMetadatum?.id,
              value: currentUserTimezoneMetadatum.value,
            }
          : undefined,
    };
  }, [
    currentUser.avatar,
    currentUser.id,
    currentUser.firstName,
    currentUser.lastName,
    currentUser.email,
    currentUserTimezoneMetadatum,
  ]);
  return { userPreferenceData };
};
