/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from "react";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiToolTip,
} from "@inscopix/ideas-eui";
import { stringToSlug } from "utils/stringToSlug";
import { useValidateMetadatumKey } from "./hooks/useValidateMetadatumKey";
import { stringToKey } from "../../utils/stringToKey";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { useMenuInsertColumnStyles } from "./useMenuInsertColumnStyles";

type PanelInsertCustomMetadataColumnProps = {
  onClose: () => void;
};

/**
 * Context menu panel for adding a custom metadata column to a recordings grid
 */
export const PanelInsertCustomMetadataColumn = ({
  onClose,
}: PanelInsertCustomMetadataColumnProps) => {
  const createColumnAction = useDatasetAction("createColumn");
  const { panelStyles } = useMenuInsertColumnStyles();

  const [columnName, setColumnName] = useState("");
  const [columnKey, setColumnKey] = useState("");
  const [shouldGenerateKey, setShouldGenerateKey] = useState(true);

  // auto focus the column name input when loaded
  const setNameRef: React.RefCallback<HTMLInputElement> = useCallback(
    (node) => {
      // timeout needed to get around EUI focusing the context menu title
      setTimeout(() => {
        node?.focus();
      }, 500);
    },
    [],
  );

  const { isKeyValid, isLoading: isValidatingKey } =
    useValidateMetadatumKey(columnKey);

  useEffect(() => {
    if (shouldGenerateKey) {
      setColumnKey(stringToSlug(columnName, "_"));
    }
  }, [columnName, shouldGenerateKey]);

  const noColumnName = columnName === "";
  const noCustomColumnKey = columnKey === "";
  const isSubmitDisabled =
    noColumnName || noCustomColumnKey || !isKeyValid || isValidatingKey;
  const submitButtonTooltip = (() => {
    if (noColumnName) return "Enter a column name";
    else if (noCustomColumnKey) return "Enter a metadata key";
    else if (!isKeyValid) return "Key is not valid";
    else if (isValidatingKey) return "Validating key...";
  })();

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    void createColumnAction.enqueue({
      colDef: {
        type: "metadata",
        headerName: columnName.trim(),
        metadataKey: `user.${columnKey}`.trim(),
      },
    });
    onClose();
  };

  return (
    <EuiForm component="form" role="form" css={panelStyles}>
      <EuiFormRow label="Column Name" display="rowCompressed">
        <EuiFieldText
          name="columnName"
          value={columnName}
          onChange={(e) => {
            const maxLength = 1024;
            const columnName = e.target.value.substring(0, maxLength);
            setColumnName(columnName);
          }}
          placeholder="e.g. Subject ID"
          compressed
          inputRef={setNameRef}
        />
      </EuiFormRow>
      <EuiFormRow
        label="Metadata Key"
        isInvalid={!isKeyValid}
        error={
          !isKeyValid ? "A metadatum with this key already exists" : undefined
        }
        helpText={
          <>
            <p>
              An alphanumeric key to represent the metadata in this column. This
              key will be used as a primary identifier to find and display
              metadata values in data exports as well as future command-line and
              API interfaces.
            </p>
            <EuiSpacer size="xs" />
            <p>
              You may use dot notation to create related categories or
              hierarchies of keys - e.g. <b>virus.type</b> and <b>virus.dose</b>{" "}
              will be grouped together under <b>virus</b>.
            </p>
          </>
        }
        display="rowCompressed"
      >
        <EuiFieldText
          isInvalid={!isKeyValid}
          isLoading={isValidatingKey}
          name="columnKey"
          value={columnKey}
          onChange={(e) => {
            const maxLength = 1024;
            const columnKey = e.target.value.substring(0, maxLength);
            setColumnKey(stringToKey(columnKey));
            setShouldGenerateKey(false);
          }}
          compressed
          placeholder="e.g. subject.id"
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiFlexGroup justifyContent="flexEnd">
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiToolTip content={submitButtonTooltip}>
          <EuiButton
            type="submit"
            onClick={handleSubmit}
            fill
            disabled={isSubmitDisabled}
          >
            Add Column
          </EuiButton>
        </EuiToolTip>
      </EuiFlexGroup>
    </EuiForm>
  );
};
