import { KeyArgsFunction } from "@apollo/client/cache/inmemory/policies";
import { isNonNullish } from "utils/isNonNullish";

/**
 * Key args policy that excludes args with nullish values
 * @param args
 * @returns A non-empty array of arg keys or `false` if none exist
 */
export const onlyNonNullishArgs = (args: Parameters<KeyArgsFunction>[0]) => {
  const keys = Object.entries(args ?? {})
    .filter(([_, val]) => isNonNullish(val))
    .map(([key]) => key);
  return keys.length > 0 ? keys : false;
};
