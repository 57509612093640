import { Dataset, useUpdateDatasetByIdMutation } from "graphql/_Types";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import assert from "assert";
import { isNonNullish } from "utils/isNonNullish";
import { updateCacheFragment } from "utils/cache-fragments";
import { useProjectPermission } from "hooks/useProjectPermission";

type UpdateDatasetPrefixParams = {
  id: Dataset["id"];
  newPrefix: Dataset["prefix"];
};

export const useActionUpdateDatasetPrefix = () => {
  const { datasetMode } = useDatasetDataContext();
  const [updateDataset] = useUpdateDatasetByIdMutation();
  const { hasPermission } = useProjectPermission();

  const getActionDef = useCallback(
    (params: UpdateDatasetPrefixParams) => {
      return {
        onEnqueue: () => {
          updateCacheFragment({
            __typename: "Dataset",
            id: params.id,
            update: (prevData) => {
              const newData = cloneDeep(prevData);
              newData.prefix = params.newPrefix;
              return newData;
            },
          });
        },

        onDequeue: async () => {
          const { data } = await updateDataset({
            variables: {
              id: params.id,
              patch: { prefix: params.newPrefix },
            },
          });

          const updatedDataset = data?.updateDataset?.dataset;
          assert(
            isNonNullish(updatedDataset),
            "Failed to update dataset prefix",
          );
          return updatedDataset;
        },
      };
    },
    [updateDataset],
  );

  const isDisabled = datasetMode.type !== "current" || !hasPermission("edit");

  return {
    getActionDef,
    isDisabled,
  };
};
