import { keyBy } from "lodash";

/***********
  BASE TYPE
 ***********/
export type FileFormat = (typeof FILE_FORMATS)[keyof typeof FILE_FORMATS];

/************************
  FILE FORMAT DEFINITIONS
 ************************/

const ISXD_FILE = {
  id: 1,
  key: "isxd",
  name: ".isxd",
} as const;

const MOVIE_FILE = {
  id: 2,
  key: "movie",
  name: "Movie",
} as const;

const GPIO_FILE = {
  id: 3,
  key: "gpio",
  name: ".gpio",
} as const;

const CSV_FILE = {
  id: 4,
  key: "csv",
  name: ".csv",
} as const;

const JSON_FILE = {
  id: 5,
  key: "json",
  name: ".json",
} as const;

const PARQUET_FILE = {
  id: 6,
  key: "parquet",
  name: ".parquet",
} as const;

const GENERAL_FILE = {
  id: 7,
  key: "general",
  name: "Unknown",
} as const;

const EVENTS_FILE = {
  id: 8,
  key: "events",
  name: "Events",
} as const;

const ZIP_FILE = {
  id: 9,
  key: "zip",
  name: ".zip",
} as const;

const IMU_FILE = {
  id: 10,
  key: "imu",
  name: ".imu",
} as const;

const H5_FILE = {
  id: 11,
  key: "h5",
  name: ".h5",
} as const;

const PNG_FILE = {
  id: 12,
  key: "png",
  name: ".png",
} as const;

const MP4_FILE = {
  id: 13,
  key: "mp4",
  name: ".mp4",
} as const;

const IMAGE_FILE = {
  id: 14,
  key: "image",
  name: "Image",
} as const;

const ISXB_FILE = {
  id: 15,
  key: "isxb",
  name: ".isxb",
} as const;

const ISXC_FILE = {
  id: 16,
  key: "isxc",
  name: ".isxc",
} as const;

const UNKNOWN_FILE = {
  id: 17,
  key: "unknown",
  name: "Unknown",
} as const;

const AVI_FILE = {
  id: 18,
  key: "avi",
  name: ".avi",
} as const;

const TSV_FILE = {
  id: 19,
  key: "tsv",
  name: ".tsv",
} as const;

const HTML_FILE = {
  id: 20,
  key: "html",
  name: ".html",
} as const;

const TIFF_FILE = {
  id: 21,
  key: "tiff",
  name: ".tiff",
} as const;

const NWB_FILE = {
  id: 22,
  key: "nwb",
  name: ".nwb",
} as const;

const NPY_FILE = {
  id: 23,
  key: "npy",
  name: ".npy",
} as const;

const TXT_FILE = {
  id: 24,
  key: "txt",
  name: ".txt",
} as const;

const MAT_FILE = {
  id: 25,
  key: "mat",
  name: ".mat",
} as const;

const TAR_FILE = {
  id: 26,
  key: "tar.gz",
  name: ".tar.gz",
} as const;

const TIF_FILE = {
  id: 27,
  key: "tif",
  name: ".tif",
} as const;

const BIN_FILE = {
  id: 28,
  key: "bin",
  name: ".bin",
} as const;

const YAML_FILE = {
  id: 29,
  key: "yaml",
  name: ".yaml",
} as const;

/*******************
  AGGREGATED EXPORTS
 *******************/

const FILE_FORMATS = {
  ISXD_FILE,
  MOVIE_FILE,
  GPIO_FILE,
  CSV_FILE,
  JSON_FILE,
  PARQUET_FILE,
  GENERAL_FILE,
  EVENTS_FILE,
  ZIP_FILE,
  IMU_FILE,
  H5_FILE,
  PNG_FILE,
  MP4_FILE,
  IMAGE_FILE,
  ISXB_FILE,
  ISXC_FILE,
  UNKNOWN_FILE,
  AVI_FILE,
  TSV_FILE,
  HTML_FILE,
  TIFF_FILE,
  NWB_FILE,
  NPY_FILE,
  TXT_FILE,
  MAT_FILE,
  TAR_FILE,
  TIF_FILE,
  BIN_FILE,
  YAML_FILE,
} as const;

export const FILE_FORMATS_BY_ID = keyBy(
  FILE_FORMATS,
  (fileFormat) => fileFormat.id,
) as {
  [id in FileFormat["id"]]: FileFormat;
} & {
  [unknown: number]: FileFormat | undefined;
};

export const FILE_FORMATS_BY_KEY = keyBy(
  FILE_FORMATS,
  (fileFormat) => fileFormat.key,
) as {
  [key in FileFormat["key"]]: FileFormat;
} & {
  [unknown: string]: FileFormat | undefined;
} & {
  [unknown: number | symbol]: undefined;
};
