import assert from "assert";
import { File as DrsFile } from "graphql/_Types";
import useGetFileDownloadUrl from "hooks/useGetFileDownloadUrl";
import { useCallback } from "react";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { downloadFile } from "utils/downloadFile";

export const useDownloadDrsFileNonSeries = () => {
  const { getFileDownloadUrl } = useGetFileDownloadUrl();

  const downloadDrsFileNonSeries = useCallback(
    /**
     * Downloads a file browser file
     */
    async (
      drsFile: Pick<
        DrsFile,
        | "id"
        | "name"
        | "isSeries"
        | "status"
        | "source"
        | "seriesParentId"
        | "processingStatus"
      >,
    ) => {
      assert(
        !drsFile.isSeries,
        "Unexpected series file passed to downloadDrsFileNonSeries",
      );
      const permissions = getDrsFileModifyPermissionByDrsFileAndAction(
        drsFile,
        "DOWNLOAD",
      );

      if (!permissions.isPermitted) {
        throw new Error(permissions.permissionErrors.join(", "));
      }

      const url = await getFileDownloadUrl(drsFile);
      downloadFile(url, drsFile.name);
    },
    [getFileDownloadUrl],
  );

  return { downloadDrsFileNonSeries };
};
