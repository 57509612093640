import { EuiButton } from "@inscopix/ideas-eui";
import { FilePreviewComponentProps } from "./FilePreview.types";

/** Component that renders a file preview formatted as an HTML file */
export const FilePreviewHtml = ({ preview }: FilePreviewComponentProps) => {
  return (
    <EuiButton
      fullWidth
      href={preview.url}
      iconType="popout"
      iconSide="right"
      target="_blank"
    >
      Open Report
    </EuiButton>
  );
};
