import { captureException } from "@sentry/react";
import axios, { AxiosResponse } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { isDefined } from "utils/isDefined";
import { useIsTabActive } from "hooks/useIsTabActive";

interface VersionResponse extends AxiosResponse {
  data: {
    version: string;
    date: string;
  };
}

const isVersionResponse = (
  response: AxiosResponse,
): response is VersionResponse => {
  return (
    isDefined(response.data) &&
    "version" in response.data &&
    "date" in response.data
  );
};

const getCurrentCachedVersion = async () => {
  const response = await axios.get(`/info.json?cacheBuster=${Date.now()}`);
  if (isVersionResponse(response)) {
    return response.data.version;
  } else {
    throw new Error("Failed to fetch version response");
  }
};

const POLLING_INTERVAL = 60 * 5 * 1000; // 5 minute interval
const CURRENT_VERSION = process.env.REACT_APP_CODEBUILD_SOURCE_VERSION;

export const useIsCurrentVersion = ():
  | { isCurrentVersion: true }
  | { isCurrentVersion: false; newVersion: string } => {
  const { isTabActive } = useIsTabActive();
  const [newVersion, setNewVersion] = useState<string>();
  const intervalRef = useRef<NodeJS.Timer>();

  const checkAndSetVersion = useCallback(async () => {
    if (CURRENT_VERSION === undefined || newVersion !== undefined) {
      return;
    }
    try {
      const version = await getCurrentCachedVersion();
      if (version !== CURRENT_VERSION) {
        setNewVersion(version);
      }
    } catch (err) {
      captureException(err);
    }
  }, [newVersion]);

  useEffect(() => {
    if (isTabActive) {
      // check when the tab is refocused
      void checkAndSetVersion();

      // poll every 5 minutes while the tab is active
      intervalRef.current = setInterval(() => {
        void checkAndSetVersion();
      }, POLLING_INTERVAL);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [checkAndSetVersion, isTabActive]);

  return newVersion !== undefined
    ? { isCurrentVersion: false, newVersion: newVersion }
    : { isCurrentVersion: true };
};
