import { cloneDeep, has } from "lodash";
import { VegaLite, VisualizationSpec } from "react-vega";

export interface FilePreviewEventsProps {
  content: {
    Time: number;
    Event_Type: string;
  }[];
}

/**
 * Renders an events Vega visualization for a given events data object
 */
export const FilePreviewEvents = ({ content }: FilePreviewEventsProps) => {
  // compatibility with CNMF-E output events
  let yField = "Event_Type";

  if (content.length > 0) {
    if (has(content, "Cell_Name")) {
      yField = "Cell_Name";
    }
  }

  const spec = {
    data: { name: "events" },
    width: 500,
    mark: "tick",
    encoding: {
      x: { field: "Time", type: "quantitative" },
      y: { field: yField, type: "ordinal" },
    },
  } as VisualizationSpec;

  return (
    <div style={{ maxHeight: "420px", overflowY: "scroll" }}>
      <VegaLite
        spec={spec}
        data={{
          events: cloneDeep(content),
        }}
        width={100}
      />
    </div>
  );
};
