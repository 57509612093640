import { EuiBadge } from "@inscopix/ideas-eui";
import { ColorsByTaskStatus } from "types/ColorsByTaskStatus";
import { TaskStatus } from "types/constants";

interface TaskStatusBadgeProps {
  taskStatus: TaskStatus;
}

export const TaskStatusBadge = ({ taskStatus }: TaskStatusBadgeProps) => {
  const color = ColorsByTaskStatus[taskStatus];
  const taskStatusName = TaskStatus[taskStatus];
  return <EuiBadge color={color}>{taskStatusName}</EuiBadge>;
};
