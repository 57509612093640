/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import {
  isAnalysisResultColumn,
  isDrsFileColumn,
  LinkedMetadataColumn,
} from "components/RecordingsGrid/RecordingsGrid.helpers";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiDescriptionList,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSelect,
  EuiSelectOption,
  EuiSpacer,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { metadataMap } from "types/MetadataMap";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { captureException } from "@sentry/react";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import assert from "assert";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { ButtonEmptyPermissioned } from "components/ButtonEmptyPermissioned/ButtonEmptyPermissioned";

export type ModalViewLinkedMetadataColumnProps = {
  column: LinkedMetadataColumn;
  onClose: () => void;
};

export const ModalViewLinkedMetadataColumn = ({
  column,
  onClose,
}: ModalViewLinkedMetadataColumnProps) => {
  const { colDef } = column;
  const { analysisResultsByTableId } = useProjectDataContext();
  const { columnsById } = useDatasetDataContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [columnName, setColumnName] = useState(colDef.headerName);
  const [dataColumnId, setDataColumnId] = useState(colDef.drsFileColumnId);
  const [metadatumKey, setMetadatumKey] = useState(colDef.metadataKey);
  const updateColumnDefAction = useDatasetAction("updateColumnDef");

  /* Report a warning to Sentry if we find ourselves in an illegal state */

  useEffect(() => {
    if (isEditMode && updateColumnDefAction.isDisabled) {
      captureException("Illegal attempt to edit a linked metadata column");
    }
  }, [isEditMode, updateColumnDefAction.isDisabled]);

  /**
   * Selection options for the data column field
   */
  const dataColumnOptions: EuiSelectOption[] = (() => {
    const drsFileColumns = Object.values(columnsById).filter(isDrsFileColumn);

    const analysisResultColumns = Object.values(columnsById).filter(
      isAnalysisResultColumn,
    );

    return [...drsFileColumns, ...analysisResultColumns].map((column) => {
      if (isDrsFileColumn(column)) {
        // We don't support linked metadata for unmapped columns
        const isDisabled = metadataMap[column.colDef.fileType] === undefined;
        return {
          text: column.colDef.headerName,
          value: column.id,
          disabled: isDisabled,
        };
      } else {
        const { analysisTableId, resultKey } = column.colDef;
        const analysisResult =
          analysisResultsByTableId[analysisTableId][resultKey];

        const metadata = metadataMap[analysisResult.file_type];
        // We don't support linked metadata for unmapped columns
        const isDisabled = metadata === undefined;

        return {
          text: analysisResult.result_name,
          value: column.id,
          disabled: isDisabled,
        };
      }
    });
  })();

  /**
   * Selection options for the metadata field
   */
  const metadataOptions = (() => {
    const dataColumn = columnsById[dataColumnId];
    assert(
      isDrsFileColumn(dataColumn) || isAnalysisResultColumn(dataColumn),
      "Expected link to data column",
    );

    if (isDrsFileColumn(dataColumn)) {
      const fileType = FILE_TYPES_BY_ID[dataColumn.colDef.fileType];
      const metadata = metadataMap[fileType.key];

      // this is checked upstream in dataColumnOptions and should never be reached
      if (metadata === undefined) {
        captureException(
          "Failed to parse metadata options for drs file column",
        );
        return [];
      }
      return Object.entries(metadata).map(([value, text]) => ({ text, value }));
    } else {
      const { analysisTableId, resultKey } = dataColumn.colDef;
      const analysisResult =
        analysisResultsByTableId[analysisTableId][resultKey];
      const metadata =
        metadataMap[analysisResult.file_type as keyof typeof metadataMap];
      // this is checked upstream in dataColumnOptions and should never be reached
      if (metadata === undefined) {
        captureException(
          "Failed to parse metadata options for drs file column",
        );
        return [];
      }
      return Object.entries(metadata).map(([value, text]) => ({ text, value }));
    }
  })();

  /* Any time the data column selection changes, we should default the 
     metadata selection to the first option */

  useEffect(() => {
    const isMetadataSelectionInOptions = metadataOptions.some((option) => {
      return option.value === metadatumKey;
    });

    if (!isMetadataSelectionInOptions && metadataOptions.length > 0) {
      setMetadatumKey(metadataOptions[0].value);
    }
  }, [metadataOptions, metadatumKey]);

  /**
   * Tooltip message to show on the save button or `undefined` if none should
   * be shown
   */
  const saveButtonTooltipMessage = (() => {
    if (columnName === "") {
      return "Enter a column name";
    }

    if (
      columnName === colDef.headerName &&
      dataColumnId === colDef.drsFileColumnId &&
      metadatumKey === colDef.metadataKey
    ) {
      return "Edit at least one value";
    }
  })();

  /**
   * Reverts the fields back to their original state
   */
  const handleCancel = () => {
    setColumnName(colDef.headerName);
    setDataColumnId(colDef.drsFileColumnId);
    setMetadatumKey(colDef.metadataKey);
    setIsEditMode(false);
  };

  /**
   * Persists changes when the save button is pressed
   */
  const handleSave = () => {
    void updateColumnDefAction.enqueue({
      columnId: column.id,
      colDef: {
        type: "linkedMetadata",
        headerName: columnName,
        drsFileColumnId: dataColumnId,
        metadataKey: metadatumKey,
      },
    });
    setIsEditMode(false);
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {isEditMode ? "Edit Column" : columnName}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {isEditMode ? (
          <>
            <EuiFormRow
              label="Column name"
              helpText="Custom name for this column"
            >
              <EuiFieldText
                value={columnName}
                autoFocus
                onChange={(e) => setColumnName(e.target.value)}
              />
            </EuiFormRow>
            <EuiSpacer />

            <EuiFormRow
              label="Data column"
              helpText="Data column that provides the value for this column"
            >
              <EuiSelect
                id={htmlIdGenerator()()}
                options={dataColumnOptions}
                value={dataColumnId}
                onChange={(e) => setDataColumnId(e.target.value)}
              />
            </EuiFormRow>
            <EuiSpacer />
            <EuiFormRow
              label="Metadata"
              helpText="Metadata value displayed in this column's cells"
            >
              <EuiSelect
                id={htmlIdGenerator()()}
                options={metadataOptions}
                value={metadatumKey}
                onChange={(e) => setMetadatumKey(e.target.value)}
              />
            </EuiFormRow>
          </>
        ) : (
          <EuiDescriptionList
            listItems={[
              {
                title: "Data column",
                description:
                  dataColumnOptions.find(
                    (option) => option.value === dataColumnId,
                  )?.text ?? "",
              },
              {
                title: "Metadata",
                description:
                  metadataOptions.find(
                    (option) => option.value === metadatumKey,
                  )?.text ?? "",
              },
            ]}
          />
        )}
      </EuiModalBody>
      <EuiModalFooter>
        {isEditMode ? (
          <>
            <EuiButtonEmpty onClick={handleCancel}>
              Discard Changes
            </EuiButtonEmpty>
            <Tooltip content={saveButtonTooltipMessage}>
              <EuiButton
                iconType="save"
                onClick={() => void handleSave()}
                fill
                disabled={
                  saveButtonTooltipMessage !== undefined ||
                  updateColumnDefAction.isDisabled
                }
              >
                Save
              </EuiButton>
            </Tooltip>
          </>
        ) : (
          <>
            <ButtonEmptyPermissioned
              iconType="pencil"
              onClick={() => setIsEditMode(true)}
              disabled={updateColumnDefAction.isDisabled}
              requiredPermission="edit"
            >
              Edit
            </ButtonEmptyPermissioned>
            <EuiButton fill onClick={onClose}>
              Close
            </EuiButton>
          </>
        )}
      </EuiModalFooter>
    </EuiModal>
  );
};
