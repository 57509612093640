/**
 * Generates a normalized slug key.
 * @param string String to convert.
 * @param delimiter The string  to delimit words with.
 * @returns The key of the slug string.
 */
export function stringToSlug(string: string, delimiter = "-") {
  return string
    .normalize("NFD")
    .replace(/\s+/g, delimiter)
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, delimiter)
    .toLowerCase();
}
