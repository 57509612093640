/** @jsxImportSource @emotion/react */
import { EuiEmptyPrompt, EuiLoadingLogo } from "@inscopix/ideas-eui";
import { useEffect, useState } from "react";
import { CallOutError } from "components/CallOutError/CallOutError";
import { FileFormat } from "types/FileFormats";
import { useFabricImage } from "../ModalToolCroppingFrameParam/useFabricImage";
import { RoiEditor, RoiEditorProps } from "../../RoiEditor/RoiEditor";
import { RoiEditorProvider } from "../../RoiEditor/RoiEditorProvider";
import { ToolRoiFrameParam } from "../ToolParamsGrid.types";
import { ProjectFile } from "stores/project-files/ProjectFilesManager";
import { DIMENSIONAL_METADATA_KEYS } from "types/MetadataMap";
import { isNullish } from "utils/isNullish";
import { css } from "@emotion/react";

export interface ModalToolRoiFrameParamWithFileProps
  extends Pick<RoiEditorProps, "onAccept" | "onCancel" | "initialShapes"> {
  file: Pick<ProjectFile, "id" | "metadata">;
  previewType: {
    fileFormat: FileFormat["id"];
    key: ToolRoiFrameParam["type"]["source_file"]["data"]["key"];
  };
  readOnly?: boolean;
  toolSpec: ToolRoiFrameParam;
}

export const ModalToolRoiFrameParamWithFile = ({
  file,
  onAccept,
  initialShapes,
  previewType,
  onCancel,
  readOnly,
  toolSpec,
}: ModalToolRoiFrameParamWithFileProps) => {
  const [scaleFactor, setScaleFactor] = useState<{ x: number; y: number }>();
  const [loadingScaling, setLoadingScaling] = useState(true);

  const { loading, image, error } = useFabricImage(
    file.id,
    previewType.fileFormat,
    previewType.key,
  );

  /**
   * Parse source file metadata and determine preview scaling
   */
  useEffect(() => {
    if (scaleFactor === undefined && image !== undefined && loadingScaling) {
      const sourceFileDimensions = {
        x: file.metadata.find(({ key }) => key === DIMENSIONAL_METADATA_KEYS.x)
          ?.value,
        y: file.metadata.find(({ key }) => key === DIMENSIONAL_METADATA_KEYS.y)
          ?.value,
      };

      if (
        isNullish(sourceFileDimensions.x) ||
        isNullish(sourceFileDimensions.y)
      ) {
        // setIsScaleError(true);
        setLoadingScaling(false);
        return;
      }

      const scaleFactor = {
        x: Number(sourceFileDimensions.x) / image.width,
        y: Number(sourceFileDimensions.y) / image.height,
      };

      if (isNaN(scaleFactor.x) || isNaN(scaleFactor.y)) {
        // setIsScaleError(true);
        setLoadingScaling(false);
        return;
      }

      setScaleFactor(scaleFactor);
      image.width = image.width * scaleFactor.x;
      image.height = image.height * scaleFactor.y;
      image.scaleX = scaleFactor.x;
      image.scaleY = scaleFactor.y;
      setLoadingScaling(false);
    }
  }, [file.metadata, image, loadingScaling, scaleFactor]);

  if (error !== undefined) {
    return (
      <CallOutError>
        <p>
          Failed to load image/video from input file. This file has either not
          yet been processed (wait for it finish and retry) or is not supported
          by this analysis tool.
        </p>
      </CallOutError>
    );
  }

  if (loading || loadingScaling) {
    return (
      <EuiEmptyPrompt
        icon={<EuiLoadingLogo logo="vector" size="xl" />}
        title={<h3>Loading...</h3>}
        titleSize="s"
        css={css`
          height: 100%;
          .euiEmptyPrompt__main {
            height: 100%;
          }
        `}
      />
    );
  }

  if (scaleFactor === undefined) {
    return (
      <CallOutError>
        <p>Failed to read metadata from input file.</p>
      </CallOutError>
    );
  }

  return (
    <RoiEditorProvider>
      <RoiEditor
        toolSpec={toolSpec}
        onCancel={onCancel}
        initialShapes={initialShapes}
        image={image}
        onAccept={onAccept}
        readOnly={readOnly}
      />
    </RoiEditorProvider>
  );
};
