import { EuiCheckbox } from "@inscopix/ideas-eui";
import { useToolParamsGridRowDataContext } from "./ToolParamsGridRowDataProvider";

export const HeaderComponentSelectRow = () => {
  const rowData = useToolParamsGridRowDataContext((s) => s.rowData);
  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );

  const selectedRows = rowData.filter(({ selected }) => selected);

  const allSelected = selectedRows.length === rowData.length;
  const indeterminate = selectedRows.length > 0 && !allSelected;

  return (
    <EuiCheckbox
      id="selectAll"
      checked={allSelected}
      indeterminate={indeterminate}
      onChange={() => {
        const selected = !allSelected && !indeterminate;
        updateRowDatum(
          rowData.map((data) => data.id),
          { selected },
          { skipSave: true, forceUpdateLockedRow: true },
        );
      }}
    />
  );
};
