import { EuiCard, EuiFocusTrap, EuiOverlayMask } from "@inscopix/ideas-eui";
import { useOnlineStatus } from "hooks/useOnlineStatus";

export const NetworkError = () => {
  const { isOnline } = useOnlineStatus();

  if (isOnline) {
    return null;
  }

  return (
    <EuiOverlayMask>
      <EuiFocusTrap>
        <EuiCard
          title="Failed to reach network"
          description="There seems to be an issue with your network connection."
        />
      </EuiFocusTrap>
    </EuiOverlayMask>
  );
};
