import {
  Tool,
  ToolVersion,
  useGetToolVersionsByToolIdAndVersionsLazyQuery,
} from "graphql/_Types";
import { useCallback } from "react";

/** Hook that fetches data for multiple versions of the same tool. */
export const useGetToolVersionsByToolIdAndVersions = () => {
  const [fetchToolVersions] = useGetToolVersionsByToolIdAndVersionsLazyQuery();

  const getToolVersions = useCallback(
    async (toolId: Tool["id"], versions: ToolVersion["version"][]) => {
      const { data } = await fetchToolVersions({
        variables: {
          toolId,
          versions,
        },
      });

      const toolVersions = data?.toolVersions?.nodes.map((toolVersion) => {
        const toolSourceIdsByToolSourceKey: Record<string, number> = {};
        toolVersion.toolSources.nodes.forEach(({ key, id }) => {
          toolSourceIdsByToolSourceKey[key] = id;
        });

        const toolParameterIdsByToolParameterKey: Record<string, number> = {};
        toolVersion.toolParameters.nodes.forEach(({ key, id }) => {
          toolParameterIdsByToolParameterKey[key] = id;
        });

        const toolResultIdsByToolResultKey: Record<string, number> = {};
        toolVersion.toolResults.nodes.forEach(({ key, id }) => {
          toolResultIdsByToolResultKey[key] = id;
        });

        return {
          ...toolVersion,
          toolSourceIdsByToolSourceKey,
          toolParameterIdsByToolParameterKey,
          toolResultIdsByToolResultKey,
        };
      });

      if (toolVersions === undefined) {
        throw new Error("Failed to fetch tool version");
      }

      return toolVersions;
    },
    [fetchToolVersions],
  );

  return { getToolVersions };
};
