import { css } from "@emotion/react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSwitch,
  EuiSuperSelect,
  EuiText,
  EuiCallOut,
} from "@inscopix/ideas-eui";
import Plot, { PlotParams } from "react-plotly.js";
import { Fragment, useState } from "react";
import { CellStatusEditorImageData } from "../CellStatusEditor.types";
import { useCellStatusContext } from "../CellStatusEditorProvider";
import { isUndefined } from "lodash";
import {
  getCellStatusColor,
  selectionColor,
} from "../CellStatusEditor.helpers";
import { isDefined } from "utils/isDefined";
import { captureException } from "@sentry/react";

export const ImageWithFootprintsPlot = () => {
  const cells = useCellStatusContext((s) => s.cells);
  const cellStatuses = useCellStatusContext((s) => s.cellStatuses);
  const cellContours = useCellStatusContext((s) => s.cellContours);
  const imageData = useCellStatusContext((s) => s.imageData);
  const selectedCells = useCellStatusContext((s) => s.selectedCells);
  const setSelectedCells = useCellStatusContext((s) => s.setSelectedCells);
  const spacingInfo = useCellStatusContext((s) => s.spacingInfo);
  const [selectedImageType, setSelectedImageType] = useState<number>(0);
  const [showFootprints, setShowFootprints] = useState<boolean>(true);

  // meant to display cell contours on a provided image
  // but we can display on a blank background as a fallback if we have both x and y spacing info
  if (
    isUndefined(cellContours) ||
    isUndefined(cellContours.contoursX) ||
    isUndefined(cellContours.contoursY) ||
    (isUndefined(imageData) &&
      (isUndefined(spacingInfo.numPixelsX) ||
        isUndefined(spacingInfo.numPixelsY)))
  ) {
    return (
      <EuiCallOut
        title="Image and cell contour data not available"
        color="primary"
        iconType="alert"
        style={{ margin: "20px" }}
      />
    );
  }
  const contoursX = cellContours.contoursX;
  const contoursY = cellContours.contoursY;

  const footprints = showFootprints
    ? cells.map((cell) => ({
        name: cell.name,
        x: contoursX[cell.index],
        y: contoursY[cell.index],
        type: "scatter" as const,
        fill: "toself" as const,
        autocolorscale: false,
        // make selected cells opaque
        fillcolor: selectedCells.has(cell.index)
          ? getCellStatusColor(cellStatuses[cell.index], 1)
          : getCellStatusColor(cellStatuses[cell.index], 0.3),
        // draw a stroke on selected cell
        line: {
          color: selectedCells.has(cell.index)
            ? selectionColor
            : getCellStatusColor(cellStatuses[cell.index], 0.5),
        },
        showLegend: false,
        mode: "lines" as const,
        legendwidth: 0,
      }))
    : [];

  const data: PlotParams["data"] = [];
  // if we have image data, use it
  // if we have more than one, let the user pick
  if (isDefined(imageData)) {
    data.push({
      z: imageData[selectedImageType].image,
      type: "heatmap",
      colorscale: [
        [0, "#000000"],
        [1, "#ffffff"],
      ],
      showscale: false,
      showlegend: false,
      hoverinfo: "skip",
    });
  } else if (
    // no image data, try to fall back to spacing info to generate a 0s array
    isDefined(spacingInfo.numPixelsX) &&
    isDefined(spacingInfo.numPixelsY)
  ) {
    data.push({
      z: new Array(spacingInfo.numPixelsY).fill(
        new Array(spacingInfo.numPixelsX).fill(0),
      ),
      type: "heatmap",
      colorscale: [
        [0, "#000000"],
        [1, "#ffffff"],
      ],
      showscale: false,
      showlegend: false,
      hoverinfo: "skip",
    });
  } else {
    // we should always have spacing info if we have a cell set - this is an unlikely state
    captureException(
      new Error("Invalid state reached in ImageWithFootprintsPlot"),
    );
    return (
      <EuiCallOut
        title="No spacing info available for footprints"
        color="primary"
        iconType="alert"
        style={{ margin: "20px" }}
      />
    );
  }

  data.push(...footprints);

  // if a cell is clicked, set it as selected
  const handleSelectCell = (event: { points: { curveNumber: number }[] }) => {
    const cellId = event.points[0].curveNumber - 1;
    setSelectedCells([cellId]);
  };

  const calculateHeight = () => {
    if (isDefined(imageData)) {
      // scale height based on image aspect ratio
      // all images are guaraanteed to have the same aspect ratio so we can just use the first one
      return 500 * (imageData[0].image.length / imageData[0].image[0].length);
    } else if (
      isDefined(spacingInfo.numPixelsY) &&
      isDefined(spacingInfo.numPixelsX)
    ) {
      // if no image, use the spacing info so we can still draw contours on a blank background
      return 500 * (spacingInfo.numPixelsY / spacingInfo.numPixelsX);
    } else {
      // default to 500 if we can't calculate
      // should never be reached as we should render a callout instead if we're missing both
      return 500;
    }
  };

  return (
    <EuiFlexGroup direction="column" gutterSize="none">
      {isDefined(imageData) && (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup
            gutterSize="s"
            direction="row"
            css={css`
              display: flex;
              padding: 0px 20px 10px 20px;
              align-items: center;
              gap: 10px;
              justify-content: space-between;
            `}
          >
            <EuiFlexItem
              grow={false}
              css={css`
                width: 300px;
              `}
            >
              <SelectImageType
                imageData={imageData}
                selectedImageType={selectedImageType}
                setSelectedImageType={setSelectedImageType}
              />
            </EuiFlexItem>
            <EuiFlexItem
              grow={false}
              css={css`
                width: 160px;
              `}
            >
              <EuiSwitch
                label="Show footprints"
                checked={showFootprints}
                onChange={() => setShowFootprints(!showFootprints)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      )}
      <EuiFlexItem>
        <Plot
          data={data}
          layout={{
            width: 500,
            height: calculateHeight(),
            yaxis: {
              title: "Y position (pixels)",
              ticks: "outside",
            },
            xaxis: {
              title: "X position (pixels)",
              ticks: "outside",
            },
            margin: { t: 0, r: 0 },
            font: {
              family: `InterVariable, "Inter var", Inter, -apple-system, "system-ui", "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif`,
            },
            showlegend: false,
          }}
          onClick={handleSelectCell}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
interface SelectImageTypeProps {
  imageData: CellStatusEditorImageData;
  selectedImageType: number;
  setSelectedImageType: (imageType: number) => void;
}

/**
 * Renders a dropdown select component for choosing an image type.
 */
export const SelectImageType = ({
  imageData,
  selectedImageType,
  setSelectedImageType,
}: SelectImageTypeProps) => {
  const imageDescriptions: { [key: string]: string } = {
    "Correlation image":
      "Correlation image computed form input movie. In this image, a particular pixel value is equal to the average correlation over time of that pixel with the 8 neighbor pixels that surround it. Bright regions in this image tend to correspond to cells. ",
    "Maximum projection":
      "Maximum projection of movie. This image is computed by finding the maximum of the movie matrix across the time axis.",
    "Mean projection":
      "Mean projection of movie. This image is computed by finding the mean of the movie matrix across the time axis.",
    "Standard deviation projection":
      "Standard deviation projection of movie. This image is computed by finding the standard deviation of the movie matrix across the time axis.",
    "Summed footprints":
      "Summed footprints of extracted cells. This image is computed by summing every footprint in the cell set file.",
  };
  const imageTypes = imageData.map((image, index) => ({
    value: String(index),
    inputDisplay: image.label,
    dropdownDisplay: (
      <Fragment>
        <strong>{image.label}</strong>
        <EuiText size="s" color="subdued">
          <p>{imageDescriptions[image.label]}</p>
        </EuiText>
      </Fragment>
    ),
  }));
  return (
    <EuiSuperSelect
      options={imageTypes}
      valueOfSelected={String(selectedImageType)}
      placeholder="Select an option"
      onChange={(value) => setSelectedImageType(parseInt(value) ?? 0)}
      itemLayoutAlign="top"
      hasDividers
    />
  );
};
