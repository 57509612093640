import axios from "axios";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type DeleteRecordingGroupInput = {
  date_assignment_ended: string;
};

type DeleteRecordingGroupDjangoResponse = {
  data: {
    id: string;
    date_assignment_ended: string;
  };
};

export const useDeleteRecordingGroupDjango = () => {
  const currentUser = useUserContext((s) => s.currentUser);

  const deleteRecordingGroupDjango = async (
    recordingGroupId: string,
    input: DeleteRecordingGroupInput,
  ): Promise<DeleteRecordingGroupDjangoResponse["data"]> => {
    const url = `${getEnvVar(
      "URL_DRS_FILE_RECORDING_GROUP_END_ASSIGNMENT",
    )}${recordingGroupId}/`;
    const headers = await getRequestHeaders();
    const response = await axios.patch<
      DeleteRecordingGroupInput,
      DeleteRecordingGroupDjangoResponse
    >(
      url,
      { ...input, user: currentUser.id },
      {
        headers,
      },
    );

    return response.data;
  };
  return { deleteRecordingGroupDjango };
};
