import { Auth } from "aws-amplify";
import { Tenant } from "graphql/_Types";
// eslint-disable-next-line no-restricted-imports
import { TenantStore } from "providers/TenantProvider/TenantProvider.helpers";

/** Represents the options that can be passed to {@link getRequestHeaders} */
type GetRequestHeadersOptions = {
  tenantId?: Tenant["id"];
};

/** Represents the request headers required by IDEAS APIs */
type IdeasApiRequestHeaders =
  | { authorization: string }
  | { authorization: string; "x-tenant-id": Tenant["id"] };

/**
 * Generates the request headers required by IDEAS APIs. The "x-tenant-id"
 * header is explicitly omitted (as opposed to being `undefined`) when the user
 * has not selected a tenant. This is necessary because some APIs (e.g. the
 * PostGraphile API) will return errors when headers have `undefined` values.
 * @returns A promise that resolves with the request headers. This promise will
 * resolve immediately if the user has a fresh access token. If the access token
 * is missing or stale, a network request will be made to refresh the token.
 * @throws An error if AWS Amplify could not retrieve the user's session.
 */
export const getRequestHeaders = async (
  options?: GetRequestHeadersOptions,
): Promise<IdeasApiRequestHeaders> => {
  const session = await Auth.currentSession();
  const jwtToken = session.getAccessToken().getJwtToken();
  const currentTenant = TenantStore.getState()?.currentTenant;
  const tenantId = options?.tenantId ?? currentTenant?.id;

  return {
    authorization: `Bearer ${jwtToken}`,
    ...(tenantId !== undefined ? { "x-tenant-id": tenantId } : {}),
  };
};
