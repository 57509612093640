import {
  EuiButton,
  EuiButtonProps,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiPopoverTitle,
  EuiSkeletonText,
} from "@inscopix/ideas-eui";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useButtonDatasetExportsLazyQuery } from "graphql/_Types";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { useEffect, useMemo, useState } from "react";
import { validateExportTasks } from "./ButtonDatasetExports.helpers";
import { ButtonDatasetExportsTable } from "./ButtonDatasetExportsTable";

export type ButtonDatasetExportsProps = Omit<EuiButtonProps, "onClick">;

/**
 * Component rendering a button that opens a popover showing information on
 * export tasks
 */
export const ButtonDatasetExports = (props: ButtonDatasetExportsProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen(false);
  const { openModal } = useDatasetLayoutContext();
  const { dataset } = useDatasetDataContext();
  const [error, setError] = useState<Error>();
  const pageSize = 5;
  const [pageIndex, setPageIndex] = useState(0);
  const { buttonExportsRef } = useDatasetLayoutContext();

  const [fetchData, { data }] = useButtonDatasetExportsLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      datasetId: dataset.id,
      first: pageSize,
      offset: pageSize * pageIndex,
    },
    pollInterval: 60 * 1000, // 1 minute
  });

  // Refetch data any time the popover is opened
  useEffect(() => {
    if (isPopoverOpen) {
      void fetchData();
    }
  }, [fetchData, isPopoverOpen]);

  // Validate and format query data
  const formattedData = useMemo(() => {
    if (data !== undefined) {
      try {
        setError(undefined);
        return validateExportTasks(data);
      } catch (error) {
        setError(error as Error);
      }
    }
  }, [data]);

  const ButtonExportToDandi = () => (
    <ButtonPermissioned
      iconType="plus"
      size="s"
      onClick={() => {
        openModal({ type: "exportToDandi" });
        closePopover();
      }}
      requiredPermission="execute"
    >
      Export to DANDI
    </ButtonPermissioned>
  );

  const content = (() => {
    if (error !== undefined) {
      return <CallOutError />;
    }

    if (formattedData === undefined) {
      return <EuiSkeletonText lines={3} />;
    }

    if (formattedData.length === 0) {
      return (
        <EuiEmptyPrompt
          title={<h2>No files exported</h2>}
          titleSize="xs"
          iconType="push"
          body={<ButtonExportToDandi />}
        />
      );
    }

    return (
      <div style={{ maxWidth: 1000 }}>
        <EuiPopoverTitle paddingSize="s">
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem>Exports</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <ButtonExportToDandi />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPopoverTitle>
        <ButtonDatasetExportsTable
          items={formattedData}
          onPageChange={setPageIndex}
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalItemCount={data?.tasks?.totalCount ?? 0}
        />
      </div>
    );
  })();

  return (
    <EuiPopover
      anchorPosition="upCenter"
      button={
        <EuiButton
          {...props}
          buttonRef={buttonExportsRef}
          onClick={togglePopover}
        >
          Exports
        </EuiButton>
      }
      closePopover={closePopover}
      isOpen={isPopoverOpen}
    >
      {content}
    </EuiPopover>
  );
};
