import { Dataset } from "components/Dataset/Dataset";
import { Page404 } from "pages/404/Page404";
import { useParams } from "react-router-dom";
import { useProjectDataContext } from "../ProjectDataProvider";
import { Dataset as TDataset } from "graphql/_Types";

type RouteParams = {
  cutoffTime: string;
  datasetId: TDataset["id"];
};

export const PageProjectDatasetHistory = () => {
  const { cutoffTime, datasetId } = useParams<RouteParams>();
  const { datasets } = useProjectDataContext();
  const dataset = datasets.find(({ id }) => id === datasetId);

  if (
    dataset === undefined ||
    // Check if the cutoff time contains non-digit characters
    /\D/.test(cutoffTime) ||
    // Check if date before dataset was created
    new Date(Number(cutoffTime) * 1000).valueOf() <
      new Date(dataset.dateCreated).valueOf()
  ) {
    return <Page404 />;
  }

  return <Dataset />;
};
