import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { renameOrganization } from "./ModalEditOrganization.helpers";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { TenantProviderDocument } from "graphql/_Types";

interface ModalEditOrganizationProps {
  onClose: () => void;
}

export const ModalEditOrganization = ({
  onClose,
}: ModalEditOrganizationProps) => {
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const [name, setName] = useState(currentTenant.name);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (name !== currentTenant.name) {
      try {
        setIsLoading(true);
        await renameOrganization({
          tenantId: currentTenant.id,
          newName: name,
        });
        await client.refetchQueries({
          include: [TenantProviderDocument],
        });
        addUtilityToastSuccess("Organization updated");
      } catch (error) {
        addUtilityToastFailure("Failed to update organization");
      } finally {
        setIsLoading(false);
        onClose();
      }
    }
  };

  return (
    <EuiModal onClose={onClose} style={{ width: 500 }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Edit organization</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm
          component="form"
          role="form"
          id={htmlIdGenerator()()}
          onSubmit={(e) => {
            e.preventDefault();
            void handleSubmit();
          }}
        >
          <EuiFormRow label="Name">
            <EuiFieldText
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              readOnly={isLoading}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          fill
          color="primary"
          isLoading={isLoading}
          type="submit"
          onClick={() => void handleSubmit()}
        >
          Update
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
