import { chain, uniq } from "lodash";
import { isDefined } from "utils/isDefined";
import { MetadataTable } from "./MetadataTable";
import { MetadatumReference, ToolParamValue } from "../ToolParamsGrid.types";
import { isToolParamValue } from "../ToolParamsGrid.helpers";
import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { FileBadge } from "components/FileBadge/FileBadge";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { getFileMetadata } from "./MetadataTableFile.helpers";
import { memo, useEffect, useState } from "react";

type Metadatum = Awaited<ReturnType<typeof getFileMetadata>>[number];

interface MetadataTableFileProps {
  fileIds: string[];
  onSelect: (metadatumKey: string, value: ToolParamValue) => void;
  currentReference: MetadatumReference | undefined;
  currentValue: ToolParamValue | undefined;
}

/** Component that renders a table of file metadata */
export const MetadataTableFile = memo(function MetadataTableFile({
  fileIds: sourceFileIds,
  onSelect,
  currentReference,
  currentValue,
}: MetadataTableFileProps) {
  const allFiles = useProjectFilesStore((s) => s.files);
  const [metadata, setMetadata] = useState<Metadatum[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  // Fetch data any time file IDs change
  useEffect(() => {
    setIsLoading(true);
    getFileMetadata(sourceFileIds)
      .then(setMetadata)
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [sourceFileIds]);

  // Create a map of metadatum values by key
  const metadataByKey = chain(metadata)
    .groupBy(({ key }) => key)
    .value();

  // Create an array of metadatum objects from the response data. If multiple
  // files have values for the same metadatum, their values are stored
  // together. We remove any metadata that does not have a name. This occurs
  // when a metadatum is not part of the `metadataMap`.
  const items = chain(metadataByKey)
    .values()
    .map((metadata) => {
      const key = metadata[0].key;
      const name = metadata[0].name;
      const values = metadata
        .map(({ value }) => value)
        .filter(isToolParamValue) as ToolParamValue[];
      return {
        key,
        name,
        values: uniq(values),
      };
    })
    .value();

  return (
    <MetadataTable
      isLoading={isLoading}
      isError={error !== undefined}
      items={items}
      onSelect={onSelect}
      currentReference={currentReference}
      currentValue={currentValue}
      renderValueTooltip={(metadatumKey, value) => {
        const files = metadataByKey[metadatumKey]
          .filter((metadatum) => metadatum.value === value)
          .map(({ fileId }) => allFiles.find((file) => file.id === fileId))
          .filter(isDefined);

        // Do not show a tooltip unless there are multiple files selected
        // in the source column
        if (sourceFileIds.length < 2 || files.length === 0) {
          return null;
        }

        return (
          <EuiFlexGroup direction="column" gutterSize="xs">
            <EuiFlexItem>
              <strong>File(s)</strong>
            </EuiFlexItem>
            {files.map((file) => (
              <EuiFlexGroup key={file.id} alignItems="center" gutterSize="xs">
                {file.recordings.map((recording) => (
                  <EuiFlexItem key={recording.id}>
                    <RecordingIdentifierBadge recordingId={recording.id} />
                  </EuiFlexItem>
                ))}
                <EuiFlexItem>
                  <FileBadge drsFile={file} />
                </EuiFlexItem>
              </EuiFlexGroup>
            ))}
          </EuiFlexGroup>
        );
      }}
    />
  );
});
