import axios from "axios";
import { File as DrsFile } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";

export type DrsFileUpdateInput = {
  name?: DrsFile["name"];
  file_type?: DrsFile["fileType"];
  file_format?: DrsFile["fileFormat"];
};

export type DrsFileUpdateDjangoResponse = {
  data: {
    id: DrsFile["id"];
    name: DrsFile["name"];
    file_type: DrsFile["fileType"];
    file_format?: DrsFile["fileType"];
    processing_status?: DrsFile["processingStatus"];
  };
};

export const useDrsFileUpdateDjango = () => {
  const drsFileUpdate = async (
    drsFileId: DrsFile["id"],
    input: DrsFileUpdateInput,
  ) => {
    const url = `${getEnvVar("URL_DRS_FILE_UPDATE")}${drsFileId}/`;
    const body = { ...input };
    const headers = await getRequestHeaders();
    const res = await axios.patch<
      DrsFileUpdateInput,
      DrsFileUpdateDjangoResponse
    >(url, body, { headers });
    return res.data;
  };

  return { drsFileUpdate };
};
