import {
  EuiConfirmModal,
  EuiConfirmModalProps,
  EuiModalBody,
  EuiRadioGroup,
} from "@inscopix/ideas-eui";
import { File as DrsFile } from "graphql/_Types";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useState } from "react";

const ID_NO_DATASET = "PROJECT";
interface ModalSelectDatasetProps
  extends Omit<EuiConfirmModalProps, "children"> {
  onSelectDataset: (datasetId: DrsFile["datasetId"]) => void;
}

export const ModalSelectDataset = ({
  onSelectDataset,
  ...props
}: ModalSelectDatasetProps) => {
  const { datasets } = useProjectDataContext();
  const [selectedDatasetId, setSelectedDatasetId] = useState(ID_NO_DATASET);

  return (
    <EuiConfirmModal
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
      title="Assign Files to Dataset"
      {...props}
      onConfirm={() =>
        onSelectDataset(
          selectedDatasetId === ID_NO_DATASET ? null : selectedDatasetId,
        )
      }
    >
      <EuiModalBody>
        <EuiRadioGroup
          options={[
            { id: ID_NO_DATASET, label: "No Dataset (upload to project only)" },
            ...datasets.map((dataset) => ({
              id: dataset.id,
              label: dataset.name,
            })),
          ]}
          idSelected={selectedDatasetId}
          onChange={(id) => {
            setSelectedDatasetId(id);
          }}
        />
      </EuiModalBody>
    </EuiConfirmModal>
  );
};
