import axios from "axios";
import { Notification } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";
import { TRegion } from "providers/RegionsProvider";
import { isDefined } from "utils/isDefined";

export type UpdateNotificationInput = {
  id: Notification["id"];
  has_seen?: boolean;
  region?: TRegion;
};

type UpdateNotificationDjangoResponse = {
  data: {
    id: Notification["id"];
    has_seen?: boolean;
  };
};

type UseUpdateNotificationDjangoResult = {
  __typename: Notification["__typename"];
  id: Notification["id"];
  hasSeen?: Notification["hasSeen"];
};

export const useUpdateNotificationDjango = () => {
  const updateNotification = useCallback(
    async (
      input: UpdateNotificationInput,
    ): Promise<UseUpdateNotificationDjangoResult> => {
      const url = (() => {
        const baseUrl = getEnvVar("URL_NOTIFICATION_NOTIFICATION");
        if (isDefined(input.region)) {
          const djangoUrl = getEnvVar("URL_DJANGO");
          const regionUrl = baseUrl.replace(djangoUrl, input.region.urlDjango);
          return `${regionUrl}${input.id}/`;
        } else {
          return `${baseUrl}${input.id}/`;
        }
      })();
      const headers = await getRequestHeaders();
      const response = await axios.patch<
        UpdateNotificationInput,
        UpdateNotificationDjangoResponse
      >(url, input, { headers });

      return {
        __typename: "Notification",
        id: response.data.id,
        hasSeen: response.data.has_seen,
      };
    },
    [],
  );
  return { updateNotification };
};
