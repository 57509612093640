import axios, { AxiosResponse } from "axios";
import { File as DrsFile } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";
import { FileFormat } from "types/FileFormats";

type DrsFileDownloadPreviewInput = undefined;

type DrsFileDownloadPreviewDjangoResponse = AxiosResponse<{
  previews:
    | {
        file_format: FileFormat["id"];
        name: string;
        help: string;
        url: string;
        key: string | null;
      }[]
    | null;
}>;

export type DrsFilePreview =
  | {
      content: string; // S3 location
      file_format: FileFormat["id"];
      help: string;
      name: string;
      order: number;
      key: string | null;
    }[]
  | null;

/** Hook that downloads file preview information from S3 */
export const useDrsFileDownloadPreviewDjango = () => {
  const downloadFilePreview = useCallback(
    async (
      fileId: DrsFile["id"],
      /* FIXME: We assume that if any of the previews are HTML files, we want
           to display them inline, within the browser. What happens if previews
           contain both HTML files and files with other formats? */
      options: { "content-disposition": "inline" | "attachment" } = {
        "content-disposition": "attachment",
      },
    ) => {
      const baseUrl = getEnvVar("URL_DRS_FILE_DOWNLOAD_PREVIEW");
      const url = `${baseUrl}${fileId}/`;
      const baseHeaders = await getRequestHeaders();
      const headers = {
        ...baseHeaders,
        "content-disposition": options["content-disposition"],
      };

      return axios.get<
        DrsFileDownloadPreviewInput,
        DrsFileDownloadPreviewDjangoResponse
      >(url, { headers });
    },
    [],
  );

  return { downloadFilePreview };
};
