import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FlyoutDrsFileInfo,
  FlyoutDrsFileInfoProps,
} from "components/FlyoutDrsFileInfo/FlyoutDrsFileInfo";
import {
  ModalDeleteDrsFile,
  ModalDeleteDrsFileProps,
} from "../../../components/ModalDeleteDrsFile/ModalDeleteDrsFile";
import {
  ModalArchiveDrsFile,
  ModalArchiveDrsFileProps,
} from "../../../components/ModalArchiveDrsFile/ModalArchiveDrsFile";
import {
  ModalUnarchiveDrsFiles,
  ModalUnarchiveDrsFilesProps,
} from "../../../components/ModalUnarchiveDrsFile/ModalUnarchiveDrsFile";
import {
  ModalToolCroppingFrameParam,
  ModalToolCroppingFrameParamProps,
} from "components/ToolParamsGrid/ModalToolCroppingFrameParam/ModalToolCroppingFrameParam";
import {
  ModalDeleteRow,
  ModalDeleteRowProps,
} from "components/ToolParamsGrid/ModalDeleteRow";
import { useSelectedDrsFileId } from "stores/useSelectedDrsFileId";

import {
  ModalToolRoiFrameParam,
  ModalToolRoiFrameParamProps,
} from "components/ToolParamsGrid/ModalToolRoiFrameParam/ModalToolRoiFrameParam";
import {
  ModalDeleteRowBulk,
  ModalDeleteRowBulkProps,
} from "components/ToolParamsGrid/ModalDeleteRowBulk";
import {
  ModalToolCellStatusParam,
  ModalToolCellStatusParamProps,
} from "components/ToolParamsGrid/ModalToolCellStatusParam/ModalToolCellStatusParam";

type AnalysisTableFlyout = {
  type: "fileInfo";
  props: Omit<FlyoutDrsFileInfoProps, "onClose"> & {
    drsFile: FlyoutDrsFileInfoProps["drsFile"];
  };
};

type AnalysisTableModal =
  | {
      type: "deleteDrsFile";
      props: Omit<ModalDeleteDrsFileProps, "onClose">;
    }
  | {
      type: "archiveDrsFile";
      props: Omit<ModalArchiveDrsFileProps, "onClose">;
    }
  | {
      type: "unarchiveDrsFile";
      props: Omit<ModalUnarchiveDrsFilesProps, "onClose">;
    }
  | {
      type: "toolCroppingFrameParam";
      props: Omit<ModalToolCroppingFrameParamProps, "onClose">;
    }
  | {
      type: "toolRoiFrameParam";
      props: Omit<ModalToolRoiFrameParamProps, "onClose">;
    }
  | {
      type: "toolCellStatusParam";
      props: Omit<ModalToolCellStatusParamProps, "onClose">;
    }
  | {
      type: "deleteRow";
      props: Omit<ModalDeleteRowProps, "onClose">;
    }
  | {
      type: "deleteRowBulk";
      props: Omit<ModalDeleteRowBulkProps, "onClose">;
    };

type AnalysisTableLayoutContextValue = {
  rightFlyout: { type: AnalysisTableFlyout["type"]; node: ReactNode } | null;
  openFlyout: (flyout: AnalysisTableFlyout) => void;
  modal: ReactNode;
  openModal: (modal: AnalysisTableModal) => void;
};

const AnalysisTableLayoutContext = createContext<
  AnalysisTableLayoutContextValue | undefined
>(undefined);

interface AnalysisTableLayoutProviderProps {
  children: ReactNode;
}

export const AnalysisTableLayoutProvider = ({
  children,
}: AnalysisTableLayoutProviderProps) => {
  const { selectDrsFile, deselectAll, deselectDrsFile, selectedDrsFileId } =
    useSelectedDrsFileId(
      ({ selectDrsFile, deselectAll, deselectDrsFile, selectedDrsFileId }) => ({
        selectDrsFile,
        deselectAll,
        deselectDrsFile,
        selectedDrsFileId,
      }),
    );

  /**
   * Clear selection state on unmount
   */
  useEffect(() => {
    return () => deselectAll();
  }, [deselectAll]);

  const [rightFlyout, setRightFlyout] = useState<AnalysisTableFlyout | null>(
    null,
  );
  const [modal, setModal] = useState<ReactNode>(null);

  /**
   * Close info flyout if file is deselected by external action (e.g. deleting a file)
   */
  useEffect(() => {
    if (
      rightFlyout?.type === "fileInfo" &&
      selectedDrsFileId !== rightFlyout.props.drsFile.id
    ) {
      setRightFlyout(null);
    }
  }, [rightFlyout?.props.drsFile.id, rightFlyout?.type, selectedDrsFileId]);

  /**
   * Opens a flyout
   * @param flyout
   */
  const openFlyout = useCallback(
    (flyout: AnalysisTableFlyout) => {
      const setFlyout = setRightFlyout;

      switch (flyout.type) {
        case "fileInfo":
          selectDrsFile(flyout.props.drsFile.id);
          setFlyout(flyout);
          break;
      }
    },
    [selectDrsFile],
  );

  /**
   * Closes a flyout
   * @param flyout
   */
  const closeFlyout = () => {
    const setFlyout = setRightFlyout;
    setFlyout(null);
  };

  /**
   * Opens a modal
   * @param modal
   */
  const openModal = (modal: AnalysisTableModal) => {
    const { type } = modal;
    const handleClose = () => setModal(null);

    switch (type) {
      case "deleteDrsFile":
        setModal(<ModalDeleteDrsFile onClose={handleClose} {...modal.props} />);
        break;
      case "archiveDrsFile":
        setModal(
          <ModalArchiveDrsFile onClose={handleClose} {...modal.props} />,
        );
        break;
      case "unarchiveDrsFile":
        setModal(
          <ModalUnarchiveDrsFiles onClose={handleClose} {...modal.props} />,
        );
        break;
      case "toolCroppingFrameParam":
        setModal(
          <ModalToolCroppingFrameParam
            onClose={handleClose}
            {...modal.props}
          />,
        );
        break;
      case "toolRoiFrameParam":
        setModal(
          <ModalToolRoiFrameParam onClose={handleClose} {...modal.props} />,
        );
        break;
      case "toolCellStatusParam":
        setModal(
          <ModalToolCellStatusParam onClose={handleClose} {...modal.props} />,
        );
        break;
      case "deleteRow":
        setModal(<ModalDeleteRow onClose={handleClose} {...modal.props} />);
        break;
      case "deleteRowBulk":
        setModal(<ModalDeleteRowBulk onClose={handleClose} {...modal.props} />);
        break;
    }
  };

  const rightFlyoutState: AnalysisTableLayoutContextValue["rightFlyout"] =
    useMemo(() => {
      if (rightFlyout === null) {
        return null;
      }

      const node = (() => {
        switch (rightFlyout.type) {
          case "fileInfo":
            return (
              <FlyoutDrsFileInfo
                {...rightFlyout.props}
                onClose={() => {
                  deselectDrsFile(rightFlyout.props.drsFile.id);
                  closeFlyout();
                }}
              />
            );
        }
      })();

      return { ...rightFlyout, node };
    }, [deselectDrsFile, rightFlyout]);

  return (
    <AnalysisTableLayoutContext.Provider
      value={{
        openFlyout,
        rightFlyout: rightFlyoutState,
        openModal,
        modal,
      }}
    >
      {children}
    </AnalysisTableLayoutContext.Provider>
  );
};

export const useAnalysisTableLayoutContext = () => {
  const value = useContext(AnalysisTableLayoutContext);

  if (value === undefined) {
    throw new ContextOutOfBoundsError("AnalysisTableLayoutContext");
  }

  return value;
};
