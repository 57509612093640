import { EuiCallOut } from "@inscopix/ideas-eui";

/** Component rendered when a file preview could not be retrieved */
export const FilePreviewError = () => {
  return (
    <EuiCallOut
      title="Sorry, there was an error"
      color="danger"
      iconType="warning"
    >
      <p>This preview could not be loaded</p>
    </EuiCallOut>
  );
};
