import axios from "axios";
import { File as DrsFile } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";

type SeriesRevertCreateResponse = {
  restored_files: DrsFile["id"][];
};

export const useDrsFileSeriesRevertCreateDjango = () => {
  const drsFileSeriesRevertCreate = async (
    drsFileId: DrsFile["id"],
  ): Promise<SeriesRevertCreateResponse> => {
    const baseUrl = getEnvVar("URL_DRS_FILE_SERIES_REVERT_CREATE");
    const url = `${baseUrl}${drsFileId}/`;
    const headers = await getRequestHeaders();
    const { data } = await axios.delete<SeriesRevertCreateResponse>(url, {
      headers,
    });

    return data;
  };
  return { drsFileSeriesRevertCreate };
};
