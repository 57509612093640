import { FC } from "react";
import { EuiAvatar, EuiAvatarProps } from "@inscopix/ideas-eui";
import { Avatar } from "graphql/_Types";

export type UserAvatarBaseProps = {
  name: string;
  avatar: Pick<Avatar, "imageBase64" | "initials" | "useImage" | "color">;
  size?: EuiAvatarProps["size"];
};

export const UserAvatarBase: FC<UserAvatarBaseProps> = ({
  name,
  avatar,
  size,
}) => {
  const { imageBase64, initials, useImage, color } = avatar;

  if (useImage && imageBase64 !== null) {
    return <EuiAvatar name={name} size={size} imageUrl={imageBase64} />;
  }

  return (
    <EuiAvatar
      name={name}
      size={size}
      initials={initials}
      initialsLength={2}
      color={color}
    />
  );
};
