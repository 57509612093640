import { ReactNode, createContext, useContext, useState } from "react";
import { createStore, useStore } from "zustand";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { getRecordingIdentifier } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge.helpers";

type RecordingIdentifierStoreState = {
  identifierMap: {
    [recordingId: string]:
      | {
          shortId: string;
          cellValues: {
            columnName: string;
            cellValue: string;
          }[];
        }
      | undefined
      | Error;
  };

  getIdentifier: (recordingId: string) => Promise<
    | {
        shortId: string;
        cellValues: {
          columnName: string;
          cellValue: string;
        }[];
      }
    | Error
  >;

  refetchIdentifier: (recordingId: string) => void;

  refetchAllIdentifiers: () => void;
};

type RecordingIdentifierStore = ReturnType<
  typeof createRecordingIdentifierStore
>;

export const createRecordingIdentifierStore = () =>
  createStore<RecordingIdentifierStoreState>((set, get) => ({
    identifierMap: {},

    /**
     * Fetches the identifier for a specified recording.
     *
     * If the identifier has already been fetched, the cached identifier is
     * turned.
     * @param recordingId
     * @returns The recording identifier.
     */
    getIdentifier: async (recordingId: string) => {
      const cachedIdentifier = get().identifierMap[recordingId];
      if (cachedIdentifier === undefined) {
        try {
          const { shortId, cellValues } = await getRecordingIdentifier(
            recordingId,
          );
          set((state) => {
            return {
              ...state,
              identifierMap: {
                ...state.identifierMap,
                [recordingId]: { shortId, cellValues },
              },
            };
          });
          return { shortId, cellValues };
        } catch (error) {
          set((state) => {
            return {
              ...state,
              identifierMap: {
                ...state.identifierMap,
                [recordingId]: error as Error,
              },
            };
          });
          return error as Error;
        }
      } else {
        return cachedIdentifier;
      }
    },

    /**
     * Removes the cached identifier for a specified recording.
     *
     * If a {@link RecordingIdentifierBadge} is mounted for the recording, the
     * component will automatically refetch the identifier data.
     * @param recordingId
     */
    refetchIdentifier: (recordingId: string) => {
      set((state) => {
        const newIdentifierMap = { ...state.identifierMap };
        delete newIdentifierMap[recordingId];
        return {
          ...state,
          identifierMap: newIdentifierMap,
        };
      });
    },

    /**
     * Removes the cached identifiers for all recordings
     *
     * If a {@link RecordingIdentifierBadge} is mounted for one of the evicted
     * identifiers, the component will automatically refetch the identifier data.
     */
    refetchAllIdentifiers: () => {
      set((state) => ({
        ...state,
        identifierMap: {},
      }));
    },
  }));

const RecordingIdentifierContext = createContext<
  RecordingIdentifierStore | undefined
>(undefined);

interface RecordingIdentifierProviderProps {
  children: ReactNode;
}

/** Context provider for caching recording identifier data */
export const RecordingIdentifierProvider = ({
  children,
}: RecordingIdentifierProviderProps) => {
  const [store] = useState(createRecordingIdentifierStore());
  return (
    <RecordingIdentifierContext.Provider value={store}>
      {children}
    </RecordingIdentifierContext.Provider>
  );
};

/** Hook for consuming the {@link RecordingIdentifierContext} */
export const useRecordingIdentifierContext = <T,>(
  selector: (state: RecordingIdentifierStoreState) => T,
  equalityFn?: (a: T, b: T) => boolean,
) => {
  const store = useContext(RecordingIdentifierContext);
  assert(
    isDefined(store),
    new ContextOutOfBoundsError("RecordingIdentifierContext"),
  );
  return useStore(store, selector, equalityFn);
};
