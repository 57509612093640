import assert from "assert";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { useToolParamsGridRowDataContext } from "components/ToolParamsGrid/ToolParamsGridRowDataProvider";
import {
  AnalysisTableGroup,
  Project,
  ToolVersion,
  useCreateAnalysisTableMutation,
} from "graphql/_Types";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useEffect, useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { isNonNullish } from "utils/isNonNullish";

interface AnalysisTableButtonCreateTabProps {
  analysisTableGroupId: AnalysisTableGroup["id"];
  projectId: Project["id"];
  toolVersionId: ToolVersion["id"];
  setSelectedTableId: (tableId: string) => Promise<void>;
}

/**
 * Component that renders a button for adding a new analysis table that uses
 * the latest tool versions to an analysis table group.
 */
export const AnalysisTableButtonCreateTab = ({
  analysisTableGroupId,
  projectId,
  toolVersionId,
  setSelectedTableId,
}: AnalysisTableButtonCreateTabProps) => {
  const currentUserId = useUserContext((s) => s.currentUser.id);
  const currentTenantId = useTenantContext((s) => s.currentTenant.id);
  const [isClicked, setIsClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createAnalysisTable] = useCreateAnalysisTableMutation();
  const saveStatus = useToolParamsGridRowDataContext((s) => s.saveStatus);

  // Create a new analysis table in the group when the button is clicked and
  // the changes made to the current tab are saved.
  useEffect(() => {
    const handleClick = async () => {
      try {
        setIsLoading(true);

        // Add a new analysis table to the group
        const { data: tableCreatedData } = await createAnalysisTable({
          variables: {
            input: {
              analysisTable: {
                userId: currentUserId,
                tenantId: currentTenantId,
                toolVersionId: toolVersionId,
                projectId: projectId,
                groupId: analysisTableGroupId,
              },
            },
          },
        });
        const tableCreated =
          tableCreatedData?.createAnalysisTable?.analysisTable;
        assert(isNonNullish(tableCreated));
        await setSelectedTableId(tableCreated.id);
      } catch (error) {
        addUtilityToastFailure("Failed to create new tab");
      } finally {
        setIsLoading(false);
      }
    };

    const isTableSaving = !["saved", "error"].includes(saveStatus);
    if (isClicked && !isTableSaving) {
      void handleClick();
      setIsClicked(false);
    }
  }, [
    analysisTableGroupId,
    createAnalysisTable,
    currentTenantId,
    currentUserId,
    isClicked,
    projectId,
    saveStatus,
    setSelectedTableId,
    toolVersionId,
  ]);

  return (
    <ButtonPermissioned
      color="primary"
      size="s"
      minWidth="auto"
      onClick={() => setIsClicked(true)}
      isLoading={isLoading || isClicked}
      requiredPermission="edit"
    >
      New version available
    </ButtonPermissioned>
  );
};
