import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";
import { TaskStatus } from "types/constants";
import {
  getDeleteAnalysisTableRowModeFromRowTaskStatus,
  ModalDeleteRowProps,
} from "./ModalDeleteRow";
import { useToolParamsGridRowDataContext } from "./ToolParamsGridRowDataProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { useState } from "react";

export interface ButtonDeleteAnalysisTableRowProps
  extends Pick<ModalDeleteRowProps, "row"> {
  task: { status: TaskStatus | undefined };
}

export const ButtonDeleteAnalysisTableRow = ({
  row,
  task,
}: ButtonDeleteAnalysisTableRowProps) => {
  const cancelTask = useToolParamsGridRowDataContext((s) => s.cancelTask);
  const removeRow = useToolParamsGridRowDataContext((s) => s.removeRow);
  const { openModal } = useAnalysisTableLayoutContext();
  const mode = getDeleteAnalysisTableRowModeFromRowTaskStatus(task.status);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteRow = () => {
    if (task.status === undefined) {
      removeRow(row.id);
    } else {
      openModal({
        type: "deleteRow",
        props: {
          row,
          task: { ...task, status: task.status },
        },
      });
    }
  };

  const handleCancelTask = async () => {
    try {
      setIsLoading(true);
      await cancelTask(row.id);
      addUtilityToastSuccess("Task cancelled");
    } catch (error) {
      addUtilityToastFailure("Failed to cancel task");
    } finally {
      setIsLoading(false);
    }
  };

  if (mode === "DISABLED") {
    return (
      <ButtonIconPermissioned
        aria-label="Cancel task"
        color="warning"
        iconType="cross"
        onClick={() => void handleCancelTask()}
        requiredPermission="edit"
        defaultTooltip="Cancel task"
        isLoading={isLoading}
      />
    );
  }

  return (
    <ButtonIconPermissioned
      aria-label="Delete row"
      color="danger"
      iconType="minusInCircle"
      onClick={handleDeleteRow}
      requiredPermission="edit"
      defaultTooltip="Delete row"
    />
  );
};
