import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiTableActionsColumnType,
  EuiTableFieldDataColumnType,
  EuiText,
} from "@inscopix/ideas-eui";
import {
  Dataset,
  DatasetVersionFieldsFragment,
  Project,
} from "../../graphql/_Types";
import { useRoutes } from "hooks/useRoutes";
import { ReactNode, useMemo, useState } from "react";
import { orderBy } from "lodash";
import { Tooltip } from "../Tooltip/Tooltip";
import { isNonNull } from "utils/isNonNull";
import { formatDate } from "utils/formatDate";
import { isNonNullish } from "utils/isNonNullish";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

interface DatasetVersionTableProps {
  project: Pick<Project, "id" | "key">;
  dataset: Pick<Dataset, "id">;
  datasetVersions: DatasetVersionFieldsFragment[];
}

const defaultSort = {
  field: "dateCreated",
  direction: "desc",
} as const;

export const DatasetVersionTable = ({
  dataset,
  datasetVersions,
  project,
}: DatasetVersionTableProps) => {
  const { routeMap } = useRoutes();
  const currentTenant = useTenantContext((s) => s.currentTenant);

  /**
   * Sorting
   */
  const [sort, setSort] = useState<{
    field: "dateCreated" | "name";
    direction: "asc" | "desc";
  }>(defaultSort);

  const sortedVersions = useMemo(
    () =>
      orderBy(
        [...datasetVersions],
        (version) => version[sort.field],
        sort.direction,
      ),
    [datasetVersions, sort.direction, sort.field],
  );

  /**
   * Expanding rows
   * Based on https://elastic.github.io/eui/#/tabular-content/tables#expanding-rows
   */
  const [versionIdToExpandedRowMap, setVersionIdToExpandedRowMap] = useState<
    Record<string, ReactNode>
  >({});

  const toggleDetails = (version: DatasetVersionFieldsFragment) => {
    const versionIdToExpandedRowMapValues = { ...versionIdToExpandedRowMap };

    if (isNonNullish(versionIdToExpandedRowMapValues[version.id])) {
      delete versionIdToExpandedRowMapValues[version.id];
    } else {
      versionIdToExpandedRowMapValues[version.id] = (
        <EuiText size={"s"}>{version.description}</EuiText>
      );
    }
    setVersionIdToExpandedRowMap(versionIdToExpandedRowMapValues);
  };

  /**
   * Columns
   */
  const columnDateCreated: EuiTableFieldDataColumnType<DatasetVersionFieldsFragment> =
    {
      name: "Date Created",
      field: "dateCreated",
      render: (_, version) => {
        return formatDate(version.dateCreated);
      },
      sortable: true,
    };

  const columnName: EuiTableFieldDataColumnType<DatasetVersionFieldsFragment> =
    {
      name: "Name",
      field: "name",
      sortable: true,
    };

  const columnActions: EuiTableActionsColumnType<DatasetVersionFieldsFragment> =
    {
      width: "40px",
      actions: [
        {
          name: "View",
          description: "View dataset version",
          icon: "inspect",
          color: "primary",
          type: "icon",
          onClick: (version) =>
            routeMap["DATASET_VERSION"]
              .dynamicPath({
                tenantKey: currentTenant.key,
                datasetId: dataset.id,
                projectKey: project.key,
                versionId: version.id,
              })
              .navigateTo(),
        },
      ],
    };

  const columnExpand: EuiBasicTableColumn<DatasetVersionFieldsFragment> = {
    align: "left",
    width: "40px",
    isExpander: true,
    render: (version: DatasetVersionFieldsFragment) => {
      const itemIdToExpandedRowMapValues = { ...versionIdToExpandedRowMap };
      const hasDescription = isNonNull(version.description);

      return hasDescription ? (
        <Tooltip content={"View description"} delayShow={500}>
          <EuiButtonIcon
            onClick={() => toggleDetails(version)}
            aria-label={
              isNonNullish(itemIdToExpandedRowMapValues[version.id])
                ? "Collapse"
                : "Expand"
            }
            iconType={
              isNonNullish(itemIdToExpandedRowMapValues[version.id])
                ? "arrowDown"
                : "arrowRight"
            }
          />
        </Tooltip>
      ) : null;
    },
  };

  return (
    <EuiBasicTable<DatasetVersionFieldsFragment>
      onChange={(criteria) => {
        const { sort: incomingSort } = criteria;
        if (
          incomingSort?.field === undefined ||
          incomingSort.direction === undefined
        ) {
          setSort(defaultSort);
        } else if (
          incomingSort.field === "dateCreated" ||
          incomingSort.field === "name"
        ) {
          setSort({
            field: incomingSort.field,
            direction: incomingSort.direction,
          });
        }
      }}
      columns={[columnExpand, columnDateCreated, columnName, columnActions]}
      sorting={{ sort }}
      itemId="id"
      items={sortedVersions}
      itemIdToExpandedRowMap={versionIdToExpandedRowMap}
      isExpandable={true}
      noItemsMessage="No dataset versions found"
    />
  );
};
