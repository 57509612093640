import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiText,
  EuiTitle,
} from "@inscopix/ideas-eui";

interface ModalSyncingDatasetProps {
  onClose: () => void;
}
export const ModalSyncingDataset = ({ onClose }: ModalSyncingDatasetProps) => (
  <EuiModal onClose={onClose}>
    <EuiModalHeader>
      <EuiTitle>
        <h1>Syncing Dataset</h1>
      </EuiTitle>
    </EuiModalHeader>
    <EuiModalBody>
      {" "}
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiLoadingSpinner />
        </EuiFlexItem>{" "}
        <EuiFlexItem>
          <EuiText>Syncing dataset...</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiModalBody>
  </EuiModal>
);
