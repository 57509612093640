/** @jsxImportSource @emotion/react */

import { BottomBar } from "components/BottomBar/BottomBar";
import { BottomBarButton } from "components/BottomBar/BottomBarButton";
import { useBottomBarStyles } from "components/BottomBar/useBottomBarStyles";
import { ButtonProjectUpload } from "components/ButtonProjectUpload/ButtonProjectUpload";
import { DRS_FILE_ACTION_ICONS } from "types/Icons";

export interface ProjectBottomBarProps {
  isFileBrowserActive: boolean;
  onClickFiles: () => void;
}
export const ProjectBottomBar = ({
  isFileBrowserActive,
  onClickFiles,
}: ProjectBottomBarProps) => {
  const styles = useBottomBarStyles();
  return (
    <BottomBar>
      <div css={styles.controlGroupContainer}>
        <div css={styles.controlGroup}>
          <ButtonProjectUpload
            size="s"
            key="upload"
            css={styles.button.default}
            iconType={DRS_FILE_ACTION_ICONS["upload"]}
          />
        </div>
        <div css={styles.controlGroup}>
          <BottomBarButton
            key="upload"
            iconType="folderOpen"
            isActive={isFileBrowserActive}
            onClick={onClickFiles}
          >
            Files
          </BottomBarButton>
        </div>
      </div>
    </BottomBar>
  );
};
