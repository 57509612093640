import { useCallback } from "react";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import axios from "axios";
import { useRegionsContext } from "providers/RegionsProvider";

type FindUserDjangoResponse = {
  home_region: string;
};

/**
 * Custom hook to find a user by email in multiple regions.
 * It makes requests to different URLs until it finds a user or exhausts all URLs.
 * @returns An object containing the `findUser` function.
 */
const useFindUser = () => {
  const { externalRegions } = useRegionsContext();
  const URL_FIND_USER = getEnvVar("URL_TENANT_FIND_USER");
  const URL_DJANGO = getEnvVar("URL_DJANGO");
  const URL_REGIONS = (() => {
    const localUrl = `${URL_DJANGO}${URL_FIND_USER}`;
    const externalUrls = externalRegions.map((region) => {
      return `${region.urlDjango}${URL_FIND_USER}`;
    });
    return [localUrl, ...externalUrls];
  })();

  /**
   * Find a user by email in multiple regions. Note on why we need to search for a user in multiple regions: https://github.com/inscopix/ideas-backend/pull/1190#issuecomment-2012268488
   * @param email - The email of the user to find.
   * @returns The user data if found.
   * @throws Error if the user is not found in any region.
   */
  const findUser = useCallback(
    async (email: string) => {
      const headers = await getRequestHeaders();

      for (const url of URL_REGIONS) {
        try {
          const response = await axios.get<FindUserDjangoResponse>(
            url + email + "/",
            {
              headers,
            },
          );

          return response.data; // If the request is successful, return the data and stop looping
        } catch (err) {
          // Don't throw the error, just continue to the next URL
        }
      }

      throw new Error("User not found in any region"); // If the loop finishes without finding a user, throw an error
    },
    [URL_REGIONS],
  );

  return { findUser };
};

export default useFindUser;
