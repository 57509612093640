import { chain, uniq } from "lodash";
import { MetadataTable } from "./MetadataTable";
import { MetadatumReference, ToolParamValue } from "../ToolParamsGrid.types";
import { isToolParamValue } from "../ToolParamsGrid.helpers";
import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { useEffect, useState } from "react";
import { getRecordingMetadata } from "./MetadataTableRecording.helpers";

type Metadatum = Awaited<ReturnType<typeof getRecordingMetadata>>[number];

interface MetadataTableRecordingProps {
  recordingIds: string[];
  onSelect: (metadatumKey: string, value: ToolParamValue) => void;
  currentReference: MetadatumReference | undefined;
  currentValue: ToolParamValue | undefined;
}

/** Component that renders a table of recording metadata */
export const MetadataTableRecording = ({
  recordingIds: sourceRecordingIds,
  onSelect,
  currentReference,
  currentValue,
}: MetadataTableRecordingProps) => {
  const [metadata, setMetadata] = useState<Metadatum[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  // Fetch data any time recording IDs change
  useEffect(() => {
    setIsLoading(true);
    getRecordingMetadata(sourceRecordingIds)
      .then(setMetadata)
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [sourceRecordingIds]);

  // Create a map of metadatum values by key
  const metadataByKey = chain(metadata)
    .groupBy(({ key }) => key)
    .value();

  // Create an array of metadatum objects from the response data. If multiple
  // recordings have values for the same metadatum, their values are stored
  // together. We remove any metadata that does not have a name. This occurs
  // when custom metadata columns are deleted.
  const items = chain(metadataByKey)
    .values()
    .map((metadata) => {
      const key = metadata[0].key;
      const name = metadata[0].name;
      const values = metadata
        .map(({ value }) => value)
        .filter(isToolParamValue) as ToolParamValue[];
      return {
        key,
        name,
        values: uniq(values),
      };
    })
    .value();

  return (
    <MetadataTable
      isLoading={isLoading}
      isError={error !== undefined}
      items={items}
      onSelect={onSelect}
      currentReference={currentReference}
      currentValue={currentValue}
      renderValueTooltip={(metadatumKey, value) => {
        const recordingIds = chain(metadataByKey[metadatumKey])
          .filter((metadatum) => metadatum.value === value)
          .map((metadatum) => metadatum.recordingId)
          .uniq()
          .value();

        // Do not show a tooltip unless there are multiple recordings selected
        // in the source column
        if (sourceRecordingIds.length < 2 || recordingIds.length === 0) {
          return null;
        }

        return (
          <EuiFlexGroup direction="column" gutterSize="xs">
            <EuiFlexItem>
              <strong>Recording session(s)</strong>
            </EuiFlexItem>
            {recordingIds.map((recordingId) => (
              <EuiFlexItem key={recordingId}>
                <RecordingIdentifierBadge recordingId={recordingId} />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        );
      }}
    />
  );
};
