import { EuiSideNav, Query } from "@inscopix/ideas-eui";
import { Dispatch, SetStateAction } from "react";

interface WorkflowSelectionModalToolkitSelectProps {
  toolkitNames: string[];
  query: Query | undefined;
  setQuery: Dispatch<SetStateAction<Query | undefined>>;
  toolkit: string | undefined;
  setToolkit: Dispatch<SetStateAction<string | undefined>>;
}

export const WorkflowSelectionModalToolkitSelect = ({
  toolkitNames,
  query,
  setQuery,
  toolkit,
  setToolkit,
}: WorkflowSelectionModalToolkitSelectProps) => {
  const toolkits = [
    {
      id: "all",
      name: "All Toolkits",
      onClick: () => setToolkit(undefined),
      emphasize: toolkit === undefined,
    },
    ...toolkitNames.map((toolkitName, index) => ({
      id: toolkitName,
      name: toolkitName,
      onClick: () => setToolkit(toolkitName),
      truncate: false,
      emphasize: toolkitName === toolkit,
    })),
  ];
  return (
    <EuiSideNav
      aria-label="Toolkits"
      style={{ width: 160 }}
      headingProps={{ screenReaderOnly: true }}
      items={toolkits}
      mobileBreakpoints={[]}
    />
  );
};
