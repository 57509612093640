import {
  EuiText,
  EuiSpacer,
  EuiTitle,
  EuiSkeletonRectangle,
  EuiTextColor,
} from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useFetchAndSetHasActiveContract } from "hooks/useFetchAndSetHasActiveContract";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  TenantResourceSummary,
  useFetchResourceSummary,
} from "./useFetchResourceSummary";
import { PanelTenantUsageQuotas } from "./PanelTenantUsageQuotas";

// Threshold for warning when quota is nearly exceeded
export const isThresholdNearlyExceeded = (percentage: number) =>
  percentage >= 90;

interface PanelTenantUsageContentProps {
  compact?: boolean;
}

/**
 * Displays a panel showing resource consumption for the entire tenant
 */
export const PanelTenantUsageContent = ({
  compact = false,
}: PanelTenantUsageContentProps) => {
  const { expiresAt, hasActiveContract, fetchAndSetHasActiveContract } =
    useFetchAndSetHasActiveContract();
  const { fetchResourceSummary } = useFetchResourceSummary();

  const [resourceSummary, setResourceSummary] =
    useState<TenantResourceSummary>();
  const [isLoading, setIsLoading] = useState(true);

  const panelMinWidth = compact ? 0 : 400;

  //
  // Set `hasActiveContract` state
  //
  useEffect(() => {
    void fetchAndSetHasActiveContract();
  }, [fetchAndSetHasActiveContract]);

  //
  // Get consumption data from Django API
  //
  useEffect(() => {
    const callFetchResourceSummary = async () => {
      const data = await fetchResourceSummary();
      setResourceSummary(data);
      setIsLoading(false);
    };

    void callFetchResourceSummary();
  }, [fetchResourceSummary]);

  if (isLoading) {
    return (
      <EuiSkeletonRectangle
        style={{ minWidth: panelMinWidth }}
        height={344} // 344 is the height of the panel when it's fully loaded, for visual consistency
        width="100%"
      />
    );
  }

  if (resourceSummary === undefined) {
    return <CallOutError />;
  }

  //
  // Checking `hasActiveContract` instead of
  // inferring if the contract is expired from the `expiresAt` date
  // because the `expiresAt` returns undefined for both:
  //  a) if the tenant has no contract or
  //  b) if the contract is already expired
  //
  const daysRemaining = Math.ceil(
    moment(expiresAt).diff(moment(), "days", true),
  );
  const expirationMessage = hasActiveContract ? (
    expiresAt !== undefined ? (
      `Valid thru ${moment(expiresAt).format(
        "DD MMMM YYYY",
      )} (${daysRemaining} day${daysRemaining > 1 ? "s" : ""} remaining)`
    ) : (
      "No expiration"
    )
  ) : (
    <EuiTextColor color="danger">
      Your IDEAS subscription has expired
    </EuiTextColor>
  );

  return (
    <>
      {compact ? (
        <>
          <EuiText size="m" color="subdued">
            {expirationMessage}
          </EuiText>
          <EuiSpacer size="m" />
        </>
      ) : (
        <>
          <EuiTitle>
            <EuiText>
              <h3>
                Subscription details
                <EuiSpacer size="xs" />
                <EuiText size="m" color="subdued">
                  {expirationMessage}
                </EuiText>
              </h3>
            </EuiText>
          </EuiTitle>
          <EuiSpacer size="xl" />
        </>
      )}

      <PanelTenantUsageQuotas
        resourceSummary={resourceSummary}
        compact={compact}
      />
    </>
  );
};
