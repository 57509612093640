import {
  EuiAccordion,
  EuiButtonEmpty,
  EuiFieldNumber,
  EuiFlexGrid,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { useRoiEditorContext } from "components/RoiEditor/RoiEditorProvider";

export interface RoiEditorBoundingBoxControlsProps {
  onReset: (key: string, name: string) => void;
  onChange: (
    dimensions: {
      left?: number;
      top?: number;
      width?: number;
      height?: number;
    },
    key: string,
    name: string,
  ) => void;
  isReadOnly?: boolean;
}

const DIMENSIONS = ["left", "top", "width", "height"] as const;

export const RoiEditorBoundingBoxControls = ({
  onReset,
  onChange,
  isReadOnly = false,
}: RoiEditorBoundingBoxControlsProps) => {
  const boundingBox = useRoiEditorContext((s) => s.boundingBox);

  if (boundingBox === undefined) {
    captureException("Controls rendered without bounding box present ");
    return null;
  }

  return (
    <EuiAccordion
      id="crop_area"
      initialIsOpen
      buttonContent={
        <EuiText size="xs">
          <h3>{boundingBox.name}</h3>
        </EuiText>
      }
      extraAction={
        <EuiButtonEmpty
          size="xs"
          onClick={() => onReset(boundingBox.groupKey, boundingBox.name)}
          disabled={isReadOnly}
        >
          Reset
        </EuiButtonEmpty>
      }
    >
      <EuiSpacer size="s" />
      <EuiFlexGrid columns={2} gutterSize="s">
        {DIMENSIONS.map((dimension) => (
          <EuiFlexItem key={dimension}>
            <EuiFieldNumber
              readOnly={isReadOnly}
              prepend={dimension}
              value={Math.round(boundingBox[dimension])}
              onChange={(e) => {
                const newDimensions = { ...boundingBox };
                newDimensions[dimension] = parseInt(e.target.value);
                onChange(newDimensions, boundingBox.groupKey, boundingBox.name);
              }}
            />
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
    </EuiAccordion>
  );
};
