import {
  EuiButton,
  EuiButtonEmpty,
  EuiCheckbox,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import {
  AnalysisResultColDef,
  RecordingsGridColDef,
} from "components/RecordingsGrid/RecordingsGrid.types";
import { DatasetRecordingsTableColumn } from "graphql/_Types";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useState } from "react";

interface ModalExportDatasetProps {
  onClose: () => void;
}

export const ModalExportDataset = ({ onClose }: ModalExportDatasetProps) => {
  const { recordingsTable } = useDatasetDataContext();
  const tableColumns = recordingsTable.datasetRecordingsTableColumns.nodes;
  const [filename, setFilename] = useState("");
  const [includedColumns, setIncludedColumns] = useState(() =>
    tableColumns.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: true }),
      {} as Record<DatasetRecordingsTableColumn["id"], boolean>,
    ),
  );

  const toggleColumn = (columnId: DatasetRecordingsTableColumn["id"]) => {
    setIncludedColumns((prevIncludedColumns) => {
      const newIncludedColumns = { ...prevIncludedColumns };
      newIncludedColumns[columnId] = !prevIncludedColumns[columnId];
      return newIncludedColumns;
    });
  };

  const isDisabled =
    filename === "" ||
    Object.values(includedColumns).every((value) => value === false);

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h1">Export Dataset</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiForm component="form" role="form">
        <EuiModalBody>
          <EuiFormRow label="Filename" display="columnCompressed">
            <EuiFieldText
              compressed
              onChange={(e) => setFilename(e.target.value)}
              placeholder='e.g. "bundle"'
              value={filename}
              append=".zip"
            />
          </EuiFormRow>
          <EuiFormRow label="Included columns">
            <div>
              {/* TODO https://inscopix.atlassian.net/browse/ID-1373 */}
              {tableColumns
                .filter(({ colDef }) => colDef.type !== "analysisResult")
                .map((column) => (
                  <EuiCheckbox
                    key={column.id}
                    id={column.id}
                    label={
                      (
                        column.colDef as Exclude<
                          RecordingsGridColDef,
                          AnalysisResultColDef
                        >
                      ).headerName
                    }
                    onChange={() => toggleColumn(column.id)}
                    checked={includedColumns[column.id]}
                  />
                ))}
            </div>
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          <EuiButton fill disabled={isDisabled} onClick={onClose} type="submit">
            Export
          </EuiButton>
        </EuiModalFooter>
      </EuiForm>
    </EuiModal>
  );
};
