import { captureException } from "@sentry/react";
import assert from "assert";
import { Task } from "graphql/_Types";
import { useGetTaskLogDownloadUrls } from "hooks/useGetTaskLogDownloadUrls";
import { useCallback, useEffect, useState } from "react";
import { TaskStatus } from "types/constants";

export const useTaskLogs = (task: Pick<Task, "id" | "status">) => {
  const { getTaskErrUrl, getTaskOutUrl } = useGetTaskLogDownloadUrls();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [data, setData] = useState<
    | {
        stdOutUrl?: string;
        stdErrUrl?: string;
      }
    | undefined
  >(undefined);

  const fetchTaskUrls = useCallback(async () => {
    let outUrl: string | undefined;
    let errUrl: string | undefined;
    try {
      outUrl = await getTaskOutUrl(task.id);
      if (task.status !== TaskStatus["COMPLETE"]) {
        errUrl = await getTaskErrUrl(task.id);
      }
    } catch (err) {
      captureException(err);
    } finally {
      if (outUrl === undefined && errUrl === undefined) {
        setError(new Error("Failed to fetch task log"));
      } else {
        setData({ stdErrUrl: errUrl, stdOutUrl: outUrl });
      }
      setLoading(false);
    }
  }, [getTaskErrUrl, getTaskOutUrl, task.id, task.status]);

  useEffect(() => {
    if (error === undefined && data === undefined) {
      void fetchTaskUrls();
    }
  }, [data, error, fetchTaskUrls]);

  if (loading) {
    return {
      loading: true,
      error: undefined,
      data: {
        stdOutUrl: undefined,
        stdErrUrl: undefined,
      },
    };
  }

  if (error) {
    return { loading, error, data: undefined };
  }

  assert(data !== undefined, "Expected task log data to be defined");
  return { loading: false, error: undefined, data };
};
