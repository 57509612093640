import axios from "axios";
import { File, Tenant } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import moment from "moment";
import pDebounce from "p-debounce";
import { useCallback, useMemo } from "react";

type Response = {
  MultiPartID: string;
  PresignedUrls: string[];
};

export type MultiPartUploadUrl = {
  issuedAt: number;
  expiresAt: number;
  url: string;
};

export const useGetMultiPartUploadUrlsDjango = () => {
  /**
   * Gets the expiration date of a pre-signed upload url
   * @param url
   * @returns The expiration date in unix time
   */
  const getUrlExpirationDate = useCallback((url: string) => {
    const searchParams = new URLSearchParams(url);

    // Used by non-EU regions, stored as a unix timestamp
    const expires = searchParams.get("Expires");
    if (expires !== null) {
      return parseInt(expires);
    }

    // Used by EU region, stored as seconds after URL generation
    const xAmzExpires = searchParams.get("X-Amz-Expires");
    if (xAmzExpires !== null) {
      const now = moment();
      const lifespan = parseInt(xAmzExpires);
      return now.add(lifespan, "seconds").unix();
    }

    throw new Error("Failed to find URL expiration date");
  }, []);

  /**
   * Gets the multi-part upload urls for a file
   * @param fileId
   * @returns The upload urls for each file part
   */
  const getMultiPartUploadUrls = useMemo(() => {
    /* Responses can be very large for files with many file parts. If a file
       part fails to upload, new URLs will be requested. If multiple file
       parts fail at the same time, multiple identical requests will be made
       for new URLs. The debounce interval is intended to deduplicate those 
       requests to avoid making multiple expensive network requests. */

    return pDebounce(async (fileId: File["id"], tenantId: Tenant["id"]) => {
      const baseUrl = getEnvVar("URL_DRS_FILE_UPLOAD_URLS");
      const url = `${baseUrl}${fileId}/`;
      const headers = await getRequestHeaders({ tenantId });
      const { data } = await axios.get<Response>(url, { headers });

      const now = moment().unix();
      const uploadUrls: MultiPartUploadUrl[] = data.PresignedUrls.map((url) => {
        const issuedAt = now;
        const expiresAt = getUrlExpirationDate(url);
        return { issuedAt, expiresAt, url };
      });

      return uploadUrls;
    }, 1000);
  }, [getUrlExpirationDate]);

  return { getMultiPartUploadUrls };
};
