import { TAnySelectionItem } from "./DataSelector/DataSelector.types";

/**
 * Gets the selection item representing the column reach by selecting a path
 * of column.
 * @param columnSelections
 * @param allItems
 * @returns The column's selection item if it can be reached or `undefined`
 * otherwise.
 */
const getLastSelectedColumn = (
  columnSelections: string[],
  allItems: TAnySelectionItem[],
): TAnySelectionItem | undefined => {
  // We can't find a column with no path to search
  if (columnSelections.length === 0) {
    return;
  }

  for (const item of allItems) {
    if (columnSelections.length === 1 && item.id === columnSelections[0]) {
      return item;
    }

    // Return a match if we find one
    if (item.id === columnSelections[0] && item.colType !== "DrsFile") {
      return getLastSelectedColumn(columnSelections.slice(1), item.children);
    }
  }
};

/**
 * Attempts to auto-select a list of columns based on the current column
 * selections. This is done by traversing the tree of all possible selectable
 * columns and selecting any columns with only one valid option.
 * @param currentColumnSelections
 * @param allSelectionItems
 * @returns An array of predicted column IDs.
 */
export const predictColumnSelections = (
  currentColumnSelections: string[],
  allSelectionItems: TAnySelectionItem[],
) => {
  /**
   * Create a list of column selections by recursively traversing a selection item
   * and all of its children (if it has any).
   */
  const recurse = (selectionItem: TAnySelectionItem) => {
    // Base case: Files have no children
    if (selectionItem.colType === "DrsFile") {
      return [];
    }

    const selections = [selectionItem.id];

    const childrenWithMatch = (
      selectionItem.children as TAnySelectionItem[]
    ).filter((child) => child.hasMatch);

    if (selectionItem.hasMatch && childrenWithMatch.length === 1) {
      const onlyChild = childrenWithMatch[0];
      selections.push(...recurse(onlyChild));
    }

    return selections;
  };

  const lastSelectedColumn = getLastSelectedColumn(
    currentColumnSelections,
    allSelectionItems,
  );

  if (lastSelectedColumn !== undefined) {
    const prevSelections = currentColumnSelections;
    const nextSelections = recurse(lastSelectedColumn);
    nextSelections.shift();
    return [...prevSelections, ...nextSelections];
  }

  const itemsWithMatches = allSelectionItems.filter((item) => item.hasMatch);
  return itemsWithMatches.length === 1 ? recurse(itemsWithMatches[0]) : [];
};
