import axios from "axios";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";

export type DatasetRecordingsTableSetColumnOrderInput = {
  date_reordered: string;
  columns: string[];
};

type DatasetRecordingsTableSetColumnOrderDjangoResponse = {
  data: {
    id: string;
  };
};

export const useDatasetRecordingsTableSetColumnOrder = () => {
  const reorderColumns = useCallback(
    async (
      recordingsTableId: string,
      input: DatasetRecordingsTableSetColumnOrderInput,
    ) => {
      const url = `${getEnvVar(
        "URL_DRS_DATASET_RECORDINGS_TABLE_SET_COLUMN_ORDER",
      )}${recordingsTableId}/`;
      const headers = await getRequestHeaders();
      const res = await axios.patch<
        DatasetRecordingsTableSetColumnOrderInput,
        DatasetRecordingsTableSetColumnOrderDjangoResponse
      >(url, input, { headers });

      return res.data;
    },
    [],
  );

  return { reorderColumns };
};
