import axios from "axios";
import { DatasetVersionFieldsFragment, Scalars } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";

export type CreateDatasetVersionInput = {
  dataset: string;
  name: string | null;
  description: string | null;
  validation_state: Scalars["JSON"]["input"];
};

type CreateDatasetVersionDjangoResponse = {
  data: {
    id: string;
    dataset: string;
    date_created: string;
    name: string;
    description: string;
    tenant_id: number;
    // TODO https://inscopix.atlassian.net/browse/ID-1596
    views: null;
    validation_state: Scalars["JSON"]["output"];
  };
};

export type CreateDatasetVersionResult = DatasetVersionFieldsFragment;

export const useCreateDatasetVersionDjango = () => {
  const createDatasetVersionDjango = async (
    input: CreateDatasetVersionInput,
  ): Promise<CreateDatasetVersionResult> => {
    const url = getEnvVar("URL_DRS_DATASET_VERSION_CREATE");
    const headers = await getRequestHeaders();
    const response = await axios.post<
      CreateDatasetVersionInput,
      CreateDatasetVersionDjangoResponse
    >(url, input, {
      headers,
    });

    const {
      id,
      dataset,
      date_created,
      tenant_id,
      views,
      // TODO https://inscopix.atlassian.net/browse/ID-1322
      validation_state,
      name,
      description,
    } = response.data;

    return {
      __typename: "DatasetVersion",
      id,
      datasetId: dataset,
      dateCreated: date_created,
      tenantId: tenant_id,
      views,
      // TODO https://inscopix.atlassian.net/browse/ID-1322
      validationState: validation_state,
      name,
      description,
    };
  };
  return { createDatasetVersionDjango };
};
