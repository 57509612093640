// Base error class
export class IdeasError extends Error {
  public title: string;

  constructor(title: string, message: string) {
    super(message);
    this.title = title;
  }
}

// Custom errors

export class AccessDeniedError extends IdeasError {
  constructor() {
    const title = "Access Denied";
    const message =
      "You do not have user access set up. Please contact your Inscopix representative.";
    super(title, message);
  }
}

export class ApplicationError extends IdeasError {
  constructor() {
    const title = "Application Error";
    const message =
      "Failed to fetch data from server. Please try again or contact support at <support.inscopix@bruker.com>";
    super(title, message);
  }
}

export class DownloadFailedError extends IdeasError {
  constructor() {
    const title = "Download Failed";
    const message =
      "Failed to download from server. Please refresh the page and try again, or contact support at <support.inscopix@bruker.com>";
    super(title, message);
  }
}
