import {
  EuiFlexGrid,
  EuiFlexItem,
  EuiPageTemplate,
  useCurrentEuiBreakpoint,
} from "@inscopix/ideas-eui";

import { ProjectPanelDatasetTable } from "./panels/ProjectPanelDataset";
import { ProjectPanelAnalysisTable } from "./panels/ProjectPanelAnalysis";

export const ProjectPanels = () => {
  // TODO ID-2461 this must be modified if we stop overriding EUI breakpoints
  // currentEuiBreakpoint returns undefined below 800px width
  const currentEuiBreakpoint = useCurrentEuiBreakpoint();
  return (
    <EuiPageTemplate.Section color={"transparent"} grow={false}>
      <EuiFlexGrid columns={currentEuiBreakpoint !== undefined ? 2 : 1}>
        <EuiFlexItem grow>
          <ProjectPanelDatasetTable />
        </EuiFlexItem>
        <EuiFlexItem grow>
          <ProjectPanelAnalysisTable />
        </EuiFlexItem>
      </EuiFlexGrid>
    </EuiPageTemplate.Section>
  );
};
