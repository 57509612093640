import { EuiFlexGroup, EuiSearchBar } from "@inscopix/ideas-eui";
import {
  ProjectsGrid,
  ProjectsGridProps,
} from "components/ProjectsTable/ProjectsGrid";
import { useEuiSearchBar } from "hooks/useEuiSearchBar";
import { useMemo } from "react";
import { isDefined } from "utils/isDefined";

export interface ProjectsGridSearchableProps {
  projects: ProjectsGridProps["projects"];
}
export const ProjectsGridSearchable = ({
  projects,
}: ProjectsGridSearchableProps) => {
  const { onChange, query, searchBarRef, executeQuery, roleFilter } =
    useEuiSearchBar();
  const filteredProjects = useMemo(
    () => (isDefined(query) ? executeQuery(query, projects) : projects),
    [executeQuery, projects, query],
  );

  return (
    <EuiFlexGroup
      direction="column"
      style={{ height: "100%", minHeight: 400 }}
      gutterSize="s"
    >
      <EuiSearchBar
        ref={searchBarRef}
        onChange={onChange}
        query={query}
        filters={[roleFilter]}
        box={{ placeholder: "Find a project...", incremental: true }}
      />
      <ProjectsGrid projects={filteredProjects} />
    </EuiFlexGroup>
  );
};
