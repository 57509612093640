import {
  EuiButton,
  EuiFieldNumber,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { useCreateRecordingGroupBulkDjango } from "hooks/useCreateRecordingGroupBulkDjango";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { FormEvent, useState } from "react";
import { writeCacheFragment } from "utils/cache-fragments";
import { uuid } from "utils/uuid";
import { useUpdatePageDatasetCache } from "../hooks/useUpdatePageDatasetCache";
import { isDefined } from "utils/isDefined";
import assert from "assert";
import { useUserContext } from "providers/UserProvider/UserProvider";

const MAX_ROW_COUNT = 999;

interface ModalInsertRecordingsProps {
  onClose: () => void;
}
export const ModalInsertRecordings = ({
  onClose,
}: ModalInsertRecordingsProps) => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { project, dataset, recordingsTable } = useDatasetDataContext();
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState<number | undefined>(1);
  const { updateCache } = useUpdatePageDatasetCache(dataset.id);
  const { createRecordingGroupBulkDjango } =
    useCreateRecordingGroupBulkDjango();

  const isValidInput = (input: HTMLInputElement["value"]) =>
    Number(input) > 0 && Number(input) <= MAX_ROW_COUNT;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    assert(isDefined(rowCount), "Numbers of rows to add is undefined");

    const numCurrentRecordings = recordingsTable.recordingGroups.nodes.length;

    const momentNow = moment();
    const recordings = Array.from({ length: rowCount }).map((_, idx) => {
      const id = uuid();
      const name = `Recording-${numCurrentRecordings + idx + 1}`;
      const nameId = uuid();
      // each new recording 1 ms later to preserve ordering
      const dateCreated = momentNow.add(idx, "milliseconds").toISOString();
      return { id, name, nameId, dateCreated };
    });

    await createRecordingGroupBulkDjango(
      recordings.map((recording, idx) => ({
        id: recording.id,
        date_created: recording.dateCreated,
        dataset_recordings_table: recordingsTable.id,
        name: {
          key: "fileRecordingGroup.name",
          display_name: "Name",
          id: recording.nameId,
          values: [{ id: uuid(), value: recording.name }],
        },
      })),
    );

    recordings.forEach((recording, idx) => {
      writeCacheFragment({
        __typename: "FileRecordingGroup",
        id: recording.id,
        data: {
          __typename: "FileRecordingGroup",
          id: recording.id,
          datasetRecordingsTableId: recordingsTable.id,
          dateAssignmentEnded: null,
          dateCreated: recording.dateCreated,
          tenantId: currentTenant.id,
          userId: currentUser.id,
          user: {
            __typename: "ApplicationUser",
            id: currentUser.id,
          },
          datasetRecordingsTable: {
            __typename: "DatasetRecordingsTable",
            id: recordingsTable.id,
          },
          assignments: {
            __typename: "RecordingGroupFilesConnection",
            nodes: [],
          },
          recordingGroupMetadata: {
            __typename: "FileRecordingGroupMetadataConnection",
            nodes: [],
          },
          tenant: {
            __typename: "Tenant",
            id: currentTenant.id,
          },
          number: (numCurrentRecordings + idx).toString(),
          project: {
            __typename: "Project",
            id: project.id,
          },
          projectId: project.id,
        },
      });

      updateCache((prevData) => {
        const newData = cloneDeep(prevData);
        const { fileRecordingGroups } = newData.dataset.datasetRecordingsTable;
        fileRecordingGroups.nodes.push({
          __typename: "FileRecordingGroup",
          id: recording.id,
          dateCreated: recording.dateCreated,
          dateAssignmentEnded: null,
          tenantId: currentTenant.id,
          userId: currentUser.id,
          number: (numCurrentRecordings + idx).toString(),
          recordingGroupFiles: {
            __typename: "RecordingGroupFilesConnection",
            nodes: [],
          },
          metadata: {
            __typename: "FileRecordingGroupMetadataConnection",
            nodes: [],
          },
        });
        return newData;
      });
    });

    setIsLoading(false);
    onClose();
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h3">
          Insert recording row(s)
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm
          onSubmit={(e) => void handleSubmit(e)}
          id={htmlIdGenerator()()}
          component="form"
        >
          <EuiFormRow label="Number of rows">
            <EuiFieldNumber
              aria-label="Number of rows"
              value={rowCount}
              min={1}
              max={MAX_ROW_COUNT}
              onChange={(e) => {
                if (e.currentTarget.value === "") {
                  setRowCount(undefined);
                } else {
                  if (isValidInput(e.currentTarget.value)) {
                    setRowCount(Number(e.currentTarget.value));
                  }
                }
              }}
              autoFocus
            />
          </EuiFormRow>
          <EuiFormRow>
            <EuiButton
              type="submit"
              isLoading={isLoading}
              isDisabled={rowCount === undefined}
            >
              Submit
            </EuiButton>
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
    </EuiModal>
  );
};
