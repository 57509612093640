import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";
import { RoiEditorProps } from "../../RoiEditor/RoiEditor";
import { ModalToolRoiFrameParamProps } from "../ModalToolRoiFrameParam/ModalToolRoiFrameParam";
import { Tooltip } from "components/Tooltip/Tooltip";
import { ToolParamsGridRowDatum } from "../ToolParamsGrid.types";

export type ButtonToolRoiFrameParamProps = Pick<
  RoiEditorProps,
  "initialShapes"
> &
  Pick<ModalToolRoiFrameParamProps, "toolParam"> & {
    rowId: ToolParamsGridRowDatum["id"];
  };

export const ButtonToolRoiFrameParam = ({
  toolParam,
  initialShapes,
  rowId,
}: ButtonToolRoiFrameParamProps) => {
  const { openModal } = useAnalysisTableLayoutContext();

  return (
    <Tooltip content={"Open ROI editor"}>
      <EuiButtonIcon
        aria-label="Open ROI editor"
        iconType="namespace"
        style={{ marginRight: 10 }}
        color="primary"
        onClick={() =>
          openModal({
            type: "toolRoiFrameParam",
            props: {
              initialShapes,
              toolParam,
              rowId,
            },
          })
        }
      />
    </Tooltip>
  );
};
