import Axios from "axios";
import { Task } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";

/**
 * Hook to get fetch presigned task log urls
 * @returns functions to fetch presigned task log urls (stdout, stderr)
 */
export const useGetTaskLogDownloadUrls = () => {
  /**
   * Fetches presigned url to download task stdout
   * @returns presigned url
   */
  const getTaskOutUrl = useCallback(async (taskId: Task["id"]) => {
    const url = getEnvVar("URL_TES_DOWNLOAD_OUT") + taskId;
    const headers = await getRequestHeaders();
    const { data } = await Axios.get<string>(url, { headers });
    return data;
  }, []);

  const getTaskErrUrl = useCallback(
    /**
     * Fetches presigned url to download task stderr
     * @returns presigned url
     */
    async (taskId: Task["id"]) => {
      const url = getEnvVar("URL_TES_DOWNLOAD_ERR") + taskId;
      const headers = await getRequestHeaders();
      const { data } = await Axios.get<string>(url, { headers });
      return data;
    },
    [],
  );

  return { getTaskOutUrl, getTaskErrUrl };
};
