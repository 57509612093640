// eslint-disable-next-line no-restricted-imports
import { generatePath, useHistory, matchPath } from "react-router-dom";
import { useCallback, useMemo } from "react";
import {
  AnalysisTableGroup,
  Dataset,
  DatasetVersion,
  Project,
  Tenant,
} from "graphql/_Types";
import { useIdeasSearchParams } from "./useIdeasSearchParams";

export const useRoutes = () => {
  const { appendParamToPath } = useIdeasSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const history = useHistory();

  const navigateTo = useCallback(
    (path: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      history.push(path);
    },
    [history],
  );

  /**
   * Function to generate static route paths
   * @param relativePath relative path for the route
   * @returns object containing absolute route path and function to navigate to
   */
  const generateStaticRoute = useCallback(
    (path: string) => {
      return { path, navigateTo: () => navigateTo(path) };
    },
    [navigateTo],
  );

  const routeMap = useMemo(
    () => ({
      CHANGELOG: generateStaticRoute("/changelog"),
      DASHBOARD: generateStaticRoute("/dashboard"),
      HELP: generateStaticRoute("/help"),
      ROOT: generateStaticRoute("/"),
      SETTINGS: generateStaticRoute("/settings"),
      get ORGANIZATION() {
        const parameterizedPath = "/:tenantKey" as const;
        return {
          parameterizedPath,
          dynamicPath: (params: { tenantKey: Tenant["key"] }) => {
            const path = generatePath(parameterizedPath, params);
            return {
              path,
              navigateTo: () => navigateTo(path),
            };
          },
        };
      },
      get ORGANIZATION_DASHBOARD() {
        const parameterizedPath = "/:tenantKey/dashboard" as const;
        return {
          parameterizedPath,
          dynamicPath: (params: { tenantKey: Tenant["key"] }) => {
            const path = generatePath(parameterizedPath, params);
            return {
              path,
              navigateTo: () => navigateTo(path),
            };
          },
        };
      },
      get ORGANIZATION_PEOPLE() {
        const parameterizedPath = "/:tenantKey/people" as const;
        return {
          parameterizedPath,
          dynamicPath: (params: { tenantKey: Tenant["key"] }) => {
            const path = generatePath(parameterizedPath, params);
            return {
              path,
              navigateTo: () => navigateTo(path),
            };
          },
        };
      },
      get ORGANIZATION_USAGE() {
        const parameterizedPath = "/:tenantKey/usage" as const;
        return {
          parameterizedPath,
          dynamicPath: (params: { tenantKey: Tenant["key"] }) => {
            const path = generatePath(parameterizedPath, params);
            return {
              path,
              navigateTo: () => navigateTo(path),
            };
          },
        };
      },
      get PROJECT() {
        const parameterizedPath = "/:tenantKey/project/:projectKey" as const;

        return {
          parameterizedPath,
          dynamicPath: (
            params: {
              tenantKey: Tenant["key"];
              projectKey: Project["key"];
            },
            options?: { openShareModal?: boolean },
          ) => {
            const path = generatePath(parameterizedPath, params);

            return {
              path,
              navigateTo: () => {
                const navigatePath = options?.openShareModal
                  ? appendParamToPath("OPEN_MODAL", "shareProject", path)
                  : path;
                navigateTo(navigatePath);
              },
            };
          },
        };
      },
      get PROJECT_ANALYSIS_TABLE_GROUP() {
        const parameterizedPath =
          `${this.PROJECT.parameterizedPath}/analysis/:analysisTableGroupId` as const;

        return {
          parameterizedPath,
          dynamicPath: (params: {
            tenantKey: Tenant["key"];
            projectKey: Project["key"];
            analysisTableGroupId: AnalysisTableGroup["id"];
          }) => {
            const path = generatePath(parameterizedPath, params);
            return {
              path,
              navigateTo: () => navigateTo(path),
            };
          },
        };
      },
      get PROJECT_TASKS() {
        const parameterizedPath =
          `${this.PROJECT.parameterizedPath}/tasks` as const;

        return {
          parameterizedPath,
          dynamicPath: (params: {
            tenantKey: Tenant["key"];
            projectKey: Project["key"];
          }) => {
            const path = generatePath(parameterizedPath, params);
            return {
              path,
              navigateTo: () => navigateTo(path),
            };
          },
        };
      },
      get DATASET() {
        const parameterizedPath =
          `${this.PROJECT.parameterizedPath}/dataset/:datasetId` as const;
        return {
          parameterizedPath,
          dynamicPath: (params: {
            tenantKey: Tenant["key"];
            projectKey: Project["key"];
            datasetId: Dataset["id"];
          }) => {
            const path = generatePath(parameterizedPath, params);
            return {
              path,
              navigateTo: () => navigateTo(path),
            };
          },
        };
      },
      get DATASET_HISTORY() {
        const parameterizedPath =
          `${this.DATASET.parameterizedPath}/history/:cutoffTime` as const;
        return {
          parameterizedPath,
          dynamicPath: (params: {
            tenantKey: Tenant["key"];
            projectKey: Project["key"];
            datasetId: Dataset["id"];
            cutoffTime: number;
          }) => {
            const path = generatePath(parameterizedPath, params);
            return {
              path,
              navigateTo: () => navigateTo(path),
            };
          },
          isRouteMatch: () =>
            matchPath(window.location.pathname, { path: parameterizedPath })
              ?.isExact ?? false,
        };
      },
      get DATASET_VERSION() {
        const parameterizedPath =
          `${this.DATASET.parameterizedPath}/version/:versionId` as const;

        return {
          parameterizedPath,
          dynamicPath: (params: {
            tenantKey: Tenant["key"];
            projectKey: Project["key"];
            datasetId: Dataset["id"];
            versionId: DatasetVersion["id"];
          }) => {
            const path = generatePath(parameterizedPath, params);
            return {
              path,
              navigateTo: () => navigateTo(path),
            };
          },
          isRouteMatch: () =>
            matchPath(window.location.pathname, { path: parameterizedPath })
              ?.isExact ?? false,
        };
      },
    }),
    [appendParamToPath, generateStaticRoute, navigateTo],
  );

  return { routeMap };
};
