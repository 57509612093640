import { UseBoundStore, create, useStore } from "zustand";
import { ToolParamsGridRowDatum, ToolSpec } from "./ToolParamsGrid.types";
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { StoreApi } from "zustand";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import assert from "assert";
import { getDefaultRowDatum } from "./ToolParamsGridProvider.helpers";
import {
  AnalysisTable,
  ToolVersion,
  File as DrsFile,
  useCreateAnalysisTableRowAttachResultMutation,
  Project,
  Dataset,
  FileRecordingGroup,
} from "graphql/_Types";
import { assign, chain, cloneDeep, mergeWith } from "lodash";
import { isToolPathParam } from "./ToolParamsGrid.helpers";
import { uuid } from "utils/uuid";
import { updateCacheFragment } from "utils/cache-fragments";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { useGetProjectFilesByFileIds } from "./hooks/useGetProjectFilesByFileIds";
import { useTablePersister } from "./ToolParamsGridProvider.hooks";
import { TaskStatus } from "types/constants";
import { getEnvVar } from "ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";
import axios from "axios";
import { isDefined } from "utils/isDefined";

export type SaveStatus = "unsaved" | "saved" | "saving" | "error";

type ToolParamsGridRowDataStoreState = {
  addNewRow: () => void;
  copyRow: (rowId: string) => void;
  removeRow: (rowId: string) => void;
  cancelTask: (rowId: string) => Promise<void>;
  rowData: ToolParamsGridRowDatum[];
  updateRowDatum: (
    rowId: string | string[],
    attrs: Partial<ToolParamsGridRowDatum>,
    options?: { forceUpdateLockedRow?: boolean; skipSave?: boolean },
  ) => void;
  attachResults: (
    rowIds: ToolParamsGridRowDatum["id"][],
    newAttachResults: boolean,
  ) => Promise<void>;
  getDatasetsAndRecordingsFromFileIds: (fileIds: DrsFile["id"][]) => {
    datasets: string[];
    recordings: string[];
  };
  saveStatus: SaveStatus;
  /**
   *   Method passed to store to interact with table persister
   */
  addOrUpdateUnsavedRow: (
    rowDatum: ToolParamsGridRowDatum,
    options?: {
      isNewTableRow?: boolean;
      markForDeletion?: boolean;
    },
  ) => void;
};

export interface ToolParamsGridRowDataProviderProps {
  children: ReactNode;
  initialRowData: ToolParamsGridRowDatum[];
  toolSpec: ToolSpec;
  analysisTable: Pick<AnalysisTable, "id"> & {
    toolVersion: Pick<ToolVersion, "id" | "version">;
  };
  projectId: Project["id"];
  projectKey: Project["key"];
}

const ToolParamsGridRowDataContext = createContext<
  UseBoundStore<StoreApi<ToolParamsGridRowDataStoreState>> | undefined
>(undefined);

export const useToolParamsGridRowDataContext = <S,>(
  selector: (
    state: Omit<
      ToolParamsGridRowDataStoreState,
      "getDatasetsAndRecordingsFromFileIds" | "addOrUpdateUnsavedRow"
    >,
  ) => S,
) => {
  const value = useContext(ToolParamsGridRowDataContext);
  assert(
    value !== undefined,
    new ContextOutOfBoundsError("ToolParamsGridRowDataContext"),
  );

  return useStore(value, selector);
};

export const ToolParamsGridRowDataProvider = memo(
  function ToolParamsGridRowDataProvider({
    initialRowData,
    analysisTable,
    toolSpec,
    projectId,
    projectKey,
    children,
  }: ToolParamsGridRowDataProviderProps) {
    const [createAnalysisTableRowAttachResult] =
      useCreateAnalysisTableRowAttachResultMutation();

    const { getProjectFilesByFileIds } = useGetProjectFilesByFileIds();

    const [store] = useState(() =>
      create<ToolParamsGridRowDataStoreState>((set, get) => ({
        saveStatus: "saved",
        addOrUpdateUnsavedRow: () => undefined,
        /*
         * Gets the current dataset and recording values from a list of files
         * @param fileIds a list of file IDs to read
         * @returns a list of unique dataset IDs and recording IDs found
         */
        getDatasetsAndRecordingsFromFileIds: () => ({
          datasets: [],
          recordings: [],
        }),
        rowData: initialRowData,
        /**
         * Adds a new row datum to the grid row data
         */
        addNewRow: () => {
          const defaultRowDatum = getDefaultRowDatum(
            toolSpec,
            analysisTable.toolVersion,
          );

          addOrUpdateUnsavedRow(defaultRowDatum, { isNewTableRow: true });

          set({ rowData: [...get().rowData, defaultRowDatum] });
        },

        /**
         * Copies a row datum and inserts the copy below the original row
         */
        copyRow: (rowId) => {
          const newRowData = [...get().rowData];
          const rowIndex = newRowData.findIndex(({ id }) => id === rowId);
          assert(rowIndex !== -1, `No row with id "${rowId}" found`);
          const prevRowDatumCopy = cloneDeep(newRowData[rowIndex]);

          const fileIds: DrsFile["id"][] = [];

          toolSpec.params.filter(isToolPathParam).forEach((toolPathParam) => {
            const paramKey = toolPathParam.key;
            const toolParamValue = prevRowDatumCopy.params[paramKey];

            // if no files in list, set input path param as undefined so cell validation works
            if (Array.isArray(toolParamValue) && toolParamValue.length === 0) {
              prevRowDatumCopy.params[paramKey] = undefined;
            } else {
              const ids = prevRowDatumCopy.params[paramKey] as
                | string[]
                | undefined;
              ids && fileIds.push(...ids);
            }
          });

          const { datasets, recordings } =
            get().getDatasetsAndRecordingsFromFileIds(fileIds);

          const rowDatumCopy: ToolParamsGridRowDatum = {
            id: uuid(),
            params: cloneDeep(prevRowDatumCopy.params),
            attachResults: prevRowDatumCopy.attachResults,
            selected: false,
            task_id: undefined,
            task_status: undefined,
            output_group_files: undefined,
            selections: cloneDeep(prevRowDatumCopy.selections),
            datasets: datasets,
            recordings: recordings,
            metadatumReferences: cloneDeep(
              prevRowDatumCopy.metadatumReferences,
            ),
            toolVersion: analysisTable.toolVersion,
          };

          newRowData.push(rowDatumCopy);
          set({ rowData: newRowData });
          addOrUpdateUnsavedRow(rowDatumCopy, { isNewTableRow: true });
        },
        /**
         * Removes a row datum from the grid row data
         */
        removeRow: (rowId) => {
          const prevRowData = get().rowData;
          const newRowData = [...prevRowData];
          const rowIndex = newRowData.findIndex(({ id }) => id === rowId);
          assert(rowIndex !== -1, `No row with id "${rowId}" found`);
          newRowData.splice(rowIndex, 1);
          addOrUpdateUnsavedRow(prevRowData[rowIndex], {
            markForDeletion: true,
          });
          set({ rowData: newRowData });
        },

        /**
         * Cancels the task of associated with a specified row
         * @param rowId
         */
        cancelTask: async (rowId) => {
          // Find row datum matching row ID
          const prevRowData = get().rowData;
          const newRowData = [...prevRowData];
          const rowIndex = newRowData.findIndex(({ id }) => id === rowId);
          assert(rowIndex !== -1, `No row with id "${rowId}" found`);
          newRowData[rowIndex] = cloneDeep(newRowData[rowIndex]);
          const taskId = newRowData[rowIndex].task_id;
          assert(isDefined(taskId));

          // Cancel task on server
          const baseUrl = getEnvVar("URL_TES_TASK_CANCEL");
          const url = `${baseUrl}${taskId}/`;
          const headers = await getRequestHeaders();
          await axios.patch(url, undefined, { headers });

          // Update row datum's task status
          newRowData[rowIndex].task_status = TaskStatus.CANCELED;
          set({ rowData: newRowData });
        },

        /**
         * Updates individual, nested properties for a specified row datum
         * Updates rows with the same data if rowId is an array
         * @param rowId Single rowId or an array of rowIds
         * @param attrs The row datum properties to be updated
         * @param skipSave  Update but don't save
         */

        updateRowDatum: (rowId, attrs, options) => {
          // Function to update a single row
          const updateRow = (
            rowId: string,
            rowData: ToolParamsGridRowDatum[],
            attrs: Partial<ToolParamsGridRowDatum>,
            options?: { forceUpdateLockedRow?: boolean; skipSave?: boolean },
          ) => {
            const rowIndex = rowData.findIndex(({ id }) => id === rowId);
            assert(rowIndex !== -1, `No row with id "${rowId}" found`);

            if (
              rowData[rowIndex].task_id !== undefined &&
              !options?.forceUpdateLockedRow
            ) {
              return;
            }

            rowData[rowIndex] = mergeWith(
              {},
              rowData[rowIndex],
              attrs,
              (objValue, srcValue) => {
                if (
                  typeof objValue === "object" &&
                  typeof srcValue === "object"
                ) {
                  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    return srcValue;
                  } else {
                    assign(objValue, srcValue);
                  }
                }
              },
            );

            // Recalculate dataset and recording references based on file IDs
            // stored in tool path param values
            if (rowData[rowIndex].task_id === undefined) {
              const { datasets, recordings } = chain(toolSpec.params)
                .filter(isToolPathParam)
                .flatMap((param) => {
                  const fileIds = rowData[rowIndex].params[param.key];
                  return (fileIds ?? []) as string[];
                })
                .thru(get().getDatasetsAndRecordingsFromFileIds)
                .value();

              rowData[rowIndex].datasets = datasets;
              rowData[rowIndex].recordings = recordings;
            }

            if (!options?.skipSave) {
              addOrUpdateUnsavedRow(rowData[rowIndex]);
            }
          };

          const newRowData = [...get().rowData];

          // If rowId is an array, process each rowId in the array
          if (Array.isArray(rowId)) {
            for (const id of rowId) {
              updateRow(id, newRowData, attrs, options);
            }
          }
          // If rowId is a single string, process it
          else {
            updateRow(rowId, newRowData, attrs, options);
          }

          set({ rowData: newRowData });
        },
        /**
         * Updates the attachResults property for a specified row datum
         * @param rowId
         * @param attach
         */
        attachResults: async (
          rowIds: ToolParamsGridRowDatum["id"][],
          attach: boolean,
        ) => {
          const applyAttachResults = async (
            rowId: ToolParamsGridRowDatum["id"],
            attach: boolean,
          ) => {
            try {
              get().updateRowDatum(
                rowId,
                { attachResults: attach },
                { skipSave: true, forceUpdateLockedRow: true },
              );

              await createAnalysisTableRowAttachResult({
                variables: {
                  input: {
                    analysisTableRowAttachResult: {
                      analysisTableRowId: rowId,
                      attachResults: attach,
                      projectId,
                    },
                  },
                },
              });

              updateCacheFragment({
                __typename: "AnalysisTableRow",
                id: rowId,
                update: (data) => {
                  const newData = cloneDeep(data);
                  newData.activeAttachResults = attach;
                  return newData;
                },
              });
            } catch (err) {
              addUtilityToastFailure("Failed to update attach results");
            }
          };
          for (const rowId of rowIds) {
            await applyAttachResults(rowId, attach);
          }
        },
      })),
    );

    /**
     * Updates the row data params after a new or updated row is saved to the server.
     * This is necessary to synchronize any metadatum reference values that may
     * have changed.
     */
    const handleAddOrUpdateComplete = useCallback(
      (newRowDatum: ToolParamsGridRowDatum) => {
        const rowData = store.getState().rowData;
        store.setState({
          ...store.getState(),
          rowData: rowData.map((prevRowDatum) => {
            const newParams = newRowDatum.params;
            return prevRowDatum.id === newRowDatum.id
              ? { ...prevRowDatum, params: newParams }
              : prevRowDatum;
          }),
        });
      },
      [store],
    );

    const { addOrUpdateUnsavedRow, saveStatus } = useTablePersister({
      tableId: analysisTable.id,
      projectKey,
      projectId,
      onAddOrUpdateComplete: handleAddOrUpdateComplete,
    });

    /**
     * Update functions and values in store when dependencies change
     */

    useEffect(() => {
      store.setState({ ...store.getState(), saveStatus });
    }, [saveStatus, store]);

    useEffect(() => {
      store.setState({ ...store.getState(), addOrUpdateUnsavedRow });
    }, [addOrUpdateUnsavedRow, store]);

    useEffect(() => {
      const getDatasetsAndRecordingsFromFileIds = (
        fileIds: DrsFile["id"][],
      ) => {
        const { drsFilesFound: files } = getProjectFilesByFileIds(fileIds);
        const datasetIds = new Set<Dataset["id"]>();
        const recordingIds = new Set<FileRecordingGroup["id"]>();
        files.forEach((file) => {
          file.recordings?.forEach(({ id }) => recordingIds.add(id));
          file.datasets?.forEach(({ id }) => datasetIds.add(id));
        });
        return {
          datasets: Array.from(datasetIds),
          recordings: Array.from(recordingIds),
        };
      };

      store.setState({
        ...store.getState(),
        getDatasetsAndRecordingsFromFileIds,
      });
    }, [getProjectFilesByFileIds, store]);

    return (
      <ToolParamsGridRowDataContext.Provider value={store}>
        {children}
      </ToolParamsGridRowDataContext.Provider>
    );
  },
);
