import { ProcessCellForExportParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { RecordingsGridRowDatum } from "components/RecordingsGrid/RecordingsGrid.types";
import {
  ToolParamsGridRowDatum,
  ToolSpec,
} from "components/ToolParamsGrid/ToolParamsGrid.types";
import { File as DrsFile } from "graphql/_Types";
import { RefObject } from "react";
import { TaskStatus } from "types/constants";
import { ProjectFile } from "stores/project-files/ProjectFilesManager";
import { isToolPathParam } from "../components/ToolParamsGrid/ToolParamsGrid.helpers";

/**
 * Exports/downloads a Dataset or Analysis Table ag-grid as CSV
 * @param gridRef the ag-grid ref for export
 * @param [fileName="ideas-export"] an optional string to set the name of the downloaded CSV (extension will be added automatically)
 */
export const exportAsCSV = (
  gridRef: RefObject<AgGridReact>,
  fileName = "ideas-export",
  filesById?: Record<DrsFile["id"], ProjectFile | undefined>,
  toolSpec?: ToolSpec,
) => {
  const columnApi = gridRef?.current?.columnApi;
  const allColumnKeys = columnApi
    ?.getColumns()
    ?.map((column) => column.getColId());

  const excludeColumns = [
    "checkbox",
    "selected",
    "row_controls",
    "task_logs",
    "rowNumber",
    "task_attach",
  ];

  gridRef.current?.api?.exportDataAsCsv({
    allColumns: true,
    fileName: `${fileName}.csv`,
    columnKeys: allColumnKeys?.filter((key) => !excludeColumns.includes(key)), // Remove some UI action columns such as the checkbox columns for both Recording and Analysis tables
    processCellCallback: (
      params: ProcessCellForExportParams<
        RecordingsGridRowDatum | ToolParamsGridRowDatum
      >,
    ) => {
      /*
       * Populating the CSV with the name of the DrsFiles
       * Assuming that the DrsFiles are in the form of an array of files
       */
      if (Array.isArray(params.value)) {
        return params.value
          .map((item) => {
            // Processing drsFiles for recording table
            if (
              typeof item === "object" &&
              "__typename" in item &&
              "name" in item
            ) {
              const localDrsFile = item as DrsFile;
              if (localDrsFile.__typename === "File") {
                return localDrsFile.name ?? "";
              }
            }
            // End processing drsFiles for recording table

            /*
             * Processing drsFiles for analysis table
             * The Analysis Table cells contains only file IDs as strings
             */
            else if (typeof item === "string") {
              const paramKey = params.column.getColId();
              const param = toolSpec?.params.find(
                ({ key }) => key === paramKey,
              );
              if (param && isToolPathParam(param) && filesById) {
                const fileId = item; // item is the drsFile ID
                const file = filesById[fileId];
                if (file) return file.name;
                else return "<File not found>";
              } else return item;
            }
            // end processing drsFiles for analysis table
            return "";
          })
          .join(", ");
      }
      // End populating the CSV with the name of the DRS objects

      // Processing for the task_status column
      if (params.column.getColId() === "task_status") {
        const toolParamsGridRowData = params.node
          ?.data as ToolParamsGridRowDatum;
        const taskStatus = toolParamsGridRowData.task_status;
        if (taskStatus === undefined) {
          return "";
        }
        return TaskStatus[taskStatus];
      }

      if (typeof params.value !== "undefined" && params.value !== null) {
        return String(params.value);
      }

      return "";
    },
  });
};
