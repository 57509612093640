import {
  EuiText,
  EuiFlexItem,
  EuiFlexGroup,
  EuiIcon,
  EuiSpacer,
  EuiHorizontalRule,
} from "@inscopix/ideas-eui";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { FlyoutAnalysisSubsectionEmptyPrompt } from "./FlyoutAnalysisSubsectionEmptyPrompt";
import { FlyoutAnalysisSubsectionAnalyses } from "./FlyoutAnalysisSubsectionAnalyses";
import { Flyout, FlyoutProps } from "components/Flyout/Flyout";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { useDatasetSelectionContext } from "pages/project/dataset/SelectionProvider";
import { chain } from "lodash";
import { compare } from "semver";

interface FlyoutAnalysisProps extends Pick<FlyoutProps, "headerControls"> {
  onClose: () => void;
}

export const FlyoutAnalysis = ({ onClose, ...props }: FlyoutAnalysisProps) => {
  const { analysisTableGroups, project } = useProjectDataContext();
  const { openModal } = useDatasetLayoutContext();
  const selectedRows = useDatasetSelectionContext((s) => s.selectedRows);

  const items = analysisTableGroups
    .filter(({ tool }) => tool !== null)
    .flatMap((group) => {
      const toolName = chain(group.analysisTables)
        .map((table) => table.toolSpec)
        .sort((a, b) => compare(a.version, b.version))
        .last()
        .thru((spec) => spec.name)
        .value();

      return {
        ...group,
        toolName,
        groupId: group.id,
        name: group.name,
        projectId: group.projectId,
        hidden: group.hidden,
        tables: group.analysisTables,
      };
    });

  const onClickCreateTable = () => {
    openModal({
      type: "runAnalysis",
      props: {
        selectedRecordings: Array.from(selectedRows.values()).map(
          ({ recording }) => recording,
        ),
      },
    });
  };

  return (
    <Flyout {...props} title="View and Run Analyses" onClose={onClose}>
      <EuiText>
        Select recordings to add to a new or existing analysis table
      </EuiText>

      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem>
          <ButtonPermissioned
            onClick={onClickCreateTable}
            requiredPermission="edit"
          >
            <EuiIcon type="plus" /> Create New Table ({selectedRows.size}{" "}
            recordings selected)
          </ButtonPermissioned>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule />

      <EuiFlexGroup direction="column">
        <EuiFlexItem grow style={{ overflow: "hidden" }}>
          {analysisTableGroups.length === 0 ? (
            <FlyoutAnalysisSubsectionEmptyPrompt
              onClickCreateTable={onClickCreateTable}
              selectedRecordingsCount={selectedRows.size}
            />
          ) : (
            <FlyoutAnalysisSubsectionAnalyses
              items={items}
              project={project}
              selectedRecordingIds={Array.from(selectedRows.keys())}
            />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </Flyout>
  );
};
