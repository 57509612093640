import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { UserRoleBadge } from "components/UserRoleBadge/UserRoleBadge";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { useState } from "react";
import { TenantStoreState } from "providers/TenantProvider/TenantProvider.types";
import { useUpdateTenantMembershipRoleDjango } from "hooks/useUpdateTenantMembershipRoleDjango";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import {
  TENANT_MEMBERSHIP_ROLES_BY_KEY,
  TenantMembershipRole,
} from "types/TenantMembershipRole";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";

interface ModalChangeUserRoleProps {
  user: TenantStoreState["tenantUsers"][number];
  newRoleKey: TenantMembershipRole["key"];
  onClose: () => void;
}

/** Component that renders a modal for changing a user's role */
export const ModalChangeUserRole = ({
  user,
  newRoleKey,
  onClose,
}: ModalChangeUserRoleProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { updateRole } = useUpdateTenantMembershipRoleDjango();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const newRole = TENANT_MEMBERSHIP_ROLES_BY_KEY[newRoleKey];
      await updateRole(user.id, newRole);
      addUtilityToastSuccess("User role updated");
      onClose();
    } catch (error) {
      addUtilityToastFailure("Failed to update user role");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EuiModal onClose={onClose} style={{ width: 600 }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Change role to {newRoleKey}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiPanel key={user.id} hasBorder>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false}>
              <UserAvatar userId={user.id} />
            </EuiFlexItem>
            <EuiFlexItem>
              <b>{`${user.firstName} ${user.lastName}`}</b>
              <p>{user.email}</p>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <UserRoleBadge role={user.role} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
        <EuiSpacer />
        <EuiText>
          {newRoleKey === "member" ? (
            <p>
              This user will no longer be able to view and edit all projects in
              this organization, including private projects. They will also no
              longer be able to manage all users&rsquo; access and roles for
              this organization. Confirm that you want to remove this
              user&rsquo;s admin privileges.
            </p>
          ) : (
            <p>
              This will allow this user to view and edit all projects in this
              organization, including private projects. They will also be able
              to manage all users&rsquo; access and roles for this organization.
              Confirm that you want to give this user admin privileges.
            </p>
          )}
        </EuiText>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          fill
          color="primary"
          isLoading={isLoading}
          onClick={() => void handleSubmit()}
        >
          Make {newRoleKey}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
