import assert from "assert";
import {
  File as DrsFile,
  MetadataTableFileDocument,
  MetadataTableFileQuery,
  MetadataTableFileQueryVariables,
} from "graphql/_Types";
import { chain } from "lodash";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { FILE_TYPES_BY_ID, FILE_TYPES_BY_KEY } from "types/FileTypes";
import { metadataMap } from "types/MetadataMap";
import { isDefined } from "utils/isDefined";
import { isNonNull } from "utils/isNonNull";

/** Keys of user-visible file metadata  */
const visibleMetadatumKeys = chain(metadataMap)
  .values()
  .filter(isDefined)
  .flatMap(Object.keys)
  .uniq()
  .value();

/**
 * Attempts to get the file type object for a file, defaulting to the unknown
 * file type if none is found.
 * @param file
 * @returns The file type object.
 */
const getFileType = (file: Pick<DrsFile, "fileType">) => {
  const unknownFileType = FILE_TYPES_BY_KEY["unknown"];
  return file.fileType !== null
    ? FILE_TYPES_BY_ID[file.fileType] ?? unknownFileType
    : unknownFileType;
};

/**
 * Gets a formatted list of user-visible file metadata.
 * @param fileIds
 * @returns The formatted metadata.
 */
export const getFileMetadata = async (fileIds: string[]) => {
  const { data } = await client.query<
    MetadataTableFileQuery,
    MetadataTableFileQueryVariables
  >({
    query: MetadataTableFileDocument,
    variables: {
      fileIds,
      metadatumKeys: visibleMetadatumKeys,
    },
  });

  const metadata = data.fileMetadata?.nodes;
  assert(isDefined(metadata));

  return metadata.flatMap(({ file, metadatum }) => {
    assert(isNonNull(file) && isNonNull(metadatum));

    // Read metadatum key
    const key = metadatum.key;

    // Read metadatum name from metadata map
    const fileType = getFileType(file);
    const name = metadataMap[fileType.key]?.[key] as string;

    // Coerce metadatum value into an array
    const values = Array.isArray(metadatum.activeValue)
      ? metadatum.activeValue
      : [metadatum.activeValue];

    return values
      .map((value) => ({ key, name, value, fileId: file.id }))
      .filter(({ name }) => isDefined(name));
  });
};
