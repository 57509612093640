import assert from "assert";
import {
  ApplicationUser,
  UserAvatarFieldsFragment,
  UserAvatarFieldsFragmentDoc,
  useUserAvatarLazyQuery,
} from "graphql/_Types";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { useEffect, useState } from "react";
import { isNonNullish } from "utils/isNonNullish";

/**
 * Hook that fetches user avatar data.
 *
 * It first checks the cache using a fragment used by the UserProvider query.
 * If the user belongs to the current tenant, it will receive a cache hit. If
 * it receives a cache miss, it fetches the data from the network.
 *
 * The backend prohibits the allApplicationUsers query from being executed
 * by the GraphQL service. This is done for security reasons and means that
 * we have to query for avatar information one user at a time. The main
 * goal of this hook is to dramatically reduce the load on the backend when
 * many avatars are rendered simultaneously.
 */
export const useUserAvatarData = (userId: string) => {
  const cachedData = client.readFragment<UserAvatarFieldsFragment>({
    id: `ApplicationUser:${userId}` satisfies `${ApplicationUser["__typename"]}:${string}`,
    fragment: UserAvatarFieldsFragmentDoc,
  });

  const [fetchData] = useUserAvatarLazyQuery({ variables: { userId } });
  const [data, setData] = useState(cachedData ?? undefined);
  const [isLoading, setIsLoading] = useState(cachedData === null);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (data === undefined) {
      setIsLoading(true);
      void fetchData()
        .then((res) => {
          const data = res.data?.user;
          assert(isNonNullish(data));
          setData(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [data, fetchData]);

  return { data, isLoading, error };
};
