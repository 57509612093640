import { GET_ALL_PROJECTS } from "graphql/queries/GetAllProjects";
import {
  GetAllProjectsQuery,
  GetAllProjectsQueryVariables,
  Project,
} from "graphql/_Types";
import { projectCardsQueryOptions } from "hooks/useGetProjects";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { isNonNullish } from "utils/isNonNullish";

export const removeProjectFromCache = (projectId: Project["id"]) => {
  client.cache.updateQuery<GetAllProjectsQuery, GetAllProjectsQueryVariables>(
    { query: GET_ALL_PROJECTS, ...projectCardsQueryOptions },
    (data) => {
      const projects = data?.projects;
      if (isNonNullish(projects)) {
        const newNodes = [...projects.nodes];
        const projectRemoveIdx = projects.nodes.findIndex(
          (project) => project.id === projectId,
        );
        if (projectRemoveIdx > -1) {
          newNodes.splice(projectRemoveIdx, 1);
        }
        return {
          __typename: "Query",
          projects: {
            ...projects,
            nodes: [...newNodes],
          },
        };
      }
    },
  );
};
