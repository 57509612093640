/** @jsxImportSource @emotion/react */
import { Interpolation, Theme, css } from "@emotion/react";
import {
  EuiButtonIcon,
  EuiSpacer,
  EuiSpacerProps,
  EuiTitle,
  useEuiTheme,
} from "@inscopix/ideas-eui";
import { FC } from "react";

const styles = {
  root: (borderColor: string) => css`
    background-color: white;
    border: 1px solid ${borderColor};
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 12px;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
  `,
  headerControls: css`
    display: flex;
  `,
  body: (persistScrollbar: boolean) => css`
    flex: 1;
    overflow-y: ${persistScrollbar ? "scroll" : "auto"};
  `,
};

export interface FlyoutProps {
  children: React.ReactNode;
  onClose: () => void;
  title: string | React.ReactNode;
  headerControls?: React.ReactNode;
  persistScrollbar?: boolean;
  css?: Interpolation<Theme>;
  titleSpacerSize?: EuiSpacerProps["size"];
}

export const Flyout: FC<FlyoutProps> = ({
  children,
  onClose,
  title,
  headerControls,
  persistScrollbar,
  css,
  titleSpacerSize,
}) => {
  const { euiTheme } = useEuiTheme();

  return (
    <div css={[styles.root(euiTheme.colors.lightShade), css]}>
      <div css={styles.header}>
        <EuiTitle size="s">
          <h2>{title}</h2>
        </EuiTitle>
        <div css={styles.headerControls}>
          {headerControls}
          <EuiButtonIcon
            title="Close"
            iconType="cross"
            color="text"
            aria-label="Close"
            onClick={onClose}
          />
        </div>
      </div>
      <EuiSpacer size={titleSpacerSize} />
      <div css={styles.body(Boolean(persistScrollbar))}>{children}</div>
    </div>
  );
};
