import { memo } from "react";
import { ToolChoiceParam } from "./ToolParamsGrid.types";
import {
  GridRendererToolParam,
  GridRendererToolParamProps,
} from "./GridRendererToolParam/GridRendererToolParam";
import { zip } from "lodash";

interface GridRendererToolChoiceParamProps
  extends Omit<GridRendererToolParamProps, "defaultValue"> {
  toolParam: ToolChoiceParam;
  value: ToolChoiceParam["type"]["choices"][number] | undefined;
}

/**
 * A component for rendering `ToolChoiceParam` grid cells
 */
export const GridRendererToolChoiceParam = memo(
  function GridRendererToolChoiceParam({
    toolParam,
    value,
    ...props
  }: GridRendererToolChoiceParamProps) {
    // Array of choice and display name pairs
    const choices = zip(
      toolParam.type.choices,
      toolParam.type.display_options,
    ).map(([choice, displayName]) => ({ choice, displayName }));

    // Display name of the default value
    const defaultValueDisplayName = choices.find(
      ({ choice }) => choice === toolParam.default,
    )?.displayName;

    // Display name of the value
    const valueDisplayName = choices.find(({ choice }) => choice === value)
      ?.displayName;

    return (
      <GridRendererToolParam
        {...props}
        defaultValue={defaultValueDisplayName}
        value={valueDisplayName}
        toolParam={toolParam}
        suppressMetadatumSelector
      />
    );
  },
);
