import { RecordingsGridColDef } from "components/RecordingsGrid/RecordingsGrid.types";
import { File as DrsFile } from "graphql/_Types";
import { FILE_TYPES_BY_KEY } from "types/FileTypes";

/**
 * Determines whether a DRS file is allowed in a given column
 * @param drsFile
 * @param column
 * @returns A `boolean` representing whether the file is allowed
 */
export const isDrsFileAllowedInColumn = (
  drsFile: Pick<DrsFile, "fileType">,
  column: { colDef: RecordingsGridColDef },
) => {
  return (
    drsFile.fileType === null ||
    column.colDef.type !== "drsFile" ||
    drsFile.fileType === column.colDef.fileType ||
    column.colDef.fileType === FILE_TYPES_BY_KEY["unknown"].id
  );
};
