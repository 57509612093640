import { Image, Object as FabricObject } from "fabric";
import { clamp } from "lodash";
import { JsonValue } from "type-fest";
import { CroppingCoordinates } from "./CroppingFrameSelector";

/**
 * Gets coordinates for current fabric object and returns new coordinates that are coerced to be in bounds
 * @param obj fabric object
 * @throws if object completely out of bounds on bottom or right
 * @returns new coordinates that are coerced to be in bounds
 */
export const getInBoundsCoordinatesForObj = (obj: FabricObject) => {
  const scale = obj.canvas?.getZoom() ?? 1;

  const targetCoords = {
    top: obj.getBoundingRect().top / scale,
    left: obj.getBoundingRect().left / scale,
    height: obj.getScaledHeight(),
    width: obj.getScaledWidth(),
    scaleX: obj.scaleX ?? 1,
    scaleY: obj.scaleY ?? 1,
  };

  const newCoords = {
    ...targetCoords,
  };

  const canvasSize = {
    height: (obj.canvas?.height ?? 0) / scale,
    width: (obj.canvas?.width ?? 0) / scale,
  };

  // is object completely out of bounds
  if (
    targetCoords.left > canvasSize.width ||
    targetCoords.top > canvasSize.height ||
    targetCoords.top + targetCoords.height < 0 ||
    targetCoords.left + targetCoords.width < 0
  ) {
    throw new Error("Failed to get in bounds coordinates");
  }

  if (targetCoords.top < 0) {
    newCoords.top = 0;

    // resizing (instead of moving)
    if (targetCoords.scaleY > 1) {
      // keep top in bounds when scaling vertically out of bounds
      // while keeping the bottom border fixed
      const newHeight = targetCoords.height + targetCoords.top;
      newCoords.height = newHeight;
      newCoords.scaleY = 1;
    }
  }

  // outside left border
  if (newCoords.left < 0) {
    newCoords.left = 0;

    // resizing (instead of moving)
    if (targetCoords.scaleX > 1) {
      // keep left in bounds when scaling vertically out of bounds
      // while keeping the right border fixed
      const newWidth = targetCoords.width + targetCoords.left;
      newCoords.width = newWidth;
      newCoords.scaleX = 1;
    }
  }

  // too tall
  if (newCoords.height + newCoords.top > canvasSize.height) {
    const newHeight = canvasSize.height - newCoords.top;

    newCoords.height = newHeight;
    newCoords.scaleY = 1;
  }

  // too wide
  if (newCoords.width + newCoords.left > canvasSize.width) {
    const newWidth = canvasSize.width - newCoords.left;
    newCoords.width = newWidth;
    newCoords.scaleX = 1;
  }

  newCoords.scaleX = 1;
  newCoords.scaleY = 1;
  return {
    ...newCoords,
    left: Math.round(newCoords.left),
    top: Math.round(newCoords.top),
    height: Math.round(newCoords.height),
    width: Math.round(newCoords.width),
  };
};

/**
 * Parses Cropping Frame param data and converts to cropping coordinates.
 * @throws if coordinates improperly formatted
 * @param value unknown param data value
 * @returns parsed cropping coordinates if valid
 */
export const parseCroppingFrameParamData = (
  value: unknown,
): CroppingCoordinates => {
  const InvalidCoordinatesError = new Error("Invalid coordinate format.");
  if (typeof value !== "string") {
    throw InvalidCoordinatesError;
  }

  try {
    const paramJSON = JSON.parse(value) as JsonValue;
    if (Array.isArray(paramJSON) && paramJSON.length === 4) {
      for (const val of paramJSON) {
        if (typeof val !== "number" || val < 0) {
          throw InvalidCoordinatesError;
        }
      }

      return {
        // checked for number above
        left: paramJSON[0] as number,
        top: paramJSON[1] as number,
        width: paramJSON[2] as number,
        height: paramJSON[3] as number,
      };
    }

    throw InvalidCoordinatesError;
  } catch (err) {
    throw InvalidCoordinatesError;
  }
};

/**
 * Stringifies cropping coordinates into their param data type
 * @param coordinates Cropping coordinates
 * @returns stringified coordinates in format [top, left, height, width]
 */
export const stringifyCroppingCoordinates = (
  coordinates: CroppingCoordinates,
) => {
  return JSON.stringify([
    coordinates.left,
    coordinates.top,
    coordinates.width,
    coordinates.height,
  ]);
};

/**
 * Type guard to check that all 4 coordinates are of type number
 */
export const isCompleteSetOfCroppingCoordinates = (
  coordinates: Partial<CroppingCoordinates>,
): coordinates is CroppingCoordinates => {
  return (
    typeof coordinates.left === "number" &&
    typeof coordinates.top === "number" &&
    typeof coordinates.width === "number" &&
    typeof coordinates.height === "number"
  );
};

/**
 * Calculates default scale factor for image based on image size  and window size
 * @param image Fabric image
 * @returns default scale factor between 0.1 and 3
 */
export const calculateDefaultScale = (
  image: Image,
  horizontalAllowance = 0,
  verticalAllowance = 0,
) => {
  const minImageWidth = 400;
  const minImageHeight = 400;
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const targetImageWidth = screenWidth - horizontalAllowance;
  const targetImageHeight = screenHeight - verticalAllowance;

  let scaledWidth = targetImageWidth;
  let scaledHeight = targetImageHeight;

  if (targetImageWidth < minImageWidth) {
    scaledWidth = minImageWidth;
  }
  if (targetImageHeight < minImageHeight) {
    scaledHeight = minImageHeight;
  }

  const scale = Math.min(
    scaledWidth / image.width,
    scaledHeight / image.height,
  );
  return Number(clamp(scale, 0.1, 3).toFixed(1));
};

export const errorMessageInvalidInitialCoordinates =
  "The saved coordinates are incorrectly formatted.";
