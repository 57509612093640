import { EuiPageTemplate } from "@inscopix/ideas-eui";
import { PanelProjects } from "components/PanelProjects/PanelProjects";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

export const PageOrganizationExternalDashboard = () => {
  const currentTenant = useTenantContext((s) => s.currentTenant);
  return (
    <EuiPageTemplate
      panelled={true}
      bottomBorder={"extended"}
      grow={true}
      style={{ maxWidth: 1000, margin: "auto" }}
    >
      <EuiPageTemplate.Section restrictWidth={false}>
        <PanelProjects
          panelTitle={`${currentTenant.name} projects shared with me`}
          tenantId={currentTenant.id}
          showCreateProject={false}
        />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  );
};
