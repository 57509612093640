import { EuiEmptyPrompt, EuiPageTemplate, EuiText } from "@inscopix/ideas-eui";

export const PageProjectAnalysisError = () => {
  return (
    <EuiPageTemplate>
      <EuiEmptyPrompt
        iconType="warning"
        iconColor="danger"
        title={
          <EuiText>
            <h1>Unable to load analysis table</h1>
          </EuiText>
        }
        body={
          <EuiText>
            <p>
              Unable to fetch all required data to display the analysis table.
              This error has been automatically logged and reported.
            </p>
          </EuiText>
        }
      />
    </EuiPageTemplate>
  );
};
