import { htmlIdGenerator } from "@inscopix/ideas-eui";
import { addToast } from "../components/GlobalToastList/GlobalToastList";
import pointing from "assets/pointing.png";

export function addUtilityToastPointing(message: string) {
  addToast({
    id: htmlIdGenerator()(),
    title: "Failed",
    color: "danger",
    text: <img src={pointing} alt={message} />,
  });
}
