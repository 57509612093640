import { useState } from "react";
import { EuiConfirmModal, EuiSpacer, EuiText } from "@inscopix/ideas-eui";
import { File as DrsFile } from "graphql/_Types";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { useDrsFileUnarchiveDjango } from "hooks/useDrsFileUnarchiveDjango";
import { updateCacheFragment } from "utils/cache-fragments";
import { cloneDeep } from "lodash";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { useActionQueueStore } from "stores/ActionsQueue/ActionsQueueManager";

export type ModalUnarchiveDrsFilesProps = {
  drsFiles: Pick<
    DrsFile,
    | "id"
    | "status"
    | "name"
    | "isSeries"
    | "source"
    | "seriesParentId"
    | "processingStatus"
  >[];
  onClose: () => void;
  onComplete?: ({
    successfulFiles,
    errors,
  }: {
    successfulFiles: Pick<DrsFile, "id">[];
    errors: Error[];
  }) => void;
};

export const ModalUnarchiveDrsFiles = ({
  drsFiles,
  onClose,
  onComplete,
}: ModalUnarchiveDrsFilesProps) => {
  drsFiles.forEach((drsFile) => {
    assert(
      getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "UNARCHIVE")
        .isPermitted,
      `Drs object without archive permissions reached unarchive modal`,
    );
  });

  const { drsFileUnarchive } = useDrsFileUnarchiveDjango();

  const status = useActionQueueStore((s) => s.status);
  const [isLoading, setIsLoading] = useState(false);

  const unarchiveDrsFile = async () => {
    const requests = drsFiles.map(({ id }) =>
      (async () => {
        try {
          const res = await drsFileUnarchive(id);
          return res;
        } catch (err) {
          return new Error(`Failed to archive ${id}`);
        }
      })(),
    );

    const drsFilesUnarchiveResults = await Promise.all(requests);

    const successfullyArchivedFiles = drsFilesUnarchiveResults.filter(
      function (
        result,
      ): result is Awaited<ReturnType<typeof drsFileUnarchive>> {
        return !(result instanceof Error);
      },
    );

    successfullyArchivedFiles.forEach((drsFileArchived) => {
      updateCacheFragment({
        __typename: "File",
        id: drsFileArchived.id,
        update: (data) => {
          const newData = cloneDeep(data);
          newData.status = drsFileArchived.status;
          return newData;
        },
      });
    });

    const errors = drsFilesUnarchiveResults.filter(
      function (result): result is Error {
        return result instanceof Error;
      },
    );
    if (errors.length > 0) {
      addUtilityToastFailure("One or more files failed to unarchive.");
    } else {
      addUtilityToastSuccess("Requested unarchival (may take up to 12 hours)");
    }

    if (isDefined(onComplete)) {
      onComplete({ successfulFiles: successfullyArchivedFiles, errors });
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await unarchiveDrsFile();
    setIsLoading(false);
    onClose();
  };

  return (
    <EuiConfirmModal
      title="Unarchive File"
      cancelButtonText="Cancel"
      confirmButtonText="Confirm"
      onCancel={onClose}
      onConfirm={() => void onSubmit()}
      buttonColor="primary"
      defaultFocusedButton="cancel"
      confirmButtonDisabled={status !== "synced"}
      isLoading={isLoading}
    >
      <div>
        <EuiText>
          Are you sure you wish to unarchive the following {drsFiles.length}{" "}
          file(s):
          <p>
            {drsFiles.map(({ name }) => (
              <div key={name}>{name}</div>
            ))}
          </p>
        </EuiText>
      </div>
      <EuiSpacer size="l" />
      <EuiText>
        <p>Move this data back to active storage.</p>
        <p>
          Unarchiving can take up to 12 hours for the data to become available.
        </p>
      </EuiText>
    </EuiConfirmModal>
  );
};
