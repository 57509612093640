import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageTemplate,
} from "@inscopix/ideas-eui";
import { PanelTenantUsage } from "components/PanelTenantUsage/PanelTenantUsage";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { Helmet } from "react-helmet-async";
import { memo, useState } from "react";
import { ModalEditOrganization } from "components/ModalEditOrganization/ModalEditOrganization";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { TENANT_MEMBERSHIP_ROLES_BY_KEY } from "types/TenantMembershipRole";
import { Tooltip } from "components/Tooltip/Tooltip";
import { PanelTenantUsageProjects } from "./PanelTenantUsageProjects";

export const OrganizationUsage = memo(function OrganizationUsage() {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const currentUserRole = useTenantContext((s) => {
    const defaultRole = TENANT_MEMBERSHIP_ROLES_BY_KEY["member"];
    const user = s.usersById.get(currentUser.id);
    return user?.role ?? defaultRole;
  });

  return (
    <>
      {isModalVisible && (
        <ModalEditOrganization onClose={() => setIsModalVisible(false)} />
      )}
      <Helmet>
        <title>{currentTenant.name}: Usage</title>
      </Helmet>
      <EuiPageTemplate
        restrictWidth={false}
        grow
        style={{
          width: "calc(100vw - 150px)",
          maxWidth: 1200,
          margin: "auto",
        }}
      >
        <EuiPageTemplate.Header
          alignItems="center"
          pageTitle={currentTenant.name}
          rightSideItems={[
            currentUserRole.key === "admin" && (
              <Tooltip content={"Edit organization name"}>
                <EuiButtonIcon
                  key="edit"
                  aria-label="Edit organization name"
                  iconType="pencil"
                  onClick={() => setIsModalVisible(true)}
                />
              </Tooltip>
            ),
          ]}
        />
        <EuiPageTemplate.Section
          color={"transparent"}
          grow
          component={({ children }) => <EuiFlexGroup>{children}</EuiFlexGroup>}
        >
          <EuiFlexGroup
            direction="column"
            style={{
              height: "100%",
            }}
          >
            <EuiFlexItem grow={false}>
              <PanelTenantUsage />
            </EuiFlexItem>
            <EuiFlexItem grow>
              {currentUserRole.key === "admin" && <PanelTenantUsageProjects />}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPageTemplate.Section>
      </EuiPageTemplate>
    </>
  );
});
