import { QueryResult } from "@apollo/client";
import { captureException } from "@sentry/react";
import {
  Exact,
  useGetAllProjectsLazyQuery,
  GetAllProjectsQuery,
  InputMaybe,
  ProjectFilter,
  ProjectsOrderBy,
} from "graphql/_Types";
import { compact, isUndefined } from "lodash";
import { TRegion, useRegionsContext } from "providers/RegionsProvider";
import { useState, useEffect } from "react";
import { projectCardsQueryOptions } from "./useGetProjects";

type TExternalProjectAndRegion = {
  projects: QueryResult<
    GetAllProjectsQuery,
    Exact<{
      filter?: InputMaybe<ProjectFilter> | undefined;
      orderBy?: InputMaybe<ProjectsOrderBy | ProjectsOrderBy[]> | undefined;
    }>
  >;
  region: TRegion;
};

export const useGetExternalProjects = () => {
  const { externalRegions } = useRegionsContext();
  const [getProjects] = useGetAllProjectsLazyQuery({
    ...projectCardsQueryOptions,
    onError: (err) => captureException(err),
    fetchPolicy: "no-cache",
  });
  const [results, setResults] = useState<TExternalProjectAndRegion[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const getAllProjects = async () => {
      try {
        const promises = externalRegions.map(async (region) => ({
          region,
          projects: await getProjects({
            context: {
              uri: region.urlGraphQL,
              queryDeduplication: false,
            },
          }),
        }));
        const results = await Promise.all(promises);
        setResults(results);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };
    void getAllProjects();
  }, [externalRegions, getProjects]);

  const projects =
    compact(
      results.flatMap(
        ({ projects, region }) =>
          projects.data?.projects?.nodes.map((project) => {
            if (isUndefined(region)) return undefined;
            return {
              ...project,
              region,
            };
          }),
      ),
    ) ?? [];

  return { projects, loading, error };
};
