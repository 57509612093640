import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldPassword,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { AgGridReact } from "ag-grid-react";
import { RecordingsGridRowDatum } from "components/RecordingsGrid/RecordingsGrid.types";
import { RefObject, useCallback, useState } from "react";
import { filesize } from "utils/filesize";
import { isFieldStateValid } from "./ModalDandi.helpers";
import { ModalDandiFieldDandiset } from "./ModalDandiFieldDandiset";
import { ModalDandiFieldDataColumn } from "./ModalDandiFieldDataColumn";
import { ModalDandiFieldServer } from "./ModalDandiFieldServer";
import { ModalDandiFieldFiles } from "./ModalDandiFieldFiles";
import { ModalDandiState } from "./ModalDandi.types";
import { cloneDeep } from "lodash";
import { Tooltip } from "components/Tooltip/Tooltip";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { useExportToDandiDjango } from "hooks/useExportToDandiDjango";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import assert from "assert";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { captureException } from "@sentry/react";
import { useDatasetNwbFiles } from "./ModalDandi.hooks";

export interface ModalDandiProps {
  gridRef: RefObject<AgGridReact<RecordingsGridRowDatum>>;
  onClose: () => void;
}

export const ModalDandi = ({ gridRef, onClose }: ModalDandiProps) => {
  const [fields, setFields] = useState<ModalDandiState>({
    apiKey: "",
    dandisetId: "",
    dataColumnId: "",
    server: "dandi",
    files: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const { exportToDandi } = useExportToDandiDjango();
  const { project } = useProjectDataContext();
  const { dataset } = useDatasetDataContext();
  const { buttonExportsRef } = useDatasetLayoutContext();
  const files = useDatasetNwbFiles();

  const setField = useCallback(
    <T extends keyof ModalDandiState>(field: T, value: ModalDandiState[T]) => {
      setFields((prevFields) => {
        const newFields = cloneDeep(prevFields);
        newFields[field] = value;

        // Any time the data column selection changes, select all the newly
        // displayed files
        if (field === "dataColumnId") {
          newFields["files"] = files.filter((file) =>
            file.columns.some((column) => column.id === value),
          );
        }

        return newFields;
      });
    },
    [files],
  );

  const exportButtonText = (() => {
    const numFiles = fields.files.length;
    const noun = numFiles === 1 ? "file" : "files";
    const totalSize = fields.files.reduce(
      (sum, file) => sum + BigInt(file.size),
      BigInt(0),
    );
    const formattedSize = filesize(totalSize).toString();

    return numFiles === 0
      ? "Export"
      : `Export (${numFiles} ${noun}, ${formattedSize})`;
  })();

  /** Opens the "Exports" popover from the bottom bar */
  const openExportsPopover = () => {
    const buttonExports = buttonExportsRef.current;
    if (buttonExports !== null) {
      buttonExports.click();
    } else {
      captureException("buttonExportsRef referenced before being set");
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      assert(isFieldStateValid(fields));
      await exportToDandi({
        datasetId: dataset.id,
        projectId: project.id,
        apiKey: fields.apiKey,
        server: fields.server,
        dandisetId: fields.dandisetId,
        fileIds: fields.files.map((file) => file.id),
      });
      addUtilityToastSuccess("Files have begun exporting to DANDI");
      openExportsPopover();
    } catch (error) {
      addUtilityToastFailure("Failed to export files to DANDI");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Export to DANDI</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm
          component="form"
          role="form"
          id={htmlIdGenerator()()}
          style={{ width: 600 }}
        >
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="API key"
                helpText={
                  <span>
                    See the{" "}
                    <a
                      href="https://www.dandiarchive.org/handbook/13_upload/#setup"
                      target="_blank"
                      rel="noreferrer"
                    >
                      DANDI documentation
                    </a>{" "}
                    for details
                  </span>
                }
              >
                <EuiFieldPassword
                  onChange={(e) => {
                    const apiKey = e.target.value;
                    setField("apiKey", apiKey);
                  }}
                  value={fields.apiKey}
                  placeholder="Enter your API key"
                  type="dual"
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label="Server">
                <ModalDandiFieldServer fields={fields} setField={setField} />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiFormRow label="Dandiset">
            <ModalDandiFieldDandiset fields={fields} setField={setField} />
          </EuiFormRow>
          <EuiHorizontalRule />
          <EuiFormRow
            label="Data column"
            helpText={
              "Select a data column/analysis result column with a data type of NWB."
            }
          >
            <ModalDandiFieldDataColumn
              fields={fields}
              gridRef={gridRef}
              setField={setField}
            />
          </EuiFormRow>
          {fields.dataColumnId !== undefined && (
            <EuiFormRow label="Files to export..." fullWidth>
              <ModalDandiFieldFiles
                fields={fields}
                setFiles={(files) => {
                  setField("files", files);
                }}
              />
            </EuiFormRow>
          )}
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <Tooltip
          content={
            !isFieldStateValid(fields)
              ? "Select a dandiset and at least one file to export"
              : undefined
          }
        >
          <EuiButton
            disabled={!isFieldStateValid(fields)}
            onClick={() => void handleSubmit()}
            isLoading={isLoading}
          >
            {exportButtonText}
          </EuiButton>
        </Tooltip>
      </EuiModalFooter>
    </EuiModal>
  );
};
