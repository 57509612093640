import { File as DrsFile } from "graphql/_Types";
import { FILE_TYPES_BY_ID, FILE_TYPES_BY_KEY } from "types/FileTypes";

/**
 * Gets the icon for a DRS object
 * @param drsFile
 * @returns The icon for the DRS object's type or the "unknown" object type
 * icon if none exists
 */
export const getDrsFileIcon = (file: Pick<DrsFile, "fileType">) => {
  const defaultIcon = FILE_TYPES_BY_KEY["unknown"].icon;

  if (file.fileType === null) {
    return defaultIcon;
  }

  const fileType = FILE_TYPES_BY_ID[file.fileType];
  return fileType?.icon ?? defaultIcon;
};
