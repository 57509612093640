import { useUpdatePageDatasetCache } from "components/Dataset/hooks/useUpdatePageDatasetCache";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import assert from "assert";
import { useDatasetRecordingsTableColumnUnpinDjango } from "hooks/useDatasetRecordingsTableColumnUnpinDjango";
import { isDefined } from "utils/isDefined";
import { DatasetRecordingsTableColumn } from "graphql/_Types";
import { useProjectPermission } from "hooks/useProjectPermission";

type UnpinColumnParams = {
  columnId: DatasetRecordingsTableColumn["id"];
};

export const useActionUnpinColumn = () => {
  const { dataset, datasetMode } = useDatasetDataContext();
  const { updateCache } = useUpdatePageDatasetCache(dataset.id);
  const { unpinColumn } = useDatasetRecordingsTableColumnUnpinDjango();
  const { hasPermission } = useProjectPermission();

  const getActionDef = useCallback(
    (params: UnpinColumnParams) => {
      const dateUnpinned = new Date().toISOString();

      return {
        onEnqueue: () => {
          updateCache((prevData) => {
            const newData = cloneDeep(prevData);
            const table = newData.dataset.datasetRecordingsTable;
            const allColumns = table.datasetRecordingsTableColumns.nodes;
            const column = allColumns.find(({ id }) => id === params.columnId);
            assert(isDefined(column), "Cache missing recordings table column");
            column.pinned = false;
            return newData;
          });
        },

        onDequeue: async () =>
          await unpinColumn(params.columnId, {
            date_unpinned: dateUnpinned,
          }),
      };
    },
    [updateCache, unpinColumn],
  );

  const isDisabled = datasetMode.type !== "current" || !hasPermission("edit");

  return {
    getActionDef,
    isDisabled,
  };
};
