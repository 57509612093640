/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageTemplate,
  EuiStat,
  EuiText,
} from "@inscopix/ideas-eui";
import { useProjectDataContext } from "../../pages/project/ProjectDataProvider";
import ProjectIcon from "../ProjectIcon/ProjectIcon";
import ModalEditProject from "../ModalProject/ModalEditProject";
import { useEffect, useState } from "react";
import { filesize } from "utils/filesize";
import { ModalProjectSharing } from "components/ModalProjectSharing/ModalProjectSharing";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { RoleBadge } from "components/RoleBadge/RoleBadge";

import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useGetUserRole } from "utils/permissions";
import { roundToSignificant } from "utils/roundToSignificant";
import { IconComputeCredits } from "components/IconComputeCredits/IconComputeCredits";
import { useIdeasSearchParams } from "hooks/useIdeasSearchParams";

const styles = {
  header: css`
    .euiText {
      max-inline-size: 100% !important;
    }
  `,
};

export const ProjectHeader = () => {
  const { getUserRole } = useGetUserRole();
  const { project } = useProjectDataContext();
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { getParam } = useIdeasSearchParams();

  const [showModalProject, setShowModalProject] = useState(false);
  const [showModalProjectSharing, setShowModalProjectSharing] = useState(false);

  const isOpenShareModal = getParam("OPEN_MODAL") === "shareProject";

  // check if there is a query param on url named openModal=share and open the modal
  useEffect(() => {
    if (isOpenShareModal) {
      setShowModalProjectSharing(true);
    }
  }, [isOpenShareModal]);

  const userRole = getUserRole(project);

  return (
    <>
      <EuiPageTemplate.Header
        paddingSize="s"
        pageTitle={
          <EuiFlexGroup gutterSize={"s"} alignItems="center">
            <EuiFlexItem grow={false}>
              <ProjectIcon
                size="l"
                project={project}
                tenantKey={currentTenant.key}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>
                <h2>{project.name}</h2>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <RoleBadge role={userRole} />
            </EuiFlexItem>
          </EuiFlexGroup>
        }
        description={project.shortDescription}
        rightSideItems={[
          <EuiFlexGroup
            responsive={false}
            gutterSize="xs"
            alignItems="center"
            key="project-edit-and-permission"
          >
            <EuiFlexItem grow={false}>
              <EuiButton
                key="project-sharing-and-permission"
                aria-label="Project sharing and permission"
                iconType={"users"}
                size={"s"}
                onClick={() => setShowModalProjectSharing(true)}
              >
                Share
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <ButtonIconPermissioned
                key="edit-project-button"
                aria-label="Edit project"
                iconType={"gear"}
                size={"s"}
                onClick={() => setShowModalProject(true)}
                requiredPermission="edit"
              />
            </EuiFlexItem>
          </EuiFlexGroup>,
          <EuiFlexGroup
            key="storage-size"
            responsive={false}
            alignItems="center"
          >
            <EuiFlexItem grow={false}>
              <EuiStat
                key="active-storage-size"
                titleSize={"s"}
                title={filesize(project.activeStorageSize)}
                description="Active storage"
                reverse
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiStat
                key="archived-storage-size"
                titleSize={"s"}
                title={filesize(project.archivedStorageSize)}
                description="Archived storage"
                reverse
                titleColor="subdued"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiStat
                key="compute-credits"
                titleSize={"s"}
                title={
                  <EuiFlexGroup alignItems="baseline" gutterSize="s">
                    {roundToSignificant(project.usedCredits ?? 0)}{" "}
                    <IconComputeCredits />
                  </EuiFlexGroup>
                }
                description={"Compute credits"}
                reverse
              />
            </EuiFlexItem>
          </EuiFlexGroup>,
        ]}
        css={styles.header}
        responsive={false}
      />
      {showModalProject && (
        <ModalEditProject
          projectId={project.id}
          onClose={() => setShowModalProject(false)}
        />
      )}
      {showModalProjectSharing && (
        <ModalProjectSharing
          projectId={project.id}
          onClose={() => setShowModalProjectSharing(false)}
        />
      )}
    </>
  );
};
