import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
} from "@inscopix/ideas-eui";
import { UserRoleBadge } from "components/UserRoleBadge/UserRoleBadge";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { useState } from "react";
import { TenantStoreState } from "providers/TenantProvider/TenantProvider.types";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { removeMembership } from "components/PanelTenantAccess/PanelTenantAccess.helpers";

interface ModalRemoveTenantMemberProps {
  user: TenantStoreState["tenantUsers"][number];
  onClose: () => void;
}

/** Component that renders a modal for removing users from a tenant */
export const ModalRemoveTenantMember = ({
  user,
  onClose,
}: ModalRemoveTenantMemberProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await removeMembership({
        membershipId: user.membershipId,
      });
      addUtilityToastSuccess("User removed");
    } catch (error) {
      addUtilityToastFailure("Failed to remove user");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <EuiModal onClose={onClose} style={{ width: 600 }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Remove user</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiPanel key={user.id} hasBorder>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false}>
              <UserAvatar userId={user.id} />
            </EuiFlexItem>
            <EuiFlexItem>
              <b>{`${user.firstName} ${user.lastName}`}</b>
              <p>{user.email}</p>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <UserRoleBadge role={user.role} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          fill
          color="danger"
          isLoading={isLoading}
          onClick={() => void handleSubmit()}
        >
          Remove
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
