/** @jsxImportSource @emotion/react */
import { useMemo, useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiSearchBar,
  EuiSuperSelectOption,
  Query,
} from "@inscopix/ideas-eui";
import { WorkflowSelectionModalBody } from "./WorkflowSelectionModalBody";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { WorkflowSelectionModalFilter } from "./WorkflowSelectionModalFilters";
import { uniq } from "lodash";
import { css } from "@emotion/react";
import {
  ToolSpecAndToolVersionId,
  useToolSpecContext,
} from "providers/ToolSpecProvider/ToolSpecProvider";
import { ToolMaturity } from "types/constants";
import { WorkflowSelectionModalToolkitSelect } from "./WorkflowSelectionModalToolkitSelect";
import { WorkflowSelectionModalDetail } from "./WorkflowSelectionModalDetail";

const styles = {
  modal: css`
    width: min(968px, calc(100vw - 16px));
    max-inline-size: calc(100vw - 16px);
    @media only screen and (min-width: 800px) {
      height: 75vh;
    }
  `,
  wrapper: css`
    height: 100%;
  `,
  modalFilter: css`
    padding: 0px 24px;
  `,
  columnGroup: css`
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    margin-top: 8px;
    .euiSideNavItem--root {
      padding-top: 8px;
      padding-left: 8px;
    }
    .toolkits {
      padding-right: 8px;
      border-right: 1px solid #d3d3d3;
    }
    .body {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      z-index: 1000;
    }
    .details {
      border-left: 1px solid #d3d3d3;
    }
  `,
  overflowYScroll: css`
    overflow-y: scroll;
  `,
};

type WorkflowSelectionModalProps = {
  onClose: () => void;
  isLoading: boolean;
  onSubmit: (
    toolSpec: ToolSpecAndToolVersionId | undefined,
    tableName: string,
  ) => Promise<void>;
  selectedToolSpecKey?: string;
};

/**
 * A modal component used to download the recordings table and files.
 */
function WorkflowSelectionModal({
  onClose,
  onSubmit,
  isLoading,
  selectedToolSpecKey: selectedToolSpecKeyProp,
}: WorkflowSelectionModalProps) {
  const { availableToolSpecs } = useToolSpecContext();
  const [selectedToolSpecKey, setSelectedToolSpecKey] = useState(
    selectedToolSpecKeyProp,
  );
  const [query, setQuery] = useState<Query>();
  const [toolkit, setToolkit] = useState<string>();

  const sortOptions: EuiSuperSelectOption<string>[] = [
    {
      value: "VERSION_CREATED_DESC",
      inputDisplay: "Updated (New-Old)",
    },
    {
      value: "VERSION_CREATED_ASC",
      inputDisplay: "Updated (Old-New)",
    },
    {
      value: "ALPHA_ASC",
      inputDisplay: "Name (A-Z)",
    },
    {
      value: "ALPHA_DESC",
      inputDisplay: "Name (Z-A)",
    },
    {
      value: "TOOL_CREATED_DESC",
      inputDisplay: "First Published (New-Old)",
    },
    {
      value: "TOOL_CREATED_ASC",
      inputDisplay: "First Published (Old-New)",
    },
  ];
  const [sortValue, setSortValue] = useState<string>("VERSION_CREATED_DESC");

  const toolkitNames = useMemo(() => {
    const toolSpecs = availableToolSpecs.map(({ spec }) => spec);
    return uniq(toolSpecs.map((spec) => spec.toolkit));
  }, [availableToolSpecs]);

  const toolSpecs = useMemo(() => {
    const filteredSpecs = availableToolSpecs
      // don't show deprecated tools as options
      .filter(({ maturity }) => {
        return maturity !== ToolMaturity.DEPRECATED;
      })
      .map(({ spec, credits, versionCreated, toolCreated, toolId }) => {
        const { key, name, help, version, toolkit, maturity } = spec;
        return {
          key,
          name,
          help,
          version,
          toolkit,
          maturity,
          credits,
          versionCreated,
          toolCreated,
          toolId,
        };
      })
      .filter((spec) => {
        return toolkit === undefined || spec.toolkit === toolkit;
      })
      .filter((spec) => {
        return (
          selectedToolSpecKeyProp === undefined ||
          spec.key === selectedToolSpecKeyProp
        );
      })
      .sort((a, b) => {
        switch (sortValue) {
          case "VERSION_CREATED_DESC":
            return (
              new Date(b.versionCreated).getTime() -
              new Date(a.versionCreated).getTime()
            );
          case "VERSION_CREATED_ASC":
            return (
              new Date(a.versionCreated).getTime() -
              new Date(b.versionCreated).getTime()
            );
          case "ALPHA_ASC":
            return a.name.localeCompare(b.name);
          case "ALPHA_DESC":
            return b.name.localeCompare(a.name);
          case "TOOL_CREATED_DESC":
            return (
              new Date(b.toolCreated).getTime() -
              new Date(a.toolCreated).getTime()
            );
          case "TOOL_CREATED_ASC":
            return (
              new Date(a.toolCreated).getTime() -
              new Date(b.toolCreated).getTime()
            );
          default:
            return 0;
        }
      });

    if (query === undefined) {
      return filteredSpecs;
    } else {
      return EuiSearchBar.Query.execute(query, filteredSpecs);
    }
  }, [availableToolSpecs, query, selectedToolSpecKeyProp, sortValue, toolkit]);

  const handleSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tableName: string,
  ) => {
    e.preventDefault();
    assert(isDefined(selectedToolSpecKey), "Expected workflow to be selected");
    const selection = availableToolSpecs.find(
      ({ spec }) => spec.key === selectedToolSpecKey,
    );
    void onSubmit(selection, tableName);
  };

  return (
    <EuiModal onClose={onClose} css={styles.modal}>
      <EuiModalBody>
        <EuiFlexGroup
          direction="column"
          css={styles.wrapper}
          responsive={false}
          gutterSize="none"
        >
          <EuiFlexItem grow={false}>
            {selectedToolSpecKeyProp === undefined && (
              <div css={styles.modalFilter}>
                <WorkflowSelectionModalFilter
                  query={query}
                  setQuery={setQuery}
                  sortValue={sortValue}
                  setSortValue={setSortValue}
                  sortOptions={sortOptions}
                />
              </div>
            )}
          </EuiFlexItem>
          <EuiFlexGroup
            css={[styles.columnGroup, styles.overflowYScroll]}
            responsive={false}
            gutterSize="none"
          >
            <EuiFlexItem
              className="workflowColumn toolkits"
              grow={false}
              style={{ overflowY: "scroll" }}
            >
              <WorkflowSelectionModalToolkitSelect
                toolkitNames={toolkitNames}
                query={query}
                setQuery={setQuery}
                toolkit={toolkit}
                setToolkit={setToolkit}
              />
            </EuiFlexItem>
            <EuiFlexItem
              className="workflowColumn body"
              css={styles.overflowYScroll}
            >
              <WorkflowSelectionModalBody
                selectedToolSpecKey={selectedToolSpecKey}
                setSelectedToolSpecKey={setSelectedToolSpecKey}
                toolSpecs={toolSpecs}
              />
            </EuiFlexItem>
            {selectedToolSpecKey !== undefined && (
              <EuiFlexItem grow={false} className="workflowColumn details">
                <WorkflowSelectionModalDetail
                  selectedToolSpecKey={selectedToolSpecKey}
                  toolSpecs={toolSpecs}
                  isLoading={isLoading}
                  handleSubmit={handleSubmit}
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexGroup>
      </EuiModalBody>
    </EuiModal>
  );
}

export default WorkflowSelectionModal;
