import { EuiButton, EuiButtonProps } from "@inscopix/ideas-eui";
import ModalCreateProject from "components/ModalProject/ModalCreateProject";
import { Tenant } from "graphql/_Types";
import { useState } from "react";

/*
 * Alternative style (filled) create project button for use in empty prompt
 */
export const ButtonCreateProjectFilled = (
  props: Omit<EuiButtonProps, "onClick"> & { tenantId?: Tenant["id"] },
) => {
  const [showModalCreateProject, setShowModalCreateProject] = useState(false);

  return (
    <>
      <EuiButton
        color="primary"
        {...props}
        onClick={() => setShowModalCreateProject(true)}
      >
        Create New Project
      </EuiButton>
      {showModalCreateProject && (
        <ModalCreateProject
          tenantId={props.tenantId}
          onClose={() => setShowModalCreateProject(false)}
        />
      )}
    </>
  );
};
