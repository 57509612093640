import { memo } from "react";
import { ToolParamsGridRowDatum } from "./ToolParamsGrid.types";
import { useToolParamsGridContext } from "./ToolParamsGridProvider";
import { AnalysisTableRowIdentifier } from "components/AnalysisTableRowIdentifier/AnalysisTableRowIdentifier";

export interface GridRendererRowIdentifierProps {
  data: ToolParamsGridRowDatum;
}

/**
 * A component for rendering a task identifier from grid data and params
 */
export const GridRendererRowIdentifier = memo(function GridRendererId({
  data,
}: GridRendererRowIdentifierProps) {
  const { toolSpec, analysisTable } = useToolParamsGridContext();
  if (data.task_id === undefined || data.task_date_created === undefined) {
    return null;
  }
  const task = { id: data.task_id, created: data.task_date_created };

  return (
    <AnalysisTableRowIdentifier
      table={analysisTable}
      params={data.params}
      task={task}
      toolSpec={toolSpec}
    />
  );
});
