/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EuiEmptyPrompt, EuiSpacer, EuiText } from "@inscopix/ideas-eui";
import { ButtonToolDocs } from "components/ButtonToolDocs/ButtonToolDocs";
import { Dispatch, SetStateAction } from "react";
import { ToolSpec } from "../ToolParamsGrid/ToolParamsGrid.types";
import { ToolBetaBadge } from "components/ToolBetaBadge/ToolBetaBadge";

const styles = {
  toolCard: css`
    border-bottom: 1px solid #e5e5e5;
    padding: 12px 12px 14px 12px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
    &.selected {
      background-color: rgb(204, 228, 245);
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
    }
  `,
  cardTitle: css`
    align-items: center;
    display: flex;
  `,
  toolName: css`
    font-weight: bold;
  `,
  toolVersion: css`
    margin-right: 5px;
  `,
  spacer: css`
    flex: 1;
  `,
};

interface WorkflowSelectionModalBodyProps {
  setSelectedToolSpecKey: Dispatch<SetStateAction<string | undefined>>;
  selectedToolSpecKey: string | undefined;
  toolSpecs: Pick<
    ToolSpec,
    "key" | "name" | "help" | "version" | "toolkit" | "maturity"
  >[];
}

export const WorkflowSelectionModalBody = ({
  setSelectedToolSpecKey,
  selectedToolSpecKey,
  toolSpecs,
}: WorkflowSelectionModalBodyProps) => {
  if (toolSpecs.length === 0) {
    return (
      <EuiEmptyPrompt
        iconType="search"
        title={
          <EuiText>
            <h4>No matching items found</h4>
          </EuiText>
        }
        body={<p> Please change your query or filters and try again.</p>}
      />
    );
  }

  return (
    <div>
      {toolSpecs.map((spec, idx) => {
        return (
          <div
            css={styles.toolCard}
            className={selectedToolSpecKey === spec.key ? "selected" : ""}
            key={spec.key}
            tabIndex={idx}
            onClick={() => setSelectedToolSpecKey(spec.key)}
            onKeyDown={(e) =>
              e.key === "Enter" ? setSelectedToolSpecKey(spec.key) : null
            }
          >
            <div css={styles.cardTitle}>
              <h1>
                <span css={styles.toolName}>{spec.name}</span>{" "}
                <span css={styles.toolVersion}>{spec.version}</span>
                {spec.maturity === "development" && <ToolBetaBadge />}
              </h1>
              <ButtonToolDocs tool={spec} />
            </div>
            <EuiSpacer size="s" />
            <p>{spec.help}</p>
          </div>
        );
      })}
    </div>
  );
};
