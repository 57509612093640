/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EuiProgressProps, useEuiTheme } from "@inscopix/ideas-eui";
import { polarToCartesian } from "./CircularProgress.helpers";

const styles = {
  root: (size: string) => css`
    flex-shrink: 0;
    height: ${size};
    width: ${size};
  `,
};

interface CircularProgressProps {
  color?: "primary" | "success" | "warning" | "danger" | "accent" | "text";
  size?: EuiProgressProps["size"];
  max: number;
  value: number;
}

export const CircularProgress = ({
  color = "primary",
  size = "m",
  value,
  max,
}: CircularProgressProps) => {
  const { euiTheme } = useEuiTheme();
  const percent = value / max;

  const largeArcFlag = percent > 0.5 ? 1 : 0;
  const arcEndpoint = polarToCartesian({
    r: 1,
    theta: Math.PI / 2 - percent * Math.PI * 2,
  });

  const pathCommands = [
    // Start at the origin
    ["M", 0, 0],
    // Draw a line over the positive y-axis
    ["L", 0, -1],
    // Draw an arc clockwise matching the percent progress
    ["A", 1, 1, 0, largeArcFlag, 1, arcEndpoint.x, -arcEndpoint.y],
    // Draw a line back to the origin
    ["Z"],
  ] as const;

  return (
    <svg
      css={styles.root(euiTheme.size[size])}
      // Bound the axes at -1 and 1 with the SVG centered at (0,0)
      viewBox="-1 -1 2 2"
    >
      <circle cx={0} cy={0} r={1} fill={euiTheme.colors.lightShade} />
      <path
        d={pathCommands.map((command) => command.join(" ")).join(" ")}
        fill={euiTheme.colors[color]}
      />
    </svg>
  );
};
