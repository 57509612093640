import { FileBrowserActionName, FileBrowserFile } from "./FileBrowser";
import { EuiButtonIconProps } from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { useSelectionState } from "components/FlyoutFiles/useSelectionState";
import { TooltipProps } from "components/Tooltip/Tooltip";
import { memo, useState } from "react";
import { UserPermission } from "types/UserAccessLevels";

export interface FileBrowserActionControlButtonProps {
  actionType: FileBrowserActionName;
  onExecute: () => Promise<{
    successfulFiles: Pick<FileBrowserFile, "id">[];
    errors: Error[];
  }>;
  icon: EuiButtonIconProps["iconType"];
  isDisabled: boolean;
  tooltip: TooltipProps["content"];
  deselectItems: ReturnType<
    typeof useSelectionState<FileBrowserFile>
  >[1]["deselectItems"];
  selectedFiles: FileBrowserFile[];
  // suppress button loading spinner while awaiting onExecute (e.g. when a modal will handle loading)
  suppressLoading?: boolean;
}

export const FileBrowserActionControlButton = memo(
  function FileBrowserActionControlButton({
    actionType,
    icon,
    isDisabled,
    onExecute,
    tooltip,
    deselectItems,
    selectedFiles,
    suppressLoading = false,
  }: FileBrowserActionControlButtonProps) {
    const [loading, setLoading] = useState(false);
    const onClick = async () => {
      setLoading(true);
      try {
        const result = await onExecute();
        deselectItems(result.successfulFiles);
      } catch (err) {
        captureException(err);
      }
      setLoading(false);
    };

    const requiredUserPermission: UserPermission = (() => {
      switch (actionType) {
        case "archive":
        case "delete":
        case "unassign":
        case "unarchive":
        case "createSeries":
        case "rename":
        case "revertSeries":
          return "edit";
        case "download":
          return "download";
      }
    })();

    return (
      <ButtonIconPermissioned
        isLoading={suppressLoading ? false : loading}
        className="euiDataGrid__controlBtn"
        aria-label={`${actionType} selected files`}
        iconType={icon}
        isDisabled={selectedFiles.length === 0 || isDisabled}
        onClick={() => void onClick()}
        requiredPermission={requiredUserPermission}
        defaultTooltip={tooltip}
      />
    );
  },
);
