import { File as DrsFile, FileSource } from "graphql/_Types";
import { FileStatus, ProcessingStatus } from "./constants";

/**
 * Core Permissions
 */
export type DrsFileModifyPermissions =
  | "ARCHIVE"
  | "DELETE"
  | "DATA_DELETE"
  | "UNARCHIVE"
  | "DOWNLOAD"
  | "UNASSIGN"
  | "CREATE_SERIES"
  | "REVERT_SERIES"
  | "RENAME"
  | "IDENTIFY"
  | "CANCEL_UPLOAD";

const ALL_PROCESSING_STATUSES = new Set([
  ProcessingStatus.COMPLETE,
  ProcessingStatus.FAILED,
  ProcessingStatus.PENDING,
  ProcessingStatus.PROCESSING,
  ProcessingStatus.SKIPPED,
  ProcessingStatus.UNPROCESSED,
]);

const PERMISSIONS_MAP: Record<
  DrsFileModifyPermissions,
  Readonly<{
    status: {
      permitted: ReadonlySet<FileStatus>;
      notPermittedTooltip: string;
    };
    source: {
      permitted: ReadonlySet<FileSource>;
      notPermittedTooltip: string;
    };
    series:
      | {
          permitted: false;
          notPermittedTooltip: string;
        }
      | { permitted: true };
    processingStatus: {
      permitted: ReadonlySet<ProcessingStatus>;
      notPermittedTooltip: string;
    };
  }>
> = {
  ARCHIVE: {
    status: {
      permitted: new Set([FileStatus["AVAILABLE"]]),
      notPermittedTooltip: "Only files with available status can be archived",
    },
    source: {
      permitted: new Set([FileSource.Uploaded, FileSource.AnalysisResult]),
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
    series: {
      permitted: true,
    },
    processingStatus: {
      permitted: ALL_PROCESSING_STATUSES,
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
  } as const,

  CREATE_SERIES: {
    status: {
      permitted: new Set([FileStatus["AVAILABLE"]]),
      notPermittedTooltip:
        "Only files with available status may be used to create series",
    },
    source: {
      permitted: new Set([FileSource.Uploaded]),
      notPermittedTooltip: "Only uploaded files may be used to create series",
    },
    series: {
      permitted: false,
      notPermittedTooltip: "Series can't be used to create other series",
    },
    processingStatus: {
      permitted: ALL_PROCESSING_STATUSES,
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
  } as const,
  DATA_DELETE: {
    status: {
      permitted: new Set([
        FileStatus["AVAILABLE"],
        FileStatus["UPLOADING"],
        FileStatus["ARCHIVED"],
      ]),
      notPermittedTooltip:
        "Only files with available and archived status can be data deleted",
    },
    source: {
      permitted: new Set([FileSource.Uploaded, FileSource.AnalysisResult]),
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
    series: {
      permitted: true,
    },
    processingStatus: {
      permitted: ALL_PROCESSING_STATUSES,
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
  } as const,
  DELETE: {
    status: {
      permitted: new Set([
        FileStatus["CREATED"],
        FileStatus["UPLOADING"],
        FileStatus["AVAILABLE"],
        FileStatus["ARCHIVED"],
        FileStatus["DATA_DELETED"],
        FileStatus["SCHEDULE_DATA_DELETE"],
        FileStatus["DATA_DELETED"],
      ]),
      notPermittedTooltip:
        "Only files with available, archived, and data deleted status can be deleted",
    },
    source: {
      permitted: new Set([FileSource.Uploaded, FileSource.AnalysisResult]),
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
    series: {
      permitted: true,
    },
    processingStatus: {
      permitted: ALL_PROCESSING_STATUSES,
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
  },
  DOWNLOAD: {
    status: {
      permitted: new Set([FileStatus["AVAILABLE"]]),
      notPermittedTooltip: "Only files with available status may be downloaded",
    },
    source: {
      permitted: new Set([
        FileSource.Uploaded,
        FileSource.AnalysisResult,
        FileSource.Unknown,
      ]),
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
    series: {
      permitted: true,
    },
    processingStatus: {
      permitted: ALL_PROCESSING_STATUSES,
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
  } as const,
  RENAME: {
    status: {
      permitted: new Set([
        FileStatus["CREATED"],
        FileStatus["UPLOADING"],
        FileStatus["AVAILABLE"],
        FileStatus["ARCHIVED"],
        FileStatus["UNARCHIVED"],
      ]),
      notPermittedTooltip: "Deleted files may not be renamed",
    },
    source: {
      permitted: new Set([FileSource.Uploaded, FileSource.AnalysisResult]),
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
    series: {
      permitted: true,
    },
    processingStatus: {
      permitted: ALL_PROCESSING_STATUSES,
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
  } as const,

  REVERT_SERIES: {
    status: {
      permitted: new Set([FileStatus["AVAILABLE"]]),
      notPermittedTooltip:
        "Only files with available status may be used to break series",
    },
    source: {
      permitted: new Set([FileSource.Uploaded]),
      notPermittedTooltip:
        "Series created from analyses may not be broken apart",
    },
    series: {
      permitted: true,
    },
    processingStatus: {
      permitted: ALL_PROCESSING_STATUSES,
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
  } as const,
  UNARCHIVE: {
    status: {
      permitted: new Set([FileStatus["ARCHIVED"]]),
      notPermittedTooltip: "Only files with archived status may be unarchived",
    },
    source: {
      permitted: new Set([FileSource.Uploaded, FileSource.AnalysisResult]),
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
    series: {
      permitted: true,
    },
    processingStatus: {
      permitted: ALL_PROCESSING_STATUSES,
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
  } as const,
  UNASSIGN: {
    status: {
      permitted: new Set([
        FileStatus["CREATED"],
        FileStatus["UPLOADING"],
        FileStatus["AVAILABLE"],
        FileStatus["ARCHIVED"],
        FileStatus["UNARCHIVED"],
        FileStatus["SCHEDULE_DATA_DELETE"],
        FileStatus["DATA_DELETED"],
      ]),
      notPermittedTooltip: "Deleted files may not be unassigned",
    },
    source: {
      permitted: new Set([FileSource.Uploaded]),
      notPermittedTooltip: "Only uploaded files may be unassigned",
    },
    series: {
      permitted: true,
    },
    processingStatus: {
      permitted: ALL_PROCESSING_STATUSES,
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
  } as const,

  IDENTIFY: {
    status: {
      permitted: new Set([FileStatus["AVAILABLE"]]),
      notPermittedTooltip: "Only files with available status can change type",
    },
    source: {
      permitted: new Set([FileSource.Uploaded]),
      notPermittedTooltip: "You may only change the type of uploaded files",
    },
    series: {
      permitted: false,
      notPermittedTooltip: "Not supported for series files",
    },
    processingStatus: {
      permitted: new Set([
        ProcessingStatus.SKIPPED,
        ProcessingStatus.FAILED,
        ProcessingStatus.UNPROCESSED,
      ]),
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "File type cannot be changed while processing",
    },
  } as const,
  CANCEL_UPLOAD: {
    status: {
      permitted: new Set([FileStatus["CREATED"], FileStatus["UPLOADING"]]),
      notPermittedTooltip: "Only uploading files may be cancelled",
    },
    source: {
      permitted: new Set([FileSource.Uploaded]),
      notPermittedTooltip: "You may only cancel uploaded files",
    },
    series: {
      permitted: false,
      notPermittedTooltip: "Not supported for series files",
    },
    processingStatus: {
      permitted: ALL_PROCESSING_STATUSES,
      // you must add a tooltip if you change permitted sources to exclude one or more sources
      notPermittedTooltip: "",
    },
  } as const,
};

export type DrsFileModifyPermissionErrorDetails = {
  type: "STATUS" | "SOURCE" | "SERIES" | "PROCESSING_STATUS";
  notPermittedTooltip: string;
};

export const getDrsFileModifyPermissionByDrsFileAndAction = (
  drsFile: Pick<
    DrsFile,
    "status" | "source" | "isSeries" | "seriesParentId" | "processingStatus"
  >,
  action: DrsFileModifyPermissions,
):
  | { isPermitted: true }
  | {
      isPermitted: false;
      permissionErrors: DrsFileModifyPermissionErrorDetails[];
    } => {
  let isPermitted = true;

  const actionPermissions = PERMISSIONS_MAP[action];

  const permissionErrors: DrsFileModifyPermissionErrorDetails[] = [];

  if (!actionPermissions.status.permitted.has(drsFile.status)) {
    isPermitted = false;
    permissionErrors.push({
      type: "STATUS",
      notPermittedTooltip: actionPermissions.status.notPermittedTooltip,
    });
  }

  if (
    !actionPermissions.source.permitted.has(
      drsFile.source ?? FileSource.Unknown,
    )
  ) {
    isPermitted = false;
    permissionErrors.push({
      type: "SOURCE",
      notPermittedTooltip: actionPermissions.source.notPermittedTooltip,
    });
  }

  if (
    !actionPermissions.series.permitted &&
    (drsFile.isSeries || drsFile.seriesParentId !== null)
  ) {
    isPermitted = false;
    permissionErrors.push({
      type: "SERIES",
      notPermittedTooltip: actionPermissions.series.notPermittedTooltip,
    });
  }

  if (
    !actionPermissions.processingStatus.permitted.has(drsFile.processingStatus)
  ) {
    isPermitted = false;
    permissionErrors.push({
      type: "PROCESSING_STATUS",
      notPermittedTooltip:
        actionPermissions.processingStatus.notPermittedTooltip,
    });
  }

  if (isPermitted) {
    return { isPermitted };
  }
  return {
    isPermitted,
    permissionErrors,
  };
};
