/** @jsxImportSource @emotion/react */

export interface TooltipContentListProps {
  tooltips: string[];
}

export const TooltipContentList = ({ tooltips }: TooltipContentListProps) => {
  return tooltips.length > 1 ? (
    <ul css={{ listStyleType: "disc", paddingLeft: 8 }}>
      {tooltips.map((tip) => (
        <li key={tip}>{tip}</li>
      ))}
    </ul>
  ) : (
    <span>{tooltips[0]}</span>
  );
};
