import { css } from "@emotion/react";

import { globalStyleConstants } from "styles/GlobalStyleConstants";

export const usePageProjectTasksStyles = () => {
  const headerHeight = "70px";

  const pageHeight = `calc(100vh - ${globalStyleConstants.headerAndBottomBarHeight}px)`;
  const gridHeight = `calc(${pageHeight} - ${headerHeight} - ${globalStyleConstants.headerAndBottomBarHeight}px)`;

  const styles = {
    header: css`
      height: ${headerHeight};
    `,
    headerPaddingSize: "m" as const,
    gridAndFlyoutContainer: css`
      overflow: auto;
      padding: 0px;
      height: ${gridHeight} !important;
    `,
    page: css`
      height: ${pageHeight} !important;
    `,
    flyoutContainer: css`
      width: 400px !important;
    `,
  };

  return styles;
};
