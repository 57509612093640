import assert from "assert";
import {
  PageProjectDocument,
  PageProjectQuery,
  PageProjectQueryVariables,
  Project,
} from "graphql/_Types";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { useCallback } from "react";
import { isNonNull } from "utils/isNonNull";
import { isNonNullish } from "utils/isNonNullish";

type TProject = NonNullable<PageProjectQuery["project"]>;
type TProjectDataset = NonNullable<TProject["datasets"]>["nodes"][number];
type CachedData = Pick<PageProjectQuery, "__typename"> & {
  project: Omit<TProject, "datasets"> & {
    datasets: {
      __typename: "DatasetsConnection";
      nodes: (TProjectDataset & {
        description: NonNullable<TProjectDataset["description"]>;
      })[];
    };
  };
};

type UpdateCacheOptions = {
  // if optional, only update if cache record exists
  // otherwise cache expected to exist and error will be thrown if not found
  optional?: boolean;
};

export const useUpdatePageProjectCache = (projectKey: Project["key"]) => {
  const currentTenant = useTenantContext((s) => s.currentTenant);
  /**
   * Applies an update function on the cached data of the base project query
   * @param update A function to update the cached data
   * @param options Update options
   */
  const updateCache = useCallback(
    (
      update: (data: CachedData) => CachedData,
      options?: UpdateCacheOptions,
    ) => {
      client.cache.updateQuery<PageProjectQuery, PageProjectQueryVariables>(
        {
          query: PageProjectDocument,
          variables: {
            tenantId: currentTenant.id,
            projectKey,
          },
        },
        (data) => {
          if (data === null) {
            if (options?.optional) {
              return;
            } else {
              throw new Error("Project cache record not found");
            }
          }

          assert(
            isNonNullish(data.project) && isNonNullish(data.project.datasets),
            "Project cache record missing partial data",
          );

          return update({
            __typename: "Query",
            project: {
              ...data.project,
              datasets: {
                __typename: "DatasetsConnection",
                nodes: data.project.datasets.nodes.map((dataset) => {
                  const { description } = dataset;
                  assert(
                    isNonNull(description),
                    "Project cache record missing partial data",
                  );
                  return { ...dataset, description };
                }),
              },
            },
          });
        },
      );
    },
    [currentTenant.id, projectKey],
  );
  return { updateCache };
};
