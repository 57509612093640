import { gql } from "@apollo/client";
import { PROJECT_FIELDS } from "graphql/fragments/ProjectFields";
import { USER_FIELDS } from "graphql/fragments/UserFields";

/**
 * Apollo query to search for all projects.
 */

export const GET_ALL_PROJECTS = gql`
  ${PROJECT_FIELDS}
  ${USER_FIELDS}
  query GetAllProjects($filter: ProjectFilter, $orderBy: [ProjectsOrderBy!]) {
    projects: allProjects(filter: $filter, orderBy: $orderBy) {
      nodes {
        ...ProjectFields
        user: applicationUserByUserId {
          ...UserFields
        }
        tenantByTenantId {
          id
          name
          key
        }
      }
    }
  }
`;
