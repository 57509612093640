import {
  EuiText,
  EuiSpacer,
  EuiPanel,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  Query,
  EuiHorizontalRule,
  EuiButton,
  EuiSearchBar,
  EuiAvatar,
} from "@inscopix/ideas-eui";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { Fragment, useCallback, useEffect, useState } from "react";
import { PanelTenantAccessSearchBar } from "./PanelTenantAccessSearchBar";
import { UserRoleBadge } from "../UserRoleBadge/UserRoleBadge";
import { PanelTenantAccessUserActions } from "./PanelTenantAccessUserActions";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { isDefined } from "utils/isDefined";
import assert from "assert";
import { ModalAddTenantMembers } from "components/ModalAddTenantMember/ModalAddTenantMember";
import { getPendingInvitations } from "components/PanelTenantAccess/PanelTenantAccess.helpers";
import { TENANT_MEMBERSHIP_ROLES_BY_KEY } from "types/TenantMembershipRole";
import { PanelTenantAccessInvitationActions } from "./PanelTenantAccessInvitationActions";
import { chain } from "lodash";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { TenantUserInvite } from "./PanelTenantAccess.types";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { TenantProviderDocument } from "graphql/_Types";

/** Component that renders a panel for managing users within a tenant */
export const PanelTenantAccess = () => {
  const allUsers = useTenantContext((s) => s.tenantUsers);
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const currentUserRole = useTenantContext((s) => {
    const user = s.usersById.get(currentUser.id);
    assert(isDefined(user));
    return user.role;
  });
  const [query, setQuery] = useState<Query>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invitations, setInvitations] = useState<TenantUserInvite[]>([]);

  const filteredInvitations = chain(invitations)
    // Sort invitations by creation date
    .sortBy((invitation) => invitation.date_created)
    // Label invited users as members
    .map((invitation) => {
      const role = TENANT_MEMBERSHIP_ROLES_BY_KEY["member"];
      return { ...invitation, role };
    })
    // Apply the current search bar query
    .thru((invitations) => {
      return isDefined(query)
        ? EuiSearchBar.Query.execute(query, invitations)
        : invitations;
    })
    .value();

  const filteredUsers = chain(allUsers)
    // Sort users by full name
    .sortBy((user) => `${user.firstName} ${user.lastName}`)
    // Apply the current search bar query
    .thru((users) => {
      return isDefined(query)
        ? EuiSearchBar.Query.execute(query, users)
        : users;
    })
    .value();

  // Fetch all pending invitations
  const fetchInvitations = useCallback(async () => {
    try {
      const invitations = await getPendingInvitations({
        tenantId: currentTenant.id,
      });
      setInvitations(invitations);
    } catch (error) {
      addUtilityToastFailure("Failed to fetch pending invitations");
    }
  }, [currentTenant.id]);

  // Fetch all pending initiations when the component mounts
  useEffect(() => {
    void fetchInvitations();
  }, [fetchInvitations]);

  return (
    <>
      {isModalVisible && (
        <ModalAddTenantMembers
          onClose={() => {
            setIsModalVisible(false);
            void fetchInvitations();
            void client.refetchQueries({ include: [TenantProviderDocument] });
          }}
        />
      )}
      <EuiPanel>
        <EuiTitle>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiText>
                <h3>Manage users</h3>
              </EuiText>
            </EuiFlexItem>

            {currentUserRole.key === "admin" && (
              <EuiFlexItem grow={false}>
                <EuiButton onClick={() => setIsModalVisible(true)}>
                  Invite users
                </EuiButton>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiTitle>

        <EuiSpacer />
        <PanelTenantAccessSearchBar
          query={query}
          onChange={({ query }) => setQuery(query ?? undefined)}
        />

        <EuiSpacer />

        {invitations.length > 0 && (
          <>
            <EuiText>
              <h4>Pending invitations</h4>
            </EuiText>

            <EuiSpacer size="s" />
            <EuiPanel hasBorder paddingSize="m">
              {filteredInvitations.map((invitation, idx, arr) => {
                const isLastItem = idx === arr.length - 1;
                return (
                  <Fragment key={invitation.id}>
                    <EuiFlexGroup alignItems="center">
                      <EuiFlexItem grow={false}>
                        <EuiAvatar color="#D3DAE6" iconType="email" name="" />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <b>{invitation.email}</b>
                        <i>Invitation sent</i>
                      </EuiFlexItem>
                      {currentUserRole.key === "admin" && (
                        <EuiFlexItem grow={false}>
                          <PanelTenantAccessInvitationActions
                            invitationId={invitation.id}
                            onRevoke={() => void fetchInvitations()}
                          />
                        </EuiFlexItem>
                      )}
                    </EuiFlexGroup>
                    {!isLastItem && <EuiHorizontalRule margin="m" />}
                  </Fragment>
                );
              })}
            </EuiPanel>

            <EuiSpacer />
          </>
        )}

        <EuiText>
          <h4>Active users</h4>
        </EuiText>

        <EuiSpacer size="s" />
        <EuiPanel hasBorder paddingSize="m">
          {filteredUsers.map((user, idx, arr) => {
            const isLastItem = idx === arr.length - 1;
            return (
              <Fragment key={user.id}>
                <EuiFlexGroup alignItems="center">
                  <EuiFlexItem grow={false}>
                    <UserAvatar userId={user.id} />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <b>{`${user.firstName} ${user.lastName}`}</b>
                    <p>{user.email}</p>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <UserRoleBadge role={user.role} />
                  </EuiFlexItem>
                  {currentUserRole.key === "admin" && (
                    <EuiFlexItem grow={false}>
                      <PanelTenantAccessUserActions user={user} />
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
                {!isLastItem && <EuiHorizontalRule margin="m" />}
              </Fragment>
            );
          })}
        </EuiPanel>
      </EuiPanel>
    </>
  );
};
