import { FilePreviewComponentProps } from "./FilePreview.types";

/** Component that renders a file preview formatted as an MP4 file */
export const FilePreviewMp4 = ({ preview }: FilePreviewComponentProps) => {
  return (
    <video width={"100%"} style={{ objectFit: "contain" }} controls>
      <source src={preview.url} type="video/mp4" />
    </video>
  );
};
