import assert from "assert";
import {
  isAnalysisResultColumn,
  isLinkedMetadataColumn,
} from "components/RecordingsGrid/RecordingsGrid.helpers";
import { RecordingsGridColDef } from "components/RecordingsGrid/RecordingsGrid.types";
import {
  DatasetRecordingsTableColumn,
  DatasetRecordingsTableColumnFieldsFragment,
  Dataset,
  DatasetFieldsFragment,
  DatasetVersion,
  DatasetVersionFieldsFragment,
  File as DrsFile,
  Metadatum,
  MetadatumFieldsFragment,
  MetadataValue,
  MetadataValueFieldsFragment,
  ProjectFieldsFragment,
  FileRecordingGroup as RecordingGroup,
  RecordingGroupFileFieldsFragment,
  usePageDatasetQuery,
} from "graphql/_Types";
import { cloneDeep, keyBy } from "lodash";
import { useMemo } from "react";
import { IdeasQueryState, NullishDataError } from "types/IdeasQueryState";
import { isNonNull } from "utils/isNonNull";
import { isDefined } from "utils/isDefined";
import { isNonNullish } from "utils/isNonNullish";
import { isNullish } from "utils/isNullish";
import { captureException } from "@sentry/react";
import { useProjectDataContext } from "../ProjectDataProvider";

type DatasetRecordingsTableQueryData = NonNullable<
  NonNullable<
    NonNullable<ReturnType<typeof usePageDatasetQuery>["data"]>["dataset"]
  >["datasetRecordingsTable"]
>;

type TFormattedRecordingGroup = Pick<
  DatasetRecordingsTableQueryData["fileRecordingGroups"]["nodes"][number],
  "id" | "dateCreated"
> & {
  number: string;
  recordingGroupFiles: {
    nodes: RecordingGroupFileFieldsFragment[];
  };
  metadata: {
    nodes: (MetadatumFieldsFragment & {
      value: MetadataValueFieldsFragment["value"] | undefined;
    })[];
  };
};

type TDatasetRecordingsTable = Pick<
  DatasetRecordingsTableQueryData,
  "id" | "name" | "views"
> & {
  datasetRecordingsTableColumns: {
    nodes: (DatasetRecordingsTableColumnFieldsFragment & {
      colDef: RecordingsGridColDef;
      colDefMetadatumId: Metadatum["id"];
      order: number;
    })[];
  };
  recordingGroups: { nodes: TFormattedRecordingGroup[] };
};

export type TDatasetMetadata = Array<{
  metadatum:
    | (Pick<Metadatum, "id" | "key"> & {
        values: {
          nodes: Array<Pick<MetadataValue, "value">>;
        };
      })
    | null;
}>;

export type TDatasetMode =
  | { type: "current" }
  | ({
      type: "version";
      date: Date;
    } & Pick<
      DatasetVersionFieldsFragment,
      "name" | "validationState" | "views"
    >)
  | { type: "history"; date: Date };

export type TMetadataByFileId = Record<
  DrsFile["id"],
  Record<Metadatum["key"], Pick<MetadataValue, "value">>
>;

export type TRecordingsById = Record<
  RecordingGroup["id"],
  {
    number: string;
    dateCreated: RecordingGroup["dateCreated"];
  }
>;

interface UsePageDatasetDataProps {
  cutoffTime: string | undefined;
  datasetId: Dataset["id"];
  datasetVersionId: DatasetVersion["id"] | undefined;
}

export const usePageDatasetData = ({
  cutoffTime,
  datasetId,
  datasetVersionId,
}: UsePageDatasetDataProps): IdeasQueryState<{
  columnsById: Record<
    DatasetRecordingsTableColumn["id"],
    {
      id: DatasetRecordingsTableColumn["id"];
      colDefMetadatumId: Metadatum["id"];
      colDef: RecordingsGridColDef;
      identifierPosition: DatasetRecordingsTableColumn["identifierPosition"];
      pinned: DatasetRecordingsTableColumn["pinned"];
      order: number;
      width: DatasetRecordingsTableColumn["width"];
    }
  >;

  dataset: DatasetFieldsFragment & {
    description: Pick<Metadatum, "id"> & {
      value: string;
    };
  };
  datasetMode: TDatasetMode;
  datasetVersions: DatasetVersionFieldsFragment[];
  metadata: TDatasetMetadata;
  project: ProjectFieldsFragment;
  recordingsTable: TDatasetRecordingsTable;
  recordingsById: TRecordingsById;
  datasetExportName: string;
}> => {
  const { analysisTableGroups } = useProjectDataContext();
  const { loading, data, error } = usePageDatasetQuery({
    variables: { cutoffTime, datasetId },
    onError: (err) => captureException(err),
  });

  const dataset = useMemo(() => {
    const dataset = cloneDeep(data?.dataset);
    if (isNullish(dataset)) {
      return;
    }
    const description = dataset.description;
    const descriptionValue = description?.metadataValuesByMetadataId.nodes[0]
      .value as string | undefined;
    assert(
      isNonNullish(description) && descriptionValue !== undefined,
      "Expected dataset description metadatum and value to be non nullish",
    );
    const formattedDescription = {
      id: description.id,
      value: descriptionValue,
    };
    return {
      ...dataset,
      description: formattedDescription,
    };
  }, [data?.dataset]);

  // format columns
  const recordingsTable = useMemo(() => {
    if (isNullish(dataset) || isNullish(dataset?.datasetRecordingsTable)) {
      return;
    }

    const recordingsTable = dataset.datasetRecordingsTable;
    if (isNullish(recordingsTable)) {
      return;
    }

    const formattedTable: TDatasetRecordingsTable = {
      ...recordingsTable,
      datasetRecordingsTableColumns: {
        ...recordingsTable.datasetRecordingsTableColumns,
        nodes: recordingsTable.datasetRecordingsTableColumns.nodes
          .map((column) => {
            const order = column.activeOrder;
            assert(
              isNonNull(order),
              "Expected order to be defined on recordings table column",
            );

            return {
              ...column,
              colDef: column.activeColDef as RecordingsGridColDef,
              colDefMetadatumId: column.colDefId,
              order,
            };
          })
          .sort((a, b) => a.order - b.order),
      },
      recordingGroups: {
        ...recordingsTable.fileRecordingGroups,
        nodes: recordingsTable.fileRecordingGroups.nodes.map((recording) => {
          assert(
            recording.number !== null,
            'Expected "number" to be defined for recording',
          );
          return {
            ...recording,
            number: recording.number,
            metadata: {
              __typename: "RecordingGroupMetadataConnection" as const,
              nodes: recording.metadata.nodes.map(({ metadatum }) => {
                assert(
                  isNonNull(metadatum),
                  "Expected metadatum to be non nullish on recordingGroupMetadatum",
                );
                return {
                  ...metadatum,
                  value: metadatum.values.nodes[0]?.value as string,
                };
              }),
            },
          };
        }),
      },
    };

    // TODO: Remove these filters when https://inscopix.atlassian.net/browse/ID-2411 completed
    // filter out results columns pointed to deleted analysis table columns
    const analysisTables = analysisTableGroups.flatMap((g) => g.analysisTables);
    formattedTable.datasetRecordingsTableColumns.nodes =
      formattedTable.datasetRecordingsTableColumns.nodes.filter((col) => {
        if (isAnalysisResultColumn(col)) {
          const analysisTableId = col.colDef.analysisTableId;
          const analysisTableExists = analysisTables.some(
            ({ id }) => id === analysisTableId,
          );
          return analysisTableExists;
        }
        return true;
      });

    // filter out metadata columns pointed to deleted analysis result columns
    formattedTable.datasetRecordingsTableColumns.nodes =
      formattedTable.datasetRecordingsTableColumns.nodes.filter((col) => {
        if (isLinkedMetadataColumn(col)) {
          const linkedColId = col.colDef.drsFileColumnId;
          return formattedTable.datasetRecordingsTableColumns.nodes.some(
            ({ id }) => id === linkedColId,
          );
        }
        return true;
      });
    return formattedTable;
  }, [analysisTableGroups, dataset]);

  const recordingsById = useMemo(() => {
    const recordings =
      dataset?.datasetRecordingsTable?.fileRecordingGroups.nodes.map(
        ({ id, number, dateCreated }) => {
          assert(
            number !== null,
            'Expected "number" to be defined for recording',
          );
          return {
            id,
            number,
            dateCreated,
          };
        },
      );
    return keyBy(recordings, ({ id }) => id);
  }, [dataset?.datasetRecordingsTable?.fileRecordingGroups.nodes]);

  if (loading) {
    return { loading: true, data: undefined, error: undefined };
  }

  if (error) {
    return { loading: false, data: undefined, error: error };
  }

  const project = dataset?.project;

  if (
    data === undefined ||
    isNullish(dataset) ||
    isNullish(recordingsTable) ||
    isNullish(project) ||
    recordingsById === undefined
  ) {
    return { loading: false, data: undefined, error: new NullishDataError() };
  }

  const metadata = dataset.metadata.nodes;

  const columns = recordingsTable.datasetRecordingsTableColumns.nodes;
  const columnsById = keyBy(columns, ({ id }) => id);

  const currentDatasetVersion = dataset.datasetVersions.nodes.find(
    ({ id }) => id === datasetVersionId,
  );

  const datasetMode: TDatasetMode = (() => {
    if (isDefined(cutoffTime)) {
      if (isDefined(datasetVersionId)) {
        assert(
          isDefined(currentDatasetVersion),
          "Expected currentDatasetVersion to be defined",
        );
        // TODO https://inscopix.atlassian.net/browse/ID-1322
        // TODO https://inscopix.atlassian.net/browse/ID-1596
        const { dateCreated, name, validationState, views } =
          currentDatasetVersion;

        return {
          type: "version",
          date: new Date(dateCreated),
          name,
          validationState,
          views,
        };
      }
      return { type: "history", date: new Date(cutoffTime) };
    }
    return { type: "current" };
  })();

  const datasetExportName = (() => {
    if (datasetMode.type === "current") {
      return `${dataset.name}`;
    } else if (datasetMode.type === "history") {
      return `${dataset.name}-${datasetMode.date.toISOString()}`;
    } else if (datasetMode.type === "version") {
      return `${dataset.name}-${
        datasetMode.name !== null
          ? datasetMode.name
          : datasetMode.date.toISOString()
      }`;
    } else return "dataset";
  })();

  return {
    loading,
    data: {
      columnsById,
      dataset,
      datasetVersions: dataset.datasetVersions.nodes,
      metadata,
      project,
      recordingsTable,
      recordingsById,
      datasetMode,
      datasetExportName,
    },
    error: undefined,
  };
};
