import {
  EuiCheckbox,
  EuiConfirmModal,
  EuiConfirmModalProps,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { SetRequired } from "type-fest";
import { isDefined } from "utils/isDefined";

/**
 * A confirmation Modal for a destructive action
 */

export type ModalConfirmDestructiveActionProps = SetRequired<
  EuiConfirmModalProps,
  "onConfirm"
> & {
  confirmCheckboxPrompt?: string;
} & { children?: never; body?: React.ReactNode };

export const ModalConfirmDestructiveAction = ({
  confirmCheckboxPrompt,
  body,
  ...rest
}: ModalConfirmDestructiveActionProps) => {
  const [confirmChecked, setConfirmChecked] = useState(false);
  return (
    <EuiConfirmModal
      title="Are you sure you wish to continue?"
      cancelButtonText="Cancel"
      confirmButtonText="Confirm"
      {...rest}
      // cannot be overwritten by props
      buttonColor="danger"
      defaultFocusedButton="cancel"
      confirmButtonDisabled={
        isDefined(confirmCheckboxPrompt) && !confirmChecked
      }
    >
      {body ?? <p>This action cannot be undone</p>}

      {isDefined(confirmCheckboxPrompt) && (
        <EuiCheckbox
          id={htmlIdGenerator()()}
          checked={confirmChecked}
          onChange={(e) => setConfirmChecked(e.target.checked)}
          label={confirmCheckboxPrompt}
        />
      )}
    </EuiConfirmModal>
  );
};
