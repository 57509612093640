import { isNull, isUndefined } from "lodash";

/**
 * Type guard to check if a value is `null` or `undefined`
 */
export const isNullish = <T>(
  value: T | null | undefined,
): value is null | undefined => {
  return isNull(value) || isUndefined(value);
};
