/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiBreadcrumbs,
  EuiButtonEmpty,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPanel,
  EuiText,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { useRoutes } from "hooks/useRoutes";
import moment from "moment";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { DatasetActionsQueueStatusIndicator } from "./DatasetActionQueueStatus";
import { DatasetMenuBar } from "./DatasetMenuBar";
import { Helmet } from "react-helmet-async";
import { formatDate } from "utils/formatDate";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

export const DatasetHeader = () => {
  const { routeMap } = useRoutes();
  const { project } = useProjectDataContext();
  const { datasetMode, dataset } = useDatasetDataContext();
  const currentTenant = useTenantContext((s) => s.currentTenant);

  return (
    <div>
      <Helmet>
        <title>
          {`${project.name}: ${dataset.name} ${
            datasetMode.type === "version"
              ? datasetMode.name !== null
                ? " - " + datasetMode.name
                : " - " + formatDate(datasetMode.date)
              : ""
          }`}
        </title>
      </Helmet>
      <EuiPageHeader bottomBorder={false} paddingSize="m">
        <EuiPageHeaderSection>
          <EuiBreadcrumbs
            data-test-subj="back-to-project"
            truncate={false}
            aria-label="breadcrumbs"
            breadcrumbs={[
              {
                text: (
                  <>
                    <EuiIcon size="s" type="arrowLeft" />
                    {project.name}
                  </>
                ),
                "aria-current": false,
                href: routeMap["PROJECT"].dynamicPath({
                  tenantKey: currentTenant.key,
                  projectKey: project.key,
                }).path,
                onClick: (e) => {
                  e.preventDefault();
                  routeMap["PROJECT"]
                    .dynamicPath({
                      tenantKey: currentTenant.key,
                      projectKey: project.key,
                    })
                    .navigateTo();
                },
              },
            ]}
          />
          <EuiTitle size="m">
            <h1 data-test-subj="dataset-header-title">
              {datasetMode.type === "current" && dataset.name}
              {datasetMode.type === "version" &&
                (datasetMode.name ?? dataset.name)}
              &nbsp;
              {datasetMode.type !== "current" && (
                <>
                  <br />
                  <EuiText
                    data-test-subj="dataset-version-date"
                    color="warning"
                    size="s"
                  >
                    <EuiIcon type="clock" /> Read-only History of &nbsp;
                    <EuiLink
                      color="warning"
                      to={
                        routeMap["DATASET"].dynamicPath({
                          tenantKey: currentTenant.key,
                          datasetId: dataset.id,
                          projectKey: project.key,
                        }).path
                      }
                    >
                      {dataset.name}
                    </EuiLink>{" "}
                    (
                    {moment(datasetMode.date).format("MMMM Do YYYY, h:mm:ss a")}
                    )
                    <EuiButtonEmpty
                      data-test-subj="return-to-current-version"
                      iconType="timeRefresh"
                      size="s"
                      onClick={() =>
                        routeMap["DATASET"]
                          .dynamicPath({
                            tenantKey: currentTenant.key,
                            datasetId: dataset.id,
                            projectKey: project.key,
                          })
                          .navigateTo()
                      }
                    >
                      Return to Current Version
                    </EuiButtonEmpty>
                  </EuiText>
                </>
              )}
              {datasetMode.type === "current" && (
                <DatasetActionsQueueStatusIndicator />
              )}
            </h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiHorizontalRule margin="none" />
      <EuiPanel
        hasShadow={false}
        css={css({ paddingTop: "2px", paddingBottom: "2px" })}
      >
        <DatasetMenuBar />
      </EuiPanel>
    </div>
  );
};
