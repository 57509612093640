/** @jsxImportSource @emotion/react */
import {
  EuiPopover,
  EuiContextMenuPanelDescriptor,
  EuiContextMenu,
  htmlIdGenerator,
  EuiPanel,
  EuiText,
  EuiEmptyPrompt,
  EuiLoadingChart,
} from "@inscopix/ideas-eui";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { AutofillQuery, Dataset, useAutofillQuery } from "graphql/_Types";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useMemo, useState } from "react";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { isUndefined, set } from "lodash";
import { ToolParamsGridRowDatum } from "../ToolParamsGrid.types";
import { isFileFilterMatch } from "../ToolParamsGridProvider.helpers";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { isToolPathParam } from "../ToolParamsGrid.helpers";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { addToast } from "components/GlobalToastList/GlobalToastList";
import assert from "assert";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";
import { css } from "@emotion/react";

interface AutofillButtonProps {
  rowDatum: Pick<ToolParamsGridRowDatum, "id">;
}

export const AutofillFromRecording = ({ rowDatum }: AutofillButtonProps) => {
  const { project } = useProjectDataContext();
  const { toolSpec } = useToolParamsGridContext();

  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );

  const files = useProjectFilesStore((s) => s.files);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const toolPathParams = toolSpec.params
    .filter(isToolPathParam)
    .map((param) => ({ key: param.key, type: param.type }));

  const { data, loading, error } = useAutofillQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      projectId: project.id,
    },
  });

  /**
   * BUILDING THE POPOVER CONTENT
   * This constructs the content to show in the popover - a loading prompt, error prompt, or context menu
   * to select a recording from a dataset
   */
  const popoverContent = useMemo(() => {
    const datasetNodes = data?.project?.datasets.nodes;
    // the query variable (projectId) can't change within this component, so we can safely check if data
    // is defined to skip loading if the cache is populated
    if (loading && isUndefined(datasetNodes)) {
      return (
        <EuiEmptyPrompt
          icon={<EuiLoadingChart size="l" mono />}
          body={<p>Loading datasets...</p>}
        />
      );
    } else if (error || isUndefined(datasetNodes)) {
      return (
        <EuiEmptyPrompt
          color="danger"
          body={<p>There was an error fetching this project&apos;s datasets</p>}
        />
      );
    }

    /**
     * Checks a giving recording in a dataset for single matching files for this row's input params
     * and saves any matches to the row. If soem or all inputs are not matched a toast will inform the user.
     * @param datasetId
     * @param recordingsTable a recordings table query result with recording groups
     * @returns {children, hasMatch} a list of recording column selectors with children, as well as a boolean to indicate if any files match the file filters
     */
    const autofillParamsFromRecording = (
      datasetId: Dataset["id"],
      selectedRecordingId: string,
    ) => {
      const updatedRowDatumAttrs: Partial<ToolParamsGridRowDatum> = {
        datasets: [datasetId],
        recordings: [selectedRecordingId],
        params: {},
      };
      let matches = 0;
      toolPathParams.forEach((param) => {
        const fileFilters = param.type.file_filters;

        // Skip auto-population for params with no file filters
        if (fileFilters === undefined) {
          return;
        }

        const matchingDrsFiles = files
          // Remove files belonging to other recordings
          .filter((file) => {
            const { recordings } = file;
            return recordings.some(({ id }) => id === selectedRecordingId);
          })
          /* Remove files that aren't visible in a column. Analysis results
               assigned back to datasets will belong to recordings even if no
               analysis result columns display them. This presents a confusing
               UX where auto-population does not work because there are multiple
               files assigned to a recording that match the file filters even
               though the user can't see them in the recordings table. */
          .filter((file) => file.columns.length > 0)
          // Remove files that don't match any of the file filters
          .filter((file) =>
            fileFilters.some((filter) => isFileFilterMatch(file, filter)),
          );

        if (matchingDrsFiles.length === 1) {
          const matchingFile = matchingDrsFiles[0];
          set(
            updatedRowDatumAttrs,
            ["params", param.key],
            matchingFile.isSeries
              ? matchingFile.seriesFiles.map(({ id }) => id)
              : [matchingFile.id],
          );
          matches++;
        } else {
          set(updatedRowDatumAttrs, ["params", param.key], undefined);
        }
      });
      updateRowDatum(rowDatum.id, updatedRowDatumAttrs);
      setIsPopoverOpen(false);
      if (matches === 0) {
        addToast({
          id: htmlIdGenerator()(),
          title: "Failed to autofill",
          color: "danger",
          text: <p>Unable to find a single matching file for any input</p>,
        });
      } else if (matches !== toolPathParams.length) {
        addToast({
          id: htmlIdGenerator()(),
          title: "Partial autofill",
          color: "warning",
          text: <p>Unable to find a single matching file for all inputs</p>,
        });
      }
    };

    /**
     * Builds a list of recording context panels for a given recordings table
     * @param datasetId
     * @param recordingsTable a recordings table query result with recording groups
     * @returns {children, hasMatch} a list of recording column selectors with children, as well as a boolean to indicate if any files match the file filters
     */
    const getRecordingPanelContentFromDataset = (
      datasetId: Dataset["id"],
      recordingsTable: NonNullable<
        AutofillQuery["project"]
      >["datasets"]["nodes"][number]["recordingsTable"],
    ) => {
      assert(
        recordingsTable !== null,
        `Dataset ${datasetId} is missing a recordings table`,
      );
      if (recordingsTable.recordingGroups.nodes.length === 0) {
        return {
          content: (
            <EuiPanel>
              <EuiText color="subdued" textAlign="center" size={"s"}>
                No recordings found in dataset
              </EuiText>
            </EuiPanel>
          ),
        };
      } else {
        return {
          // mapping over all recording nodes, we build a selector for each
          items: recordingsTable.recordingGroups.nodes.map((recordingGroup) => {
            return {
              name: (
                <RecordingIdentifierBadge recordingId={recordingGroup.id} />
              ),
              onClick: () =>
                autofillParamsFromRecording(datasetId, recordingGroup.id),
            };
          }),
        };
      }
    };

    /**
     * Build a list of recording and dataset panels
     */
    const recordingPanels: EuiContextMenuPanelDescriptor[] = [];
    const datasetPanelContent = (() => {
      if (datasetNodes.length === 0) {
        return {
          content: (
            <EuiPanel>
              <EuiText color="subdued" textAlign="center" size={"s"}>
                No datasets found in project
              </EuiText>
            </EuiPanel>
          ),
        };
      } else {
        return {
          items: datasetNodes.map(({ id, name, recordingsTable }) => {
            recordingPanels.push({
              id,
              title: name,
              initialFocusedItemIndex: 0,
              ...getRecordingPanelContentFromDataset(id, recordingsTable),
            });
            return {
              name,
              panel: id,
            };
          }),
        };
      }
    })();
    const datasetPanel: EuiContextMenuPanelDescriptor = {
      id: "datasets",
      title: "Select Dataset",
      initialFocusedItemIndex: 0,
      ...datasetPanelContent,
    };

    // if only one dataset exists/only one recordings panel available, skip the dataset panel
    const initialPanel =
      recordingPanels.length === 1 ? recordingPanels[0].id : "datasets";

    return (
      <EuiContextMenu
        initialPanelId={initialPanel}
        panels={[datasetPanel, ...recordingPanels]}
        css={css`
          max-height: min(50vh, 400px);
          overflow-y: scroll;
        `}
      />
    );
  }, [
    data,
    loading,
    error,
    files,
    rowDatum.id,
    toolPathParams,
    updateRowDatum,
  ]);

  return (
    <EuiPopover
      panelPaddingSize="none"
      button={
        <ButtonIconPermissioned
          defaultTooltip="Autofill from recording"
          iconType="sparkles"
          requiredPermission="edit"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          aria-label="Autofill from recording"
        />
      }
      isOpen={isPopoverOpen}
      closePopover={() => setIsPopoverOpen(false)}
    >
      {popoverContent}
    </EuiPopover>
  );
};
