/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useRef, useState } from "react";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFieldText,
  EuiFlexGroup,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiToolTip,
} from "@inscopix/ideas-eui";
import { useDatasetDataContext } from "../../pages/project/dataset/DatasetDataProvider";
import { isDefined } from "../../utils/isDefined";
import { metadataMap, UNSELECTABLE_METADATA_KEYS } from "types/MetadataMap";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import {
  isAnalysisResultColumn,
  isDrsFileColumn,
} from "components/RecordingsGrid/RecordingsGrid.helpers";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { captureException } from "@sentry/react";
import { useMenuInsertColumnStyles } from "./useMenuInsertColumnStyles";
import { isUndefined } from "lodash";

type PanelInsertLinkedMetadataColumnProps = {
  onClose: () => void;
};

export const PanelInsertLinkedMetadataColumn = ({
  onClose,
}: PanelInsertLinkedMetadataColumnProps) => {
  const { analysisResultsByTableId } = useProjectDataContext();
  const { columnsById } = useDatasetDataContext();
  const createColumnAction = useDatasetAction("createColumn");
  const { panelStyles } = useMenuInsertColumnStyles();

  const [columnName, setColumnName] = useState("");
  const [selectedDataColumnOption, setSelectedDataColumnOption] =
    useState<EuiComboBoxOptionOption<string>>();
  const [selectedMetadataOption, setSelectedMetadataOption] =
    useState<EuiComboBoxOptionOption<string>>();

  // auto focus the column selection input when loaded
  const setDataColumnRef: React.RefCallback<HTMLInputElement> = useCallback(
    (node) => {
      // timeout needed to get around EUI focusing the context menu title
      setTimeout(() => {
        node?.focus();
      }, 500);
    },
    [],
  );
  const metadataKeyRef = useRef<HTMLInputElement | null>(null);
  const setMetadataKeyRef: React.RefCallback<HTMLInputElement> = useCallback(
    (node) => {
      metadataKeyRef.current = node;
    },
    [],
  );
  const nameRef = useRef<HTMLInputElement | null>(null);
  const setNameRef: React.RefCallback<HTMLInputElement> = useCallback(
    (node) => {
      nameRef.current = node;
    },
    [],
  );

  const noColumnName = columnName === "";
  const noDataColumnSelection = isUndefined(selectedDataColumnOption);
  const noMetadataSelection = isUndefined(selectedMetadataOption);
  const isSubmitDisabled =
    noColumnName || noDataColumnSelection || noMetadataSelection;
  const submitButtonTooltip = (() => {
    if (noColumnName) return "Enter a column name";
    else if (noDataColumnSelection) return "Select a data column";
    else if (noMetadataSelection) return "Select metadata";
  })();

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (
      !noDataColumnSelection &&
      isDefined(selectedDataColumnOption.key) &&
      !noMetadataSelection &&
      isDefined(selectedMetadataOption.key)
    ) {
      void createColumnAction.enqueue({
        colDef: {
          type: "linkedMetadata",
          headerName: columnName.trim(),
          metadataKey: selectedMetadataOption.key,
          drsFileColumnId: selectedDataColumnOption.key,
        },
      });
    } else {
      throw new Error(
        "Invalid parameters read for new metadata column submission",
      );
    }
    onClose();
  };

  const drsFileColumnDefs: EuiComboBoxOptionOption<string>[] = useMemo(() => {
    const columnDefs: EuiComboBoxOptionOption<string>[] = [];
    for (const column of Object.values(columnsById)) {
      if (isDrsFileColumn(column)) {
        // We don't support linked metadata for unmapped columns
        const isDisabled =
          metadataMap[FILE_TYPES_BY_ID[column.colDef.fileType].key] ===
          undefined;
        columnDefs.push({
          label: column.colDef.headerName,
          value: column.id,
          key: column.id,
          disabled: isDisabled,
        });
      } else if (isAnalysisResultColumn(column)) {
        const { analysisTableId, resultKey } = column.colDef;
        const analysisResult =
          analysisResultsByTableId[analysisTableId][resultKey];

        const metadata = metadataMap[analysisResult.file_type];
        // We don't support linked metadata for unmapped columns
        const isDisabled = metadata === undefined;

        columnDefs.push({
          label: analysisResult.result_name,
          value: column.id,
          key: column.id,
          disabled: isDisabled,
        });
      }
    }
    return columnDefs;
  }, [analysisResultsByTableId, columnsById]);

  const metadataOptions: EuiComboBoxOptionOption<string>[] = (() => {
    const metadataOptions: EuiComboBoxOptionOption<string>[] = [];
    if (!noDataColumnSelection) {
      const id = selectedDataColumnOption.key;
      if (isDefined(id) && isDefined(columnsById[id])) {
        const column = columnsById[id];
        if (isDrsFileColumn(column)) {
          const fileType = column.colDef.fileType;
          const fileTypeKey = FILE_TYPES_BY_ID[fileType].key;
          const mappedMetadata = metadataMap[fileTypeKey];

          // this is checked upstream in drsFileColumnDefs and should never be reached
          if (mappedMetadata === undefined) {
            captureException(
              "Failed to parse metadata options for drs file column",
            );
            return [];
          }
          for (const [key, value] of Object.entries(mappedMetadata)) {
            // @TODO ID-2041 remove filter once DLC movies have their own type
            if (!UNSELECTABLE_METADATA_KEYS.includes(key)) {
              metadataOptions.push({
                label: value,
                key: key,
              });
            }
          }
        } else if (isAnalysisResultColumn(column)) {
          const { analysisTableId, resultKey } = column.colDef;
          const analysisResult =
            analysisResultsByTableId[analysisTableId][resultKey];
          const mappedMetadata = metadataMap[analysisResult.file_type];

          // this is checked upstream in drsFileColumnDefs and should never be reached
          if (mappedMetadata === undefined) {
            captureException(
              "Failed to parse metadata options for drs file column",
            );
            return [];
          }

          for (const [key, value] of Object.entries(mappedMetadata)) {
            // @TODO ID-2041 remove filter once DLC movies have their own type
            if (!UNSELECTABLE_METADATA_KEYS.includes(key)) {
              metadataOptions.push({
                label: value,
                key: key,
              });
            }
          }
        }
      }
    }
    return metadataOptions;
  })();

  const onChangeColumn = (
    newSelectedOption: EuiComboBoxOptionOption<string> | undefined,
  ) => {
    setSelectedDataColumnOption(newSelectedOption);
    setSelectedMetadataOption(undefined);
    // focus the metadata input after a data column is selected
    if (isDefined(newSelectedOption)) {
      setTimeout(() => {
        metadataKeyRef?.current?.focus();
      }, 100);
    }
  };

  const onChangeMetadata = (
    newSelectedOption: EuiComboBoxOptionOption<string> | undefined,
  ) => {
    setSelectedMetadataOption(newSelectedOption);
    if (columnName === "" || columnName === selectedMetadataOption?.label) {
      setColumnName(newSelectedOption?.label ?? "");
    }
    // focus the column name input after metadata is selected
    if (isDefined(newSelectedOption)) {
      setTimeout(() => {
        nameRef?.current?.focus();
      }, 100);
    }
  };

  return (
    <EuiForm component="form" role="form" css={panelStyles}>
      <EuiFormRow
        label="Select data column"
        helpText={
          <>
            <p>
              Choose a data column to provide the value for this metadata
              column.
            </p>
          </>
        }
        display="rowCompressed"
      >
        <EuiComboBox<string>
          placeholder="Select a single column"
          singleSelection={{ asPlainText: true }}
          options={drsFileColumnDefs}
          selectedOptions={
            selectedDataColumnOption ? [selectedDataColumnOption] : []
          }
          onChange={(newSelectedOptions) =>
            onChangeColumn(newSelectedOptions[0])
          }
          compressed
          inputRef={setDataColumnRef}
        />
      </EuiFormRow>
      <EuiFormRow
        label="Select metadata"
        helpText={
          <>
            <p>Choose the metadata value you wish to display.</p>
          </>
        }
        display="rowCompressed"
      >
        <EuiComboBox<string>
          placeholder="Select a metadata value"
          singleSelection={{ asPlainText: true }}
          options={metadataOptions}
          selectedOptions={
            selectedMetadataOption ? [selectedMetadataOption] : []
          }
          onChange={(newSelectedOptions) =>
            onChangeMetadata(newSelectedOptions[0])
          }
          isDisabled={noDataColumnSelection}
          compressed
          inputRef={setMetadataKeyRef}
        />
      </EuiFormRow>
      <EuiFormRow label="Column Name" display="rowCompressed">
        <EuiFieldText
          name="columnName"
          value={columnName}
          onChange={(e) => {
            const maxLength = 1024;
            const columnName = e.target.value.substring(0, maxLength);
            setColumnName(columnName);
          }}
          placeholder="e.g. Subject ID"
          compressed
          inputRef={setNameRef}
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiFlexGroup justifyContent="flexEnd">
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiToolTip content={submitButtonTooltip}>
          <EuiButton
            type="submit"
            onClick={handleSubmit}
            fill
            disabled={isSubmitDisabled}
          >
            Add Column
          </EuiButton>
        </EuiToolTip>
      </EuiFlexGroup>
    </EuiForm>
  );
};
