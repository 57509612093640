import AppLoading from "components/AppLoading/AppLoading";
import { ReactNode, useEffect, useState } from "react";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { logOut } from "utils/logOut";
import { BackendStatus, useGetBackendStatus } from "./useGetBackendStatus";

interface BackendStatusManagerProps {
  children: ReactNode;
}

/**
 * Checks the backend health endpoint for an ok status.
 *
 * NOTE: this provides critical functionality for SSO support. If an SSO provider authenticates a
 * user that does not yet exist in the database, Django will create one - however, the first request
 * the frontend makes with the user's token must be to a Django REST endpoint (not GraphQL) as it is
 * the Django authentication layer that creates the user. This component must be mounted before the
 * UserProvider (as the user will not have tenant access until Django has finished the request) - it
 * makes a request to the Django system health endpoint, which will trigger user creation and will not
 * return a response until it is completed.
 * see:
 *  https://inscopix.atlassian.net/browse/ID-2846
 *  https://inscopix.atlassian.net/browse/ID-2863
 *  https://inscopix.atlassian.net/wiki/spaces/~800856445/pages/2990440449/Okta+integration+discovery+phase
 */
export const BackendStatusManager = ({
  children,
}: BackendStatusManagerProps) => {
  const [backendStatus, setBackendStatus] = useState<BackendStatus>();
  const { getBackendStatus } = useGetBackendStatus();

  useEffect(() => {
    // Initialize status when provider mounts
    if (backendStatus === undefined) {
      void getBackendStatus().then((status) => setBackendStatus(status));
    }
  }, [backendStatus, getBackendStatus]);

  // Prevent children from rendering until status is confirmed
  if (backendStatus === undefined) {
    return <AppLoading />;
  }

  if (backendStatus.status === "error") {
    return (
      <CallOutError>
        <EuiText>
          An unexpected response was received when connecting to the IDEAS
          database. Please wait a moment and retry, or contact support if the
          issue persists.
        </EuiText>
        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton
              fill
              size="s"
              color="primary"
              onClick={() => {
                setBackendStatus(undefined);
              }}
            >
              Retry
            </EuiButton>
          </EuiFlexItem>
          {/* if we don't provide a logout here, a user could become trapped (ie. if SSO user creation fails) */}
          <EuiFlexItem grow={false}>
            <EuiButton
              size="s"
              color="primary"
              onClick={() => {
                void logOut();
              }}
            >
              Logout
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </CallOutError>
    );
  }

  return <>{children}</>;
};
