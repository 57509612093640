import {
  EuiSearchBar,
  EuiSearchBarProps,
  Query,
  SearchFilterConfig,
} from "@inscopix/ideas-eui";
import { TENANT_MEMBERSHIP_ROLES } from "types/TenantMembershipRole";

interface PanelTenantAccessSearchBarProps {
  query: Query | undefined;
  onChange: EuiSearchBarProps["onChange"];
}

/**
 * Component that renders a search bar for filter the users displayed by
 * `PanelTenantAccess`
 */
export const PanelTenantAccessSearchBar = ({
  query,
  onChange,
}: PanelTenantAccessSearchBarProps) => {
  const schema = {
    strict: true,
    fields: {
      "role.key": {
        type: "string",
      },
    },
  };

  const filters: SearchFilterConfig[] = [
    {
      type: "field_value_selection",
      field: "role.key",
      name: "Role",
      multiSelect: false,
      options: Object.values(TENANT_MEMBERSHIP_ROLES).map((role) => ({
        name: role.name,
        value: role.key,
      })),
    },
  ];

  return (
    <EuiSearchBar
      query={query}
      box={{
        placeholder: "Find a user...",
        incremental: true,
        schema,
      }}
      filters={filters}
      onChange={onChange}
    />
  );
};
