/********************************************
  TOOL RESOURCE REQUIREMENT TYPE DEFINITIONS
 ********************************************/

import { getEnvVar } from "ideas.env";
import { keyBy } from "lodash";

const CPU = {
  id: 1,
  key: "cpu",
  name: "CPU",
  unit: "hours",
  tipsOnLimit: "Please contact Inscopix support to increase your CPU quota.",
  icon: undefined,
  documentation: undefined,
} as const;

const GPU = {
  id: 2,
  key: "gpu",
  name: "GPU",
  unit: "hours",
  tipsOnLimit: "Please contact Inscopix support to increase your GPU quota.",
  icon: undefined,
  documentation: undefined,
} as const;

const MEM = {
  id: 3,
  key: "memory",
  name: "Memory",
  unit: "GiB",
  tipsOnLimit: "Please contact Inscopix support to increase your Memory quota.",
  icon: undefined,
  documentation: undefined,
} as const;

const STORAGE = {
  id: 4,
  key: "storage",
  name: "Storage",
  unit: "GB",
  tipsOnLimit:
    "Delete unused files or move to Archive Storage to free up storage space.",
  icon: "save",
  documentation: undefined,
} as const;

// downloaded
const NETWORK = {
  id: 5,
  key: "network",
  name: "Downloaded Files",
  unit: "GB",
  tipsOnLimit:
    "Please contact Inscopix support to increase your Downloaded Files quota.",
  icon: "download",
  documentation: undefined,
} as const;

const ARCHIVED_STORAGE = {
  id: 6,
  key: "archived_storage",
  name: "Archived Storage",
  unit: "GB",
  tipsOnLimit: "Delete unused files to free up storage space.",
  icon: "storage",
  documentation: undefined,
} as const;

const CONCURRENT_TASKS = {
  id: 7,
  key: "concurrent_tasks",
  name: "Concurrent Tasks",
  unit: "",
  tipsOnLimit:
    "Please contact Inscopix support to increase your Concurrent Tasks quota.",
  icon: undefined,
  documentation: undefined,
} as const;

const CREDITS = {
  id: 8,
  key: "credits",
  name: "Compute Credits",
  unit: "credits",
  tipsOnLimit:
    "Please contact Inscopix support to increase your compute credits quota.",
  icon: "compute",
  documentation: `${getEnvVar(
    "URL_DOCS",
  )}/resource-consumption.html#resource-consumption`,
} as const;

const QUOTA_TYPES = {
  CPU,
  GPU,
  MEM,
  STORAGE,
  NETWORK,
  ARCHIVED_STORAGE,
  CONCURRENT_TASKS,
  CREDITS,
};

/***********
  BASE TYPE
 ***********/

export type QuotaType = (typeof QUOTA_TYPES)[keyof typeof QUOTA_TYPES];

/*******************
  AGGREGATED EXPORTS
 *******************/

export const QUOTA_TYPES_BY_ID = keyBy(
  QUOTA_TYPES,
  (QuotaType) => QuotaType.id,
) as {
  [id in QuotaType["id"]]: QuotaType;
} & {
  [unknown: number]: QuotaType | undefined;
};

export const QUOTA_TYPES_BY_KEY = keyBy(
  QUOTA_TYPES,
  (QuotaType) => QuotaType.key,
) as {
  [key in QuotaType["key"]]: QuotaType;
} & {
  [unknown: string]: QuotaType | undefined;
} & {
  [unknown: number | symbol]: undefined;
};
