import { EuiImage, EuiText } from "@inscopix/ideas-eui";
import Inscopix_logo from "../../assets/Inscopix-logo.svg";

const AppLoading = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <EuiImage
        size="l"
        alt="Inscopix logo"
        aria-label="Inscopix logo"
        url={Inscopix_logo}
      />
      <EuiText size="m">Loading</EuiText>
    </div>
  );
};

export default AppLoading;
