import { UserProjectAccessDjango } from "hooks/useProjectPermission";
import { create } from "zustand";

interface ProjectPermissionStore {
  permissions: UserProjectAccessDjango[] | undefined;
  setPermissions: (permissions: UserProjectAccessDjango[]) => void;
  updatePermission: (updatedPermission: UserProjectAccessDjango) => void;
  removePermission: (id: string) => void;
}

/**
 * A hook for for storing the project permissions of current user
 * @returns The project permissions and setter
 */

export const useProjectPermissionStore = create<ProjectPermissionStore>()((
  set,
) => {
  return {
    permissions: undefined,
    setPermissions: (permissions) => set({ permissions }),
    updatePermission: (updatedPermission) =>
      set((state) => ({
        permissions: state.permissions?.map((permission) =>
          permission.id === updatedPermission.id
            ? updatedPermission
            : permission,
        ),
      })),
    removePermission: (id) =>
      set((state) => ({
        permissions: state.permissions?.filter(
          (permission) => permission.id !== id,
        ),
      })),
  };
});
