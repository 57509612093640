import {
  EuiBasicTable,
  EuiCallOut,
  EuiLoadingChart,
} from "@inscopix/ideas-eui";
import {
  Metadatum,
  MetadataValue,
  useGetFileMetadataByFileIdQuery,
  File as DrsFile,
} from "graphql/_Types";
import { keys, set, unset } from "lodash";
import { captureException } from "@sentry/react";
import ReactJson from "react-json-view";
import { isNonNull } from "utils/isNonNull";
import { JsonValue } from "type-fest";

interface TMetadataNestedKeysAndValues {
  [key: string]: MetadataValue["value"] | TMetadataNestedKeysAndValues;
}

type FlyoutFileInfoBodyMetadataTreeProps = { drsFile: Pick<DrsFile, "id"> };

export const FlyoutFileInfoBodyMetadataTree = ({
  drsFile,
}: FlyoutFileInfoBodyMetadataTreeProps) => {
  const { data, error, loading } = useGetFileMetadataByFileIdQuery({
    fetchPolicy: "no-cache",
    variables: {
      drsFileId: drsFile.id,
    },
    onError: (err) => captureException(err),
  });

  const metadata = data?.drsFile?.metadata.nodes
    .map(({ metadatum }) => metadatum)
    .filter(isNonNull);

  if (error || metadata === undefined) {
    if (loading) {
      return <EuiLoadingChart size="l" mono />;
    }
    // if no data and not loading, then query failure (null connection)
    return (
      <EuiCallOut
        title="Error fetching metadata"
        color="danger"
        iconType="alert"
        size="s"
      />
    );
  }
  const allMetadataByKey = metadata.reduce<Record<Metadatum["key"], JsonValue>>(
    (metadataByKey, { key, activeValue }) => {
      metadataByKey[key] = activeValue;
      return metadataByKey;
    },
    {},
  );

  // create a nested tree with the dot notated keys
  const allMetadataTree: Record<string, TMetadataNestedKeysAndValues> = {};
  keys(allMetadataByKey).forEach(function (key) {
    set(allMetadataTree, key, allMetadataByKey[key]);
  });

  // extract only the metadata in the "ideas" domain from the tree for display
  // we don't want to show any other metadata right now (like IDAS/IDPS metadata under "isx")
  const ideasMetadataTree = allMetadataTree["ideas"] ?? {};
  // remove the dataset information as it is redundant and should not be shown
  unset(ideasMetadataTree, "dataset");

  // if no metadata found, show a message instead of the tree component
  if (Object.keys(ideasMetadataTree).length === 0) {
    // this seeks to replicate the same display behaviour as the table view
    return (
      <EuiBasicTable
        responsive={false}
        items={[]}
        rowHeader="displayName"
        columns={[]}
        compressed
        tableLayout="auto"
      />
    );
  }

  return (
    <ReactJson
      src={ideasMetadataTree}
      name={false}
      collapsed={1} // collapse all but the first level
      displayDataTypes={false}
      sortKeys={true}
      quotesOnKeys={false}
      displayObjectSize={false}
    />
  );
};
