import { EuiButtonProps } from "@inscopix/ideas-eui";
import assert from "assert";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { File as DrsFile } from "graphql/_Types";
import { useUploadFile } from "hooks/useUploadFile";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useState } from "react";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { isNonNull } from "utils/isNonNull";
import { selectSystemFiles } from "utils/selectSystemFiles";
import { uuid } from "utils/uuid";
import { ModalSelectDataset } from "./ModalSelectDataset";

export type ButtonProjectUploadProps = Omit<EuiButtonProps, "onClick">;

export const ButtonProjectUpload = (props: ButtonProjectUploadProps) => {
  const { project } = useProjectDataContext();
  const { writeCache, enqueueUpload } = useUploadFile();
  const [selectedFiles, setSelectedFiles] = useState<File[]>();

  const handleUpload = (files: File[], datasetId: DrsFile["datasetId"]) => {
    files.forEach((file) => {
      const dateCreated = new Date().toISOString();
      const fileId = uuid();
      assert(isNonNull(file), "Expected file to be non null");

      const params = {
        id: fileId,
        dateCreated,
        datasetId: datasetId,
        name: file.name,
        project,
        tenantId: project.tenantId,
      };

      writeCache(params);

      void enqueueUpload({ ...params, blob: file });
    });
    addUtilityToastSuccess(
      `Successfully queued ${files.length} files for upload.`,
    );
  };

  return (
    <>
      <ButtonPermissioned
        onClick={() =>
          void selectSystemFiles(true).then((files) => {
            if (files !== undefined) {
              setSelectedFiles(files);
            }
          })
        }
        {...props}
        requiredPermission="upload"
      >
        Upload
      </ButtonPermissioned>
      {selectedFiles && (
        <ModalSelectDataset
          onSelectDataset={(datasetId) => {
            handleUpload(selectedFiles, datasetId);
            setSelectedFiles(undefined);
          }}
          onCancel={() => setSelectedFiles(undefined)}
        />
      )}
    </>
  );
};
