import { useState } from "react";
import { EuiConfirmModal, EuiSpacer, EuiText } from "@inscopix/ideas-eui";
import { File as DrsFile, useGetDrsFilesLazyQuery } from "graphql/_Types";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { useDrsFileSeriesRevertCreateDjango } from "hooks/useDrsFileSeriesRevertCreate";
import { captureException } from "@sentry/react";
import { cloneDeep, isUndefined } from "lodash";
import { updateCacheFragment, writeCacheFragment } from "utils/cache-fragments";
import { useActionQueueStore } from "stores/ActionsQueue/ActionsQueueManager";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";

type TDrsFile = Pick<
  DrsFile,
  "id" | "status" | "datasetId" | "name" | "isSeries"
>;
export type ModalBreakSeriesProps = {
  drsFiles: TDrsFile[];
  onClose: () => void;
  onComplete?: ({
    successfulFiles,
    errors,
  }: {
    successfulFiles: Pick<DrsFile, "id">[];
    errors: Error[];
  }) => void;
};

export const ModalBreakSeries = ({
  drsFiles,
  onClose,
  onComplete,
}: ModalBreakSeriesProps) => {
  drsFiles.forEach(({ isSeries }) => {
    assert(isSeries, `Expected drsFile to be series.`);
  });

  const { drsFileSeriesRevertCreate } = useDrsFileSeriesRevertCreateDjango();
  const [getDrsFiles] = useGetDrsFilesLazyQuery();
  const { project } = useProjectDataContext();

  const status = useActionQueueStore((s) => s.status);
  const [isLoading, setIsLoading] = useState(false);

  const revertSeries = async () => {
    const requests = drsFiles.map(({ id }) =>
      (async () => {
        try {
          return {
            consumedFileId: id,
            result: await drsFileSeriesRevertCreate(id),
          };
        } catch (err) {
          captureException(err);
          return new Error(`Failed to break series for file ${id}`);
        }
      })(),
    );

    /**
     * Revert series
     */
    const revertSeriesResults = await Promise.all(requests);
    const successfullyRevertedFiles = revertSeriesResults.filter(
      (
        result,
      ): result is {
        // consumed file needs to be removed from cache and resolved in onComplete to be deselected
        consumedFileId: DrsFile["id"];
        result: Awaited<ReturnType<typeof drsFileSeriesRevertCreate>>;
      } => !(result instanceof Error),
    );
    const successfullyConsumedDrsFiles = successfullyRevertedFiles.map(
      ({ consumedFileId }) => ({ id: consumedFileId }),
    );

    const errors = revertSeriesResults.filter(
      function (result): result is Error {
        return result instanceof Error;
      },
    );

    /**
     * Retrieve file info so we can update the cache
     */

    const restoredFileIds = successfullyRevertedFiles.flatMap(
      ({ result }) => result.restored_files,
    );

    const fileQueryResults = (
      await getDrsFiles({
        variables: {
          ids: restoredFileIds,
        },
        onError: (err) => captureException(err),
      })
    ).data?.drsFiles?.nodes;

    /**
     * Update cache
     */

    updateCacheFragment({
      __typename: "Project",
      id: project.id,
      update: (data) => {
        const newData = cloneDeep(data);

        if (newData.activeFiles?.nodes) {
          fileQueryResults?.forEach((drsFile) => {
            newData.activeFiles?.nodes.push(drsFile);
          });

          newData.activeFiles.nodes = newData.activeFiles.nodes.filter(
            ({ id: cachedDrsFileId }) =>
              !successfullyConsumedDrsFiles.some(
                ({ id: consumedFileId }) =>
                  // remove consumed series files from cache
                  consumedFileId === cachedDrsFileId,
              ),
          );
        }

        return newData;
      },
    });

    // write latest fields to cache for restored files
    fileQueryResults?.forEach((file) =>
      writeCacheFragment({
        __typename: "File",
        id: file.id,
        data: { ...file },
      }),
    );

    /**
     * Report errors
     */

    if (
      isUndefined(fileQueryResults) ||
      fileQueryResults.length < restoredFileIds.length
    ) {
      addUtilityToastFailure(
        "Failed to retreive updated file info. You may need to refresh the page to see new files.",
      );
    }

    if (errors.length > 0) {
      addUtilityToastFailure("Failed to break series with one or more files.");
    } else {
      addUtilityToastSuccess("Successfully broke series.");
    }

    if (isDefined(onComplete)) {
      onComplete({
        successfulFiles: successfullyConsumedDrsFiles,
        errors: errors,
      });
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await revertSeries();
    setIsLoading(false);
    onClose();
  };

  return (
    <EuiConfirmModal
      title="Break Series"
      cancelButtonText="Cancel"
      confirmButtonText="Confirm"
      onCancel={onClose}
      onConfirm={() => void onSubmit()}
      buttonColor="warning"
      defaultFocusedButton="cancel"
      confirmButtonDisabled={status !== "synced"}
      isLoading={isLoading}
    >
      <div>
        <EuiText>
          Are you sure you wish to break apart series for the following{" "}
          {drsFiles.length} files(s):
          {drsFiles.map(({ name }) => (
            <p key={name}>{name}</p>
          ))}
        </EuiText>
      </div>
      <EuiSpacer size="l" />
      <EuiText>
        <p>This process cannot be undone.</p>
      </EuiText>
    </EuiConfirmModal>
  );
};
