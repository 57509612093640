import {
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { ICellEditorParams } from "ag-grid-community";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import "./GridEditorToolBooleanParam.scss";
import {
  ToolBooleanParam,
  ToolParamsGridRowDatum,
} from "./ToolParamsGrid.types";

interface GridEditorToolBooleanParamProps extends ICellEditorParams {
  data: ToolParamsGridRowDatum;
  toolParam: ToolBooleanParam;
  value: boolean;
}

/**
 * A component for editing any `ToolBooleanParam` grid cells
 */
export const GridEditorToolBooleanParam = forwardRef(
  function GridEditorToolBooleanParam(
    { stopEditing, value, column, node }: GridEditorToolBooleanParamProps,
    ref,
  ) {
    const [selection, setSelection] = useState<boolean | undefined>(value);

    useImperativeHandle(ref, () => ({
      getValue: () => selection,
    }));

    const items = useMemo(
      () => [
        <EuiListGroupItem
          id="true"
          key="true"
          iconType={selection === true ? "check" : "empty"}
          label="true"
          onClick={() => setSelection(true)}
        />,
        <EuiListGroupItem
          id="false"
          key="false"
          iconType={selection === false ? "check" : "empty"}
          label="false"
          onClick={() => setSelection(false)}
        />,
      ],
      [selection],
    );

    return (
      <EuiPopover
        anchorPosition="downLeft"
        button={
          <div
            style={{
              width: column.getActualWidth(),
              height: node.rowHeight ?? 0,
              display: "flex",
              alignItems: "center",
              fontSize: 14,
            }}
          >
            <span style={{ paddingLeft: 17 }}>
              {(selection ?? "").toString()}
            </span>
          </div>
        }
        className="Popover PopoverAnchor"
        closePopover={stopEditing}
        display="block"
        isOpen
        panelPaddingSize="none"
        repositionOnScroll={false}
      >
        <EuiListGroup className="ContextMenu" maxWidth="100%">
          {items}
        </EuiListGroup>
      </EuiPopover>
    );
  },
);
