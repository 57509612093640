/** @jsxImportSource @emotion/react */

import { css, Interpolation, Theme } from "@emotion/react";
import { EuiIcon, EuiText } from "@inscopix/ideas-eui";
import { ClassAttributes, InputHTMLAttributes, useState } from "react";
import { DRS_FILE_ACTION_ICONS } from "types/Icons";
import { selectSystemFiles } from "utils/selectSystemFiles";

const iconClass = "drop-icon";

export interface DropZoneBasicProps
  extends ClassAttributes<HTMLInputElement>,
    InputHTMLAttributes<HTMLInputElement> {
  css: Interpolation<Theme>;
  onSelectFiles: (files: File[]) => void;
  isClickable?: boolean;
}

export const DropZoneBasic = ({
  multiple = true,
  onSelectFiles,
  isClickable,
  ...props
}: DropZoneBasicProps) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  return (
    <div
      onDrop={(e) => {
        setIsDraggingOver(false);
        const fileList = e.dataTransfer.files;
        onSelectFiles(Array.from(fileList));
      }}
      title="file drop zone"
      {...props}
      css={[props?.css]}
      onDragOver={() => setIsDraggingOver(true)}
      onDragLeave={() => setIsDraggingOver(false)}
    >
      <div
        css={[
          css`
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            height: 100%;
            width: 100%;
          `,
          isDraggingOver &&
            css`
              text-decoration: underline;
              .${iconClass} {
                transform: scale(1.1);
              }
            `,
          isClickable &&
            css`
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
              &:hover .${iconClass} {
                transform: scale(1.1);
              }
            `,
        ]}
        onClick={() => {
          if (isClickable) {
            void selectSystemFiles(true).then((files) => {
              if (files) {
                onSelectFiles(files);
              }
            });
          }
        }}
      >
        <EuiIcon
          className={iconClass}
          type={DRS_FILE_ACTION_ICONS["upload"]}
          size="l"
        />
        <EuiText size="m">
          Drop Files {isClickable && "or Click "}Here to Upload
        </EuiText>
      </div>
    </div>
  );
};
