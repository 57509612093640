import { useState } from "react";
import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiMarkdownEditor,
  EuiMarkdownEditorProps,
  EuiMarkdownFormat,
  EuiMarkdownParseError,
  EuiPanel,
} from "@inscopix/ideas-eui";
import { ButtonEmptyPermissioned } from "components/ButtonEmptyPermissioned/ButtonEmptyPermissioned";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { isNonNullish } from "utils/isNonNullish";

interface MarkdownEditorProps {
  height?: number | "full";
  disableEdit?: boolean;
  initialValue: string;
  emptyPrompt: string;
  onSave: (markdown: string) => Promise<void>;
}

/**
 * Dataset description editor and display component
 */
export const MarkdownEditor = ({
  disableEdit,
  emptyPrompt,
  height = "full",
  initialValue,
  onSave,
}: MarkdownEditorProps) => {
  // toggle state for editing mode
  const [editMarkdown, setEditMarkdown] = useState(false);
  // validation messages for EuiMarkdownEditor
  const [messages, setMessages] = useState<EuiMarkdownParseError[]>([]);

  const [editorMarkdownValue, setEditorMarkdownValue] = useState(initialValue);

  const [loading, setLoading] = useState(false);

  // sets markdown validation errors
  const onParse: EuiMarkdownEditorProps["onParse"] = (err, { messages }) => {
    setMessages(isNonNullish(err) ? [err] : messages);
  };

  const handleSave = async () => {
    if (editorMarkdownValue === initialValue) {
      return setEditMarkdown(false);
    }
    setLoading(true);
    await onSave(editorMarkdownValue);
    setEditMarkdown(false);
    setLoading(false);
  };

  const cancelEditMarkdown = () => {
    setEditMarkdown(false);
    setEditorMarkdownValue(initialValue);
  };

  const body = (() => {
    if (loading) {
      return <EuiSkeletonText />;
    }

    if (editMarkdown) {
      return (
        <EuiMarkdownEditor
          aria-label="Markdown Editor"
          value={editorMarkdownValue}
          onChange={setEditorMarkdownValue}
          onSave={() => void handleSave()}
          onCancel={cancelEditMarkdown}
          height={height}
          onParse={onParse}
          errors={messages}
        />
      );
    }

    if (initialValue === "") {
      return (
        <EuiEmptyPrompt
          body={
            <>
              <p>{emptyPrompt}</p>
            </>
          }
          actions={
            disableEdit ? null : (
              <ButtonPermissioned
                onClick={() => setEditMarkdown(true)}
                size="s"
                color="primary"
                fill
                aria-label="edit markdown"
                requiredPermission="edit"
              >
                Edit
              </ButtonPermissioned>
            )
          }
        />
      );
    }

    return (
      <EuiFlexGroup>
        <EuiFlexItem grow={true}>
          <EuiMarkdownFormat>{initialValue}</EuiMarkdownFormat>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <ButtonEmptyPermissioned
            onClick={() => {
              setEditMarkdown(true);
            }}
            iconType="documentEdit"
            aria-label="edit markdown"
            disabled={disableEdit}
            requiredPermission="edit"
          >
            Edit
          </ButtonEmptyPermissioned>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  })();

  return (
    <EuiPanel
      paddingSize="s"
      hasShadow={false}
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      {body}
    </EuiPanel>
  );
};
