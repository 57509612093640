import {
  CellEditRequestEvent,
  FillOperationParams,
  ProcessCellForExportParams,
} from "ag-grid-community";
import assert from "assert";
import { useCallback } from "react";
import { isDefined } from "utils/isDefined";
import {
  ToolParamsGridRowDatum as RowDatum,
  ToolParamsGridColDef as ColDef,
  ToolParamValue,
  ToolParamsGridRowDatum,
} from "../ToolParamsGrid.types";

/**
 * A hook for formatting tool param values
 */
export const useValueFormatter = () => {
  /**
   * https://www.ag-grid.com/react-data-grid/range-selection-fill-handle/#reference-selection-fillOperation
   *
   * Formats cell values changed using the fill handle
   * @param params
   * @returns The formatted cell value
   */
  const fillOperation = useCallback(
    (params: FillOperationParams<ToolParamsGridRowDatum>) => {
      const colDef = params.column.getColDef() as ColDef;
      const newValue = params.values[
        params.values.length - 1
      ] as ToolParamValue;
      const rowDatum = params.rowNode.data;

      assert(
        isDefined(rowDatum),
        "Expected row datum to be defined in fillOperation",
      );
      if (colDef.onCellFill !== null) {
        return colDef.onCellFill(newValue, rowDatum);
      }
    },
    [],
  );

  /**
   * https://www.ag-grid.com/react-data-grid/value-setters/#read-only-edit
   *
   * Formats cell values changed using a cell editor
   * @param event
   * @returns The formatted cell value
   */
  const onCellEditRequest = useCallback(
    (event: CellEditRequestEvent<ToolParamsGridRowDatum, ToolParamValue>) => {
      const colDef = event.column.getColDef() as ColDef;
      const newValue = event.newValue as ToolParamValue;
      const rowDatum: RowDatum = event.data;

      if (colDef.onCellEdit === undefined) {
        const { colId } = colDef;
        throw new Error(`Unhandled edit request on column "${colId}"`);
      }

      colDef.onCellEdit(newValue, rowDatum);
    },
    [],
  );

  /**
   * https://www.ag-grid.com/react-data-grid/clipboard/#reference-clipboard-processCellFromClipboard
   *
   * Formats cell values changed by pasting from the clipboard
   * @param params
   * @returns The formatted cell value
   */
  const processCellFromClipboard = useCallback(
    (params: ProcessCellForExportParams<ToolParamsGridRowDatum>) => {
      const colDef = params.column.getColDef() as ColDef;
      const newValue = params.value as ToolParamValue;
      const rowDatum = params.node?.data;

      if (rowDatum === undefined || colDef.onCellPaste === undefined) {
        const { colId } = colDef;
        throw new Error(`Unhandled paste from clipboard on column "${colId}"`);
      }

      return colDef.onCellPaste(newValue, rowDatum);
    },
    [],
  );

  return {
    fillOperation,
    onCellEditRequest,
    processCellFromClipboard,
  };
};
