import { EuiPageTemplate } from "@inscopix/ideas-eui";
import { PanelProjects } from "components/PanelProjects/PanelProjects";
import { PageOrganizationSideBar } from "./PageOrganizationSidebar";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { CallOutQuotas } from "components/CallOutQuotas/CallOutQuotas";
import { CallOutContract } from "components/CallOutContract/CallOutContract";

export const PageOrganizationDashboard = () => {
  const currentTenant = useTenantContext((s) => s.currentTenant);
  return (
    <EuiPageTemplate panelled={true} bottomBorder={"extended"} grow={true}>
      <CallOutContract />
      <CallOutQuotas />
      <EuiPageTemplate.Sidebar minWidth={300} sticky={true}>
        <PageOrganizationSideBar />
      </EuiPageTemplate.Sidebar>

      <EuiPageTemplate.Section restrictWidth={false}>
        <PanelProjects tenantId={currentTenant.id} />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  );
};
