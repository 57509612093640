import { EuiPanel, EuiMarkdownFormat } from "@inscopix/ideas-eui";

interface EulaBodyProps {
  eulaContent: string;
}

export const EulaBody = ({ eulaContent }: EulaBodyProps) => {
  return (
    <EuiPanel
      hasBorder={true}
      style={{ maxHeight: "40rem", overflowY: "auto" }}
    >
      <EuiMarkdownFormat textSize="s">{eulaContent}</EuiMarkdownFormat>
    </EuiPanel>
  );
};
