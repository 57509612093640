import { createContext, useContext, useMemo } from "react";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { getEnvVar } from "ideas.env";
import assert from "assert";

export interface TRegionEnv {
  KEY: string;
  URL: string;
  URL_GRAPHQL: string;
  URL_DJANGO: string;
}

const isRegionEnv = (value: unknown): value is TRegionEnv => {
  return (
    value != null &&
    typeof (value as Partial<TRegionEnv>).KEY === "string" &&
    typeof (value as Partial<TRegionEnv>).URL === "string" &&
    typeof (value as Partial<TRegionEnv>).URL_GRAPHQL === "string" &&
    typeof (value as Partial<TRegionEnv>).URL_DJANGO === "string"
  );
};

type TRegionsEnv = TRegionEnv[];

const isRegionsEnvArray = (value: unknown): value is TRegionsEnv => {
  return Array.isArray(value) && value.every((item) => isRegionEnv(item));
};

export type TRegion = {
  key: string;
  url: string;
  urlDjango: string;
  urlGraphQL: string;
  isHealthy: boolean;
};

type RegionsProviderContextValue = {
  currentRegionKey: string;
  externalRegions: TRegion[];
};

export const RegionsProviderContext = createContext<
  RegionsProviderContextValue | undefined
>(undefined);

export const useRegionsContext = () => {
  const context = useContext(RegionsProviderContext);
  if (context === undefined) {
    throw new ContextOutOfBoundsError("RegionsProviderContext");
  }

  return context;
};

interface RegionsProviderProps {
  children: React.ReactNode;
}

export const RegionsProvider = ({ children }: RegionsProviderProps) => {
  const contextValue = useMemo(() => {
    const currentRegionKey = getEnvVar("REGION");
    assert(currentRegionKey, "REGION environment variable is not set");
    const externalRegionsList = (() => {
      try {
        const parsedList = JSON.parse(getEnvVar("EXTERNAL_REGIONS")) as unknown;
        if (isRegionsEnvArray(parsedList)) {
          return parsedList;
        } else {
          throw new Error(
            "EXTERNAL_REGIONS environment variable is not an array of TRegionEnv",
          );
        }
      } catch (error) {
        throw new Error(
          "EXTERNAL_REGIONS environment variable is not set or could not be parsed",
        );
      }
    })();
    const externalRegions: TRegion[] = externalRegionsList.map((regionEnv) => ({
      key: regionEnv.KEY,
      url: regionEnv.URL,
      urlDjango: regionEnv.URL_DJANGO,
      urlGraphQL: regionEnv.URL_GRAPHQL,
      isHealthy: false,
    }));

    return {
      currentRegionKey,
      externalRegions,
    };
  }, []);

  return (
    <RegionsProviderContext.Provider value={contextValue}>
      {children}
    </RegionsProviderContext.Provider>
  );
};
