/** @jsxImportSource @emotion/react */
import { Flyout, FlyoutProps } from "components/Flyout/Flyout";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { EuiFlexItem, EuiText } from "@inscopix/ideas-eui";
import {
  FileBrowser,
  FileBrowserFile,
  FileBrowserProps,
} from "../FileBrowser/FileBrowser";
import { isNonNull } from "utils/isNonNull";
import assert from "assert";
import { DraggableFileBadge } from "components/RecordingsGrid/DraggableFileBadge";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { useMemo } from "react";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { DropZoneBasic } from "components/DropZoneBasic/DropZoneBasic";
import { useDragState } from "hooks/useDragState";
import { css } from "@emotion/react";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { uuid } from "utils/uuid";
import {
  DatasetRecordingsTableColumn,
  FileRecordingGroup,
  FileSource,
} from "graphql/_Types";
import { isDefined } from "utils/isDefined";
import { useGetFileBrowserActions } from "./useGetFileBrowserActions";

const fileBrowserZIndex = 100;
const styles = {
  mainFlexContainer: css`
    height: 100%;
    min-height: 0px;
    flex: 4;
    position: relative;
  `,
  dropZone: css`
    display: flex;
    position: absolute;
    bottom: 0;
    z-index: ${fileBrowserZIndex * 2};
    border: 1px dashed lightgray;
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  `,
  fileBrowser: css`
    z-index: ${fileBrowserZIndex};
  `,
};
export interface FlyoutFilesProps extends Pick<FlyoutProps, "headerControls"> {
  onClose: () => void;
}

export const FlyoutFiles = ({ onClose, ...props }: FlyoutFilesProps) => {
  const { dataset, datasetMode } = useDatasetDataContext();
  const { openModal, openFlyout } = useDatasetLayoutContext();

  const files = useProjectFilesStore((s) => s.files);

  const actionUnassignFile = useDatasetAction("unassignFile");
  const uploadFileAction = useDatasetAction("uploadFile");
  const { actions: projectActions } = useGetFileBrowserActions(openModal);

  const selection = useMemo<FileBrowserProps["selection"]>(
    () => ({
      actions: {
        ...projectActions,
        unassign: {
          onExecute: (files) =>
            new Promise((res) => {
              const unassignableFiles = files.filter(
                (
                  file,
                ): file is FileBrowserFile & {
                  source: {
                    type: FileSource.Uploaded;
                    assignment: {
                      recordingId: FileRecordingGroup["id"];
                      recordingName: string;
                      columnId: DatasetRecordingsTableColumn["id"];
                    };
                  };
                } =>
                  file.source.type === "UPLOADED" &&
                  isDefined(file.source.assignments),
              );
              openModal({
                type: "confirmAction",
                props: {
                  body: (
                    <EuiText>
                      Are you sure you wish to unassign the following{" "}
                      {unassignableFiles.length} file(s):
                      <p>
                        {unassignableFiles.map(({ id, name }) => (
                          <div key={id}>{name}</div>
                        ))}
                      </p>
                    </EuiText>
                  ),
                  onConfirm: () => {
                    unassignableFiles.forEach((file) => {
                      void actionUnassignFile.enqueue({
                        columnId: file.source.assignment.columnId,
                        recordingId: file.source.assignment.recordingId,
                        drsFileId: file.id,
                      });
                    });
                    // we treat enqueuing actions on the dataset as a success
                    res({
                      successfulFiles: unassignableFiles,
                      errors: [],
                    });
                  },
                  onClose: () => res({ successfulFiles: [], errors: [] }),
                },
              });
            }),
          suppressLoading: true,
          isDisabled: (files) =>
            !files.some(
              ({ source }) =>
                source.type === "UPLOADED" && isDefined(source.assignments),
            ),
          disabledToolTip: "You must select at least one assigned file.",
        },
      },
    }),
    [actionUnassignFile, openModal, projectActions],
  );

  const fileBrowserFiles: FileBrowserFile[] = useMemo(
    () =>
      files.map((projectFile) => {
        const source: FileBrowserFile["source"] = (() => {
          if (projectFile.source === FileSource.Uploaded) {
            const formattedAssignments:
              | FileBrowserFile["source"]["assignments"]
              | undefined =
              // only one recording possible for uploaded files
              projectFile.recordings?.[0] !== undefined &&
              projectFile.columns?.[0] !== undefined
                ? [
                    {
                      dataset: projectFile.recordings[0].dataset,
                      columnId: projectFile.columns[0].id,
                      recording: projectFile.recordings[0],
                    },
                  ]
                : undefined;
            return {
              ...projectFile,
              type: FileSource.Uploaded,
              dataset: projectFile.datasets[0],
              assignments: formattedAssignments,
            };
          }

          return {
            ...projectFile,
            type: FileSource.AnalysisResult,
            assignments: projectFile.recordings.map(
              ({ id, number, dataset }) => ({
                dataset,
                recording: { id, number },
              }),
            ),
          };
        })();

        assert(isNonNull(projectFile.user));

        return {
          ...projectFile,
          source,
        };
      }),
    [files],
  );
  const { isDraggingFiles } = useDragState();

  const onDropFiles = (files: File[]) => {
    files.forEach(
      (file) =>
        void uploadFileAction.enqueue({
          blob: file,
          id: uuid(),
          name: file.name,
          datasetId: dataset.id,
          tenantId: dataset.tenantId,
        }),
    );
  };

  return (
    <Flyout
      onClose={onClose}
      title="File Browser"
      titleSpacerSize="s"
      {...props}
    >
      <EuiFlexItem css={styles.mainFlexContainer}>
        {datasetMode.type === "current" &&
          (isDraggingFiles || fileBrowserFiles.length === 0) && (
            <DropZoneBasic
              isClickable={fileBrowserFiles.length === 0}
              onSelectFiles={onDropFiles}
              css={styles.dropZone}
            />
          )}
        {fileBrowserFiles.length === 0 && datasetMode.type !== "current" && (
          <div>
            <EuiText>
              No files were present in this dataset at the timestamp you are
              currently viewing
            </EuiText>
          </div>
        )}
        {fileBrowserFiles.length > 0 && (
          <FileBrowser
            css={styles.fileBrowser}
            defaultHiddenColumns={["dataset"]}
            datasetFilter={[
              {
                value: "CURRENT_DATASET",
                inputDisplay: "Current Dataset",
                onFilter: (file) =>
                  file.datasets.some(({ id }) => id === dataset.id),
                default: true,
              },
              {
                value: "NO_DATASET",
                inputDisplay: "No Dataset Assigned",
                onFilter: (file) => file.datasets.length === 0,
              },
            ]}
            defaultSource="UPLOADED"
            selection={selection}
            files={fileBrowserFiles}
            fileRenderer={({ file }) => (
              <div style={{ marginTop: -5 }}>
                <DraggableFileBadge
                  drsFile={{ ...file, source: file.source.type }}
                  suppressContextMenu={true}
                  // Disable dragging in fullscreen mode
                  // There is currently some odd behavior where dnd
                  // drop events on the grid are propagating through the
                  // fullscreen file browser
                  // https://inscopix.atlassian.net/browse/ID-2010
                  disabled={file.source.type !== "UPLOADED"}
                  onClick={() => {
                    openFlyout({
                      type: "fileInfo",
                      props: { drsFile: { ...file, source: file.source.type } },
                    });
                  }}
                />
              </div>
            )}
          />
        )}
      </EuiFlexItem>
    </Flyout>
  );
};
