import axios from "axios";
import { getEnvVar } from "ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { captureException } from "@sentry/react";
import { useCallback } from "react";
import { useHasActiveContract } from "stores/contract/useHasActiveContract";

interface ContractDatum {
  active: boolean;
  expires?: string;
}

export const useFetchAndSetHasActiveContract = () => {
  const { hasActiveContract, expiresAt, setHasActiveContract, setExpiresAt } =
    useHasActiveContract();

  const currentTenant = useTenantContext((s) => s.currentTenant);

  //
  // Checking if the contract is active
  //
  const fetchAndSetHasActiveContract = useCallback(async () => {
    try {
      const headers = await getRequestHeaders();
      const contractResponse = await axios.get<ContractDatum>(
        `${getEnvVar("URL_TENANT_CHECK_CONTRACT")}${currentTenant.id}/`,
        {
          headers,
        },
      );

      // Check if the contract is active and set the Zustand store
      setHasActiveContract(contractResponse.data.active);
      setExpiresAt(contractResponse.data?.expires);
    } catch (error) {
      captureException(error);
    }
  }, [currentTenant.id, setExpiresAt, setHasActiveContract]);

  // Reset has active contract
  const resetActiveContract = useCallback(() => {
    setHasActiveContract(true);
    setExpiresAt(undefined);
  }, [setExpiresAt, setHasActiveContract]);

  return {
    hasActiveContract,
    expiresAt,
    fetchAndSetHasActiveContract,
    resetActiveContract,
  };
};
