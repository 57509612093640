import { QueryResult } from "@apollo/client";
import { captureException } from "@sentry/react";
import {
  Exact,
  useGetExternalTenantsLazyQuery,
  GetExternalTenantsQuery,
} from "graphql/_Types";
import { compact, isUndefined } from "lodash";
import { TRegion, useRegionsContext } from "providers/RegionsProvider";
import { useState, useEffect } from "react";

type TExternalTenantAndRegion = {
  tenants: QueryResult<
    GetExternalTenantsQuery,
    Exact<{
      [key: string]: never;
    }>
  >;
  region: TRegion;
};

export const useGetExternalTenants = () => {
  const { externalRegions } = useRegionsContext();
  const [getExternalTenants] = useGetExternalTenantsLazyQuery({
    onError: (err) => captureException(err),
    fetchPolicy: "no-cache",
  });
  const [results, setResults] = useState<TExternalTenantAndRegion[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const getAllExternalTenants = async () => {
      try {
        const promises = externalRegions.map(async (region) => ({
          region,
          tenants: await getExternalTenants({
            context: {
              uri: region.urlGraphQL,
              queryDeduplication: false,
            },
          }),
        }));
        const results = await Promise.all(promises);
        setResults(results);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };
    void getAllExternalTenants();
  }, [externalRegions, getExternalTenants]);

  const externalTenants =
    compact(
      results.flatMap(
        ({ tenants, region }) =>
          tenants.data?.currentUser?.memberships.nodes.map((node) => {
            if (isUndefined(region) || isUndefined(node.tenant?.key)) {
              return undefined;
            }
            return {
              ...node.tenant,
              region,
            };
          }),
      ),
    ) ?? [];

  return { externalTenants, loading, error };
};
