import { gql } from "@apollo/client";
import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import { File as DrsFile, useGetProcessingStatusQuery } from "graphql/_Types";
import { useEffect, useMemo } from "react";
import { ProcessingStatus, TaskStatus } from "types/constants";
import { isNonNull } from "utils/isNonNull";

gql`
  query GetProcessingStatus($drsFileId: UUID!) {
    file: fileById(id: $drsFileId) {
      id
      processingStatus
      taskSources: taskSourcesByFileId {
        nodes {
          id
          task: taskByTaskId {
            id
            status
            errorDescription
            toolVersion: toolVersionByToolVersionId {
              id
              tool: toolByToolId {
                id
                name
                key
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetDrsFileProcessingStatus = (
  drsFile: Pick<DrsFile, "id" | "processingStatus">,
) => {
  const shouldSkip =
    drsFile.processingStatus === ProcessingStatus["SKIPPED"] ||
    drsFile.processingStatus === ProcessingStatus["UNPROCESSED"] ||
    drsFile.processingStatus === ProcessingStatus["COMPLETE"];
  const { data, stopPolling } = useGetProcessingStatusQuery({
    variables: { drsFileId: drsFile.id },
    pollInterval: 5000,
    skip: shouldSkip,
  });

  const status = useMemo(() => {
    const fileInfo = data?.file;
    if (isNullish(fileInfo)) {
      return { processingStatus: "pending" as const };
    }

    const failedTask = fileInfo.taskSources.nodes
      .map((taskSource) => taskSource.task)
      .filter(isNonNull)
      .filter((task) => {
        const tool = task.toolVersion?.tool;
        return tool?.key === "process_file";
      })
      .find((task) => {
        const failedTaskStatuses = [TaskStatus.FAILED, TaskStatus.ERROR];
        return failedTaskStatuses.includes(task.status);
      });

    switch (
      (data?.file?.processingStatus as ProcessingStatus | undefined | null) ??
      ProcessingStatus["PENDING"]
    ) {
      case ProcessingStatus["SKIPPED"]:
      case ProcessingStatus["UNPROCESSED"]:
      case ProcessingStatus["COMPLETE"]:
        return { processingStatus: "complete" as const, failedTask: undefined };
      case ProcessingStatus["FAILED"]:
        return { processingStatus: "error" as const, failedTask };
      case ProcessingStatus["PENDING"]:
      case ProcessingStatus["PROCESSING"]:
        return { processingStatus: "pending" as const, failedTask: undefined };
    }
  }, [data?.file]);

  useEffect(() => {
    if (status.processingStatus !== "pending") {
      stopPolling();
    }
  }, [status.processingStatus, stopPolling]);

  if (shouldSkip) {
    return { processingStatus: "complete" as const, failedTask: undefined };
  }

  return status;
};
