import { EuiLink } from "@inscopix/ideas-eui";
import assert from "assert";
import { useRoutes } from "hooks/useRoutes";
import { memo } from "react";
import { ProjectsGridCellRendererParams } from "./ProjectsGrid";

type ProjectsGridCellRendererNameProps = ProjectsGridCellRendererParams;

export const ProjectsGridCellRendererName = memo(
  function ProjectsGridCellRendererName({
    data,
  }: ProjectsGridCellRendererNameProps) {
    assert(
      data !== undefined,
      "Expected data to be defined in ProjectsGridCellRendererName",
    );

    const { routeMap } = useRoutes();
    if (data.role !== "restricted") {
      return (
        <EuiLink
          to={
            routeMap.PROJECT.dynamicPath({
              projectKey: data.key,
              tenantKey: data.tenantKey,
            }).path
          }
        >
          {data.name}
        </EuiLink>
      );
    }
    return <>{data.name}</>;
  },
);
