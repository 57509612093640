import { css } from "@emotion/react";
import { useEuiTheme } from "@inscopix/ideas-eui";

export const useMenuInsertColumnStyles = () => {
  const theme = useEuiTheme();
  const menuInsertColumnStyles = {
    panelStyles: css`
      overflow-y: scroll;
      padding: ${theme.euiTheme.size.base};
    `,
  };

  return menuInsertColumnStyles;
};
