import { Dataset, Project } from "graphql/_Types";
import { useState } from "react";
import { getDatasetWithPrefix } from "utils/getDatasetWithPrefix";

type FormFields = {
  prefix: string;
};

type ValidationErrorMap = {
  prefix?: string;
};

export const useValidateRecordingIdentifierFormat = (
  datasetId: Dataset["id"],
  projectId: Project["id"],
) => {
  const [validationErrorMap, setValidationErrorMap] =
    useState<ValidationErrorMap>({});

  /**
   * Validates the fields in the recording identifier format form
   * @param fields
   * @returns A `boolean` representing whether the form is valid
   */
  const validateFields = async (fields: FormFields) => {
    const newValidationErrorMap: ValidationErrorMap = {};

    // Validate prefix field
    newValidationErrorMap.prefix = await (async () => {
      if (fields.prefix === "") {
        return "Please specify a prefix";
      }

      // Only uppercase letter, numbers and underscores are allowed
      // Prefixes cannot exceed 10 characters
      const datasetPrefixRegex = /^[A-Z0-9_]{1,10}$/;

      if (!datasetPrefixRegex.test(fields.prefix)) {
        return "Invalid prefix format";
      }

      const existingDatasetId = await getDatasetWithPrefix({
        prefix: fields.prefix,
        projectId,
      });

      if (existingDatasetId !== undefined && existingDatasetId !== datasetId) {
        return "Prefix is already used by another dataset in this project";
      }
    })();

    // Update validation error map
    setValidationErrorMap(newValidationErrorMap);

    // Check if there are any validation errors
    return Object.values(newValidationErrorMap).every(
      (val) => val === undefined,
    );
  };

  return { validateFields, validationErrorMap };
};
