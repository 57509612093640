import {
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { ICellEditorParams } from "ag-grid-community";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import "./GridEditorToolChoiceParam.scss";
import {
  ToolChoiceParam,
  ToolParamsGridRowDatum,
} from "./ToolParamsGrid.types";

interface GridEditorToolChoiceParamProps extends ICellEditorParams {
  data: ToolParamsGridRowDatum;
  toolParam: ToolChoiceParam;
  value: ToolChoiceParam["type"]["choices"][number] | undefined;
}

/**
 * A component for editing any `ToolChoiceParam` grid cells
 */
export const GridEditorToolChoiceParam = forwardRef(
  function GridEditorToolChoiceParam(
    {
      stopEditing,
      toolParam,
      value,
      column,
      node,
    }: GridEditorToolChoiceParamProps,
    ref,
  ) {
    const [selection, setSelection] = useState(value);

    const valueIndex =
      value !== undefined ? toolParam.type.choices.indexOf(value) : -1;
    const [displayValue, setDisplayValue] = useState(
      toolParam.type.display_options?.[valueIndex] ?? value,
    );

    useImperativeHandle(ref, () => ({
      getValue: () => selection,
    }));
    const items = useMemo(
      () =>
        toolParam.type.choices.map((choice, index) => (
          <EuiListGroupItem
            id={choice.toString()}
            key={choice}
            iconType={choice === selection ? "check" : "empty"}
            label={toolParam.type.display_options[index] ?? choice} //display_options will always have a 1:1 relation with the choice according to their index (sort order)
            onClick={() => {
              setSelection(choice);
              setDisplayValue(toolParam.type.display_options[index] ?? choice);
            }}
          />
        )),
      [selection, toolParam.type.choices, toolParam.type.display_options],
    );

    return (
      <EuiPopover
        anchorPosition="downLeft"
        button={
          <div
            style={{
              width: column.getActualWidth(),
              height: node.rowHeight ?? 0,
              display: "flex",
              alignItems: "center",
              fontSize: 14,
            }}
          >
            <span style={{ paddingLeft: 17 }}>{displayValue}</span>
          </div>
        }
        className="Popover PopoverAnchor"
        closePopover={stopEditing}
        display="block"
        isOpen
        panelPaddingSize="none"
        repositionOnScroll={false}
      >
        <EuiListGroup className="ContextMenu" maxWidth="100%">
          {items}
        </EuiListGroup>
      </EuiPopover>
    );
  },
);
