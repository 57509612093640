import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { createContext, ReactNode, useContext } from "react";
import { usePageProjectData } from "./usePageProjectData";

type ProjectData = NonNullable<ReturnType<typeof usePageProjectData>["data"]>;
type ProjectDataContextValue = ProjectData;

const ProjectDataContext = createContext<ProjectDataContextValue | undefined>(
  undefined,
);

interface ProjectDataProviderProps extends ProjectData {
  children: ReactNode;
}

export const ProjectDataProvider = ({
  children,
  analysisTableGroups,
  analysisResultsByTableId,
  datasets,
  project,
}: ProjectDataProviderProps) => {
  return (
    <ProjectDataContext.Provider
      value={{
        analysisTableGroups,
        analysisResultsByTableId,
        project,
        datasets,
      }}
    >
      {children}
    </ProjectDataContext.Provider>
  );
};

export const useProjectDataContext = () => {
  const value = useContext(ProjectDataContext);

  if (value === undefined) {
    throw new ContextOutOfBoundsError("ProjectDataContext");
  }

  return value;
};
