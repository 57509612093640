/** @jsxImportSource @emotion/react */

import { useBottomBarStyles } from "./useBottomBarStyles";

export interface BottomBarProps {
  children: React.ReactNode;
}
export const BottomBar = ({ children }: BottomBarProps) => {
  const bottomBarStyles = useBottomBarStyles();

  return <div css={bottomBarStyles.root}>{children}</div>;
};
