import { useEffect, useState } from "react";

/**
 * Hook for accessing the current online status of the browser
 * @returns A `boolean` representing whether the browser has access to the
 * network
 */
export const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    window.addEventListener("online", handleOnline);

    const handleOffline = () => setIsOnline(false);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return { isOnline };
};
