import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { StoreApi, useStore } from "zustand";
import AppLoading from "components/AppLoading/AppLoading";
import { useUserProviderQuery } from "graphql/_Types";
import { createUserStore } from "./UserProvider.helpers";
import { UserStoreState } from "./UserProvider.types";
import { Eula } from "components/Eula/Eula";
import { AccessDeniedError } from "components/SentryErrorBoundary/ErrorBoundaryFallback/ErrorBoundaryFallback";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import * as sentry from "@sentry/react";

type UserContextValue = StoreApi<UserStoreState>;

const UserContext = createContext<UserContextValue | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

/** Context provider for managing data for the current user */
export const UserProvider = ({ children }: UserProviderProps) => {
  const { data, refetch } = useUserProviderQuery();
  const [store, setStore] = useState<UserContextValue>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const initStore = async () => {
      if (data !== undefined) {
        try {
          // Initialize store
          const store = await createUserStore(data);
          setStore(store);
          // Add user information to Sentry reports
          const { currentUser } = store.getState();
          sentry.setUser({
            id: currentUser.id,
            username: currentUser.username,
            email: currentUser.email,
            segment: currentUser.internal ? "internal" : "external",
            ip_address: "{{auto}}",
          });
        } catch (error) {
          setError(error as Error);
        }
      }
    };
    void initStore();
  }, [data]);

  if (error !== undefined) {
    throw new AccessDeniedError();
  }

  if (store === undefined) {
    return <AppLoading />;
  }

  const { isEulaAccepted, currentUser } = store.getState();
  if (!isEulaAccepted) {
    return <Eula refetch={refetch} userID={currentUser.id} />;
  }

  return <UserContext.Provider value={store}>{children}</UserContext.Provider>;
};

/** Hook for consuming the {@link UserContext} */
export const useUserContext = <T,>(selector: (state: UserStoreState) => T) => {
  const store = useContext(UserContext);
  assert(isDefined(store), new ContextOutOfBoundsError("UserContext"));
  return useStore(store, selector);
};
