import { EuiIcon } from "@inscopix/ideas-eui";
import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { EuiBadgeMemo } from "components/ToolParamsGrid/EuiBadgeMemo";
import { UserAvatarRenderer } from "components/UserAvatarRenderer/UserAvatarRenderer";
import { useGetProjects } from "hooks/useGetProjects";
import { useCallback, useMemo } from "react";
import { USER_ACCESS_LEVELS_BY_KEY } from "types/UserAccessLevels";
import { filesize } from "utils/filesize";
import { roundToSignificant } from "utils/roundToSignificant";
import { ProjectsGridCellRendererActions } from "./ProjectsGridCellRendererActions";
import { ProjectsGridCellRendererName } from "./ProjectsGridCellRendererName";

export interface ProjectsGridProps {
  projects: NonNullable<ReturnType<typeof useGetProjects>["projects"]>;
}

type ProjectsGridRowDatum = ProjectsGridProps["projects"][number];

type ProjectsGridColDef = ColDef<ProjectsGridRowDatum>;

export type ProjectsGridCellRendererParams =
  ICellRendererParams<ProjectsGridRowDatum>;

export const ProjectsGrid = ({ projects }: ProjectsGridProps) => {
  const columnDefs: ProjectsGridColDef[] = useMemo(() => {
    const colDefName: ProjectsGridColDef = {
      colId: "name",
      headerName: "Name",
      valueGetter: (project) => project.data?.name,
      cellRenderer: ProjectsGridCellRendererName,
      flex: 1,
      minWidth: 100,
    };

    const colDefOwner: ProjectsGridColDef = {
      colId: "owner",
      headerName: "Owner",
      valueGetter: (project) => project.data?.userId,
      cellRenderer: ({ data }: ProjectsGridCellRendererParams) => {
        const userId = data?.userId;
        if (userId !== undefined) {
          return <UserAvatarRenderer userId={userId} size="s" />;
        }
        return <EuiIcon type="warning" title="Failed to retrieve user info" />;
      },
      width: 70,
    };

    const colDefStorage: ProjectsGridColDef = {
      colId: "activeStorage",
      headerName: "Active Storage",
      valueGetter: (project) => Number(project.data?.activeStorageSize ?? "0"),
      valueFormatter: ({ value }: { value: number }) =>
        filesize(value) as string,
      width: 100,
    };

    const colDefArchivedStorage: ProjectsGridColDef = {
      colId: "archivedStorage",
      headerName: "Archived Storage",
      valueGetter: (project) =>
        Number(project.data?.archivedStorageSize ?? "0"),
      valueFormatter: ({ value }: { value: number }) =>
        filesize(value) as string,
      width: 100,
    };

    const colDefTotalStorage: ProjectsGridColDef = {
      colId: "totalStorage",
      headerName: "Total Storage",
      valueGetter: (project) =>
        Number(project.data?.archivedStorageSize ?? "0") +
        Number(project.data?.activeStorageSize ?? "0"),
      valueFormatter: ({ value }: { value: number }) =>
        filesize(value) as string,
      width: 100,
    };

    const colDefComputeCredits: ProjectsGridColDef = {
      colId: "computeCredits",
      headerName: "Compute Credits",
      valueGetter: (project) => project.data?.usedCredits ?? 0,
      valueFormatter: ({ value }: { value: number }) =>
        String(roundToSignificant(value)),
      width: 80,
    };

    const colDefRole: ProjectsGridColDef = {
      colId: "role",
      headerName: "Access Level",
      valueGetter: (project) => project.data?.role ?? "restricted",
      cellRenderer: ({ data }: ProjectsGridCellRendererParams) => (
        <EuiBadgeMemo color="hollow">
          {USER_ACCESS_LEVELS_BY_KEY[data?.role ?? "restricted"]?.name}
        </EuiBadgeMemo>
      ),
      width: 120,
    };

    const colDefActions: ProjectsGridColDef = {
      colId: "actions",
      cellRenderer: ProjectsGridCellRendererActions,
      width: 40,
      wrapHeaderText: true,
      resizable: false,
      sortable: false,
      pinned: "right",
    };

    return [
      colDefName,
      colDefRole,
      colDefOwner,
      colDefStorage,
      colDefArchivedStorage,
      colDefTotalStorage,
      colDefComputeCredits,
      colDefActions,
    ];
  }, []);

  const defaultColDef: ProjectsGridColDef = {
    resizable: true,
    sortable: true,
    suppressMenu: true,
    wrapHeaderText: true,
  };

  const rowData = useMemo(() => {
    return projects;
  }, [projects]);

  const getRowId = useCallback(
    ({ data }: { data: ProjectsGridRowDatum }) => data.id,
    [],
  );

  return (
    <AgGridReact
      className="ag-theme-balham-cell-borders"
      getRowId={getRowId}
      headerHeight={45}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
      rowHeight={35}
    />
  );
};
