import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageTemplate,
} from "@inscopix/ideas-eui";
import UserPreference from "components/UserPreference/UserPreference";
import { ModalEula } from "components/Eula/ModalEula";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

/**
 * Settings page
 */

export const PageSettings = () => {
  const [isModalEulaVisible, setIsModalEulaVisible] = useState(false);
  const closeModalEula = () => setIsModalEulaVisible(false);
  const openModalEula = () => setIsModalEulaVisible(true);

  return (
    <EuiPageTemplate restrictWidth={false}>
      <Helmet>
        <title>{`Profile`}</title>
      </Helmet>
      {isModalEulaVisible && <ModalEula onClose={closeModalEula} />}
      <EuiPageTemplate.Header
        pageTitle={"User Profile"}
        rightSideItems={[
          <EuiButtonEmpty
            key={"view-eula-button"}
            onClick={openModalEula}
            data-test-subj="view-eula-button"
            iconType="documentation"
          >
            View EULA
          </EuiButtonEmpty>,
        ]}
      />
      <EuiPageTemplate.Section>
        <EuiFlexGroup justifyContent="center">
          <EuiFlexItem>
            <UserPreference />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  );
};
