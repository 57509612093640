import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalProps,
} from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";

interface ModalErrorProps {
  title?: string;
  onClose: EuiModalProps["onClose"];
}

/**
 * Displays an error modal
 */
export const ModalError = ({ title, onClose }: ModalErrorProps) => {
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{title ?? "Error"}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <CallOutError />
      </EuiModalBody>
    </EuiModal>
  );
};
