import { memo } from "react";
import { Task } from "graphql/_Types";
import { ShortTaskId } from "../ShortTaskId/ShortTaskId";

export interface GridRendererTaskShortIdProps {
  taskId: Task["id"] | undefined;
}

/**
 * A component for rendering a short ID for a submitted task
 */
export const GridRendererTaskShortId = memo(function GridRendererTaskShortId({
  taskId,
}: GridRendererTaskShortIdProps) {
  if (taskId === undefined) {
    return null;
  }

  return <ShortTaskId taskId={taskId} />;
});
