import { FC } from "react";
import {
  EuiAvatar,
  EuiAvatarProps,
  EuiSkeletonCircle,
} from "@inscopix/ideas-eui";
import { UserAvatarBase } from "./UserAvatarBase";
import { ApplicationUser } from "graphql/_Types";
import { isDefined } from "utils/isDefined";
import { isNullish } from "utils/isNullish";
import { useUserAvatarData } from "./UserAvatar.hooks";

export type UserAvatarProps = {
  userId: ApplicationUser["id"];
  size?: EuiAvatarProps["size"];
  displayName?: boolean;
};

export const UserAvatar: FC<UserAvatarProps> = ({
  userId,
  size,
  displayName,
}) => {
  const { data, isLoading, error } = useUserAvatarData(userId);
  const fullName = isDefined(data)
    ? `${data.firstName} ${data.lastName}`.trim()
    : "";
  const avatar = data?.avatar;

  if (isLoading) {
    return <EuiSkeletonCircle size={size} />;
  }

  if (isDefined(error) || isNullish(avatar)) {
    return (
      <EuiAvatar name={fullName} size={size} color="#dbd7d2" iconType="user" />
    );
  }

  return (
    <>
      <UserAvatarBase size={size} name={fullName} avatar={avatar} />
      {displayName && <span style={{ paddingLeft: "2px" }}>{fullName}</span>}
    </>
  );
};
