import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import {
  ModalArchiveDrsFile,
  ModalArchiveDrsFileProps,
} from "components/ModalArchiveDrsFile/ModalArchiveDrsFile";
import { useRoutes } from "hooks/useRoutes";
import { useState } from "react";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";

export interface ButtonArchiveDrsFileProps {
  drsFile: Parameters<typeof getDrsFileModifyPermissionByDrsFileAndAction>[0] &
    ModalArchiveDrsFileProps["drsFiles"][number];
}

export const ButtonArchiveDrsFile = ({
  drsFile,
}: ButtonArchiveDrsFileProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { routeMap } = useRoutes();
  const isViewingHistory =
    routeMap.DATASET_HISTORY.isRouteMatch() ||
    routeMap.DATASET_VERSION.isRouteMatch();

  return (
    <>
      <ButtonIconPermissioned
        aria-label="Archive file"
        defaultTooltip={
          isViewingHistory
            ? "Archive disabled while viewing history"
            : "Archive file"
        }
        color="primary"
        iconType="snowflake"
        isDisabled={
          isViewingHistory ||
          !getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "ARCHIVE")
            .isPermitted
        }
        onClick={() => {
          setShowConfirmModal(true);
        }}
        requiredPermission="edit"
      />
      {showConfirmModal && (
        <ModalArchiveDrsFile
          drsFiles={[drsFile]}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};
