import {
  EuiButton,
  EuiDescriptionList,
  EuiSkeletonText,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiTab,
  EuiTabs,
  EuiDescriptionListProps,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ShortTaskId } from "components/ShortTaskId/ShortTaskId";
import { TaskStatusBadge } from "components/TaskStatusBadge/TaskStatusBadge";
import { Task, useGetTaskByIdQuery } from "graphql/_Types";
import moment from "moment";
import { useEffect, useState } from "react";
import { LazyLog } from "react-lazylog";
import { TaskStatus } from "types/constants";
import { isNonNull } from "utils/isNonNull";
import { isNonNullish } from "utils/isNonNullish";
import { ButtonDownloadTaskLog } from "./ButtonDownloadTaskLog";
import { useTaskLogs } from "./useTaskLogs";

export interface ModalTaskLogsProps {
  onClose: () => void;
  task: Pick<Task, "id" | "status">;
}
export const ModalTaskLogs = ({ task, onClose }: ModalTaskLogsProps) => {
  const {
    loading: loadingTaskLogUrls,
    error: taskLogUrlError,
    data: taskLogUrlData,
  } = useTaskLogs(task);

  const { data: taskData } = useGetTaskByIdQuery({
    fetchPolicy: "network-only",
    variables: {
      id: task.id,
    },
  });

  const [selectedTabId, setSelectedTabId] = useState<
    "log-stdout" | "log-stderr" | undefined
  >(undefined);

  // set selected tab as appropriate
  useEffect(() => {
    if (!loadingTaskLogUrls && taskLogUrlData !== undefined) {
      if (taskLogUrlData.stdOutUrl !== undefined) {
        setSelectedTabId("log-stdout");
      } else if (taskLogUrlData.stdErrUrl !== undefined) {
        setSelectedTabId("log-stderr");
      } else {
        captureException("Unexpected state in ModalTaskLogs");
      }
    }
  }, [loadingTaskLogUrls, taskLogUrlData]);

  const taskInfo: NonNullable<EuiDescriptionListProps["listItems"]> = [
    {
      title: "Task ID",
      description: <ShortTaskId taskId={task.id} />,
    },
    {
      title: "Task Status",
      description: <TaskStatusBadge taskStatus={task.status} />,
    },
  ];

  const fetchedTask = taskData?.task;
  if (isNonNullish(fetchedTask)) {
    taskInfo.push({
      title: "Date created",
      description: moment(fetchedTask.created).format(
        "MMMM Do YYYY, h:mm:ss a",
      ),
    });

    if (isNonNull(fetchedTask.toolExitcode)) {
      taskInfo.push({
        title: "Exit code",
        description: fetchedTask.toolExitcode,
      });
    }
    if (typeof fetchedTask?.toolExitcodeDescription === "string") {
      taskInfo.push({
        title: "Exit code description",
        description: fetchedTask.toolExitcodeDescription,
      });
    }
  }

  return (
    <EuiModal style={{ width: 800 }} onClose={onClose}>
      <EuiModalHeader>
        <EuiDescriptionList type="column" listItems={taskInfo} />
      </EuiModalHeader>

      <EuiModalBody style={{ height: "400px" }}>
        {loadingTaskLogUrls && <EuiSkeletonText lines={3} />}
        {taskLogUrlError && (
          <CallOutError>Failed to fetch task logs</CallOutError>
        )}
        {!loadingTaskLogUrls && !taskLogUrlError && (
          <>
            <EuiTabs>
              <EuiTab
                key={"log-stdout"}
                onClick={() => setSelectedTabId("log-stdout")}
                isSelected={"log-stdout" === selectedTabId}
              >
                stdout
              </EuiTab>
              <EuiTab
                key={"log-stderr"}
                onClick={() => setSelectedTabId("log-stderr")}
                isSelected={"log-stderr" === selectedTabId}
                disabled={task.status === TaskStatus["COMPLETE"]}
              >
                stderr
              </EuiTab>
            </EuiTabs>
            {taskLogUrlData?.stdOutUrl !== undefined &&
              "log-stdout" === selectedTabId && (
                <LazyLog
                  height={320}
                  selectableLines
                  url={taskLogUrlData.stdOutUrl}
                />
              )}
            {taskLogUrlData?.stdErrUrl !== undefined &&
              "log-stderr" === selectedTabId && (
                <LazyLog
                  height={320}
                  selectableLines
                  url={taskLogUrlData.stdErrUrl}
                />
              )}
          </>
        )}
      </EuiModalBody>

      <EuiModalFooter>
        {taskLogUrlData?.stdOutUrl !== undefined && (
          <ButtonDownloadTaskLog
            downloadFileName={"stdout"}
            downloadUrl={taskLogUrlData.stdOutUrl}
          />
        )}

        {taskLogUrlData?.stdErrUrl !== undefined && (
          <ButtonDownloadTaskLog
            downloadFileName={"stderr"}
            downloadUrl={taskLogUrlData.stdErrUrl}
          />
        )}
        <EuiButton onClick={onClose} fill>
          Close
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
