import { EuiSkeletonText } from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import {
  DrsFileInfoAnalysisTable,
  DrsFileInfoAnalysisTableProps,
} from "components/FlyoutDrsFileInfo/DrsFileInfoAnalysisTable";
import {
  File as DrsFile,
  useFileBrowserDetailRendererAnalysisResultQuery,
} from "graphql/_Types";
import { isUndefined } from "lodash";
import { isNullish } from "utils/isNullish";

interface FileBrowserDetailRendererAnalysisResultProps {
  drsFile: Pick<DrsFile, "id">;
}
export const FileBrowserDetailRendererAnalysisResult = ({
  drsFile,
}: FileBrowserDetailRendererAnalysisResultProps) => {
  const { loading, data, error } =
    useFileBrowserDetailRendererAnalysisResultQuery({
      variables: { drsFileId: drsFile.id },
    });
  if (loading) {
    return <EuiSkeletonText lines={3} />;
  }

  const analysisTableInfo: DrsFileInfoAnalysisTableProps | undefined = (() => {
    const task = data?.drsFile?.outputGroupFiles.nodes[0].fileOutputGroup?.task;
    const analysisTable = task?.analysisTableRow?.analysisTable;
    const analysisTableGroup = analysisTable?.analysisTableGroup;
    const project = data?.drsFile?.project;
    if (
      isNullish(task) ||
      isNullish(analysisTable) ||
      isNullish(analysisTableGroup) ||
      isNullish(project)
    ) {
      return;
    }
    // TODO https://inscopix.atlassian.net/browse/ID-2124
    return { task, analysisTable, analysisTableGroup, project };
  })();

  if (error || isUndefined(analysisTableInfo)) {
    return <CallOutError />;
  }

  return <DrsFileInfoAnalysisTable {...analysisTableInfo} />;
};
