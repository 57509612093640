import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopoverTitle,
} from "@inscopix/ideas-eui";
import { sumBy } from "lodash";
import { useFileUploadStore } from "stores/upload/useFileUploadStore";
import { filesize } from "utils/filesize";
import { isDefined } from "utils/isDefined";

export const PopoverFileUploadTitle = () => {
  // Number of files that finished uploading
  const numCompleted = useFileUploadStore((s) => {
    const uploadedFiles = Object.values(s.filesById).filter(
      (file) => file?.progress === 100,
    );
    return uploadedFiles.length;
  });

  // Number of files currently uploading or waiting to be uploaded
  const numPending = useFileUploadStore((s) => {
    const uploadingFiles = Object.values(s.filesById).filter(
      (file) => file?.progress !== 100 && file?.error === undefined,
    );
    return uploadingFiles.length;
  });

  // Number of files that failed to upload
  const numFailed = useFileUploadStore((s) => {
    const failedFiles = Object.values(s.filesById).filter(
      (file) => file?.error !== undefined,
    );
    return failedFiles.length;
  });

  // Number of bytes already uploaded
  const bytesUploaded = useFileUploadStore((s) => {
    const files = Object.values(s.filesById).filter(isDefined);
    return sumBy(files, (file) => {
      if (file.progress === undefined) {
        return 0;
      } else {
        return file.fileSize * (file.progress / 100);
      }
    });
  });

  // Total number of bytes to be uploaded
  const totalBytes = useFileUploadStore((s) => {
    const files = Object.values(s.filesById).filter(isDefined);
    return sumBy(files, (file) => file.fileSize);
  });

  return (
    <EuiPopoverTitle paddingSize="s">
      <EuiFlexGroup>
        <EuiFlexItem>
          {filesize(bytesUploaded)} / {filesize(totalBytes)}
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ display: "inline" }}>
          <EuiBadge color="success" title="Files uploaded">
            {numCompleted}
          </EuiBadge>
          <EuiBadge color="primary" title="Files uploading">
            {numPending}
          </EuiBadge>
          <EuiBadge color="warning" title="Failed uploads">
            {numFailed}
          </EuiBadge>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopoverTitle>
  );
};
