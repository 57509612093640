import { useMemo, useState } from "react";

export const useSelectionState = <T extends { id: string | number }>(
  allItems: T[],
) => {
  const [items, setItems] = useState(new Map<T["id"], T>());

  const readonlyItems: ReadonlyMap<T["id"], T> = useMemo(() => items, [items]);

  const actions = useMemo(
    () => ({
      selectItems: (items: T[]) =>
        setItems((previousItems) => {
          const newSelectedRows = new Map(previousItems);
          items.forEach((item) => newSelectedRows.set(item.id, item));
          return newSelectedRows;
        }),
      deselectItems: (items: Pick<T, "id">[]) =>
        setItems((previousItems) => {
          const newSelectedRows = new Map(previousItems);
          items.forEach((item) => newSelectedRows.delete(item.id));
          return newSelectedRows;
        }),
      deselectAll: () => setItems(new Map()),
      selectAll: () =>
        setItems(
          new Map(
            allItems.map((item) => {
              const itemId = item.id;
              return [itemId, item];
            }),
          ),
        ),
    }),
    [allItems],
  );

  return [readonlyItems, actions] as const;
};
