import {
  Column,
  ColumnGroup,
  IProvidedColumn,
  ProvidedColumnGroup,
} from "ag-grid-community";

/**
 * Similar to {@link flattenColumnGroup} but expects a {@link IProvidedColumn}
 * as input.
 * @param providedColumn
 * @returns An array of all nested columns.
 */
const flattenProvidedColumn = (providedColumn: IProvidedColumn): Column[] => {
  if (providedColumn instanceof ProvidedColumnGroup) {
    // TODO: Investigate why getChildren sometimes returns undefined
    return providedColumn.getChildren()?.flatMap(flattenProvidedColumn) ?? [];
  }

  if (providedColumn instanceof Column) {
    return [providedColumn];
  }

  return [];
};

/**
 * Gets all deeply nested columns inside of a column group. This includes columns
 * that are children of column groups inside the specified column group.
 * @param columnGroup
 * @returns An array of all nested columns.
 */
export const flattenColumnGroup = (columnGroup: ColumnGroup) => {
  const providedColumnGroup = columnGroup.getProvidedColumnGroup();
  return flattenProvidedColumn(providedColumnGroup);
};
