import { EuiButton, EuiButtonProps } from "@inscopix/ideas-eui";
import { useBottomBarStyles } from "./useBottomBarStyles";

export interface BottomBarButtonProps
  extends Omit<EuiButtonProps, "size" | "color" | "css">,
    Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> {
  isActive?: boolean;
}
export const BottomBarButton = ({
  children,
  isActive = false,
  ...props
}: BottomBarButtonProps) => {
  const { button } = useBottomBarStyles();
  return (
    <EuiButton
      {...props}
      size="s"
      css={isActive ? button.active : button.default}
    >
      {children}
    </EuiButton>
  );
};
