import {
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { ReactNode } from "react";
import { isDefined } from "utils/isDefined";

interface ModalInfoProps {
  body: ReactNode;
  footer?: ReactNode;
  onClose: () => void;
  title: string;
}

/**
 * Displays generic information in a modal
 */
export const ModalInfo = ({ body, footer, onClose, title }: ModalInfoProps) => {
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>{body}</EuiModalBody>
      {isDefined(footer) && <EuiModalFooter>{footer}</EuiModalFooter>}
    </EuiModal>
  );
};
