import assert from "assert";
import { isMetadataColumn } from "components/RecordingsGrid/RecordingsGrid.helpers";
import { RecordingsGridColDef } from "components/RecordingsGrid/RecordingsGrid.types";
import {
  MetadataTableRecordingDocument,
  MetadataTableRecordingQuery,
  MetadataTableRecordingQueryVariables,
} from "graphql/_Types";
import { chain } from "lodash";
import { client } from "providers/ApolloProvider/ApolloProvider";
import { isDefined } from "utils/isDefined";
import { isNonNull } from "utils/isNonNull";

/**
 * Gets a formatted list of user-visible recording metadata.
 * @param recordingIds
 * @returns The formatted metadata.
 */
export const getRecordingMetadata = async (recordingIds: string[]) => {
  const { data } = await client.query<
    MetadataTableRecordingQuery,
    MetadataTableRecordingQueryVariables
  >({
    query: MetadataTableRecordingDocument,
    variables: { recordingIds },
  });

  const recordings = data.recordings?.nodes;
  const metadata = data.recordingMetadata?.nodes;
  assert(isDefined(recordings) && isDefined(metadata));

  // Create a a map of metadata keys to names using custom metadatum columns
  const metadatumNamesByKey: Record<string, string | undefined> = chain(
    recordings,
  )
    .flatMap((recording) => {
      const columns = recording.recordingsTable?.columns.nodes;
      assert(isDefined(columns));
      return columns.map((column) => ({
        ...column,
        colDef: column.colDef as RecordingsGridColDef,
      }));
    })
    .filter(isMetadataColumn)
    .keyBy((column) => column.colDef.metadataKey)
    .mapValues((column) => column.colDef.headerName)
    .value();

  return metadata.flatMap(({ recordingId, metadatum }) => {
    assert(isNonNull(recordingId) && isNonNull(metadatum));

    // Read metadatum key
    const key = metadatum.key;

    // Read metadatum name from metadata map
    const name = metadatumNamesByKey[key];
    assert(isDefined(name));

    // Coerce metadatum value into an array
    const values = Array.isArray(metadatum.activeValue)
      ? metadatum.activeValue
      : [metadatum.activeValue];

    return values.map((value) => ({
      key,
      name,
      value,
      recordingId,
    }));
  });
};
