import Axios from "axios";
import { Project } from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { ProjectStatus } from "types/constants";
import assert from "assert";

export type ProjectScheduleDeleteDjangoInput = {
  date_deleted: string;
};

type ProjectScheduleDeleteDjangoResponse = {
  data: {
    id: Project["id"];
    date_deleted: Project["dateDeleted"];
    status: Project["status"];
  };
};

export const useProjectScheduleDeleteDjango = () => {
  const projectScheduleDeleteDjango = useCallback(
    async (id: Project["id"], input: ProjectScheduleDeleteDjangoInput) => {
      const url = `${getEnvVar("URL_PROJECT_SCHEDULE_DELETE")}${id}/`;
      const headers = await getRequestHeaders();
      const { data: projectDeletedData } = await Axios.patch<
        Project["id"],
        ProjectScheduleDeleteDjangoResponse
      >(url, { ...input, status: ProjectStatus["AVAILABLE"] }, { headers });

      const { status, date_deleted } = projectDeletedData;
      assert(
        status === (ProjectStatus["PENDING_SCHEDULE_DELETE"] as const) &&
          date_deleted !== null,
      );

      return { id, status, dateDeleted: date_deleted };
    },
    [],
  );
  return { projectScheduleDeleteDjango };
};
