import { create } from "zustand";
import { File as DrsFile } from "graphql/_Types";

interface ISelectedDrsFileId {
  selectedDrsFileId: DrsFile["id"] | undefined;
  selectDrsFile: (drsFile: DrsFile["id"]) => void;
  deselectDrsFile: (drsFile: DrsFile["id"]) => void;
  deselectAll: () => void;
}

export const useSelectedDrsFileId = create<ISelectedDrsFileId>()(
  (set, get) => ({
    selectedDrsFileId: undefined,
    selectDrsFile: (drsFileId) => set({ selectedDrsFileId: drsFileId }),
    deselectDrsFile: (drsFileId) => {
      if (get().selectedDrsFileId === drsFileId) {
        set({ selectedDrsFileId: undefined });
      }
    },
    deselectAll: () => {
      set({ selectedDrsFileId: undefined });
    },
  }),
);
