import { captureException } from "@sentry/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { FILE_TYPES_BY_KEY } from "types/FileTypes";
import { FilePreviewComponentProps } from "./FilePreview.types";
import {
  FilePreviewCellSetFootprints,
  FilePreviewCellSetFootprintsProps,
} from "./FilePreviewCellSetFootprints";
import {
  FilePreviewCellSetTraces,
  FilePreviewCellSetTracesProps,
} from "./FilePreviewCellSetTraces";
import { FilePreviewError } from "./FilePreviewError";
import { FilePreviewEvents, FilePreviewEventsProps } from "./FilePreviewEvents";
import { FilePreviewLoading } from "./FilePreviewLoading";

/** Component that renders a file preview formatted as a JSON file */
export const FilePreviewJson = ({
  file,
  preview,
}: FilePreviewComponentProps) => {
  const [data, setData] = useState<unknown>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const resetState = () => {
      setData(undefined);
      setIsLoading(true);
      setError(undefined);
    };

    const fetchContent = async () => {
      try {
        resetState();
        const res = await axios.get(preview.url);
        setData(res.data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchContent();
  }, [preview.url]);

  if (isLoading) {
    return <FilePreviewLoading />;
  }

  if (error !== undefined) {
    return <FilePreviewError />;
  }

  if (
    file.fileType === FILE_TYPES_BY_KEY["cell_set"].id &&
    preview.name === "Cell set traces"
  ) {
    return (
      <FilePreviewCellSetTraces
        content={data as FilePreviewCellSetTracesProps["content"]}
      />
    );
  }

  if (
    file.fileType === FILE_TYPES_BY_KEY["cell_set"].id &&
    preview.name === "Cell set footprints"
  ) {
    return (
      <FilePreviewCellSetFootprints
        content={data as FilePreviewCellSetFootprintsProps["content"]}
      />
    );
  }

  if (
    file.fileType === FILE_TYPES_BY_KEY["neural_events"].id ||
    file.fileType === FILE_TYPES_BY_KEY["timestamp_events"].id
  ) {
    return (
      <FilePreviewEvents content={data as FilePreviewEventsProps["content"]} />
    );
  }

  // Report any file previews we didn't expect to receive
  captureException("Unsupported file preview", {
    extra: {
      preview,
    },
  });

  return null;
};
