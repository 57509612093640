import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { ModalIdentifyFile } from "components/ModalIdentifyFile/ModalIdentifyFile";
import { Tooltip } from "components/Tooltip/Tooltip";
import { File as DrsFile } from "graphql/_Types";
import { useRoutes } from "hooks/useRoutes";
import { useModalContext } from "providers/ModalProvider/ModalProvider";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";

export interface ButtonIdentifyFileProps {
  file: Pick<
    DrsFile,
    | "id"
    | "fileType"
    | "processingStatus"
    | "source"
    | "fileFormat"
    | "originalName"
    | "isSeries"
    | "seriesParentId"
    | "status"
  >;
}
export const ButtonIdentifyFile = ({ file }: ButtonIdentifyFileProps) => {
  const { routeMap } = useRoutes();
  const isViewingHistory =
    routeMap.DATASET_HISTORY.isRouteMatch() ||
    routeMap.DATASET_VERSION.isRouteMatch();

  const { openModal, closeModal } = useModalContext();

  return (
    <>
      <Tooltip content={"Change Type"}>
        <ButtonIconPermissioned
          aria-label="Set file type"
          defaultTooltip={
            isViewingHistory
              ? "Change file type disabled while viewing history"
              : "Change file type"
          }
          requiredPermission="edit"
          color={"primary"}
          onClick={() =>
            openModal(<ModalIdentifyFile file={file} onClose={closeModal} />)
          }
          iconType="documentEdit"
          disabled={
            isViewingHistory ||
            !getDrsFileModifyPermissionByDrsFileAndAction(file, "IDENTIFY")
              .isPermitted
          }
        />
      </Tooltip>
    </>
  );
};
