import { useUpdatePageDatasetCache } from "components/Dataset/hooks/useUpdatePageDatasetCache";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import { useDatasetRecordingsTableColumnPinDjango } from "hooks/useDatasetRecordingsTableColumnPinDjango";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { DatasetRecordingsTableColumn } from "graphql/_Types";
import { useProjectPermission } from "hooks/useProjectPermission";

type PinColumnParams = {
  columnId: DatasetRecordingsTableColumn["id"];
};

export const useActionPinColumn = () => {
  const { dataset, datasetMode } = useDatasetDataContext();
  const { updateCache } = useUpdatePageDatasetCache(dataset.id);
  const { pinColumn } = useDatasetRecordingsTableColumnPinDjango();
  const { hasPermission } = useProjectPermission();

  const getActionDef = useCallback(
    (params: PinColumnParams) => {
      const datePinned = new Date().toISOString();

      return {
        onEnqueue: () => {
          updateCache((prevData) => {
            const newData = cloneDeep(prevData);
            const table = newData.dataset.datasetRecordingsTable;
            const allColumns = table.datasetRecordingsTableColumns.nodes;
            const column = allColumns.find(({ id }) => id === params.columnId);
            assert(isDefined(column), "Cache missing recordings table column");
            column.pinned = true;

            /* After pinning is applied, pinned columns should appear before
               unpinned columns in the same order they appeared before pinning */

            const reorderedColumns = allColumns.sort((a, b) => {
              /* Pinned columns should appear before unpinned columns */

              if (a.pinned && !b.pinned) {
                return -1;
              }

              if (!a.pinned && b.pinned) {
                return 1;
              }

              /* All columns should have an active order but if somehow they
                 don't, order them last */

              if (a.activeOrder === null) {
                return 1;
              }

              if (b.activeOrder === null) {
                return -1;
              }

              /* If both columns are unpinned, sort them based on their active
                 orders */

              return a.activeOrder - b.activeOrder;
            });

            /* Apply order changes */

            reorderedColumns.forEach((column, idx) => {
              column.activeOrder = idx;
            });

            return newData;
          });
        },

        onDequeue: async () =>
          await pinColumn(params.columnId, {
            date_pinned: datePinned,
          }),
      };
    },
    [updateCache, pinColumn],
  );

  const isDisabled = datasetMode.type !== "current" || !hasPermission("edit");

  return {
    getActionDef,
    isDisabled,
  };
};
