import { createStore } from "zustand";
import { UserStoreState } from "./UserProvider.types";
import { Tenant, UserProviderQuery } from "graphql/_Types";
import assert from "assert";
import { isNonNull } from "utils/isNonNull";
import { TENANT_MEMBERSHIP_ROLES_BY_ID } from "types/TenantMembershipRole";
import { isDefined } from "utils/isDefined";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import axios from "axios";
import { QuotaType } from "types/QuotaType";
import { isNullish } from "utils/isNullish";

/**
 * Checks if a tenant's quota has been exceeded.
 * @param tenantId The ID of the tenant.
 * @param quotaType A specific resource quota to check.
 * @returns If a quota type is specified, returns a `boolean` representing
 * whether a tenant's quota for that resource is exceeded. Otherwise, returns
 * a `boolean` representing whether any resource quota is exceeded.
 */
const checkTenantQuota = async (
  tenantId: Tenant["id"],
  quotaType?: QuotaType,
) => {
  const baseUrl = getEnvVar("URL_TENANT_CHECK_QUOTA");
  const url = `${baseUrl}${tenantId}/`;
  const headers = await getRequestHeaders({ tenantId });
  const params = { quota_type: quotaType?.key };
  const config = { headers, params };
  const res = await axios.get<{ exceeded?: boolean }>(url, config);
  return res.data.exceeded ?? false;
};

/**
 * Gets the IDs of tenants with exceeded quotas.
 * @param data Data fetched by the UserProvider.
 * @returns A set of tenant IDs with exceeded quotas.
 */
const getTenantsWithExceededQuotas = async (data: UserProviderQuery) => {
  const { currentUser } = data;
  assert(isNonNull(currentUser));

  const tenantIds = currentUser.memberships.nodes
    .map((membership) => {
      const { tenant } = membership;
      // if a tenant is marked for deletion, can be null - filter it out
      return tenant && tenant.id;
    })
    .filter(isNonNull);

  const tenantsWithExceededQuotas = new Set<Tenant["id"]>();

  for (const tenantId of tenantIds) {
    if (await checkTenantQuota(tenantId)) {
      tenantsWithExceededQuotas.add(tenantId);
    }
  }

  return tenantsWithExceededQuotas;
};

/**
 * Creates a zustand store for data about the current user.
 * @param data Data fetched by the UserProvider.
 * @returns The new store.
 */
export const createUserStore = async (data: UserProviderQuery) => {
  const tenantsWithExceededQuotas = await getTenantsWithExceededQuotas(data);
  return createStore<UserStoreState>((set, get) => {
    // Validate current user
    const { currentUser } = data;
    assert(isNonNull(currentUser));

    // Initialize isEulaAccepted (TODO: handle the case of a rejected EULA)
    const allEulaAgreements = currentUser.eula.nodes;
    const isEulaAccepted = allEulaAgreements.some(({ accepted }) => accepted);

    // Validate tenants
    const tenants = currentUser.memberships.nodes
      .map((membership) => {
        const { tenant } = membership;
        // if a tenant is marked for deletion, can be null here
        if (isNullish(tenant)) return undefined;
        const role = TENANT_MEMBERSHIP_ROLES_BY_ID[membership.role];
        assert(isDefined(role));
        const isQuotaExceeded = tenantsWithExceededQuotas.has(tenant.id);
        return { ...tenant, role, isQuotaExceeded };
      })
      .filter(isDefined);

    return {
      currentUser,
      isEulaAccepted,
      tenants,
      checkTenantQuota: async (
        tenantId: Tenant["id"],
        quotaType?: QuotaType,
      ) => {
        const isQuotaExceeded = await checkTenantQuota(tenantId, quotaType);
        if (isQuotaExceeded) {
          set({
            tenants: get().tenants.map((tenant) => {
              return tenant.id === tenantId
                ? { ...tenant, isQuotaExceeded }
                : tenant;
            }),
          });
        }
        return isQuotaExceeded;
      },
    };
  });
};
