/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { MenuBarMenu } from "components/MenuBar/MenuBarMenu";
import { MenuBar } from "components/MenuBar/MenuBar";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { exportAsCSV } from "utils/exportAsCSV";
import { useDatasetSelectionContext } from "pages/project/dataset/SelectionProvider";

type Menu = "file" | "edit" | "view" | "insert" | "export";

export const DatasetMenuBar = () => {
  const [selectedMenu, setSelectedMenu] = useState<Menu>();
  const isAnyMenuOpen = selectedMenu !== undefined;
  const closeMenu = () => setSelectedMenu(undefined);

  const { openFlyout, openModal, gridRef } = useDatasetLayoutContext();

  const selectedRows = useDatasetSelectionContext((s) => s.selectedRows);
  const setSelectedRows = useDatasetSelectionContext((s) => s.setSelectedRows);
  const deleteRecordingAction = useDatasetAction("deleteRecording");
  const { datasetMode, recordingsTable, datasetExportName } =
    useDatasetDataContext();

  const isViewingCurrentVersion = datasetMode.type === "current";

  return (
    <MenuBar>
      <MenuBarMenu
        label="File"
        enableHoverToOpen={isAnyMenuOpen}
        isOpen={selectedMenu === "file"}
        items={[
          {
            type: "item",
            icon: "save",
            label: "Save version",
            onClick: () => openModal({ type: "createVersion" }),
            disabled:
              !isViewingCurrentVersion ||
              recordingsTable.datasetRecordingsTableColumns.nodes.length === 0,
            requiredPermission: "edit",
          },
          {
            type: "item",
            icon: "download",
            label: "Download as CSV",
            onClick: () => exportAsCSV(gridRef, datasetExportName),
            requiredPermission: "view",
          },
          {
            type: "item",
            icon: "exportAction",
            label: "Export to DANDI",
            onClick: () => openModal({ type: "exportToDandi" }),
            disabled: !isViewingCurrentVersion,
            requiredPermission: "execute",
          },
        ]}
        onClose={closeMenu}
        onOpen={() => setSelectedMenu("file")}
      />
      <MenuBarMenu
        label="Edit"
        enableHoverToOpen={isAnyMenuOpen}
        isOpen={selectedMenu === "edit"}
        items={[
          {
            type: "item",
            disabled:
              (gridRef.current?.api?.getCellRanges()?.length ?? 0) === 0,
            icon: "copy",
            label: "Copy",
            onClick: () => gridRef.current?.api?.copyToClipboard(),
            requiredPermission: "view",
          },
          {
            key: "separator_1",
            type: "separator",
          },
          {
            type: "item",
            disabled: !isViewingCurrentVersion || selectedRows.size === 0,
            icon: "trash",
            label: "Delete selected rows",
            onClick: () => {
              const dateDeleted = new Date().toISOString();
              const recordingIds = Array.from(selectedRows.keys());
              for (const recordingId of recordingIds) {
                void deleteRecordingAction.enqueue({
                  recordingId,
                  dateDeleted,
                });
              }
              setSelectedRows([]);
            },
            requiredPermission: "edit",
          },
        ]}
        onClose={closeMenu}
        onOpen={() => setSelectedMenu("edit")}
      />
      <MenuBarMenu
        label="View"
        enableHoverToOpen={isAnyMenuOpen}
        isOpen={selectedMenu === "view"}
        items={[
          {
            type: "item",
            icon: "folderOpen",
            label: "Files",
            onClick: () => openFlyout({ type: "uploadedFiles" }),
            requiredPermission: "view",
          },
          {
            type: "item",
            icon: "indexMapping",
            label: "Analyses",
            onClick: () => openFlyout({ type: "runAnalysis" }),
            requiredPermission: "view",
          },
          {
            type: "item",
            icon: "notebookApp",
            label: "Notes",
            onClick: () => openFlyout({ type: "datasetInfo" }),
            requiredPermission: "view",
          },

          {
            type: "item",
            icon: "timeRefresh",
            label: "Dataset versions",
            onClick: () => openFlyout({ type: "datasetVersions" }),
            requiredPermission: "view",
          },
        ]}
        onClose={closeMenu}
        onOpen={() => setSelectedMenu("view")}
      />
      <MenuBarMenu
        label="Insert"
        enableHoverToOpen={isAnyMenuOpen}
        isOpen={selectedMenu === "insert"}
        items={[
          {
            type: "item",
            icon: "plus",
            label: "Insert recording row(s)",
            onClick: () => {
              openModal({ type: "insertRecordings" });
            },
            disabled: !isViewingCurrentVersion,
            requiredPermission: "edit",
          },
          {
            type: "item",
            icon: "plus",
            label: "Insert column",
            onClick: () => openModal({ type: "insertColumn" }),
            disabled: !isViewingCurrentVersion,
            requiredPermission: "edit",
          },
        ]}
        onClose={closeMenu}
        onOpen={() => setSelectedMenu("insert")}
      />
    </MenuBar>
  );
};
