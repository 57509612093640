import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { useState } from "react";

export interface ModalNewVersionProps {
  newVersion: string;
}
export const ModalNewVersion = ({ newVersion }: ModalNewVersionProps) => {
  const [hideModal, setHideModal] = useState(false);

  if (hideModal) {
    return null;
  }

  return (
    <EuiModal
      onClose={() => {
        setHideModal(true);
        setTimeout(() => setHideModal(false), 60 * 5 * 1000);
      }}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          New IDEAS Version Available ({newVersion})
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <p>
          Please refresh the page to see the newest version of IDEAS. If you
          have any unsaved work, please close this dialog and save it before
          refreshing.
        </p>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={() => window.location.reload()} fill>
          Reload
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
