import {
  AnalysisTableRowAttachments,
  FileMetadatumReference,
  MetadatumReference,
  RecordingMetadatumReference,
  ToolBooleanParam,
  ToolChoiceParam,
  ToolCroppingFrameParam,
  ToolFloatRangeParam,
  ToolIntRangeParam,
  ToolParam,
  ToolParamValue,
  ToolPathParam,
  ToolRoiFrameParam,
  ToolStringParam,
  ToolCellStatusParam,
} from "./ToolParamsGrid.types";

/**
 * Type guard to check if a value is a `ToolParamValue`
 */
export const isToolParamValue = <T>(
  value: T | ToolParamValue,
): value is ToolParamValue => {
  return (
    typeof value === "string" ||
    typeof value === "boolean" ||
    typeof value === "number" ||
    typeof value === "undefined" ||
    (Array.isArray(value) && value.every((elm) => typeof elm === "string"))
  );
};

/**
 * Type guard to check if a tool spec param is a `ToolBooleanParam`
 */
export const isToolBooleanParam = (
  toolParam: ToolParam,
): toolParam is ToolBooleanParam => {
  return toolParam.type.param_type === "ToolBooleanParam";
};

/**
 * Type guard to check if a tool spec param is a `ToolChoiceParam`
 */
export const isToolChoiceParam = (
  toolParam: ToolParam,
): toolParam is ToolChoiceParam => {
  return toolParam.type.param_type === "ToolChoiceParam";
};

/**
 * Type guard to check if a tool spec param is a `ToolFloatRangeParam`
 */
export const isToolFloatRangeParam = (
  toolParam: ToolParam,
): toolParam is ToolFloatRangeParam => {
  return toolParam.type.param_type === "ToolFloatRangeParam";
};

/**
 * Type guard to check if a tool spec param is a `ToolIntRangeParam`
 */
export const isToolIntRangeParam = (
  toolParam: ToolParam,
): toolParam is ToolIntRangeParam => {
  return toolParam.type.param_type === "ToolIntRangeParam";
};

/**
 * Type guard to check if a tool spec param is a `ToolPathParam`
 */
export const isToolPathParam = (
  toolParam: ToolParam,
): toolParam is ToolPathParam => {
  return toolParam.type.param_type === "ToolPathParam";
};

/**
 * Type guard to check if a tool spec param is a `ToolCroppingFrameParam`
 */
export const isToolCroppingFrameParam = (
  toolParam: ToolParam,
): toolParam is ToolCroppingFrameParam => {
  return toolParam.type.param_type === "ToolCroppingFrameParam";
};

/**
 * Type guard to check if a tool spec param is a `ToolRoiFrameParam`
 */
export const isToolRoiFrameParam = (
  toolParam: ToolParam,
): toolParam is ToolRoiFrameParam => {
  return toolParam.type.param_type === "ToolRoiFrameParam";
};

/**
 * Type guard to check if a tool spec param is a `ToolCellStatusParam`
 */
export const isToolCellStatusParam = (
  toolParam: ToolParam,
): toolParam is ToolCellStatusParam => {
  return toolParam.type.param_type === "ToolCellStatusParam";
};

/**
 * Type guard to check if a tool spec param is a `ToolStringParam`
 */
export const isToolStringParam = (
  toolParam: ToolParam,
): toolParam is ToolStringParam => {
  return toolParam.type.param_type === "ToolStringParam";
};

/**
 * Type guard to check if a `MetadatumReference` is a `RecordingMetadatumReference`
 */
export const isRecordingMetadatumReference = (
  reference: MetadatumReference,
): reference is RecordingMetadatumReference => {
  return reference.type === "recording";
};

/**
 * Type guard to check if a `MetadatumReference` is a `FileMetadatumReference`
 */
export const isFileMetadatumReference = (
  reference: MetadatumReference,
): reference is FileMetadatumReference => {
  return reference.type === "file";
};

/**
 * Type guard to check if an object is an attachments field from AnalysisTableRow
 */
export const isAnalysisTableRowAttachments = (
  input: unknown,
): input is AnalysisTableRowAttachments => {
  return (
    typeof input === "object" &&
    input !== null &&
    "recordings" in input &&
    "datasets" in input &&
    Array.isArray(input["recordings"]) &&
    Array.isArray(input["datasets"])
  );
};
