import {
  ComparatorOptions,
  DEFAULT_COMPARATOR_OPTIONS,
} from "types/comparators";

/**
 * Used to compare nullable date fields with Array.prototype.sort
 * @param a sort value a
 * @param b sort value b
 * @param key key where date string is stored
 * @param options sort order and where to place nulls (default descending, nulls last)
 */
export function compareDates<
  T extends Record<K, string | null>,
  K extends keyof T,
>(a: T, b: T, key: K, options?: ComparatorOptions) {
  const { orderBy, nulls } = { ...DEFAULT_COMPARATOR_OPTIONS, ...options };

  let nullFallbackDate: number;
  if (nulls === "FIRST") {
    nullFallbackDate = orderBy === "DESC" ? Infinity : -Infinity;
  } else {
    nullFallbackDate = orderBy === "ASC" ? Infinity : -Infinity;
  }

  const aDate = a[key];
  const bDate = b[key];

  const aTime = aDate !== null ? new Date(aDate).getTime() : nullFallbackDate;
  const bTime = bDate !== null ? new Date(bDate).getTime() : nullFallbackDate;

  const orderBySign = orderBy === "ASC" ? 1 : -1;
  return (aTime - bTime) * orderBySign;
}
