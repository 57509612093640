import { gql } from "@apollo/client";

/**
 * The subset of project fields returned by queries and mutations
 */

export const PROJECT_FIELDS = gql`
  fragment ProjectFields on Project {
    id
    key
    name
    shortDescription
    description
    icon
    iconColor
    iconText
    dateCreated
    tenantId
    userId
    dateArchived
    dateDeleted
    dateDataDeleted
    status
    activeStorageSize
    archivedStorageSize
    defaultUserAccessLevel
    pinned
    usedCredits
  }
`;
