/** @jsxImportSource @emotion/react */
import { useDraggable } from "@dnd-kit/core";
import { css } from "@emotion/react";
import {
  DraggableData,
  generateDatasetDraggableId,
} from "components/Dataset/DatasetDndProvider";
import { File as DrsFile } from "graphql/_Types";
import { memo, useState } from "react";
import { FileBadge } from "components/FileBadge/FileBadge";
import { isDefined } from "utils/isDefined";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { useDatasetAction } from "../../hooks/useDatasetAction/useDatasetAction";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { isDrsFileAllowedInColumn } from "utils/isDrsFileAllowedInColumn";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";

const cursorCss = (hasOnClick: boolean, isDisabled: boolean | undefined) => {
  if (hasOnClick) {
    if (isDisabled) {
      return css`
        cursor: pointer;
      `;
    } else {
      return css`
        cursor: grab;
      `;
    }
  }
};
const styles = {
  root: (hasOnClick: boolean, isDisabled: boolean | undefined) => [
    css`
      height: auto;
      max-width: 100%;
      position: relative;
      width: auto;
    `,
    cursorCss(hasOnClick, isDisabled),
  ],
  badge: (hasOnClick: boolean, isDisabled: boolean | undefined) =>
    cursorCss(hasOnClick, isDisabled),
};

interface DraggableFileBadgeProps {
  drsFile: Pick<
    DrsFile,
    | "id"
    | "name"
    | "originalName"
    | "status"
    | "fileType"
    | "fileFormat"
    | "datasetId"
    | "isSeries"
    | "seriesParentId"
    | "source"
    | "projectId"
    | "processingStatus"
  >;
  compact?: boolean;
  disabled?: boolean;
  suppressContextMenu?: boolean;
  onClick?: () => void;
}

export const DraggableFileBadge = memo(function DraggableFileBadge({
  drsFile,
  compact,
  disabled,
  suppressContextMenu,
  onClick,
}: DraggableFileBadgeProps) {
  const { openModal } = useDatasetLayoutContext();
  const assignFileAction = useDatasetAction("assignFile");
  const unassignFileAction = useDatasetAction("unassignFile");
  const scheduleDeleteFileAction = useDatasetAction("scheduleDeleteFile");

  const columns = useProjectFilesStore(
    (s) => s.files.find((file) => file.id === drsFile.id)?.columns,
  );
  // can assume 1 column for an uploaded file
  const column = columns ? columns[0] : undefined;

  const recording = useProjectFilesStore(
    (s) =>
      // only uploaded files use DraggableFileBadge and therefore can assume one recording match
      // if DraggableFileBadges are used for results in the future, this will need to change
      s.files.find((file) => file.id === drsFile.id)?.recordings?.[0],
  );

  const isMisplaced =
    isDefined(column) && !isDrsFileAllowedInColumn(drsFile, column);

  const data: DraggableData = {
    drsFile: drsFile,
    recordingId: recording?.id,
    columnId: column?.id,
  };
  const [id] = useState(generateDatasetDraggableId(data));

  const isDisabled = assignFileAction.isDisabled || disabled;

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef: draggableRef,
  } = useDraggable({
    id,
    data,
    disabled: isDisabled,
  });

  return (
    <div
      {...attributes}
      {...listeners}
      css={styles.root(onClick !== undefined, isDisabled)}
      ref={draggableRef}
    >
      <FileBadge
        cssOverrides={styles.badge(onClick !== undefined, isDisabled)}
        onClick={onClick}
        suppressContextMenu={suppressContextMenu}
        onDelete={() =>
          openModal({
            type: "deleteDrsFile",
            props: {
              drsFiles: [drsFile],
            },
          })
        }
        onArchive={() =>
          openModal({
            type: "archiveDrsFile",
            props: {
              drsFiles: [drsFile],
            },
          })
        }
        onUnarchive={() =>
          openModal({
            type: "unarchiveDrsFile",
            props: {
              drsFiles: [drsFile],
            },
          })
        }
        onUnassign={
          column && recording
            ? () =>
                unassignFileAction.enqueue({
                  drsFileId: drsFile.id,
                  recordingId: recording.id,
                  columnId: column.id,
                })
            : undefined
        }
        onRename={() =>
          openModal({ type: "renameDrsFile", props: { drsFile } })
        }
        onIdentify={
          getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "IDENTIFY")
            .isPermitted
            ? () =>
                openModal({ type: "identifyFile", props: { file: drsFile } })
            : undefined
        }
        onCancelUpload={() =>
          void scheduleDeleteFileAction.enqueue({
            fileId: drsFile.id,
          })
        }
        drsFile={drsFile}
        isDraggingActive={isDragging}
        isMisplaced={isMisplaced}
        compact={compact}
      />
    </div>
  );
});
