/** @jsxImportSource @emotion/react */
import { FormEventHandler, useCallback, useState } from "react";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCheckbox,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiForm,
  EuiFormFieldset,
  EuiFormRow,
  EuiSpacer,
  EuiToolTip,
} from "@inscopix/ideas-eui";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { isDefined } from "utils/isDefined";
import { chain, filter } from "lodash";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { isAnalysisResultColumn } from "components/RecordingsGrid/RecordingsGrid.helpers";
import { useMenuInsertColumnStyles } from "./useMenuInsertColumnStyles";

type PanelInsertAnalysisResultColumnsProps = {
  onClose: () => void;
};

/**
 * Context menu panel for adding analysis result columns from an analysis table to a recordings grid
 */
function PanelInsertAnalysisResultColumns({
  onClose,
}: PanelInsertAnalysisResultColumnsProps) {
  const createColumnAction = useDatasetAction("createColumn");
  const { analysisTableGroups } = useProjectDataContext();
  const { recordingsTable } = useDatasetDataContext();
  const { openFlyout } = useDatasetLayoutContext();
  const { panelStyles } = useMenuInsertColumnStyles();

  const [
    selectedAnalysisTableGroupOption,
    setSelectedAnalysisTableGroupOption,
  ] = useState<EuiComboBoxOptionOption<string>>();
  const [selectedAnalysisTableOption, setSelectedAnalysisTableOption] =
    useState<EuiComboBoxOptionOption<string>>();
  const [selectedResultColumns, setSelectedResultColumns] = useState<string[]>(
    [],
  );

  // auto focus the analysis table group selection input when loaded
  const setGroupRef: React.RefCallback<HTMLInputElement> = useCallback(
    (node) => {
      // timeout needed to get around EUI focusing the context menu title
      setTimeout(() => {
        node?.focus();
      }, 500);
    },
    [],
  );

  /**
   * Checks whether an analysis results column already exists for a given
   * analysis table and result key
   * @param analysisTableId
   * @param resultKey
   * @returns A `boolean` representing whether the column already exists
   */
  const isExistingColumn = (analysisTableId: string, resultKey: string) => {
    return recordingsTable.datasetRecordingsTableColumns.nodes
      .filter(isAnalysisResultColumn)
      .some((column) => {
        return (
          column.colDef.analysisTableId === analysisTableId &&
          column.colDef.resultKey === resultKey
        );
      });
  };

  const groups = analysisTableGroups.filter((group) => !group.hidden); // Filtering out hidden groups

  // get selected group from ID
  const selectedGroup = groups.find(
    ({ id }) => id === selectedAnalysisTableGroupOption?.key,
  );

  const tables = selectedGroup?.analysisTables
    .map((table) => {
      // get list of object results
      const toolResults = (() => {
        const groupResults = table.toolSpec.results;
        // assume only one group result
        if (groupResults.length > 0) {
          return groupResults[0].files;
        } else {
          return [];
        }
      })();

      return toolResults !== undefined
        ? {
            id: table.id,
            name: table.name,
            toolResults,
          }
        : undefined;
    })
    .filter(isDefined);

  // get selected table from ID
  const selectedTable = tables?.find(
    ({ id }) => id === selectedAnalysisTableOption?.key,
  );

  const handleSelectionGroup = (
    groupOption: EuiComboBoxOptionOption<string>,
  ) => {
    setSelectedAnalysisTableGroupOption(groupOption);

    const group = groups.find((group) => group.id === groupOption.key);
    const latestTable = chain(group?.analysisTables ?? [])
      .sortBy((table) => table.dateCreated)
      .last()
      .value();

    // Auto-select the latest table
    if (isDefined(latestTable)) {
      const toolResults = (() => {
        const groupResults = latestTable.toolSpec.results ?? [];
        // assume only one group result
        if (groupResults.length > 0) {
          return groupResults[0].files.map((file) => file.result_key);
        } else {
          return [];
        }
      })();

      setSelectedAnalysisTableOption({
        label: latestTable.name,
        id: String(latestTable.id),
        key: latestTable.id,
      });
      setSelectedResultColumns(toolResults);
    } else {
      setSelectedAnalysisTableOption(undefined);
      setSelectedResultColumns([]);
    }
  };

  const handleSelectionTable = (
    tableOption: EuiComboBoxOptionOption<string>,
  ) => {
    const newSelectedTable = tables?.find(({ id }) => id === tableOption?.key);
    const toolResultKeys =
      newSelectedTable?.toolResults
        .map((toolResult) => toolResult.result_key)
        .filter(
          (resultKey) => !isExistingColumn(newSelectedTable.id, resultKey),
        ) ?? [];
    setSelectedAnalysisTableOption(tableOption);
    setSelectedResultColumns(toolResultKeys);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (
      selectedTable?.id !== undefined &&
      selectedResultColumns !== undefined
    ) {
      selectedResultColumns.forEach((resultKey) => {
        void createColumnAction.enqueue({
          colDef: {
            type: "analysisResult",
            analysisTableId: selectedTable.id,
            resultKey,
          },
        });
      });
      onClose();
    }
  };

  const isSubmitDisabled =
    selectedAnalysisTableGroupOption === undefined ||
    selectedResultColumns.length === 0;

  const submitButtonTooltip = isSubmitDisabled
    ? selectedAnalysisTableGroupOption === undefined
      ? "Select an analysis table"
      : "At least one column must be visible"
    : null;

  const groupOptions = groups.map((group) => {
    return {
      label: group.name,
      id: String(group.id),
      key: group.id,
    };
  });

  const tableOptions = (tables ?? []).map((table) => {
    return {
      label: table.name,
      id: String(table.id),
      key: table.id,
    };
  });

  const resultSwitches =
    selectedTable?.toolResults.map((result) => {
      return (
        <EuiCheckbox
          name={result.result_name}
          id={result.result_key}
          key={result.result_key}
          label={result.result_name}
          checked={
            // force checked on if column already exists in the recordings table
            isExistingColumn(selectedTable.id, result.result_key) ||
            // otherwise check our selections
            selectedResultColumns.includes(result.result_key)
          }
          disabled={isExistingColumn(selectedTable.id, result.result_key)}
          compressed
          onChange={() => {
            const newKeys = selectedResultColumns.includes(result.result_key)
              ? filter(
                  [...selectedResultColumns],
                  (resultKey) => resultKey !== result.result_key,
                )
              : [...selectedResultColumns, result.result_key];
            setSelectedResultColumns(newKeys);
          }}
        />
      );
    }) ?? [];

  return (
    <EuiForm
      component="form"
      role="form"
      onSubmit={handleSubmit}
      css={panelStyles}
    >
      {groups.length === 0 ? (
        <EuiEmptyPrompt
          title={<h2>No analyses found</h2>}
          titleSize="xs"
          body={
            <p>Start a new analysis table to start generating new results.</p>
          }
          actions={
            <EuiButton
              size="s"
              color="primary"
              fill
              onClick={() => {
                openFlyout({ type: "runAnalysis" });
                onClose();
              }}
            >
              Add analysis
            </EuiButton>
          }
        />
      ) : (
        <EuiFormRow label="Analysis Table" display="rowCompressed">
          <EuiComboBox<string>
            aria-label="Select data column type"
            options={groupOptions}
            onChange={(newSelectedOptions) =>
              handleSelectionGroup(newSelectedOptions[0])
            }
            singleSelection={true}
            selectedOptions={
              selectedAnalysisTableGroupOption
                ? [selectedAnalysisTableGroupOption]
                : []
            }
            placeholder={`Select an option or start typing to search`}
            compressed
            inputRef={setGroupRef}
          />
        </EuiFormRow>
      )}
      {isDefined(selectedAnalysisTableGroupOption) && (
        <EuiFormRow label="Tab" display="rowCompressed">
          <EuiComboBox<string>
            aria-label="Select data column type"
            options={tableOptions}
            onChange={(newSelectedOptions) =>
              handleSelectionTable(newSelectedOptions[0])
            }
            singleSelection={true}
            selectedOptions={
              selectedAnalysisTableOption ? [selectedAnalysisTableOption] : []
            }
            placeholder={`Select an option or start typing to search`}
            compressed
          />
        </EuiFormRow>
      )}
      <EuiSpacer size="s" />
      <EuiFormFieldset
        legend={{
          children:
            resultSwitches.length > 0
              ? "Choose which result columns you would like to add from the selected analysis table:"
              : "",
        }}
      >
        {resultSwitches}
      </EuiFormFieldset>
      <EuiSpacer />
      <EuiFlexGroup justifyContent="flexEnd">
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiToolTip content={submitButtonTooltip}>
          <EuiButton type="submit" fill disabled={isSubmitDisabled}>
            Add Column
          </EuiButton>
        </EuiToolTip>
      </EuiFlexGroup>
    </EuiForm>
  );
}

export default PanelInsertAnalysisResultColumns;
