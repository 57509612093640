import { htmlIdGenerator } from "@inscopix/ideas-eui";
import { useEffect, useState } from "react";
import * as fabric from "fabric";

export interface CroppingFrameSelectorCanvasProps {
  backgroundImage: fabric.Image;
  onCanvasReady?: (canvas: fabric.Canvas) => void;
}

export const CroppingFrameSelectorCanvas = ({
  backgroundImage,
  onCanvasReady,
}: CroppingFrameSelectorCanvasProps) => {
  const [canvasId] = useState(htmlIdGenerator("canvas"));

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isReady) {
      const newCanvas = new fabric.Canvas(canvasId, {
        height: backgroundImage.height,
        width: backgroundImage.width,
        backgroundImage,
        preserveObjectStacking: true,
        uniformScaling: false,
      });

      if (onCanvasReady !== undefined) {
        onCanvasReady(newCanvas);
      }

      setIsReady(true);
    }
  }, [backgroundImage, canvasId, isReady, onCanvasReady]);

  return <canvas id={canvasId} />;
};
