/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { EuiLink } from "@inscopix/ideas-eui";
import { useRoutes } from "hooks/useRoutes";

const logoStyle = css`
  vertical-align: middle;
  padding-left: 8px !important;
  padding-right: 8px !important;
  height: 16px;
`;

/**
 * Renders "IDEAS" title
 * Navigate home on click (preserves project key from route params)
 */

function AppName() {
  const { routeMap } = useRoutes();
  return (
    <EuiLink className={"euiHeaderLogo"} to={routeMap["ROOT"].path}>
      <div css={logoStyle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="64"
          height="16"
          viewBox="0 0 196 49"
          fill="none"
        >
          <g fill="#fff">
            <use xlinkHref="#B" />
            <use xlinkHref="#C" />
            <use xlinkHref="#D" />
            <use xlinkHref="#E" />
            <use xlinkHref="#F" />
          </g>
          <g stroke="#fff" strokeWidth="2">
            <use xlinkHref="#B" />
            <use xlinkHref="#C" />
            <use xlinkHref="#D" />
            <use xlinkHref="#E" />
            <use xlinkHref="#F" />
          </g>
          <defs>
            <path
              id="B"
              d="M5.852 44.9c0 .56-.196 1.064-.588 1.512-.392.392-.896.588-1.512.588-.56 0-1.064-.196-1.512-.588-.392-.448-.588-.952-.588-1.512V4.16c0-.616.196-1.12.588-1.512.448-.392.952-.588 1.512-.588.616 0 1.12.196 1.512.588s.588.896.588 1.512V44.9z"
            />
            <path
              id="C"
              d="M24.824 47c-.56 0-1.064-.196-1.512-.588-.392-.448-.588-.952-.588-1.512V4.16c0-.616.196-1.12.588-1.512.448-.392.952-.588 1.512-.588h14.784c3.696 0 7.14.952 10.332 2.856 3.248 1.904 5.852 4.536 7.812 7.896 2.016 3.36 3.024 7.252 3.024 11.676 0 4.2-.896 8.008-2.688 11.424a20.5 20.5 0 0 1-7.392 8.064C47.56 45.992 43.864 47 39.608 47H24.824zm2.1-40.74V42.8h12.684c3.472 0 6.468-.812 8.988-2.436a16.4 16.4 0 0 0 5.88-6.636c1.4-2.8 2.1-5.88 2.1-9.24 0-3.528-.784-6.664-2.352-9.408s-3.64-4.9-6.216-6.468-5.376-2.352-8.4-2.352H26.924z"
            />
            <path
              id="D"
              d="M76.258 47c-.56 0-1.064-.196-1.512-.588-.392-.448-.588-.952-.588-1.512V4.16c0-.56.168-1.036.504-1.428.392-.448.84-.672 1.344-.672h23.52c.616 0 1.12.196 1.512.588s.588.896.588 1.512c0 .56-.196 1.064-.588 1.512-.392.392-.896.588-1.512.588H78.358v14.616h17.556c.616 0 1.12.196 1.512.588s.588.896.588 1.512c0 .56-.196 1.064-.588 1.512-.392.392-.896.588-1.512.588H78.358V42.8h21.336c.616 0 1.12.196 1.512.588s.588.896.588 1.512c0 .56-.196 1.064-.588 1.512-.392.392-.896.588-1.512.588H76.258z"
            />
            <path
              id="E"
              d="M150.1 47c-.952 0-1.596-.448-1.932-1.344l-2.016-5.04-2.52-5.796h-23.688l-2.268 5.796-1.848 4.956a2.31 2.31 0 0 1-.84 1.092 2.5 2.5 0 0 1-1.26.336c-.504 0-.98-.196-1.428-.588-.392-.448-.588-.98-.588-1.596 0-.168.028-.392.084-.672.112-.336.364-.98.756-1.932l3.36-8.652 4.284-10.164 4.62-10.668 4.536-10.164c.392-.84 1.064-1.26 2.016-1.26h.336c1.008 0 1.68.392 2.016 1.176l4.788 10.5 5.04 11.172L148 34.4l3.276 7.812c.448 1.008.7 1.68.756 2.016.112.336.168.56.168.672 0 .56-.224 1.064-.672 1.512-.392.392-.868.588-1.428.588zm-8.232-16.38l-5.124-11.508-5.208-11.172-4.956 11.256-4.872 11.424h20.16z"
            />
            <path
              id="F"
              d="M187.896 7.604c-1.344-.56-2.828-1.008-4.452-1.344-1.568-.336-3.64-.504-6.216-.504-2.856 0-5.124.728-6.804 2.184-1.68 1.4-2.52 2.996-2.52 4.788s.784 3.416 2.352 4.872 4.62 2.716 9.156 3.78c4.032.896 7.14 2.044 9.324 3.444 2.24 1.4 3.808 2.996 4.704 4.788.896 1.736 1.344 3.668 1.344 5.796 0 2.072-.532 4.06-1.596 5.964-1.008 1.848-2.688 3.388-5.04 4.62-2.296 1.176-5.376 1.764-9.24 1.764-5.488 0-10.556-1.288-15.204-3.864-.728-.448-1.092-1.064-1.092-1.848 0-.504.196-.98.588-1.428s.896-.672 1.512-.672c.336 0 .672.084 1.008.252 3.976 2.24 8.372 3.36 13.188 3.36 3.808 0 6.692-.784 8.652-2.352 2.016-1.624 3.024-3.556 3.024-5.796 0-2.352-.952-4.312-2.856-5.88-1.848-1.568-4.9-2.884-9.156-3.948-3.976-1.008-7.056-2.156-9.24-3.444-2.128-1.288-3.612-2.744-4.452-4.368-.784-1.624-1.176-3.416-1.176-5.376 0-1.792.504-3.5 1.512-5.124 1.064-1.68 2.604-3.052 4.62-4.116s4.48-1.596 7.392-1.596c3.08 0 5.544.252 7.392.756 1.904.448 3.892 1.092 5.964 1.932.896.336 1.344.98 1.344 1.932 0 .56-.224 1.064-.672 1.512-.392.392-.868.588-1.428.588-.112 0-.28-.028-.504-.084-.224-.112-.7-.308-1.428-.588z"
            />
          </defs>
        </svg>
      </div>
    </EuiLink>
  );
}

export default AppName;
