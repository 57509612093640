import { EuiButtonIcon } from "@inscopix/ideas-eui";

export interface ButtonExpandFlyoutProps {
  onClick: () => void;
  isExpanded: boolean;
}
export const ButtonExpandFlyout = ({
  onClick,
  isExpanded,
}: ButtonExpandFlyoutProps) => {
  return isExpanded ? (
    <EuiButtonIcon
      key="Shrink flyout"
      title="Shrink flyout"
      aria-label="Shrink flyout"
      iconType="minimize"
      color="text"
      onClick={onClick}
    />
  ) : (
    <EuiButtonIcon
      key="Expand flyout"
      title="Expand flyout"
      aria-label="Expand flyout"
      iconType="expand"
      color="text"
      onClick={onClick}
    />
  );
};
