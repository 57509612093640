/** @jsxImportSource @emotion/react */
import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@inscopix/ideas-eui";
import { useDataSelectorStyles } from "./useDataSelectorStyles";
import { NoMatchWarning } from "./NoMatchWarning";
import { TSelectionDrsFile } from "./DataSelector.types";

interface DataSelectorColumnSelectorProps {
  item: TSelectionDrsFile;
  isFileSelected: boolean;
  toggleFileSelection: (file: TSelectionDrsFile) => void;
  isMultiselectEnabled: boolean;
}
export const DataSelectorDrsFile = ({
  item,
  isFileSelected,
  toggleFileSelection,
  isMultiselectEnabled,
}: DataSelectorColumnSelectorProps) => {
  const styles = useDataSelectorStyles();
  return (
    <EuiFlexGroup
      key={item.id}
      css={styles.selectionFile(isFileSelected, item.hasMatch)}
      onClick={() => toggleFileSelection(item)}
    >
      {isMultiselectEnabled && (
        <EuiFlexItem grow={false}>
          {isFileSelected ? (
            <EuiIcon type={"check"} />
          ) : (
            <EuiIcon type={"empty"} />
          )}
        </EuiFlexItem>
      )}
      {isFileSelected && !item.hasMatch && (
        <EuiFlexItem grow={false}>
          <NoMatchWarning />
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={false} css={styles.label}>
        {item.label}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
