import axios from "axios";
import {
  DatasetRecordingsTable,
  DatasetRecordingsTableColumn,
} from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { getEnvVar } from "ideas.env";
import { useCallback } from "react";

export type DatasetRecordingsTableSetIdentifierColumnsInput = {
  columns: DatasetRecordingsTableColumn["id"][];
};

type DatasetRecordingsTableSetIdentifierColumnsDjangoResponse = {
  data: {
    id: DatasetRecordingsTable["id"];
  };
};

export const useDatasetRecordingsTableSetIdentifierColumns = () => {
  const setIdentifierColumns = useCallback(
    async (
      recordingsTableId: DatasetRecordingsTable["id"],
      identifierColumnIds: DatasetRecordingsTableColumn["id"][],
    ) => {
      const url = `${getEnvVar(
        "URL_DRS_DATASET_RECORDINGS_TABLE_SET_IDENTIFIER_COLUMNS",
      )}${recordingsTableId}/`;

      const input = { columns: identifierColumnIds };
      const headers = await getRequestHeaders();
      const res = await axios.patch<
        DatasetRecordingsTableSetIdentifierColumnsInput,
        DatasetRecordingsTableSetIdentifierColumnsDjangoResponse
      >(url, input, { headers });

      return res.data;
    },
    [],
  );

  return { setIdentifierColumns };
};
