import assert from "assert";
import { RecordingsGridRowDatum } from "components/RecordingsGrid/RecordingsGrid.types";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { createContext, useContext, useEffect, useState } from "react";
import { create, StoreApi, UseBoundStore, useStore } from "zustand";
import { useDatasetDataContext } from "./DatasetDataProvider";
import { useDatasetLayoutContext } from "./DatasetLayoutProvider";

export interface SelectionStoreState<T extends { id: string | number }> {
  selectedRows: ReadonlyMap<T["id"], T>;
  setSelectedRows: (selectedRows: T[]) => void;
}

const DatasetSelectionContext = createContext<
  | UseBoundStore<StoreApi<SelectionStoreState<RecordingsGridRowDatum>>>
  | undefined
>(undefined);

export const useDatasetSelectionContext = <S,>(
  selector: (state: SelectionStoreState<RecordingsGridRowDatum>) => S,
) => {
  const value = useContext(DatasetSelectionContext);
  assert(
    value !== undefined,
    new ContextOutOfBoundsError("DatasetSelectionContext"),
  );

  return useStore(value, selector);
};

export const DatasetSelectionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [store] = useState(() =>
    create<SelectionStoreState<RecordingsGridRowDatum>>((set, get) => {
      return {
        selectedRows: new Map(),
        setSelectedRows: (selectedRows) => {
          const newSelectedRows = new Map(
            selectedRows.map((row) => [row.id, row]),
          );
          set({ ...get(), selectedRows: newSelectedRows });
        },
      };
    }),
  );

  /**
   * Clear row selections when toggling dataset modes
   */
  const { datasetMode } = useDatasetDataContext();
  const { gridRef } = useDatasetLayoutContext();
  useEffect(() => {
    if (datasetMode.type !== "current") {
      store.getState().setSelectedRows([]);
      gridRef?.current?.api?.deselectAll();
    }
  }, [datasetMode.type, gridRef, store]);

  return (
    <DatasetSelectionContext.Provider value={store}>
      {children}
    </DatasetSelectionContext.Provider>
  );
};
