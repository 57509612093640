import {
  EuiSkeletonText,
  EuiCallOut,
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { useGetAllEulaVersionsQuery } from "graphql/_Types";
import { EulaBody } from "./EulaBody";

interface ModalEulaProps {
  onClose: () => void;
}

export const ModalEula = ({ onClose }: ModalEulaProps) => {
  const {
    data: dataEulaContent,
    loading: loadingEulaContent,
    error: errorEulaContent,
  } = useGetAllEulaVersionsQuery({
    onError: (err) => captureException(err),
  });

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>End User License Agreement</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        {loadingEulaContent ? (
          <EuiSkeletonText lines={5} />
        ) : errorEulaContent ||
          dataEulaContent?.allEulaVersions?.nodes[0]?.content === undefined ? (
          <EuiCallOut
            title="Sorry, there was an error"
            color="danger"
            iconType="warning"
          >
            <p>
              There was an error loading the content of the EULA. Please reload
              the page to try again.
            </p>
          </EuiCallOut>
        ) : (
          <EulaBody
            eulaContent={dataEulaContent?.allEulaVersions?.nodes[0]?.content}
          />
        )}
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={onClose} fill>
          Close
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
