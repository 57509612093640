import { createContext, useContext, useMemo } from "react";
import { Tool, ToolVersion, useToolSpecProviderQuery } from "graphql/_Types";
import { ToolSpec } from "components/ToolParamsGrid/ToolParamsGrid.types";
import { EuiSkeletonText } from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { captureException } from "@sentry/react";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

type ToolSpecProviderContextValue = {
  availableToolSpecs: ToolSpecAndToolVersionId[];
};

export const ToolSpecProviderContext = createContext<
  ToolSpecProviderContextValue | undefined
>(undefined);

export const useToolSpecContext = () => {
  const context = useContext(ToolSpecProviderContext);
  if (context === undefined) {
    throw new ContextOutOfBoundsError("UserProviderContext");
  }

  return context;
};

/**
 * Type guard to check if a tool version is a version of an "analysis" tool.
 * "Analysis" tools are executed from analysis tables whereas "utility" tools
 * (e.g. bundle tool, export to dandi, etc) are run outside of analysis tables.
 */
const isAnalysisToolVersion = <T extends Pick<ToolVersion, "toolSpec">>(
  toolVersion: T,
): toolVersion is T & { toolSpec: ToolSpec } => {
  const toolSpec = toolVersion.toolSpec as ToolSpec | null;
  const isUtilityToolVersion = toolSpec?.toolkit === "System Utilities";
  return toolSpec !== null && !isUtilityToolVersion;
};

interface ToolSpecProviderProps {
  children: React.ReactNode;
}

export type ToolSpecAndToolVersionId = {
  spec: ToolSpec;
  toolId: Tool["id"];
  toolVersionId: ToolVersion["id"];
  maturity: ToolVersion["maturity"];
  credits: ToolVersion["credits"];
  versionCreated: ToolVersion["created"];
  toolCreated: Tool["created"];
};

export const ToolSpecProvider = ({ children }: ToolSpecProviderProps) => {
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { data, error, loading } = useToolSpecProviderQuery({
    variables: {
      tenantId: currentTenant.id,
    },
    onError: (err) => captureException(err),
  });

  const availableToolSpecs = useMemo(() => {
    return data?.tools?.nodes
      .flatMap((tool) =>
        tool.toolVersions.nodes.map((toolVersion) => ({
          ...toolVersion,
          toolCreated: tool.created,
        })),
      )
      .filter(isAnalysisToolVersion)
      .map((toolVersion) => ({
        toolId: toolVersion.toolId,
        toolVersionId: toolVersion.id,
        spec: toolVersion.toolSpec,
        maturity: toolVersion.maturity,
        credits: toolVersion.credits,
        versionCreated: toolVersion.created,
        toolCreated: toolVersion.toolCreated,
      }));
  }, [data]);

  if (loading) {
    return <EuiSkeletonText lines={5} />;
  }

  if (error || availableToolSpecs === undefined) {
    return <CallOutError />;
  }

  return (
    <ToolSpecProviderContext.Provider value={{ availableToolSpecs }}>
      {children}
    </ToolSpecProviderContext.Provider>
  );
};
