/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ICellRendererParams } from "ag-grid-community";
import { MetadataValue } from "graphql/_Types";
import { RecordingsGridRowDatum } from "./RecordingsGrid.types";
import { useDndContext } from "@dnd-kit/core";
import { useEuiTheme } from "@inscopix/ideas-eui";
import { stringifyMetadataValue } from "utils/stringifyMetadataValue";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
  `,
  content: css`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  dropZone: (backgroundColor: string) => css`
    background-color: ${backgroundColor};
  `,
};

type GridRendererMetadataProps = ICellRendererParams<
  RecordingsGridRowDatum,
  MetadataValue["value"] | undefined
>;

export const GridRendererMetadata = ({ value }: GridRendererMetadataProps) => {
  const { euiTheme } = useEuiTheme();
  const { active } = useDndContext();

  const rootCss = (() => {
    const css = [styles.root];

    if (active === null) {
      return css;
    } else {
      const bgColor = euiTheme.colors.lightestShade;
      css.push(styles.dropZone(bgColor));
    }

    return css;
  })();

  return (
    <div css={rootCss}>
      <div css={styles.content}>{stringifyMetadataValue(value)}</div>
    </div>
  );
};
