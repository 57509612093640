import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiTitle,
} from "@inscopix/ideas-eui";
import {
  ProjectCard,
  ProjectCardProps,
} from "components/ProjectCard/ProjectCard";

export interface PinnedProjectsProps {
  projects: ProjectCardProps["project"][];
}
export const PinnedProjects = ({ projects }: PinnedProjectsProps) => {
  return (
    <>
      <EuiFlexItem>
        <EuiTitle>
          <h4>Pinned Projects</h4>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexGroup direction="column" gutterSize="s">
        {projects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </EuiFlexGroup>
      <EuiHorizontalRule margin="s" />
    </>
  );
};
