/** @jsxImportSource @emotion/react */
import {
  EuiButtonIcon,
  EuiFormRow,
  EuiLink,
  EuiPopover,
  EuiText,
} from "@inscopix/ideas-eui";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { useState } from "react";
import { sleep } from "utils/sleep";
import {
  AnalysisResultColumn,
  DrsFileColumn,
  isAnalysisResultColumn,
  isDrsFileColumn,
  LinkedMetadataColumn,
} from "./RecordingsGrid.helpers";
import { css } from "@emotion/react";
import { metadataMap } from "types/MetadataMap";
import { PageProjectData } from "pages/project/usePageProjectData";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { captureException } from "@sentry/react";

const styles = {
  /* EuiButtonIcons do not shrink with their parents in the same way that
     EuiIcons do. These styles are necessary to match the sizing of non-button
     icons in the other column headers. */
  icon: css`
    height: 12px !important;
    width: 12px !important;
    & > * {
      block-size: 12px !important;
      inline-size: 12px !important;
    }
  `,
};

type LinkedMetadataColumnIconProps = {
  column: LinkedMetadataColumn;
  linkedCol: DrsFileColumn | AnalysisResultColumn;
  analysisResultsByTableId: PageProjectData["analysisResultsByTableId"];
};

export const LinkedMetadataColumnIcon = ({
  column,
  linkedCol,
  analysisResultsByTableId,
}: LinkedMetadataColumnIconProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { gridRef } = useDatasetLayoutContext();

  const jumpToColumn = async (columnId: string) => {
    gridRef.current?.api?.ensureColumnVisible(columnId, "start");
    await sleep(0); // Wait a tick for the scroll to complete
    gridRef.current?.api?.flashCells({ columns: [columnId] });
  };

  const dataColumnName = (() => {
    if (isDrsFileColumn(linkedCol)) {
      return linkedCol.colDef.headerName;
    } else if (isAnalysisResultColumn(linkedCol)) {
      const { analysisTableId, resultKey } = linkedCol.colDef;
      const analysisResult =
        analysisResultsByTableId[analysisTableId][resultKey];
      return analysisResult.result_name;
    }
  })();

  const metadatumName = (() => {
    if (isDrsFileColumn(linkedCol)) {
      const fileType = FILE_TYPES_BY_ID[linkedCol.colDef.fileType];
      const metadata = metadataMap[fileType.key];
      // this should never happen, but just in case:
      // gracefully handle misses so we don't crash the app over a column icon
      if (metadata === undefined) {
        captureException("Failed to find parse metadatum name");
        return "";
      }
      return metadata[column.colDef.metadataKey];
    } else if (isAnalysisResultColumn(linkedCol)) {
      const { analysisTableId, resultKey } = linkedCol.colDef;
      const analysisResult =
        analysisResultsByTableId[analysisTableId][resultKey];
      const metadata = metadataMap[analysisResult.file_type];
      // this should never happen, but just in case:
      // gracefully handle misses so we don't crash the app over a column icon
      if (metadata === undefined) {
        captureException("Failed to find parse metadatum name");
        return "";
      }
      return metadata[column.colDef.metadataKey];
    }
  })();

  return (
    <EuiPopover
      anchorPosition="downLeft"
      button={
        <EuiButtonIcon
          aria-label="Link"
          color="primary"
          css={styles.icon}
          onClick={() => setIsPopoverOpen((isOpen) => !isOpen)}
          iconType="link"
        />
      }
      closePopover={() => setIsPopoverOpen(false)}
      isOpen={isPopoverOpen}
      panelPaddingSize="s"
    >
      <div>
        <EuiFormRow label="Data column">
          <EuiLink
            onClick={() => {
              void jumpToColumn(column.colDef.drsFileColumnId);
              setIsPopoverOpen(false);
            }}
          >
            {dataColumnName}
          </EuiLink>
        </EuiFormRow>
        <EuiFormRow label="Metadata">
          <EuiText size="s">{metadatumName}</EuiText>
        </EuiFormRow>
      </div>
    </EuiPopover>
  );
};
