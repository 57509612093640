import {
  MetadataValueFieldsFragment,
  useCreateMetadataValueMutation,
} from "graphql/_Types";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { uuid } from "utils/uuid";
import { isNonNullish } from "utils/isNonNullish";
import assert from "assert";
import { cloneDeep } from "lodash";
import { useUpdatePageDatasetCache } from "components/Dataset/hooks/useUpdatePageDatasetCache";
import { useUpdatePageProjectCache } from "pages/project/hooks/useUpdatePageProjectCache";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { MarkdownEditor } from "components/MarkdownEditor/MarkdownEditor";
import { captureException } from "@sentry/react";
import { useUserContext } from "providers/UserProvider/UserProvider";

export const DatasetNotes = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { project } = useProjectDataContext();
  const { datasetMode, dataset } = useDatasetDataContext();
  const { updateCache: updatePageDatasetCache } = useUpdatePageDatasetCache(
    dataset.id,
  );

  const { updateCache: updatePageProjectCache } = useUpdatePageProjectCache(
    project.key,
  );

  const [createMetadatumValue] = useCreateMetadataValueMutation();

  const onSave = async (markdown: string) => {
    const metadataValue: Omit<MetadataValueFieldsFragment, "__typename"> = {
      id: uuid(),
      metadataId: dataset.description.id,
      value: markdown,
      tenantId: currentTenant.id,
      dateCreated: new Date().toISOString(),
      userId: currentUser.id,
      taskId: null,
      projectId: project.id,
    };
    try {
      const { data } = await createMetadatumValue({
        variables: {
          metadataValue,
        },
        onError: (err) => captureException(err),
      });
      const valueCreated = data?.createMetadataValue?.metadataValue;
      assert(
        isNonNullish(valueCreated),
        "Expected valueCreated to be nonNullish in DatasetNotes",
      );

      updatePageDatasetCache(
        (data) => {
          const newDataset = cloneDeep(data.dataset);
          const description = newDataset.description;
          assert(
            isNonNullish(description),
            "Expected description to be non nullish in page dataset cache",
          );
          description.metadataValuesByMetadataId.nodes = [valueCreated];

          return { ...data, dataset: newDataset };
        },
        { optional: true },
      );

      updatePageProjectCache((data) => {
        const newProject = cloneDeep(data.project);
        const _dataset = newProject.datasets.nodes.find(
          ({ id }) => id === dataset.id,
        );
        assert(
          _dataset !== undefined,
          "Expect dataset to be defined in page project cache",
        );
        _dataset.description.metadataValuesByMetadataId.nodes = [valueCreated];
        return { ...data, project: newProject };
      });
    } catch (err) {
      captureException(err);
      addUtilityToastFailure("Failed to update dataset description");
    }
  };

  return (
    <MarkdownEditor
      emptyPrompt={
        datasetMode.type === "current"
          ? "Add markdown notes for the dataset here"
          : "No notes were set at this timestamp. Editing dataset notes is not possible when viewing a past timestamp."
      }
      disableEdit={datasetMode.type !== "current"}
      initialValue={dataset.description.value}
      onSave={onSave}
    />
  );
};

/*



*/
