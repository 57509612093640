import { Vega, VisualizationSpec } from "react-vega";
import { zip } from "lodash";

export interface FilePreviewCellSetFootprintsProps {
  content: number[][];
}

/**
 * Renders a cell footprints Vega visualization for a given cellset-group data object
 */
export const FilePreviewCellSetFootprints = ({
  content,
}: FilePreviewCellSetFootprintsProps) => {
  const footprintsFormattedData = {
    width: content.length,
    height: content[0].length,
    values: zip(...content).flat(),
  };

  const spec: VisualizationSpec = {
    $schema: "https://vega.github.io/schema/vega/v5.json",
    width: 500,
    height: 500,
    autosize: {
      type: "fit-x",
    },

    data: [
      {
        name: "footprints",
        values: footprintsFormattedData,
      },
      {
        name: "contours",
        source: "footprints",
        transform: [
          {
            type: "heatmap",
            color: { expr: "scale('density', datum.$value)" },
            opacity: 1,
          },
        ],
      },
    ],
    scales: [
      {
        name: "density",
        type: "linear",
        zero: true,
        domain: [0, 1],
        range: { scheme: "viridis" },
      },
    ],
    marks: [
      {
        type: "image",
        from: { data: "contours" },
        encode: {
          update: {
            x: { value: 0 },
            y: { value: 0 },
            width: { signal: "width" },
            height: { signal: "height" },
            image: { field: "image" },
            aspect: { value: true },
          },
        },
      },
    ],
  };

  return (
    <div style={{ maxHeight: "520px", overflowY: "scroll" }}>
      <Vega spec={spec} />
    </div>
  );
};
