/** @jsxImportSource @emotion/react */

import { useBottomBarStyles } from "../../BottomBar/useBottomBarStyles";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { ButtonDatasetUpload } from "components/ButtonDatasetUpload/ButtonDatasetUpload";
import { BottomBar } from "components/BottomBar/BottomBar";
import { BottomBarButton } from "components/BottomBar/BottomBarButton";
import { DRS_FILE_ACTION_ICONS } from "types/Icons";
import { ButtonDatasetExports } from "components/ButtonDatasetExports/ButtonDatasetExports";

export const DatasetBottomBar = () => {
  const { openFlyout, isFlyoutActive, closeFlyout } = useDatasetLayoutContext();
  const styles = useBottomBarStyles();

  return (
    <BottomBar>
      <div css={styles.controlGroupContainer}>
        <div css={styles.controlGroup}>
          <ButtonDatasetUpload
            size="s"
            key="upload"
            css={styles.button.default}
            iconType={DRS_FILE_ACTION_ICONS["upload"]}
          />
          <ButtonDatasetExports
            size="s"
            key="exports"
            css={styles.button.default}
            iconType="push"
          />
        </div>
        <div css={styles.controlGroup}>
          <BottomBarButton
            key="notes"
            iconType="notebookApp"
            isActive={isFlyoutActive("datasetInfo")}
            onClick={() =>
              isFlyoutActive("datasetInfo")
                ? closeFlyout("datasetInfo")
                : openFlyout({ type: "datasetInfo" })
            }
          >
            Notes
          </BottomBarButton>

          <BottomBarButton
            key="analyses"
            iconType="indexMapping"
            isActive={isFlyoutActive("runAnalysis")}
            onClick={() =>
              isFlyoutActive("runAnalysis")
                ? closeFlyout("runAnalysis")
                : openFlyout({ type: "runAnalysis" })
            }
          >
            Analyses
          </BottomBarButton>

          <BottomBarButton
            key="upload"
            iconType="folderOpen"
            isActive={isFlyoutActive("uploadedFiles")}
            onClick={() =>
              isFlyoutActive("uploadedFiles")
                ? closeFlyout("uploadedFiles")
                : openFlyout({ type: "uploadedFiles" })
            }
          >
            Files
          </BottomBarButton>
        </div>
      </div>
    </BottomBar>
  );
};
