import { css } from "@emotion/react";
import { EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import { ScatterPlot } from "./Plots/ScatterPlot";
import { TraceAndEventPlot } from "./Plots/TraceAndEventPlot";
import { ImageWithFootprintsPlot } from "./Plots/ImageWithFootprintsPlot";

export const CellStatusEditorPlots = () => {
  // just a structural component
  // two top charts sit side by side unless there's not enough room
  // bottom chart can expand to fill the width but not larger than both top charts
  return (
    <EuiFlexGroup
      wrap
      css={css`
        align-content: flex-start;
        display: flex !important;
        flex-wrap: wrap;
      `}
    >
      <EuiFlexItem
        css={css`
          width: 520px;
        `}
        grow={false}
      >
        <ImageWithFootprintsPlot />
      </EuiFlexItem>
      <EuiFlexItem
        css={css`
          width: 520px;
        `}
        grow={false}
      >
        <ScatterPlot />
      </EuiFlexItem>
      <EuiFlexItem
        css={css`
          flex-basis: 100%;
          max-width: 1040px;
        `}
      >
        <TraceAndEventPlot />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
