/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import {
  EuiButtonEmpty,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiPopover,
  useGeneratedHtmlId,
  EuiIcon,
  useEuiTheme,
  shade,
  tint,
} from "@inscopix/ideas-eui";
import { keyBy } from "lodash";
import { css } from "@emotion/react";

type ToggleFilterItem = {
  value: string;
  name: string;
  states: {
    icon: string;
    value: string;
  }[];
};

type SelectedItem<T extends ToggleFilterItem> = {
  item: T["value"];
  state: number;
};

export interface ToggleFilterProps<T extends ToggleFilterItem> {
  items: ToggleFilterItem[];
  selected: SelectedItem<T>;
  onChange: (selectedItem: SelectedItem<T>) => void;
}

export const ToggleFilter = <T extends ToggleFilterItem>({
  items,
  selected,
  onChange,
}: ToggleFilterProps<T>) => {
  const { euiTheme, colorMode } = useEuiTheme();

  // match button background to role filter group button
  const backgroundColor =
    colorMode === "DARK"
      ? shade(euiTheme.colors.lightestShade, 0.4)
      : tint(euiTheme.colors.lightestShade, 0.6);

  const itemsByValue = keyBy(items, (item) => item.value);

  const [isPopoverOpen, setPopover] = useState(false);

  const singleContextMenuPopoverId = useGeneratedHtmlId({
    prefix: "singleContextMenuPopover",
  });

  const onButtonClick = () => {
    setPopover((isPopoverOpen) => !isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const selectedItem = itemsByValue[selected.item];
  const selectedItemName = selectedItem?.name;

  const selectedItemIcon = selectedItem?.states?.[selected.state]?.icon ?? null;
  const button = (
    <EuiButtonEmpty
      size="m"
      iconType="arrowDown"
      iconSide="right"
      onClick={onButtonClick}
      color="text"
      css={css`
        font-weight: 500;
        border: 1px solid ${euiTheme.colors.lightShade};
        background-color: ${backgroundColor};
      `}
    >
      {selectedItemIcon !== null && <EuiIcon type={selectedItemIcon} />}{" "}
      <span>{selectedItemName}</span>
    </EuiButtonEmpty>
  );

  return (
    <EuiPopover
      id={singleContextMenuPopoverId}
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenuPanel
        size="s"
        items={items.map((item) => {
          return (
            <EuiContextMenuItem
              key={item.value}
              icon={
                selectedItem.value === item.value
                  ? selectedItem?.states[selected.state].icon
                  : "empty"
              }
              onClick={() => {
                const newSelected = { ...selected };
                if (newSelected.item === item.value) {
                  const selectedItem = itemsByValue[newSelected.item];
                  const nextItemIdx =
                    selectedItem.states[selected.state + 1] !== undefined
                      ? newSelected.state + 1
                      : 0;
                  newSelected.state = nextItemIdx;
                  return onChange(newSelected);
                } else {
                  return onChange({ item: item.value, state: 0 });
                }
              }}
            >
              {item.name}
            </EuiContextMenuItem>
          );
        })}
      />
    </EuiPopover>
  );
};
