import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
} from "@inscopix/ideas-eui";
import { ButtonIdentifyFile } from "components/ButtonIdentifyFile/ButtonIdentifyFile";
import { ButtonViewTaskLogs } from "components/ButtonViewTaskLogs/ButtonViewTaskLogs";
import { CircularProgress } from "components/CircularProgress/CircularProgress";
import { fileBadgeColors } from "components/FileBadge/FileBadge";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useGetDrsFileProcessingStatus } from "hooks/useGetDrsFileProcessingStatus";
import { FileStatus } from "types/constants";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { FileBrowserFile } from "./FileBrowser";

export interface FileBrowserCellRendererFileTypeProps {
  file: FileBrowserFile;
}

export const FileBrowserCellRendererFileType = ({
  file,
}: FileBrowserCellRendererFileTypeProps) => {
  const { processingStatus, failedTask } = useGetDrsFileProcessingStatus(file);

  let type = "";

  const fileType = file.fileType;
  if (fileType === null) {
    type = "Unknown";
  } else {
    type = FILE_TYPES_BY_ID[fileType]?.name ?? "Unknown";
  }

  const isUploading = file.status === FileStatus["CREATED"];

  const tooltipMessage = (() => {
    if (isUploading) {
      return "Uploading";
    }
    if (processingStatus === "pending") {
      return "Extracting metadata and generating preview...";
    }
    if (processingStatus === "error") {
      return failedTask?.errorDescription ?? "Failed to read file";
    }
  })();

  return (
    <EuiFlexGroup
      alignItems="center"
      gutterSize="xs"
      justifyContent="flexStart"
    >
      <Tooltip
        content={
          <div>
            <EuiText size="s">{type}</EuiText>
            {tooltipMessage !== undefined && (
              <EuiText color={fileBadgeColors.processing} size="s">
                {tooltipMessage}
              </EuiText>
            )}
          </div>
        }
      >
        {processingStatus === "pending" && (
          <EuiIcon
            type={() => (
              <CircularProgress size="xs" color="text" value={100} max={100} />
            )}
          />
        )}

        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {type}
        </span>
      </Tooltip>
      <EuiFlexItem />
      {(() => {
        if (processingStatus === "error") {
          if (failedTask !== undefined) {
            return (
              <ButtonViewTaskLogs
                taskId={failedTask.id}
                taskStatus={failedTask.status}
                color="warning"
              />
            );
          }
          return <EuiIcon type="warning" color="warning" />;
        }
      })()}
      {getDrsFileModifyPermissionByDrsFileAndAction(
        {
          ...file,
          source: file.source.type,
        },
        "IDENTIFY",
      ).isPermitted && (
        <ButtonIdentifyFile file={{ ...file, source: file.source.type }} />
      )}
    </EuiFlexGroup>
  );
};
