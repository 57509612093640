/** @jsxImportSource @emotion/react */
import { useSelectionState } from "components/FlyoutFiles/useSelectionState";

import {
  DrsFileModifyPermissionErrorDetails,
  DrsFileModifyPermissions,
  getDrsFileModifyPermissionByDrsFileAndAction,
} from "types/DrsFileModifyPermissions";
import { DRS_FILE_ACTION_ICONS } from "types/Icons";
import { isDefined } from "utils/isDefined";
import { FileBrowserFile, FileBrowserProps } from "./FileBrowser";
import { sortBy } from "lodash";
import { FileBrowserActionControlButton } from "./FileBrowserActionControlButton";
import { TooltipContentList } from "components/Tooltip/TooltipContentList";

const actionKeyPropMap: Record<
  keyof NonNullable<FileBrowserProps["selection"]>["actions"],
  {
    tooltips: { default: string };
    permissionKeys: DrsFileModifyPermissions[];
    // Most actions will filter permitted files and apply the action to the filtered files.
    // Setting this to false requires that all files must be permitted to execute an action.
    permitPartial: boolean;
  }
> = {
  archive: {
    permissionKeys: ["ARCHIVE"],
    tooltips: {
      default: "Archive selected file(s).",
    },
    permitPartial: true,
  },
  delete: {
    permissionKeys: ["DATA_DELETE", "DELETE"],
    tooltips: {
      default: "Delete data and history for selected file(s).",
    },
    permitPartial: true,
  },
  download: {
    permissionKeys: ["DOWNLOAD"],
    tooltips: {
      default: "Download selected file(s).",
    },
    permitPartial: false,
  },
  unarchive: {
    permissionKeys: ["UNARCHIVE"],
    tooltips: {
      default: "Unarchive selected file(s)",
    },
    permitPartial: true,
  },
  unassign: {
    permissionKeys: ["UNASSIGN"],
    tooltips: {
      default: "Unassign selected files from their respective recording(s).",
    },
    permitPartial: true,
  },
  createSeries: {
    permissionKeys: ["CREATE_SERIES"],
    tooltips: {
      default: "Create series with selected file(s)",
    },
    permitPartial: false,
  },
  revertSeries: {
    permissionKeys: ["REVERT_SERIES"],
    tooltips: {
      default: "Break series with selected file(s)",
    },
    permitPartial: false,
  },
  rename: {
    permissionKeys: ["RENAME"],
    permitPartial: false,
    tooltips: {
      default: "Rename selected file",
    },
  },
};

enum ActionSortOrder {
  "rename",
  "unassign",
  "createSeries",
  "revertSeries",
  "archive",
  "unarchive",
  "delete",
  "download",
}

interface FileBrowserActionControlsProps {
  selectedFiles: FileBrowserFile[];
  actions: NonNullable<FileBrowserProps["selection"]>["actions"];
  selectedFileActions: ReturnType<typeof useSelectionState<FileBrowserFile>>[1];
}
export const FileBrowserActionControls = ({
  actions,
  selectedFiles,
  selectedFileActions,
}: FileBrowserActionControlsProps) => {
  const availableActions = Object.entries(actions)
    .sort(
      ([keyA], [keyB]) =>
        ActionSortOrder[
          keyA as keyof NonNullable<FileBrowserProps["selection"]>["actions"]
        ] -
        ActionSortOrder[
          keyB as keyof NonNullable<FileBrowserProps["selection"]>["actions"]
        ],
    )
    .map(([key, action]) => {
      if (action === undefined) {
        return undefined;
      }

      const actionKey = key as keyof typeof actions;
      const permissionKeys = actionKeyPropMap[actionKey].permissionKeys;

      const notPermittedByDrsFileErrorTips: Partial<
        Record<DrsFileModifyPermissionErrorDetails["type"], string>
      > = {};

      const permittedFiles = selectedFiles.filter((fileBrowserFile) =>
        permissionKeys.some((permissionKey) => {
          const objectPermission = getDrsFileModifyPermissionByDrsFileAndAction(
            { ...fileBrowserFile, source: fileBrowserFile.source.type },
            permissionKey,
          );
          if (!objectPermission.isPermitted) {
            objectPermission.permissionErrors.forEach(
              (permissionError) =>
                (notPermittedByDrsFileErrorTips[permissionError.type] =
                  permissionError.notPermittedTooltip),
            );
          }
          return objectPermission.isPermitted;
        }),
      );

      const isPermissionPartial = actionKeyPropMap[actionKey].permitPartial;

      const isActionPermittedByFileFields = isPermissionPartial
        ? permittedFiles.length > 0
        : permittedFiles.length === selectedFiles.length;

      const isActionDisabledFromProps =
        action.isDisabled !== undefined
          ? action.isDisabled(selectedFiles)
          : false;

      const isActionPermitted =
        isActionPermittedByFileFields &&
        !isActionDisabledFromProps &&
        selectedFiles.length > 0;

      /**
       * Get tooltip
       */
      const actionToolTip = (() => {
        if (isActionPermitted || selectedFiles.length === 0) {
          return actionKeyPropMap[actionKey].tooltips.default;
        }

        // construct tooltip
        const tooltips: string[] = [];

        // from props
        if (isActionDisabledFromProps) {
          if (typeof action.disabledToolTip === "function") {
            const propTooltipPortion = action.disabledToolTip(selectedFiles);
            if (isDefined(propTooltipPortion)) {
              tooltips.push(propTooltipPortion);
            }
          } else {
            const propTooltipPortion = action.disabledToolTip;
            if (isDefined(propTooltipPortion)) {
              tooltips.push(propTooltipPortion);
            }
          }
        }

        // from drs file permissions
        // sort so tooltips always in same order
        sortBy(
          Object.entries(notPermittedByDrsFileErrorTips),
          ([key]) => key,
        ).forEach(([_, errorTip]) => tooltips.push(errorTip));

        return <TooltipContentList tooltips={tooltips} />;
      })();

      const suppressLoading = (() => {
        const actionSuppressLoading = action.suppressLoading;
        if (typeof actionSuppressLoading === "function") {
          return actionSuppressLoading(selectedFiles);
        }
        return Boolean(actionSuppressLoading);
      })();

      return {
        actionType: actionKey,
        onExecute: () => action.onExecute(permittedFiles),
        icon: DRS_FILE_ACTION_ICONS[actionKey],
        isDisabled: !isActionPermitted,
        tooltip: actionToolTip,
        suppressLoading,
      };
    })
    .filter(isDefined);

  return (
    <>
      {availableActions.map((action) => (
        <FileBrowserActionControlButton
          {...action}
          key={action.actionType}
          deselectItems={selectedFileActions.deselectItems}
          selectedFiles={selectedFiles}
        />
      ))}
    </>
  );
};
