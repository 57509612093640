import {
  EuiBadge,
  EuiBadgeProps,
  EuiLoadingSpinner,
  IconType,
} from "@inscopix/ideas-eui";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Prompt } from "react-router-dom";
import "./useStatusBar.scss";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";

export const StatusPanelAddRow: FC = () => {
  const addNewRow = useToolParamsGridRowDataContext((s) => s.addNewRow);

  return (
    <ButtonPermissioned
      requiredPermission="edit"
      iconType="plus"
      onClick={addNewRow}
    >
      Add row
    </ButtonPermissioned>
  );
};

export const StatusPanelSaveStatus: FC = () => {
  const saveStatus = useToolParamsGridRowDataContext((s) => s.saveStatus);
  const [isPromptVisible, setIsPromptVisible] = useState(false);

  const handleBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  }, []);

  useEffect(() => {
    if (saveStatus !== "saved") {
      window.addEventListener("beforeunload", handleBeforeUnload);
      setIsPromptVisible(true);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      setIsPromptVisible(false);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [saveStatus, handleBeforeUnload]);

  const {
    color,
    icon,
    statusText,
  }: { color: EuiBadgeProps["color"]; icon: IconType; statusText: string } =
    useMemo(() => {
      switch (saveStatus) {
        case "unsaved":
          return {
            color: "hollow",
            icon: "dot",
            statusText: "Unsaved changes",
          };
        case "saving":
          return {
            color: "hollow",
            icon: () => (
              <EuiLoadingSpinner size="s" style={{ marginRight: 4 }} />
            ),
            statusText: "Syncing changes",
          };
        case "saved":
          return {
            color: "hollow",
            icon: "check",
            statusText: "Changes synced",
          };
        case "error":
          return {
            color: "danger",
            icon: "error",
            statusText: "Error syncing changes",
          };
      }
    }, [saveStatus]);

  return (
    <>
      <Prompt
        when={isPromptVisible}
        message="Leaving the page will erase unsaved changes. Continue anyway?"
      />
      <EuiBadge color={color} iconType={icon}>
        {statusText}
      </EuiBadge>
    </>
  );
};
