import { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { useGetAllDatasetRecordingsTableColumnsLazyQuery } from "graphql/_Types";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { isDefined } from "utils/isDefined";
import assert from "assert";
import { RecordingsGridColDef } from "components/RecordingsGrid/RecordingsGrid.types";
import { captureException } from "@sentry/react";

export const useValidateMetadatumKey = (key: string) => {
  const { recordingsTable } = useDatasetDataContext();
  const [getColumns] = useGetAllDatasetRecordingsTableColumnsLazyQuery();
  const [isKeyValid, setIsKeyValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const isKeyUnique = useCallback(
    async (key: string) => {
      const { data } = await getColumns({
        variables: {
          datasetRecordingsTableId: recordingsTable.id,
        },
        fetchPolicy: "network-only",
        onError: (err) => captureException(err),
      });

      const columns = data?.datasetRecordingsTableColumns?.nodes;
      assert(
        isDefined(columns),
        "Expected recordings table columns to be defined",
      );

      return !columns.some((column) => {
        const colDef = column.colDef?.metadataValues.nodes[0]
          ?.value as RecordingsGridColDef;
        return (
          colDef.type === "linkedMetadata" &&
          colDef.metadataKey === `user.${key}`
        );
      });
    },
    [getColumns, recordingsTable.id],
  );

  const validateKey = useCallback(
    async (key: string) => {
      try {
        setIsLoading(true);
        const isUnique = await isKeyUnique(key);
        setError(undefined);
        setIsKeyValid(isUnique);
      } catch (err) {
        captureException(err);
        setError(err as Error);
        setIsKeyValid(false);
      } finally {
        setIsLoading(false);
      }
    },
    [isKeyUnique],
  );

  const debouncedValidateKey = useMemo(
    () => debounce(validateKey, 500),
    [validateKey],
  );

  useEffect(() => {
    void debouncedValidateKey(key);
  }, [debouncedValidateKey, key]);

  return {
    isKeyValid,
    isLoading,
    error,
  };
};
