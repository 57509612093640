import { captureException } from "@sentry/react";
import assert from "assert";
import {
  DatasetActionDef,
  DatasetActionName,
  DatasetActionResult,
} from "hooks/useDatasetAction/actionDefFactoryMap";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { TDatasetMode } from "pages/project/dataset/usePageDatasetData";
import { ReactNode, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { create } from "zustand";

type EnqueueOptions<T extends DatasetActionName> = {
  onSuccess?: (result: Awaited<DatasetActionResult<T>>) => void;
  onError?: (error: Error, isQueueError?: boolean) => void;
};
type QueuedAction<T extends DatasetActionName> = DatasetActionDef<
  DatasetActionResult<T>
> & {
  resolve: (
    result:
      | {
          error: Error;
          data: undefined;
        }
      | {
          error: undefined;
          data: Awaited<DatasetActionResult<T>>;
        },
  ) => void;
  options: EnqueueOptions<T> | undefined;
};

export type ActionsQueueStoreState = {
  datasetMode: TDatasetMode["type"] | undefined;
  update: (params: {
    state: "datasetMode";
    value: TDatasetMode["type"];
  }) => void;
  enqueueAction: <T extends DatasetActionName>(
    action: DatasetActionDef<DatasetActionResult<T>>,
    options?: EnqueueOptions<T>,
  ) => Promise<
    | {
        error: Error;
        data: undefined;
      }
    | {
        error: undefined;
        data: Awaited<DatasetActionResult<T>>;
      }
  >;
  executeAction: () => Promise<void>;
  isActionRunning: boolean;
  status: "synced" | "syncing" | "error";
  queue: QueuedAction<DatasetActionName>[];
  resetState: () => void;
};

const defaultState = {
  datasetMode: undefined,
  isActionRunning: false,
  status: "synced" as const,
  queue: [],
};

// in case awaited actions want to check if an upstream queue failure or action failure
export class TerminalActionsError extends Error {
  constructor() {
    super("An upstream action failure has terminated the actions queue.");
  }
}

/**
 * Private actions queue store
 */
const _useActionsQueueStore = create<ActionsQueueStoreState>((set, get) => {
  const resetState = () => set({ ...defaultState });

  /**
   * Adds an action to the queue and returns a promise that will always resolve
   * the promise will resolve with an error object if the action fails
   * @param action
   */
  const enqueueAction: ActionsQueueStoreState["enqueueAction"] = (
    action,
    options,
  ) => {
    if (get().datasetMode !== "current") {
      throw new Error(
        "Caught prohibited action fallback when viewing dataset history",
      );
    }

    return new Promise((resolve) => {
      action.onEnqueue();
      set({
        queue: [
          ...get().queue,
          {
            ...action,
            resolve: resolve as (
              val:
                | {
                    error: Error;
                    data: undefined;
                  }
                | {
                    error: undefined;
                    data: Awaited<DatasetActionResult<DatasetActionName>>;
                  },
            ) => void,
            options,
          },
        ],
        status: "syncing",
      });
    });
  };

  /**
   * Removes the oldest action from the queue
   * @returns The dequeued action
   */
  const dequeueAction = () => {
    const newQueue = get().queue;

    const action = newQueue.shift();
    assert(action !== undefined, "Dequeued action from empty queue");

    set({ queue: newQueue });

    return action;
  };

  /**
   * Resolves failed action with its error, and resolves all queued actions with TerminalActionsError
   * @param action Action that caused the terminal failure
   * @param error Action error
   */
  const processTerminalQueueFailure = () => {
    get().queue.forEach((queuedAction) => {
      queuedAction.resolve({
        error: new TerminalActionsError(),
        data: undefined,
      });
      if (queuedAction.options?.onError) {
        queuedAction.options.onError(new TerminalActionsError(), true);
      }
    });
  };

  /**
   * Polls online status until online
   * @throws if online status can not be established
   */
  const waitForConnection = async () => {
    if (window.navigator.onLine) {
      return;
    }
    return new Promise<void>((res) => {
      const onOnline = () => {
        window.removeEventListener("online", onOnline);
        res();
      };
      window.addEventListener("online", onOnline);
    });
  };

  /**
   * Handles retrying failed actions
   */
  const retryAction = async <T extends DatasetActionName>(
    action: QueuedAction<T>,
  ) => {
    const retryCap = 3;
    for (let retryCount = 1; retryCount <= retryCap; retryCount++) {
      try {
        await waitForConnection();
        const data = await action.onDequeue();
        if (get().queue.length === 0) {
          set({ status: "synced" });
        }
        action.resolve({ error: undefined, data });
        if (action.options?.onSuccess) {
          action.options.onSuccess(data);
        }
        break;
      } catch (err) {
        if (retryCount === retryCap) {
          captureException(err);

          action.resolve({ error: err as Error, data: undefined });

          if (action.options?.onError) {
            action.options.onError(err as Error);
          }

          processTerminalQueueFailure();
          set({ status: "error" });
        }
      }
    }
  };

  /**
   * Executes the next action in the queue
   */
  const executeAction = async () => {
    set({ isActionRunning: true });

    const action = dequeueAction();

    try {
      await waitForConnection();

      const result = await action.onDequeue();
      action.resolve({ error: undefined, data: result });
      if (action.options?.onSuccess) {
        action.options.onSuccess(result);
      }

      if (get().queue.length === 0) {
        set({ status: "synced" });
      }
    } catch (error) {
      await retryAction(action);
    } finally {
      set({ isActionRunning: false });
    }
  };

  const update: ActionsQueueStoreState["update"] = (params) => {
    return set({ [params.state]: params.value });
  };

  return {
    ...defaultState,
    update,
    enqueueAction,
    executeAction,
    resetState,
  };
});

/**
 * Public actions queue store
 */
export const useActionQueueStore = <U,>(
  selector: (
    state: Pick<ActionsQueueStoreState, "status" | "enqueueAction">,
  ) => U,
  equals?: (a: U, b: U) => boolean,
) =>
  _useActionsQueueStore((state) => {
    const { status, enqueueAction } = state;

    return selector({
      status,
      enqueueAction,
    });
  }, equals);

interface ActionsQueueManagerProps {
  children: ReactNode;
}

/**
 * Manages action queue store
 */
export const ActionsQueueManager = ({ children }: ActionsQueueManagerProps) => {
  const { datasetMode } = useDatasetDataContext();

  const { isActionRunning, status, queue, executeAction, update, resetState } =
    _useActionsQueueStore();

  /**
   * Warn users before leaving with unsynced data
   */

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (status === "syncing") {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", beforeUnload);
    return () => window.removeEventListener("beforeunload", beforeUnload);
  }, [status]);
  /**
   * Keep store up to date on mode
   */
  useEffect(() => {
    update({ state: "datasetMode", value: datasetMode.type });
  }, [datasetMode, update]);

  /*
   * Queue manager
   */
  useEffect(() => {
    if (!isActionRunning && queue.length > 0 && status !== "error") {
      void executeAction().catch((err) => captureException(err));
    }
  }, [executeAction, isActionRunning, queue, status]);

  /**
   * Reset state on unmount
   */
  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  return (
    <>
      <Prompt
        when={status === "syncing"}
        message="You have unsaved changes. 
       Press cancel if you wish to wait for your changes to sync.
       If you click OK to proceed, your changes may be lost."
      />
      {children}
    </>
  );
};
