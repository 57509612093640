import { css } from "@emotion/react";
import { shade, useEuiTheme } from "@inscopix/ideas-eui";
import { globalStyleConstants } from "styles/GlobalStyleConstants";

export const useBottomBarStyles = () => {
  const { euiTheme } = useEuiTheme();
  const color = css`
    color: ${euiTheme.colors.ghost};
  `;
  const { headerAndBottomBarHeight } = globalStyleConstants;

  const bottomBarStyles = {
    root: [
      css`
        height: ${headerAndBottomBarHeight}px;
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        overflow-x: auto;
        padding: 0px 10px;
        /* matching to EUI bottom bar background color */
        background-color: ${shade(euiTheme.colors.darkestShade, 0.645)};
      `,
      color,
    ],
    button: {
      default: [
        color,
        css`
          background-color: ${euiTheme.colors.darkestShade};
        `,
      ],
      active: [
        color,
        css`
          background-color: ${euiTheme.colors.darkestShade};
          border: 1px solid ${euiTheme.colors.ghost};
        `,
      ],
    },
    controlGroupContainer: css`
      display: flex;
      justify-content: space-between;
      width: 100%;
    `,
    controlGroup: [
      css`
        display: flex;
        align-items: center;
        gap: 5px;
      `,
    ],
  };
  return bottomBarStyles;
};
