/**
 * Generates a normalized metadata key - allows periods, but cannot start with one.
 * @param string String to convert.
 * @param delimiter The string  to delimit words with.
 * @returns The key of the slug string.
 */
export function stringToKey(string: string, delimiter = ".") {
  return string
    .normalize("NFD")
    .replace(/\s+/g, delimiter)
    .replace(/[^\w.-]+/g, "")
    .replace(/\.\.+/g, ".")
    .replace(/^\./g, "")
    .replace(/--+/g, delimiter);
}
