import { EuiPanel } from "@inscopix/ideas-eui";
import { PanelTenantUsageContent } from "./PanelTenantUsageContent";

const panelMinWidth = 400;

/**
 * Displays a panel showing resource consumption for the entire tenant
 */
export const PanelTenantUsage = () => {
  return (
    <EuiPanel style={{ minWidth: panelMinWidth }}>
      <PanelTenantUsageContent />
    </EuiPanel>
  );
};
