import { useCallback, useEffect, useState } from "react";
import * as fabric from "fabric";
import { htmlIdGenerator } from "@inscopix/ideas-eui";
import { useDrsFileDownloadPreviewDjango } from "hooks/useDrsFileDownloadPreviewDjango";
import { captureException } from "@sentry/react";
import { FileFormat, FILE_FORMATS_BY_KEY } from "types/FileFormats";
import { ToolRoiFrameParam } from "../ToolParamsGrid.types";

const getImage = (url: string, elementId: string) =>
  new Promise<fabric.Image>((res, rej) => {
    const image = document.createElement("img");
    image.id = elementId;
    image.src = url;
    document.body.append(image);

    image.onerror = () => {
      rej("Failed to load image");
    };

    image.onload = () => {
      res(new fabric.Image(elementId));
    };
  });

const getVideo = (url: string, elementId: string) =>
  new Promise<fabric.Image>((res, rej) => {
    const video = document.createElement("video");
    video.id = elementId;
    video.hidden = true;

    const source: HTMLSourceElement = document.createElement("source");
    source.src = url;
    source.type = "video/mp4";

    source.addEventListener("error", () => {
      rej("Failed to load video source");
    });

    video.appendChild(source);
    document.body.append(video);

    const getNativeSize = () =>
      new Promise<{ height: number; width: number }>((res) => {
        video.addEventListener(
          "loadedmetadata",
          function () {
            const height = this.videoHeight;
            const width = this.videoWidth;

            res({ height, width });
          },
          false,
        );
      });

    getNativeSize()
      .then(({ height, width }) => {
        const image = new fabric.Image(video, {
          left: 0,
          top: 0,
          height,
          width,
          objectCaching: false,
          hasControls: false,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          selectable: false,
          zIndex: 0,
        });
        video.height = height;
        video.width = width;
        video.currentTime = 0;
        // make sure first frame can be rendered before resolving
        video.onloadeddata = () => res(image);
      })
      .catch((err) => rej(err));
  });

export const useFabricImage = (
  fileId: string,
  previewFileFormat: FileFormat["id"],
  /**
   * TODO add preview key logic when backend is fixed to return preview keys
   */
  previewKey?: ToolRoiFrameParam["type"]["source_file"]["data"]["key"],
):
  | { loading: false; image: fabric.Image; error?: undefined }
  | { loading: true; image?: undefined; error?: undefined }
  | { loading: false; image?: undefined; error: Error } => {
  const [error, setError] = useState<Error | undefined>();
  const [image, setImage] = useState<fabric.Image | undefined>();
  const [elementId] = useState(
    htmlIdGenerator("canvas-background-html-element")(),
  );

  const { downloadFilePreview } = useDrsFileDownloadPreviewDjango();

  const fetchPreview = useCallback(async () => {
    try {
      const { data } = await downloadFilePreview(fileId);
      const preview = data.previews
        // sort previews so matching key is first
        // preview key is a preferred choice, but if the preview of that key doesn't exist
        // we grab the next available preview of the right file type
        ?.sort(({ key }) => (key === previewKey ? -1 : 1))
        .find(({ file_format }) => file_format === previewFileFormat);

      if (preview === undefined) {
        throw new Error(
          "No preview of specified file format found on source file.",
        );
      }

      return preview.url;
    } catch (err) {
      captureException(err);
      throw new Error("Failed to fetch preview information.");
    }
  }, [downloadFilePreview, fileId, previewFileFormat, previewKey]);

  useEffect(() => {
    if (!image && !error) {
      fetchPreview()
        .then((url) => {
          switch (previewFileFormat) {
            case FILE_FORMATS_BY_KEY["png"].id:
              getImage(url, elementId)
                .then((image) => {
                  setImage(image);
                })
                .catch((err) => setError(err as Error));
              break;
            case FILE_FORMATS_BY_KEY["mp4"].id:
              getVideo(url, elementId)
                .then((image) => setImage(image))
                .catch((err) => setError(err as Error));
              break;
            default:
              captureException("Unsupported file format in useFabricImage", {
                extra: { previewFileFormat },
              });
              setError(new Error("Unsupported file format in useFabricImage"));
          }
        })
        .catch((err) => setError(err as Error));
    }
  }, [elementId, error, fetchPreview, image, previewFileFormat]);

  /**
   * Remove element on unmount
   */
  useEffect(() => {
    return () => {
      const element = document.getElementById(elementId);
      if (element !== null) {
        element.remove();
      }
    };
  }, [elementId]);

  if (image !== undefined) {
    return { loading: false, image };
  }

  if (error !== undefined) {
    return { loading: false, error };
  }

  return { loading: true };
};
