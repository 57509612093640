import { client } from "providers/ApolloProvider/ApolloProvider";

/**
 * Gets the cache IDs for all cache records with a specified typename
 * @param __typename
 * @returns Cache IDs for all matching cache records
 */
export const getCacheIdsByTypename = (__typename: string) => {
  const normalizedCache = client.cache.extract();
  return Object.entries(normalizedCache)
    .filter(([, val]) => val?.__typename === __typename)
    .map(([key]) => key);
};
