import {
  EuiAccordion,
  EuiButton,
  EuiButtonIcon,
  EuiCallOut,
  EuiColorPicker,
  EuiColorPickerSwatch,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { ShapeType } from "components/RoiEditor/RoiEditor";
import {
  Shape,
  validateToolRoiFrameParamValue,
} from "components/RoiEditor/RoiEditor.helpers";
import { ShapeState } from "components/RoiEditor/RoiEditorProvider";
import { ToolRoiFrameParam } from "components/ToolParamsGrid/ToolParamsGrid.types";
import { startCase } from "lodash";
import IconCircle from "assets/circle.svg";
import IconEllipse from "assets/ellipse.svg";
import IconHexagon from "assets/hexagon.svg";
import IconSquare from "assets/square.svg";
import { isDefined } from "utils/isDefined";
import { Tooltip } from "components/Tooltip/Tooltip";

interface RoiEditorRoiGroupProps {
  isDrawing: boolean;
  onCreateShape: (shape: ShapeType, groupKey: string) => void;
  onSelectShape: (shape: Shape) => void;
  onDeselectShape: (shape: Shape) => void;
  group: NonNullable<
    ToolRoiFrameParam["type"]["roi_settings"]["groups"]
  >[number];
  shapes: ShapeState[];
  selectedShapeIds: Set<Shape["id"]>;
  onRemoveShape: (shape: ShapeState) => void;
  onChangeShapeColor: (shape: Shape, newColor: string) => void;
  onChangeShapeName: (shape: Shape, newName: string) => void;
  validationState: ReturnType<typeof validateToolRoiFrameParamValue>;
  isReadOnly?: boolean;
}

export const RoiEditorRoiGroup = ({
  onCreateShape,
  onSelectShape,
  onDeselectShape,
  onRemoveShape,
  onChangeShapeColor,
  onChangeShapeName,
  isDrawing,
  group,
  shapes,
  selectedShapeIds,
  validationState,
  isReadOnly = false,
}: RoiEditorRoiGroupProps) => {
  const isShapeLimitReached =
    isDefined(group.max) && shapes.length === group.max;

  const insufficientShapesError =
    validationState.groups?.[group.key]?.minNotMet;

  // drawing is presently disabled when you reach the limit, but this could change
  const shapesExceededError = validationState.groups?.[group.key]?.maxExceeded;

  return (
    <EuiAccordion
      id={group.key}
      buttonContent={
        <EuiText size="xs">
          <h3>{group.name}</h3>
        </EuiText>
      }
      initialIsOpen
    >
      <EuiSpacer size="s" />
      <EuiFlexGroup direction="column" gutterSize="s">
        {insufficientShapesError !== undefined && (
          <EuiCallOut
            title="Insufficient shapes"
            color="warning"
            iconType="warning"
          >
            <p>{insufficientShapesError.message}</p>
          </EuiCallOut>
        )}
        {shapesExceededError !== undefined && (
          <EuiCallOut
            title="Shape limit exceeded"
            color="warning"
            iconType="warning"
          >
            <p>{shapesExceededError.message}</p>
          </EuiCallOut>
        )}
        {shapes.length === 0 && (
          <EuiFlexItem>
            <EuiText>
              <p>No shapes found</p>
            </EuiText>
          </EuiFlexItem>
        )}
        {shapes.map((shape) => {
          const icon = (() => {
            switch (shape.object.shapeType) {
              case "circle":
                return IconCircle;
              case "ellipse":
                return IconEllipse;
              case "line":
                return "lineSolid";
              case "polygon":
                return IconHexagon;
              case "rectangle":
                return IconSquare;
              case "contour":
                return "pencil";
              case "point":
                return "dot";
            }
          })();

          return (
            <EuiFlexItem
              key={shape.id}
              onFocus={() => onSelectShape(shape.object)}
              onBlur={() => onDeselectShape(shape.object)}
            >
              <EuiFieldText
                value={shape.name}
                icon={icon}
                style={
                  selectedShapeIds.has(shape.id)
                    ? {
                        backgroundImage:
                          "linear-gradient(to top, #07C, #07C 2px, transparent 2px, transparent 100%)",
                        backgroundSize: "100%",
                      }
                    : undefined
                }
                readOnly={isDrawing || isReadOnly}
                onChange={(e) =>
                  onChangeShapeName(shape.object, e.target.value)
                }
                append={[
                  <EuiColorPicker
                    key="color"
                    color={shape.stroke}
                    onChange={(color) =>
                      onChangeShapeColor(shape.object, color)
                    }
                    readOnly={isDrawing || isReadOnly}
                    button={
                      <EuiColorPickerSwatch
                        color={shape.stroke}
                        style={{
                          marginLeft: 8,
                          marginRight: 8,
                          marginTop: 7,
                        }}
                      />
                    }
                  />,
                  <EuiButtonIcon
                    aria-label="Remove shape"
                    key="remove"
                    color="text"
                    iconType="trash"
                    onClick={() => onRemoveShape(shape)}
                    disabled={isDrawing || isReadOnly}
                  />,
                ]}
              />
            </EuiFlexItem>
          );
        })}
        <EuiSpacer size="xs" />
        <EuiFlexItem>
          <EuiFlexGroup
            alignItems="center"
            justifyContent="flexStart"
            wrap
            gutterSize="s"
            responsive={false}
          >
            <EuiFlexItem grow={false}>
              <strong style={{ fontSize: 12 }}>Add</strong>
            </EuiFlexItem>
            {[...group.shapes].sort().map((kind) => {
              return (
                <EuiFlexItem key={kind} grow={false}>
                  <Tooltip
                    content={isShapeLimitReached ? "Shape limit reached" : ""}
                  >
                    <EuiButton
                      color="text"
                      size="s"
                      minWidth="auto"
                      onClick={() => onCreateShape(kind, group.key)}
                      disabled={isDrawing || isShapeLimitReached || isReadOnly}
                    >
                      {startCase(kind)}
                    </EuiButton>
                  </Tooltip>
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiAccordion>
  );
};
