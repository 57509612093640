import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { Helmet } from "react-helmet-async";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { PageOrganizationDashboard } from "./PageOrganizationDashboard";
import { PageOrganizationExternalDashboard } from "./PageOrganizationExternalDashboard";

export const PageOrganization = () => {
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const currentUserTenants = useUserContext((s) => s.tenants);

  const userIsTenantMember = currentUserTenants.some(
    ({ id }) => id === currentTenant.id,
  );

  return (
    <>
      <Helmet>
        <title>{currentTenant.name}</title>
      </Helmet>
      {userIsTenantMember ? (
        <PageOrganizationDashboard />
      ) : (
        <PageOrganizationExternalDashboard />
      )}
    </>
  );
};
