import { htmlIdGenerator } from "@inscopix/ideas-eui";
import { addToast } from "../components/GlobalToastList/GlobalToastList";

export function addUtilityToastSuccess(message: string) {
  addToast({
    id: htmlIdGenerator()(),
    title: "Success",
    color: "success",
    text: <p>{message}</p>,
  });
}
