/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useRef } from "react";
import {
  EuiDescriptionList,
  EuiModal,
  EuiPanel,
  EuiSelectable,
  EuiSelectableOption,
  EuiIcon,
  EuiBadge,
  EuiTextTruncate,
} from "@inscopix/ideas-eui";
import { FileRecordingGroup, FileSource } from "graphql/_Types";
import { DrsFileColumn } from "components/RecordingsGrid/RecordingsGrid.helpers";
import {
  ProjectFile,
  useProjectFilesStore,
} from "stores/project-files/ProjectFilesManager";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { css } from "@emotion/react";
import { FILE_TYPES_BY_ID, FILE_TYPES_BY_KEY } from "types/FileTypes";
import { compareDates } from "utils/compareDates";
import { partition } from "lodash";
import assert from "assert";

export type ModalAssignFileProps = {
  column: DrsFileColumn;
  recordingId: FileRecordingGroup["id"];
  onClose: () => void;
};

interface OptionData {
  file: ProjectFile;
}

const HEADER_HEIGHT = 130;
const FILTER_HEIGHT = 40;
const SELECTABLE_HEIGHT = 200;

export const ModalAssignFile = ({
  column,
  recordingId,
  onClose,
}: ModalAssignFileProps) => {
  const assignFileAction = useDatasetAction("assignFile");
  const unassignFileAction = useDatasetAction("unassignFile");

  // focus filter when modal is ready
  const searchRef = useRef<HTMLInputElement | null>(null);
  const setSearchRef: React.RefCallback<HTMLInputElement> = useCallback(
    (node) => {
      searchRef.current = node;
      // timeout needed to get around EUI modal focus behavior
      // see https://github.com/elastic/eui/blob/70634a0d79814a395ecf6fc44bb6ca20825766fa/src/components/modal/confirm_modal.tsx#L95
      setTimeout(() => {
        node?.focus();
      }, 500);
    },
    [],
  );

  const files = useProjectFilesStore((s) => s.files);

  // build options for selectable
  const options: Array<EuiSelectableOption<OptionData>> = useMemo(() => {
    const matchingFiles = files
      // only uploaded files can be assigned
      .filter((file) => file.source === FileSource.Uploaded)
      // if column type is not any/unknown, filter to matching files only
      .filter((file) => {
        if (column.colDef.fileType !== FILE_TYPES_BY_KEY["unknown"].id) {
          return column.colDef.fileType === file.fileType;
        }
        return true;
      })
      // sort by date added descending to prioritize newer uploaded files
      .sort((a, b) => compareDates(a, b, "dateCreated"));

    // partition the file array into unassigned and assigned files so we can show unassigned first
    const partitionedByAssignment = partition(
      matchingFiles,
      (file) => file.recordings.length === 0,
    );
    return [...partitionedByAssignment[0], ...partitionedByAssignment[1]].map(
      (file) => {
        // get the file icon to prepend
        const fileType =
          file.fileType !== null ? FILE_TYPES_BY_ID[file.fileType] : undefined;
        const fileIcon = fileType?.icon;
        const prepend =
          fileIcon !== undefined ? <EuiIcon type={fileIcon} /> : undefined;

        // get the recording assignment to append
        const assignment = (() => {
          if (file.recordings.length === 0) {
            return <EuiBadge>Unassigned</EuiBadge>;
          } else {
            // only one recording possible for uploaded/assignable files
            const recording = file.recordings[0];
            return (
              <RecordingIdentifierBadge
                recordingId={recording.id}
                showShortIdOnly
              />
            );
          }
        })();

        return {
          label: file.name,
          prepend: prepend,
          append: assignment,
          file: file,
        };
      },
    );
  }, [files, column.colDef.fileType]);

  const noMatchMessage = (
    <span>
      No matching files found. <br />
      <br />
      Files must be uploaded to the dataset and match the data type of the
      column to be assignable.
    </span>
  );

  const onChange = (options: EuiSelectableOption<OptionData>[]) => {
    if (options !== undefined) {
      const selectedOption = options.find((option) => option.checked === "on");
      if (selectedOption !== undefined) {
        const isCurrentlyAssigned =
          selectedOption.file.recordings.length > 0 &&
          selectedOption.file.columns.length > 0;

        if (isCurrentlyAssigned) {
          assert(
            selectedOption.file.recordings.length === 1,
            "Expected only one existing recording assignment",
          );
          assert(
            selectedOption.file.columns.length === 1,
            "Expected only one existing column assignment",
          );

          const recordingId = selectedOption.file.recordings[0].id;
          const columnId = selectedOption.file.columns[0].id;

          void unassignFileAction.enqueue({
            drsFileId: selectedOption.file.id,
            recordingId: recordingId,
            columnId: columnId,
          });
        }

        void assignFileAction.enqueue({
          drsFileId: selectedOption.file.id,
          recordingId,
          columnId: column.id,
        });
      }
      onClose();
    }
  };

  return (
    <EuiModal
      onClose={onClose}
      // we want hard set height and width for this modal so it doesn't resize as users filter the options
      // we also need to set euiSelectableListItem__append > div to display inline so the identifier badge plays nice with the selectable's onFocusBadge
      css={css`
        min-block-size: ${HEADER_HEIGHT + FILTER_HEIGHT + SELECTABLE_HEIGHT}px;
        width: 540px;
        .euiSelectableListItem__append > div {
          display: inline;
        }
      `}
    >
      <EuiPanel color="subdued" hasShadow={false} grow={false}>
        <EuiDescriptionList
          listItems={[
            {
              title: "Recording",
              description: (
                <RecordingIdentifierBadge recordingId={recordingId} />
              ),
            },
            {
              title: "Column",
              description: (
                <EuiTextTruncate
                  text={column.colDef.headerName}
                  truncation="middle"
                />
              ),
            },
          ]}
        />
      </EuiPanel>

      <EuiSelectable<OptionData>
        aria-label="Searchable list of assignable files"
        searchable
        searchProps={{
          inputRef: setSearchRef,
        }}
        listProps={{
          showIcons: false,
        }}
        singleSelection={true}
        noMatchesMessage={noMatchMessage}
        emptyMessage={noMatchMessage}
        options={options}
        onChange={onChange}
        height={SELECTABLE_HEIGHT}
      >
        {(list, search) => (
          <>
            {search}
            {list}
          </>
        )}
      </EuiSelectable>
    </EuiModal>
  );
};
